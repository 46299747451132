import "./index.less";
import * as React from "react";
import { GroupBase, SelectInstance } from "react-select";
import { ISelectBaseSharedProps, InnerRefType, LoadOptionsMethod, OptionTypeBase } from "../../../types";
import { getSelectStyles } from "../../../shared/selects/getSelectStyles";
import Async from "react-select/async";
import cn from "classnames";

export interface IAtAsyncSelectBaseProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends ISelectBaseSharedProps<TOption, IsMulti, TGroup> {
    loadOptions: LoadOptionsMethod<TOption, TGroup>;

    cacheOptions?: boolean;
    defaultOptions?: ReadonlyArray<TOption | TGroup> | boolean;

    innerRef?: InnerRefType<SelectInstance<TOption, IsMulti, TGroup>>;
}

/**
 * AtAsyncSelectBase - Async Select Base Component
 * Category: Atom
 *
 * @param props {IAtAsyncSelectBaseProps}
 **/
export const AtAsyncSelectBase = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtAsyncSelectBaseProps<TOption, IsMulti, TGroup>>,
) => {
    const {
        value,
        onChange,
        loadOptions,
        cacheOptions,
        defaultOptions,
        formatOptionLabel,
        formatGroupLabel,
        getOptionLabel,
        getOptionValue,
        placeholder,
        additionalStyles,
        components,
        size = "md",
        isMulti,
        isDisabled,
        isClearable,
        isSearchable,
        hideSelectedOptions,
        closeMenuOnSelect,
        innerRef,
        onBlur,
        onFocus,
        className,
        id,
        children: _,
        ...otherProps
    } = props;

    return (
        <Async<TOption, IsMulti, TGroup>
            className={cn("AtAsyncSelectBase", className)}
            styles={getSelectStyles<TOption, IsMulti, TGroup>(additionalStyles, size)}
            id={id}
            loadOptions={loadOptions}
            cacheOptions={cacheOptions}
            defaultOptions={defaultOptions}
            value={value}
            onChange={onChange}
            formatOptionLabel={formatOptionLabel}
            formatGroupLabel={formatGroupLabel}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            hideSelectedOptions={hideSelectedOptions ?? isMulti ? false : undefined}
            closeMenuOnSelect={closeMenuOnSelect}
            components={components}
            ref={innerRef}
            classNamePrefix="AtAsyncSelectBase"
            {...otherProps}
        />
    );
};
