import { AtmanCoPlatformsEnum } from "../../global/components/ProductSelector";
import { AuthApi, IAuthApi } from "../../api/AuthApi";
import { ISystemStateInfo, SystemStateInfo } from "../models/SystemState";
import { action, observable, runInAction } from "mobx";
import { sortArrayByNumberProp } from "@atman/ui-kit";

export class SystemStateStore {
    private readonly _authApi: IAuthApi;

    @observable public systemStateInfos: SystemStateInfo[] = [];
    @observable public hasLoadedSystemState: boolean = false;

    private _systemStateLoadingTimeout: number | null = null;

    constructor() {
        this._authApi = new AuthApi();
    }

    @action
    public loadSystemStatesInfo = async () => {
        this.hasLoadedSystemState = false;

        this._systemStateLoadingTimeout = window.setTimeout(() => {
            if (!this.hasLoadedSystemState) {
                runInAction(() => {
                    this.hasLoadedSystemState = true;
                });
            }
        }, 30000);

        let result: ISystemStateInfo[];

        if (process.env.REACT_APP_ENVIRONMENT === "local") {
            result = [];
        } else {
            result = await this._authApi.getSystemStateInfos();
        }

        runInAction(() => {
            this.systemStateInfos = result.map((x) => new SystemStateInfo(x));
            this.hasLoadedSystemState = true;

            if (this._systemStateLoadingTimeout != null) {
                window.clearTimeout(this._systemStateLoadingTimeout);
            }
        });
    };

    public getStateForPlatform(platform: AtmanCoPlatformsEnum): {
        available: boolean | undefined;
        activeState?: SystemStateInfo;
    } {
        if (!this.hasLoadedSystemState) {
            return {
                available: undefined,
            };
        }

        if (this.systemStateInfos.length === 0) {
            return {
                available: true,
            };
        }

        const activeStateEntriesForPlatform = sortArrayByNumberProp(
            this.systemStateInfos.filter(
                (x) => x.platforms.includes(platform) && x.startDate.isBefore() && x.endDate.isAfter(),
            ),
            (x) => x.startDate.valueOf(),
            "desc",
        );

        if (activeStateEntriesForPlatform.length === 0) {
            return {
                available: true,
            };
        }

        return {
            available: false,
            activeState: activeStateEntriesForPlatform.firstOrDefault(),
        };
    }
}
