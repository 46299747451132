import "./index.less";
import { AtTableRowHeight } from "../../../../../atoms/AtTableBase/component/parts/AtTableRow";
import React, { ReactElement, useMemo } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface IAtTableRowsSkeletonProps {
    numberOfColumns: number;
    numberOfLines: number;
    height?: AtTableRowHeight;
    baseColor?: string;
    highlightColor?: string;
}

export function AtTableRowsSkeleton(props: IAtTableRowsSkeletonProps): ReactElement {
    // Use colors from the design system
    const {
        baseColor = "#f4f5f7",
        highlightColor = "#e9ebee",
        numberOfLines,
        numberOfColumns,
        height = "lg",
        ...otherProps
    } = props;

    const linesArray = Array.from(Array(numberOfLines));
    const columnsArray = Array.from(Array(numberOfColumns));

    const heightRow = useMemo(() => {
        switch (height) {
            case "sm":
                return 8;

            case "md":
                return 16;

            case "lg":
                return 24;
        }
    }, [height]);

    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor} {...otherProps}>
            {linesArray.map((_v, index) => (
                <tr key={`skeletonLine_${index}`}>
                    {columnsArray.map((_v, index) => (
                        <td className="rowSkeletonLine" key={`skeletonCell_${index}`}>
                            <Skeleton height={`${heightRow}px`} className="rowSkeletonSkeleton" />
                        </td>
                    ))}
                </tr>
            ))}
        </SkeletonTheme>
    );
}
