import "./index.less";
import { AtButton, AtContainer, AtIcon, IconTitleSubtitle } from "@atman/design-system";
import {
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessResultsData,
} from "@atman/business";
import { ResultTableContainer } from "../ResultTableContainer";
import { Trans } from "@lingui/react";
import { getIconPropsForAssessmentType } from "../logic";
import { observer } from "mobx-react";
import React from "react";

export interface ITeamDevelopmentImprovementObjectivesProps {
    candidateProcessData: CompetencyDevelopmentProcessData;
    candidateProcessResultsData: CompetencyDevelopmentProcessResultsData;
    openAndMonitorTabFunc: (url: string) => void;
}

export const TeamDevelopmentImprovementObjectives: React.FC<ITeamDevelopmentImprovementObjectivesProps> = observer(
    (props) => {
        const { candidateProcessData, candidateProcessResultsData, openAndMonitorTabFunc } = props;

        const competenciesToImproveAssessment = candidateProcessData.assessmentsData.get(
            CompetencyDevelopmentAssessmentType.CompetenciesToImprove,
        );

        const icon = (
            <AtIcon
                {...getIconPropsForAssessmentType(CompetencyDevelopmentAssessmentType.CompetenciesToImprove)}
                size="2x"
            />
        );

        return (
            <AtContainer id="TeamDevelopmentImprovementObjectives">
                <div className="objectives-header">
                    <IconTitleSubtitle
                        icon={icon}
                        title={"clientApp.competenciesToDevelop".localize()}
                        subtitle={"clientApp.competenciesToDevelop.description".localize()}
                        size="lg"
                    />
                    {competenciesToImproveAssessment && (
                        <AtButton
                            color="secondary"
                            onClick={() => openAndMonitorTabFunc(competenciesToImproveAssessment.url)}
                        >
                            <Trans id="global.edit" message="Edit" />
                        </AtButton>
                    )}
                </div>
                <ResultTableContainer
                    processResultsData={candidateProcessResultsData}
                    dataType="competency"
                    displayType="improvementObjectives"
                    columns={["competencyName"]}
                    showSourceFilter={false}
                    showColumnFilter={false}
                    showLegend={false}
                />
            </AtContainer>
        );
    },
);
