import "./App.less";
import * as React from "react";
import { ApplicationSetup } from "@atman/business";
import { EmployeeContainer } from "./views/EmployeeContainer";
import { IS_STAGING } from "../index";
import { LoadingIndicator } from "@atman/design-system";
import { LoginAppStores } from "../index";
import { Redirect, Route, Switch } from "react-router-dom";
import { SystemStateStore } from "./data/stores/SystemStateStore";
import { inject, observer } from "mobx-react";
import { observable, runInAction } from "mobx";
import AuthenticatedContainer from "./views/AuthenticatedContainer";
import Axios from "axios";
import UnauthenticatedContainer from "./views/UnauthenticatedContainer";

export interface IUrls {
    classic: string;
    proNA: string;
    proEU: string;
    blobStorage: string;
}

export let backend_urls: IUrls | undefined = undefined;

export interface IAppProps {
    systemStateStore?: SystemStateStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    systemStateStore: stores.systemStateStore,
}))
@observer
class App extends React.Component<IAppProps> {
    @observable private isLoadingUrls: boolean = true;

    componentDidMount() {
        this.loadInitialData();
    }

    private loadUrls = async () => {
        if (!backend_urls) {
            const result = await Axios.get<IUrls>("/urls.json");

            backend_urls = result.data;

            let sentryBaseUrl = result.data.proNA;

            if (IS_STAGING) {
                sentryBaseUrl = sentryBaseUrl.replace(
                    /https:\/\/([a-zA-Z\-_]+)\.([a-zA-Z\-_]+)\.com/,
                    `https://$1-staging.$2.com`,
                );
            }

            ApplicationSetup.setupSentry(
                "https://08ecef4d7fd246b7b7e29412e5f0eb41@o1061249.ingest.sentry.io/6053293",
                false,
                sentryBaseUrl,
            );
        }

        runInAction(() => {
            this.isLoadingUrls = false;
        });
    };

    private loadInitialData = async () => {
        const { systemStateStore } = this.props;

        await this.loadUrls();

        systemStateStore!.loadSystemStatesInfo();
    };

    public render() {
        const {} = this.props;

        return (
            <div className={"App"}>
                {this.isLoadingUrls ? (
                    <LoadingIndicator />
                ) : (
                    <Switch>
                        <Route path={"/Unauth"} component={UnauthenticatedContainer} />
                        <Route path={"/Auth"} component={AuthenticatedContainer} />
                        <Route path={"/Employee"} component={EmployeeContainer} exact={false} />
                        <Redirect to={{ search: window.location.search, pathname: "/Unauth" }} />
                    </Switch>
                )}
            </div>
        );
    }
}

export default App;
