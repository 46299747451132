import * as React from "react";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IProfilesCountCardItemProps {
    profilesCount: number;
}

export const ProfilesCountCardItem: React.FC<IProfilesCountCardItemProps> = (props) => {
    const { profilesCount } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "file-user"] as IconProp}
            cardTitle={"partnerApp.numberOfProfiles".localize()}
            className={"ProfilesCountCardItem"}
        >
            {profilesCount}
        </BaseOverviewCardItem>
    );
};
