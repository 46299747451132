import { Layouts } from "@atman/core";
import { useMediaQuery } from "react-responsive";

export interface IMediaQueryComponentProps {}

const Mobile = (props) => {
    const isMobile = useMediaQuery({ maxWidth: Layouts.sm.max });
    return isMobile ? props?.children ?? null : null;
};

const Tablet = (props) => {
    const isTablet = useMediaQuery({ minWidth: Layouts.md.min, maxWidth: Layouts.md.max });
    return isTablet ? props?.children ?? null : null;
};

const Desktop = (props) => {
    const isDesktop = useMediaQuery({ minWidth: Layouts.lg.min });
    return isDesktop ? props?.children ?? null : null;
};

const Default = (props) => {
    const isNotMobile = useMediaQuery({ minWidth: Layouts.md.min });
    return isNotMobile ? props?.children ?? null : null;
};

export { Mobile, Tablet, Desktop, Default };
