import { FitElement, IFitElement } from "./FitElement";

export interface IFitDimensionInfo extends IFitElement {
    readonly dimension: string;
}

export class FitDimensionInfo extends FitElement implements IFitDimensionInfo {
    public readonly dimension: string;

    constructor(json: IFitDimensionInfo) {
        super(json);

        this.dimension = json.dimension;
    }
}
