import autobind from "autobind-decorator";

export interface ISizeInfo {
    min: number;
    max: number;
}

export type BootstrapSizes = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export class ResponsivenessHelper {
    public static bootstrapSizes: Record<BootstrapSizes, ISizeInfo> = {
        xs: {
            min: 0,
            max: 575,
        },
        sm: {
            min: 576,
            max: 767,
        },
        md: {
            min: 768,
            max: 991,
        },
        lg: {
            min: 992,
            max: 1199,
        },
        xl: {
            min: 1200,
            max: 1399,
        },
        xxl: {
            min: 1400,
            max: 99999,
        },
    };

    public static getWidth() {
        return window.innerWidth;
    }

    public static getHeight() {
        return window.innerHeight;
    }

    public static getBootstrapSize() {
        const width = this.getWidth();

        for (const key of Object.keys(this.bootstrapSizes)) {
            const size = this.bootstrapSizes[key] as ISizeInfo;

            if (width >= size.min && width <= size.max) {
                return {
                    name: key,
                    min: size.min,
                    max: size.max,
                };
            }
        }

        return;
    }

    public static getBootstrapSizeFromName(size: BootstrapSizes) {
        return this.bootstrapSizes[size];
    }

    @autobind
    public static isSize(size: BootstrapSizes) {
        const currentSize = this.getBootstrapSize();
        return size === currentSize?.name;
    }

    @autobind
    public static isSizeOrBigger(size: BootstrapSizes) {
        const requestedSize = this.getBootstrapSizeFromName(size);

        return this.getWidth() >= requestedSize.min;
    }

    @autobind
    public static isBiggerThan(size: number) {
        return this.getWidth() >= size;
    }
}
