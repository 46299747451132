import "./EntityOverview.less";
import * as React from "react";
import { AppScope, Client, GlobalStores, IAppContext, Partner, UsageModel, UserStore } from "@atman/business";
import { ClientsCountCardItem } from "./components/ClientsCountCardItem";
import { Col, Row } from "reactstrap";
import { ConsumptionEndDateCardItem } from "./components/ConsumptionEndDateCardItem";
import { ConsumptionRateCardItem } from "./components/ConsumptionRateCardItem";
import { EntityOverviewCard } from "./components/EntityOverviewCard";
import { LastSeenCardItem } from "./components/LastSeenCardItem";
import { ProfilesCountCardItem } from "./components/ProfilesCountCardItem";
import { UsersCountCardItem } from "./components/UsersCountCardItem";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../contexts";

export interface IEntityOverviewProps extends IAppContext {
    entity: Client | Partner;
    userStore?: UserStore;
}

@inject(GlobalStores!.userStore)
@observer
class EntityOverviewComp extends React.Component<IEntityOverviewProps, {}> {
    render() {
        const { entity, scope, userStore } = this.props;

        const activeScheduleEntry = new UsageModel(entity.usageModel).currentScheduleEntry;

        const cards: React.ReactNode[] = [];

        switch (scope) {
            case AppScope.Partner:
                const client = entity as Client;
                cards.push(
                    <ProfilesCountCardItem key={"ProfilesCountCardItem"} profilesCount={client.profilesCount || 0} />,
                );
                cards.push(
                    <ConsumptionRateCardItem
                        key={"ConsumptionRateCardItem"}
                        scheduleEntry={activeScheduleEntry}
                        profilesCount={(entity as Client).profilesCount || 0}
                    />,
                );
                break;
            case AppScope.Supplier:
                const partner = entity as Partner;
                cards.push(
                    <ClientsCountCardItem key={"ClientsCountCardItem"} clientsCount={partner.clientCount || 0} />,
                );
                cards.push(
                    <UsersCountCardItem key={"UsersCountCardItem"} usersCount={userStore!.entityUsers.length || 0} />,
                );
                break;
        }

        cards.push([
            <ConsumptionEndDateCardItem
                key={"ConsumptionEndDateCardItem"}
                consumptionEndDate={activeScheduleEntry ? activeScheduleEntry.endDate : undefined}
            />,
            <LastSeenCardItem key={"LastSeenCardItem"} lastSeen={entity.lastSeen} />,
        ]);

        return (
            <Row className="EntityOverview" noGutters>
                <Col sm={4}>
                    <div className="cards">{cards}</div>
                </Col>
                <Col sm={8} className="pl-4">
                    <EntityOverviewCard entity={entity} />
                </Col>
            </Row>
        );
    }
}

const EntityOverview = withAppContext(EntityOverviewComp);

export { EntityOverview };
