import * as React from "react";
import {
    AppScope,
    CompetencyProfile,
    ContentStore,
    GlobalStores,
    ItemType,
    Job,
    JobStore,
    ModalStore,
    ModalTypes,
    UrlFormatter,
} from "@atman/business";
import { AtCustomizableCard, IAtCustomizablePropertyProps } from "../../../../components/AtCustomizableCard";
import { CustomDeleteAlert } from "../../../../components/CustomDeleteAlert";
import { DropdownItem } from "reactstrap";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useAppContext } from "../../../../contexts";
import { useHistory } from "react-router";

export interface IJobCardProps {
    job: Job;
    contentStore?: ContentStore;
    modalStore?: ModalStore;
    jobStore?: JobStore;
}

export const JobCard: React.FC<IJobCardProps> = inject(
    GlobalStores.contentStore,
    GlobalStores.modalStore,
    GlobalStores.jobStore,
)(
    observer((props) => {
        const { job, contentStore, modalStore, jobStore } = props;

        const { scope } = useAppContext();
        const history = useHistory();

        const ctaAction = () => history.push(UrlFormatter.formatReactPath(`/Settings/Jobs/${job.id}`));
        const openAssignmentModal = () =>
            modalStore!.openModal(ModalTypes.AssignJobToEmployees, { competencySetId: job.id });
        const duplicateAction = () =>
            history.push(UrlFormatter.formatReactPath("/Settings/Jobs/Create"), { duplicatedId: job.id });
        const archiveAction = () =>
            new CustomDeleteAlert(ItemType.Job, job.name, () => jobStore!.deleteItem(job.id)).fire();

        const topProperties: IAtCustomizablePropertyProps[] = [
            {
                label: t({ id: "global.competencyCount" }),
                value: job.competencyIds.length,
            },
        ];

        if (scope === AppScope.Client) {
            topProperties.push({
                label: t({ id: "global.employeesCount" }),
                value: job.candidatesCount,
            });
        }

        const menuActions = (
            <>
                <DropdownItem onClick={duplicateAction}>{t({ id: "global.buttons.labels.duplicate" })}</DropdownItem>
                {scope === AppScope.Client && (
                    <DropdownItem onClick={openAssignmentModal}>{t({ id: "global.assignToEmployees" })};</DropdownItem>
                )}
                <DropdownItem onClick={archiveAction} disabled={job.candidatesCount > 0}>
                    {t({ id: "global.archive" })}
                </DropdownItem>
            </>
        );

        const department = job.departmentId ? contentStore!.getDepartmentById(job.departmentId) : undefined;

        return (
            <AtCustomizableCard
                title={job.name}
                subtitle={
                    scope === AppScope.Client
                        ? department?.name
                        : t({ id: `global.competencyProfiles.${CompetencyProfile[job.profile!].toCamel()}` })
                }
                ctaAction={ctaAction}
                ctaLabel={t({ id: "global.edit" })}
                topProperties={topProperties}
                menuActions={menuActions}
                className={"JobCard"}
                inverseTitleAndSubtitle
            />
        );
    }),
);
