import { Colors } from "../../../styles/variables/colors";
import { DayPicker, DayPickerMultipleProps, DayPickerRangeProps, DayPickerSingleProps } from "react-day-picker";
import { Sizing } from "../../../styles/variables/sizing";
import { Typography } from "../../../styles/variables/typography";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export const AtDatePickerBase: React.FC<DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps> = observer(
    (props) => {
        const {
            mode,
            selected,
            onSelect,
            className,
            showOutsideDays = true,
            captionLayout = "dropdown",
            styles,
            modifiersStyles,
            style,
            ...otherProps
        } = props;

        return (
            <DayPicker
                mode={mode}
                selected={selected}
                // @ts-ignore
                onSelect={onSelect}
                showOutsideDays={showOutsideDays}
                captionLayout={captionLayout}
                style={{
                    ...style,
                    fontFamily: "Quicksand",
                }}
                styles={{
                    ...styles,
                    day: {
                        fontSize: Typography.fontSizes.md,
                        fontWeight: 600,
                        borderRadius: "6px",
                        margin: "1px",
                    },
                    head_cell: {
                        fontSize: Typography.fontSizes.xs,
                        fontWeight: 700,
                        color: Colors.greyShades.shade5,
                    },
                    caption_label: {
                        fontSize: Typography.fontSizes.lg,
                        fontWeight: 600,
                        color: Colors.greyBase,
                    },
                    dropdown_month: {
                        marginRight: Sizing.pixels.md,
                    },
                    caption_dropdowns: {
                        fontFamily: "Quicksand",
                        fontSize: Typography.fontSizes.md,
                        fontWeight: 600,
                        color: Colors.greyBase,
                    },
                }}
                // @ts-ignore
                modifiersStyles={{
                    ...modifiersStyles,
                    range_start: {
                        backgroundColor: Colors.primaryShades.shade1,
                    },
                    range_middle: {
                        backgroundColor: Colors.primaryShades.shade9,
                        color: Colors.greyBase,
                    },
                    range_end: {
                        backgroundColor: Colors.primaryShades.shade1,
                    },
                    outside: {
                        color: Colors.greyShades.shade6,
                    },
                    disabled: {
                        color: Colors.greyShades.shade6,
                    },
                }}
                className={cn("AtDatePickerBase", className)}
                {...otherProps}
            />
        );
    },
);
