import "./index.less";
import * as React from "react";
import { BackgroundColorProperty, ColorProperty } from "csstype";
import { ExtraAndDefaultSizes, InitialsAvatar, XXL } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IReportCandidateLegendItemProps {
    candidateDisplayName: string | string[];
    textColor?: ColorProperty;
    bgColor?: BackgroundColorProperty;
    size?: ExtraAndDefaultSizes | XXL;
    onClose?: () => void;
}
export const ReportCandidateLegendItem: React.FC<IReportCandidateLegendItemProps> = ({
    candidateDisplayName,
    textColor,
    bgColor,
    size = "xs",
    onClose,
}: IReportCandidateLegendItemProps) => {
    return (
        <div className="ReportLegendItem ReportCandidateLegendItem">
            <div className="legend-icon candidate-icon">
                <InitialsAvatar text={candidateDisplayName} bgColor={bgColor} textColor={textColor} size={size} />
            </div>
            <span className="legend-text">{candidateDisplayName}</span>
            {onClose && (
                <div className="legend-close-icon" onClick={onClose}>
                    <FontAwesomeIcon icon="times" size="xs" />
                </div>
            )}
        </div>
    );
};
