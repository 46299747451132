import "./index.less";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { GlobalStores, Role, RouteWithSubRoutes, UserInfoStore } from "@atman/business";
import { IconPlacement, TabNavItem } from "./components/TabNavItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Redirect, RouteComponentProps, Switch, withRouter } from "react-router";
import { TabNav } from "./components/TabNav";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAtNewTabNavigationLayoutProps extends RouteComponentProps<{ activeView: string }> {
    navigationItems: IAtNewTabNavigationItem[];
    className?: string;
    id?: string;
    userInfoStore?: UserInfoStore;
    headerView?: JSX.Element;
    isContainerized?: boolean;
    isHiddenTabs?: boolean;
}

@inject(GlobalStores.userInfoStore)
@observer
class AtNewTabNavigationLayoutComp extends BaseResponsiveComponent<IAtNewTabNavigationLayoutProps> {
    @computed protected get rootElementClassName(): string | undefined {
        const { className } = this.props;

        return cn("AtNewTabNavigationLayout", className);
    }

    @computed protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    @computed protected get defaultRoute(): IAtNewTabNavigationItem {
        const defaultRoute = this.authorizedNavigationItems.find((i) => i.default);

        if (defaultRoute) {
            return defaultRoute;
        } else {
            return this.authorizedNavigationItems[0];
        }
    }

    @computed protected get authorizedNavigationItems(): IAtNewTabNavigationItem[] {
        return this.props.navigationItems.filter((x) => {
            if (!x.restrictedToRole) {
                return true;
            }

            return this.props.userInfoStore!.hasOneOfRoles(x.restrictedToRole);
        });
    }

    renderAll() {
        const {
            headerView,
            match: { path, url },
            isContainerized = false,
            isHiddenTabs = false,
        } = this.props;

        const { authorizedNavigationItems, defaultRoute } = this;

        if (this.authorizedNavigationItems.length === 0) {
            console.warn(`[WARNING] No navigation items were found to render.`);
            return null;
        }

        return (
            <>
                <TabNav
                    isContainerized={isContainerized}
                    headerView={headerView}
                    authorizedNavigationItems={authorizedNavigationItems}
                    url={url}
                    isHiddenTabs={isHiddenTabs}
                />

                <div className="content">
                    <Switch>
                        {authorizedNavigationItems.map((x) => (
                            <RouteWithSubRoutes
                                exact={x.exactMatch !== undefined ? x.exactMatch : true}
                                path={`${path}${x.hrefToMatch ?? x.href}`}
                                key={x.href}
                            >
                                {x.component}
                            </RouteWithSubRoutes>
                        ))}

                        <Redirect exact from={path} to={`${path}${defaultRoute.href}`} />
                    </Switch>
                </div>
            </>
        );
    }
}

export interface IAtNewTabNavigationItem {
    name: string;
    href: string;
    hrefToMatch?: string;
    exactMatch?: boolean;
    default?: boolean;
    component: React.ReactNode;
    restrictedToRole?: Role[];
    iconName?: IconProp;
    iconPlacement?: IconPlacement;
    disabled?: boolean;
    external?: boolean;
    onClick?: () => void;
}

const AtNewTabNavigationLayout = withRouter(AtNewTabNavigationLayoutComp);

export { AtNewTabNavigationLayout, TabNavItem };
