import * as qs from "qs";
import { ApiManager, ReactAppSettings, UrlFormatter } from "../libs";
import {
    AssessmentAuthMethod,
    CompetencyProfile,
    HttpMethodEnum,
    IAddProductConsumptionModeScheduleEntryModel,
    IContactInformation,
    IProductBalanceCreditCardRequestModel,
    IProductBalanceRequestModel,
    IProductConsumptionModeScheduleEntryModel,
    IRemoveProductConsumptionModeScheduleEntryModel,
    IStripeCustomerCard,
    ITransactionLogFilters,
    IUpdateBillingAddressCommand,
    IUpdateClientAdditionalInformationModel,
    IUpdateProductConsumptionModeScheduleEntryModel,
    ProductCodeProEnum,
} from "../types";
import { AxiosRequestConfig } from "axios";
import { BaseApi, DataType } from "../base";
import {
    IClient,
    IClientConsumptionSettings,
    IClientGeneralSettings,
    IClientInput,
    IClientMarketingInfo,
    IClientToggledFeatures,
    ICustomTermsAndConditions,
    IOrganizationCustomTermsConditions,
    IUpdateClientCompetencyDevelopmentSettings,
    Product,
    ShortClient,
    Transaction,
    UsageModel,
    User,
} from "../models";
import { IMapEntries } from "mobx";
import { IProductDefinition } from "../appLogic/ProductDefinition";

export class ClientsApi extends BaseApi {
    public static async getClients() {
        return await this.sendApiRequest<IClient[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getClients);
    }

    public static async getClient(id: string) {
        return await this.sendApiRequest<IClient>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClient,
            undefined,
            id,
        );
    }

    public static async createClient(client: IClientInput) {
        return await this.sendApiRequestWithResult<IClientInput, IClient>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createClient,
            client,
        );
    }

    public static async updateClientName(id: string, newName: string) {
        return await this.sendApiRequestWithResult<{ newName: string }, IClient>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientName,
            { newName },
            id,
        );
    }

    public static async updateClientRequiredLanguages(id: string, languages: string[]) {
        const data = {
            languages,
        };

        return await this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientRequiredLanguages,
            data,
            id,
        );
    }

    public static async updateClientAdditionalInformation(id: string, model: IUpdateClientAdditionalInformationModel) {
        return await this.sendApiRequestWithResult<IUpdateClientAdditionalInformationModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientAdditionalInformation,
            model,
            id,
        );
    }

    public static async updateClientBillingInformation(id: string, billingInformation: IUpdateBillingAddressCommand) {
        return await this.sendApiRequestWithResult<IUpdateBillingAddressCommand, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientBillingInformation,
            billingInformation,
            id,
        );
    }

    public static async updateClientGeneralSettings(id: string, generalSettings: IClientGeneralSettings) {
        return await this.sendApiRequestWithResult<IClientGeneralSettings, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientGeneralSettings,
            generalSettings,
            id,
        );
    }

    public static async updateClientMarketingInfo(id: string, marketingInfo: IClientMarketingInfo) {
        return await this.sendApiRequestWithResult<IClientMarketingInfo, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientMarketingInfo,
            marketingInfo,
            id,
        );
    }

    public static async updateClientConsumptionSettings(id: string, consumptionSettings: IClientConsumptionSettings) {
        return await this.sendApiRequestWithResult<IClientConsumptionSettings, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientConsumptionSettings,
            consumptionSettings,
            id,
        );
    }

    public static async deleteClient(id: string) {
        return await this.sendApiRequest<null>(
            HttpMethodEnum.DELETE,
            ReactAppSettings.appUrls.deleteClient,
            undefined,
            id,
        );
    }

    public static async getClientUsers(id: string) {
        return this.sendApiRequestWithResult<null, User[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientUsers,
            undefined,
            id,
        );
    }

    public static async getClientTransactions(
        id: string,
        filters?: ITransactionLogFilters,
        pageSize: number = 100,
        offset: number = 0,
        sortDirection: "asc" | "desc" = "desc",
    ) {
        const data = {
            ...filters,
            pageSize,
            offset,
            sortDirection,
        };

        return await this.sendApiRequestWithResult<typeof data, Transaction[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientTransactionLog,
            data,
            id,
            DataType.QueryString,
        );
    }

    public static async getClientTransactionsExcel(id: string, filters?: ITransactionLogFilters) {
        const apiCall = ApiManager.get(UrlFormatter.format(ReactAppSettings.appUrls.getClientTransactionLogExcel, id), {
            responseType: "blob",
            timeout: 300000,
            params: filters,
            paramsSerializer: (params) => qs.stringify(params),
        });

        this.downloadFile("Transactions.xlsx", apiCall);
    }

    public static async updateProductRestrictions(id: string, productCodes: ProductCodeProEnum[]) {
        return await this.sendApiRequestWithResult<ProductCodeProEnum[], null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientProductRestrictions,
            productCodes,
            id,
        );
    }

    public static async getUsageModel(id: string) {
        return await this.sendApiRequestWithResult<null, UsageModel>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientUsageModel,
            undefined,
            id,
        );
    }

    public static async getManagerContactInfo(id: string) {
        return await this.sendApiRequestWithResult<null, IContactInformation>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientManagerContactInfo,
            undefined,
            id,
        );
    }

    public static async creditProductBalance(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.creditClientProductBalance,
            model,
            id,
        );
    }

    public static async debitProductBalance(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.debitClientProductBalance,
            model,
            id,
        );
    }

    public static async purchaseProductsByInvoicing(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.purchaseClientProductsWithInvoicing,
            model,
            id,
        );
    }

    public static async purchaseProductsByCreditCard(id: string, model: IProductBalanceCreditCardRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceCreditCardRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.purchaseClientProductsWithCreditCard,
            model,
            id,
        );
    }

    public static async addClientConsumptionScheduleEntry(
        id: string,
        model: IAddProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<
            IAddProductConsumptionModeScheduleEntryModel,
            IProductConsumptionModeScheduleEntryModel
        >(HttpMethodEnum.POST, ReactAppSettings.appUrls.addClientConsumptionScheduleEntry, model, id);
    }

    public static async updateClientConsumptionScheduleEntry(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientConsumptionScheduleEntry,
            model,
            id,
        );
    }

    public static async removeClientConsumptionScheduleEntry(
        id: string,
        model: IRemoveProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IRemoveProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.removeClientConsumptionScheduleEntry,
            model,
            id,
        );
    }

    public static async updateClientConsumptionScheduleEntryStartDate(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientConsumptionScheduleEntryStartDate,
            model,
            id,
        );
    }

    public static async updateClientConsumptionScheduleEntryEndDate(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientConsumptionScheduleEntryEndDate,
            model,
            id,
        );
    }

    public static async updateClientConsumptionScheduleEntryMode(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientConsumptionScheduleEntryMode,
            model,
            id,
        );
    }

    public static async getAvailableProducts(id: string) {
        return await this.sendApiRequestWithResult<null, Product[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientAvailableProducts,
            undefined,
            id,
        );
    }

    public static async getAvailableProductDefinitions(id: string) {
        return await this.sendApiRequestWithResult<null, IProductDefinition[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientAvailableProductDefinitions,
            undefined,
            id,
        );
    }

    public static async getStripeCustomer(id: string) {
        return await this.sendApiRequestWithResult<
            null,
            { cards: IStripeCustomerCard[]; mostRecentlyUsedCard: IStripeCustomerCard }
        >(HttpMethodEnum.GET, ReactAppSettings.appUrls.getClientCustomerCards, undefined, id);
    }

    public static async addCustomerCard(id: string, cardToken: string, cardLabel: string) {
        return await this.sendApiRequestWithResult<{ cardToken: string; cardLabel: string }, IStripeCustomerCard>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.addClientCustomerCard,
            { cardToken, cardLabel },
            id,
        );
    }

    public static async updateCustomerCard(id: string, card: IStripeCustomerCard) {
        return await this.sendApiRequestWithResult<IStripeCustomerCard, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientCustomerCard,
            card,
            id,
        );
    }

    public static async deleteCustomerCard(id: string, cardId: string) {
        return await this.sendApiRequestWithResult<{ data: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.deleteClientCustomerCard,
            { data: cardId },
            id,
        );
    }

    public static async searchClients(
        searchString: string,
        partnerId?: string,
        offset: number = 0,
        fetchSize: number = 100,
    ) {
        const extraAxiosOptions = {
            params: {
                searchString: searchString ?? "",
                partnerId,
                offset,
                fetchSize,
            },
            paramsSerializer: (params) => qs.stringify(params),
        } as AxiosRequestConfig;

        return await this.sendApiRequest<ShortClient[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchClients,
            undefined,
            undefined,
            DataType.Json,
            extraAxiosOptions,
        );
    }

    public static async toggleFeatures(id: string, features: IClientToggledFeatures) {
        return await this.sendApiRequestWithResult<IClientToggledFeatures, null>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.toggleClientFeatures,
            features,
            id,
        );
    }

    public static async transferCandidate(id: string, candidateProId: string, newClientId: string) {
        const data = {
            candidateProId,
            newClientId,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.transferCandidate,
            data,
            id,
        );
    }

    public static async getMappings(id: string) {
        return await this.sendApiRequestWithResult<null, Dictionary<CompetencyProfile, string>>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getClientPerformanceEvaluationMappings,
            null,
            id,
        );
    }

    public static async updateMappings(id: string, mappings: Dictionary<CompetencyProfile, string>) {
        const data = {
            statementsVersion: null,
            competencyProfilePerformanceEvaluationTemplateIds: mappings,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientPerformanceEvaluationMappings,
            data,
            id,
        );
    }

    public static async assignCultureToClient(id: string, cultureId: string): Promise<void> {
        const data = {
            data: cultureId,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignCultureToClient,
            data,
            id,
        );
    }

    public static async unassignCultureFromClient(id: string, cultureId: string): Promise<void> {
        const data = {
            data: cultureId,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.unassignCultureFromClient,
            data,
            id,
        );
    }

    public static async updateClientCompetencyDevelopmentSettings(
        id: string,
        clientCompetencyDevelopmentSettings: IUpdateClientCompetencyDevelopmentSettings,
    ): Promise<void> {
        return this.sendApiRequestWithResult<IUpdateClientCompetencyDevelopmentSettings, void>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateClientCompetencyDevelopmentSettings,
            clientCompetencyDevelopmentSettings,
            id,
        );
    }

    public static updateClientAssessmentAuthMethodOverride(
        id: string,
        assessmentAuthMethodOverride: AssessmentAuthMethod | null,
    ): Promise<void> {
        const data = {
            data: assessmentAuthMethodOverride,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateClientAssessmentAuthMethodOverride,
            data,
            id,
        );
    }

    public static updateClientStylesGroupOverride(
        id: string,
        stylesGroupId: string,
        styleIds: string[],
    ): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateClientStylesGroupOverride,
            { stylesGroupId, styleIds },
            id,
        );
    }

    public static async activateClientCustomTermsConditions(id: string): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.activateClientCustomTermsConditions,
            null,
            id,
        );
    }

    public static async deactivateClientCustomTermsConditions(id: string): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.deactivateClientCustomTermsConditions,
            null,
            id,
        );
    }

    public static async getOrganizationCustomTermsConditions(): Promise<IOrganizationCustomTermsConditions> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getOrganizationCustomTermsConditions,
            null,
        );
    }

    public static async updateOrganizationCustomTermsConditions(
        titleEntries: IMapEntries<string, string>,
        descriptionEntries: IMapEntries<string, string>,
    ): Promise<ICustomTermsAndConditions> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateOrganizationCustomTermsConditions,
            {
                title: titleEntries,
                description: descriptionEntries,
            },
        );
    }
}
