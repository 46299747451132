import "./index.less";
import * as React from "react";
import { AtTable, AtTableColumn, IAtButtonProps, TableItemHeading } from "@atman/design-system";
import { BaseForm } from "../../components/BaseForm";
import { ContentStore, GlobalStores, Job, JobStore, ModalStore, UrlFormatter } from "@atman/business";
import { IRoutedAppContext, withRoutedAppContext } from "../../..";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { JobCard } from "./components/JobCard";
import { autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import getJobsTableColumns from "./JobsContainerColumns";

export interface IJobsContainerProps extends IRoutedAppContext {
    jobStore?: JobStore;
    modalStore?: ModalStore;
    contentStore?: ContentStore;
    icon?: IconProp;
}

@inject(GlobalStores!.jobStore, GlobalStores.modalStore, GlobalStores.contentStore)
@observer
class JobsContainerImpl extends BaseForm<IJobsContainerProps> {
    @observable private searchString: string = "";
    @observable private filteredJobs: Job[] = [];

    constructor(props) {
        super(props);

        autorun(() => {
            this.filteredJobs = this._filteredJobs;
        });
    }

    @computed private get _filteredJobs(): Job[] {
        const { jobStore } = this.props;

        if (!this.searchString) {
            return jobStore!.items;
        }

        return jobStore!.items.filter(
            (x) =>
                x.name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                x.description?.toLowerCase().includes(this.searchString.toLowerCase()),
        );
    }

    render() {
        const { history, jobStore, modalStore, contentStore, scope, icon } = this.props;

        const duplicateAction = (id: string) =>
            history.push(UrlFormatter.formatReactPath("/Settings/Jobs/Create"), { duplicatedId: id });

        const editAction = (id: string) => history.push(UrlFormatter.formatReactPath(`/Settings/Jobs/${id}`));

        const columns: AtTableColumn<Job>[] = getJobsTableColumns(
            scope,
            modalStore,
            jobStore,
            contentStore,
            duplicateAction,
            editAction,
            this.filteredJobs,
        );

        const buttons: IAtButtonProps[] = [
            {
                children: t({ id: "global.createJob" }),
                onClick: () => history.push(UrlFormatter.formatReactPath(`/Settings/Jobs/Create`)),
                color: "primary",
            },
        ];

        return (
            <div id="JobsContainer">
                <TableItemHeading
                    title={t({ id: "global.jobs" })}
                    description={t({ id: "global.jobs.description" })}
                    buttons={buttons}
                    icon={icon}
                />
                <div className="cards-container">
                    <AtTable<Job>
                        enableCardDisplay
                        cardContentRender={(record) => <JobCard job={record} key={record.id} />}
                        columns={columns}
                        items={this.filteredJobs}
                        refreshTable={jobStore!.loadItems}
                        isLoading={jobStore!.isLoading}
                    />
                </div>
            </div>
        );
    }
}

export const JobsContainer = withRoutedAppContext(JobsContainerImpl);
