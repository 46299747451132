import "./index.less";
import * as React from "react";
import {
    AssessmentStructure,
    CompDevSubCompetencyResult,
    CompetencyDevelopmentProcessResultsData,
} from "@atman/business";
import { AtButton, AtCollapse, AtTitle, Colors } from "@atman/design-system";
import { BaseResponsiveComponent, Default, Mobile } from "../../../../../../..";
import { CompDevAdjustmentModal } from "../../AdjustmentModal";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../../..";
import { DetailedObservableBehaviorSubRow } from "../DetailedObservableBehaviorSubRow";
import { DetailedScoreColumns } from "../../TableRow/components/DetailedScoreColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader } from "reactstrap";
import { action, observable } from "mobx";
import { observer } from "mobx-react";

export interface IDetailedSubCompetencySubRow {
    parentCompetencyId: string;
    subCompetency: CompDevSubCompetencyResult;
    assessmentStructure?: AssessmentStructure;
    processResultsData: CompetencyDevelopmentProcessResultsData;
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    isReadOnly: boolean;
    columns: CompetencyDevelopmentColumnType[];
    showRank?: boolean;
    hiddenColumns?: CompetencyDevelopmentColumnType[];
    saveAssessmentFunction?: (
        competencyId: string,
        observableBehaviorScores: Map<string, number | undefined>,
        observableBehaviorNotes: Map<string, string | undefined>,
    ) => Promise<void>;
}

@observer
export class DetailedSubCompetencySubRow extends BaseResponsiveComponent<IDetailedSubCompetencySubRow> {
    @observable isExpanded: boolean = false;
    @observable isAdjustmentModalOpen: boolean = false;

    @action toggleExpansion = (event?: React.SyntheticEvent<HTMLDivElement>) => {
        const target: HTMLElement = event?.target as HTMLElement;

        if (target?.className?.includes("AtButton") || target?.parentElement?.className?.includes("AtButton")) {
            return;
        }

        this.isExpanded = !this.isExpanded;
    };

    toggleAdjustmentModal = () => {
        this.isAdjustmentModalOpen = !this.isAdjustmentModalOpen;
    };

    renderToggleRow() {
        const {
            subCompetency,
            isReadOnly,
            managerInfo,
            employeeInfo,
            showRank = true,
            columns,
            hiddenColumns,
        } = this.props;

        return (
            <>
                <Mobile>
                    <div className="chevron-container">
                        <FontAwesomeIcon icon={["far", this.isExpanded ? "chevron-up" : "chevron-down"]} />
                    </div>
                    <div className="sub-competency-content-container">
                        <div className="competency-col content-col">
                            <div className="left-container">
                                <AtTitle
                                    className="sub-competency-title"
                                    headingType={4}
                                    title={`${showRank ? subCompetency.hierarchicalRank : ""} ${
                                        subCompetency.name
                                    }`.trim()}
                                />
                            </div>
                            <div className="right-container">
                                {!isReadOnly && (
                                    <AtButton
                                        color={"secondary"}
                                        size={"md"}
                                        icon={"pen"}
                                        iconProps={{ color: Colors.primaryBase, size: "xs" }}
                                        onClick={this.toggleAdjustmentModal}
                                    >
                                        {"competencyDevelopment.review.buttons.resultAdjustment".localize()}
                                    </AtButton>
                                )}
                            </div>
                        </div>
                        <DetailedScoreColumns
                            reviewScore={subCompetency.observableBehaviorsReviewAverage}
                            averageScore={subCompetency.observableBehaviorsAverage!}
                            employeeScore={subCompetency.observableBehaviorsEmployeeAverage}
                            managerScore={subCompetency.observableBehaviorsManagerAverage}
                            columns={columns}
                            averageScoreDisplayMode={"light"}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                        />
                    </div>
                </Mobile>
                <Default>
                    <div className="competency-col content-col">
                        <div className="left-container">
                            <div className="chevron-container">
                                <FontAwesomeIcon icon={["far", this.isExpanded ? "chevron-up" : "chevron-down"]} />
                            </div>
                            {showRank && (
                                <AtTitle
                                    headingType={5}
                                    className="sub-competency-rank"
                                    title={subCompetency.hierarchicalRank}
                                />
                            )}
                            <AtTitle headingType={5} className="sub-competency-name" title={subCompetency.name} />
                        </div>
                        <div className="right-container">
                            {!isReadOnly && (
                                <AtButton
                                    color="secondary"
                                    size="sm"
                                    icon="pencil"
                                    iconProps={{ size: "xs" }}
                                    onClick={this.toggleAdjustmentModal}
                                >
                                    {"competencyDevelopment.review.buttons.resultAdjustment".localize()}
                                </AtButton>
                            )}
                        </div>
                    </div>
                    <DetailedScoreColumns
                        reviewScore={subCompetency.observableBehaviorsReviewAverage}
                        averageScore={subCompetency.observableBehaviorsAverage!}
                        employeeScore={subCompetency.observableBehaviorsEmployeeAverage}
                        managerScore={subCompetency.observableBehaviorsManagerAverage}
                        columns={columns}
                        averageScoreDisplayMode="light"
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        hiddenColumns={hiddenColumns}
                    />
                </Default>
            </>
        );
    }

    render() {
        const {
            subCompetency,
            parentCompetencyId,
            assessmentStructure,
            processResultsData,
            managerInfo,
            employeeInfo,
            isReadOnly,
            hiddenColumns,
            saveAssessmentFunction,
        } = this.props;

        return (
            <>
                <AtCollapse
                    className="DetailedSubCompetencySubRow"
                    isOpen={this.isExpanded}
                    toggleCollapse={this.toggleExpansion}
                    toggleRowContent={this.renderToggleRow()}
                    toggleRowSize={this.isMobile ? "sm" : "xs"}
                    toggleRowSizeHorizontal="lg"
                    toggleRowClassName={"toggle-row"}
                    collapseClassName={"observable-behaviors-container"}
                    collapseContentOffset={true}
                    renderChevron={false}
                >
                    <div className={"observable-behaviors-label"}>
                        {"competencyDevelopment.global.observableBehaviors".localize()}
                    </div>
                    {subCompetency.observableBehaviors.map((x) => (
                        <DetailedObservableBehaviorSubRow
                            observableBehavior={x}
                            key={x.id}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                        />
                    ))}
                </AtCollapse>
                {!isReadOnly && assessmentStructure && saveAssessmentFunction && (
                    <Modal
                        isOpen={this.isAdjustmentModalOpen}
                        toggle={this.toggleAdjustmentModal}
                        modalClassName="BaseModalContainer modal-right-pane"
                        wrapClassName="modal-wrapper"
                        contentClassName="modal-content"
                        backdropClassName="modal-backdrop"
                    >
                        <ModalHeader toggle={this.toggleAdjustmentModal}>
                            {"competencyDevelopment.review.adjustmentModalTitle".localize()}
                        </ModalHeader>
                        <CompDevAdjustmentModal
                            subCompetency={subCompetency}
                            competencyId={parentCompetencyId}
                            toggleModal={this.toggleAdjustmentModal}
                            assessmentStructure={assessmentStructure}
                            processResultsData={processResultsData}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            saveAssessmentFunction={saveAssessmentFunction}
                        />
                    </Modal>
                )}
            </>
        );
    }
}
