import { ApiManager, ErrorHandler, ReactAppSettings, UrlFormatter } from "../libs";

export class PreferencesApi {
    static async updatePreferences(
        firstName: string,
        lastName: string,
        communicationEmail: string,
        languageCode: string,
    ) {
        return ApiManager.post<{ requiresPageReload: boolean }>(
            UrlFormatter.format(
                ReactAppSettings.appUrls.updateUserPreferences,
                ReactAppSettings.viewBag.baseInfo.userInfo.userId,
            ),
            {
                firstName,
                lastName,
                communicationEmail,
                languageCode,
            },
        )
            .then(({ data: { requiresPageReload } }) => {
                if (requiresPageReload) {
                    window.location.reload();
                }
            })
            .catch((e) => {
                ErrorHandler.handleApiError(e);

                throw e;
            });
    }

    static async updateCandidatePreferences(
        firstName: string,
        lastName: string,
        communicationEmail: string,
        languageCode: string,
    ) {
        return ApiManager.post<{ requiresPageReload: boolean }>(
            UrlFormatter.format(
                ReactAppSettings.appUrls.updateCandidatePreferences,
                ReactAppSettings.viewBag.baseInfo.candidateProId!,
            ),
            {
                firstName,
                lastName,
                communicationEmail,
                languageCode,
            },
        ).then(({ data: { requiresPageReload } }) => {
            if (requiresPageReload) {
                window.location.reload();
            }
        });
    }

    static async changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
        return ApiManager.post(
            UrlFormatter.format(
                ReactAppSettings.appUrls.changePassword,
                ReactAppSettings.viewBag.baseInfo.userInfo.userId,
            ),
            {
                currentPassword,
                newPassword,
                confirmNewPassword,
            },
        );
    }
}
