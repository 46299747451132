import "./index.less";
import {
    BaseColors,
    DangerLight,
    DefaultComponentProps,
    DefaultSizes,
    Ghost,
    StatusAltColors,
    StatusColors,
    XS,
    XXS,
} from "../../../types";
import { Colors } from "../../../internal";
import { observer } from "mobx-react";
import React, { CSSProperties, useMemo } from "react";
import cn from "classnames";

export type AtBadgeColor = StatusColors | StatusAltColors | BaseColors | DangerLight | Ghost;
export type AtBadgeStyle = "outline" | "fill-light" | "fill-heavy" | "fill-medium";
export type AtBadgeSizes = XXS | XS | DefaultSizes;

export interface IAtBadgeProps extends DefaultComponentProps {
    size?: AtBadgeSizes;
    pill?: boolean;
    badgeStyle?: AtBadgeStyle;
    color?: AtBadgeColor;
    customColor?: Colors;
    bordered?: boolean;
}

export const AtBadge: React.FC<IAtBadgeProps> = observer((props) => {
    const {
        children,
        size = "md",
        color = "secondary",
        customColor,
        badgeStyle = "fill-light",
        className,
        pill,
        bordered = false,
        ...otherProps
    } = props;

    const outline = useMemo(() => {
        switch (badgeStyle) {
            case "outline":
                return "outline";
            case "fill-light":
            case "fill-medium":
            case "fill-heavy":
                return `fill ${badgeStyle}`;
            default:
                return undefined;
        }
    }, [badgeStyle]);

    const customColorStyle: CSSProperties = useMemo(() => {
        if (customColor) {
            return { backgroundColor: customColor as string };
        } else {
            return {};
        }
    }, [customColor]);

    return (
        <div
            className={cn("AtBadge", `size-${size}`, `color-${color}`, outline, className, {
                pill: pill,
                bordered: bordered,
            })}
            style={customColorStyle}
            {...otherProps}
        >
            {children}
        </div>
    );
});
