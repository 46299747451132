import { IMapEntries } from "mobx";

export type StyleId = string;
export type StyleScore = number;

export interface IAtStylesCandidateResult {
    readonly candidateProId: string;
    readonly stylesGroupId: string;
    readonly dominantStyleId: string;
    readonly secondaryStyleIds: string[];
    readonly scoresDistribution: IMapEntries<StyleId, StyleScore>;
}

export class AtStylesCandidateResult {
    public readonly candidateProId: string;
    public readonly stylesGroupId: string;
    public readonly dominantStyleId: string;
    public readonly secondaryStyleIds: string[];
    public readonly scoresDistribution: Map<StyleId, StyleScore>;

    constructor(json: IAtStylesCandidateResult) {
        this.candidateProId = json.candidateProId;
        this.stylesGroupId = json.stylesGroupId;
        this.dominantStyleId = json.dominantStyleId;
        this.secondaryStyleIds = json.secondaryStyleIds;
        this.scoresDistribution = new Map<StyleId, StyleScore>(json.scoresDistribution);
    }
}
