import { AngleBasedPie } from "./AngleBasedPie";
import { ColorProperty } from "csstype";
import { Colors } from "../../../../styles/variables/colors";
import { HALFPIE_ANGLERAGNE, HALFPIE_STARTANGLE } from "./constants";
import { IRange } from "@atman/business";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IHalfMeterRangeHighlightProps {
    range?: IRange; // Min/Max of the range to highlight
    maxValue: number; // Max possible value
    color: ColorProperty; // Background color of the pie
    strokeColor: ColorProperty; // Border color of the pie
}

@inject()
@observer
export class HalfMeterRangeHighlight extends React.Component<IHalfMeterRangeHighlightProps> {
    public static defaultProps = {
        color: Colors.greyShades.shade8,
        strokeColor: Colors.greyShades.shade7,
    };

    render() {
        const { range, maxValue, color, strokeColor } = this.props;

        if (!range) {
            return null;
        }

        const rangeStartAngle = (range.min / maxValue) * HALFPIE_ANGLERAGNE + HALFPIE_STARTANGLE;
        const rangeEndAngle = (range.max / maxValue) * HALFPIE_ANGLERAGNE + HALFPIE_STARTANGLE;

        return (
            <AngleBasedPie
                startAngle={rangeStartAngle}
                endAngle={rangeEndAngle}
                color={color}
                strokeColor={strokeColor}
                highlightMode
            />
        );
    }
}
