import { AtStylesCandidateResult, IAtStylesCandidateResult } from "./AtStylesCandidateResult";
import {IMapEntries} from "mobx";

export interface IAtStylesTeamResult {
    readonly stylesGroupId: string;
    readonly teamDominantStyleId: string;
    readonly managerDominantStyleId: string;
    readonly managerSecondaryStyleIds: string[];
    readonly memberResults: IAtStylesCandidateResult[];
    readonly teamStyleDistribution: IMapEntries<string, number>;
}

export class AtStylesTeamResult {
    public readonly stylesGroupId: string;
    public readonly teamDominantStyleId: string;
    public readonly managerDominantStyleId: string;
    public readonly managerSecondaryStyleIds: string[];
    public readonly memberResults: AtStylesCandidateResult[];
    public readonly teamStyleDistribution: Map<string, number>;

    constructor(json: IAtStylesTeamResult) {
        this.stylesGroupId = json.stylesGroupId;
        this.teamDominantStyleId = json.teamDominantStyleId;
        this.managerDominantStyleId = json.managerDominantStyleId;
        this.managerSecondaryStyleIds = json.managerSecondaryStyleIds;
        this.memberResults = json.memberResults.map((x) => new AtStylesCandidateResult(x));
        this.teamStyleDistribution = new Map<string, number>(json.teamStyleDistribution);
    }
}
