import "./index.less";
import * as React from "react";
import { AssessmentResultsCalculationService } from "@atman/business";
import { Col, Label, Row } from "reactstrap";
import { computed } from "mobx";

export interface IAtBipolarScaleLegendProps {
    maxScore: number;
}

/**
 * @deprecated
 */
export class AtBipolarScaleLegend extends React.Component<IAtBipolarScaleLegendProps> {
    @computed
    get legendElements() {
        const { maxScore } = this.props;

        const elementWidth = (1 / maxScore) * 100;
        const legendElements: ILegendElement[] = [
            {
                label: 1,
                width: elementWidth,
                left: 0,
            },
        ];

        for (let i = 1; i <= maxScore; i++) {
            if (i === 1) {
                continue;
            }

            legendElements.push({
                label: i,
                width: elementWidth,
                left: AssessmentResultsCalculationService.getScorePercentage(i, maxScore),
            });
        }

        return legendElements;
    }

    render() {
        const {} = this.props;

        return (
            <Row className="AtBipolarScaleLegend" noGutters>
                <Col sm={12} className={"legend-col"}>
                    {this.legendElements.map((x, i) => (
                        <Label key={i} className={"legend-label"} style={{ width: `${x.width}%`, left: `${x.left}%` }}>
                            {x.label}
                        </Label>
                    ))}
                </Col>
            </Row>
        );
    }
}

export interface ILegendElement {
    label: number | string;
    width: number;
    left: number;
}
