import "./index.less";
import { AtTitle } from "@atman/design-system";
import { ColorProperty } from "csstype";
import { Colors } from "../../../styles/variables/colors";
import {
    FitLevelEnum,
    GlobalStores,
    IRange,
    LocalizationStore,
    ReportType,
    ScaleMaxScore,
    getHelpCenterUrl,
    stanineFitLevelMapping,
} from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullMeterPie } from "./components/FullMeterPie";
import { PieChart } from "./components/PieChart";
import { ScoreDisplayType, TileType } from "@atman/business/lib/models/ScoreData";
import { ScoreLabel } from "./components/ScoreLabel";
import { ScoreSVGContainer } from "./components/ScoreSVGContainer";
import { ScoreSubTitle } from "./components/ScoreSubTitle";
import { ThreeQuaterMeterPie } from "./components/ThreeQuaterMeterPie";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";

export type ScoreColor = "red" | "yellow" | "green" | "blue";

export interface IAtScaleData {
    x: number;
    y: number;
    label: string;
    active: boolean;
}

export interface DistributionPieValue {
    label: string;
    value: number;
    color: ColorProperty;
}

export enum ScoreInfoSizing {
    auto,
    regular,
    compact,
}

export interface IScoreInfoProps {
    tileType?: TileType;
    scoreDisplayType?: ScoreDisplayType;
    containerWidth?: number; // Try using the sizing props before using this one in order to keep it standard.
    viewBoxWidth?: number;
    sizing?: ScoreInfoSizing;
    score?: number;
    range?: IRange; // Use this to highlight a certain region of the bar
    denominator?: number;
    showDenominator?: boolean;
    showOutOfXLabel?: boolean; // Ex.: "Out of 9"
    isScoreAnAverage?: boolean;
    isScoreComparedToAnAverage?: boolean;
    showInterpretation?: boolean; // The interpretation should probably be calculated outside of this meter component.
    showLabel?: boolean;
    isGradient?: boolean;
    distributionPieData?: DistributionPieValue[]; // Data for the DistributionPie mode
    useAlternativeColors?: boolean; // Changes the red/yellow/green color classes to the blue/green gradient variant
    localizationStore?: LocalizationStore;
    isInPrint?: boolean;
    reportType?: ReportType;
}

@inject(GlobalStores.localizationStore)
@observer
export class ScoreInfo extends React.Component<IScoreInfoProps> {
    public static defaultProps = {
        showDenominator: false,
        denominator: ScaleMaxScore.Stanine,
        sizing: ScoreInfoSizing.auto,
        isScoreComparedToAnAverage: false,
        showInterpretation: true,
        isGradient: false,
        isInPrint: false,
    };

    private readonly tileTypeScoreDisplayMapping: Map<TileType, ScoreDisplayType> = new Map<TileType, ScoreDisplayType>(
        [
            [TileType.Job, ScoreDisplayType.Percentage],
            [TileType.Culture, ScoreDisplayType.Percentage],
            [TileType.Potential, ScoreDisplayType.Percentage],
            [TileType.CompatibilityFit, ScoreDisplayType.Percentage],
            [TileType.Competencies, ScoreDisplayType.StanineHalfCircle],
        ],
    );

    private readonly colorHexMapping: Map<ScoreColor, ColorProperty> = new Map<ScoreColor, ColorProperty>([
        ["red", Colors.redBase],
        ["yellow", Colors.yellowBase],
        ["green", Colors.greenBase],
        ["blue", Colors.blueGreenGradient1],
    ]);

    private readonly percentageColorMapping: Map<IRange, ScoreColor> = new Map<IRange, ScoreColor>([
        [{ min: 0, max: 59.99 }, "red"],
        [{ min: 60, max: 74.99 }, "yellow"],
        [{ min: 75, max: 100 }, "green"],
    ]);

    private readonly stanineColorMapping: Map<IRange, ScoreColor> = new Map<IRange, ScoreColor>([
        [{ min: 0, max: 2.99 }, "red"],
        [{ min: 3, max: 5.99 }, "yellow"],
        [{ min: 6, max: 9 }, "green"],
    ]);

    private readonly stanineAverageLabelMapping: Map<IRange, string> = new Map<IRange, string>([
        [{ min: 0, max: 2.99 }, "underAverage"],
        [{ min: 3, max: 5.99 }, "inAverage"],
        [{ min: 6, max: 9 }, "aboveAverage"],
    ]);

    @computed get scoreType(): ScoreDisplayType {
        const { tileType, scoreDisplayType } = this.props;

        if (scoreDisplayType !== undefined) {
            return scoreDisplayType;
        }

        return tileType !== undefined
            ? this.tileTypeScoreDisplayMapping.get(tileType) ?? ScoreDisplayType.Percentage
            : ScoreDisplayType.Percentage;
    }

    @computed get scoreColor(): ScoreColor {
        const { score, isInPrint, reportType } = this.props;

        const defaultColor: ScoreColor = "red";

        if (score === null || score === undefined) {
            return defaultColor;
        }

        if (isInPrint && reportType === ReportType.Competencies) {
            return "green";
        }

        if (isInPrint && reportType === ReportType.Psychometrics) {
            return "blue";
        }

        const mappingToUse = [ScoreDisplayType.Stanine, ScoreDisplayType.StanineHalfCircle].includes(this.scoreType)
            ? this.stanineColorMapping
            : this.percentageColorMapping;

        for (const [key, value] of mappingToUse) {
            if (score! >= key.min && score! <= key.max) {
                return value;
            }
        }

        return defaultColor;
    }

    @computed get scoreColorHex(): string {
        const defaultValue = Colors.greyShades.shade9;
        const color = this.scoreColor;

        return this.colorHexMapping.get(color) ?? defaultValue;
    }

    @computed get fitLevelForScore(): FitLevelEnum {
        const { score } = this.props;
        let returnValue: FitLevelEnum = FitLevelEnum.VeryGood;

        stanineFitLevelMapping.forEach((value, key) => {
            if (score! >= key.min && score! <= key.max) {
                returnValue = value;
            }
        });

        return returnValue;
    }

    @computed get averageLabelForScore(): string {
        const { score } = this.props;
        let returnValue: string = "aboveAverage";

        this.stanineAverageLabelMapping.forEach((value, key) => {
            if (score! >= key.min && score! <= key.max) {
                returnValue = value;
            }
        });

        return returnValue;
    }

    @computed get roundedScore(): string | undefined {
        const { score } = this.props;

        let fractionDigits: number;

        switch (this.scoreType) {
            case ScoreDisplayType.Stanine:
            case ScoreDisplayType.StanineHalfCircle:
                fractionDigits = 1;
                break;
            default:
                fractionDigits = 0;
                break;
        }

        return score?.toFixed(fractionDigits);
    }

    @computed get hasScore(): boolean {
        const { score } = this.props;

        return score !== undefined && score !== null;
    }

    @computed get isScoreAvailable(): boolean {
        const { score } = this.props;

        return this.hasScore && score! >= 0;
    }

    @computed get percentageScoreToDisplay(): string | string[] {
        if (!this.hasScore) {
            return "";
        }

        if (!this.isScoreAvailable) {
            return "N/A";
        }

        return [this.roundedScore!, "%"];
    }

    @computed get numericScoreToDisplay(): string {
        if (!this.hasScore) {
            return "";
        }

        if (!this.isScoreAvailable) {
            return "N/A";
        }

        return this.roundedScore!;
    }

    @computed get percentageScoreToDisplayInPie(): string | string[] {
        let roundedScoreLabel = "-";
        if (this.hasScore && this.isScoreAvailable) {
            roundedScoreLabel = this.roundedScore!;
        }

        return [roundedScoreLabel, "%"];
    }

    @computed get numericScoreToDisplayInPie(): string[] {
        const { denominator, showDenominator } = this.props;
        let roundedScoreLabel = "-";
        if (this.hasScore && this.isScoreAvailable) {
            roundedScoreLabel = this.roundedScore!;
        }

        return showDenominator ? [roundedScoreLabel, " / ", String(denominator)] : [roundedScoreLabel];
    }

    openNonApplicationHelpCenterPage = async () => {
        const { localizationStore } = this.props;

        const helpCenterUrl = await getHelpCenterUrl("/articles/360055912712", localizationStore!.currentFullLocale);

        window.open(helpCenterUrl, "_blank");
    };

    renderNonApplicableComponent() {
        return (
            <div className={"na-score-container"}>
                <span>N/A</span>
                <a onClick={this.openNonApplicationHelpCenterPage}>
                    <FontAwesomeIcon icon={["far", "question-circle"]} color={Colors.greyColors.lighter4} />
                </a>
            </div>
        );
    }

    renderNumericHalfCircleScore() {
        const {
            score,
            showDenominator,
            showInterpretation,
            isScoreComparedToAnAverage,
            denominator,
            sizing,
            containerWidth,
            showLabel = true,
            range,
            showOutOfXLabel,
            isGradient,
            viewBoxWidth,
        } = this.props;

        if (!score) return null;

        let roundedScoreLabel = "-";
        if (this.isScoreAvailable) roundedScoreLabel = String(score ? Math.round(score * 10) / 10 : 0);

        let outOfXLabel: React.ReactNode | undefined;
        if (showOutOfXLabel) {
            outOfXLabel = <ScoreSubTitle text={"global.stanine".localize()} />;
        }

        let interpretationLabel: React.ReactNode | undefined;
        if (showInterpretation && showLabel) {
            const interpKey = isScoreComparedToAnAverage
                ? this.averageLabelForScore
                : FitLevelEnum[this.fitLevelForScore];

            interpretationLabel = <ScoreSubTitle text={interpKey.toCamel().localize()} />;
        }

        const scoreLabelText = showDenominator ? [roundedScoreLabel, " / ", String(denominator)] : [roundedScoreLabel];

        let gradientDef: JSX.Element | undefined = undefined;
        let color = this.scoreColorHex;
        if (isGradient) {
            const startColorStyle: React.CSSProperties = {
                stopColor: Colors.STANINE_SCORE_TO_GRADIENT_MAPPING.get(1),
                stopOpacity: 1,
            };
            const endColorStyle: React.CSSProperties = {
                stopColor: Colors.STANINE_SCORE_TO_GRADIENT_MAPPING.get(score),
                stopOpacity: 1,
            };

            gradientDef = (
                <defs>
                    <linearGradient id="halfCircleGradient">
                        <stop offset="0%" style={startColorStyle} />
                        <stop offset="85%" style={endColorStyle} />
                    </linearGradient>
                </defs>
            );

            color = "url(#halfCircleGradient)";
        }

        return (
            <>
                <ScoreSVGContainer
                    containerWidth={containerWidth}
                    sizing={sizing}
                    viewBoxWidth={viewBoxWidth}
                    isThreeQuater
                >
                    <ThreeQuaterMeterPie value={score} maxValue={denominator!} color={color} range={range} />

                    <ScoreLabel text={scoreLabelText} size={"regular"} />

                    {gradientDef}
                </ScoreSVGContainer>
                {outOfXLabel}
                {interpretationLabel}
            </>
        );
    }

    renderPercentageScore() {
        const { score, sizing, containerWidth, showLabel = true } = this.props;

        return (
            <>
                <ScoreSVGContainer containerWidth={containerWidth} sizing={sizing}>
                    <FullMeterPie value={score} maxValue={100} color={this.scoreColorHex} />
                    <ScoreLabel text={this.percentageScoreToDisplay} />
                </ScoreSVGContainer>

                {showLabel && (
                    <AtTitle className="percentage" headingType={6} title={"global.overallScore".localize()} />
                )}
            </>
        );
    }

    renderNumericScore() {
        const { useAlternativeColors, isScoreAnAverage = true, containerWidth = 82 } = this.props;

        return (
            <>
                <div
                    className={`score-stanine ${this.scoreColor} ${useAlternativeColors ? "color-alt" : ""} ${
                        this.hasScore ? "has-score" : ""
                    } ${isScoreAnAverage ? "is-average" : ""}`}
                    style={{ height: containerWidth, width: containerWidth }}
                >
                    <AtTitle className="value-container" headingType={1} title={this.numericScoreToDisplay} />
                </div>
            </>
        );
    }

    renderDistributionPieKit() {
        const { distributionPieData, sizing, containerWidth } = this.props;

        if (distributionPieData === undefined) return undefined;

        let scoreLabelCtl: React.ReactNode;
        if (this.scoreType === ScoreDisplayType.StanineHalfCircle || this.scoreType === ScoreDisplayType.Stanine)
            scoreLabelCtl = <ScoreLabel text={this.numericScoreToDisplayInPie} />;
        else if (this.scoreType === ScoreDisplayType.Percentage)
            scoreLabelCtl = <ScoreLabel text={this.percentageScoreToDisplayInPie} />;

        return (
            <>
                <ScoreSVGContainer containerWidth={containerWidth} sizing={sizing}>
                    <PieChart distributionPieData={distributionPieData} />
                    {scoreLabelCtl}
                </ScoreSVGContainer>
            </>
        );
    }

    render() {
        const { distributionPieData } = this.props;
        const distributionMode = distributionPieData !== undefined;
        let contentToRender: React.ReactNode;

        if (distributionMode) {
            contentToRender = this.renderDistributionPieKit();
        } else {
            if (this.hasScore && !this.isScoreAvailable) {
                contentToRender = this.renderNonApplicableComponent();
            } else {
                switch (this.scoreType) {
                    case ScoreDisplayType.Percentage:
                        contentToRender = this.renderPercentageScore();
                        break;
                    case ScoreDisplayType.Stanine:
                        contentToRender = this.renderNumericScore();
                        break;
                    case ScoreDisplayType.StanineHalfCircle:
                        contentToRender = this.renderNumericHalfCircleScore();
                        break;
                }
            }
        }

        return <div className="ScoreInfo">{contentToRender}</div>;
    }
}
