import "@atman/polyfills";
import "@atman/primitive-extensions";
import "@atman/ui-kit/lib/styles/libs/cogo-toast.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "core-js/stable";
import "moment/locale/fr";
import "regenerator-runtime/runtime";
import "sweetalert2/dist/sweetalert2.css";
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import "./index.less";
import * as React from "react";
import { ApplicationSetup, DefaultStores, LocalizationStore } from "@atman/business";
import { AuthStore } from "./app/data/stores/AuthStore";
import { Provider } from "mobx-react";
import { Router } from "react-router";
import { StandaloneI18nLoader } from "@atman/business/lib/kernel/localization/StandaloneI18nLoader";
import { SystemStateStore } from "./app/data/stores/SystemStateStore";
import { ViewportProvider } from "@atman/core";
import App from "./app/App";
import ReactDOM from "react-dom";

export const history = ApplicationSetup.setupHistory();

ApplicationSetup.setupFontAwesome();

const localizationStore = new LocalizationStore();
const authStore = new AuthStore(localizationStore);
const systemStateStore = new SystemStateStore();

const stores = {
    authStore,
    localizationStore,
    systemStateStore,
};

export type LoginAppStores = typeof stores & DefaultStores;

export const IS_STAGING = new URLSearchParams(history.location.search).get("is_staging") === "true";

const getAppMessagesFromCompiledFiles = (locale: string) =>
    import(
        /* webpackMode: "lazy" */
        `../../../../../../localization/src/loginApp/messages.${locale}.js`
    );

const getAppMessagesFromLinguiLoader = (locale: string) =>
    import(
        `@lingui/loader?config=${__dirname}/../../../lingui.config.js!./../../../../../../localization/src/loginApp/messages.${locale}.po`
    );

function getAppMessages(locale: string) {
    // THIS COMMENT CAN BE REMOVED WITH NEXT DEPLOYMENT
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
        return getAppMessagesFromLinguiLoader(locale);
    } else {
        return getAppMessagesFromCompiledFiles(locale);
    }
}

ReactDOM.render(
    <Router history={history}>
        <Provider {...stores}>
            <StandaloneI18nLoader getAppMessages={getAppMessages}>
                <ViewportProvider>
                    <App />
                </ViewportProvider>
            </StandaloneI18nLoader>
        </Provider>
    </Router>,
    document.getElementById("react-root"),
);
