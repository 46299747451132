import moment from "moment";

export class StringFormatter {
    static escapeRegexCharacters(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static getTimeSinceDateString(sinceDate: number) {
        const createdSinceInDays = moment().diff(sinceDate, "days");
        let createdSinceString = "";

        if (createdSinceInDays <= 0) {
            const createdSinceinHours = moment().diff(sinceDate, "hours");
            if (createdSinceinHours === 1) {
                createdSinceString = "clientApp.hourAgo".localize().replace("{0}", createdSinceinHours.toFixed(0));
            } else {
                createdSinceString = "clientApp.hoursAgo".localize().replace("{0}", createdSinceinHours.toFixed(0));
            }
        } else if (createdSinceInDays <= 30) {
            if (createdSinceInDays === 1) {
                createdSinceString = "clientApp.dayAgo".localize().replace("{0}", createdSinceInDays.toFixed(0));
            } else {
                createdSinceString = "global.daysAgo".localize().replace("{0}", createdSinceInDays.toFixed(0));
            }
        } else if (createdSinceInDays > 30 && createdSinceInDays <= 365) {
            if (createdSinceInDays > 30 && createdSinceInDays <= 60) {
                createdSinceString = "clientApp.monthAgo"
                    .localize()
                    .replace("{0}", (createdSinceInDays / 30).toFixed(0));
            } else {
                createdSinceString = "clientApp.monthsAgo"
                    .localize()
                    .replace("{0}", (createdSinceInDays / 30).toFixed(0));
            }
        } else {
            if (createdSinceInDays > 365 && createdSinceInDays <= 709) {
                createdSinceString = "clientApp.yearAgo"
                    .localize()
                    .replace("{0}", (createdSinceInDays / 365).toFixed(0));
            } else {
                createdSinceString = "clientApp.yearsAgo"
                    .localize()
                    .replace("{0}", (createdSinceInDays / 365).toFixed(0));
            }
        }

        return createdSinceString;
    }

    static get2CharIsoLanguage(languageCode: string) {
        return languageCode.includes("-") ? languageCode.split("-")[0] : languageCode;
    }

    static truncateWithEllipsis(str: string | undefined | null, maxLength: number): string | undefined | null {
        return str !== undefined && str !== null && str.length > maxLength
            ? str.substring(0, maxLength - 1) + "…"
            : str;
    }
}
