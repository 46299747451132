import { AtButton, AtTableColumn, IReactSelectOptionObject } from "@atman/design-system";
import { t } from "@lingui/macro";
import React from "react";
const getAssignementFormColumns = (removeItemAction): AtTableColumn<IReactSelectOptionObject>[] => [
    {
        key: "label",
        fieldName: "label",
        align: "left",
        label: t({ id: "global.name" }),
        sort: true,
        filter: {
            type: "freeText",
        },
    },
    {
        key: "value",
        fieldName: "value",
        align: "right",
        render: (value: string) => (
            <AtButton onClick={() => removeItemAction(value)} color="ghost" size="md">
                {t({ id: "clientApp.remove" })}
            </AtButton>
        ),
    },
];

export default getAssignementFormColumns;
