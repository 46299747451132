import { AtBadge, AtTitle, IAtBadgeProps, IAtTitleProps } from "../../../internal";
import React from "react";

import "./index.less";

export interface IAtTitleBadgeProps {
    titleProps: IAtTitleProps;
    badgeProps?: IAtBadgeProps;
}

export const AtTitleBadge: React.FC<IAtTitleBadgeProps> = ({ titleProps, children, badgeProps, ...otherProps }) => {
    return (
        <div className="AtTitleBadge" {...otherProps}>
            <AtTitle noMargin {...titleProps} />
            <AtBadge size="xs" {...badgeProps}>
                {children}
            </AtBadge>
        </div>
    );
};
