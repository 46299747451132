import "./index.less";
import { AtIcon, Colors, DefaultComponentProps, IconTitleSubtitle, Sizing } from "@atman/design-system";
import { t } from "@lingui/macro";
import React from "react";

export interface ISearchPlaceHolderProps extends DefaultComponentProps {}

export const SearchPlaceHolder: React.FC<ISearchPlaceHolderProps> = (props: ISearchPlaceHolderProps) => {
    const { ...otherProps } = props;

    return (
        <div className="SearchHelper" {...otherProps}>
            <IconTitleSubtitle
                icon={
                    <AtIcon
                        icon={["fas", "search"]}
                        color={Colors.greyBase}
                        size="2x"
                        bgColor={Colors.almostWhite}
                        borderRadius={Sizing.pixels.xs + Sizing.pixels.xxs}
                        bordered
                        extraPadding
                    />
                }
                title={t({ id: "clientApp.searchProfileTitle" })}
                subtitle={t({ id: "clientApp.searchProfileSubtitle" })}
            />
        </div>
    );
};
