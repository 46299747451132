import "./index.less";
import * as React from "react";
import { AtCollapse, AtTitle } from "@atman/design-system";
import { IStepperStepDetails } from "../../index";
import { observer } from "mobx-react";

export interface IAtStepperStepProps extends IStepperStepDetails {
    currentStep: number;
    expandedStep: number | undefined;
    toggleStep: (step: number) => void;
    allowExpansionOnAllSteps?: boolean;
}

export const AtStepperStep: React.FC<IAtStepperStepProps> = observer((props) => {
    const {
        title,
        description,
        number,
        content,
        currentStep,
        expandedStep,
        toggleStep,
        additionalHeaderContent,
        allowExpansionOnAllSteps,
    } = props;

    const isActive = currentStep === number;
    const isCompleted = currentStep > number;
    let isDisabled = currentStep >= number;

    if (allowExpansionOnAllSteps) {
        isDisabled = false;
    }

    const toggleCollapse = () => toggleStep(number);

    const renderToggleRowContent = () => {
        return (
            <>
                <div className={`progress-number ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}>
                    {number}
                </div>
                <div className="info-container">
                    <AtTitle headingType={4} title={title} />
                    <div className="description">{description}</div>
                </div>
                {additionalHeaderContent && <div className="additional-header-content">{additionalHeaderContent}</div>}
            </>
        );
    };

    return (
        <AtCollapse
            toggleRowContent={renderToggleRowContent()}
            isOpen={number === expandedStep}
            toggleCollapse={toggleCollapse}
            className={"AtStepperStep"}
            disabled={isDisabled}
        >
            {content}
        </AtCollapse>
    );
});
