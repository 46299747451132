import "./index.less";
import { AtIcon, AtParagraph, Colors } from "@atman/design-system";
import { SidePanelSearchType } from "../..";
import { SortDirection } from "../../../../../BaseComponent";
import { SortLabel } from "../../../../../SidePanelElement/SidePanelStructure";
import { observer } from "mobx-react";
import React, { useMemo } from "react";

export interface ISorterProps {
    sortDirection: string;
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
    sortType: SidePanelSearchType;
    appliedSortType: SidePanelSearchType;
    setSortType: React.Dispatch<React.SetStateAction<SidePanelSearchType>>;
    sortLabel: SortLabel;
    currentTab?: string;
}

export const SidePanelSorter: React.FC<ISorterProps> = observer((props: ISorterProps) => {
    const {
        sortDirection,
        setSortDirection,
        sortLabel,
        setSortType,
        sortType,
        appliedSortType,
        currentTab = "",
        ...otherProps
    } = props;

    const sortIcon = useMemo(() => {
        if (appliedSortType !== sortType) {
            return "sort";
        }

        if (sortDirection === "asc") {
            return "sort-up";
        }

        return "sort-down";
    }, [appliedSortType, sortDirection, sortType]);

    const iconColor = appliedSortType !== sortType ? Colors.greyShades.shade6 : Colors.greyBase;

    const handleSortOnClick = () => {
        setSortType(sortType);

        if (sortDirection === "asc") {
            setSortDirection("desc");
        }
        if (sortDirection === "desc") {
            setSortDirection("asc");
        }
    };

    const finalSortLabel = useMemo(() => {
        if (typeof sortLabel === "object") {
            if (sortLabel.has(currentTab)) {
                return sortLabel.get(currentTab);
            } else {
                return Array.from(sortLabel)[0][1];
            }
        }

        return sortLabel;
    }, [currentTab, sortLabel]);

    return (
        <div className="SidePanelSortWrapper" {...otherProps}>
            <div className="sidePanelSort" onClick={handleSortOnClick}>
                <AtParagraph weight="bold">{finalSortLabel}</AtParagraph>
                <AtIcon size="sm" icon={["fas", sortIcon]} color={iconColor} />
            </div>
        </div>
    );
});
