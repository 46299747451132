import "./index.less";
import * as React from "react";
import { CardCVCElement } from "react-stripe-elements";
import { Label } from "reactstrap";
import { stripeStyles } from "../StripeJsStyles";

const cardCvcStyles = {
    ...stripeStyles,
};

export interface IStripeCVCProps {}

export const StripeCVC: React.FC<IStripeCVCProps> = (props: IStripeCVCProps) => {
    const {} = props;

    return (
        <div className="StripeCVC StripeCardElement">
            <Label>{"global.cvc".localize()}</Label>
            <CardCVCElement style={cardCvcStyles} />
        </div>
    );
};
