import { BaseFit, IBaseFit } from "./BaseFit";
import { GenericFitContent, IGenericFitContent } from "./GenericFitContent";
import { observable } from "mobx";

export interface IGenericFit extends IBaseFit<IGenericFitContent> {
    readonly genericFitType: GenericFitType;
}

export class GenericFit extends BaseFit<IGenericFitContent> implements IGenericFit {
    @observable public genericFitType: GenericFitType;
    public content: GenericFitContent;

    constructor(json: IGenericFit) {
        super(json);

        this.content = new GenericFitContent(json.content);
        this.genericFitType = json.genericFitType;
    }
}

export enum GenericFitType {
    Culture,
    Potential,
}
