import "./index.less";
import * as React from "react";
import { AtButtonTabs } from "@atman/design-system";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useHistory, useLocation } from "react-router";
import { useMemo } from "react";

export interface ILoginFlowSwitcherProps {}

export const LoginFlowSwitcher: React.FC<ILoginFlowSwitcherProps> = observer((props) => {
    const {} = props;

    const history = useHistory();
    const location = useLocation();

    const selectedButtonIndex = useMemo(() => {
        if (location.pathname.includes("/Employee")) {
            return "1";
        }

        return "0";
    }, [location.pathname]);

    const onClick = (index: string) => {
        switch (index) {
            case "0":
                history.push("/Unauth/Login");
                break;
            case "1":
                history.push("/Employee/SignIn/Mfa");
                break;
        }
    };

    return (
        <AtButtonTabs
            className="LoginFlowSwitcher"
            buttons={[
                { id: "0", buttonContent: t({ id: "loginApp.loginFlows.userLogin", message: "User login" }) },
                {
                    id: "1",
                    buttonContent: t({ id: "loginApp.loginFlows.employeeLogin", message: "Employee Login" }),
                },
            ]}
            onClick={onClick}
            activeButton={selectedButtonIndex}
            fitContent
            round
        />
    );
});
