import "./AccountContainer.less";
import * as React from "react";
import { AccountDetails } from "./details/AccountDetails";
import { AccountNotifications } from "./notifications/AccountNotifications";
import { AppScope, GlobalStores, IAppContext, UserInfoStore } from "@atman/business";
import { AtSubMenuNavigationLayout, IAtSubMenuNavigationItem } from "../../components/AtSubMenuNavigationLayout";
import { AtTabNavigationLayout, IAtTabNavigationItem } from "../../components/AtTabNavigationLayout";
import { BaseResponsiveComponent } from "../../components/BaseResponsiveComponent";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import AccountSecurity from "./security/AccountSecurity";

export interface IAccountContainerProps extends IAppContext {
    userInfoStore?: UserInfoStore;
    showNotificationsPage?: boolean;
}

@inject(GlobalStores.userInfoStore)
@observer
class AccountContainerComp extends BaseResponsiveComponent<IAccountContainerProps> {
    protected get rootElementId(): string | undefined {
        return "AccountContainer";
    }

    renderMobile() {
        const { scope, showNotificationsPage = true, userInfoStore } = this.props;

        const navigationItems: IAtTabNavigationItem[] = [
            {
                navItemId: "account-details",
                label: "global.yourDetails".localize(),
                iconName: ["far", "user"] as IconProp,
                component: <AccountDetails userContext={userInfoStore!.userContext} />,
            },
        ];

        if (
            showNotificationsPage &&
            scope !== AppScope.Supplier &&
            Object.keys(userInfoStore!.notificationSettings.emailNotificationSettings).any()
        ) {
            navigationItems.push({
                navItemId: "account-notifications",
                label: "global.yourNotifications".localize(),
                iconName: ["far", "bell"] as IconProp,
                component: <AccountNotifications />,
            });
        }

        navigationItems.push({
            navItemId: "account-security",
            label: "global.security".localize(),
            iconName: ["far", "key"] as IconProp,
            component: <AccountSecurity />,
        });

        return <AtTabNavigationLayout navigationItems={navigationItems} />;
    }

    renderDefault() {
        const { scope, userInfoStore } = this.props;

        const navigationItems: IAtSubMenuNavigationItem[] = [
            {
                href: "/Details",
                uniqueKey: "yourDetails",
                label: "global.yourDetails".localize(),
                description: "global.yourDetails.description".localize(),
                helpCenterArticleId: "360054655271",
                helpCenterUrlLabel: "global.yourDetails.helpCenterLabel".localize(),
                icon: ["far", "user"],
                component: <AccountDetails userContext={userInfoStore!.userContext} />,
            },
        ];

        if (
            scope !== AppScope.Supplier &&
            Object.keys(userInfoStore!.notificationSettings.emailNotificationSettings).any()
        ) {
            navigationItems.push({
                href: "/Notifications",
                uniqueKey: "yourNotifications",
                label: "global.yourNotifications".localize(),
                description: "global.yourNotifications.description".localize(),
                helpCenterArticleId: "360054656411",
                helpCenterUrlLabel: "global.yourNotifications.helpCenterLabel".localize(),
                icon: ["far", "bell"],
                component: <AccountNotifications />,
            });
        }

        navigationItems.push({
            href: "/Security",
            uniqueKey: "security",
            label: "global.security".localize(),
            description: "global.security.description".localize(),
            helpCenterArticleId: "360054191432",
            helpCenterUrlLabel: "global.security.helpCenterLabel".localize(),
            icon: ["far", "key"],
            component: <AccountSecurity />,
        });

        return (
            <AtSubMenuNavigationLayout
                navItemGroups={[{ items: navigationItems }]}
                pageTitle={"global.account".localize()}
            />
        );
    }
}

const AccountContainer = withAppContext(AccountContainerComp);

export { AccountContainer };
