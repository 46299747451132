import { ErrorHandler } from "./ErrorHandler";
import { ReactAppSettings } from "./ReactAppSettings";
import axios, { AxiosError } from "axios";

const ApiManager = axios.create({
    baseURL: ReactAppSettings?.appUrls?.baseUrl,
    timeout: 120000,
    withCredentials: true,
});

ApiManager.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
ApiManager.interceptors.response.use(
    (response) => {
        switch (response.status) {
            case 300:
            case 301:
            case 302:
            case 303:
            case 307:
            case 308:
                window.location.href = response.headers.location;
                break;
            default:
                break;
        }

        return response;
    },
    (error) => {
        const errorResponse = (error as AxiosError).response;

        if (errorResponse) {
            switch (errorResponse.status) {
                case 400:
                    // ErrorHandler.handleApiError(error);
                    break;
                case 401:
                    //window.location.href = `${ReactAppSettings?.appUrls?.login}?returnUrl=${window.location.href}`;
                    break;
                case 403:
                    // ErrorHandler.handleApiError(error);
                    break;
                case 404:
                    // ErrorHandler.handleApiError(error);
                    break;
                case 422:
                    break;
                case 500:
                    // ErrorHandler.handleApiError(error);
                    break;
                default:
                    ErrorHandler.handleApiError(error);
                    break;
            }

            return Promise.reject(errorResponse.data);
        }

        return Promise.reject(error);
    },
);

export { ApiManager };
