import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { GlobalStores, ModalStore } from "@atman/business";
import { ModalBody, ModalHeader } from "reactstrap";
import { ModalCloseButton } from "../../modals/components/ModalCloseButton";
import { inject, observer } from "mobx-react";

export interface IBaseModalProps {
    modalTitle: string;
    id?: string;
    className?: string;
    toggleModal?: () => void;
    modalStore?: ModalStore;
}

@inject(GlobalStores.modalStore)
@observer
export class BaseModal extends React.Component<IBaseModalProps> {
    render(): JSX.Element[] {
        const { modalTitle, modalStore, toggleModal, id, className, children } = this.props;

        return [
            <ModalHeader
                key={"header"}
                toggle={toggleModal ?? modalStore!.toggleModal}
                close={<ModalCloseButton toggleModal={toggleModal} />}
                className="BaseModalHeader"
            >
                <AtTitle headingType={4} title={modalTitle} />
            </ModalHeader>,
            <ModalBody key={"body"} id={id} className={`BaseModalBody ${className ?? ""}`}>
                {children}
            </ModalBody>,
        ];
    }
}
