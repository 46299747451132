import "./index.less";
import { AtInput } from "../../../../AtInput";
import { AtRangeSlider } from "../RangeSlider";
import { AtTitle } from "../../../../../atoms/AtTitle";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { NUMBER_FILTER_VALUES } from "./const";
import { t } from "@lingui/macro";
import React, { ReactElement, useEffect, useState } from "react";

interface IAtTableFilterNumberRangeProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    appliedFilterValue: number[];
    setFilterValue: (columnKey: string, value: number[]) => void;
}

export function AtTableNumberRangeFilter<T extends IBaseEntity>(
    props: IAtTableFilterNumberRangeProps<T>,
): ReactElement {
    const { appliedFilterValue, setFilterValue, column, ...otherProps } = props;

    const min = column.filter?.min ?? NUMBER_FILTER_VALUES.min;
    const max = column.filter?.max ?? NUMBER_FILTER_VALUES.max;
    const step = column.filter?.step ?? NUMBER_FILTER_VALUES.step;

    const initializeRangeValue = (filterValue: number[], whichValue: "lowest" | "highest"): number => {
        if (whichValue === "lowest") {
            if (filterValue) {
                return filterValue[0] < min ? min : filterValue[0];
            } else {
                return min;
            }
        } else {
            if (filterValue) {
                return filterValue[1] > max ? max : filterValue[1];
            } else {
                return max;
            }
        }
    };

    const handleRangeValueChange = (values: number[]) => {
        setRangeValues(values);
        setLowestValue(values[0]);
        setHighestValue(values[1]);
        setFilterValue(column.key, values);
    };

    const [rangeValues, setRangeValues] = useState(appliedFilterValue ? appliedFilterValue : [min, max]);
    const [lowestValue, setLowestValue] = useState(initializeRangeValue(appliedFilterValue, "lowest"));
    const [highestValue, setHighestValue] = useState(initializeRangeValue(appliedFilterValue, "highest"));

    useEffect(() => {
        setRangeValues(appliedFilterValue ? appliedFilterValue : [Number(min), Number(max)]);
        setLowestValue(initializeRangeValue(appliedFilterValue, "lowest"));
        setHighestValue(initializeRangeValue(appliedFilterValue, "highest"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilterValue]);

    const handleLowestValueChange = (e) => {
        const val: number = Number(e.target.value);
        let filterValues: number[] = [];

        if (val < min) {
            setLowestValue(min);
            filterValues = [min, highestValue];
        }

        if (val > highestValue) {
            setLowestValue(highestValue);
            filterValues = [highestValue, highestValue];
        }

        if (val >= min && val <= highestValue) {
            setLowestValue(val);
            filterValues = [val, highestValue];
        }

        setRangeValues(filterValues);
        setFilterValue(column.key, filterValues);
    };

    const handleHighestValueChange = (e) => {
        const val: number = Number(e.target.value);

        let filterValues: number[] = [];

        if (val > max) {
            setHighestValue(max);
            filterValues = [lowestValue, max];
        }

        if (val < lowestValue) {
            setHighestValue(lowestValue);
            filterValues = [lowestValue, lowestValue];
        }

        if (val >= lowestValue && val <= max) {
            setHighestValue(val);
            filterValues = [lowestValue, val];
        }

        setRangeValues(filterValues);
        setFilterValue(column.key, filterValues);
    };

    return (
        <div className="AtTableNumberRangeFilter" {...otherProps}>
            <AtRangeSlider
                min={min}
                max={max}
                step={step}
                rangeValues={rangeValues}
                multipleThumbs
                handleRangeValueChange={handleRangeValueChange}
            />

            <div className="layout">
                <div className="numberInput">
                    <AtTitle className="inputTitle" title={t({ id: "global.minimum" })} />
                    <AtInput
                        className="input"
                        fieldName={`field_min_${column.key}`}
                        type="number"
                        onChange={handleLowestValueChange}
                        value={lowestValue.toString()}
                        min={min}
                        max={max}
                    />
                </div>
                <div className="numberInput">
                    <AtTitle className="inputTitle" title={t({ id: "global.maximum" })} />
                    <AtInput
                        className="input"
                        fieldName={`field_max_${column.key}`}
                        type="number"
                        onChange={handleHighestValueChange}
                        value={highestValue.toString()}
                        min={min}
                        max={max}
                    />
                </div>
            </div>
        </div>
    );
}
