import "./index.less";
import * as React from "react";
import cn from "classnames";

export interface IReportLegendContainerProps {
    children: any;
}
export const ReportLegendContainer: React.FC<IReportLegendContainerProps> = (props: IReportLegendContainerProps) => {
    const { children, ...otherProps } = props;
    return (
        <div {...otherProps} className={cn("ReportLegendContainer", `${children ? "AtContainer" : ""}`)}>
            {children}
        </div>
    );
};
