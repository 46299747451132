import "./index.less";
import * as React from "react";
import { AtSubtitle, AtTitle, fade } from "@atman/design-system";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";

export interface IAtSimpleCardProps {
    title: string;
    description?: React.ReactNode;
    subtitle?: string;
    icon?: IconProp | React.ReactNode;
    iconProps?: Partial<FontAwesomeIconProps>;
    inverseTitleAndSubtitle?: boolean;
    actionsComponent?: React.ReactNode;
    onClick?: () => void;
}

@observer
export class AtSimpleCard extends BaseResponsiveComponent<IAtSimpleCardProps> {
    protected get rootElementClassName(): string | undefined {
        const { className, description } = this.props;

        return `AtSimpleCard ${className ?? ""} ${description ? "has-description" : ""}`;
    }

    protected get containerProps(): React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
        const { onClick } = this.props;

        return {
            onClick,
        };
    }

    protected get rootElementStyle(): React.CSSProperties {
        const { onClick, style } = this.props;

        if (!onClick) {
            return style ?? {};
        }

        return {
            cursor: "pointer",
        };
    }

    renderAll() {
        const {
            title,
            description,
            subtitle,
            icon,
            iconProps,
            inverseTitleAndSubtitle,
            actionsComponent,
            ...otherProps
        } = this.props;

        return (
            <>
                <div className="top-section" {...otherProps}>
                    {icon && (
                        <div className={"header"}>
                            {React.isValidElement(icon) ? (
                                icon
                            ) : (
                                <div
                                    className="icon-container"
                                    style={{
                                        backgroundColor: iconProps?.color ? fade(iconProps.color, 0.2) : undefined,
                                    }}
                                >
                                    <FontAwesomeIcon {...iconProps} icon={icon as IconProp} />
                                </div>
                            )}
                        </div>
                    )}
                    <div className="titles-container">
                        {subtitle && inverseTitleAndSubtitle && <AtSubtitle subtitle={subtitle} />}
                        <AtTitle headingType={2} className="title" title={title} />
                        {subtitle && !inverseTitleAndSubtitle && <AtSubtitle subtitle={subtitle} />}
                    </div>
                    {description && <div className="description-container">{description}</div>}
                </div>
                <div className="bottom-section">
                    {actionsComponent && (
                        <div className="actions-container">
                            {typeof actionsComponent === "function" ? actionsComponent() : actionsComponent}
                        </div>
                    )}
                </div>
            </>
        );
    }
}
