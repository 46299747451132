import { BaseApi, DataType } from "../base";
import { HttpMethodEnum, ICreateTeamParams } from "../types";
import { ITeamCompetencyDevelopmentSettings, ITeamInput, Team } from "../models";
import { ReactAppSettings } from "../libs";

export class TeamsApi extends BaseApi {
    public static async getTeams() {
        return await this.sendApiRequest<Team[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getTeams);
    }

    public static async getTeam(id: string) {
        return await this.sendApiRequest<Team>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getTeam, undefined, id);
    }

    public static async createTeam({
        name,
        purpose,
        restricted,
        ownerIds,
        leadIds,
        memberIds,
        tagIds,
    }: ICreateTeamParams) {
        return await this.sendApiRequestWithResult<any, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createTeam,
            {
                name,
                purpose,
                restricted,
                ownerIds,
                memberIds,
                leadIds,
                tagIds,
            },
        );
    }

    public static async updateTeam(id: string, team: ITeamInput) {
        return await this.sendApiRequestWithResult<ITeamInput, Team>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateTeam,
            team,
            id,
        );
    }

    public static async updateTeamInformation(id: string, newName: string, newPurpose: string) {
        const data = {
            newName,
            newPurpose,
        };

        return await this.sendApiRequestWithResult<typeof data, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateTeamInformation,
            data,
            id,
        );
    }

    public static async updateTeamRestrictions(id: string, restricted: boolean, newOwnerIds: string[]) {
        const data = {
            restricted,
            newOwnerIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateTeamRestrictions,
            data,
            id,
        );
    }

    public static async switchMemberToLead(id: string, candidateProId: string) {
        const data = {
            data: candidateProId,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.switchTeamMemberToLead,
            data,
            id,
        );
    }

    public static async switchLeadToMember(id: string, candidateProId: string) {
        const data = {
            data: candidateProId,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.switchTeamLeadToMember,
            data,
            id,
        );
    }

    public static async changeTeamName(id: string, name: string) {
        return await this.sendApiRequestWithResult<{ newName: string }, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.changeTeamName,
            { newName: name },
            id,
            DataType.Json,
        );
    }

    public static async changeTeamPurpose(id: string, purpose: string) {
        return await this.sendApiRequestWithResult<{ newPurpose: string }, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.changeTeamPurpose,
            { newPurpose: purpose },
            id,
            DataType.Json,
        );
    }

    public static async changeTeamOwners(id: string, ownerIds: string[]) {
        return await this.sendApiRequestWithResult<{ data: string[] }, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.changeTeamOwners,
            { data: ownerIds },
            id,
        );
    }

    public static async changeTeamMembers(id: string, memberIds: string[], tagIds?: string[]) {
        const payload = {
            memberIds,
            tagIds,
        };

        return await this.sendApiRequestWithResult<typeof payload, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.changeTeamMembers,
            payload,
            id,
        );
    }

    public static async changeTeamLeads(id: string, leadIds: string[]) {
        return await this.sendApiRequestWithResult<{ data: string[] }, Team>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.changeTeamLeads,
            { data: leadIds },
            id,
            DataType.Json,
        );
    }

    public static async restrictTeam(id: string) {
        return await this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.restrictTeam, undefined, id);
    }

    public static async unrestrictTeam(id: string) {
        return await this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.unrestrictTeam, undefined, id);
    }

    public static async deleteTeam(id: string) {
        return await this.sendApiRequest(HttpMethodEnum.DELETE, ReactAppSettings.appUrls.deleteTeam, null, id);
    }

    public static async validateTeamName(name: string) {
        const data = {
            name,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.validateTeamName,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    public static async assignCulture(id: string, cultureId: string) {
        const data = {
            data: cultureId,
        };

        return await this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignTeamCulture,
            data,
            id,
        );
    }

    public static async unassignCulture(id: string, cultureId: string) {
        const data = {
            data: cultureId,
        };

        return await this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.unassignTeamCulture,
            data,
            id,
        );
    }

    public static async assignManager(id: string, managerUserId: string) {
        const data = {
            data: managerUserId,
        };

        return await this.sendApiRequestWithResult<typeof data, { managerName: string }>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignTeamManager,
            data,
            id,
        );
    }

    public static async updateTeamCompetencyDevelopmentSettings(
        id: string,
        teamCompetencyDevelopmentSettings: ITeamCompetencyDevelopmentSettings,
    ): Promise<void> {
        return this.sendApiRequestWithResult<ITeamCompetencyDevelopmentSettings, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateTeamCompetencyDevelopmentSettings,
            teamCompetencyDevelopmentSettings,
            id,
        );
    }
}
