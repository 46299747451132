import * as React from "react";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { IReactSelectOptionObject } from "@atman/design-system";
import { action, observable } from "mobx";

export interface IBaseFormProps {}

export abstract class BaseForm<SpecificProps, TEntity = any> extends BaseResponsiveComponent<
    IBaseFormProps & SpecificProps
> {
    @observable public typingTimeouts: Dictionary<string, NodeJS.Timer> = {};
    @observable public isLoading: boolean = false;

    @action setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    @action.bound
    onSelectFieldChange<T = HTMLInputElement>(event: React.FormEvent<T>) {
        const eventTarget = (event as any).target;

        if (this.hasOwnProperty(eventTarget.id)) {
            this.clearErrorForField(eventTarget.id);

            this[eventTarget.id] = eventTarget.value;
        }
    }

    @action.bound
    onEnumSelectFieldChange<T = HTMLInputElement>(event: React.FormEvent<T>) {
        const eventTarget = (event as any).target;

        if (this.hasOwnProperty(eventTarget.id)) {
            this.clearErrorForField(eventTarget.id);

            this[eventTarget.id] = Number(eventTarget.value);
        }
    }

    @action.bound
    onLocalizedTextChange = (event: React.FormEvent<HTMLInputElement>, currentLocale: string) => {
        const fieldName = event.currentTarget.name;

        if (this.hasOwnProperty(fieldName)) {
            (this[fieldName] as Map<string, string>).set(currentLocale, event.currentTarget.value);
        }
    };

    @action.bound
    loadAsyncSelectOptions(
        timeoutKey: string,
        getItemsAction: () => Promise<IReactSelectOptionObject[]>,
        sortByLabel: boolean = true,
    ): Promise<IReactSelectOptionObject[]> {
        return new Promise(async (resolve) => {
            if (this.typingTimeouts[timeoutKey]) {
                window.clearTimeout(this.typingTimeouts[timeoutKey]);
            }

            this.typingTimeouts[timeoutKey] = window.setTimeout(async () => {
                let processedResults = await getItemsAction();

                if (sortByLabel) {
                    processedResults = processedResults.sort((a, b) => {
                        const textA = a.label.toLowerCase();
                        const textB = b.label.toLowerCase();

                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    });
                }

                resolve(processedResults);
            }, 300);
        });
    }
}
