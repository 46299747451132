import "./index.less";
import * as React from "react";
import { AtContainer, AtIcon, Colors, IconTitleSubtitle } from "@atman/design-system";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";

export interface IAtWizardIconHeaderProps {
    title: string;
    description?: string;
    icon?: IconProp;
    color?: string;
    bgColor?: string;
}

export const AtWizardIconHeader: React.FC<IAtWizardIconHeaderProps> = observer((props) => {
    const { title, description, icon, color = Colors.atmanOrange, bgColor = Colors.atmanOrangeFaded } = props;

    let finalIcon = icon;

    if (!finalIcon) {
        finalIcon = ["fas", "question"];
    }

    return (
        <AtContainer className="AtWizardIconHeader">
            <IconTitleSubtitle
                title={title}
                subtitle={description}
                icon={<AtIcon icon={finalIcon} size="2x" color={color} bgColor={bgColor} />}
                size={"lg"}
            />
        </AtContainer>
    );
});
