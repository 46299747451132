import "./index.less";
import * as React from "react";
import { GlobalStores, ScopedOrganizationStore } from "@atman/business";
import { OrgBaseCustomizationForm } from "./components/OrgBaseCustomizationForm";
import { OrgSsoCustomizationForm } from "./components/OrgSsoCustomizationForm";
import { inject, observer } from "mobx-react";

export interface IOrgCustomizationSettingsProps {
    scopedOrganizationStore?: ScopedOrganizationStore;
}

export const OrgCustomizationSettings: React.FC<IOrgCustomizationSettingsProps> = inject(
    GlobalStores.scopedOrganizationStore,
)(
    observer((props) => {
        const { scopedOrganizationStore } = props;

        return (
            <div id="OrgCustomizationSettings">
                <OrgBaseCustomizationForm />
                {scopedOrganizationStore!.scopedOrganizationAuthConfig?.samlConfiguration && (
                    <OrgSsoCustomizationForm />
                )}
            </div>
        );
    }),
);
