import "./index.less";
import * as React from "react";
import { AtTooltip, DefaultComponentProps, IAtTooltipProps } from "@atman/design-system";
import { Colors } from "../../../styles";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { SortDirection } from "../../BaseComponent";
import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-duotone-svg-icons";
import { observer } from "mobx-react";
import { useUniqueId } from "@atman/core";

export interface ITableHeaderProps extends DefaultComponentProps {
    onClick?: () => void;
    isActive?: boolean;
    showSortIcon?: boolean;
    sortDirection?: SortDirection;
    tooltipContent?: React.ReactNode;
    tooltipProps?: Omit<IAtTooltipProps, "target" | "children">;
}

export const TableHeader: React.FC<ITableHeaderProps> = observer((props) => {
    const {
        isActive,
        showSortIcon = true,
        sortDirection,
        onClick,
        className,
        style,
        children,
        tooltipContent,
        tooltipProps,
    } = props;

    let elementClassName = "TableHeader";

    if (className) {
        elementClassName += ` ${className}`;
    }

    let styles: React.CSSProperties = {
        ...style,
    };

    const isSortable = isActive !== undefined && onClick !== undefined;

    if (isSortable) {
        styles = {
            ...styles,
            color: isActive ? Colors.primaryBase : undefined,
            cursor: "pointer",
        };
    }

    const iconProps: FontAwesomeIconProps = {
        icon: faSort,
        color: Colors.greyShades.shade4,
    };

    if (isSortable) {
        if (isActive) {
            iconProps.style = {
                // @ts-ignore
                "--fa-primary-color": Colors.primaryBase,
                "--fa-secondary-color": Colors.greyShades.shade4,
            };

            switch (sortDirection) {
                case "asc":
                    iconProps.icon = faSortUp;
                    break;
                case "desc":
                    iconProps.icon = faSortDown;
                    break;
            }
        }
    }

    const { uniqueId } = useUniqueId();

    return (
        <>
            <div className={elementClassName} id={uniqueId} style={styles} onClick={onClick}>
                {children}
                {showSortIcon && isSortable && <FontAwesomeIcon {...iconProps} />}
            </div>
            {tooltipContent && (
                <AtTooltip
                    displayMode={"light"}
                    placement="top"
                    target={uniqueId}
                    {...tooltipProps}
                    style={{
                        textAlign: "center",
                        ...tooltipProps?.style,
                    }}
                >
                    {tooltipContent}
                </AtTooltip>
            )}
        </>
    );
});
