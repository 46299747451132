import "./index.less";
import * as React from "react";
import { AtButton, AtCard, AtTitle, Colors, Sizing } from "../../../internal";

export interface IAtRemovableItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "style" | "onClick"> {
    title: string;
    onRemoveClick: () => void;
    buttonId?: string;
}

export const AtRemovableItem: React.FC<IAtRemovableItemProps> = (props) => {
    const { buttonId, title, onRemoveClick, className, ...otherProps } = props;

    return (
        <AtCard className={`AtRemovableItem ${className ?? ""}`.trim()} padding={Sizing.paddings.sm} {...otherProps}>
            <AtTitle title={title} noMargin />
            <AtButton
                onClick={onRemoveClick}
                icon={["fal", "times"]}
                iconProps={{ size: "lg", color: Colors.greyShades.shade2 }}
                color="ghost"
                size="sm"
                id={buttonId}
            />
        </AtCard>
    );
};
