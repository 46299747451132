import "./index.less";
import { AtButton, Colors } from "@atman/design-system";
import { CompetencyDevelopmentAssessmentType } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import { useCompDevEvaluationContext } from "../../../../../../contexts";
import React from "react";

export interface ICompetencyDevelopmentAssessmentThankYouScreenProps {}

export const CompetencyDevelopmentAssessmentThankYouScreen: React.FC<ICompetencyDevelopmentAssessmentThankYouScreenProps> =
    observer((props) => {
        const {} = props;

        const { employeeDetails, assessmentType } = useCompDevEvaluationContext()!;

        let description: string = "";

        switch (assessmentType) {
            case CompetencyDevelopmentAssessmentType.Self:
                description = t({
                    id: "competencyDevelopment.assessments.thankYouScreen.intro.description.self",
                    message: "You have completed the self-evaluation of your competencies",
                });
                break;
            case CompetencyDevelopmentAssessmentType.Manager:
                description = t({
                    id: "competencyDevelopment.assessments.thankYouScreen.intro.description.manager",
                    message: `You have completed your evaluation of ${employeeDetails.firstName} ${employeeDetails.lastName}.`,
                    values: {
                        0: employeeDetails.firstName,
                        1: employeeDetails.lastName,
                    },
                });
                break;
            case CompetencyDevelopmentAssessmentType.Review:
                description = t({
                    id: "competencyDevelopment.assessments.thankYouScreen.intro.description.review",
                    message: `You have completed your 1-on-1 discussion.`,
                });
                break;
        }

        return (
            <div id="CompetencyDevelopmentAssessmentThankYouScreen">
                <div className="top-container">
                    <div className="completed-icon-container">
                        <FontAwesomeIcon icon={["far", "badge-check"]} color={Colors.greenBase} />
                    </div>
                    <h1 className="completed-title">
                        <Trans id={"competencyDevelopment.assessments.thankYouScreen.intro.title"}>Done!</Trans>
                    </h1>
                    <div className="completed-description">{description}</div>

                    <AtButton color={"secondary"} onClick={() => window.close()}>
                        <Trans id={"global.buttons.labels.close"}>Close</Trans>
                    </AtButton>
                </div>
            </div>
        );
    });
