import "./EntityUsers.less";
import {
    AppScope,
    Client,
    GlobalStores,
    IAppContext,
    ModalStore,
    ModalTypes,
    Partner,
    ReactAppSettings,
    UnhandledScopeError,
    UserInfoStore,
    UserStore,
} from "@atman/business";
import { AtTabCard, IAtButtonProps } from "@atman/design-system";
import { UserManagement } from "../../../components/UserManagement";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../contexts";
import React from "react";

export interface IEntityUsersProps extends IAppContext {
    entity: Client | Partner;
    modalStore?: ModalStore;
    userInfoStore?: UserInfoStore;
    userStore?: UserStore;
}

@inject(GlobalStores.modalStore, GlobalStores.userInfoStore, GlobalStores.userStore)
@observer
class EntityUsersComp extends React.Component<IEntityUsersProps, {}> {
    render() {
        const { modalStore, userInfoStore, userStore, scope, entity } = this.props;

        const childProps: { clientId?: string; partnerProId?: string; externalUsers: boolean } = {
            externalUsers: true,
        };
        let hasDelegation: boolean;

        switch (scope) {
            case AppScope.Partner:
                childProps.clientId = entity.id;
                hasDelegation =
                    userInfoStore!.automaticClientDelegationEnabled ||
                    (ReactAppSettings.appModel.delegatedClientIds ?? []).includes(entity.id);
                break;
            case AppScope.Supplier:
                childProps.partnerProId = entity.id;
                hasDelegation = true;
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        const headerButtonProps: IAtButtonProps[] = [];

        // if (scope === AppScope.Partner) {
        //     headerButtonProps.push({
        //         color: "secondary",
        //         onClick: () => modalStore!.openModal(ModalTypes.AddExistingUserForm, {
        //             ...childProps
        //         }),
        //         children: 'inviteFromExisting'.localize(),
        //         size: "small"
        //     },)
        // }

        if (hasDelegation || userStore!.entityUsers.length === 0) {
            headerButtonProps.push({
                color: "primary",
                onClick: () => modalStore!.openModal(ModalTypes.UserForm, childProps),
                children: "global.inviteUser".localize(),
                size: "md",
            });
        }

        return (
            <AtTabCard
                id="EntityUsers"
                cardTitle={"global.usersManagement".localize()}
                headerButtonProps={headerButtonProps}
            >
                <UserManagement ownerId={entity.id} />
            </AtTabCard>
        );
    }
}

const EntityUsers = withAppContext(EntityUsersComp);

export { EntityUsers };
