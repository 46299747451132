import { BasePlatformRootStore } from "../base";
import { CompDevCompetency } from "../models";
import { IDepartment } from "../models/Department";
import { PlatformContentApi } from "../apis/PlatformContentApi";
import { action, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class ContentStore {
    // @ts-ignore
    private readonly rootStore: BasePlatformRootStore;

    @observable public hasLoadedCompetencies: boolean = false;
    @observable private _competencies: CompDevCompetency[] = [];
    @computed public get competencies(): CompDevCompetency[] {
        return this._competencies;
    }

    @observable private _departments: IDepartment[] = [];
    @computed public get departments(): IDepartment[] {
        return this._departments;
    }

    constructor(rootStore: BasePlatformRootStore) {
        this.rootStore = rootStore;
    }

    getCompetencyByCode: (code: string) => CompDevCompetency | undefined = computedFn((code: string) => {
        return this.competencies.find((x) => x.code === code);
    });

    getCompetencyById: (id: string) => CompDevCompetency | undefined = computedFn((id: string) => {
        return this.competencies.find((x) => x.id === id);
    });

    getDepartmentByCode: (code: string) => IDepartment | undefined = computedFn((code: string) => {
        return this.departments.find((x) => x.code === code);
    });

    getDepartmentById: (id: string) => IDepartment | undefined = computedFn((id: string) => {
        return this.departments.find((x) => x.id === id);
    });

    @action loadCompetencies = async () => {
        const loadCompetenciesMockResult = await PlatformContentApi.getCompetencies();

        runInAction(() => {
            this._competencies = loadCompetenciesMockResult.map((x) => new CompDevCompetency(x));
            this.hasLoadedCompetencies = true;
        });
    };

    @action loadDepartments = async () => {
        const result: IDepartment[] = await PlatformContentApi.getDepartments();

        runInAction(() => {
            this._departments = result;
        });
    };
}
