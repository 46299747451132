import "./index.less";
import * as React from "react";
import { AtPersonAvatar } from "@atman/design-system";
import { AtScoreReviewIndicator, LikertScoreDisplay } from "../../../../../..";
import { CompDevObservableBehaviorResult, CompetencyDevelopmentAssessmentType } from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../../..";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IDetailedObservableBehaviorSubRow {
    observableBehavior: CompDevObservableBehaviorResult;
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    hiddenColumns?: CompetencyDevelopmentColumnType[];
}

export const DetailedObservableBehaviorSubRow: React.FC<IDetailedObservableBehaviorSubRow> = observer((props) => {
    const { observableBehavior, managerInfo, employeeInfo, hiddenColumns } = props;

    const { isMobile } = useDetectDevice();

    const managerValue = observableBehavior.assessmentScores.get(CompetencyDevelopmentAssessmentType.Manager);
    const employeeValue = observableBehavior.assessmentScores.get(CompetencyDevelopmentAssessmentType.Self);
    const averageScore = observableBehavior.averageBetweenManagerAndEmployee!;
    const reviewScore = observableBehavior.assessmentScores.get(CompetencyDevelopmentAssessmentType.Review);
    const reviewNotes = observableBehavior.assessmentNotes.get(CompetencyDevelopmentAssessmentType.Review);

    const renderMobile = () => {
        return (
            <div className="DetailedObservableBehaviorSubRow">
                {!hiddenColumns?.includes("competencyName") && (
                    <div className="observable-behavior-title">
                        {`${observableBehavior.hierarchicalRank} ${observableBehavior.name}`}
                    </div>
                )}
                <div className="scores-row">
                    <div className="section-left">
                        {!hiddenColumns?.includes("employeeScore") && (
                            <div className="score-container">
                                <AtPersonAvatar
                                    firstName={managerInfo.firstName}
                                    lastName={managerInfo.lastName}
                                    email={managerInfo.email}
                                    pictureUri={managerInfo.pictureUri}
                                />
                                <div className="score">{managerValue?.toFixed(1) ?? "-"}</div>
                            </div>
                        )}
                        {!hiddenColumns?.includes("managerScore") && (
                            <div className="score-container">
                                <AtPersonAvatar
                                    firstName={employeeInfo.firstName}
                                    lastName={employeeInfo.lastName}
                                    email={employeeInfo.email}
                                    pictureUri={employeeInfo.pictureUri}
                                />

                                <div className="score">{employeeValue?.toFixed(1) ?? "-"}</div>
                            </div>
                        )}
                    </div>
                    <div className="section-right">
                        {!hiddenColumns?.includes("averageScore") && (
                            <div className="score-container">
                                <LikertScoreDisplay score={reviewScore ?? averageScore} />
                                {reviewScore && (
                                    <AtScoreReviewIndicator
                                        tooltipTargetId={`tooltip-${observableBehavior.id}`}
                                        score={averageScore}
                                        scoreDisplayMode={"likert"}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {reviewNotes ? <div className="observable-behavior-notes">{reviewNotes}</div> : null}
            </div>
        );
    };

    const renderDefault = () => {
        return (
            <div className="DetailedObservableBehaviorSubRow">
                {!hiddenColumns?.includes("competencyName") && (
                    <div className="competency-col content-col">
                        <div className="observable-behavior-details">
                            <div className="observable-behavior-rank">{observableBehavior.hierarchicalRank}</div>
                            <div className="observable-behavior-label">{observableBehavior.name}</div>
                        </div>
                        {reviewNotes ? <div className="observable-behavior-notes">{reviewNotes}</div> : null}
                    </div>
                )}
                {!hiddenColumns?.includes("averageScore") && (
                    <div className="average-note-col content-col">
                        <LikertScoreDisplay score={reviewScore ?? averageScore} />
                        {reviewScore && (
                            <AtScoreReviewIndicator
                                tooltipTargetId={`tooltip-${observableBehavior.id}`}
                                score={averageScore}
                                scoreDisplayMode={"likert"}
                            />
                        )}
                    </div>
                )}
                {!hiddenColumns?.includes("employeeScore") && (
                    <div className="employee-note-col content-col">{employeeValue?.toFixed(1) ?? "-"}</div>
                )}
                {!hiddenColumns?.includes("managerScore") && (
                    <div className="manager-note-col content-col">{managerValue?.toFixed(1) ?? "-"}</div>
                )}
                {!hiddenColumns?.includes("spread") && (
                    <div className="spread-col content-col">
                        {employeeValue && managerValue ? `± ${Math.abs(employeeValue - managerValue).toFixed(1)}` : "-"}
                    </div>
                )}
            </div>
        );
    };

    return isMobile ? renderMobile() : renderDefault();
});
