import * as React from "react";
import * as moment from "moment";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ILastSeenCardItemProps {
    lastSeen: string;
}

export const LastSeenCardItem: React.FC<ILastSeenCardItemProps> = (props) => {
    const { lastSeen } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "calendar-day"] as IconProp}
            cardTitle={"global.lastSeen".localize()}
            className={"LastSeenCardItem"}
        >
            {lastSeen ? moment.parseZone(lastSeen).fromNow() : "global.never".localize()}
        </BaseOverviewCardItem>
    );
};
