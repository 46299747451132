import "./BillingModalHelpMessage.less";
import * as React from "react";
import { GlobalStores, IntercomApi, TransactionStore } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IBillingModalHelpMessageProps {
    transactionStore?: TransactionStore;
}

@inject(GlobalStores.transactionStore)
@observer
export class BillingModalHelpMessage extends React.Component<IBillingModalHelpMessageProps> {
    render() {
        const { transactionStore } = this.props;

        const managersContactPhoneNumber =
            transactionStore!.managersContactInformation &&
            transactionStore!.managersContactInformation.address &&
            transactionStore!.managersContactInformation.address.phone;

        let phoneNumberComponent: React.ReactNode;

        if (managersContactPhoneNumber) {
            phoneNumberComponent = <span>{managersContactPhoneNumber}</span>;
        } else {
            phoneNumberComponent = (
                <a href={"tel:18779355959"} className="phone-link">
                    +1 (877) 935-5959
                </a>
            );
        }

        return (
            <div className="BillingModalHelpMessage">
                {`${"global.needHelp".localize()} ?`}
                {` ${"global.callUsAt".localize()} `}
                {phoneNumberComponent}
                {` ${"global.or".localize()} `}
                <a onClick={() => IntercomApi.showNewMessage()} className={"chat-link"}>
                    {"global.chatWithUs".localize()}
                </a>
            </div>
        );
    }
}
