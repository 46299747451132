import "./index.less";
import * as React from "react";
import { AtContainer, AtTitleSubtitle, LocalizedTextInput } from "@atman/design-system";
import { BaseForm } from "../../../BaseForm";
import { CardSaveButtons } from "../../../CardSaveButtons";
import { Form } from "reactstrap";
import { GlobalStores, ISupportedCulture, LocalizationStore, ScopedOrganizationStore } from "@atman/business";
import { ObservableMap, action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IOrgSsoCustomizationFormProps {
    scopedOrganizationStore?: ScopedOrganizationStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.scopedOrganizationStore, GlobalStores.localizationStore)
@observer
export class OrgSsoCustomizationForm extends BaseForm<IOrgSsoCustomizationFormProps> {
    @observable private localizedInstructions: ObservableMap<string, string> = new ObservableMap<string, string>();

    componentDidMount() {
        super.componentDidMount();

        this.resetForm();
    }

    onLocalizedInstructionsChange = action((event: React.FormEvent<HTMLInputElement>, currentLocale: string) => {
        this.localizedInstructions.set(currentLocale, event.currentTarget.value);
    });

    resetForm = () => {
        const { scopedOrganizationStore } = this.props;

        const { instructions } = scopedOrganizationStore!.scopedOrganizationCustomization?.ssoCustomizations ?? {};

        this.localizedInstructions.clear();

        if (instructions) {
            for (const key of Object.keys(instructions)) {
                this.localizedInstructions.set(key, instructions[key]);
            }
        }
    };

    onSave = async () => {
        const { scopedOrganizationStore } = this.props;

        try {
            this.setIsLoading(true);

            await scopedOrganizationStore!.updateSsoCustomization({
                instructions: this.localizedInstructions.toJSON(),
            });

            this.setIsLoading(false);
        } catch (e) {
            console.log(e);
            this.setIsLoading(false);
        }
    };

    render() {
        const { localizationStore } = this.props;

        let languagesToTranslate: ISupportedCulture[];

        if (localizationStore!.availableAssessmentLanguages.any()) {
            languagesToTranslate = localizationStore!.availableAssessmentLanguages;
        } else {
            languagesToTranslate = localizationStore!.availableLanguages;
        }

        return (
            <AtContainer id="OrgSsoCustomizationForm">
                <AtTitleSubtitle
                    size={"xl"}
                    title={t({
                        id: "global.orgCustomization.ssoForm.title",
                        message: "Organization Sso Customization",
                    })}
                    subtitle={t({
                        id: "global.orgCustomization.ssoForm.description",
                        message: "Customize your SSO details, such as instructions",
                    })}
                />
                <Form className="sso-customization-form">
                    {languagesToTranslate.map((x) => (
                        <LocalizedTextInput
                            key={x.shortName}
                            labelKey={"global.orgCustomization.instructions.label"}
                            formText={"global.orgCustomization.instructions.description".localize()}
                            fieldName={`localizedInstructions-${x.shortName}`}
                            value={this.localizedInstructions.get(x.shortName) ?? ""}
                            currentLocale={x.shortName}
                            onChange={(e) => this.onLocalizedInstructionsChange(e, x.shortName)}
                        />
                    ))}
                    <CardSaveButtons
                        saveAction={this.onSave}
                        cancelAction={this.resetForm}
                        isLoading={this.isLoading}
                    />
                </Form>
            </AtContainer>
        );
    }
}
