import "./index.less";
import * as React from "react";
import { AtButton, AtParagraph, LoadingIndicator } from "@atman/design-system";
import { AtmanLogoFullDark } from "@atman/ui-kit/lib/static";
import { AuthStore } from "../../../data/stores/AuthStore";
import { ENGLISH_CULTURE } from "@atman/business/lib/stores/LocalizationStore";
import { ISsoProviderDetails, LocalizationStore } from "@atman/business";
import { LoginAppStores } from "../../../../index";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

export interface ISsoSignInContainerProps {
    authStore?: AuthStore;
    localizationStore?: LocalizationStore;
}

export const SsoSignInContainer: React.FC<ISsoSignInContainerProps> = inject<LoginAppStores, any, any, any>(
    (stores) => ({
        authStore: stores.authStore,
        localizationStore: stores.localizationStore,
    }),
)(
    observer((props) => {
        const { localizationStore, authStore } = props;

        const [providerDetails, setProviderDetails] = useState<ISsoProviderDetails | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const {
            params: { orgId },
        } = useRouteMatch<{ orgId: string }>();

        const loadProviderDetails = async () => {
            const result = await authStore!.getSsoProviderDetails(orgId);

            setProviderDetails(result);
        };

        useEffect(() => {
            loadProviderDetails();
        }, []);

        const getLocalizedText = (localizedText: Record<string, string>): string | undefined => {
            if (Object.keys(localizedText).length === 0) {
                return undefined;
            }

            if (localizedText.hasOwnProperty(localizationStore!.currentShortLocale)) {
                return localizedText[localizationStore!.currentShortLocale];
            }

            return localizedText[ENGLISH_CULTURE.shortName];
        };

        const handleButtonClick = () => {
            setIsLoading(true);

            if (providerDetails?.signInUrl) {
                window.location.href = providerDetails.signInUrl;
            }
        };

        return (
            <div id="SsoSignInContainer">
                {providerDetails ? (
                    <>
                        <div className="logo-container">
                            <img className="provider-logo" src={providerDetails.logoUri ?? AtmanLogoFullDark} />
                        </div>
                        <h1 className="provider-name">{providerDetails.name}</h1>
                        {providerDetails.description && (
                            <h4 className="provider-description">{getLocalizedText(providerDetails.description)}</h4>
                        )}
                        {providerDetails.instructions && (
                            <div className="provider-instructions">
                                {getLocalizedText(providerDetails.instructions)
                                    ?.split("\n")
                                    .map((x, i) => (
                                        <AtParagraph key={i}>{x}</AtParagraph>
                                    ))}
                            </div>
                        )}
                        <AtButton
                            color={"primary"}
                            className="provider-access-button"
                            onClick={handleButtonClick}
                            isLoading={isLoading}
                            fullWidth
                        >
                            <Trans id={"loginApp.employee.signIn.sso.cta"}>
                                Access external provider - {providerDetails.name}
                            </Trans>
                        </AtButton>
                    </>
                ) : (
                    <LoadingIndicator size={"xl"} />
                )}
            </div>
        );
    }),
);
