import "./index.less";
import {
    AnalysisTileEnum,
    AnalyzedCandidate,
    AtmanAssessmentDocumentStructure,
    FitLevelEnum,
    IAssessmentResultScore,
    IPersonScores,
    PrintModeEnum,
    ScoreDisplayTypeFromScoreType,
    TeamAnalysisHelper,
} from "@atman/business";
import {
    AtBadge,
    AtParagraph,
    AtPeopleLabelCompare,
    AtTooltip,
    Colors,
    ComponentPadding,
    Person,
    ReportHeader,
} from "@atman/design-system";
import { DimensionGroupScoreSummary, ScoreInfo, ScoreInfoSizing, ViewFilterType } from "../";
import { FitLevelFiltering } from "../FitLevelFiltering";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HelpCenterInfoLink, ReportLegendContainer } from "../../";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useMemo } from "react";
import cn from "classnames";

import { FitScoreMapDisplay } from "../FitScoreMapDisplay";

export interface IAssessmentDocumentsHeaderProps {
    analysisTile: AnalysisTileEnum;
    title?: string;
    secondaryTitle?: string;
    helpCenterLinkArticleId?: string;
    description?: string;
    icon?: IconProp;
    assessmentResultScore?: IAssessmentResultScore;
    personScores?: IPersonScores[];
    firstCandidate?: AnalyzedCandidate;
    secondCandidate?: AnalyzedCandidate;
    currentViewFilter?: ViewFilterType;
    legendItems?: JSX.Element[];
    assessmentDocuments?: AtmanAssessmentDocumentStructure[];
    fitScoreCountMap?: Nullable<Map<FitLevelEnum, number>>;
    printMode?: PrintModeEnum;
    padding?: null | ComponentPadding;
    viewBoxWidth?: number;
    onCurrentViewFiltersChange?: (tile: AnalysisTileEnum, option: ViewFilterType) => void;
}

export const AssessmentDocumentsHeader: React.FC<IAssessmentDocumentsHeaderProps> = (props) => {
    const {
        analysisTile,
        title,
        secondaryTitle,
        helpCenterLinkArticleId,
        description,
        icon,
        assessmentResultScore,
        personScores,
        firstCandidate,
        secondCandidate,
        assessmentDocuments,
        fitScoreCountMap,
        currentViewFilter,
        legendItems,
        printMode,
        padding,
        onCurrentViewFiltersChange,
        viewBoxWidth,
    } = props;

    const getAlertBadges = () => {
        const penalties = assessmentResultScore?.penaltiesApplied;

        if (!penalties || !penalties.length) {
            return;
        }

        return (
            <div className={"alerts-container"} style={{ display: "flex", flexWrap: "wrap", marginTop: 8 }}>
                {penalties.map((x) => {
                    const badgeId = `jobfit-alert-${x.flagIdentifier}`;

                    return (
                        <>
                            {x.description && <AtTooltip target={badgeId}>{x.description}</AtTooltip>}
                            <AtBadge
                                id={badgeId}
                                size="md"
                                color="danger"
                                badgeStyle="fill-medium"
                                key={x.name}
                                style={{ marginRight: 8, marginBottom: 8 }}
                            >
                                <FontAwesomeIcon icon={["fas", "flag"]} size="sm" color={Colors.redBase} />
                                <span style={{ marginLeft: 8 }}>{x.name}</span>
                                {x.negativeImpact === 0 ? null : (
                                    <strong style={{ marginLeft: 8 }}>{`-${x.negativeImpact}%`}</strong>
                                )}
                            </AtBadge>
                        </>
                    );
                })}
            </div>
        );
    };

    const getPersonProps: (candidate: AnalyzedCandidate) => Person = (candidate: AnalyzedCandidate) => {
        const person: Person = {
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            email: candidate.email,
            pictureUri: candidate.profilePictureUri,
        };

        const personScore = personScores?.find((x) => x.candidateProId === candidate.candidateProId);

        if (personScore) {
            person.avatarProps = {
                textColor: personScore.textColor,
                bgColor: personScore.color,
            };
        }

        return person;
    };

    const iconOrResultScore = useMemo(() => {
        if (icon) {
            return (
                <div className="icon-container">
                    <FontAwesomeIcon icon={icon} size="3x" color={Colors.greyShades.shade2} />
                </div>
            );
        }

        if (assessmentResultScore) {
            return (
                <div className="main-info-container">
                    {icon && (
                        <div className="icon-container">
                            <FontAwesomeIcon icon={icon} size="3x" color={Colors.greyShades.shade2} />
                        </div>
                    )}
                    {assessmentResultScore && (
                        <div className="score-container">
                            <ScoreInfo
                                tileType={TeamAnalysisHelper.analysisTilesToTileTypeMapping.get(analysisTile)}
                                score={assessmentResultScore?.score}
                                isScoreAnAverage={false}
                                sizing={ScoreInfoSizing.regular}
                                scoreDisplayType={ScoreDisplayTypeFromScoreType(assessmentResultScore?.scoreType)}
                                showOutOfXLabel={true}
                                showInterpretation={false}
                                viewBoxWidth={viewBoxWidth}
                            />
                        </div>
                    )}
                </div>
            );
        }

        return <></>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analysisTile, assessmentResultScore, icon as unknown]);

    const dimensions =
        assessmentDocuments !== undefined && assessmentDocuments.length > 0
            ? assessmentDocuments[0].groups.flatMap((x) => x.dimensions.slice())
            : undefined;

    const isPersonComparison = firstCandidate && secondCandidate && personScores;

    return (
        <>
            <div className={cn("AssessmentDocumentsHeader", { isPersonComparison: isPersonComparison })}>
                <ReportHeader
                    icon={iconOrResultScore}
                    secondaryTitle={secondaryTitle}
                    title={title}
                    description={description}
                    headerSize={1}
                    size="xl"
                    reverseTitles
                    padding={padding}
                    rightContent={
                        <>
                            {printMode === undefined && getAlertBadges()}
                            {printMode === undefined && (
                                <HelpCenterInfoLink
                                    title={"global.moreAboutThisReport".localize()}
                                    href={`articles/${helpCenterLinkArticleId}`}
                                />
                            )}
                        </>
                    }
                />

                {firstCandidate && secondCandidate && personScores && (
                    <div className="peopleLabelCompareWrapper AtContainer">
                        <div className="d-flex">
                            {printMode !== undefined && dimensions && <FitScoreMapDisplay dimensions={dimensions} />}
                            <AtPeopleLabelCompare
                                leftPerson={getPersonProps(firstCandidate)}
                                rightPerson={getPersonProps(secondCandidate)}
                                isInPrintMode={printMode !== undefined ? true : false}
                            />
                        </div>

                        <div className="helpCenterLinkWrapper">
                            <HelpCenterInfoLink
                                title={"global.moreAboutThisReport".localize()}
                                href={`articles/${helpCenterLinkArticleId}`}
                            />
                        </div>
                    </div>
                )}

                {printMode === undefined && assessmentDocuments && firstCandidate && (
                    <DimensionGroupScoreSummary
                        assessmentDocuments={assessmentDocuments}
                        scores={firstCandidate.dimensionScores}
                    />
                )}

                {fitScoreCountMap &&
                    currentViewFilter !== undefined &&
                    onCurrentViewFiltersChange &&
                    printMode === undefined && (
                        <FitLevelFiltering
                            fitLevelCountMap={fitScoreCountMap}
                            onLegendItemClick={(fitLevel) => {
                                if (fitLevel === FitLevelEnum.ShowAll) {
                                    onCurrentViewFiltersChange(analysisTile, "showAll");
                                }

                                onCurrentViewFiltersChange(analysisTile, fitLevel);
                            }}
                            currentViewFilter={currentViewFilter}
                            tile={analysisTile}
                        />
                    )}
            </div>

            {legendItems && legendItems.length > 0 && <ReportLegendContainer>{legendItems}</ReportLegendContainer>}
        </>
    );
};

export const WEBLATE_LINE_RETURN = "\n";
export const WEBLATE_BULLET_POINT = "•\\t";

export function renderWeblateDescription(description: string, inPrint: boolean = false): React.ReactNode {
    const hasBulletPoints = description.includes(WEBLATE_BULLET_POINT);
    const startsWithBulletPoint = description.startsWith(WEBLATE_BULLET_POINT);
    const hasLineBreaks = description.includes(WEBLATE_LINE_RETURN);

    if (!hasBulletPoints && !hasLineBreaks) {
        return (
            <AtParagraph size={inPrint ? "lg" : "md"} weight={"medium"}>
                {description}
            </AtParagraph>
        );
    }

    let preBulletPointRows: string[] = [];
    let bulletPointRows: string[] = [];
    let postBulletPointRows: string[] = [];

    if (hasBulletPoints) {
        bulletPointRows = description.split(WEBLATE_LINE_RETURN + WEBLATE_BULLET_POINT);

        if (startsWithBulletPoint) {
            bulletPointRows[0] = bulletPointRows[0].replace(WEBLATE_BULLET_POINT, "");
        } else {
            // remove first part before the first weblate bullet point
            const preBulletPointSection = bulletPointRows.splice(0, 1)[0];

            preBulletPointRows = preBulletPointSection.split(WEBLATE_LINE_RETURN);
        }

        // if last part include standalone '\n' characters, remove the last part
        if (bulletPointRows[bulletPointRows.length - 1].includes(WEBLATE_LINE_RETURN)) {
            const postBulletPointSection = bulletPointRows.pop();

            postBulletPointRows = postBulletPointSection?.split(WEBLATE_LINE_RETURN) ?? [];
        }
    } else {
        preBulletPointRows = description.split(WEBLATE_LINE_RETURN);
    }

    return (
        <>
            {preBulletPointRows.map((x, i) => (
                <AtParagraph size={inPrint ? "lg" : "md"} weight={"medium"} style={{ marginBottom: 0 }} key={i}>
                    {x}
                </AtParagraph>
            ))}
            {hasBulletPoints && (
                <ul style={{ paddingLeft: 20 }}>
                    {bulletPointRows.map((x, i) => (
                        <li key={i}>
                            <AtParagraph size={inPrint ? "lg" : "md"} weight={"medium"} style={{ marginBottom: 0 }}>
                                {x}
                            </AtParagraph>
                        </li>
                    ))}
                </ul>
            )}
            {postBulletPointRows.map((x, i) => (
                <AtParagraph size={inPrint ? "lg" : "md"} weight={"medium"} style={{ marginBottom: 0 }} key={i}>
                    {x}
                </AtParagraph>
            ))}
        </>
    );
}
