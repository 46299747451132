import { CustomTextInput } from "../../..";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

export interface ISidePanelSearchProps {
    appliedFilter: string | undefined;
    setAppliedFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    placeholder?: string;
}

export const SidePanelSearch: React.FC<ISidePanelSearchProps> = observer((props) => {
    const { appliedFilter, setAppliedFilter, placeholder, ...otherProps } = props;

    useEffect(() => {
        if (appliedFilter) {
            setSearched(appliedFilter);
        } else {
            setSearched("");
        }
    }, [appliedFilter]);

    const [searched, setSearched] = useState("");

    const handleChange = (e: any) => {
        const text = e.target.value;

        if (text === "") {
            handleClear();
        } else {
            setSearched(text);
            setAppliedFilter(text);
        }
    };

    const handleClear = () => {
        setSearched("");
        setAppliedFilter(undefined);
    };

    const rightIcon: IconProp | undefined = useMemo(() => {
        if (searched) {
            return ["fas", "times-circle"];
        }
        return;
    }, [searched]);

    return (
        <div className="SidePanelSearch" {...otherProps}>
            <CustomTextInput
                leftIcon={["fas", "search"]}
                onChange={handleChange}
                fieldName="SidePanelSearch"
                value={searched}
                hideLabel
                rightIcon={rightIcon}
                onRightIconClick={handleClear}
                placeholder={placeholder}
            />
        </div>
    );
});
