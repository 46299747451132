import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { ModalTypes } from "../types";
import { action, autorun, observable } from "mobx";

export class ModalStore extends BaseStore {
    @observable public showModal: boolean = false;
    @observable public modalType?: ModalTypes = undefined;
    @observable public childProps?: { [key: string]: any } = {};

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
    }

    @action
    openModal = (modalType: ModalTypes, childProps?: { [key: string]: any }) => {
        this.showModal = false;
        this.modalType = modalType;
        this.childProps = childProps || {};
        this.showModal = true;
    };

    @action
    closeModal = () => {
        this.showModal = false;
        this.modalType = undefined;
        this.childProps = undefined;
    };

    @action
    toggleModal = () => {
        this.showModal = !this.showModal;
    };

    autorunner = autorun(() => {});
}
