import "./index.less";
import * as React from "react";
import {
    AppScope,
    BaseCompetencySet,
    ContentStore,
    ItemType,
    LocalizationStore,
    ModalStore,
    UrlFormatter,
} from "@atman/business";
import { AtButton, IReactSelectOptionObject } from "@atman/design-system";
import { AtEntityWizard } from "../../../../components/AtEntityWizard";
import { BLANK_VALUE } from "../Steps/CompetencySetTemplateStep";
import { BaseForm } from "../../../../components/BaseForm";
import { IAtWizardRef, IWizardStep } from "../../../../components/AtWizard";
import { IRoutedAppContext } from "../../../../contexts";
import { StaticContext } from "react-router";
import { action, computed, observable, when } from "mobx";
import autobind from "autobind-decorator";

export interface IBaseCompetencySetWizardProps
    extends IRoutedAppContext<{ id?: string }, StaticContext, { duplicatedId?: string }> {
    contentStore?: ContentStore;
    localizationStore?: LocalizationStore;
    modalStore?: ModalStore;
}

export abstract class BaseCompetencySetWizard<TProps, TSet extends BaseCompetencySet> extends BaseForm<
    IBaseCompetencySetWizardProps & TProps,
    {}
> {
    protected readonly wizardRef: React.RefObject<IAtWizardRef> = React.createRef<IAtWizardRef>();
    @observable protected selectedCompetencyIds: string[] = [];
    @observable protected localizedNames: Map<string, string> = new Map<string, string>();
    @observable protected localizedDescriptions: Map<string, string> = new Map<string, string>();
    @observable protected departmentId?: IReactSelectOptionObject;
    @observable protected createdFromId?: string = BLANK_VALUE;
    @observable protected templates: TSet[] = [];

    protected abstract creationLabelKey: string;
    protected abstract wizardDomId: string;
    protected abstract setListPath: string;
    abstract get steps(): IWizardStep[];
    protected abstract loadTemplates(): Promise<void>;
    protected abstract loadFromSet(
        set: TSet,
        options?: { isNewSet?: boolean; isSystemSet?: boolean; isDuplicate?: boolean },
    ): void;
    protected abstract findSetById(id: string): TSet | undefined;
    protected abstract onSubmit: () => Promise<void>;
    protected abstract itemType: ItemType;

    async componentDidMount(): Promise<void> {
        if (this.shouldIncludeTemplateStep) {
            await this.loadTemplates();
        }

        if (!this.props.contentStore!.hasLoadedCompetencies) {
            this.props.contentStore!.loadCompetencies();
        }

        this.loadFromExisting();
    }

    @computed protected get matchedSetId(): string | undefined {
        const { match } = this.props;

        return match?.params?.id;
    }

    @computed protected get duplicatedSetId(): string | undefined {
        const { location } = this.props;

        return location?.state?.duplicatedId;
    }

    @computed protected get shouldIncludeTemplateStep(): boolean {
        const { scope } = this.props;

        return scope === AppScope.Client && !this.matchedSetId && !this.duplicatedSetId;
    }

    @action private async loadFromExisting() {
        await when(() => !this.isLoading);

        if (this.matchedSetId) {
            const existingSet = this.findSetById(this.matchedSetId);

            if (existingSet) {
                this.loadFromSet(existingSet);
            }
        } else if (this.duplicatedSetId) {
            const existingSet = this.findSetById(this.duplicatedSetId);

            if (existingSet) {
                this.loadFromSet(existingSet, { isDuplicate: true });
            }
        }
    }

    @action protected onCompetencyIdSelection = (id: string) => {
        const index = this.selectedCompetencyIds.indexOf(id);

        if (index === -1) {
            this.selectedCompetencyIds.push(id);
        } else {
            this.selectedCompetencyIds.splice(index, 1);
        }
    };

    @action protected onTemplateChange = (id: string): void => {
        this.createdFromId = id;
    };

    @action protected onLocalizedNameChange = (locale: string, name: string): void => {
        this.localizedNames.set(locale, name);
    };

    @action protected onLocalizedDescriptionChange = (locale: string, description: string): void => {
        this.localizedDescriptions.set(locale, description);
    };

    onFormSubStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.wizardRef.current?.goNext();
    };

    @autobind
    onFormSubmit_TemplateStep() {
        const {} = this.props;

        if (this.createdFromId === BLANK_VALUE) {
            return;
        }

        const template = this.templates.find((x) => x.id === this.createdFromId);

        if (!template) {
            console.error(`Could not find system job with ID: ${this.createdFromId}`);
            return false;
        }

        this.loadFromSet(template, { isSystemSet: true });
        return;
    }

    @autobind
    protected validateForm_CompetencySelectionStep(): boolean {
        return this.selectedCompetencyIds.length > 0;
    }

    @autobind
    protected validateForm_TemplateStep(): boolean {
        return this.createdFromId !== undefined;
    }

    protected abstract validateForm_InformationStep(): boolean;

    renderCustomLeftHeaderContent(): JSX.Element {
        const { localizationStore } = this.props;

        return (
            <div style={{ fontWeight: 700 }}>
                {this.matchedSetId
                    ? this.localizedNames.get(localizationStore!.currentShortLocale)
                    : this.creationLabelKey.localize()}
            </div>
        );
    }

    renderCustomRightHeaderContent() {
        if (!this.matchedSetId) {
            return null;
        }

        return (
            <>
                <AtButton onClick={this.onSubmit}>{"global.buttons.labels.saveAndClose".localize()}</AtButton>
            </>
        );
    }

    protected abstract get sidePanelContentSections(): { section: JSX.Element; flexible?: boolean }[];

    renderEntityOverviewContent(): JSX.Element {
        return (
            <>
                {this.sidePanelContentSections.map((x, i) => (
                    <div key={i} className={`content-section ${x.flexible ? "flexible" : ""}`.trim()}>
                        {x.section}
                    </div>
                ))}
            </>
        );
    }

    render(): JSX.Element {
        const { history, localizationStore } = this.props;

        return (
            <div id={this.wizardDomId} className="BaseCompetencySetWizard">
                <AtEntityWizard
                    entityType={this.itemType}
                    entityName={this.localizedNames.get(localizationStore!.currentShortLocale)}
                    entityOverviewContent={this.renderEntityOverviewContent()}
                    steps={this.steps}
                    customRightHeaderContent={this.renderCustomRightHeaderContent()}
                    onSubmit={this.onSubmit}
                    cancelAction={() => history.push(UrlFormatter.formatReactPath(this.setListPath))}
                    allowStepClickNavigation={!!this.matchedSetId}
                    wizardRef={this.wizardRef}
                />
            </div>
        );
    }
}
