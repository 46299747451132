import "./AcceptOrDenyConfirmation.less";
import * as React from "react";
import { AtTitleSubtitle, Colors } from "@atman/design-system";

export interface IAcceptOrDenyConfirmation {
    title: string;
    subtitle: string;
    color: "success" | "danger";
}

export const AcceptOrDenyConfirmation: React.FC<IAcceptOrDenyConfirmation> = (props) => {
    const { title, color, subtitle } = props;

    const style: React.CSSProperties = {
        backgroundColor: color === "success" ? Colors.greenBg : Colors.redBg,
        borderRadius: "6px",
        border: color === "success" ? "dashed 1px #52BD94" : "dashed 1px #D14343",
    };

    return (
        <div className="AcceptOrDenyConfirmation">
            <AtTitleSubtitle style={style} className={color} title={title} subtitle={subtitle} />
        </div>
    );
};
