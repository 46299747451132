import { AnalysisTileEnum } from "../models/Tile";
import { ReactAppSettings } from "./ReactAppSettings";

export class UrlFormatter {
    public static format(url: string, id: string | number): string {
        return url.replace("{id}", String(id));
    }

    public static formatReactPath(path: string): string {
        if (path.includes(ReactAppSettings.basePath)) {
            return path;
        } else {
            return `${ReactAppSettings.basePath}${path}`;
        }
    }

    public static formatPictureUrlForSize(pictureUrl: string, size: "sm" | "md" | "lg" | "xl" | "xxl" = "lg"): string {
        const splitIndex = pictureUrl.lastIndexOf(".");
        const splitUrl = [pictureUrl.slice(0, splitIndex), pictureUrl.slice(splitIndex)];
        let urlWithoutExtension = splitUrl[0];
        const extension = splitUrl[1];

        switch (size) {
            case "sm":
                urlWithoutExtension += "_small";
                break;
            case "md":
                urlWithoutExtension += "_medium";
                break;
            case "lg":
                break;
            default:
                break;
        }

        urlWithoutExtension += extension;

        return urlWithoutExtension;
    }

    public static getBlobFileUrl(path: string, languageCode: string): string {
        const publicBlobPath = ReactAppSettings?.appUrls?.publicBlobPath ?? "https://secure.atmanco.com/PublicFiles";

        return `${publicBlobPath}/${path}/${languageCode}`;
    }

    public static getTeamDevelopmentReportUrl(teamId: string, candidateId: string): string {
        return `Print/App/TeamDevelopment/${teamId}/${candidateId}`;
    }

    public static getEmployeeDevelopmentReportUrl(candidateProId: string, processId: string): string {
        return `Print/App/Employee/Development/${candidateProId}/${processId}`;
    }

    public static getReportUrl(
        tile: AnalysisTileEnum | string,
        subjectType: ReportSubjectType,
        subjectIds: string[],
    ): string {
        let reportUrl = `${typeof tile === "number" ? AnalysisTileEnum[tile] : tile}/${subjectType}`;
        for (const subjectId of subjectIds) {
            reportUrl += `/${subjectId}`;
        }

        return `Print/App/${reportUrl}`;
    }

    public static getWeblateUrl(
        view: WeblateView,
        contentType: LocalizedDynamicContentType,
        languageCode: string,
        searchQuery?: string,
    ): string {
        const projectSlug = ReactAppSettings.appUrls.weblateDynamicContentProjectSlug;

        let url = `${ReactAppSettings.appUrls.weblateUrl}/${view}/${projectSlug}/${contentType}/${languageCode}`;

        if (searchQuery) {
            url += `?q=${searchQuery}`;
        }

        return url;
    }
}

export enum ReportSubjectType {
    Candidate = "Candidate",
    Team = "Team",
}

export type LocalizedDynamicContentType = "fits";
export type WeblateView = "zen" | "translate";
