import { Colors } from "../../styles";

export const stripeStyles: Dictionary<string, stripe.elements.Style> = {
    base: {
        fontFamily: "Quicksand",
        color: Colors.greyBase,
    },
    complete: {},
    empty: {},
    invalid: {},
};
