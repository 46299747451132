import "./index.less";
import * as React from "react";
import {
    AtBadge,
    AtTitle,
    ExtraAndDefaultSizes,
    HeadingType,
    IInitialsAvatarProps,
    InitialsAvatar,
    XXL,
} from "@atman/design-system";
import { AtPersonAvatar } from "@atman/design-system";
import { BaseResponsiveComponent } from "..";
import { Candidate, IShortCandidate, ReactAppSettings, UrlFormatter } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface ICandidateIdentityViewProps {
    candidate?: Candidate | IShortCandidate;
    size?: "sm" | "md" | "lg";
    isTeamLead?: boolean;
    onClick?: () => void;
    showInitialsUnderPicture?: boolean;
    avatarProps?: Partial<IInitialsAvatarProps>;
    showEmail?: boolean;
    headingSize?: HeadingType;
    customClassName?: string;
}

@inject()
@observer
export class CandidateIdentityView extends BaseResponsiveComponent<ICandidateIdentityViewProps> {
    protected get rootElementClassName(): string | undefined {
        const { size } = this.props;

        return `CandidateIdentityView size-${size}`;
    }

    protected get containerProps(): React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement> {
        const { onClick } = this.props;

        return { onClick };
    }

    renderAll() {
        const {
            candidate,
            isTeamLead = false,
            size = "sm",
            avatarProps,
            showInitialsUnderPicture = false,
            headingSize,
            customClassName,
        } = this.props;

        const candidateName =
            candidate?.firstName || candidate?.lastName
                ? `${candidate?.firstName} ${candidate?.lastName}`
                : candidate?.email;

        let pictureSize: ExtraAndDefaultSizes | XXL;
        let nameComponent: React.ReactNode;

        switch (size) {
            case "sm":
                pictureSize = "sm";
                nameComponent = (
                    <AtTitle className={customClassName} headingType={headingSize ?? 6} title={candidateName} />
                );
                break;
            case "md":
                pictureSize = "lg";
                nameComponent = <AtTitle className={customClassName} headingType={4} title={candidateName} />;
                break;
            case "lg":
                pictureSize = "xl";
                nameComponent = <AtTitle className={customClassName} headingType={2} title={candidateName} />;
                break;
        }

        return (
            <>
                <div className={`left-section ${isTeamLead ? "is-team-lead" : ""}`}>
                    <div className="profile-picture-container">
                        {candidate ? (
                            <>
                                <AtPersonAvatar
                                    firstName={candidate.firstName}
                                    lastName={candidate.lastName}
                                    email={candidate.email}
                                    pictureUri={candidate.profilePictureUri}
                                    pictureFormatFunc={UrlFormatter.formatPictureUrlForSize}
                                    size={pictureSize}
                                    displayIconInsteadOfAvatar={showInitialsUnderPicture}
                                />
                                {showInitialsUnderPicture && (
                                    <InitialsAvatar
                                        text={[candidate.firstName, candidate.lastName]}
                                        size={"sm"}
                                        className={"candidate-initials"}
                                        {...avatarProps}
                                    />
                                )}
                            </>
                        ) : (
                            <div className="blank-profile-picture" />
                        )}
                    </div>
                    <div className="info-container">
                        {nameComponent}
                        {candidate && (
                            <div className="job-name">
                                {ReactAppSettings.viewBag.baseInfo.competencyDevelopmentEnabled
                                    ? candidate.jobName ?? "global.noJobAssigned".localize()
                                    : candidate.email}
                            </div>
                        )}
                    </div>
                </div>
                {candidate && isTeamLead && (
                    <div className="right-section">
                        <AtBadge size={"sm"}>{this.width >= 1300 ? "global.manager".localize() : "M"}</AtBadge>
                    </div>
                )}
            </>
        );
    }
}
