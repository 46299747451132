import "./index.less";
import { AtBaseCard, DefaultComponentProps } from "@atman/design-system";
import { CustomInput } from "reactstrap";
import React from "react";
import cn from "classnames";

export type RadioPosition = "top-left" | "top-right";

export interface IAtBaseSelectableCardProps extends DefaultComponentProps {
    inputName: string;
    inputId: string;
    inputValue: any;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    active: boolean;
    radioPosition?: RadioPosition;
    disabled?: boolean;
    className?: string;
    id?: string;
}

export const AtBaseSelectableCard: React.FC<IAtBaseSelectableCardProps> = (props) => {
    const {
        inputName,
        inputId,
        inputValue,
        onChange,
        active,
        className,
        id,
        children,
        disabled = false,
        radioPosition = "top-right",
        ...otherProps
    } = props;

    return (
        <AtBaseCard
            className={cn("AtBaseSelectableCard", `radio-position-${radioPosition}`, className, { active: active })}
            id={id}
            disabled={disabled}
            {...otherProps}
        >
            {children}
            <CustomInput
                type={"radio"}
                name={inputName}
                id={inputId}
                value={inputValue}
                checked={active}
                onChange={onChange}
                disabled={disabled}
            />
        </AtBaseCard>
    );
};
