import "./index.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IBillingInformation,
    ICountry,
    LocalizationStore,
    PartnerStore,
    ReactAppSettings,
    ScopedOrganizationStore,
    ToastProvider,
} from "@atman/business";
import { AtSelect, AtTabCard, IReactSelectOptionObject } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { CultureSelectField } from "../CommonFields";
import { CustomTextInput } from "../CustomTextInput";
import { action, observable } from "mobx";
import { findCultureFromSet } from "@atman/business/lib/stores/LocalizationStore";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IBillingInformationFormProps extends IAppContext {
    currentBillingInformation: IBillingInformation;
    ownerId?: string;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    localizationStore?: LocalizationStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.localizationStore,
    GlobalStores.scopedOrganizationStore,
)
@observer
class BillingInformationFormComp extends BaseForm<IBillingInformationFormProps, {}> {
    @observable public contactName: string = "";
    @observable public phoneNumber: string = "";
    @observable public contactEmail: string = "";
    @observable public legalName: string = "";
    @observable public language: IReactSelectOptionObject;

    @observable public streetAddress: string = "";
    @observable public streetAddress2: string = "";
    @observable public countryId: IReactSelectOptionObject;
    @observable public stateId: IReactSelectOptionObject | null = null;
    @observable public city: string = "";
    @observable public zipCode: string = "";

    constructor(props: IBillingInformationFormProps) {
        super(props);

        this.resetContactInformation();
        this.resetBillingAddress();

        this.language = {
            value: this.props.localizationStore!.currentLocale.cultureInfo,
            label: this.props.localizationStore!.currentLocale.shortDisplay,
        };
    }

    componentDidMount(): void {
        this.resetContactInformation();
        this.resetBillingAddress();
    }

    getLocalizableName = (localizableName: Dictionary<string, string>, fallback?: string) => {
        const { localizationStore } = this.props;

        if (localizableName.hasOwnProperty(localizationStore!.currentLocale.name)) {
            return localizableName[localizationStore!.currentLocale.cultureInfo];
        }

        return fallback || "";
    };

    @autobind
    async saveContactInformation() {
        const country = this.findCountry(this.props.currentBillingInformation.address.country);

        if (!country) {
            ToastProvider.error("global.invalidCountry".localize());
            return;
        }

        const state = this.findState(country, this.props.currentBillingInformation.address.state);

        if (country.states.any() && !state) {
            ToastProvider.error("global.invalidState".localize());
            return;
        }

        const updatedBillingInformation = {
            contactName: this.contactName,
            email: this.contactEmail,
            legalName: this.legalName,
            language: this.language.value,
            address: {
                ...this.props.currentBillingInformation.address,
                country: country.id,
                state: state ? state.id : "",
                phone: this.phoneNumber,
            },
        } as IBillingInformation;

        this.setIsLoading(true);

        switch (this.props.scope) {
            case AppScope.Client:
                await this.props.scopedOrganizationStore!.updateBillingInformation(updatedBillingInformation);
                break;
            case AppScope.Partner:
                if (this.props.ownerId) {
                    await this.props.clientStore!.updateClientBillingInformation(
                        this.props.ownerId,
                        updatedBillingInformation,
                    );
                } else {
                    await this.props.scopedOrganizationStore!.updateBillingInformation(updatedBillingInformation);
                }
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.updatePartnerBillingInformation(
                    this.props.ownerId!,
                    updatedBillingInformation,
                );
                break;
            default:
                this.setIsLoading(false);
                throw new Error(`Unhandled scope for component: ${this.props.scope}`);
        }

        this.setIsLoading(false);
    }

    @action.bound
    resetContactInformation = () => {
        const { localizationStore, currentBillingInformation } = this.props;

        this.contactName = currentBillingInformation.contactName || "";
        this.phoneNumber = currentBillingInformation.address.phone || "";
        this.contactEmail = currentBillingInformation.email || "";
        this.legalName = currentBillingInformation.legalName || "";

        const locale = findCultureFromSet(
            localizationStore!.availableLanguages,
            currentBillingInformation.language ?? "",
        );

        if (locale) {
            this.language = {
                value: locale.cultureInfo,
                label: locale.shortDisplay,
            };
        }
    };

    @autobind
    async saveBillingAddress() {
        const updatedBillingInformation = {
            ...this.props.currentBillingInformation,
            address: {
                ...this.props.currentBillingInformation.address,
                address: this.streetAddress,
                address2: this.streetAddress2,
                city: this.city,
                zipCode: this.zipCode,
                state: this.stateId ? this.stateId.value : undefined,
                country: this.countryId.value,
            },
        } as IBillingInformation;

        switch (this.props.scope) {
            case AppScope.Client:
                await this.props.scopedOrganizationStore!.updateBillingInformation(updatedBillingInformation);
                break;
            case AppScope.Partner:
                if (this.props.ownerId) {
                    await this.props.clientStore!.updateClientBillingInformation(
                        this.props.ownerId,
                        updatedBillingInformation,
                    );
                } else {
                    await this.props.scopedOrganizationStore!.updateBillingInformation(updatedBillingInformation);
                }
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.updatePartnerBillingInformation(
                    this.props.ownerId!,
                    updatedBillingInformation,
                );
                break;
            default:
                throw new Error(`Unhandled scope for component: ${this.props.scope}`);
        }
    }

    findCountry = (countryCode: string) => {
        return ReactAppSettings.appModel.countries.find(
            (x) => x.code && x.code.toUpperCase() === countryCode?.toUpperCase(),
        );
    };

    findState = (country: ICountry, stateCode: string) => {
        return country.states.find((x) => x.code && x.code.toUpperCase() === stateCode?.toUpperCase());
    };

    @action.bound
    resetBillingAddress = () => {
        this.streetAddress = this.props.currentBillingInformation.address.address || "";
        this.streetAddress2 = this.props.currentBillingInformation.address.address2 || "";
        this.city = this.props.currentBillingInformation.address.city || "";
        this.zipCode = this.props.currentBillingInformation.address.zipCode || "";

        const currentCountry = this.findCountry(this.props.currentBillingInformation.address.country);

        if (currentCountry) {
            this.countryId = {
                value: currentCountry.id,
                label: this.getLocalizableName(currentCountry.localizableName),
            };

            const currentState = this.findState(currentCountry, this.props.currentBillingInformation.address.state);

            if (currentState) {
                this.stateId = {
                    value: currentState.id,
                    label: this.getLocalizableName(currentState.localizableName),
                };
            } else {
                this.stateId = null;
            }
        } else {
            console.error(
                `Couldn't find a country matching the code: ${this.props.currentBillingInformation.address.country}`,
            );
        }
    };

    @action.bound
    onCountryChange(option: IReactSelectOptionObject) {
        this.countryId = option;
        this.stateId = null;
    }

    @action.bound
    onStateChange(option: IReactSelectOptionObject) {
        this.stateId = option ? option : null;
    }

    @action.bound
    onLanguageChange(option: IReactSelectOptionObject) {
        this.language = option;
    }

    render() {
        const {} = this.props;

        const countries = ReactAppSettings.appModel.countries;
        const countryOptions: IReactSelectOptionObject[] = countries.map((x) => ({
            value: x.id,
            label: this.getLocalizableName(x.localizableName),
        }));

        const currentCountry = countries.find((x) => x.id === this.countryId?.value);

        let stateOptions: IReactSelectOptionObject[] = [];

        if (currentCountry) {
            stateOptions = currentCountry.states.map((x) => ({
                value: x.id,
                label: this.getLocalizableName(x.localizableName),
            }));
        }

        return (
            <div id="BillingInformationForm">
                <AtTabCard
                    cardTitle={"global.billingInformationCardTitle".localize()}
                    className={"contact-information-form"}
                >
                    <div className="inputs">
                        <CustomTextInput
                            fieldName={"contactName"}
                            value={this.contactName}
                            onChange={this.onTextFieldChange}
                        />
                        <CustomTextInput
                            fieldName={"legalName"}
                            value={this.legalName}
                            onChange={this.onTextFieldChange}
                        />
                        <CustomTextInput
                            fieldName={"phoneNumber"}
                            value={this.phoneNumber}
                            onChange={this.onTextFieldChange}
                        />
                        <CustomTextInput
                            fieldName={"contactEmail"}
                            value={this.contactEmail}
                            onChange={this.onTextFieldChange}
                        />
                        <CultureSelectField cultureValue={this.language} onChange={this.onLanguageChange} />
                    </div>
                    <CardSaveButtons
                        saveAction={this.saveContactInformation}
                        cancelAction={this.resetContactInformation}
                        isLoading={this.isLoading}
                    />
                </AtTabCard>
                <AtTabCard cardTitle={"global.billingAddress".localize()} className={"billing-address-form"}>
                    <div className="inputs">
                        <CustomTextInput
                            fieldName={"streetAddress"}
                            value={this.streetAddress}
                            onChange={this.onTextFieldChange}
                        />
                        <CustomTextInput
                            fieldName={"streetAddress2"}
                            value={this.streetAddress2}
                            onChange={this.onTextFieldChange}
                        />
                        <AtSelect
                            label={"global.country".localize()}
                            options={countryOptions}
                            value={this.countryId}
                            onChange={this.onCountryChange}
                            placeholder={"country_placeholder".localize()}
                        />
                        <AtSelect
                            label={"global.state".localize()}
                            options={stateOptions}
                            value={this.stateId}
                            onChange={this.onStateChange}
                            placeholder={"state_placeholder".localize()}
                            isDisabled={!this.countryId?.value || !stateOptions.any()}
                        />
                        <CustomTextInput fieldName={"city"} value={this.city} onChange={this.onTextFieldChange} />
                        <CustomTextInput fieldName={"zipCode"} value={this.zipCode} onChange={this.onTextFieldChange} />
                    </div>
                    <CardSaveButtons
                        saveAction={this.saveBillingAddress}
                        cancelAction={this.resetBillingAddress}
                        isLoading={this.isLoading}
                    />
                </AtTabCard>
            </div>
        );
    }

    _onSave(): any {}

    validateForm(): boolean {
        return false;
    }
}

const BillingInformationForm = withAppContext(BillingInformationFormComp);

export { BillingInformationForm };
