import "./index.less";
import * as React from "react";
import { AtScoreReviewIndicator } from "../../../../../../../AtScoreReviewIndicator";
import { CompDevObservableBehaviorResult, CompetencyDevelopmentAssessmentType, PrintModeEnum } from "@atman/business";
import { LikertScoreDisplay } from "../../../../../../../LikertScoreDisplay";
import { v4 } from "uuid";

export interface IObservableBehaviorsTableProps {
    observableBehaviors?: CompDevObservableBehaviorResult[];
    printMode?: PrintModeEnum;
}

export const ObservableBehaviorsTable: React.FC<IObservableBehaviorsTableProps> = (props) => {
    const { observableBehaviors, printMode } = props;

    const funcCompId = React.useMemo(() => v4(), []);
    return (
        <div className="ObservableBehaviorsTable">
            <table className="table">
                <thead>
                    <tr>
                        <th>{"competencyDevelopment.observableBehaviors".localize()}</th>
                        <th>{"global.average".localize()}</th>
                    </tr>
                </thead>
                <tbody>
                    {observableBehaviors &&
                        observableBehaviors.map((x) => {
                            const reviewScore = x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Review);
                            const reviewNotes = x.assessmentNotes.get(CompetencyDevelopmentAssessmentType.Review);

                            return (
                                <tr key={x.code}>
                                    <td>
                                        <div>{x.name}</div>
                                        {reviewNotes ? (
                                            <div className="observable-behavior-notes">
                                                {reviewNotes.split("\n").map((x, i) => (
                                                    <div key={`observable-behavior-note-${i}`}>{x}</div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </td>
                                    <td>
                                        <div>
                                            <LikertScoreDisplay
                                                score={reviewScore ?? x.averageBetweenManagerAndEmployee}
                                            />
                                            {!!reviewScore && printMode === undefined && (
                                                <AtScoreReviewIndicator
                                                    tooltipTargetId={`observable_behavior_${x.id}_${funcCompId}`}
                                                    score={x.averageBetweenManagerAndEmployee}
                                                    scoreDisplayMode={"likert"}
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};
