import { AngleBasedPie } from "./AngleBasedPie";
import { Colors } from "../../../../styles/variables/colors";
import {
    HALFPIE_CORNERRADIUS,
    HALFPIE_INNERRADIUS,
    HALFPIE_PADDING,
    THREEQUARTERPIE_ENDANGLE,
    THREEQUARTERPIE_STARTANGLE,
} from "./constants";
import { HalfMeterRangeHighlight } from "./HalfMeterRangeHighlight";
import { IHalfMeterPieProps } from "./HalfMeterPie";
import { VictoryPie } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IThreeQuaterMeterPieProps extends IHalfMeterPieProps {}

@inject()
@observer
export class ThreeQuaterMeterPie extends React.Component<IThreeQuaterMeterPieProps> {
    public static defaultProps = { value: 0 };

    render() {
        const { value, maxValue, color, range } = this.props;

        return (
            <>
                {/* Gray Background */}
                <AngleBasedPie
                    startAngle={THREEQUARTERPIE_STARTANGLE}
                    endAngle={THREEQUARTERPIE_ENDANGLE}
                    color={Colors.greyBg}
                />

                {/* Range highlight */}
                <HalfMeterRangeHighlight range={range} maxValue={maxValue} />

                {/* Main meter */}
                {
                    <VictoryPie
                        animate={true}
                        data={[
                            { x: 1, y: value!, label: "", active: true }, // The main value
                            { x: 2, y: maxValue - value!, label: "", active: false }, // The rest. Since it's a single value, we need to fill the rest of the pie
                        ]}
                        labels={() => ""}
                        standalone={false}
                        innerRadius={HALFPIE_INNERRADIUS}
                        cornerRadius={HALFPIE_CORNERRADIUS}
                        startAngle={THREEQUARTERPIE_STARTANGLE}
                        endAngle={THREEQUARTERPIE_ENDANGLE}
                        padding={HALFPIE_PADDING}
                        style={{
                            data: {
                                fill: ({ datum: { active } }) => {
                                    if (active) {
                                        return color;
                                    }
                                    return "transparent";
                                },
                            },
                        }}
                    />
                }
            </>
        );
    }
}
