import "./index.less";
import * as React from "react";
import { ColorProperty } from "csstype";
import { InitialsAvatar } from "@atman/design-system";

export interface ICandidateInterpretationHeaderProps {
    bgColor: ColorProperty;
    textColor: ColorProperty;
    candidateDisplayName: string;
}

export const CandidateInterpretationHeader: React.FC<ICandidateInterpretationHeaderProps> = (props) => {
    const { candidateDisplayName, bgColor, textColor } = props;

    return (
        <div className="CandidateInterpretationHeader">
            <InitialsAvatar text={candidateDisplayName} size={"sm"} bgColor={bgColor} textColor={textColor} />
            <div className="name">{candidateDisplayName}</div>
        </div>
    );
};
