import "./RequestCredits.less";
import * as React from "react";
import { AtLink, AtTitle } from "../../../../design-system";
import { BaseModal } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, ModalStore, TransactionStore } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IRequestCreditsProps {
    modalStore?: ModalStore;
    transactionStore?: TransactionStore;
}

@inject(GlobalStores.modalStore, GlobalStores.transactionStore)
@observer
export class RequestCredits extends React.Component<IRequestCreditsProps, {}> {
    render() {
        const { transactionStore } = this.props;

        const phone =
            !!transactionStore!.managersContactInformation &&
            !!transactionStore!.managersContactInformation.address &&
            transactionStore!.managersContactInformation.address.phone;
        const extension =
            (!!transactionStore!.managersContactInformation &&
                !!transactionStore!.managersContactInformation.address &&
                transactionStore!.managersContactInformation.address.extension) ||
            (!!phone && phone.split("#")[1]);
        const email =
            !!transactionStore!.managersContactInformation && transactionStore!.managersContactInformation.email;
        const address =
            !!transactionStore!.managersContactInformation && transactionStore!.managersContactInformation.address;

        return (
            <BaseModal modalTitle={"global.requestProducts".localize()} id={"RequestCredits"}>
                <div className="header-message">{"global.requestProductsIntroText".localize()}</div>
                <div className="contact-info-container">
                    {!!phone ? (
                        <div className="contact-info-card contact-phone">
                            <div className="card-title-container">
                                <div className="icon-container">
                                    <FontAwesomeIcon icon={"phone"} flip={"horizontal"} />
                                </div>
                                <AtTitle
                                    headingType={4}
                                    className="card-title"
                                    title={`${"global.call".localize()} ${
                                        transactionStore!.managersContactInformation.name
                                    }`}
                                />
                            </div>
                            <div className="card-content">
                                <div className="office-phone">
                                    {`${"global.officePhone".localize()}: `}
                                    <AtLink color={"secondary"} href={`tel:${phone}`}>
                                        {phone}
                                    </AtLink>
                                </div>
                                <div className="extension">
                                    {`${"global.extension".localize()}: `}
                                    <AtLink color={"secondary"} href={`tel:${phone},,${extension}`}>
                                        {extension}
                                    </AtLink>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {!!email ? (
                        <div className="contact-info-card contact-email">
                            <div className="card-title-container">
                                <div className="icon-container">
                                    <FontAwesomeIcon icon={"envelope"} />
                                </div>
                                <AtTitle
                                    headingType={4}
                                    className="card-title"
                                    title={`${"global.email".localize()} ${
                                        transactionStore!.managersContactInformation.name
                                    }`}
                                />
                            </div>
                            <div className="card-content">
                                <div className="email">
                                    {`${"global.email".localize()}: `}
                                    <AtLink color={"secondary"} href={`mailto:${email}`}>
                                        {email}
                                    </AtLink>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {!!address ? (
                        <div className="contact-info-card contact-address">
                            <div className="card-title-container">
                                <div className="icon-container">
                                    <FontAwesomeIcon icon={"map-marked"} />
                                </div>
                                <AtTitle
                                    className="card-title"
                                    headingType={4}
                                    title={"global.whereToFindThem".localize()}
                                />
                            </div>
                            <div className="card-content">
                                <div className="address-line-1">{address.address}</div>
                                <div className="address-line-2">{address.address2}</div>
                                <div className="adress-region">
                                    {`${address.city}, ${address.state}, ${address.country}`}
                                </div>
                                <div className="address-postal-code">{address.zipCode}</div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </BaseModal>
        );
    }
}
