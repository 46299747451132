import "./index.less";
import * as React from "react";
import { CustomInput } from "reactstrap";
import { DefaultSizes, XS } from "../../../internal";

export interface IAtCheckboxBaseProps {
    checked: boolean;
    size?: XS | DefaultSizes;
    indeterminate?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    value?: any;
    fieldName?: string;
    id?: string;
    disabled?: boolean;
}

export const AtCheckboxBase: React.FC<IAtCheckboxBaseProps> = (props: IAtCheckboxBaseProps) => {
    const { checked, disabled, fieldName, size = "md", indeterminate, ...otherProps } = props;

    return (
        <CustomInput
            className={`AtCheckboxBase size-${size} ${disabled ? "disabled" : ""}`}
            type={"checkbox"}
            checked={checked}
            disabled={disabled}
            name={fieldName}
            indeterminate={indeterminate?.toString() ?? false.toString()}
            {...otherProps}
        />
    );
};
