import "./ProductElement.less";
import { AtTitle } from "@atman/design-system";
import { IAtmanCoProductInfo } from "../index";
import React from "react";
import cn from "classnames";

export interface IProductElementProps {
    productInfo: IAtmanCoProductInfo;
    onClick: () => void;
    active?: boolean;
    disabled?: boolean;
}

const ProductElement: React.FC<IProductElementProps> = (props) => {
    const { productInfo, onClick, active = false, disabled = false } = props;

    return (
        <div className={cn("ProductElement", { active, disabled })} onClick={onClick}>
            <AtTitle headingType={3} className={"product-name-prefix"} title={productInfo.namePrefix} />
            <AtTitle headingType={3} className={"product-name-suffix"} title={productInfo.nameSuffix} />
            <div className={`active-indicator ${active ? "active" : ""}`}></div>
        </div>
    );
};

export default ProductElement;
