import "./index.less";
import * as React from "react";

export interface IAtNotesProps {
    notes: string;
}

const AtNotes: React.FC<IAtNotesProps> = (props) => {
    const { notes } = props;
    return <div className="AtNotes" dangerouslySetInnerHTML={{ __html: notes }} />;
};

export default AtNotes;
