import {
    AssessmentStructure,
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessResultsData,
    ISelectedObjectiveData,
    SelectedSubCompetencyToImproveData,
} from "@atman/business";
import { IPersonInfo } from "../../components";
import { createContext } from "react";

export interface ICompDevEvaluationContext {
    assessmentId: string;
    assessmentType: CompetencyDevelopmentAssessmentType;
    assessmentStatus: CompetencyDevelopmentAssessmentStatus;
    assessmentStructure?: AssessmentStructure;
    processResultsData?: CompetencyDevelopmentProcessResultsData;
    employeeDetails: IPersonInfo & { id: string; jobName?: string };
    managerDetails: Partial<IPersonInfo> & { id: string };
    teamId: string;
    loadAssessmentStructure(): Promise<void>;
    loadProcessResultsData(): Promise<void>;
    startAssessment(): Promise<void>;
    saveAssessment(
        competencyId: string,
        observableBehaviorScores: Map<string, number | undefined>,
        observableBehaviorNotes: Map<string, string | undefined>,
    ): Promise<void>;
    completeAssessment(): Promise<void>;
    selectToImproveObjectives(
        selectedObjectives: Map<string, Map<string, SelectedSubCompetencyToImproveData>>,
    ): Promise<void>;
    selectToLeverageObjectives(selectedObjectives: Map<string, Map<string, ISelectedObjectiveData>>): Promise<void>;
}

export const compDevEvaluationContext = createContext<ICompDevEvaluationContext | null>(null);

export const CompDevEvaluationContextProvider = compDevEvaluationContext.Provider;
export const CompDevEvaluationContextConsumer = compDevEvaluationContext.Consumer;
