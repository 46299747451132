import { AppScope, Role, UserState } from "../types";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { ClientsApi, PartnersApi, UsersApi } from "../apis";
import { IUserInput, User } from "../models";
import { ToastProvider } from "../libs";
import { UnhandledScopeError } from "../base";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class UserStore extends BaseStore {
    private readonly scope: AppScope;
    @observable public users: User[] = [];
    @observable public entityUsers: User[] = [];

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
        this.scope = rootStore.scope;
    }

    @action
    loadUsers = async () => {
        await this.tryCatch(async () => {
            const users = await UsersApi.getUsers();

            runInAction(() => {
                this.users = users;
            });
        });
    };

    @action
    loadEntityUsers = async (entityId: string) => {
        this.entityUsers = [];

        await this.tryCatch(async () => {
            let entityUsers: User[];

            switch (this.scope) {
                case AppScope.Partner:
                    entityUsers = await ClientsApi.getClientUsers(entityId);
                    break;
                case AppScope.Supplier:
                    entityUsers = await PartnersApi.getPartnerUsers(entityId);
                    break;
                default:
                    throw new UnhandledScopeError(this.scope);
            }

            runInAction(() => {
                this.entityUsers = entityUsers;
            });
        });
    };

    getUserById: (userId: string) => User | undefined = computedFn((userId: string) => {
        return this.users.find((x) => x.id === userId);
    });

    getUserIndexById: (userId: string) => number = computedFn((userId: string) => {
        return this.users.findIndex((x) => x.id === userId);
    });

    @action
    createUser = async (user: IUserInput & { notifyUser?: boolean }, externalUsers: boolean = false) => {
        await this.tryCatch(
            async () => {
                const result = await UsersApi.createUser(user);

                runInAction(() => {
                    if (!externalUsers) {
                        this.users.push(result);
                    } else {
                        this.entityUsers.push(result);
                    }

                    ToastProvider.success("global.userCreationSuccessMessage".localize());
                });
            },
            undefined,
            true,
        );
    };

    @action
    updateUser = async (userId: string, user: IUserInput, externalUsers: boolean = false) => {
        const index = this.getGlobalIndex(userId, externalUsers);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    const result = await UsersApi.updateUser(userId, user);

                    runInAction(() => {
                        if (!externalUsers) {
                            this.users[index] = result;
                        } else {
                            this.entityUsers[index] = result;
                        }

                        ToastProvider.success("global.userUpdateSuccessMessage".localize());
                    });
                },
                undefined,
                true,
            );
        }
    };

    @action
    updateUserClientsDelegation = async (
        userId: string,
        automaticClientDelegationDisabled: boolean,
        clientsDelegationOverride: string[],
    ) => {
        const index = this.getUserIndexById(userId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await UsersApi.updateUserClientsDelegation(
                    userId,
                    automaticClientDelegationDisabled,
                    clientsDelegationOverride,
                );

                runInAction(() => {
                    this.users[index].automaticClientDelegationDisabled = automaticClientDelegationDisabled;
                    this.users[index].clientsDelegationOverride = automaticClientDelegationDisabled
                        ? clientsDelegationOverride
                        : [];

                    ToastProvider.success("userUpdateSuccessMessage".localize());
                });
            });
        }
    };

    @action
    deactivateUser = async (userId: string, externalUsers: boolean = false) => {
        const index = this.getGlobalIndex(userId, externalUsers);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await UsersApi.deactivateUser(userId);

                runInAction(() => {
                    if (!externalUsers) {
                        this.users[index] = {
                            ...this.users[index],
                            state: UserState.Inactive,
                        };
                    } else {
                        this.entityUsers[index] = {
                            ...this.entityUsers[index],
                            state: UserState.Inactive,
                        };
                    }

                    ToastProvider.success("global.userDeactivatedSuccessMessage".localize());
                });
            });
        }
    };

    @action
    reactivateUser = async (userId: string, externalUsers: boolean = false) => {
        const index = this.getGlobalIndex(userId, externalUsers);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await UsersApi.reactivateUser(userId);

                runInAction(() => {
                    if (!externalUsers) {
                        this.users[index] = {
                            ...this.users[index],
                            state: UserState.Active,
                        };
                    } else {
                        this.entityUsers[index] = {
                            ...this.entityUsers[index],
                            state: UserState.Active,
                        };
                    }

                    ToastProvider.success("global.userReactivatedSuccessMessage".localize());
                });
            });
        }
    };

    @action
    deleteUser = async (userId: string, clientId?: string) => {
        const index = this.getGlobalIndex(userId, !!clientId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                let ownerIdToSend: string | undefined = undefined;

                if (this.scope === AppScope.Partner) {
                    ownerIdToSend = clientId;
                }

                await UsersApi.deleteUser(userId, ownerIdToSend);

                runInAction(() => {
                    if (!clientId) {
                        this.users.splice(index, 1);
                    } else {
                        this.entityUsers.splice(index, 1);
                    }

                    ToastProvider.success("global.userDeletionSuccessMessage".localize());

                    if (!clientId) {
                        this.loadUsers();
                    } else {
                        this.loadEntityUsers(clientId);
                    }
                });
            });
        }
    };

    @action
    getAssignableRoles = async (partnerId?: string, clientId?: string) => {
        return await this.tryCatch(async () => {
            return await UsersApi.getAssignableRoles(partnerId, clientId);
        });
    };

    @action
    searchPartnerClientUsers = async (searchTerm: string, excludedClientId?: string) => {
        return await this.tryCatch(async () => {
            return await UsersApi.searchPartnerClientUsers(searchTerm, excludedClientId);
        });
    };

    @action
    addAdminFromExistingUser = async (id: string, clientId: string, baseRole: Role, additionalRoles: Role[]) => {
        await this.tryCatch(async () => {
            await UsersApi.addAdminFromExistingUser(id, clientId, baseRole, additionalRoles);
            this.loadEntityUsers(clientId);
        });
    };

    private getGlobalIndex = (userId: string, externalUsers: boolean) => {
        let index: number;

        if (!externalUsers) {
            index = this.getUserIndexById(userId);
        } else {
            index = this.entityUsers.findIndex((x) => x.id === userId);
        }

        return index;
    };

    @computed
    get usersCount() {
        return this.users.length;
    }

    autorunner = autorun(() => {});
}
