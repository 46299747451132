import { BaseCompetencySet, IBaseCompetencySet, IBaseCompetencySetInput } from "./BaseCompetencySet";
import { CompetencyProfile } from "../types";
import { observable } from "mobx";

export interface IJobInput extends IBaseCompetencySetInput {
    readonly seniorityLevel: SeniorityLevel;
    readonly departmentId?: string;
    readonly profile?: CompetencyProfile;
}

export interface IJob extends IBaseCompetencySet {
    readonly seniorityLevel: SeniorityLevel;
    readonly departmentId?: string;
    readonly profile?: CompetencyProfile;
    readonly candidatesCount: number;
}

export class Job extends BaseCompetencySet {
    @observable public seniorityLevel: SeniorityLevel;
    @observable public departmentId?: string;
    @observable public profile?: CompetencyProfile;
    @observable public candidatesCount: number;

    constructor(json?: IJob) {
        super(json);

        if (!json) {
            return;
        }

        this.seniorityLevel = json.seniorityLevel;
        this.departmentId = json.departmentId;
        this.profile = json.profile;
        this.candidatesCount = json.candidatesCount;
    }
}

export enum SeniorityLevel {
    Student = 0,
    Entry = 1,
    Mid = 2,
    Senior = 3,
    Director = 4,
    VP = 5,
}
