import "./index.less";
import { AtBadge, AtCheckableCard, AtLink, AtParagraph, AtTextList, AtTitle, Sizing } from "@atman/design-system";
import { CompDevCompetency, CompDevCompetencyGroup } from "@atman/business";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import React, { useState } from "react";

export interface ICompetencyEntryInputProps {
    competency: CompDevCompetency;
    isSelected: boolean;
    isSuggested: boolean;
    onCompetencyIdSelection: (code: string) => void;
    customBadges?: string[];
    displayCategory?: boolean;
}

const CompetencyEntryInput: React.FC<ICompetencyEntryInputProps> = (props) => {
    const {
        competency,
        isSelected,
        isSuggested,
        onCompetencyIdSelection,
        customBadges = [],
        displayCategory = false,
    } = props;

    const [showDetails, setShowDetails] = useState<boolean>(false);

    const onCheckboxClick = () => {
        onCompetencyIdSelection(competency.id);
    };

    return (
        <AtCheckableCard
            className="CompetencyEntryInput"
            id={`competency_id_${competency.id}`}
            checked={isSelected}
            onCheckboxClick={onCheckboxClick}
            topSectionPadding={[Sizing.pixels.sm]}
            bottomSectionPadding={[Sizing.pixels.sm, Sizing.pixels.xxl]}
            topContent={
                <div>
                    <div className="name-tags">
                        <AtTitle title={competency.name} headingType={5} noMargin />
                        {displayCategory && (
                            <AtBadge color="secondary" badgeStyle="fill-light" size="md">
                                {`global.competencyGroups.${CompDevCompetencyGroup[
                                    competency.competencyGroup
                                ].toCamel()}`.localize()}
                            </AtBadge>
                        )}
                        {isSuggested && (
                            <AtBadge color="warning" badgeStyle="fill-medium" size="md">
                                <Trans id={"global.recommended"} />
                            </AtBadge>
                        )}
                        {customBadges.map((b) => (
                            <AtBadge key={b} className="suggested-badge" size="md">
                                {b}
                            </AtBadge>
                        ))}
                    </div>
                    <AtParagraph lineHeight="sm" weight="medium">
                        {competency.description}
                    </AtParagraph>
                    <AtLink
                        color="secondary"
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                        underlined
                    >
                        {t({ id: showDetails ? "global.showLessDetails" : "global.showMoreDetails" })}
                    </AtLink>
                </div>
            }
        >
            {showDetails && (
                <div className="details">
                    <AtTitle title={t({ id: "competencyDevelopment.subCompetencies" })} headingType={5} />
                    <div className="subCompetenciesTags">
                        {competency.subCompetencies.map((e) => (
                            <AtBadge key={e.id} color="ghost" badgeStyle="outline" size="md" bordered>
                                {e.name}
                            </AtBadge>
                        ))}
                    </div>

                    <AtTextList
                        title={t({ id: "competencyDevelopment.global.observableBehaviors" })}
                        texts={competency.subCompetencies.flatMap((x) => x.observableBehaviors.map((y) => y.name))}
                        ordered
                    />
                </div>
            )}
        </AtCheckableCard>
    );
};

export default observer(CompetencyEntryInput);
