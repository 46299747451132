import { BrowserHelper } from "@atman/business";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const withReactContent = require("sweetalert2-react-content");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require("sweetalert2/dist/sweetalert2");

export class ConfirmationAlert {
    constructor(
        confirmAction: () => void,
        text: string,
        title: string,
        confirmButtonText?: string,
        cancelButtonText?: string,
    ) {
        this.confirmAction = confirmAction;
        this.text = text;
        this.title = title;
        this.confirmButtonText = confirmButtonText || "global.yes".localize();
        this.cancelButtonText = cancelButtonText || "global.buttons.labels.cancel".localize();
    }

    confirmAction: () => void;
    text: string;
    title: string;
    confirmButtonText: string;
    cancelButtonText: string;

    async fire() {
        // @ts-ignore
        const MySwal: Swal = withReactContent(Swal);

        if (!BrowserHelper.isInternetExplorerVersion(10)) {
            await MySwal.fire({
                title: this.title,
                text: this.text,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#00B5DD",
                cancelButtonColor: "#DF5B21",
                confirmButtonText: this.confirmButtonText,
                cancelButtonText: this.cancelButtonText,
                showLoaderOnConfirm: true,
                preConfirm: this.confirmAction,
            });

            return;
        }

        // MARK: IE10 Alternative as SweetAlert2 doesn't support anything lower than IE11
        const alert = confirm(this.title + "\n" + this.text);

        if (alert) {
            this.confirmAction();
        }
    }
}
