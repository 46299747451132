import { AssessmentType, CandidateState, IShortCandidate } from "../types";
import { ScoreType } from "./ScoreData";
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";

export interface IAnalyzedCandidate extends IShortCandidate {
    dimensionScores: Dictionary<string, number>;
    competencyEnabled: boolean;
    analyzedAssessmentTypes: AssessmentType[];
    tileScore?: number;
}

export class AnalyzedCandidate implements IAnalyzedCandidate {
    @observable public candidateProId: string;
    @observable public candidateState: CandidateState;
    @observable public email: string;
    @observable public firstName: string;
    @observable public lastName: string;
    @observable public dimensionScores: Dictionary<string, number>;
    @observable public assessmentId: string;
    @observable public profilePictureUri?: string;
    @observable public tileScore?: number;
    @observable public tileScoreType?: ScoreType;
    @observable public isTeamLead?: boolean;
    @observable public jobId?: string;
    @observable public jobName?: string;
    @observable public requestedAssessmentTypes: AssessmentType[];
    @observable public completedAssessmentTypes: AssessmentType[];
    @observable public analyzedAssessmentTypes: AssessmentType[];
    @observable public competencyEnabled: boolean;

    @computed get displayName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        } else if (this.firstName) {
            return this.firstName;
        } else if (this.lastName) {
            return this.lastName;
        } else {
            return this.email;
        }
    }

    @computed get initials(): string {
        const parts = this.displayName
            .split(" ")
            .map((x) => x.replace(/[&\/\\#, +()$~%.'":*?<>{}_-]/g, ""))
            .filter((x) => !!x);

        switch (parts.length) {
            case 1:
                return `${parts[0].charAt(0).toUpperCase()} ${parts[0].charAt(1).toUpperCase()}`;
            case 2:
                return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}`;
            case 3:
                return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}${parts[2]
                    .charAt(0)
                    .toUpperCase()}`;
            default:
                return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}`;
        }
    }

    tryGetDimensionScore: (dimension: string) => number | undefined = computedFn((dimension: string) => {
        if (this.dimensionScores.hasOwnProperty(dimension)) {
            return this.dimensionScores[dimension];
        }

        return undefined;
    });

    constructor(model: IAnalyzedCandidate) {
        this.candidateProId = model.candidateProId;
        this.candidateState = model.candidateState;
        this.email = model.email;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.assessmentId = model.assessmentId;
        this.profilePictureUri = model.profilePictureUri;
        this.dimensionScores = model.dimensionScores;
        this.tileScore = model.tileScore;
        this.jobId = model.jobId;
        this.jobName = model.jobName;
        this.requestedAssessmentTypes = model.requestedAssessmentTypes;
        this.completedAssessmentTypes = model.completedAssessmentTypes;
        this.analyzedAssessmentTypes = model.analyzedAssessmentTypes;
        this.competencyEnabled = model.competencyEnabled;
    }
}

export interface IKPI {
    value: number;
    maxScore: number;
    color: KPIColor;
}

export enum KPIEnum {
    CultureFit,
    JobFit,
    TeamFit,
}

export enum KPIColor {
    Red,
    Yellow,
    Green,
}
