import "./index.less";
import { AtContainer, AtIcon, AtLink, AtParagraph, AtTitle, Sizing } from "@atman/design-system";
import { Colors } from "../../styles/variables/colors";
import { GlobalStores, LocalizationStore, getHelpCenterUrl } from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export interface IHelpCenterInfoContainedProps {
    title: string;
    href: string;
    description?: string;
    ctaLabelOverride?: string;
    localizationStore?: LocalizationStore;
}

export const HelpCenterInfoContained: React.FC<IHelpCenterInfoContainedProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { title, href, description, ctaLabelOverride, localizationStore } = props;

        const onLinkClick = async () => {
            const helpCenterUrl = await getHelpCenterUrl(href, localizationStore!.currentFullLocale);
            window.open(helpCenterUrl, "_blank");
        };

        return (
            <AtContainer className="HelpCenterInfoContained" padding={[Sizing.pixels.lg]}>
                <div className="header">
                    <AtIcon icon={["far", "question-circle"]} color={Colors.primaryBase} />
                    <AtTitle title={title} headingType={3} />
                </div>
                {description && <AtParagraph lineHeight="sm">{description}</AtParagraph>}
                <AtLink onClick={onLinkClick} underlined color="secondary">
                    <span>{ctaLabelOverride ?? t({ id: "global.checkOutOurHelpCenter" })}</span>
                </AtLink>
            </AtContainer>
        );
    }),
);
