import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";
import { CompetencyDevelopmentLeveragingObjectiveType } from "../CompDevEnums";

export interface ICompDevApplication extends IBaseCompetencyElement {
    readonly objectiveType?: CompetencyDevelopmentLeveragingObjectiveType;
}

export class CompDevApplication extends BaseCompetencyElement implements ICompDevApplication {
    public readonly objectiveType?: CompetencyDevelopmentLeveragingObjectiveType;

    constructor(json: ICompDevApplication) {
        super(json);

        this.objectiveType = json.objectiveType;
    }
}
