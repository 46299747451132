import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";
import { CompDevApplication, ICompDevApplication } from "./CompDevApplication";
import { CompDevLearningResource, ICompDevLearningResource } from "./CompDevLearningResource";

export interface IBaseCompDevObjectiveElement extends IBaseCompetencyElement {
    readonly learningResources: ICompDevLearningResource[];
    readonly applications: ICompDevApplication[];
}

export abstract class BaseCompDevObjectiveElement
    extends BaseCompetencyElement
    implements IBaseCompDevObjectiveElement
{
    public readonly learningResources: ICompDevLearningResource[];
    public readonly applications: ICompDevApplication[];

    protected constructor(json: IBaseCompDevObjectiveElement) {
        super(json);

        this.learningResources = json.learningResources?.map((x) => new CompDevLearningResource(x)) ?? [];
        this.applications = json.applications?.map((x) => new CompDevApplication(x)) ?? [];
    }
}
