import "./index.less";
import { AtContainer, AtParagraph } from "../../../..";
import { AtTitle } from "../../atoms/AtTitle";
import { DefaultComponentProps } from "../../../types";
import React from "react";
import cn from "classnames";

export interface IAtContainerList extends DefaultComponentProps {
    title: string;
    elements: string[];
    narrative?: string;
    icon?: JSX.Element;
    customJsx?: JSX.Element;
}

export const AtContainerList: React.FC<IAtContainerList> = (props) => {
    const { icon, title, elements, narrative, className, customJsx, ...otherProps } = props;

    const header = (
        <div className="header">
            {customJsx ? (
                customJsx
            ) : (
                <>
                    {icon}
                    <AtTitle noMargin headingType={2} title={title} />
                </>
            )}
        </div>
    );

    const content = (
        <ul className="listContainer">
            {elements.map((el, index) => (
                <li className="item" key={`${el}_${index}`}>
                    {el}
                </li>
            ))}
        </ul>
    );

    return (
        <AtContainer
            className={cn("AtContainerList", className)}
            header={header}
            isUnderlined={true}
            headerPadding={"16px"}
            {...otherProps}
        >
            {narrative && <AtParagraph className="narrativeText">{narrative}</AtParagraph>}
            {content}
        </AtContainer>
    );
};
