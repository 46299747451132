import "./index.less";
import * as React from "react";
import { AtBadge, AtBadgeColor } from "@atman/design-system";
import { FitLevelEnum, RangeHelper, stanineCompetencyFitLevelMapping } from "@atman/business";
import { observer } from "mobx-react";

export interface IPotentialBadgeProps {
    score?: number;
}

export const PotentialBadge: React.FC<IPotentialBadgeProps> = observer((props) => {
    const { score } = props;

    const fitLevelEnum: FitLevelEnum | undefined = React.useMemo(() => {
        if (score === undefined) {
            return undefined;
        }

        return RangeHelper.getRangeMapValue(stanineCompetencyFitLevelMapping, score);
    }, [score]);

    const getColor: () => AtBadgeColor = () => {
        switch (fitLevelEnum) {
            case FitLevelEnum.VeryGood:
                return "success-alt";
            case FitLevelEnum.GoodFit:
                return "warning-alt";
            case FitLevelEnum.BeVigilent:
                return "danger-alt";
            default:
                return "secondary";
        }
    };

    const getLabel: () => string = () => {
        let label: string;

        switch (fitLevelEnum) {
            case FitLevelEnum.VeryGood:
                label = "global.fitLevels.naturalTalent";
                break;
            case FitLevelEnum.GoodFit:
                label = "global.fitLevels.good";
                break;
            case FitLevelEnum.BeVigilent:
                label = "global.fitLevels.lessNatural";
                break;
            default:
                label = "global.unknown";
                break;
        }

        return label.localize();
    };

    return (
        <AtBadge className="PotentialBadge" badgeStyle="fill-medium" color={getColor()} size="xs">
            {getLabel()}
        </AtBadge>
    );
});
