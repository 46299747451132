import { ITile, Tile } from "./Tile";
import { observable } from "mobx";

export interface ITileSet {
    label: string;
    tiles: ITile[];
}

export class TileSet {
    @observable public label: string;
    @observable public tiles: Tile[];

    constructor(json: ITileSet) {
        this.label = json.label;
        this.tiles = json.tiles.map((x) => new Tile(x));
    }
}
