import { CompDevObservableBehaviorResult, ICompDevObservableBehaviorResult } from "./CompDevObservableBehaviorResult";
import {
    CompDevSubCompetenciesStrategicObjectiveResult,
    ICompDevSubCompetenciesStrategicObjectiveResult,
} from "./CompDevSubCompetenciesStrategicObjectiveResult";
import { CompetencyDevelopmentAssessmentType } from "../CompDevEnums";

import {
    CompDevLearningResource,
    CompDevSubCompetency,
    ICompDevLearningResource,
    ICompDevSubCompetency,
} from "../StructureModels";
import { autorun, computed, observable } from "mobx";

export interface ICompDevSubCompetencyResult extends ICompDevSubCompetency {
    readonly selectedLearningResourceIds: string[];
    readonly learningResourceNotes: string;
    readonly learningResources: ICompDevLearningResource[];
    readonly observableBehaviors: ICompDevObservableBehaviorResult[];
    readonly strategicObjectives: ICompDevSubCompetenciesStrategicObjectiveResult[];
}

export class CompDevSubCompetencyResult extends CompDevSubCompetency {
    public readonly observableBehaviors: CompDevObservableBehaviorResult[];
    public readonly strategicObjectives: CompDevSubCompetenciesStrategicObjectiveResult[];
    public readonly learningResources: CompDevLearningResource[];
    public readonly hierarchicalRank: string;

    @observable public selectedLearningResourceIds: string[];
    @observable public learningResourceNotes: string;

    @observable public observableBehaviorsAverage: number | undefined;
    @observable public observableBehaviorsManagerAverage: number | undefined;
    @observable public observableBehaviorsEmployeeAverage: number | undefined;
    @observable public observableBehaviorsReviewAverage: number | undefined;

    constructor(json: ICompDevSubCompetencyResult, parentRank: number) {
        super(json, parentRank);
        // TODO fix that
        this.strategicObjectives = (json?.strategicObjectives || []).map(
            (x) => new CompDevSubCompetenciesStrategicObjectiveResult(x),
        );
        this.hierarchicalRank = `${parentRank}.${this.rank}`;
        this.observableBehaviors = json.observableBehaviors.map(
            (x) => new CompDevObservableBehaviorResult(x, this.hierarchicalRank),
        );

        this.learningResources = json.learningResources?.map((x) => new CompDevLearningResource(x)) ?? [];

        this.selectedLearningResourceIds = json.selectedLearningResourceIds;
        this.learningResourceNotes = json.learningResourceNotes;

        autorun(() => {
            this.observableBehaviorsAverage = this._observableBehaviorsAverage;
            this.observableBehaviorsManagerAverage = this._observableBehaviorsManagerAverage;
            this.observableBehaviorsEmployeeAverage = this._observableBehaviorsEmployeeAverage;
            this.observableBehaviorsReviewAverage = this._observableBehaviorsReviewAverage;
        });
    }

    @computed
    private get _observableBehaviorsAverage(): number | undefined {
        const observableBehaviorsWithScores = this.observableBehaviors.filter(
            (x) => x.averageBetweenManagerAndEmployee !== undefined,
        );

        if (!observableBehaviorsWithScores.any()) {
            return undefined;
        }

        const sum = observableBehaviorsWithScores.sum((x) => x.averageBetweenManagerAndEmployee!);
        return sum / observableBehaviorsWithScores.length;
    }

    @computed
    private get _observableBehaviorsManagerAverage(): number | undefined {
        const observableBehaviorsWithScores = this.observableBehaviors.filter(
            (x) => !!x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Manager),
        );

        if (!observableBehaviorsWithScores.any()) {
            return undefined;
        }

        const sum = observableBehaviorsWithScores.sum(
            (x) => x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Manager)!,
        );

        return sum / observableBehaviorsWithScores.length;
    }

    @computed
    private get _observableBehaviorsEmployeeAverage(): number | undefined {
        const observableBehaviorsWithScores = this.observableBehaviors.filter(
            (x) => !!x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Self),
        );

        if (!observableBehaviorsWithScores.any()) {
            return undefined;
        }

        const sum = observableBehaviorsWithScores.sum(
            (x) => x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Self)!,
        );

        return sum / observableBehaviorsWithScores.length;
    }

    @computed
    private get _observableBehaviorsReviewAverage(): number | undefined {
        if (
            this.observableBehaviors.every((x) => !x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Review))
        ) {
            return undefined;
        }

        const observableBehaviorsWithScores = this.observableBehaviors.filter(
            (x) =>
                !!x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Review) ||
                !!x.averageBetweenManagerAndEmployee,
        );

        if (!observableBehaviorsWithScores.any()) {
            return undefined;
        }

        const sum = observableBehaviorsWithScores.sum(
            (x) =>
                x.assessmentScores.get(CompetencyDevelopmentAssessmentType.Review) ??
                x.averageBetweenManagerAndEmployee!,
        );

        return sum / observableBehaviorsWithScores.length;
    }

    @computed
    public get selectedLearningResourses(): CompDevLearningResource[] {
        return this.learningResources.filter((x) => this.selectedLearningResourceIds.includes(x.id));
    }
}
