import "./NewConsumptionScheduling.less";
import * as React from "react";
import {
    AppScope,
    Browser,
    BrowserHelper,
    ClientStore,
    GlobalStores,
    IAddProductConsumptionModeScheduleEntryModel,
    IAppContext,
    IProductConsumptionModeScheduleEntryModel,
    LocalizationStore,
    ModalStore,
    Partner,
    PartnerStore,
    ProductConsumptionMode,
    ScopedOrganizationStore,
    ToastProvider,
    UnhandledScopeError,
} from "@atman/business";
import { AtDateRangePickerPopover, AtSelect, DateRange, IReactSelectOptionObject } from "@atman/design-system";
import { BaseModal, BaseResponsiveComponent, ModalButtons } from "../../components";
import { CustomTextInput } from "../../components/CustomTextInput";
import { FormGroupSection } from "../../components/FormGroupSection";
import { Label } from "reactstrap";
import { Moment } from "moment";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { renderConsumptionSchedulingDatePresets } from "../components/ConsumptionSchedulingDatePresets";
import { withAppContext } from "../../contexts";
import autobind from "autobind-decorator";
import moment from "moment";

export interface INewConsumptionSchedulingProps extends IAppContext {
    modalStore?: ModalStore;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
    localizationStore?: LocalizationStore;
}

@inject(
    GlobalStores.modalStore,
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.scopedOrganizationStore,
    GlobalStores.localizationStore,
)
@observer
class NewConsumptionSchedulingComp extends BaseResponsiveComponent<INewConsumptionSchedulingProps> {
    @observable public consumptionMode: IReactSelectOptionObject<ProductConsumptionMode> = {
        value: ProductConsumptionMode.Balance,
        label: `global.productConsumptionModes.${ProductConsumptionMode[
            ProductConsumptionMode.Balance
        ].toCamel()}`.localize(),
    };

    @observable public expectedConsumption: string = "";
    @observable public dateRange: DateRange = {
        from: undefined,
        to: undefined,
    };

    @computed
    get isLoading(): boolean {
        switch (this.props.scope) {
            case AppScope.Partner:
                return this.props.clientStore!.isLoading;
            case AppScope.Supplier:
                return this.props.partnerStore!.isLoading;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }
    }

    @computed
    get consumptionScheduleEntries(): IProductConsumptionModeScheduleEntryModel[] {
        const ownerId = this.props.modalStore!.childProps!.ownerId;

        switch (this.props.scope) {
            case AppScope.Partner:
                const client = this.props.clientStore!.getClientById(ownerId);

                if (!client) {
                    return [];
                }

                return client.usageModel.productConsumptionModeSchedule;
            case AppScope.Supplier:
                const partner = this.props.partnerStore!.getPartnerById(ownerId);

                if (!partner) {
                    return [];
                }

                return partner.usageModel.productConsumptionModeSchedule;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }
    }

    @action.bound
    handleDatesChanged = (dateRange: DateRange) => {
        this.dateRange = dateRange;
    };

    @autobind
    async _onSave() {
        if (!this.validateForm()) {
            const startDateIsValid =
                !!this.dateRange?.from && moment(this.dateRange?.from).isSameOrAfter(undefined, "D");
            if (!startDateIsValid) {
                ToastProvider.error("global.invalidStartDate".localize());
            }

            const endDateIsValid =
                !!this.dateRange?.to &&
                moment(this.dateRange?.to).isAfter(undefined, "D") &&
                moment(this.dateRange?.to).isAfter(this.dateRange.from!, "D");
            if (!endDateIsValid) {
                ToastProvider.error("global.invalidEndDate".localize());
            }

            const expectedConsumptionIsValid =
                this.consumptionMode.value !== ProductConsumptionMode.Unlimited ||
                Number(this.expectedConsumption) >= 0;
            const expectedConsumptionIsValidInIE =
                !BrowserHelper.isBrowser(Browser.IE) || /\d+/.test(this.expectedConsumption);
            if (!expectedConsumptionIsValid && expectedConsumptionIsValidInIE) {
                ToastProvider.error("global.expectedConsumptionHasToBeAValidNumber".localize());
            }
            return;
        }

        if (!this.dateRange.from || !this.dateRange.to) {
            return;
        }

        const model = {
            consumptionMode: this.consumptionMode.value,
            effectiveDate: moment(this.dateRange.from).local().startOf("D").toISOString(true),
            endDate: moment(this.dateRange.to).local().startOf("D").toISOString(true),
            expectedConsumption: Number(this.expectedConsumption),
        } as IAddProductConsumptionModeScheduleEntryModel;

        let hasErrored: boolean;

        switch (this.props.scope) {
            case AppScope.Partner:
                await this.props.clientStore!.addClientConsumptionScheduleEntry(
                    this.props.modalStore!.childProps!.ownerId,
                    model,
                );
                hasErrored = this.props.clientStore!.hasErrored;
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.addPartnerConsumptionScheduleEntry(
                    this.props.modalStore!.childProps!.ownerId,
                    model,
                );
                hasErrored = this.props.partnerStore!.hasErrored;
                break;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }

        if (!hasErrored) {
            this.props.modalStore!.toggleModal();
        }
    }

    @autobind
    validateForm(): boolean {
        const startDateIsValid = !!this.dateRange.from && moment(this.dateRange.from).isSameOrAfter(undefined, "D");
        if (!startDateIsValid) {
            return false;
        }

        const endDateIsValid =
            !!this.dateRange.to &&
            moment(this.dateRange.to).isAfter(undefined, "D") &&
            moment(this.dateRange.to).isAfter(this.dateRange.from!, "D");
        if (!endDateIsValid) {
            return false;
        }

        const expectedConsumptionIsValid =
            this.consumptionMode.value !== ProductConsumptionMode.Unlimited || Number(this.expectedConsumption) >= 0;
        const expectedConsumptionIsValidInIE =
            !BrowserHelper.isBrowser(Browser.IE) || /\d+/.test(this.expectedConsumption);
        if (!expectedConsumptionIsValid && expectedConsumptionIsValidInIE) {
            return false;
        }

        return true;
    }

    @action.bound
    isOutsideRange = (day: Moment) => {
        if (day.isBefore(undefined, "D")) {
            return true;
        }

        return this.consumptionScheduleEntries.some(
            (x) => day.isSameOrAfter(x.effectiveDate, "D") && day.isBefore(x.endDate, "D"),
        );
    };

    @action.bound
    onConsumptionModeChange = (option: IReactSelectOptionObject<ProductConsumptionMode>) => {
        this.consumptionMode = option;
    };

    @computed
    get latestConsumptionSchedulingEntry(): IProductConsumptionModeScheduleEntryModel | undefined {
        return this.sortArrayByNumberProp(
            this.consumptionScheduleEntries,
            (x) => moment.parseZone(x.endDate).valueOf(),
            "desc",
        ).firstOrDefault();
    }

    @computed
    get visibleMonthsProps() {
        const latestEntry = this.latestConsumptionSchedulingEntry;

        if (!latestEntry) {
            return {};
        }

        return {
            initialVisibleMonth: () => moment.parseZone(latestEntry.endDate),
        };
    }

    render() {
        const { scope, scopedOrganizationStore, localizationStore } = this.props;

        const availableConsumptionModes = [ProductConsumptionMode.Balance];

        switch (scope) {
            case AppScope.Partner:
                if ((scopedOrganizationStore!.scopedOrganization as Partner)!.unlimitedClientCreationEnabled) {
                    availableConsumptionModes.push(ProductConsumptionMode.Unlimited);
                }
                break;
            case AppScope.Supplier:
                availableConsumptionModes.push(ProductConsumptionMode.Unlimited);
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        availableConsumptionModes.push(ProductConsumptionMode.Blocked);

        const presetStartDate = this.latestConsumptionSchedulingEntry
            ? moment.parseZone(this.latestConsumptionSchedulingEntry.endDate)
            : this.dateRange.from;

        const consumptionModeOptions = availableConsumptionModes.map((x) => ({
            value: x,
            label: `global.productConsumptionModes.${ProductConsumptionMode[x].toCamel()}`.localize(),
        }));

        return (
            <BaseModal modalTitle={"global.newConsumptionScheduling".localize()} id={"NewConsumptionScheduling"}>
                <FormGroupSection sectionTitle={"global.planSettings".localize()} id={"new-consumption-settings-form"}>
                    <div className="main-section">
                        <AtSelect
                            name={"consumptionMode"}
                            label={"global.selectPlan"}
                            options={consumptionModeOptions}
                            value={this.consumptionMode}
                            onChange={this.onConsumptionModeChange}
                        />
                        <div className="date-range">
                            <Label>{"global.period".localize()}</Label>

                            <AtDateRangePickerPopover
                                range={this.dateRange}
                                setRange={this.handleDatesChanged}
                                datePresets={renderConsumptionSchedulingDatePresets(presetStartDate)}
                                languageCode={localizationStore!.currentShortLocale}
                            />
                        </div>
                    </div>
                    <CustomTextInput
                        type={"number"}
                        fieldName={"expectedConsumption"}
                        value={this.expectedConsumption}
                        onChange={this.onTextFieldChange}
                        label={"global.expectedUsage".localize()}
                    />
                    <ModalButtons
                        saveAction={this._onSave}
                        saveLabel={"global.addEntry".localize()}
                        disabledSaveButton={!this.validateForm()}
                        isLoading={this.isLoading}
                    />
                </FormGroupSection>
            </BaseModal>
        );
    }
}

const NewConsumptionScheduling = withAppContext(NewConsumptionSchedulingComp);

export { NewConsumptionScheduling };
