import "./index.less";
import { AtLabelledComponent } from "../AtLabelledComponent";
import { AtLabelledComponentList } from "../AtLabelledComponentList";
import { AtMenuButton, AtPersonAvatar, AtTitle, AtTitleSubtitle, Colors } from "@atman/design-system";
import { AtPrintButton, IConfigurablePrintOption, PrintReportParams } from "../AtPrintButton";
import {
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessStatus,
    CompetencyDevelopmentProcessesApi,
    FileHelper,
    GlobalStores,
    ReactAppSettings,
    TeamDevelopmentReportType,
    ToastProvider,
    UserInfoStore,
} from "@atman/business";
import { CustomAlert } from "../CustomAlert";
import { DropdownItem } from "reactstrap";
import { inject, observer } from "mobx-react";
import { isActionPlanAvailable } from "../../../../client/src/app/views/teamView/development/logic";
import { t } from "@lingui/macro";
import { useDetectDevice } from "@atman/core";
import React from "react";

export interface ICompDevEmployeeHeaderProps {
    teamId: string;
    candidateProId: string;
    candidateEmail: string;
    candidateFirstName: string;
    candidateLastName: string;
    organizationName: string;
    candidateProfilePictureUri?: string;
    candidateProcessData?: CompetencyDevelopmentProcessData;
    isLoadingProcessData: boolean;
    reloadProcess?: (processIdOverride?: string) => Promise<void>;
    showCancelButton?: boolean;
    cancelProcessFunction?: () => Promise<void>;
    showCompleteButton?: boolean;
    completeProcessFunction?: () => Promise<void>;
    hidePrintButton?: boolean;
    printReportUrl?: string;
    showPrintConfigurationOptions?: boolean;
    showSendInvitationAction?: boolean;
    showReloadButton?: boolean;
    userInfoStore?: UserInfoStore;
    hideTitleDescription?: boolean;
}

export const CompDevEmployeeHeader: React.FC<ICompDevEmployeeHeaderProps> = inject(GlobalStores.userInfoStore)(
    observer((props) => {
        const {
            candidateProcessData,
            candidateEmail,
            candidateFirstName,
            candidateLastName,
            candidateProfilePictureUri,
            organizationName,
            reloadProcess,
            isLoadingProcessData,
            showCancelButton,
            cancelProcessFunction,
            showCompleteButton,
            completeProcessFunction,
            hidePrintButton,
            printReportUrl,
            showReloadButton,
            showPrintConfigurationOptions = true,
            showSendInvitationAction,
            hideTitleDescription = false,
        } = props;

        const isActionPlanReportAvailable = isActionPlanAvailable(candidateProcessData!);

        const configurablePrintOptions: IConfigurablePrintOption[] = [
            {
                label: t({ id: "global.print.options.reportType", message: "Report Type" }),
                key: "reportType",
                options: [
                    {
                        value: TeamDevelopmentReportType[TeamDevelopmentReportType.EmployeeAppraisal],
                        label: t({ id: "global.print.options.reportType.appraisal", message: "Employee Appraisal" }),
                    },
                    {
                        value: TeamDevelopmentReportType[TeamDevelopmentReportType.ActionPlan],
                        label: t({ id: "global.print.options.reportType.actionPlan", message: "Action Plan" }),
                        isDisabled: !isActionPlanReportAvailable,
                    },
                    {
                        value: TeamDevelopmentReportType[TeamDevelopmentReportType.Full],
                        label: t({ id: "global.print.options.reportType.full", message: "Full" }),
                        isDisabled: !isActionPlanReportAvailable,
                    },
                ],
            },
        ];

        const getPrintReportParams = (params: Map<string, string | string[]>): PrintReportParams => {
            const reportType = params.get("reportType");

            const leftTitle =
                configurablePrintOptions[0].options.find((x) => x.value === reportType)?.label ??
                t({ id: "competencyDevelopment.actionPlan" });
            const leftSubtitle = `AtmanCo • ${organizationName}`;

            return {
                urls: [printReportUrl!],
                leftTitle,
                leftSubtitle,
                rightTitle: `${candidateFirstName} ${candidateLastName}`,
            };
        };

        const sendInvitationEmail = async () => {
            if (!candidateProcessData?.id) {
                return;
            }

            await CompetencyDevelopmentProcessesApi.sendSelfAssessmentInvitations([candidateProcessData.id]);

            ToastProvider.success("clientApp.compDevInvitationHaveBeenSent".localize());
        };

        const cancelProcessAlert = cancelProcessFunction
            ? new CustomAlert(
                  cancelProcessFunction,
                  "clientApp.compDevCancelProcessAlertTitle".localize(),
                  "clientApp.compDevCancelProcessAlertDescription".localize(),
                  {
                      confirmButtonProps: {
                          color: "danger",
                      },
                  },
                  "clientApp.compDevCancelProcess.confirmButton".localize(),
                  "clientApp.compDevCancelProcess.cancelButton".localize(),
              )
            : undefined;

        const completeProcessAlert = completeProcessFunction
            ? new CustomAlert(
                  completeProcessFunction,
                  "clientApp.compDevCompleteProcessAlertTitle".localize(),
                  "clientApp.compDevCompleteProcessAlertDescription".localize(),
                  {},
                  "clientApp.compDevCompleteProcess.confirmButton".localize(),
                  "clientApp.compDevCompleteProcess.cancelButton".localize(),
              )
            : undefined;

        const { isMobile } = useDetectDevice();

        const _showSendInvitationAction = showSendInvitationAction && !!candidateProcessData?.id;

        return (
            <div className="CompDevEmployeeHeader">
                <div className="employee-header">
                    {hideTitleDescription ? (
                        <div className="employee-section-content">
                            <AtPersonAvatar
                                firstName={candidateFirstName}
                                lastName={candidateLastName}
                                email={candidateEmail}
                                pictureUri={candidateProfilePictureUri}
                                size="lg"
                            />

                            <AtTitleSubtitle
                                className="employee-name"
                                size="sm"
                                title={`${candidateFirstName} ${candidateLastName}`}
                                subtitle={candidateEmail}
                            />
                        </div>
                    ) : (
                        <AtLabelledComponentList>
                            <AtLabelledComponent
                                label={"competencyDevelopment.global.header.employee".localize()}
                                className={"employee-section"}
                            >
                                <div className="employee-section-content">
                                    <AtPersonAvatar
                                        firstName={candidateFirstName}
                                        lastName={candidateLastName}
                                        email={candidateEmail}
                                        pictureUri={candidateProfilePictureUri}
                                        size="lg"
                                    />

                                    <AtTitle
                                        headingType={4}
                                        title={
                                            candidateFirstName || candidateLastName
                                                ? `${candidateFirstName} ${candidateLastName}`
                                                : candidateEmail
                                        }
                                    />
                                </div>
                            </AtLabelledComponent>
                        </AtLabelledComponentList>
                    )}

                    <div className="buttons-section">
                        {!hidePrintButton && printReportUrl && (
                            <AtPrintButton
                                reportNamePrefix={`${t({
                                    id: "competencyDevelopment.actionPlan",
                                })}_${FileHelper.removeInvalidFileNameChars(
                                    `${candidateFirstName}_${candidateLastName}`.replace(" ", "_"),
                                )}`}
                                configurableOptions={showPrintConfigurationOptions ? configurablePrintOptions : []}
                                getPrintReportParams={getPrintReportParams}
                                availableReportLanguagesOverride={
                                    ReactAppSettings.appModel.allowedLanguageCodesForAssessmentApp ?? ["fr"]
                                }
                            />
                        )}
                        {(showCancelButton || showCompleteButton || _showSendInvitationAction || showReloadButton) && (
                            <AtMenuButton borderType="always" size="lg" disabled={isLoadingProcessData}>
                                {_showSendInvitationAction && (
                                    <DropdownItem
                                        disabled={isLoadingProcessData}
                                        onClick={sendInvitationEmail}
                                        key={"sendInvitation"}
                                    >
                                        {t({ id: "clientApp.compDev.buttons.sendInvitation" })}
                                    </DropdownItem>
                                )}
                                {showReloadButton && reloadProcess && (
                                    <DropdownItem
                                        disabled={isLoadingProcessData}
                                        onClick={() => reloadProcess()}
                                        key={"reloadProcess"}
                                    >
                                        {"global.update".localize()}
                                    </DropdownItem>
                                )}
                                {showCancelButton && cancelProcessAlert && (
                                    <DropdownItem
                                        disabled={isLoadingProcessData}
                                        onClick={() => cancelProcessAlert.fire(undefined, isMobile)}
                                        key={"cancelProcess"}
                                        style={{ color: Colors.redBase }}
                                    >
                                        {"global.buttons.labels.cancel".localize()}
                                    </DropdownItem>
                                )}
                                {showCompleteButton && completeProcessAlert && (
                                    <DropdownItem
                                        disabled={isLoadingProcessData}
                                        onClick={() => completeProcessAlert.fire(undefined, isMobile)}
                                        key={"completeProcess"}
                                        style={{ color: Colors.primaryBase }}
                                    >
                                        {"clientApp.compDevCompleteProcess.completeButton".localize()}
                                    </DropdownItem>
                                )}
                            </AtMenuButton>
                        )}
                    </div>
                </div>
                {candidateProcessData?.status === CompetencyDevelopmentProcessStatus.Cancelled && (
                    <div className="cancelled-notice-container">
                        {"clientApp.processCancelledByNotice".localize()}{" "}
                        <span className="user-name">{candidateProcessData.updatedBy ?? "unknownUser".localize()}</span>
                    </div>
                )}
            </div>
        );
    }),
);
