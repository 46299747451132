import "./index.less";
import {
    AssessmentStructure,
    CompDevCompetencyResult,
    CompDevSubCompetencyResult,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessResultsData,
} from "@atman/business";
import { AtButton, AtIcon, AtIconTooltip, AtTitle, AtTitleSubtitle } from "@atman/design-system";
import { BaseResponsiveComponent, Default, LikertLegend, Mobile, TableHeader } from "../../../../../..";
import { Col, Form, ModalBody, ModalFooter, Row } from "reactstrap";
import { CompetencyNameTableHeaderCol } from "../TableHeaderRow/components";
import { DetailedScoreColumns } from "../TableRow/components/DetailedScoreColumns";
import { IPersonInfo, TableHeaderRow } from "../../../..";
import { ObservableBehaviorStatementRow } from "./ObservableBehaviorStatementRow";
import { Trans } from "@lingui/macro";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";

export interface ICompDevAdjustmentModalProps {
    subCompetency: CompDevSubCompetencyResult;
    competencyId: string;
    toggleModal: () => void;
    assessmentStructure: AssessmentStructure;
    processResultsData: CompetencyDevelopmentProcessResultsData; // TODO Simon 2021-06-18: Pass the competency directly instead of the ID + the whole results data.
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    saveAssessmentFunction: (
        competencyId: string,
        observableBehaviorScores: Map<string, number | undefined>,
        observableBehaviorNotes: Map<string, string | undefined>,
    ) => Promise<void>;
}

@observer
export class CompDevAdjustmentModal extends BaseResponsiveComponent<ICompDevAdjustmentModalProps, {}> {
    @observable private isLoading: boolean = false;
    @observable private observableBehaviorScoreMap: Map<string, number | undefined>;
    @observable private observableBehaviorNoteMap: Map<string, string | undefined>;

    @observable private competencyDetails: CompDevCompetencyResult;

    @computed private get _competencyDetails(): CompDevCompetencyResult {
        const { competencyId, processResultsData } = this.props;

        return processResultsData.competencies.find((x) => x.id === competencyId)!;
    }

    constructor(props) {
        super(props);

        this.observableBehaviorScoreMap = new Map<string, number | undefined>(
            this.props.subCompetency.observableBehaviors.map((x) => [
                x.id,
                x.assessmentScores!.get(CompetencyDevelopmentAssessmentType.Review) ?? undefined,
            ]),
        );

        this.observableBehaviorNoteMap = new Map<string, string | undefined>(
            this.props.subCompetency.observableBehaviors.map((x) => [
                x.id,
                x.assessmentNotes!.get(CompetencyDevelopmentAssessmentType.Review) ?? undefined,
            ]),
        );

        autorun(() => {
            this.competencyDetails = this._competencyDetails;
        });
    }

    @action onScoreChange = (id: string, score: number) => {
        const existingValue = this.observableBehaviorScoreMap.get(id);

        if (existingValue && existingValue === score) {
            this.observableBehaviorScoreMap.set(id, undefined);
        } else {
            this.observableBehaviorScoreMap.set(id, score);
        }
    };

    @action onNoteChange = (id: string, note: string) => {
        const existingValue = this.observableBehaviorNoteMap.get(id);

        if (existingValue && existingValue === note) {
            this.observableBehaviorNoteMap.set(id, undefined);
        } else {
            this.observableBehaviorNoteMap.set(id, note);
        }
    };

    save = async () => {
        const { toggleModal, saveAssessmentFunction } = this.props;

        this.isLoading = true;

        const mergedScoreMapEntries = [
            ...[...this.competencyDetails.getCurrentObservableBehaviorScores()].filter(
                ([k]) => !this.observableBehaviorScoreMap.has(k),
            ),
            ...this.observableBehaviorScoreMap.entries(),
        ];

        const mergedNoteMapEntries = [
            ...[...this.competencyDetails.getCurrentObservableBehaviorNotes()].filter(
                ([k]) => !this.observableBehaviorNoteMap.has(k),
            ),
            ...this.observableBehaviorNoteMap.entries(),
        ];

        await saveAssessmentFunction(
            this.competencyDetails.id,
            new Map(mergedScoreMapEntries),
            new Map(mergedNoteMapEntries),
        );

        runInAction(() => {
            this.isLoading = false;
        });

        toggleModal();
    };

    render() {
        return (
            <>
                <Mobile>{this.renderMobile()}</Mobile>
                <Default>{this.renderDefault()}</Default>
            </>
        );
    }

    renderMobile() {
        const { subCompetency, toggleModal, managerInfo, employeeInfo } = this.props;

        return (
            <>
                <ModalBody id="CompDevAdjustmentModal">
                    <div className="content-container">
                        <AtTitleSubtitle
                            title={"competencyDevelopment.review.adjustmentModal.observableBehaviorIndications".localize()}
                            subtitle={"competencyDevelopment.review.adjustmentModal.explication".localize()}
                        />
                        <div className="privacy-warning">
                            <AtIcon icon={["far", "exclamation-circle"]} />
                            <div className="content">
                                <Trans id="competencyDevelopment.review.adjustmentModal.privacyWarning">
                                    Attention: Your notes are <strong>not anonymous nor private</strong> and may be
                                    visible to the employee and anyone who has access to this competency development
                                    project.
                                </Trans>
                            </div>
                        </div>
                        <div className="competency-details">
                            <TableHeader>{"competencyDevelopment.global.competency".localize()}</TableHeader>
                            <div className="competency-name-container">
                                <AtTitle
                                    headingType={4}
                                    className="competency-name"
                                    title={`${this.competencyDetails.rank}. ${this.competencyDetails.name}`}
                                />

                                <AtIconTooltip icon={"question-circle"} tooltipDisplayMode={"dark"}>
                                    {this.competencyDetails.description}
                                </AtIconTooltip>
                            </div>
                        </div>
                        <div className="sub-competency-details">
                            <CompetencyNameTableHeaderCol competencyType="subCompetency" />
                            <div className="details-row">
                                <div className="competency-col content-col">
                                    <div className="sub-competency-name-container">
                                        <AtTitle
                                            className="sub-competency-name"
                                            headingType={5}
                                            title={`${subCompetency.hierarchicalRank} ${subCompetency.name}`}
                                        />
                                        <AtIconTooltip icon={"question-circle"} tooltipDisplayMode={"dark"}>
                                            {subCompetency.description}
                                        </AtIconTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="score-values d-flex">
                                <DetailedScoreColumns
                                    reviewScore={subCompetency.observableBehaviorsReviewAverage}
                                    averageScore={subCompetency.observableBehaviorsAverage!}
                                    employeeScore={subCompetency.observableBehaviorsEmployeeAverage!}
                                    managerScore={subCompetency.observableBehaviorsManagerAverage!}
                                    columns={[
                                        "competencyName",
                                        "averageScore",
                                        "potential",
                                        "employeeScore",
                                        "managerScore",
                                        "spread",
                                    ]}
                                    averageScoreDisplayMode={"light"}
                                    disableScoreReviewIndicator
                                    managerInfo={managerInfo}
                                    employeeInfo={employeeInfo}
                                />
                            </div>
                        </div>
                        <Form className="observable-behaviors-form" onSubmit={(e) => e.preventDefault()}>
                            <Row className={"header"} noGutters>
                                <Col className="left-section" md={5}>
                                    <div className="header-label">
                                        {"competencyDevelopment.global.observableBehaviors".localize()}
                                    </div>
                                </Col>
                                <Col className="right-section" md={7}>
                                    <Default>
                                        <LikertLegend scales={this.competencyDetails.scales} inCol={false} />
                                    </Default>
                                </Col>
                            </Row>
                            <div className="statements-container">
                                {subCompetency.observableBehaviors.map((x) => (
                                    <ObservableBehaviorStatementRow
                                        key={x.id}
                                        observableBehavior={x}
                                        observableBehaviorScoreMap={this.observableBehaviorScoreMap}
                                        observableBehaviorNoteMap={this.observableBehaviorNoteMap}
                                        onScoreChange={this.onScoreChange}
                                        onNoteChange={this.onNoteChange}
                                        scales={this.competencyDetails.scales}
                                    />
                                ))}
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter className={"CompDevAdjustmentModal-footer"}>
                    <AtButton onClick={toggleModal} size="xl" color={"secondary"}>
                        {"global.buttons.labels.cancel".localize()}
                    </AtButton>
                    <AtButton onClick={this.save} size="xl" color={"primary"} isLoading={this.isLoading}>
                        {"global.buttons.labels.save".localize()}
                    </AtButton>
                </ModalFooter>
            </>
        );
    }

    renderDefault() {
        const { subCompetency, toggleModal, managerInfo, employeeInfo } = this.props;

        return (
            <>
                <ModalBody id="CompDevAdjustmentModal">
                    <div className="content-container">
                        <AtTitleSubtitle
                            title={"competencyDevelopment.review.adjustmentModal.observableBehaviorIndications".localize()}
                            subtitle={"competencyDevelopment.review.adjustmentModal.explication".localize()}
                            size={"xl"}
                        />
                        <div className="privacy-warning">
                            <AtIcon icon={["far", "exclamation-circle"]} />
                            <div className="content">
                                <Trans id="competencyDevelopment.review.adjustmentModal.privacyWarning">
                                    Attention: Your notes are <strong>not anonymous nor private</strong> and may be
                                    visible to the employee and anyone who has access to this competency development
                                    project.
                                </Trans>
                            </div>
                        </div>
                        <div className="competency-details">
                            <TableHeader>{"competencyDevelopment.global.competency".localize()}</TableHeader>
                            <div className="competency-name-container">
                                <AtTitle
                                    headingType={4}
                                    className="competency-name"
                                    title={`${this.competencyDetails.rank}. ${this.competencyDetails.name}`}
                                />
                                <AtIconTooltip icon={"question-circle"} tooltipDisplayMode={"dark"}>
                                    {this.competencyDetails.description}
                                </AtIconTooltip>
                            </div>
                        </div>
                        <div className="sub-competency-details">
                            <TableHeaderRow
                                competencyType="subCompetency"
                                managerInfo={managerInfo}
                                employeeInfo={employeeInfo}
                                columns={["competencyName", "averageScore", "employeeScore", "managerScore", "spread"]}
                            />
                            <div className="details-row">
                                <div className="competency-col content-col">
                                    <div className="sub-competency-name-container">
                                        <AtTitle
                                            headingType={4}
                                            className="sub-competency-name"
                                            title={`${subCompetency.hierarchicalRank} ${subCompetency.name}`}
                                        />
                                        <AtIconTooltip icon={"question-circle"} tooltipDisplayMode={"dark"}>
                                            {subCompetency.description}
                                        </AtIconTooltip>
                                    </div>
                                </div>
                                <DetailedScoreColumns
                                    reviewScore={subCompetency.observableBehaviorsReviewAverage}
                                    averageScore={subCompetency.observableBehaviorsAverage!}
                                    employeeScore={subCompetency.observableBehaviorsEmployeeAverage!}
                                    managerScore={subCompetency.observableBehaviorsManagerAverage!}
                                    columns={[
                                        "competencyName",
                                        "averageScore",
                                        "potential",
                                        "employeeScore",
                                        "managerScore",
                                        "spread",
                                    ]}
                                    averageScoreDisplayMode={"light"}
                                    disableScoreReviewIndicator
                                    managerInfo={managerInfo}
                                    employeeInfo={employeeInfo}
                                />
                            </div>
                        </div>
                        <Form className="observable-behaviors-form" onSubmit={(e) => e.preventDefault()}>
                            <Row className={"header"} noGutters>
                                <Col className="left-section" md={5}>
                                    <div className="header-label">
                                        {"competencyDevelopment.global.observableBehaviors".localize()}
                                    </div>
                                </Col>
                                <Col className="right-section" md={7}>
                                    <Default>
                                        <LikertLegend scales={this.competencyDetails.scales} inCol={false} />
                                    </Default>
                                </Col>
                            </Row>
                            <div className="statements-container">
                                {subCompetency.observableBehaviors.map((x) => (
                                    <ObservableBehaviorStatementRow
                                        key={x.id}
                                        observableBehavior={x}
                                        observableBehaviorScoreMap={this.observableBehaviorScoreMap}
                                        observableBehaviorNoteMap={this.observableBehaviorNoteMap}
                                        onScoreChange={this.onScoreChange}
                                        onNoteChange={this.onNoteChange}
                                        scales={this.competencyDetails.scales}
                                    />
                                ))}
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter className={"CompDevAdjustmentModal-footer"}>
                    <AtButton onClick={toggleModal} color={"secondary"}>
                        {"global.buttons.labels.cancel".localize()}
                    </AtButton>
                    <AtButton onClick={this.save} color={"primary"} isLoading={this.isLoading}>
                        {"global.buttons.labels.save".localize()}
                    </AtButton>
                </ModalFooter>
            </>
        );
    }
}
