import { Layouts, viewportContext } from "../internal";
import React from "react";

export const useDetectDevice = () => {
    const { width } = React.useContext(viewportContext);

    const isMobile: boolean = width <= Layouts.sm.max;
    const isTablet: boolean = width >= Layouts.md.min && width <= Layouts.md.max;
    const isDesktop: boolean = width >= Layouts.lg.min;

    return { isMobile, isTablet, isDesktop };
};
