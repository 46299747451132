import "./index.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IClientToggledFeatures,
    IPartnerToggledFeatures,
    IndustryEnum,
    Partner,
    PartnerStore,
    ReactAppSettings,
    Role,
    ScopedOrganizationStore,
    UnhandledScopeError,
    UserInfoStore,
} from "@atman/business";
import { AtTabCard } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { FeatureEntry } from "./components/FeatureEntry";
import { action, autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IEnabledFeaturesFormProps extends IAppContext {
    ownerId: string;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
    userInfoStore?: UserInfoStore;
}

@inject(
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.scopedOrganizationStore,
    GlobalStores.userInfoStore,
)
@observer
class EnabledFeaturesFormComp extends BaseForm<IEnabledFeaturesFormProps, {}> {
    @observable private currentFeatures: IClientToggledFeatures | IPartnerToggledFeatures | null;

    @observable public quickLinksEnabled: boolean;
    @observable public socioDemoEnabled: boolean;
    @observable public hideSocioDemoSensitiveQuestions: boolean;
    @observable public competencyDevelopmentEnabled: boolean;
    @observable public allowSso: boolean;
    @observable public canAllowSsoOnSubOrganizations: boolean;
    @observable public virtualCoach: boolean;
    @observable public customConsent: boolean;

    constructor(props) {
        super(props);

        autorun(() => {
            this.currentFeatures = this._currentFeatures;
        });
    }

    componentDidMount(): void {
        this.resetEnabledFeatures();
    }

    @computed get _currentFeatures(): IClientToggledFeatures | IPartnerToggledFeatures | null {
        const { scope, clientStore, partnerStore, ownerId } = this.props;

        let currentFeatures: IClientToggledFeatures | IPartnerToggledFeatures;

        switch (scope) {
            case AppScope.Partner:
                const client = clientStore!.getClientById(ownerId);

                if (!client) {
                    return null;
                }

                currentFeatures = client.features;

                break;
            case AppScope.Supplier:
                const partner = partnerStore!.getPartnerById(ownerId);

                if (!partner) {
                    return null;
                }

                currentFeatures = partner.features;

                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        return currentFeatures;
    }

    @autobind
    async _onSave() {
        const { scope, clientStore, partnerStore, ownerId } = this.props;

        this.setIsLoading(true);

        switch (scope) {
            case AppScope.Partner:
                await clientStore!.toggleFeatures(ownerId, {
                    quickLinks: this.quickLinksEnabled,
                    socioDemo: this.socioDemoEnabled,
                    hideSocioDemoSensitiveQuestions: this.hideSocioDemoSensitiveQuestions,
                    competencyDevelopment: this.competencyDevelopmentEnabled,
                    allowSso: this.allowSso,
                    virtualCoach: this.virtualCoach,
                    customTermsConditions: this.customConsent,
                });
                break;
            case AppScope.Supplier:
                await partnerStore!.toggleFeatures(ownerId, {
                    quickLinks: this.quickLinksEnabled,
                    competencyDevelopment: this.competencyDevelopmentEnabled,
                    allowSso: this.allowSso,
                    canAllowSsoOnSubOrganizations: this.canAllowSsoOnSubOrganizations,
                    customTermsConditions: this.customConsent,
                });
                break;
            default:
                this.setIsLoading(false);
                throw new UnhandledScopeError(scope);
        }

        this.setIsLoading(false);
    }

    @action.bound
    resetEnabledFeatures = () => {
        const { scope } = this.props;

        if (!this.currentFeatures) {
            return;
        }

        this.quickLinksEnabled = this.currentFeatures.quickLinks;
        this.competencyDevelopmentEnabled = this.currentFeatures.competencyDevelopment;
        this.allowSso = this.currentFeatures.allowSso;
        this.customConsent = this.currentFeatures.customTermsConditions;

        if (scope === AppScope.Partner) {
            this.socioDemoEnabled = (this.currentFeatures as IClientToggledFeatures).socioDemo;
            this.hideSocioDemoSensitiveQuestions = (
                this.currentFeatures as IClientToggledFeatures
            ).hideSocioDemoSensitiveQuestions;
            this.virtualCoach = (this.currentFeatures as IClientToggledFeatures).virtualCoach;
        }

        if (scope === AppScope.Supplier) {
            this.canAllowSsoOnSubOrganizations = (
                this.currentFeatures as IPartnerToggledFeatures
            ).canAllowSsoOnSubOrganizations;
        }
    };

    validateForm(): boolean {
        return true;
    }

    render() {
        const { scope, scopedOrganizationStore, userInfoStore } = this.props;

        const socioDemoEnabled =
            scope === AppScope.Partner &&
            (scopedOrganizationStore!.scopedOrganization as Partner)?.marketingInfo?.marketingType ===
                IndustryEnum.Internal;
        const hideSocioDemoSensitiveQuestionsEnabled = scope === AppScope.Partner;
        const quickLinksEnabled =
            scope === AppScope.Supplier || ReactAppSettings.viewBag.baseInfo.partnerQuickLinksEnabled;
        const competencyDevelopmentEnabled =
            scope === AppScope.Supplier || ReactAppSettings.viewBag.baseInfo.partnerCompetencyDevelopmentEnabled;
        const allowSsoEnabled =
            scope === AppScope.Supplier
                ? userInfoStore!.hasOneOfRoles([Role.Developer, Role.AuthenticationManager])
                : userInfoStore!.hasRole(Role.Developer) ||
                  userInfoStore!.rolesByLevel.get("supplier")?.includes(Role.AuthenticationManager) ||
                  (ReactAppSettings.viewBag.baseInfo.partnerCanAllowSsoOnSubOrganizations &&
                      userInfoStore!.hasRole(Role.AuthenticationManager));
        const canAllowSsoOnSubOrganizationsEnabled = scope === AppScope.Supplier;
        const virtualCoachEnabled = scope === AppScope.Partner && userInfoStore!.hasRole(Role.AdminSupplier);
        const canEnableCustomConsent =
            scope === AppScope.Supplier || (scope === AppScope.Partner && userInfoStore!.hasRole(Role.AdminSupplier));

        if (
            (!socioDemoEnabled &&
                !quickLinksEnabled &&
                !hideSocioDemoSensitiveQuestionsEnabled &&
                !competencyDevelopmentEnabled &&
                !allowSsoEnabled &&
                !canAllowSsoOnSubOrganizationsEnabled &&
                !canEnableCustomConsent &&
                !virtualCoachEnabled) ||
            (scope !== AppScope.Supplier && !scopedOrganizationStore!.scopedOrganization)
        ) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"global.enabledFeatures".localize()} id="EnabledFeaturesForm">
                {competencyDevelopmentEnabled && (
                    <FeatureEntry
                        feature={"competencyDevelopment"}
                        checked={this.competencyDevelopmentEnabled}
                        onChange={this.onCheckboxChange}
                        id={"competencyDevelopmentEnabled"}
                    />
                )}
                {quickLinksEnabled && (
                    <FeatureEntry
                        feature={"quickLinks"}
                        checked={this.quickLinksEnabled}
                        onChange={this.onCheckboxChange}
                        id={"quickLinksEnabled"}
                    />
                )}
                {socioDemoEnabled && (
                    <FeatureEntry
                        feature={"socioDemo"}
                        checked={this.socioDemoEnabled}
                        onChange={this.onCheckboxChange}
                        id={"socioDemoEnabled"}
                    />
                )}
                {hideSocioDemoSensitiveQuestionsEnabled && (
                    <FeatureEntry
                        feature={"hideSocioDemoSensitiveQuestions"}
                        checked={this.hideSocioDemoSensitiveQuestions}
                        onChange={this.onCheckboxChange}
                        id={"hideSocioDemoSensitiveQuestions"}
                    />
                )}
                {allowSsoEnabled && (
                    <FeatureEntry
                        feature={"allowSso"}
                        checked={this.allowSso}
                        onChange={this.onCheckboxChange}
                        id={"allowSso"}
                    />
                )}
                {canAllowSsoOnSubOrganizationsEnabled && (
                    <FeatureEntry
                        feature={"canAllowSsoOnSubOrganizations"}
                        checked={this.canAllowSsoOnSubOrganizations}
                        onChange={this.onCheckboxChange}
                        id={"canAllowSsoOnSubOrganizations"}
                    />
                )}
                {virtualCoachEnabled && (
                    <FeatureEntry
                        feature={"virtualCoach"}
                        checked={this.virtualCoach}
                        onChange={this.onCheckboxChange}
                        id={"virtualCoach"}
                    />
                )}
                {canEnableCustomConsent && (
                    <FeatureEntry
                        feature={"customConsent"}
                        checked={this.customConsent}
                        onChange={this.onCheckboxChange}
                        id={"customConsent"}
                    />
                )}
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetEnabledFeatures}
                    disabledSaveButton={!this.validateForm()}
                    isLoading={this.isLoading}
                />
            </AtTabCard>
        );
    }
}

const EnabledFeaturesForm = withAppContext(EnabledFeaturesFormComp);

export { EnabledFeaturesForm };
