import "./index.less";
import * as React from "react";
import { FieldError } from "@atman/core";
import { FormGroup, FormGroupProps, FormText, Label } from "reactstrap";
import { observer } from "mobx-react";

export interface ICustomRadioInputGroupProps extends FormGroupProps<any> {
    label: string;
    flex?: boolean;
    errors?: FieldError[];
}

export const CustomRadioInputGroup: React.FC<ICustomRadioInputGroupProps> = observer((props) => {
    const { label, className, children, flex = false, errors, ...otherProps } = props;

    return (
        <FormGroup className={`CustomRadioInputGroup ${className ? className : ""}`} tag={"fieldset"} {...otherProps}>
            <Label>{label}</Label>
            <div className={`inputs ${flex ? "flex-inputs" : ""}`}>{children}</div>
            {errors?.map((x) => (
                <FormText key={x.message} color={"danger"}>
                    {x.message}
                </FormText>
            ))}
        </FormGroup>
    );
});
