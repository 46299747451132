import * as React from "react";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IClientsCountCardItemProps {
    clientsCount: number;
}

export const ClientsCountCardItem: React.FC<IClientsCountCardItemProps> = (props) => {
    const { clientsCount } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "building"] as IconProp}
            cardTitle={"supplierApp.numberOfClients".localize()}
            className={"ClientsCountCardItem"}
        >
            {clientsCount}
        </BaseOverviewCardItem>
    );
};
