import "./index.less";
import { AtContainer } from "../../atoms/AtContainer";
import { Colors, ComponentPadding, IAtContainerProps, Sizing, generatePadding } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtCardProps extends Omit<IAtContainerProps, "onClick"> {
    topContent?: JSX.Element;
    topSectionPadding?: ComponentPadding;
    bottomSectionPadding?: ComponentPadding;
    borderRadius?: number;
    backgroundColor?: string;
    borderColor?: string;
    noShadow?: boolean;
    onClick?: () => void;
    onTopSectionClick?: () => void;
}

type StyleFunction = () => React.CSSProperties;

export const AtCard: React.FC<IAtCardProps> = (props) => {
    const {
        topContent,
        topSectionPadding,
        bottomSectionPadding,
        borderRadius = 6,
        backgroundColor,
        borderColor,
        noShadow = false,
        onClick,
        onTopSectionClick,
        className,
        children,
        padding,
        style: propsStyle,
        ...others
    } = props;

    const getContainerStyle: StyleFunction = () => {
        const style: React.CSSProperties = {
            backgroundColor,
            borderColor: borderColor ?? Colors.greyShades.shade9,
            borderRadius,
            ...propsStyle,
        };

        if (!topSectionPadding && !bottomSectionPadding && padding === undefined) {
            style.padding = generatePadding([Sizing.pixels.xs + Sizing.pixels.xxs, Sizing.pixels.sm]);
        } else if (padding) {
            style.padding = generatePadding(padding);
        }

        return style;
    };

    const getTopSectionStyle: StyleFunction = () => {
        const style: React.CSSProperties = {};

        if (topSectionPadding) {
            style.padding = generatePadding(topSectionPadding);
        }

        return style;
    };

    const getBottomSectionStyle: StyleFunction = () => {
        const style: React.CSSProperties = {
            backgroundColor,
            borderColor: borderColor ?? Colors.greyShades.shade8,
            borderRadius: borderRadius,
        };

        if (bottomSectionPadding) {
            style.padding = generatePadding(bottomSectionPadding);
        }

        return style;
    };

    const headerProps: React.HTMLProps<HTMLDivElement> = {
        className: cn({ clickable: onTopSectionClick }),
        style: getTopSectionStyle(),
        onClick: onTopSectionClick,
    };

    return (
        <AtContainer
            className={cn("AtCard", className, {
                clickable: onClick,
                "no-shadow": noShadow,
            })}
            style={getContainerStyle()}
            onClick={onClick}
            header={topContent}
            headerProps={headerProps}
            {...others}
        >
            {children && (
                <div className="bottom-section" style={getBottomSectionStyle()}>
                    {children}
                </div>
            )}
        </AtContainer>
    );
};
