import "./index.less";
import {
    AtCheckableCard,
    AtContainer,
    AtLink,
    AtTitle,
    AtTitleSubtitle,
    TextCopyToClipboard,
} from "@atman/design-system";
import {
    AuthenticationMethod,
    GlobalStores,
    ReactAppSettings,
    ScopedOrganizationStore,
    ToastProvider,
} from "@atman/business";
import { CardSaveButtons } from "../CardSaveButtons";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { parseError } from "@atman/core";
import React, { useEffect, useState } from "react";

export interface IUsersAuthenticationSettingsProps {
    scopedOrganizationStore?: ScopedOrganizationStore;
}

export const UsersAuthenticationSettings: React.FC<IUsersAuthenticationSettingsProps> = inject(
    GlobalStores.scopedOrganizationStore,
)(
    observer((props) => {
        const { scopedOrganizationStore } = props;

        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [authenticationMethod, setAuthenticationMethod] = useState<AuthenticationMethod>(
            AuthenticationMethod.Password,
        );

        const resetFormData = () => {
            if (!scopedOrganizationStore!.scopedOrganizationAuthConfig) {
                return;
            }

            setAuthenticationMethod(scopedOrganizationStore!.scopedOrganizationAuthConfig.authenticationMethod);
        };

        useEffect(() => {
            if (scopedOrganizationStore!.scopedOrganizationAuthConfig) {
                resetFormData();
            }
        }, [scopedOrganizationStore!.scopedOrganizationAuthConfig]);

        const onSave = async () => {
            try {
                setIsLoading(true);
                await scopedOrganizationStore!.updateSsoAuthMethod(authenticationMethod);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                parseError(e).forEach((x) => ToastProvider.error(x.message));
            }
        };

        const hasChanged = () => {
            if (!scopedOrganizationStore!.scopedOrganizationAuthConfig) {
                return false;
            }

            return authenticationMethod !== scopedOrganizationStore!.scopedOrganizationAuthConfig?.authenticationMethod;
        };

        const getCheckableCardsHeader = (x: AuthenticationMethod) => {
            return (
                <AtTitle
                    headingType={2}
                    title={`global.settings.usersAuthentication.authMethods.${AuthenticationMethod[
                        x
                    ].toCamel()}`.localize()}
                />
            );
        };

        const onTextCopied: () => void = () => {
            ToastProvider.success("global.textCopySuccessMessage".localize());
        };

        return (
            <AtContainer id="UsersAuthenticationSettings">
                <AtTitleSubtitle
                    size={"xl"}
                    title={t({
                        id: "global.settings.usersAuthentication.title",
                        message: "Users Authentication Methods",
                    })}
                    subtitle={t({
                        id: "global.settings.usersAuthentication.description",
                        message:
                            "Please select which of the following methods you want to require when you users sign in to the platform",
                    })}
                />
                <div className="cards-container">
                    <AtCheckableCard
                        id={"password-checkable-card"}
                        checked={authenticationMethod === AuthenticationMethod.Password}
                        onContainerClick={() => setAuthenticationMethod(AuthenticationMethod.Password)}
                        topContent={getCheckableCardsHeader(AuthenticationMethod.Password)}
                    >
                        <Trans id={"global.settings.usersAuthentication.authMethods.password.description"}>
                            global.settings.usersAuthentication.authMethods.password.description
                        </Trans>
                    </AtCheckableCard>

                    {scopedOrganizationStore!.isSsoAllowed && (
                        <AtCheckableCard
                            id={"sso-checkable-card"}
                            checked={authenticationMethod === AuthenticationMethod.Sso}
                            onContainerClick={() => setAuthenticationMethod(AuthenticationMethod.Sso)}
                            topContent={getCheckableCardsHeader(AuthenticationMethod.Sso)}
                            checkboxProps={{
                                disabled: !scopedOrganizationStore!.scopedOrganizationAuthConfig?.samlConfiguration,
                            }}
                        >
                            <Trans id={"global.settings.usersAuthentication.authMethods.sso.description"}>
                                global.settings.usersAuthentication.authMethods.sso.description
                            </Trans>
                        </AtCheckableCard>
                    )}
                </div>
                <AtContainer className="generic-url-container">
                    <Trans id="global.settings.usersAuthentication.genericUrlDetails">
                        Users can always sign in at
                    </Trans>
                    <TextCopyToClipboard text={ReactAppSettings.appUrls.login} onCopy={onTextCopied}>
                        <AtLink
                            color="primary"
                            className="custom-url-link"
                            href={ReactAppSettings.appUrls.login}
                            target={"_blank"}
                        >
                            {ReactAppSettings.appUrls.login}
                        </AtLink>
                    </TextCopyToClipboard>
                </AtContainer>
                <CardSaveButtons
                    saveAction={onSave}
                    cancelAction={resetFormData}
                    disabledSaveButton={!hasChanged()}
                    isLoading={isLoading}
                />
            </AtContainer>
        );
    }),
);
