import "./index.less";
import * as React from "react";
import { Colors } from "../../../internal";
import { observer } from "mobx-react";

export enum ValueColorRenderMode {
    Background,
    LeftSquare,
}

export interface IAtValueColorProps {
    value?: number | string;
    textColor?: string;
    bgColor?: string;
    bgColorFunc?: (value?: number | string) => string | undefined;
    borderColor?: string;
    borderColorFunc?: (value?: number | string) => string | undefined;
    renderMode: ValueColorRenderMode;
}

export const AtValueColor: React.FC<IAtValueColorProps> = observer((props) => {
    const { value, textColor, renderMode, bgColor, bgColorFunc, borderColor, borderColorFunc, ...otherProps } = props;

    const defaultValue: string = "-";

    const getBorder = () => {
        let border: string = "solid 2px ";
        if (borderColorFunc) {
            border += borderColorFunc(value);
        } else {
            border += borderColor ?? Colors.greyShades.shade6;
        }

        return border;
    };

    const style = {
        color: textColor ?? Colors.greyBase,
        backgroundColor: bgColorFunc ? bgColorFunc(value) : bgColor ? bgColor : Colors.greyBg,
        border: getBorder(),
    };

    const hasValue = () => {
        return value !== undefined && value !== null;
    };

    const valueOrDefault = () => {
        return hasValue() ? value : defaultValue;
    };

    const render = () => {
        switch (renderMode) {
            case ValueColorRenderMode.Background:
                return (
                    <div className="AtValueColor render-background" style={style} {...otherProps}>
                        {valueOrDefault()}
                    </div>
                );
            case ValueColorRenderMode.LeftSquare:
                let classes: string = "AtValueColor render-left-square";

                if (!hasValue()) {
                    classes += " empty";
                }

                return (
                    <div className={classes} {...otherProps}>
                        {hasValue() && <div className="square" style={style}></div>}
                        {valueOrDefault()}
                    </div>
                );
        }
    };

    return render();
});
