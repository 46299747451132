import { AtAsyncSelectBase, IAtAsyncSelectBaseProps } from "../../atoms/AtAsyncSelectBase";
import { AtLabel } from "../../atoms/AtLabel";
import { AtSelectCheckmarkOption } from "../SelectSharedComponents/AtSelectCheckmarkOption";
import { AtSelectIconValueContainer } from "../SelectSharedComponents/AtSelectIconValueContainer";
import { AtSubLabel } from "../../atoms/AtSubLabel";
import { FormGroup, FormText } from "reactstrap";
import { GroupBase, SelectComponentsConfig } from "react-select";
import { ISelectSharedProps, OptionTypeBase } from "../../../types";
import { getSelectStylesWithIcon } from "../../../shared/selects/getSelectStyles";
import React from "react";
import cn from "classnames";

export interface IAtAsyncSelectProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends IAtAsyncSelectBaseProps<TOption, IsMulti, TGroup>,
        ISelectSharedProps<TOption, IsMulti, TGroup> {}

/**
 * AtAsyncSelect - Async Select Component
 * Category: Molecule
 *
 * @param props {IAtAsyncSelectProps}
 **/
export const AtAsyncSelect = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtAsyncSelectProps<TOption, IsMulti, TGroup>>,
) => {
    const {
        className,
        style,
        id,
        innerDefaultComponentProps,
        additionalStyles,
        label,
        subLabel,
        validationErrors,
        icon,
        iconProps,
        ...otherProps
    } = props;

    let selectComponents: SelectComponentsConfig<TOption, IsMulti, TGroup> = {
        Option: AtSelectCheckmarkOption,
    };

    if (icon) {
        selectComponents = {
            ValueContainer: (props) => <AtSelectIconValueContainer {...props} icon={icon} iconProps={iconProps} />,
        };
    }

    // TODO: Max Number of Items Containers (?)

    const getAdditionalStyles = () => {
        if (icon) {
            return getSelectStylesWithIcon(additionalStyles);
        }

        if (additionalStyles) {
            return additionalStyles;
        }

        return undefined;
    };

    return (
        <FormGroup className={cn("AtAsyncSelect", className)} style={style} id={id}>
            {label && <AtLabel>{label}</AtLabel>}
            {subLabel && <AtSubLabel>{subLabel}</AtSubLabel>}
            <AtAsyncSelectBase<TOption, IsMulti, TGroup>
                components={selectComponents}
                additionalStyles={getAdditionalStyles()}
                {...otherProps}
                {...(innerDefaultComponentProps ?? {})}
            />
            {validationErrors?.map((x) => (
                <FormText key={x.fieldName} color={"danger"}>
                    {x.message}
                </FormText>
            ))}
        </FormGroup>
    );
};
