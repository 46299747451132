import { observable } from "mobx";

export enum AnalysisTileEnum {
    Assessments, // MARK: Only available on a Profile page
    Distribution, // MARK: Only available on a Team page
    Competencies,
    JobFit,
    CultureFit,
    Potential,
    CompatibilityFit,
}

export interface ITile {
    title: string;
    subtitle: string;
    type: AnalysisTileEnum;
}

export class Tile implements ITile {
    @observable public title: string;
    @observable public subtitle: string;
    @observable public type: AnalysisTileEnum;

    constructor(json: ITile) {
        this.title = json.title;
        this.subtitle = json.subtitle;
        this.type = json.type;
    }
}
