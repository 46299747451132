import { Colors, IAtIconProps } from "@atman/design-system";
import { CompetencyDevelopmentAssessmentStatus, CompetencyDevelopmentAssessmentType } from "@atman/business";

export const getIconPropsForAssessmentType = (assessmentType: CompetencyDevelopmentAssessmentType): IAtIconProps => {
    switch (assessmentType) {
        case CompetencyDevelopmentAssessmentType.Self:
            return {
                icon: ["far", "file-check"],
                color: Colors.greyBase,
                bgColor: Colors.atmanOrangeShades.shade9,
            };
        case CompetencyDevelopmentAssessmentType.Manager:
            return {
                icon: ["far", "file-check"],
                color: Colors.greyBase,
                bgColor: Colors.atmanOrangeFaded,
            };
        case CompetencyDevelopmentAssessmentType.Review:
            return {
                icon: ["far", "users"],
                color: Colors.greyBase,
                bgColor: Colors.yellowShades.shade9,
            };
        case CompetencyDevelopmentAssessmentType.CompetenciesToLeverage:
            return {
                icon: ["far", "fist-raised"],
                color: Colors.greyBase,
                bgColor: Colors.greenShades.shade9,
            };
        case CompetencyDevelopmentAssessmentType.CompetenciesToImprove:
            return {
                icon: ["far", "bullseye-arrow"],
                color: Colors.greyBase,
                bgColor: Colors.purpleShades.shade9,
            };
    }
};

export const getIconPropsForAssessmentStatus = (
    assessmentStatus: CompetencyDevelopmentAssessmentStatus,
): IAtIconProps => {
    switch (assessmentStatus) {
        case CompetencyDevelopmentAssessmentStatus.NotStarted:
            return {
                icon: ["fas", "check-circle"],
                color: Colors.greyShades.shade7,
            };
        case CompetencyDevelopmentAssessmentStatus.Started:
            return {
                icon: ["fas", "check-circle"],
                color: Colors.yellowBase,
            };
        case CompetencyDevelopmentAssessmentStatus.Completed:
            return {
                icon: ["fas", "check-circle"],
                color: Colors.greenBase,
            };
        case CompetencyDevelopmentAssessmentStatus.Cancelled:
            return {
                icon: ["fas", "times-circle"],
                color: Colors.redBase,
            };
    }
};
