import React from "react";
import { RouteConfigEntry } from "./RouteConfigEntry";
import { SentryErrorBoundary, useAppContext } from "@atman/ui-kit";
import { SentryRoute } from "./SentryRoute";

export interface IRouteWithSubRoutesProps {
    path: string;
    Component?: React.ComponentType;
    children?: React.ReactNode;
    subRoutes?: RouteConfigEntry[];
    exact?: boolean;
}

export const RouteWithSubRoutes: React.FC<IRouteWithSubRoutesProps> = (props) => {
    const { path, Component, subRoutes, children, exact = true } = props;

    const { scope } = useAppContext();

    return (
        <SentryErrorBoundary scope={scope} identifier={"route-level"}>
            <SentryRoute
                exact={exact}
                path={path}
                key={"static"}
                render={
                    Component
                        ? // @ts-ignore
                          (renderProps) => <Component {...renderProps} routes={subRoutes?.filter((x) => x.enabled)} />
                        : undefined
                }
            >
                {children}
            </SentryRoute>
        </SentryErrorBoundary>
    );
};
