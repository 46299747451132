import "./index.less";
import * as React from "react";
import { CardExpiryElement } from "react-stripe-elements";
import { Label } from "reactstrap";
import { stripeStyles } from "../StripeJsStyles";

const cardExpirationStyles = {
    ...stripeStyles,
};

export interface IStripeExpirationDateProps {}

export const StripeExpirationDate: React.FC<IStripeExpirationDateProps> = (props: IStripeExpirationDateProps) => {
    const {} = props;

    return (
        <div className="StripeExpirationDate StripeCardElement">
            <Label>{"global.expirationDate".localize()}</Label>
            <CardExpiryElement style={cardExpirationStyles} />
        </div>
    );
};
