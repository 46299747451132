import "./index.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, Role, UserInfoStore } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { RouteNavItem } from "../../../AtSidebar";
import { inject, observer } from "mobx-react";
import React from "react";

export type IconPlacement = "left" | "right";

export interface ITabNavItemProps {
    name: string;
    href: string;
    iconName?: IconProp;
    iconPlacement?: IconPlacement;
    external?: boolean;
    disabled?: boolean;
    restrictedToRole?: Role[];
    onClick?: () => void;
    userInfoStore?: UserInfoStore;
}

export const TabNavItem: React.FC<ITabNavItemProps> = inject(GlobalStores.userInfoStore)(
    observer((props: ITabNavItemProps) => {
        const {
            name,
            href,
            iconName,
            iconPlacement = "left",
            disabled,
            external,
            restrictedToRole,
            onClick,
            userInfoStore,
        } = props;

        const iconElement = iconName && (
            <span className={`icon-container icon-placement-${iconPlacement}`}>
                <FontAwesomeIcon icon={iconName} />
            </span>
        );

        const _onClick = () => {
            if (disabled) {
                return;
            }

            return onClick?.();
        };

        return (
            <RouteNavItem
                extraClasses={`TabNavItem ${disabled ? "disabled" : ""}`}
                href={!disabled ? href : "#"}
                roles={restrictedToRole}
                currentRoles={userInfoStore!.roles}
                external={external ? "true" : ""}
                onClick={_onClick}
                includeActiveIndicator
            >
                {iconPlacement === "left" && iconElement}
                <span className="name">{name}</span>
                {iconPlacement === "right" && iconElement}
            </RouteNavItem>
        );
    }),
);
