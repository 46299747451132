import "./index.less";
import * as React from "react";
import { AtBadge, AtIcon, AtText, fade } from "@atman/design-system";
import { ColorProperty } from "csstype";
import { Colors } from "../../../styles/variables/colors";
import { FitLevelEnum } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { t } from "@lingui/macro";
import { useMemo } from "react";
import cn from "classnames";

export interface IFitScoreContainerAndLegendProps {
    fitScore: FitLevelEnum;
    isJobFitReport?: boolean;
    description?: string;
    count?: number;
    isInDetailed?: boolean;
}

type propertyItemsColor = {
    color: ColorProperty;
    icon: IconProp;
    iconColor: string;
    level: string;
    textColorClass: string;
};

export const FitScoreContainerAndLegend: React.FC<IFitScoreContainerAndLegendProps> = (props) => {
    const { fitScore, description, count, isInDetailed = false, isJobFitReport = false } = props;

    const propertyItems: propertyItemsColor = useMemo(() => {
        switch (fitScore) {
            case 0:
                return {
                    color: Colors.redShades.shade4,
                    icon: ["far", "circle"],
                    iconColor: "#303030",
                    level: FitLevelEnum[FitLevelEnum.BeVigilent],
                    textColorClass: "vigilent",
                };

            case 1:
                return {
                    color: Colors.yellowShades.shade4,
                    icon: ["far", "check-circle"],
                    iconColor: "#303030",
                    level: FitLevelEnum[FitLevelEnum.GoodFit],
                    textColorClass: "goodFit",
                };

            case 2:
                return {
                    color: Colors.greenBase,
                    icon: ["fas", "check-circle"],
                    iconColor: "#fff",
                    level: FitLevelEnum[FitLevelEnum.VeryGood],
                    textColorClass: "veryGood",
                };

            default:
                return {
                    color: Colors.redShades.shade4,
                    icon: ["far", "circle"],
                    iconColor: "#303030",
                    level: FitLevelEnum[FitLevelEnum.BeVigilent],
                    textColorClass: "vigilent",
                };
        }
    }, [fitScore]);

    const { level, color, icon, iconColor, textColorClass } = propertyItems;

    const bgColor = fade(color, 1);

    return (
        <div className={`FitScoreContainerAndLegend`}>
            {isJobFitReport ? (
                <div className="fit-counter-and-text">
                    <AtBadge className={textColorClass} customColor={bgColor} pill size="lg">
                        {count}
                    </AtBadge>

                    {description && (
                        <AtText weight="bold" className="description" size="xl">
                            {description}
                        </AtText>
                    )}
                </div>
            ) : (
                <AtBadge customColor={bgColor} pill size="xs">
                    <div className={cn("iconTextWrapper", description ? "withDescription" : "")}>
                        <AtIcon icon={icon} size={description ? "1x" : "xs"} color={iconColor} />
                        {description && (
                            <span style={{ color: iconColor }}>
                                {count} {description}
                            </span>
                        )}
                        {isInDetailed === true ? (
                            <span style={{ color: iconColor }}>{t({ id: `global.fitLevels.${level.toCamel()}` })}</span>
                        ) : null}
                    </div>
                </AtBadge>
            )}
        </div>
    );
};
