import * as React from "react";
import { AppScope, IAppContext } from "@atman/business";
import { LocationState } from "history";
import { RouteComponentProps, StaticContext } from "react-router";

export interface IRoutedAppContext<TMatch = {}, TContext = StaticContext, TState = LocationState>
    extends IAppContext,
        RouteComponentProps<TMatch, TContext, TState> {}

export const appContext = React.createContext<IAppContext>({
    scope: AppScope.Client,
});

export const AppContextProvider = appContext.Provider;
export const AppContextConsumer = appContext.Consumer;
