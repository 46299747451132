import * as qs from "qs";
import { ApiManager, ReactAppSettings, UrlFormatter } from "../libs";
import { BaseApi, DataType } from "../base";
import { HttpMethodEnum, IDashboardElements, IDashboardFilters, ITransactionLogFilters } from "../types";
import { IProductDefinition } from "../appLogic/ProductDefinition";
import { Product, Transaction } from "../models";
import SimpleCandidate from "../models/SimpleCandidate";

export class SuppliersApi extends BaseApi {
    public static async getSupplierTransactions(
        id: string,
        filters?: ITransactionLogFilters,
        pageSize: number = 100,
        offset: number = 0,
        sortDirection: "asc" | "desc" = "desc",
    ) {
        const data = {
            ...filters,
            pageSize,
            offset,
            sortDirection,
        };

        return await this.sendApiRequestWithResult<typeof data, Transaction[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSupplierTransactionLog,
            data,
            id,
            DataType.QueryString,
        );
    }

    public static async getSupplierTransactionsExcel(id: string, filters?: ITransactionLogFilters) {
        const apiCall = ApiManager.get(
            UrlFormatter.format(ReactAppSettings.appUrls.getSupplierTransactionLogExcel, id),
            {
                responseType: "blob",
                timeout: 300000,
                params: filters,
                paramsSerializer: (params) => qs.stringify(params),
            },
        );

        this.downloadFile("Transactions.xlsx", apiCall);
    }

    public static async getAvailableProducts(id: string) {
        return await this.sendApiRequestWithResult<null, Product[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSupplierAvailableProducts,
            undefined,
            id,
        );
    }

    public static async getAvailableProductDefinitions(id: string) {
        return await this.sendApiRequestWithResult<null, IProductDefinition[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSupplierAvailableProductDefinitions,
            undefined,
            id,
        );
    }

    public static async searchCandidates(searchString: string) {
        const data = {
            searchString,
        };

        return await this.sendApiRequestWithResult<typeof data, SimpleCandidate[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchCandidates,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    public static async getDashboardElements(filters?: IDashboardFilters) {
        return await this.sendApiRequestWithResult<typeof filters, IDashboardElements>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerDashboardElements,
            filters,
            undefined,
            DataType.QueryString,
        );
    }
}
