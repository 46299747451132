import "./index.less";
import * as React from "react";
import {
    AverageNoteScaleTableHeaderCol,
    AverageNoteTableHeaderCol,
    CompetencyNameTableHeaderCol,
    EmployeeNoteTableHeaderCol,
    ManagerNoteTableHeaderCol,
    PotentialTableHeaderCol,
    SpreadTableHeaderCol,
} from "./components";
import { CompetencyDevelopmentColumnType, IPersonInfo, isColumnDisplayed } from "../../../..";
import { SortDirection } from "../../../../../..";
import { observer } from "mobx-react";

export interface ITableHeaderRowProps<TSortElement = string> {
    competencyType?: "competency" | "subCompetency";
    sortElement?: CompetencyDevelopmentColumnType;
    onSortChange?: (element: TSortElement) => void;
    sortDirection?: SortDirection;
    managerInfo?: IPersonInfo;
    employeeInfo?: IPersonInfo;
    columns: CompetencyDevelopmentColumnType[];
    hiddenColumns?: CompetencyDevelopmentColumnType[];
}

export const TableHeaderRow: React.FC<ITableHeaderRowProps> = observer((props) => {
    const {
        competencyType = "competency",
        sortElement,
        onSortChange,
        sortDirection,
        managerInfo,
        employeeInfo,
        columns,
        hiddenColumns,
    } = props;

    return (
        <div className="TableHeaderRow">
            {isColumnDisplayed("competencyName", columns, hiddenColumns) && (
                <CompetencyNameTableHeaderCol
                    competencyType={competencyType}
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
            {isColumnDisplayed("potential", columns, hiddenColumns) && (
                <PotentialTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
            {isColumnDisplayed("averageScore", columns, hiddenColumns) && (
                <AverageNoteTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
            {isColumnDisplayed("averageScoreScale", columns, hiddenColumns) && (
                <AverageNoteScaleTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
            {isColumnDisplayed("employeeScore", columns, hiddenColumns) && employeeInfo && (
                <EmployeeNoteTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    employeeInfo={employeeInfo}
                />
            )}
            {isColumnDisplayed("managerScore", columns, hiddenColumns) && managerInfo && (
                <ManagerNoteTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    managerInfo={managerInfo}
                    hideAvatar
                />
            )}
            {isColumnDisplayed("spread", columns, hiddenColumns) && (
                <SpreadTableHeaderCol
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
        </div>
    );
});
