import "./PackageBundleItem.less";
import {
    AppScope,
    CartStore,
    Client,
    CurrencyEnum,
    GlobalStores,
    IAppContext,
    IProductSkuDetails,
    LocalizationStore,
    ProductBalanceStore,
    ProductCodeProEnum,
    ScopedOrganizationStore,
} from "@atman/business";
import { AtBadge } from "@atman/design-system";
import { AtBaseSelectableCard } from "../../components/AtBaseSelectableCard";
import { AtTitle } from "@atman/design-system";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React, { CSSProperties } from "react";

export interface IPackageBundleItemProps extends IAppContext {
    skuDetails: IProductSkuDetails;
    discountRate: number;
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    style?: CSSProperties;
    localizationStore?: LocalizationStore;
    productBalanceStore?: ProductBalanceStore;
    cartStore?: CartStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(GlobalStores.localizationStore, GlobalStores.cartStore, GlobalStores.scopedOrganizationStore)
@observer
class PackageBundleItemComp extends React.Component<IPackageBundleItemProps> {
    @computed
    get numberFormatter(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.props.localizationStore!.currentShortLocale, {
            style: "currency",
            currency: CurrencyEnum[this.props.cartStore!.billingCurrency],
        });
    }

    render() {
        const { skuDetails, discountRate, checked, onChange, scope, style, scopedOrganizationStore, cartStore } =
            this.props;

        const price = skuDetails.prices.find((x) => x.currency === cartStore!.billingCurrency);

        const showPrices =
            scope !== AppScope.Client ||
            (scopedOrganizationStore!.scopedOrganization &&
                (scopedOrganizationStore!.scopedOrganization as Client).partnerIsInternal);

        return (
            <AtBaseSelectableCard
                inputName={skuDetails.sku}
                inputId={`${skuDetails.sku}-bundle`}
                inputValue={skuDetails.sku}
                onChange={onChange}
                active={checked}
                className={`PackageBundleItem ${!showPrices ? "hidden-prices" : ""}`}
                style={style}
            >
                <div className="top-section">
                    <div className="product-category">{"AtmanPro"}</div>
                    <AtTitle
                        headingType={5}
                        className="bundle-name"
                        title={`${skuDetails.quantity} ${`global.productCodes.${ProductCodeProEnum[
                            skuDetails.productCode
                        ].toCamel()}`.localize()}`}
                    />
                </div>
                <div className="bottom-section">
                    {showPrices ? (
                        <>
                            <div className="prices-container">
                                <div className="price">{this.numberFormatter.format(price!.value)}</div>
                            </div>
                            <div className="unit-price">
                                {`${this.numberFormatter.format(
                                    price!.value / skuDetails.quantity,
                                )} / ${"global.perUnit".localize()}`}
                            </div>
                            {!!discountRate ? (
                                <AtBadge color={"success"} size={"md"} style={{ display: "inline-block" }}>
                                    {`-${discountRate}%`}
                                </AtBadge>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </AtBaseSelectableCard>
        );
    }
}

const PackageBundleItem = withAppContext(PackageBundleItemComp);

export { PackageBundleItem };
