import "./index.less";
import { AssessmentType, GlobalStores, IInterpretationData, LocalizationStore } from "@atman/business";
import {
    AtContainer,
    AtParagraph,
    AtTabs,
    AtText,
    AtTitle,
    HighlightedText,
    IAtButtonProps,
    IAtTabsContent,
    Sizing,
} from "@atman/design-system";
import { AtSubScaleNavigation } from "..";

import { ReportPageHeader } from "../../ReportPageHeader";
import { inject, observer } from "mobx-react";
import { isObservableArray, observable } from "mobx";
import { t } from "@lingui/macro";
import React from "react";

export enum InterpretationDataViewTabType {
    Interpretation,
    Strengths,
    Advices,
    Elements,
}

export type InterpretationDataViewTab = { type: InterpretationDataViewTabType; navigationItem: JSX.Element };

export interface IInterpretationDataViewProps {
    data: IInterpretationData;
    showAllTabs?: boolean;
    printMode?: boolean;
    highlightedColor?: string;
    highlightedTitle?: string;
    localizationStore?: LocalizationStore;
    bannerImageUrl?: string;
    assessmentType?: AssessmentType;
}

@inject(GlobalStores.localizationStore)
@observer
export class InterpretationDataView extends React.Component<IInterpretationDataViewProps, {}> {
    public static defaultProps = {
        showAllTabs: false,
    };

    @observable currentTab: InterpretationDataViewTabType;

    constructor(props: IInterpretationDataViewProps) {
        super(props);

        const { data } = this.props;

        if (data.interpretation) {
            this.currentTab = InterpretationDataViewTabType.Interpretation;
        } else if (data.elements.any()) {
            this.currentTab = InterpretationDataViewTabType.Elements;
        } else if (data.pros.any()) {
            this.currentTab = InterpretationDataViewTabType.Strengths;
        } else if (data.advices.any()) {
            this.currentTab = InterpretationDataViewTabType.Advices;
        } else {
            this.currentTab = InterpretationDataViewTabType.Interpretation;
        }
    }

    renderContentForTab(tab: InterpretationDataViewTabType): JSX.Element {
        const { data } = this.props;

        switch (tab) {
            case InterpretationDataViewTabType.Interpretation:
                return this.renderText(data.interpretation, true);

            case InterpretationDataViewTabType.Elements:
                return this.renderText(data.elements);

            case InterpretationDataViewTabType.Strengths:
                return this.renderText(data.pros);

            case InterpretationDataViewTabType.Advices:
                return this.renderText(data.advices);
        }
    }

    renderText(text: string | string[], handleLineBreaks: boolean = false): JSX.Element {
        if (isObservableArray(text)) {
            return (
                <ul className="items">
                    {text.map((x) => (
                        <li key={x} className="render-text-li">
                            <AtParagraph>{x}</AtParagraph>
                        </li>
                    ))}
                </ul>
            );
        }

        if (handleLineBreaks) {
            return (
                <>
                    {(text as string).split("\n").map((x) => (
                        <AtParagraph key={x} className="items">
                            {x}
                        </AtParagraph>
                    ))}
                </>
            );
        }

        return <AtParagraph className="items">{text}</AtParagraph>;
    }

    renderInterpretationForPrint(text: string): JSX.Element {
        const { highlightedColor, bannerImageUrl, assessmentType } = this.props;

        return (
            <>
                <HighlightedText color={highlightedColor ?? ""}>
                    <AtText weight="bold" className="interpretationTitle">
                        {t({ id: "psychometrics.interpretation" })}
                    </AtText>
                    {text.split("\n").map((x) => (
                        <>
                            <AtParagraph key={x} className="items">
                                {x}
                            </AtParagraph>
                        </>
                    ))}
                </HighlightedText>

                {assessmentType && AssessmentType[assessmentType] === "ProLearningTest" && bannerImageUrl && (
                    <ReportPageHeader size="sm" imageUrl={bannerImageUrl} />
                )}
            </>
        );
    }

    countTabs() {
        let total = 0;

        const {
            data: { pros, elements, advices, interpretation },
        } = this.props;

        if (pros.any()) {
            total++;
        }
        if (elements.any()) {
            total++;
        }
        if (advices.any()) {
            total++;
        }
        if (interpretation) {
            total++;
        }

        return total;
    }

    render(): JSX.Element {
        const {
            data: { pros, elements, advices, interpretation },
            showAllTabs,
            printMode,
        } = this.props;

        const buttonProps: Omit<IAtButtonProps, "children" | "onClick"> = { size: "md" };
        const tabs: IAtTabsContent[] = [
            ...(interpretation
                ? [
                      {
                          id: InterpretationDataViewTabType.Interpretation.toString(),
                          buttonContent: t({ id: "psychometrics.interpretation" }),
                          buttonProps,
                          content: printMode
                              ? this.renderInterpretationForPrint(interpretation)
                              : this.renderContentForTab(InterpretationDataViewTabType.Interpretation),
                      },
                  ]
                : []),
            ...(elements.any()
                ? [
                      {
                          id: InterpretationDataViewTabType.Elements.toString(),
                          buttonContent: t({ id: "psychometrics.interpretation" }),
                          buttonProps,
                          content: this.renderContentForTab(InterpretationDataViewTabType.Elements),
                      },
                  ]
                : []),
            ...(pros.any()
                ? [
                      {
                          id: InterpretationDataViewTabType.Strengths.toString(),
                          buttonContent: t({ id: "psychometrics.strengths" }),
                          buttonProps,
                          content: this.renderContentForTab(InterpretationDataViewTabType.Strengths),
                      },
                  ]
                : []),
            ...(advices.any()
                ? [
                      {
                          id: InterpretationDataViewTabType.Advices.toString(),
                          buttonContent: t({ id: "psychometrics.advices" }),
                          buttonProps,
                          content: this.renderContentForTab(InterpretationDataViewTabType.Advices),
                      },
                  ]
                : []),
        ];

        const tabsOrSingleContainer =
            tabs.length === 1 ? (
                <>
                    <AtTitle title={tabs[0].buttonContent} />
                    {tabs[0].content}
                </>
            ) : (
                <AtTabs className="interpretation" tabs={tabs} />
            );

        return (
            <AtContainer
                className="InterpretationDataView"
                padding={printMode ? Sizing.paddings.xxs : Sizing.paddings.smPlus}
            >
                {showAllTabs
                    ? tabs.map((tab) => (
                          <AtSubScaleNavigation key={tab.id} navigationItems={tab.buttonContent}>
                              {<div key={tab.id}>{tab.content}</div>}
                          </AtSubScaleNavigation>
                      ))
                    : tabsOrSingleContainer}
            </AtContainer>
        );
    }
}
