import { BaseRootStore } from "../base";
import { Browser } from "../types";
import { BrowserHelper, ToastProvider } from "../libs";
import { FullscreenLogic } from "../libs/FullscreenLogic";
import { action, computed, observable } from "mobx";

export class UiStore {
    // @ts-ignore
    private readonly rootStore: BaseRootStore;

    public constructor(rootStore: BaseRootStore) {
        this.rootStore = rootStore;

        switch (BrowserHelper.browser) {
            case Browser.IE:
                document.addEventListener("MSFullscreenChange", (e) => {
                    this.refreshFullscreenStatesAccordingToBrowserState();
                });
                break;
            case Browser.Safari:
                document.addEventListener("webkitfullscreenchange", (e) => {
                    this.refreshFullscreenStatesAccordingToBrowserState();
                });
                break;
            default:
                document.addEventListener("fullscreenchange", (e) => {
                    this.refreshFullscreenStatesAccordingToBrowserState();
                });
                break;
        }

        document.addEventListener("keydown", (e) => {
            return this.handleFullscreenF11Key(e);
        });

        this.refreshFullscreenStatesAccordingToBrowserState();
    }

    @observable private _showHeader: boolean = true;
    @computed public get showHeader(): boolean {
        return this._showHeader;
    }

    @observable private _showSidebar: boolean = true;
    @computed public get showSidebar(): boolean {
        return this._showSidebar;
    }

    @observable public isFullscreenSupported: boolean = false;
    @observable public isFullscreen: boolean = false;

    @action public updateShowHeader(value: boolean) {
        this._showHeader = value;
    }

    @action public updateShowSidebar(value: boolean) {
        this._showSidebar = value;
    }

    @computed private get internalIsFullScreen(): boolean {
        switch (BrowserHelper.browser) {
            case Browser.IE: // In IE we are unable to rely on MSFullscreenChange events or FullscreenLogic.isFullScreen() for F11 Fullscreen, so we use the mobx state directly.
                return this.isFullscreen;
            default:
                // We use the logic instead of the state here so we are sure there has been no side actions in the meantime.
                return FullscreenLogic.isFullScreen();
        }
    }

    openFullscreen = async () => {
        if (this.isFullscreenSupported && !this.internalIsFullScreen) {
            await FullscreenLogic.openFullscreen();
        }
    };

    closeFullscreen = async () => {
        if (this.isFullscreenSupported && this.internalIsFullScreen) {
            await FullscreenLogic.closeFullscreen();

            if (BrowserHelper.isBrowser(Browser.IE)) {
                // We have to do this to handle the case in IE where you open F11 Fullscreen and try to close it via the button.
                window.setTimeout(() => {
                    if (this.isFullscreen) {
                        ToastProvider.error("global.unableToCloseFullscreen".localize());
                    }
                }, 50);
            }
        }
    };

    toggleFullscreen = () => {
        if (this.isFullscreenSupported) {
            if (this.internalIsFullScreen) {
                this.closeFullscreen();
            } else {
                this.openFullscreen();
            }
        }
    };

    @action
    refreshFullscreenStatesAccordingToBrowserState() {
        const isFullscreenSupported = FullscreenLogic.getFullscreenSupported();
        const isFullscreen = FullscreenLogic.isFullScreen();

        this.isFullscreenSupported = isFullscreenSupported;
        this.isFullscreen = isFullscreen; // This doesn't catch the F11 fullscreen mode.
    }

    @action
    handleFullscreenF11Key(e: KeyboardEvent) {
        // If F11 is pressed and soft fullscreen is supported
        if (e.keyCode === 122 && this.isFullscreenSupported) {
            switch (BrowserHelper.browser) {
                case Browser.IE: // In IE we have to toggle the mobx observable directly, since IE F11 Fullscreen doesn't behave the same as JS Fullscreen.
                    this.isFullscreen = !this.isFullscreen;
                    break;
                default:
                    e.preventDefault(); // Block the regular full screen
                    this.toggleFullscreen();
                    return false;
            }
        }

        return e;
    }
}
