import "./index.less";
import * as React from "react";
import { AtButton, AtButtonColors } from "@atman/design-system";

import { Direction } from "reactstrap/lib/Dropdown";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";

export type AtMenuButtonBorderType = "hidden" | "hover" | "always";

// Variants
export * from "./components/AtDownChevronMenuButton";

export interface IAtMenuButtonProps {
    size?: "md" | "lg";
    borderType?: AtMenuButtonBorderType;
    customButtonContent?: React.ReactNode;
    customIcon?: IconProp;
    buttonColorOverride?: AtButtonColors;
    buttonClassName?: string;
    disabled?: boolean;
    id?: string;
    direction?: Direction;
    className?: string;
}

export const AtMenuButton: React.FC<IAtMenuButtonProps> = (props) => {
    const {
        children,
        className,
        id,
        size = "lg",
        borderType = "always",
        buttonColorOverride,
        buttonClassName,
        customButtonContent,
        customIcon,
        disabled,
        direction = "down",
        ...otherProps
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen((prevState) => !prevState);
    const onToggleClick = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            id={id}
            direction={direction}
            className={`AtMenuButton ${className ?? ""} size-${size} border-type-${borderType}`}
            {...otherProps}
        >
            <DropdownToggle tag={"span"} disabled={disabled} onClick={onToggleClick}>
                <AtButton
                    className={buttonClassName}
                    disabled={disabled}
                    color={buttonColorOverride ?? "secondary"}
                    size={size}
                    icon={customIcon ? customIcon : !customButtonContent ? (["far", "ellipsis-h"] as any) : undefined}
                >
                    {customButtonContent}
                </AtButton>
            </DropdownToggle>
            <DropdownMenu>{children}</DropdownMenu>
        </Dropdown>
    );
};
