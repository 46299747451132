import { CookieSetOptions } from "universal-cookie";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export class CookieProvider {
    public static get<TValue = any>(name: string, options?) {
        return cookies.get<TValue>(name, options);
    }

    public static getAll<TValue = any>(options?) {
        return cookies.getAll<TValue>(options);
    }

    public static getOrSet<TValue = any>(name: string, valueIfUnset: TValue): TValue {
        let result = this.get<TValue>(name);

        if (!result) {
            this.set(name, valueIfUnset);
            result = valueIfUnset;
        }

        return result;
    }

    public static set(name: string, value: any, options?: CookieSetOptions) {
        if (!options) {
            const expireDate = new Date();

            expireDate.setMonth(new Date().getMonth() + 1);

            options = {
                path: "/",
                expires: expireDate,
                sameSite: "lax",
            };
        }

        return cookies.set(name, value, options);
    }

    public static remove(name: string, options?) {
        return cookies.remove(name, options);
    }

    public static addChangeListener(callback: () => void) {
        return cookies.addChangeListener(callback);
    }

    public static removeChangeListener(callback: () => void) {
        return cookies.removeChangeListener(callback);
    }
}
