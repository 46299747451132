import "./index.less";
import * as React from "react";
import { AtWizardFooter } from "./components/AtWizardFooter";
import { AtWizardHeader } from "./components/AtWizardHeader";
import { AtWizardIconHeader } from "./components/AtWizardIconHeader";
import { AtWizardStepHeader } from "./components/AtWizardStepHeader";
import { DefaultComponentProps } from "@atman/design-system";
import { GlobalStores, UiStore } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IAtWizardProps extends DefaultComponentProps {
    steps: IWizardStep[];
    customLeftHeaderContent?: React.ReactNode;
    customRightHeaderContent?: React.ReactNode;
    sidePanelContent?: JSX.Element;
    allowStepClickNavigation?: boolean;
    onSubmit?: () => Promise<void>;
    formatSubStepProgressLabel?: (current: number, total: number) => React.ReactNode;
    cancelAction?: () => void;
    uiStore?: UiStore;
    defaultStepValues?: {
        currentStep: number;
        currentSubStep?: number;
    };
}

export interface IAtWizardRef {
    goNext(): Promise<void>;
}

export interface IAtWizardSidePanelProps {
    isLastStep: boolean;
}

export const AtWizard = inject(GlobalStores.uiStore)(
    observer(
        React.forwardRef<IAtWizardRef, IAtWizardProps>((props, ref) => {
            const {
                steps,
                customLeftHeaderContent,
                customRightHeaderContent,
                formatSubStepProgressLabel,
                cancelAction,
                allowStepClickNavigation = false,
                onSubmit,
                sidePanelContent,
                defaultStepValues,
                className,
                uiStore,
            } = props;

            const [isLoading, setIsLoading] = React.useState<boolean>();
            const [currentStep, setCurrentStep] = React.useState<number>(defaultStepValues?.currentStep ?? 1);
            const [currentSubStep, setCurrentSubStep] = React.useState<number>(defaultStepValues?.currentSubStep ?? 1);

            React.useEffect(() => {
                window.scroll({
                    behavior: "smooth",
                    top: 0,
                });
            }, [currentStep, currentSubStep]);

            React.useEffect(() => {
                if (!uiStore) {
                    return;
                }

                uiStore.updateShowHeader(false);
                uiStore.updateShowSidebar(false);

                return () => {
                    uiStore.updateShowHeader(true);
                    uiStore.updateShowSidebar(true);
                };
            });

            const stepDetails: IWizardStepDetails[] = React.useMemo(() => {
                return steps.map(
                    (x, i) =>
                        ({
                            ...x,
                            subSteps: x.subSteps?.map((subStep, i) => ({
                                ...subStep,
                                number: i + 1,
                            })),
                            number: i + 1,
                        } as IWizardStepDetails),
                );
            }, [steps]);

            const currentStepDetails: IWizardStepDetails | undefined = React.useMemo(() => {
                return stepDetails.find((x) => x.number === currentStep);
            }, [currentStep, stepDetails]);

            const shouldShowHeader: boolean = React.useMemo(() => {
                return steps.length > 1 || !!customRightHeaderContent || !!customLeftHeaderContent;
            }, [steps, customRightHeaderContent, customLeftHeaderContent]);

            const goToStep = (newStep: number): void => {
                setCurrentStep(newStep);
            };

            const goBack = (): void => {
                if (!currentStepDetails) {
                    console.warn("Could not find any active current step");
                    return;
                }

                if (currentSubStep === 1) {
                    const previousStepDetails = stepDetails.find((x) => x.number === currentStep - 1);

                    if (!previousStepDetails) {
                        return;
                    }

                    setCurrentSubStep(previousStepDetails.subSteps?.length ?? 1);
                    goToStep(currentStep - 1);
                } else {
                    setCurrentSubStep(currentSubStep - 1);
                }
            };

            const goNext = async (): Promise<void> => {
                if (!(await validateStepAndSubStep())) {
                    return;
                }

                const hasNextStep = currentStep < stepDetails.length;

                if (!currentStepDetails?.subSteps?.any()) {
                    if (hasNextStep) {
                        goToStep(currentStep + 1);
                    }
                    return;
                }

                if (currentSubStep < currentStepDetails!.subSteps?.length) {
                    setCurrentSubStep(currentSubStep + 1);
                } else {
                    if (hasNextStep) {
                        goToStep(currentStep + 1);
                        setCurrentSubStep(1);
                    }
                }
            };

            const onWizardSubmit = async (): Promise<void> => {
                setIsLoading(true);

                if (!(await validateStepAndSubStep())) {
                    setIsLoading(false);

                    return;
                }

                try {
                    await onSubmit?.();
                } catch (e) {
                    console.error(e);
                }

                setIsLoading(false);
            };

            const validateStepAndSubStep = async (): Promise<boolean> => {
                if (!currentStepDetails) {
                    console.warn("Could not find any active current step");
                    return false;
                }

                if (currentStepDetails.validateStep?.() === false) {
                    console.error("Form Validation Error");
                    return false;
                }

                setIsLoading(true);

                const hasErrored = (await currentStepDetails.additionalOnSubmit?.()) === false;

                if (hasErrored) {
                    setIsLoading(false);

                    console.error("Form AdditionalOnSubmit Error");
                    return false;
                }

                const currentSubStepDetails = currentStepDetails.subSteps?.find((x) => x.number === currentSubStep);

                if (currentSubStepDetails?.validateStep?.() === false) {
                    setIsLoading(false);

                    console.error("Form Validation Error");
                    return false;
                }

                const subStepHasErrored = (await currentSubStepDetails?.additionalOnSubmit?.()) === false;

                if (subStepHasErrored) {
                    setIsLoading(false);

                    console.error("Form AdditionalOnSubmit Error");
                    return false;
                }

                setIsLoading(false);

                return true;
            };

            let isLastStep = false;

            if (currentStep === stepDetails.length) {
                if (!currentStepDetails?.subSteps?.any()) {
                    isLastStep = true;
                } else {
                    isLastStep = currentSubStep === currentStepDetails?.subSteps?.length;
                }
            }

            let isFirstStep = false;

            if (currentStep === 1) {
                if (!currentStepDetails?.subSteps?.any()) {
                    isFirstStep = true;
                } else {
                    isFirstStep = currentSubStep === 1;
                }
            }

            let stepContent = currentStepDetails?.component;

            if (currentStepDetails?.subSteps?.any()) {
                const currentSubStepDetails = currentStepDetails.subSteps.find((x) => x.number === currentSubStep);

                stepContent = currentSubStepDetails?.component;
            }

            let classes: string = "AtWizard";

            if (className) {
                classes += ` ${className}`;
            }

            if (sidePanelContent) {
                classes += " has-side-panel";
            }

            React.useImperativeHandle(ref, () => ({
                async goNext(): Promise<void> {
                    goNext();
                },
            }));

            return (
                <div className={classes}>
                    {shouldShowHeader && (
                        <AtWizardHeader
                            activeStep={currentStep}
                            goToStep={goToStep}
                            stepDetails={stepDetails}
                            customLeftHeaderContent={customLeftHeaderContent}
                            customRightHeaderContent={customRightHeaderContent}
                            allowStepClickNavigation={allowStepClickNavigation}
                        />
                    )}
                    <div className="content">
                        <div className="main-content">
                            <div className="step-content-container">{stepContent}</div>
                            <AtWizardFooter
                                goBack={goBack}
                                goNext={goNext}
                                onSubmit={onWizardSubmit}
                                currentSubStep={currentSubStep}
                                currentStepDetails={currentStepDetails}
                                isLastStep={isLastStep}
                                isFirstStep={isFirstStep}
                                formatSubStepProgressLabel={formatSubStepProgressLabel}
                                cancelAction={cancelAction}
                                isLoading={isLoading}
                                hasSidePanel={sidePanelContent !== undefined}
                            />
                        </div>
                        {sidePanelContent && (
                            <div className="side-panel-content">
                                {React.cloneElement(sidePanelContent, {
                                    isLastStep: isLastStep,
                                } as IAtWizardSidePanelProps)}
                            </div>
                        )}
                    </div>
                </div>
            );
        }),
    ),
);

export interface IBaseWizardStep {
    title: string;
    component: React.ReactNode;
    validateStep?: () => boolean;
    additionalOnSubmit?: () => boolean | void | Promise<boolean | void>;
}

export interface IWizardStep extends IBaseWizardStep {
    subSteps?: IWizardSubStep[];
}

export interface IWizardSubStep extends IBaseWizardStep {}

export interface IWizardStepDetails extends IWizardStep {
    number: number;
    subSteps?: IWizardSubStepDetails[];
}

export interface IWizardSubStepDetails extends IWizardSubStep {
    number: number;
}

export { AtWizardStepHeader, AtWizardIconHeader };
