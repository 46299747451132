import * as React from "react";
import * as moment from "moment";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IConsumptionEndDateCardItemProps {
    consumptionEndDate?: string;
}

export const ConsumptionEndDateCardItem: React.FC<IConsumptionEndDateCardItemProps> = (props) => {
    const { consumptionEndDate } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "calendar-times"] as IconProp}
            cardTitle={"global.planEndDate".localize()}
            className={"ConsumptionEndDateCardItem"}
        >
            {consumptionEndDate ? moment.parseZone(consumptionEndDate).format("ll") : "unset".localize()}
        </BaseOverviewCardItem>
    );
};
