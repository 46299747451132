import {
    BaseCompDevObjectiveElement,
    CompDevApplication,
    CompDevLearningResource,
    IBaseCompDevObjectiveElement,
} from "../StructureModels";
import { observable } from "mobx";

export interface IBaseCompDevObjectiveElementResult extends IBaseCompDevObjectiveElement {
    readonly selectedLearningResourceIds: string[];
    readonly selectedApplicationIds: string[];
    readonly learningResourceNotes: string;
    readonly applicationNotes: string;
    readonly isSelected: boolean;
}

export abstract class BaseCompDevObjectiveElementResult
    extends BaseCompDevObjectiveElement
    implements IBaseCompDevObjectiveElementResult
{
    @observable public selectedLearningResourceIds: string[];
    @observable public selectedApplicationIds: string[];
    @observable public learningResourceNotes: string;
    @observable public applicationNotes: string;
    @observable public isSelected: boolean;

    protected constructor(json: IBaseCompDevObjectiveElementResult) {
        super(json);

        this.selectedLearningResourceIds = json.selectedLearningResourceIds;
        this.selectedApplicationIds = json.selectedApplicationIds;
        this.learningResourceNotes = json.learningResourceNotes;
        this.applicationNotes = json.applicationNotes;
        this.isSelected = json.isSelected;
    }

    getSelectedLearningResources(): CompDevLearningResource[] {
        return this.learningResources.filter((x) => this.selectedLearningResourceIds.includes(x.id));
    }

    getSelectedApplications(): CompDevApplication[] {
        return this.applications.filter((x) => this.selectedApplicationIds.includes(x.id));
    }
}
