import "./index.less";
import { AtButton } from "../../molecules/AtButton";
import { AtDateRangePicker } from "../../molecules/AtDateRangePicker";
import { AtIcon } from "../../atoms/AtIcon";
import { DateRange } from "react-day-picker";
import { Input, Popover, PopoverBody } from "reactstrap";
import { isValid, parse } from "date-fns";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useUniqueId } from "@atman/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import moment, { Moment } from "moment/moment";

export interface IDatePreset {
    range: {
        from: Moment;
        to: Moment;
    };
    labelOverride?: string;
}

export interface IAtDateRangePickerPopoverProps {
    range: DateRange;
    setRange: (range: DateRange) => void;
    languageCode: string;
    datePresets?: IDatePreset[];
    fromDate?: Date;
    maxYear?: number;
    hideDateTo?: boolean; // TODO @ma - Temporary fix to hide the date to field - refactor to create new filter based on one date only
}

export const AtDateRangePickerPopover: React.FC<IAtDateRangePickerPopoverProps> = observer((props) => {
    const { range, setRange, languageCode, datePresets, fromDate, maxYear, hideDateTo = false } = props;

    const [fromDateInputValue, setFromDateInputValue] = useState("");
    const [toDateInputValue, setToDateInputValue] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
        setFromDateInputValue(getFormattedDate(range.from) ?? "");
        setToDateInputValue(getFormattedDate(range.to) ?? "");
    }, []);

    const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

    const { uniqueId } = useUniqueId();

    const onFromDateInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFromDateInputValue(event.currentTarget.value);
        const date = parse(event.currentTarget.value, "y-MM-dd", new Date());

        setRange({
            ...range,
            from: isValid(date) ? date : undefined,
        });
    };

    const onToDateInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setToDateInputValue(event.currentTarget.value);
        const date = parse(event.currentTarget.value, "y-MM-dd", new Date());

        setRange({
            ...range,
            to: isValid(date) ? date : undefined,
        });
    };

    const onDateRangeChange = (newDateRange?: DateRange) => {
        if (!newDateRange) {
            setRange({ from: undefined, to: undefined });
            return;
        }

        if (!newDateRange.from) {
            setFromDateInputValue("");
        } else {
            setFromDateInputValue(getFormattedDate(newDateRange.from) ?? "");
        }

        if (!newDateRange.to) {
            setToDateInputValue("");
        } else {
            setToDateInputValue(getFormattedDate(newDateRange.to) ?? "");
        }

        setRange(newDateRange);
    };

    return (
        <div className="AtDateRangePickerPopover">
            <AtButton
                className={"date-range-picker-popover-trigger"}
                color={"secondary"}
                icon={["far", "calendar"]}
                id={uniqueId}
                onClick={togglePopover}
            >
                <div className="date-range-label">
                    {`${range.from ? getFormattedDate(range.from) : "YYYY-MM-DD"} ${!hideDateTo ? "-" : ""}  ${
                        !hideDateTo ? (range.to ? getFormattedDate(range.to) : "YYYY-MM-DD") : ""
                    }`}
                </div>
                <AtIcon icon={["far", "angle-down"]} />
            </AtButton>
            <Popover
                target={uniqueId}
                placement={"bottom-end"}
                isOpen={isPopoverOpen}
                toggle={togglePopover}
                className={"date-range-picker-popover"}
                style={{ maxWidth: "initial" }}
            >
                <PopoverBody className={"date-range-picker-popover-body"}>
                    <div className="popover-body-top-section">
                        {datePresets?.any() && !hideDateTo ? (
                            <div className="presets-section">
                                {datePresets.map(({ range: presetRange, labelOverride }, i) => {
                                    const isSelected =
                                        !!range.from &&
                                        !!presetRange.from &&
                                        presetRange.from.isSame(range.from, "d") &&
                                        !!range.to &&
                                        !!presetRange.to &&
                                        presetRange.to.isSame(range.to, "d");

                                    return (
                                        <AtButton
                                            active={isSelected}
                                            size={"md"}
                                            color={"ghost"}
                                            key={i}
                                            onClick={() => {
                                                if (isSelected) {
                                                    onDateRangeChange({ from: undefined, to: undefined });
                                                } else {
                                                    onDateRangeChange({
                                                        from: presetRange.from.toDate(),
                                                        to: presetRange.to.toDate(),
                                                    });
                                                }
                                            }}
                                            fullWidth
                                        >
                                            {labelOverride ??
                                                moment
                                                    .duration(moment(presetRange.from).diff(presetRange.to))
                                                    .humanize()}
                                        </AtButton>
                                    );
                                })}
                            </div>
                        ) : null}
                        <AtDateRangePicker
                            range={range}
                            setRange={onDateRangeChange}
                            languageCode={languageCode}
                            fromDate={fromDate}
                            maxYear={maxYear}
                        />
                    </div>
                    <div className="popover-body-footer">
                        <div className="date-inputs">
                            <Input
                                type={"text"}
                                className={"from-date date-input"}
                                value={fromDateInputValue}
                                onChange={onFromDateInputValueChange}
                                placeholder={"YYYY-MM-DD"}
                            />

                            {!hideDateTo && (
                                <>
                                    <span className={"input-separator"}>-</span>
                                    <Input
                                        type={"text"}
                                        className={"to-date date-input"}
                                        value={toDateInputValue}
                                        onChange={onToDateInputValueChange}
                                        placeholder={"YYYY-MM-DD"}
                                    />
                                </>
                            )}
                        </div>
                        <div className="actions-container">
                            <AtButton color={"secondary"} onClick={togglePopover}>
                                {t({ id: "global.close" })}
                            </AtButton>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
});

export const getFormattedDate = (date: Date | undefined) => {
    return date?.toISOString().split("T")[0];
};
