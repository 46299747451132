import "./index.less";
import * as React from "react";
import { AtCompetenciesAverageScore } from "./components/AtCompetenciesAverageScore";
import { CompDevCompetencyResult, CompetencyDevelopmentProcessData, CompetencySource } from "@atman/business";

export interface IAtCompetenciesAverageScoresGroupProps {
    candidateProcessData: CompetencyDevelopmentProcessData;
    discretionalCompetencies?: CompDevCompetencyResult[];
    jobCompetencies?: CompDevCompetencyResult[];
    teamCultureCompetencies?: CompDevCompetencyResult[];
    enterpriseCultureCompetencies?: CompDevCompetencyResult[];
}

export const AtCompetenciesAverageScoresGroup: React.FC<IAtCompetenciesAverageScoresGroupProps> = (props) => {
    const {
        candidateProcessData,
        discretionalCompetencies,
        jobCompetencies,
        teamCultureCompetencies,
        enterpriseCultureCompetencies,
    } = props;

    return (
        <div className="AtCompetenciesAverageScoresGroup">
            {enterpriseCultureCompetencies?.any() && (
                <AtCompetenciesAverageScore
                    sourceTitle={
                        candidateProcessData.competencySetsData.get(CompetencySource.Org)?.name ??
                        "global.unknown".localize()
                    }
                    competencyDetails={enterpriseCultureCompetencies}
                    source={CompetencySource.Org}
                />
            )}
            {teamCultureCompetencies?.any() && (
                <AtCompetenciesAverageScore
                    sourceTitle={
                        candidateProcessData.competencySetsData.get(CompetencySource.Team)?.name ??
                        "global.unknown".localize()
                    }
                    competencyDetails={teamCultureCompetencies}
                    source={CompetencySource.Team}
                />
            )}
            {jobCompetencies?.any() && (
                <AtCompetenciesAverageScore
                    sourceTitle={
                        candidateProcessData.competencySetsData.get(CompetencySource.Job)?.name ??
                        "global.unknown".localize()
                    }
                    competencyDetails={jobCompetencies}
                    source={CompetencySource.Job}
                />
            )}
            {discretionalCompetencies?.any() && (
                <AtCompetenciesAverageScore
                    sourceTitle={"competencyDevelopment.specific.plural".localize()}
                    competencyDetails={discretionalCompetencies}
                    source={CompetencySource.Specific}
                />
            )}
        </div>
    );
};
