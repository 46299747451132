import "./PanelItem.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { BaseResponsiveComponent } from "../../BaseResponsiveComponent";
import { CSSProperties } from "react";
import { HeightProperty } from "csstype";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

export type PanelSize = "sm" | "lg" | "auto";
export type ContainerSize = "sm" | "md" | "lg";
export type CollapseDirection = "left" | "right";

export interface IPanelItemProps {
    panelContent: React.ReactNode;
    panelSize: PanelSize;
    collapseDirection?: CollapseDirection;
    containerSize?: ContainerSize;
    headerContent?: React.ReactNode;
    customHeaderHeight?: HeightProperty<number>;
    isContentScrollable?: boolean;
    overrideCollapseBehaviour?: boolean;
    className?: string;
    id?: string;
    style?: CSSProperties;
}

@inject()
@observer
export class PanelItem extends BaseResponsiveComponent<IPanelItemProps> {
    @observable public isCollapsed: boolean = false;

    @computed protected get rootElementClassName(): string | undefined {
        const { className, panelSize } = this.props;

        return `PanelItem ${className ?? ""} size-${panelSize} ${
            this.shouldUseCollapsing && this.isCollapsed ? "is-collapsed" : ""
        }`;
    }

    @computed protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    @computed protected get rootElementStyle(): React.CSSProperties {
        return this.props.style ?? {};
    }

    @computed get shouldUseCollapsing(): boolean {
        const { panelSize, overrideCollapseBehaviour = false } = this.props;

        return (panelSize === "sm" || panelSize === "lg") && !overrideCollapseBehaviour;
    }

    @action.bound toggleIsCollapsed = () => {
        this.isCollapsed = !this.isCollapsed;
    };

    renderAll() {
        const {
            headerContent,
            panelContent,
            panelSize,
            customHeaderHeight,
            containerSize = "lg",
            isContentScrollable = true,
            collapseDirection = "left",
        } = this.props;

        let contentClassName = "content";
        let headerClassName = "header";

        if (!headerContent) {
            contentClassName += " no-header";
        }

        if (panelSize === "auto") {
            contentClassName += ` size-${containerSize}`;
            headerClassName += ` size-${containerSize}`;
        }

        if (isContentScrollable) {
            contentClassName += " scrollable";
        }

        let contentStyle: CSSProperties = {};
        let headerStyle: CSSProperties = {};

        if (customHeaderHeight) {
            headerStyle = {
                height: customHeaderHeight,
            };
            contentStyle = {
                top: customHeaderHeight,
            };
        }

        const shouldShowContent = !this.shouldUseCollapsing || !this.isCollapsed;

        return (
            <>
                {shouldShowContent && headerContent && (
                    <div className={headerClassName} style={headerStyle}>
                        {headerContent}
                    </div>
                )}
                {shouldShowContent && (
                    <div className={contentClassName} style={contentStyle}>
                        {panelContent}
                    </div>
                )}
                {this.shouldUseCollapsing ? (
                    this.isCollapsed ? (
                        <AtButton
                            color={"secondary"}
                            icon={["far", collapseDirection === "left" ? "arrow-from-left" : "arrow-from-right"]}
                            className={`collapse-action expand-btn collapse-direction-${collapseDirection}`}
                            onClick={this.toggleIsCollapsed}
                        />
                    ) : (
                        <AtButton
                            color={"secondary"}
                            icon={["far", collapseDirection === "left" ? "arrow-from-right" : "arrow-from-left"]}
                            className={`collapse-action collapse-btn collapse-direction-${collapseDirection}`}
                            onClick={this.toggleIsCollapsed}
                        />
                    )
                ) : null}
            </>
        );
    }
}
