import "./index.less";
import { AtInputBase, IAtInputBaseProps } from "../../atoms/AtInputBase";
import { Colors } from "../../../internal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, FormText, Label } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { toJS } from "mobx";
import React from "react";
import cn from "classnames";

export interface IAtInputProps extends IAtInputBaseProps {
    fieldName: string;
    hideLabel?: boolean;
    leftIcon?: IconProp;
    label?: string;
    formText?: string;
    size?: "small" | "medium" | "large";
    className?: string;
    disabled?: boolean;
    rightIcon?: IconProp;
    onRightIconClick?: () => void;
    min?: number;
    max?: number;
}

export const AtInput: React.FC<IAtInputProps> = (props) => {
    const {
        fieldName,
        leftIcon,
        hideLabel = false,
        label,
        formText,
        size,
        className,
        disabled,
        rightIcon,
        onRightIconClick,
        ...otherProps
    } = props;

    const displayLabel = hideLabel === true && label !== undefined;

    return (
        <FormGroup className={cn("AtInput", `${fieldName}-input`, { "has-icon": leftIcon }, className)}>
            {displayLabel && <Label for={fieldName}>{label}</Label>}
            {leftIcon && (
                <div className="left-icon-container">
                    <FontAwesomeIcon icon={leftIcon} color={Colors.greyShades.shade4} />
                </div>
            )}
            <AtInputBase fieldName={fieldName} size={size} disabled={disabled} {...otherProps} />
            {formText && <FormText>{formText}</FormText>}

            {rightIcon && (
                <div
                    className={cn("right-icon-container", { hasOnClickEvent: onRightIconClick })}
                    onClick={() => {
                        if (onRightIconClick) {
                            onRightIconClick();
                        }
                    }}
                >
                    <FontAwesomeIcon icon={toJS(rightIcon)} color={Colors.greyShades.shade4} />
                </div>
            )}
        </FormGroup>
    );
};
