import "./index.less";
import { AtTabCard, IReactSelectOptionObject } from "@atman/design-system";
import { CardSaveButtons } from "../CardSaveButtons";
import { CultureSelectField } from "..";
import { CustomValidatedTextInput } from "../CustomValidatedTextInput";
import { FieldError } from "@atman/core";
import { Form } from "reactstrap";
import { GlobalStores, LocalizationStore, UserInfoStore, Validator } from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useErrorHandling } from "../../hooks";
import React from "react";

export interface ICandidatePersonalInformationFormProps {
    userInfoStore?: UserInfoStore;
    localizationStore?: LocalizationStore;
}

export const CandidatePersonalInformationForm: React.FC<ICandidatePersonalInformationFormProps> = inject(
    GlobalStores.userInfoStore,
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const { userInfoStore, localizationStore } = props;

        const [isLoading, setIsLoading] = useState(false);
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [email, setEmail] = useState("");
        const [communicationEmail, setCommunicationEmail] = useState("");
        const [languageCode, setLanguageCode] = useState<IReactSelectOptionObject>({ value: "", label: "" });
        const { addErrors, clearErrors, errors } = useErrorHandling();

        const resetForm = () => {
            setFirstName(userInfoStore!.firstName);
            setLastName(userInfoStore!.lastName);
            setEmail(userInfoStore!.email);
            setCommunicationEmail(userInfoStore!.communicationEmail);
            setLanguageCode({
                value: localizationStore!.currentLocale.name,
                label: localizationStore!.currentLocale.shortDisplay,
            });
        };

        useEffect(() => {
            resetForm();
        }, []);

        const onSave = async (): Promise<void> => {
            if (!validateForm()) {
                return;
            }

            setIsLoading(true);

            await userInfoStore!.updateCandidatePreferences(
                firstName,
                lastName,
                communicationEmail,
                languageCode.value,
            );

            setIsLoading(false);
        };

        const validateForm = (): boolean => {
            clearErrors();

            const validFirstName = !!firstName;

            if (!validFirstName) {
                addErrors(new FieldError("firstName", t({ id: "global.errors.field.empty" })));
            }

            const validLastName = !!lastName;

            if (!validLastName) {
                addErrors(new FieldError("lastName", t({ id: "global.errors.field.empty" })));
            }

            const validCommunicationEmail = Validator.validateEmail(communicationEmail);

            if (!validCommunicationEmail) {
                addErrors(new FieldError("communicationEmail", t({ id: "global.errors.email.invalid" })));
            }

            return validFirstName && validLastName && validCommunicationEmail;
        };

        return (
            <AtTabCard
                cardTitle={"global.updateYourPersonalInformation".localize()}
                id="CandidatePersonalInformationForm"
            >
                <Form className="inputs">
                    <CustomValidatedTextInput
                        fieldName={"firstName"}
                        value={firstName}
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                        validationErrors={errors}
                    />
                    <CustomValidatedTextInput
                        fieldName={"lastName"}
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                        validationErrors={errors}
                    />
                    <CustomValidatedTextInput
                        fieldName={"email"}
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        disabled
                        formText={t({ id: "employeeApp.personalInformationForm.candidateEmail.subtitle" })}
                        validationErrors={errors}
                    />
                    <CustomValidatedTextInput
                        fieldName={"communicationEmail"}
                        value={communicationEmail}
                        onChange={(e) => setCommunicationEmail(e.currentTarget.value)}
                        formText={t({ id: "employeeApp.personalInformationForm.communicationEmail.subtitle" })}
                        validationErrors={errors}
                    />
                    <CultureSelectField cultureValue={languageCode} onChange={setLanguageCode} />
                </Form>
                <CardSaveButtons saveAction={onSave} cancelAction={resetForm} isLoading={isLoading} />
            </AtTabCard>
        );
    }),
);
