import "./index.less";
import { AtParagraph, AtTitle } from "@atman/design-system";
import React from "react";
import cn from "classnames";

export interface IReportPageHeaderProps {
    size: "sm" | "md" | "lg";
    imageUrl: string;
    title?: string;
    pageBreakBeforeAuto?: boolean;
    description?: string;
}

export const ReportPageHeader: React.FC<IReportPageHeaderProps> = (props) => {
    const { title, imageUrl, size, pageBreakBeforeAuto = false, description } = props;

    return (
        <div
            className={cn(
                "ReportPageHeader",
                `${size}HeaderSvg`,
                pageBreakBeforeAuto ? "pageBreakBeforeAuto" : undefined,
            )}
        >
            {title && <AtTitle className="titleOnBanner" headingType={2} title={title} />}

            <img className="pageHeader" src={imageUrl} alt="Report Page Header" />

            {description && <AtParagraph size={"lg"}>{description}</AtParagraph>}
        </div>
    );
};
