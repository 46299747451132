import { AtDatePickerBase } from "../AtDatePickerBase";
import { DateRange } from "react-day-picker";
import { Locale as Locale$1 } from "date-fns";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import cn from "classnames";

export interface IAtDateRangePickerProps {
    range: DateRange;
    setRange: (range: DateRange) => void;
    languageCode: string;
    fromDate?: Date;
    maxYear?: number;
    className?: string;
}

const today = new Date();

export const AtDateRangePicker: React.FC<IAtDateRangePickerProps> = observer((props) => {
    const { range, setRange, fromDate, maxYear = today.getFullYear() + 25, className, languageCode } = props;

    const [localeObj, setLocaleObj] = useState<Locale$1 | undefined>(undefined);

    const loadLocaleObj = async (languageCode: string) => {
        let locale: Locale$1;

        switch (languageCode) {
            case "fr":
                locale = await import("date-fns/locale").then((x) => x.fr);
                break;
            case "es":
                locale = await import("date-fns/locale").then((x) => x.es);
                break;
            case "en":
            default:
                locale = await import("date-fns/locale").then((x) => x.enUS);
                break;
        }

        setLocaleObj(locale);
    };

    useEffect(() => {
        loadLocaleObj(languageCode);
    }, [languageCode]);

    return (
        <AtDatePickerBase
            mode={"range"}
            selected={range}
            onSelect={setRange}
            fromDate={fromDate ?? today}
            toYear={maxYear}
            className={cn("AtDateRangePicker", className)}
            locale={localeObj}
        />
    );
});

export { DateRange };
