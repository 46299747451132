import "./index.less";
import {
    AtContainer,
    AtIcon,
    AtParagraph,
    AtTabs,
    AtText,
    HighlightedText,
    IAtButtonProps,
    IAtTabsContent,
} from "@atman/design-system";
import { AtSubScaleNavigationItem } from "../AtSubScaleNavigation/components/AtSubScaleNavigationItem";
import { CandidateInterpretationHeader } from "../CandidateInterpretationHeader";
import { IAtCompatibilityFitStructure, IPersonScore } from "@atman/business";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export enum AtCompatibilityFitDataViewTabType {
    Interpretation,
    Strengths,
    Advices,
}

export type AtCompatibilityFitDataViewTab = { type: AtCompatibilityFitDataViewTabType; navigationItem: JSX.Element };

export interface IAtCompatibilityFitDataViewProps {
    data: IAtCompatibilityFitStructure;
    scores?: IPersonScore[];
    showAllTabs?: boolean;
    status?: React.ReactNode;
}

@inject()
@observer
export class AtCompatibilityFitDataView extends React.Component<IAtCompatibilityFitDataViewProps, {}> {
    public static defaultProps = {
        showAllTabs: false,
    };

    @observable currentTab: AtCompatibilityFitDataViewTabType;

    constructor(props: IAtCompatibilityFitDataViewProps) {
        super(props);

        if (this.hasSummary) {
            this.currentTab = AtCompatibilityFitDataViewTabType.Interpretation;
        } else if (this.hasStrengths) {
            this.currentTab = AtCompatibilityFitDataViewTabType.Strengths;
        } else if (this.hasAdvices) {
            this.currentTab = AtCompatibilityFitDataViewTabType.Advices;
        } else {
            this.currentTab = AtCompatibilityFitDataViewTabType.Interpretation;
        }
    }

    @computed get numberOfOptions(): number {
        const values = [this.hasSummary, this.hasStrengths, this.hasAdvices];

        return values.filter(Boolean).length;
    }

    @computed get hasSummary(): boolean {
        const { data } = this.props;

        return !!data.summary;
    }

    @computed get hasStrengths(): boolean {
        const {
            data: { strengths, candidatesData },
        } = this.props;

        return strengths.any() || candidatesData[0].strengths.any() || candidatesData[1].strengths.any();
    }

    @computed get hasAdvices(): boolean {
        const {
            data: { advices, candidatesData },
        } = this.props;

        return advices.any() || candidatesData[0].advices.any() || candidatesData[1].advices.any();
    }

    @action onFilterItemClick = (tab: AtCompatibilityFitDataViewTabType): void => {
        this.currentTab = tab;
    };

    getTabContent(type: AtCompatibilityFitDataViewTabType.Advices | AtCompatibilityFitDataViewTabType.Strengths): {
        combinedTextElementsAdvices: string[];
        combinedTextElementsStrengths: string[];
        firstCandidateTextElements: string[];
        secondCandidateTextElements: string[];
    } {
        if (type === AtCompatibilityFitDataViewTabType.Advices) {
            return {
                combinedTextElementsAdvices: this.props.data.advices,
                combinedTextElementsStrengths: [],
                firstCandidateTextElements: this.props.data.candidatesData[0].advices,
                secondCandidateTextElements: this.props.data.candidatesData[1].advices,
            };
        }

        return {
            combinedTextElementsStrengths: this.props.data.strengths,
            combinedTextElementsAdvices: [],
            firstCandidateTextElements: this.props.data.candidatesData[0].strengths,
            secondCandidateTextElements: this.props.data.candidatesData[1].strengths,
        };
    }

    renderStatusAndCandidatesAvatar(): JSX.Element | null {
        const { status, data, scores } = this.props;

        const firstCandidateScore = scores?.find((x) => x.candidateProId === data.candidatesData[0].candidateProId);
        const secondCandidateScore = scores?.find((x) => x.candidateProId === data.candidatesData[1].candidateProId);

        if (status !== undefined && firstCandidateScore && secondCandidateScore) {
            return (
                <div className="statusAndCandidatesAvatar">
                    {status}
                    <div className="highlighted-candidates">
                        <CandidateInterpretationHeader
                            candidateDisplayName={firstCandidateScore.candidateDisplayName}
                            bgColor={firstCandidateScore.color}
                            textColor={firstCandidateScore.textColor}
                        />

                        <CandidateInterpretationHeader
                            candidateDisplayName={secondCandidateScore.candidateDisplayName}
                            bgColor={secondCandidateScore.color}
                            textColor={secondCandidateScore.textColor}
                        />
                    </div>
                </div>
            );
        }
        return null;
    }

    renderContentForTab(tab: AtCompatibilityFitDataViewTabType): JSX.Element | null {
        const { data, scores, showAllTabs } = this.props;

        if (tab === AtCompatibilityFitDataViewTabType.Interpretation) {
            return (
                <>
                    <HighlightedText color={showAllTabs ? "#FFFAF1" : "#FFF"}>
                        {showAllTabs && (
                            <AtText className="interpretation-title" weight="bold">
                                {t({ id: "psychometrics.interpretation" })}
                            </AtText>
                        )}
                        <AtText>{data.summary}</AtText>
                    </HighlightedText>
                </>
            );
        }

        const firstCandidateScore = scores?.find((x) => x.candidateProId === data.candidatesData[0].candidateProId);
        const secondCandidateScore = scores?.find((x) => x.candidateProId === data.candidatesData[1].candidateProId);

        if (!firstCandidateScore || !secondCandidateScore) {
            console.warn("Missing candidate score");
            return null;
        }

        const {
            combinedTextElementsStrengths,
            combinedTextElementsAdvices,
            firstCandidateTextElements,
            secondCandidateTextElements,
        } = this.getTabContent(tab);

        return (
            <>
                {combinedTextElementsStrengths.any() && (
                    <div className="combined-elements-container-strength elements-container">
                        {showAllTabs && (
                            <AtText weight="bold">{t({ id: "global.compatibilityStrengthTitle" })}</AtText>
                        )}

                        {showAllTabs === false && firstCandidateScore && secondCandidateScore && (
                            <div className="highlighted-candidates">
                                <CandidateInterpretationHeader
                                    candidateDisplayName={firstCandidateScore.candidateDisplayName}
                                    bgColor={firstCandidateScore.color}
                                    textColor={firstCandidateScore.textColor}
                                />
                                <AtIcon icon={["far", "exchange"]} />
                                <CandidateInterpretationHeader
                                    candidateDisplayName={secondCandidateScore.candidateDisplayName}
                                    bgColor={secondCandidateScore.color}
                                    textColor={secondCandidateScore.textColor}
                                />
                            </div>
                        )}

                        <ul>
                            {combinedTextElementsStrengths.map((x, i) => (
                                <li key={i}>
                                    <AtParagraph>{x}</AtParagraph>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {combinedTextElementsAdvices.any() && (
                    <div className="combined-elements-container-advice elements-container">
                        {showAllTabs && (
                            <AtText weight="bold">{t({ id: "global.compatibilityAdviceTitle" })}</AtText>
                        )}

                        {showAllTabs === false && firstCandidateScore && secondCandidateScore && (
                            <div className="highlighted-candidates">
                                <CandidateInterpretationHeader
                                    candidateDisplayName={firstCandidateScore.candidateDisplayName}
                                    bgColor={firstCandidateScore.color}
                                    textColor={firstCandidateScore.textColor}
                                />
                                <AtIcon icon={["far", "exchange"]} />
                                <CandidateInterpretationHeader
                                    candidateDisplayName={secondCandidateScore.candidateDisplayName}
                                    bgColor={secondCandidateScore.color}
                                    textColor={secondCandidateScore.textColor}
                                />
                            </div>
                        )}

                        <ul>
                            {combinedTextElementsAdvices.map((x, i) => (
                                <li key={i}>
                                    <AtParagraph>{x}</AtParagraph>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {firstCandidateTextElements.any() && (
                    <div className="first-candidate-elements-container elements-container">
                        <div className="header">
                            <CandidateInterpretationHeader
                                candidateDisplayName={firstCandidateScore.candidateDisplayName}
                                bgColor={firstCandidateScore.color}
                                textColor={firstCandidateScore.textColor}
                            />
                        </div>
                        <ul>
                            {firstCandidateTextElements.map((x, i) => (
                                <li key={i}>
                                    <AtParagraph>{x}</AtParagraph>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {secondCandidateTextElements.any() && (
                    <div className="second-candidate-elements-container elements-container">
                        <div className="header">
                            <CandidateInterpretationHeader
                                candidateDisplayName={secondCandidateScore.candidateDisplayName}
                                bgColor={secondCandidateScore.color}
                                textColor={secondCandidateScore.textColor}
                            />
                        </div>
                        <ul>
                            {secondCandidateTextElements.map((x, i) => (
                                <li key={i}>
                                    <AtParagraph>{x}</AtParagraph>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </>
        );
    }

    render(): JSX.Element {
        const viewTabs: AtCompatibilityFitDataViewTab[] = [];

        if (this.hasSummary) {
            viewTabs.push({
                type: AtCompatibilityFitDataViewTabType.Interpretation,
                navigationItem: (
                    <AtSubScaleNavigationItem
                        icon={["far", "info-circle"]}
                        selected={this.currentTab === AtCompatibilityFitDataViewTabType.Interpretation}
                        onClick={() => this.onFilterItemClick(AtCompatibilityFitDataViewTabType.Interpretation)}
                    >
                        {"psychometrics.interpretation".localize()}
                    </AtSubScaleNavigationItem>
                ),
            });
        }
        if (this.hasStrengths) {
            viewTabs.push({
                type: AtCompatibilityFitDataViewTabType.Strengths,
                navigationItem: (
                    <AtSubScaleNavigationItem
                        icon={["far", "fist-raised"]}
                        selected={this.currentTab === AtCompatibilityFitDataViewTabType.Strengths}
                        onClick={() => this.onFilterItemClick(AtCompatibilityFitDataViewTabType.Strengths)}
                    >
                        {"psychometrics.strengths".localize()}
                    </AtSubScaleNavigationItem>
                ),
            });
        }
        if (this.hasAdvices) {
            viewTabs.push({
                type: AtCompatibilityFitDataViewTabType.Advices,
                navigationItem: (
                    <AtSubScaleNavigationItem
                        icon={["far", "lightbulb"]}
                        selected={this.currentTab === AtCompatibilityFitDataViewTabType.Advices}
                        onClick={() => this.onFilterItemClick(AtCompatibilityFitDataViewTabType.Advices)}
                    >
                        {"psychometrics.advices".localize()}
                    </AtSubScaleNavigationItem>
                ),
            });
        }

        const { showAllTabs } = this.props;

        const buttonProps: Omit<IAtButtonProps, "children" | "onClick"> = { size: "md" };
        const tabs: IAtTabsContent[] = [
            ...(this.hasSummary
                ? [
                      {
                          id: AtCompatibilityFitDataViewTabType.Interpretation.toString(),
                          buttonContent: t({ id: "psychometrics.interpretation" }),
                          buttonProps,
                          content: this.renderContentForTab(AtCompatibilityFitDataViewTabType.Interpretation),
                      },
                  ]
                : []),
            ...(this.hasStrengths
                ? [
                      {
                          id: AtCompatibilityFitDataViewTabType.Strengths.toString(),
                          buttonContent: t({ id: "psychometrics.strengths" }),
                          buttonProps,
                          content: this.renderContentForTab(AtCompatibilityFitDataViewTabType.Strengths),
                      },
                  ]
                : []),
            ...(this.hasAdvices
                ? [
                      {
                          id: AtCompatibilityFitDataViewTabType.Advices.toString(),
                          buttonContent: t({ id: "psychometrics.advices" }),
                          buttonProps,
                          content: this.renderContentForTab(AtCompatibilityFitDataViewTabType.Advices),
                      },
                  ]
                : []),
        ];
        return (
            <>
                <AtContainer className="AtCompatibilityFitDataView">
                    {showAllTabs && this.renderStatusAndCandidatesAvatar()}
                    {showAllTabs ? (
                        tabs.map((tab) => (
                            <div className="atCompatibilityFitDataView" key={tab.id}>
                                {tab.content}
                            </div>
                        ))
                    ) : (
                        <AtTabs tabs={tabs} />
                    )}
                </AtContainer>
            </>
        );
    }
}
