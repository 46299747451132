import { BaseModel, IBaseInput, IBaseModel } from "../base/BaseModel";
import {
    IBillingInformation,
    IProductConsumptionModeScheduleEntryModel,
    IndustryEnum,
    PlatformUseEnum,
    ProRestrictionType,
    ProductCodeProEnum,
} from "../types";
import { ReactText } from "react";
import { UsageModel } from "./UsageModel";

export interface IPartnerInput extends IBaseInput {
    name: string;
    email: string;
    communicationLanguage: string;
    ownerId: string;
    quickLinksEnabled: boolean;
    marketingInfo: IPartnerMarketingInfo;
    consumptionSettings: IPartnerConsumptionSettings;
    billingInformation: IBillingInformation;
    initialProductConsumptionModeScheduleEntry: IProductConsumptionModeScheduleEntryModel;
    unlimitedClientCreationEnabled: boolean;
    defaultAssessmentLabel: string;
    features?: IPartnerToggledFeatures;
}

export interface IPartner extends IBaseModel {
    readonly name: string;
    readonly email: string;
    readonly communicationLanguage: string;
    readonly ownerId: string;
    readonly quickLinksEnabled: boolean;
    readonly availableJobFits: string[];
    readonly lastSeen: string;
    readonly billingInformation: IBillingInformation;
    readonly marketingInfo: IPartnerMarketingInfo;
    readonly consumptionSettings: IPartnerConsumptionSettings;
    readonly usageModel: UsageModel;
    readonly transferableProducts: ProductCodeProEnum[];
    readonly unlimitedClientCreationEnabled: boolean;
    readonly clientCount: number;
    readonly features: IPartnerToggledFeatures;
}

export class Partner extends BaseModel implements IBaseInput {
    public name: string;
    public email: string;
    public communicationLanguage: string;
    public ownerId: string;
    public quickLinksEnabled: boolean;
    public availableJobFits: string[];
    public lastSeen: string;
    public billingInformation: IBillingInformation;
    public marketingInfo: IPartnerMarketingInfo;
    public consumptionSettings: IPartnerConsumptionSettings;
    public usageModel: UsageModel;
    public transferableProducts: ProductCodeProEnum[];
    public unlimitedClientCreationEnabled: boolean;
    public clientCount: number;
    public features: IPartnerToggledFeatures;

    constructor(json: IPartner) {
        super(json);

        this.name = json.name;
        this.email = json.email;
        this.communicationLanguage = json.communicationLanguage;
        this.ownerId = json.ownerId;
        this.quickLinksEnabled = json.quickLinksEnabled;
        this.availableJobFits = json.availableJobFits;
        this.lastSeen = json.lastSeen;
        this.billingInformation = json.billingInformation;
        this.marketingInfo = json.marketingInfo;
        this.consumptionSettings = json.consumptionSettings;
        this.usageModel = new UsageModel(json.usageModel);
        this.transferableProducts = json.transferableProducts;
        this.unlimitedClientCreationEnabled = json.unlimitedClientCreationEnabled;
        this.clientCount = json.clientCount;
        this.features = json.features;
    }
}

export interface IPartnerConsumptionSettings {
    restrictionType: ProRestrictionType;
    discountRate: number;
}

export interface IPartnerMarketingInfo {
    marketingType: IndustryEnum;
    employeesCount: ReactText;
    expectedAnnualHiresCount: ReactText;
    expectedInterviewPerHire: ReactText;
    expectedAnnualGrowth: ReactText;
    turnoverRate: ReactText;
    platformUses: PlatformUseEnum[];
}

export interface IPartnerToggledFeatures {
    quickLinks: boolean;
    competencyDevelopment: boolean;
    allowSso: boolean;
    canAllowSsoOnSubOrganizations: boolean;
    customTermsConditions: boolean;
}
