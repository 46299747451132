import { AssessmentDocumentType, IPersonScore, IPersonScores } from "../models";
import { AssessmentType, CandidateState, IAssessmentInfo } from "../types";

export class AssessmentDocumentHelper {
    public static readonly assessmentTypeToDocumentTypeMapping: Map<AssessmentType, AssessmentDocumentType> = new Map<
        AssessmentType,
        AssessmentDocumentType
    >([
        [AssessmentType.ProCognitiveTest, AssessmentDocumentType.Cognitive],
        [AssessmentType.ProPersonalityTest, AssessmentDocumentType.Personality],
        [AssessmentType.ProPreferencesTest, AssessmentDocumentType.Preferences],
        [AssessmentType.ProTripleBottomLineTest, AssessmentDocumentType.TripleBilan],
        [AssessmentType.ProLearningTest, AssessmentDocumentType.LearningMode],
    ]);

    public static getNullableScore = (scores: Dictionary<string, number>, key: string) => {
        if (!scores.hasOwnProperty(key)) {
            return undefined;
        }

        return scores[key];
    };

    public static getScoresForDimension = (scores: IPersonScores[] = [], dimensionName: string): IPersonScore[] => {
        return scores.map(
            (x) =>
                ({
                    score: AssessmentDocumentHelper.getNullableScore(x.scores, dimensionName),
                    color: x.color,
                    textColor: x.textColor,
                    candidateProId: x.candidateProId,
                    candidateDisplayName: x.candidateDisplayName,
                    candidatePictureUri: x.candidatePictureUri,
                } as IPersonScore),
        );
    };

    public static getCanditateAssessmentState(assessmentInfo: IAssessmentInfo | undefined): CandidateState | undefined {
        if (!assessmentInfo) {
            return undefined;
        }

        if (!assessmentInfo.startDate && !assessmentInfo.endDate) {
            return CandidateState.NeverTakeTheTest;
        }

        if (assessmentInfo.startDate && !assessmentInfo.endDate) {
            return CandidateState.TestStarted;
        }

        if (assessmentInfo.startDate && assessmentInfo.endDate) {
            return CandidateState.TestDone;
        }

        return undefined;
    }
}
