import "./index.less";
import { AtBadge, AtIcon, AtSubtitle, AtTitle, AtTooltip, DefaultComponentProps } from "@atman/design-system";
import { HandlePanelChangeWithAnimation } from "../AtSidePanelLayout/components/SidePanelContainer";
import { SidePanelItemDefinition } from "./SidePanelStructure";
import { observer } from "mobx-react";
import { useUniqueId } from "@atman/core";
import React, { useEffect } from "react";
import cn from "classnames";

export interface ISidePanelElementProps extends DefaultComponentProps {
    sidePanelItemDefinition: SidePanelItemDefinition;
    isActive: boolean;
    handleActivePanelChange: HandlePanelChangeWithAnimation;
    isCustomRightSidePanelDisplayed?: boolean;
}

const SidePanelElement: React.FC<ISidePanelElementProps> = observer((props) => {
    const {
        className,
        sidePanelItemDefinition,
        isActive,
        handleActivePanelChange,
        isCustomRightSidePanelDisplayed,
        ...otherProps
    } = props;

    const {
        title,
        description,
        label,
        icon,
        subElements,
        customHoverHandler,
        customBackendSearch,
        customRightSidePanelRenderer,
        customLeftSidePanelRenderer,
        isStarred,
    } = sidePanelItemDefinition;

    useEffect(() => {
        if (sidePanelItemDefinition.isClickedAtLoad) {
            handleActivePanelChange(sidePanelItemDefinition, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidePanelItemDefinition.isClickedAtLoad]);

    const descriptionSizeLimit = 150;
    const cleanedDescription = description ? description.replace(/\\t/g, " ") : "";

    const trimmedDescription = cleanedDescription && cleanedDescription.substr(0, descriptionSizeLimit);
    const isDescriptionTrimmed = trimmedDescription && trimmedDescription.length < cleanedDescription.length;

    const { uniqueId } = useUniqueId();

    const isDisabled = sidePanelItemDefinition.isDisabled;

    return (
        <div
            className={cn(
                "SidePanelElement",
                { isActive: isActive, isDisabled: isDisabled, bottomBorderLess: customLeftSidePanelRenderer },
                className,
            )}
            onClick={() => {
                handleActivePanelChange(sidePanelItemDefinition);
            }}
            onMouseEnter={customHoverHandler ? () => customHoverHandler("enter") : undefined}
            onMouseLeave={customHoverHandler ? () => customHoverHandler("leave") : undefined}
            {...otherProps}
        >
            <div className="elementContainer">
                <div className="inside">
                    {icon && (
                        <div className="icon-container">
                            <AtIcon size="lg" icon={icon} />
                        </div>
                    )}

                    {customLeftSidePanelRenderer ? (
                        <span className="custom-left-container">{customLeftSidePanelRenderer()}</span>
                    ) : null}
                    <div className="customTitleSubtitle">
                        <span>
                            {isStarred && (
                                <div className="starred">
                                    <p>M</p>
                                </div>
                            )}
                            <AtTitle title={title} headingType={5} />
                        </span>

                        <AtSubtitle
                            subtitle={isDescriptionTrimmed ? `${trimmedDescription}...` : trimmedDescription}
                            size={"sm"}
                        />
                    </div>
                </div>

                {customRightSidePanelRenderer && isCustomRightSidePanelDisplayed ? (
                    <span className="custom-right-container">{customRightSidePanelRenderer()}</span>
                ) : null}

                {isDescriptionTrimmed && (
                    <div className="tooltipDescription">
                        <AtIcon id={uniqueId} className="tooltipIcon" icon={["fas", "question-circle"]} />
                        <AtTooltip
                            target={uniqueId}
                            displayMode="dark"
                            placement={"right"}
                            modifiers={{ preventOverflow: { enabled: true, boundariesElement: "viewport" } }}
                        >
                            {cleanedDescription}
                        </AtTooltip>
                    </div>
                )}

                {(subElements.size > 0 || customBackendSearch) && (
                    <span className="icon-rightSide">
                        <AtIcon icon={["far", "chevron-right"]} />
                    </span>
                )}
            </div>
            {label && <AtBadge>{label}</AtBadge>}
        </div>
    );
});

export default SidePanelElement;

export * from "./SidePanelElementSkeleton";
