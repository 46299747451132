import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";
import { CompDevObservableBehavior, ICompDevObservableBehavior } from "./CompDevObservableBehavior";
import {
    CompDevSubCompetenciesStrategicObjective,
    ICompDevSubCompetenciesStrategicObjective,
} from "./CompDevSubCompetenciesStrategicObjective";

export interface ICompDevSubCompetency extends IBaseCompetencyElement {
    readonly observableBehaviors: ICompDevObservableBehavior[];
    readonly strategicObjectives: ICompDevSubCompetenciesStrategicObjective[];
}

export class CompDevSubCompetency extends BaseCompetencyElement implements ICompDevSubCompetency {
    public readonly observableBehaviors: CompDevObservableBehavior[];
    public readonly strategicObjectives: CompDevSubCompetenciesStrategicObjective[];
    public readonly hierarchicalRank: string;

    constructor(json: ICompDevSubCompetency, parentRank: number) {
        super(json);

        this.strategicObjectives = json.strategicObjectives;
        this.hierarchicalRank = `${parentRank}.${this.rank}`;
        this.observableBehaviors = json.observableBehaviors.map(
            (x) => new CompDevObservableBehavior(x, this.hierarchicalRank),
        );
    }
}
