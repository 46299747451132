export enum CompetencyDevelopmentProcessStatus {
    Started,
    Completed,
    Cancelled,
    Closed,
}

export enum CompetencySource {
    Specific,
    Job,
    Team,
    Org,
}

export enum CompetencyDevelopmentAssessmentStatus {
    NotStarted,
    Started,
    Completed,
    Cancelled,
}

export enum CompetencyDevelopmentAssessmentType {
    Manager,
    Self,
    Review,
    CompetenciesToLeverage,
    CompetenciesToImprove,
}

export enum CompetencyDevelopmentProcessStep {
    Started = 0,
    Assessment = 1,
    Review = 2,
    DevelopmentPlan = 3,
    Completed = 4,
}

export enum CompetencyDevelopmentLeveragingObjectiveType {
    Blooming,
    CareerDevelopment,
    Contribution,
}

export enum CompetencyDevelopmentLearningResourceType {
    Article,
    Book,
    Video,
    Podcast,
    Certification,
    Audio,
    Audiobook,
    Blog,
    Toolbox,
    Diagnostic,
    Course,
    InternationalCourse,
    PartnerCourse,
    Other,
}

export enum CompDevCompetencyGroup {
    Analysis = 0,
    Discipline = 1,
    Energy = 2,
    Influence = 3,
    Relation = 4,
    Others = 6,
    Performance = 7,
}
