import "./index.less";
import * as React from "react";
import { DefaultComponentProps } from "@atman/design-system";
import { observer } from "mobx-react";

export interface IAtLabelledComponentListProps extends DefaultComponentProps {
    //children: Array<AtLabelledComponent>;
}

export const AtLabelledComponentList: React.FC<IAtLabelledComponentListProps> = observer((props) => {
    const { children, className, ...otherProps } = props;

    let elementClassName: string = "AtLabelledComponentList";

    if (className) {
        elementClassName += ` ${className}`;
    }

    return (
        <div className={elementClassName} {...otherProps}>
            {children}
        </div>
    );
});
