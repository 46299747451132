import "./index.less";
import * as React from "react";
import {
    AtCard,
    AtCheckboxBase,
    AtRadioButtonBase,
    AtSwitch,
    IAtCardProps,
    IAtCheckboxBaseProps,
} from "../../../internal";
import { observer } from "mobx-react";

export type AtCheckableCardInputType = "checkbox" | "radio" | "switch";

export interface IAtCheckableCardProps extends IAtCardProps {
    id?: string;
    className?: string;
    inputType?: AtCheckableCardInputType;
    checked: boolean;
    onCheckboxClick?: () => void;
    onContainerClick?: () => void;
    checkboxProps?: Partial<Omit<IAtCheckboxBaseProps, "checked" | "onChange" | "id">>;
}

export const AtCheckableCard: React.FC<IAtCheckableCardProps> = observer((props) => {
    const {
        id,
        className,
        inputType = "checkbox",
        checked,
        topContent,
        onCheckboxClick,
        onContainerClick,
        children,
        checkboxProps,
        ...otherProps
    } = props;

    const _onCheckboxClick = (event: React.FormEvent<HTMLInputElement>) => {
        event?.stopPropagation();

        if (checkboxProps?.disabled) {
            return;
        }

        onCheckboxClick?.();
        onContainerClick?.();
        otherProps.onTopSectionClick?.();
    };

    const _onContainerClick = () => {
        if (checkboxProps?.disabled) {
            return;
        }

        onContainerClick?.();
    };

    const renderTopContent: () => JSX.Element = () => {
        return (
            <>
                {inputType === "checkbox" && (
                    <AtCheckboxBase checked={checked} onChange={_onCheckboxClick} id={id} {...checkboxProps} />
                )}
                {inputType === "radio" && <AtRadioButtonBase checked={checked} onChange={_onCheckboxClick} id={id} />}
                {inputType === "switch" && <AtSwitch checked={checked} onChange={_onCheckboxClick} id={id} />}
                {topContent}
            </>
        );
    };

    return (
        <AtCard
            className={`AtCheckableCard ${className ?? ""} ${
                checkboxProps?.disabled === true ? "is-disabled" : ""
            }`.trim()}
            topContent={renderTopContent()}
            onClick={_onContainerClick}
            {...otherProps}
        >
            {children}
        </AtCard>
    );
});
