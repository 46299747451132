import "./index.less";
import {
    AnalysisTileEnum,
    AnalyzedCandidate,
    AssessmentDocumentHelper,
    AssessmentDocumentType,
    AssessmentType,
    AtmanAssessmentDocumentStructure,
    DimensionGroupStructure,
    DimensionStructure,
    DistributionData,
    DistributionViewMode,
    DrillDownLevelEnum,
    IDimensionGroupStructure,
    IInterpretationData,
    IPersonScores,
    IRange,
    ScaleMaxScore,
    ScaleType,
    ScoreDisplayType,
    Team,
} from "@atman/business";
import { AtBadge, AtCollapsibleCard, AtTitle, ReportRangeLegendItem } from "@atman/design-system";
import { AtBipolarScaleGroup } from "../AtBipolarScaleGroup";
import { AtScaleLegend } from "../AtScaleLegend";
import { AtUnipolarScaleGroup } from "../AtUnipolarScaleGroup";
import { BaseAtScale } from "../BaseAtScale";
import { BaseComponent } from "../../BaseComponent";
import { FitScoreContainer } from "../FitScoreContainer";
import { HelpCenterInfoLink } from "../..";
import { IBaseAtScaleGroupProps } from "../BaseAtScaleGroup";
import { ReportCandidateLegendItem } from "../../ReportCandidateLegendItem";
import { ReportDimensionWeightLegendItem } from "../../ReportDimensionWeightLegendItem";
import { ReportLegendContainer } from "../../ReportLegend";
import { STANINE_SCALE_SEPARATORS } from "../../AtGenericScales";
import { ScoreInfo } from "../ScoreInfo";
import { action, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { inject, observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAssessmentReportProps {
    activeTile: AnalysisTileEnum;
    documentStructure: AtmanAssessmentDocumentStructure;
    documentIndex: number;
    scores?: IPersonScores[];
    distributionData?: DistributionData;
    interpretationDataEntries?: Map<string, IInterpretationData>;
    analyzedCandidate?: AnalyzedCandidate;
    distributionMembers?: AnalyzedCandidate[];
    showDistribution?: boolean;
    showFitIndicators?: boolean;
    distributionViewMode?: DistributionViewMode;
    showUnipolarScaleAsBipolar?: boolean;
    defaultCollapseOpenState?: boolean;
    team?: Team;
    onViewCandidateDetailClick?: (candidateProId: string) => void;
    onActiveMemberUnselected?: () => void;
}

@inject()
@observer
export class AssessmentReport extends BaseComponent<IAssessmentReportProps> {
    @observable expandedDimension?: string;

    @action onExpandedDimensionChange = (dimensionName: string): void => {
        if (this.expandedDimension === dimensionName) {
            this.expandedDimension = undefined;
        } else {
            this.expandedDimension = dimensionName;
        }
    };

    getCommonScaleGroupProps: (group: IDimensionGroupStructure) => IBaseAtScaleGroupProps = computedFn((group) => {
        const {
            documentStructure,
            scores = [],
            distributionData,
            showDistribution,
            showFitIndicators,
            distributionViewMode,
            distributionMembers,
            analyzedCandidate,
            interpretationDataEntries,
            onViewCandidateDetailClick,
        } = this.props;

        return {
            dimensionGroup: group,
            maxScore: documentStructure.maxScore,
            assessmentType: documentStructure.assessmentTypeEnum,
            analyzedCandidateScores: analyzedCandidate?.dimensionScores,
            expandedDimension: this.expandedDimension,
            onExpandedDimensionChange: this.onExpandedDimensionChange,
            key: group.enumName ?? group.name,
            scores,
            distributionData,
            showDistribution,
            distributionViewMode,
            distributionMembers,
            showFitIndicators,
            interpretationDataEntries,
            showDescription: true,
            showHeader: documentStructure.drillDownLevel === DrillDownLevelEnum.Assessment,
            onViewCandidateDetailClick: onViewCandidateDetailClick,
        } as IBaseAtScaleGroupProps & React.ComponentProps<any>;
    });

    get isInTeamView(): boolean {
        return this.props.team !== undefined;
    }

    renderScaleGroup(
        group: IDimensionGroupStructure,
        showDenominator: boolean = false,
        displayCompact: boolean = false,
    ): JSX.Element {
        const { documentStructure, showUnipolarScaleAsBipolar } = this.props;

        switch (documentStructure.scaleType) {
            case ScaleType.Unipolar:
                return (
                    <AtUnipolarScaleGroup
                        isCompetencyGroup={false}
                        scaleLabels={documentStructure.scaleLabels}
                        showUnipolarScaleAsBipolar={showUnipolarScaleAsBipolar}
                        key={group.name}
                        showDenominator={showDenominator}
                        displayCompact={!this.isInTeamView && displayCompact}
                        {...this.getCommonScaleGroupProps(group)}
                    />
                );
            case ScaleType.Bipolar:
                return <AtBipolarScaleGroup key={group.name} {...this.getCommonScaleGroupProps(group)} />;
        }
    }

    getScore(dimensions: string[]): number | undefined {
        const { distributionData, scores, analyzedCandidate } = this.props;

        if (distributionData) {
            return distributionData.getAverageForDimensions(dimensions);
        } else {
            const candidateScores = scores?.find((x) => x.candidateProId === analyzedCandidate?.candidateProId)?.scores;

            if (candidateScores) {
                const averageValues: number[] = [];

                for (const dimensionName of dimensions) {
                    if (!candidateScores.hasOwnProperty(dimensionName)) {
                        continue;
                    }

                    averageValues.push(candidateScores[dimensionName]);
                }

                if (!averageValues.any()) {
                    return undefined;
                }

                return averageValues.sum((x) => x) / averageValues.length;
            }
        }

        return undefined;
    }

    renderFitHeaderSideComponents(dimension: DimensionStructure): JSX.Element {
        const {} = this.props;

        return (
            <div className={"fit-header-side-components-container"}>
                {dimension.weightLabel && (
                    <AtBadge size={"sm"}>{`${"important".localize()} ${dimension.weightLabel}`}</AtBadge>
                )}
                {!this.isNullOrUndefined(dimension.fitScore) && <FitScoreContainer fitScore={dimension.fitScore!} />}
            </div>
        );
    }

    renderCognitive(group: DimensionGroupStructure): JSX.Element {
        const {
            documentStructure,
            showDistribution,
            distributionData,
            distributionMembers,
            distributionViewMode,
            onViewCandidateDetailClick,
            interpretationDataEntries,
            scores,
        } = this.props;

        const score = this.getScore(group.dimensions.map((x) => x.enumName));
        const generalCognitiveDimension = group.dimensions[0];

        const range: IRange | undefined = generalCognitiveDimension.range
            ? { min: generalCognitiveDimension.range.min, max: generalCognitiveDimension.range.max }
            : undefined;

        const teamViewContent = (
            <div className="cognitive-container-team">
                <AtScaleLegend
                    separators={STANINE_SCALE_SEPARATORS}
                    sectionLabels={documentStructure.scaleLabels}
                    maxScore={ScaleMaxScore.Stanine}
                    scaleType={ScaleType.Unipolar}
                    showScaleValues={false}
                    isInTeamView
                    showScoreLabels
                    useAverageLabel
                />
                <BaseAtScale
                    scaleType={ScaleType.Bipolar}
                    dimension={generalCognitiveDimension}
                    maxScore={documentStructure.maxScore}
                    assessmentType={documentStructure.assessmentTypeEnum!}
                    scores={AssessmentDocumentHelper.getScoresForDimension(scores, generalCognitiveDimension.enumName)}
                    distributionMembers={distributionMembers}
                    distributionEntries={distributionData?.getEntriesForDimension(generalCognitiveDimension.enumName)}
                    distributionAverage={distributionData?.getAverageForDimension(generalCognitiveDimension.enumName)}
                    distributionViewMode={distributionViewMode}
                    showDistribution={showDistribution}
                    expandedDimension={this.expandedDimension}
                    onExpandedDimensionChange={this.onExpandedDimensionChange}
                    interpretationData={interpretationDataEntries?.get(generalCognitiveDimension.enumName)}
                    key={generalCognitiveDimension.enumName}
                    printMode={undefined}
                    showDescription={true}
                    onViewCandidateDetailClick={onViewCandidateDetailClick}
                    separators={STANINE_SCALE_SEPARATORS}
                />
            </div>
        );

        return (
            <>
                {this.isInTeamView ? (
                    teamViewContent
                ) : (
                    <div className="cognitive-container-profile">
                        <div className="score-container">
                            <ScoreInfo
                                scoreDisplayType={ScoreDisplayType.StanineHalfCircle}
                                containerWidth={120}
                                score={score}
                                isScoreAnAverage={group.dimensions.length > 1}
                                showLabel={true}
                                range={range}
                                showOutOfXLabel={true}
                                showInterpretation={false}
                                isScoreComparedToAnAverage
                                isGradient
                            />
                        </div>
                        <div className="header-container">
                            <AtTitle headingType={3} title={group.name} />
                        </div>
                    </div>
                )}

                {documentStructure.groups.length > 1 && (
                    <AtCollapsibleCard
                        title={"clientApp.viewSpecificCognitiveSkills".localize()}
                        titleSize="sm"
                        defaultCollapseOpenState={false}
                    >
                        <>
                            {this.isInTeamView && (
                                <AtScaleLegend
                                    maxScore={ScaleMaxScore.Stanine}
                                    scaleType={ScaleType.Unipolar}
                                    separators={STANINE_SCALE_SEPARATORS}
                                    sectionLabels={documentStructure.scaleLabels}
                                    useAverageLabel
                                    isInTeamView
                                />
                            )}
                            {this.renderScaleGroup(documentStructure.groups[1], false, true)}
                        </>
                    </AtCollapsibleCard>
                )}
            </>
        );
    }

    renderAssessmentDrillDownLevel(): JSX.Element | null {
        const { documentStructure, defaultCollapseOpenState, activeTile } = this.props;

        if (!documentStructure.groups.any()) {
            return null;
        }

        let cardContent: JSX.Element;
        const isCognitive: boolean = documentStructure.assessmentDocumentType === AssessmentDocumentType.Cognitive;

        if (isCognitive) {
            cardContent = this.renderCognitive(documentStructure.groups[0]);
        } else {
            cardContent = this.renderScaleGroups();
        }

        let helpCenterArticleId: number | undefined;

        switch (activeTile) {
            case AnalysisTileEnum.Assessments:
            case AnalysisTileEnum.Distribution:
                switch (documentStructure.assessmentTypeEnum) {
                    case AssessmentType.ProCognitiveTest:
                        helpCenterArticleId = 360054659111;
                        break;
                    case AssessmentType.ProPersonalityTest:
                        helpCenterArticleId = 360054193572;
                        break;
                    case AssessmentType.ProPreferencesTest:
                        helpCenterArticleId = 360054659191;
                        break;
                    case AssessmentType.ProTripleBottomLineTest:
                        helpCenterArticleId = 360054659311;
                        break;
                    case AssessmentType.ProLearningTest:
                        helpCenterArticleId = 5482878105869;
                        break;
                }
                break;
            case AnalysisTileEnum.JobFit:
                helpCenterArticleId = 360054659371;
                break;
            case AnalysisTileEnum.CultureFit:
                helpCenterArticleId = 360054193812;
                break;
            case AnalysisTileEnum.Potential:
                helpCenterArticleId = 360054659411;
                break;
            case AnalysisTileEnum.CompatibilityFit:
                helpCenterArticleId = 360054659431;
                break;
        }

        return (
            <AtCollapsibleCard
                title={documentStructure.title}
                defaultCollapseOpenState={defaultCollapseOpenState && (!isCognitive || !this.isInTeamView)}
                customRightHeaderContent={
                    helpCenterArticleId ? (
                        <HelpCenterInfoLink
                            title={"global.moreAboutThisReport".localize()}
                            href={`articles/${helpCenterArticleId}`}
                        />
                    ) : undefined
                }
            >
                {cardContent}
            </AtCollapsibleCard>
        );
    }

    renderScaleGroups(): JSX.Element {
        const { distributionMembers, documentStructure, showUnipolarScaleAsBipolar } = this.props;

        const hasHighLabel = documentStructure.groups.some((g) => g.dimensions.some((d) => d.highLabel));
        const scaleType = showUnipolarScaleAsBipolar && hasHighLabel ? ScaleType.Bipolar : documentStructure.scaleType;
        const isInTeamView = distributionMembers !== undefined && distributionMembers.length > 0;
        const showScale = isInTeamView || scaleType === ScaleType.Bipolar;

        return (
            <>
                {showScale && (
                    <AtScaleLegend
                        separators={STANINE_SCALE_SEPARATORS}
                        sectionLabels={documentStructure.scaleLabels}
                        maxScore={ScaleMaxScore.Stanine}
                        scaleType={scaleType}
                        showScaleValues={scaleType === ScaleType.Bipolar}
                        isInTeamView={isInTeamView}
                        showScoreLabels={scaleType !== ScaleType.Bipolar}
                    />
                )}

                <div className="groups">
                    {documentStructure.groups.map((g) => this.renderScaleGroup(g, false, false))}
                </div>
            </>
        );
    }

    renderContentHeader(isInTeamView: boolean): JSX.Element | null | undefined {
        const { activeTile, documentStructure, scores, distributionData, onActiveMemberUnselected } = this.props;

        if (!scores) {
            return null;
        }

        const isInProfileOrTeamDistribution = !this.isInTeamView || activeTile === AnalysisTileEnum.Distribution; // In team view, we show them only in distribution mode. In the other modes we have it on the top header.

        const hasPositiveDimensionWithRange = documentStructure.groups.some((g) =>
            g.dimensions.some((d) => d.range && !d.isInverted),
        );
        const hasInvertedDimensionWithRange = documentStructure.groups.some((g) =>
            g.dimensions.some((d) => d.range && d.isInverted),
        );
        const hasDimensionRange = hasPositiveDimensionWithRange || hasInvertedDimensionWithRange;

        const dimensionStructures = documentStructure.groups.flatMap((g) => g.dimensions.slice());
        const hasDistributionRange = dimensionStructures.some((d) => {
            // This could be in a logic file.
            const entries = distributionData?.getEntriesForDimension(d.enumName);
            if (entries === undefined) return false;
            return entries.some((e) => e.isRange);
        });
        const hasDimensionWeight = documentStructure.groups.some((g) => g.dimensions.some((d) => d.weightLabel));
        const showCandidates = isInTeamView || documentStructure.scaleType === ScaleType.Bipolar;

        const legendItems: JSX.Element[] = [];

        // Ranges
        if (isInProfileOrTeamDistribution && hasDistributionRange) {
            legendItems.push(
                <ReportRangeLegendItem
                    iconClassName="team-concentration"
                    text={"psychometrics.teamsTendancy".localize()}
                />,
            );
        }

        if (isInProfileOrTeamDistribution && hasDimensionRange) {
            if (hasPositiveDimensionWithRange)
                legendItems.push(<ReportRangeLegendItem text={"psychometrics.desiredArea".localize()} />);
            if (hasInvertedDimensionWithRange)
                legendItems.push(<ReportRangeLegendItem text={"psychometrics.undesirableArea".localize()} />);
        }

        if (hasDimensionWeight) {
            legendItems.push(<ReportDimensionWeightLegendItem />);
        }

        // Candidates - Note: In team view, we show candidate only at the top of the document.
        if (showCandidates) {
            scores.forEach((c) =>
                legendItems.push(
                    <ReportCandidateLegendItem
                        key={c.candidateProId}
                        candidateDisplayName={c.candidateDisplayName}
                        bgColor={c.color}
                        textColor={c.textColor}
                        size={"xs"}
                        onClose={c.isSelectedCandidate ? onActiveMemberUnselected : undefined}
                    />,
                ),
            );
        }

        if (!legendItems.length) {
            return;
        }

        return (
            <div className="content-header">
                {(scores.length > 0 || hasDimensionRange || hasDistributionRange) && (
                    <ReportLegendContainer>{legendItems}</ReportLegendContainer>
                )}
            </div>
        );
    }

    renderGroupDrillDownLevel() {
        const { distributionMembers, documentStructure, defaultCollapseOpenState } = this.props;

        const isInTeamView = distributionMembers !== undefined && distributionMembers.length > 0;

        return (
            <>
                {documentStructure.groups.map((g, i) => {
                    const cardContent: JSX.Element = (
                        <>
                            {isInTeamView && (
                                <AtScaleLegend
                                    maxScore={ScaleMaxScore.Stanine}
                                    scaleType={documentStructure.scaleType}
                                    showScaleValues={documentStructure.scaleType === ScaleType.Bipolar}
                                    sectionLabels={documentStructure.scaleLabels}
                                    showScoreLabels={i === 0}
                                    isInTeamView={isInTeamView}
                                />
                            )}
                            {this.renderScaleGroup(g)}
                        </>
                    );

                    return (
                        <AtCollapsibleCard
                            title={this.isStringNullEmptyOrUndefined(g.name) ? documentStructure.title : g.name}
                            defaultCollapseOpenState={defaultCollapseOpenState}
                            key={g.enumName}
                        >
                            {cardContent}
                        </AtCollapsibleCard>
                    );
                })}
            </>
        );
    }

    renderDimensionDrillDownLevel(): JSX.Element[] | null {
        const {
            documentStructure,
            scores = [],
            showUnipolarScaleAsBipolar,
            distributionMembers,
            distributionData,
            distributionViewMode,
            showDistribution,
            interpretationDataEntries,
            onViewCandidateDetailClick,
            defaultCollapseOpenState,
        } = this.props;

        if (!documentStructure) {
            return null;
        }

        const dimensionStructures = documentStructure.groups.reduce((a, b) => [...a, ...b.dimensions], []);

        return dimensionStructures.map((d) => (
            <AtCollapsibleCard
                title={d.highLabel ? `${d.lowLabel} - ${d.highLabel}` : d.lowLabel} // Consider using d.enumName instead
                defaultCollapseOpenState={defaultCollapseOpenState}
                customRightHeaderContent={this.renderFitHeaderSideComponents(d)}
                className={"dimension-drilldown-card"}
                key={`dimensionStructures_${d.enumName}`}
            >
                <div className={"at-scale-container"}>
                    <BaseAtScale
                        scaleType={showUnipolarScaleAsBipolar ? ScaleType.Bipolar : documentStructure.scaleType}
                        dimension={d}
                        maxScore={documentStructure.maxScore}
                        assessmentType={documentStructure.assessmentTypeEnum!}
                        scores={AssessmentDocumentHelper.getScoresForDimension(scores, d.enumName)}
                        distributionMembers={distributionMembers}
                        distributionEntries={distributionData?.getEntriesForDimension(d.enumName)}
                        distributionAverage={distributionData?.getAverageForDimension(d.enumName)}
                        distributionViewMode={distributionViewMode}
                        showDistribution={showDistribution}
                        expandedDimension={this.expandedDimension}
                        onExpandedDimensionChange={this.onExpandedDimensionChange}
                        interpretationData={interpretationDataEntries?.get(d.enumName)}
                        printMode={undefined}
                        showDescription={true}
                        onViewCandidateDetailClick={onViewCandidateDetailClick}
                        separators={STANINE_SCALE_SEPARATORS}
                    />
                </div>
            </AtCollapsibleCard>
        ));
    }

    render(): JSX.Element {
        const { documentStructure, team } = this.props;
        let assessmentReportContent: React.ReactNode;

        switch (documentStructure.drillDownLevel) {
            case DrillDownLevelEnum.Assessment:
                assessmentReportContent = this.renderAssessmentDrillDownLevel();
                break;
            case DrillDownLevelEnum.Group:
                assessmentReportContent = this.renderGroupDrillDownLevel();
                break;
            case DrillDownLevelEnum.Dimension:
                assessmentReportContent = this.renderDimensionDrillDownLevel();
                break;
        }

        return (
            <div
                className={cn(
                    "AssessmentReport",
                    `${AssessmentDocumentType[documentStructure.assessmentDocumentType].toCamel()}-report`,
                    { "team-report": !!team, "profile-report": !team },
                )}
            >
                {assessmentReportContent}
            </div>
        );
    }
}
