import { AtButton, AtButtonSizes, Colors } from "@atman/design-system";
import {
    ConfigurationProvider,
    Environment,
    GlobalStores,
    INTERCOM_TOUR_IDS,
    IntercomApi,
    LocalizationStore,
    UserInfoStore,
} from "@atman/business";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IStartTourButtonProps {
    tourKey: keyof typeof INTERCOM_TOUR_IDS;
    btnSize?: AtButtonSizes;
    customLabel?: string;
    className?: string;
    id?: string;
    localizationStore?: LocalizationStore;
    userInfoStore?: UserInfoStore;
}

export const StartTourButton: React.FC<IStartTourButtonProps> = inject(
    GlobalStores.localizationStore,
    GlobalStores.userInfoStore,
)(
    observer((props) => {
        const { tourKey, customLabel, className, id, btnSize, localizationStore, userInfoStore } = props;

        if (userInfoStore!.intercomUserBlacklisted) {
            return null;
        }

        const intercomEnv = ConfigurationProvider.currentEnvironment === Environment.Prod ? "prod" : "test";
        const tourId = INTERCOM_TOUR_IDS[tourKey][intercomEnv][localizationStore!.currentShortLocale];

        const onClick = () => IntercomApi.startTour(tourId);
        let classNames = "StartTourButton";

        if (className) {
            classNames += ` ${className}`;
        }

        return (
            <AtButton
                className={classNames}
                id={id}
                color={"ghost"}
                size={btnSize}
                icon={["far", "directions"]}
                iconProps={{ color: Colors.primaryBase }}
                onClick={onClick}
            >
                {customLabel ?? "global.buttons.labels.takeTheTour".localize()}
            </AtButton>
        );
    }),
);
