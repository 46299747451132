import "./index.less";
import { AtTableColumn } from "../../../types";
import { AtTableRowHeight } from "../AtTableRow";
import { IBaseEntity } from "@atman/core";
import { isColumnHidden } from "../ColumnsDisplayHelper";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAtTableRowCellProps<T extends IBaseEntity> {
    column: AtTableColumn<T>;
    item: T;
    height?: AtTableRowHeight;
}

function AtTableRowCellRaw<T extends IBaseEntity>(props: IAtTableRowCellProps<T>) {
    const { item, column, height = "sm", ...otherProps } = props;
    const { fieldName, render, width, displayOnHover, align, displayOverSize, hideOverSize, hideColumn } = column;

    const isHidden = isColumnHidden(displayOverSize, hideOverSize);
    const value = item[fieldName];

    return (
        <td
            className={cn("AtTableRowCell", {
                isHidden: isHidden || hideColumn,
                displayOnHover: displayOnHover,
                [`size-${height}`]: height,
            })}
            {...otherProps}
            style={{ width, textAlign: align }}
        >
            {render ? render(value, item) : value}
        </td>
    );
}

export const AtTableRowCell = observer(AtTableRowCellRaw);
