import "./index.less";
import * as React from "react";
import { AtSelect, IReactSelectOptionObject } from "../../../../../design-system";
import { AtmanCoPlatformsEnum } from "../../global/components/ProductSelector";
import { AtmanLogoFullDark } from "@atman/ui-kit/lib/static";
import { AuthStore } from "../../data/stores/AuthStore";
import { LocalizationStore } from "@atman/business";
import { LoginAppStores } from "../../../index";
import { Route } from "react-router-dom";
import { RouteComponentProps, StaticContext, Switch } from "react-router";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import ChangePassword from "./ChangePassword";
import ChooseClient from "./ChooseClient";
import CookiePolicy from "./CookiePolicy";

export interface IAuthenticatedContainerProps
    extends RouteComponentProps<{}, StaticContext, { platform: AtmanCoPlatformsEnum }> {
    authStore?: AuthStore;
    localizationStore?: LocalizationStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    authStore: stores.authStore,
    localizationStore: stores.localizationStore,
}))
@observer
class AuthenticatedContainer extends React.Component<IAuthenticatedContainerProps, {}> {
    @observable private platform: AtmanCoPlatformsEnum;

    @computed get languageCodeDerivedValue(): IReactSelectOptionObject {
        const { localizationStore } = this.props;

        return {
            value: localizationStore!.currentShortLocale,
            label: localizationStore!.currentShortLocale.toUpperCase(),
        };
    }

    constructor(props) {
        super(props);

        this.loadPlatformFromState();
    }

    @action loadPlatformFromState = () => {
        const { location } = this.props;

        if (location?.state?.platform !== undefined) {
            this.platform = location?.state?.platform;
        }
    };

    updateLanguageCode = (option: IReactSelectOptionObject) => {
        const { localizationStore } = this.props;

        localizationStore!.updateCurrentLocale(option.value);
    };

    render() {
        const { authStore, localizationStore, history } = this.props;

        if (!authStore!.isAuthenticated) {
            history.push("/");
            return null;
        }

        return (
            <div id="AuthenticatedContainer">
                <div className="header">
                    <img height={24} src={AtmanLogoFullDark} />
                    <div className="language-selector">
                        <AtSelect
                            options={localizationStore!.availableLanguages.map((x) => ({
                                value: x.shortName,
                                label: x.shortDisplay,
                            }))}
                            value={this.languageCodeDerivedValue}
                            onChange={this.updateLanguageCode}
                            additionalStyles={{
                                control: (base) => ({
                                    ...base,
                                    minHeight: 31,
                                    padding: 4,
                                }),
                                indicatorsContainer: (base) => ({
                                    ...base,
                                    padding: 0,
                                }),
                                dropdownIndicator: (base) => ({
                                    ...base,
                                    padding: 0,
                                }),
                            }}
                        />
                    </div>
                </div>
                <div className="content">
                    {
                        <Switch>
                            <Route path={"/Auth/ChooseClient"}>
                                <ChooseClient platform={this.platform} />
                            </Route>
                            <Route path={"/Auth/ChangePassword"}>
                                <ChangePassword platform={this.platform} />
                            </Route>
                            <Route path={"/Auth/CookiePolicy"}>
                                <CookiePolicy platform={this.platform} />
                            </Route>
                        </Switch>
                    }
                </div>
            </div>
        );
    }
}

export default AuthenticatedContainer;
