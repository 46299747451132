import "./EntityOverviewCardLine.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IEntityOverviewStat } from "./EntityOverviewCard";

export interface IEntityOverviewCardLineProps {
    stat: IEntityOverviewStat;
}

export const EntityOverviewCardLine: React.FC<IEntityOverviewCardLineProps> = (props) => {
    const { stat } = props;

    return (
        <div className="EntityOverviewCardLine">
            <div className="left-section">
                <FontAwesomeIcon icon={stat.icon} />
                <span className="name">{stat.nameKey.localize()}</span>
            </div>
            <div className="right-section">
                <span className="value">{stat.value}</span>
            </div>
        </div>
    );
};
