import "./index.less";
import {
    AppScope,
    GlobalStores,
    IAppContext,
    IndustryEnum,
    LocalizationStore,
    ScopedOrganizationStore,
    UnhandledScopeError,
    UserInfoStore,
} from "@atman/business";
import { AtCheckbox, AtTabCard } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { Col, Row } from "reactstrap";
import { CustomTextInput } from "../CustomTextInput";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface ICompanyInformationFormProps extends IAppContext {
    userInfoStore?: UserInfoStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.userInfoStore, GlobalStores.scopedOrganizationStore, GlobalStores.localizationStore)
@observer
class CompanyInformationFormComp extends BaseForm<ICompanyInformationFormProps, {}> {
    @observable public companyName: string = "";
    @observable public timezone: string = "";
    @observable public industry: IndustryEnum = IndustryEnum.Reseller;
    @observable public requiredLanguages: string[] = [];

    componentDidMount() {
        this.resetCompanyInformation();
        this.resetRequiredLanguages();
    }

    @action
    resetCompanyInformation = () => {
        const { scope, scopedOrganizationStore, userInfoStore } = this.props;

        switch (scope) {
            case AppScope.Client:
                this.companyName = scopedOrganizationStore!.scopedOrganization?.name ?? userInfoStore!.clientName;
                break;
            case AppScope.Partner:
                this.companyName = scopedOrganizationStore!.scopedOrganization?.name ?? userInfoStore!.partnerName;
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    };

    @action
    resetRequiredLanguages = () => {
        const { scope, localizationStore } = this.props;

        switch (scope) {
            case AppScope.Client:
                this.requiredLanguages = localizationStore!.requiredLanguages;
                break;
            case AppScope.Partner:
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    };

    @autobind
    async _onCompanyInformationSave() {
        const { scope, scopedOrganizationStore } = this.props;

        switch (scope) {
            case AppScope.Client:
            case AppScope.Partner:
                this.setIsLoading(true);
                await scopedOrganizationStore!.updateName(this.companyName);
                this.setIsLoading(false);
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    @autobind
    async onCompanyRequiredLanguagesSave() {
        const { scope, scopedOrganizationStore } = this.props;

        switch (scope) {
            case AppScope.Client:
                this.setIsLoading(true);
                await scopedOrganizationStore!.updateClientRequiredLanguages(this.requiredLanguages);
                this.setIsLoading(false);
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    @autobind
    validateCompanyInformationForm(): boolean {
        return !!this.companyName;
    }

    render() {
        const { scope, localizationStore } = this.props;

        return (
            <div id="CompanyInformation">
                <Row>
                    <Col md={12} lg={6}>
                        <AtTabCard cardTitle={"global.updateCompanyInformation".localize()} className="company-info">
                            <CustomTextInput
                                fieldName={"companyName"}
                                value={this.companyName}
                                placeholder={"global.companyNamePlaceholder".localize()}
                                onChange={this.onTextFieldChange}
                                formText={"global.companyNameInfoText".localize()}
                            />
                            <div className="buttons-container">
                                <CardSaveButtons
                                    saveAction={this._onCompanyInformationSave}
                                    cancelAction={this.resetCompanyInformation}
                                    disabledSaveButton={!this.validateCompanyInformationForm()}
                                    isLoading={this.isLoading}
                                />
                            </div>
                        </AtTabCard>
                    </Col>
                    {scope === AppScope.Client && (
                        <Col md={12} lg={6}>
                            <AtTabCard
                                cardTitle={"global.updateCompanyRequiredLanguages".localize()}
                                className="required-languages-form"
                            >
                                <div className="description">{"global.requiredLanguages.description".localize()}</div>
                                {localizationStore!.availableLanguages.map((x, i) => (
                                    <AtCheckbox
                                        checked={this.requiredLanguages.includes(x.shortName)}
                                        onChange={this.onCheckboxesChange}
                                        value={x.shortName}
                                        fieldName={"requiredLanguages"}
                                        id={`requiredLanguageCheckbox-${x.shortName}`}
                                        key={i}
                                    >
                                        {x.shortDisplay}
                                    </AtCheckbox>
                                ))}
                                <div className="buttons-container">
                                    <CardSaveButtons
                                        saveAction={this.onCompanyRequiredLanguagesSave}
                                        cancelAction={this.resetRequiredLanguages}
                                        isLoading={this.isLoading}
                                    />
                                </div>
                            </AtTabCard>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const CompanyInformationForm = withAppContext(CompanyInformationFormComp);

export { CompanyInformationForm };
