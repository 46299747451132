import { CompDevCompetencyResult, IRange } from "../models";
import { RangeHelper } from "./RangeHelper";

export class ScaleHelper {
    static interpretLikertAvgScore(score: number): LikertValue | undefined {
        return RangeHelper.getRangeMapValue(LIKERT_AVG_INTERPRETATION_RANGES, score, true, false);
    }

    static countCompetenciesPerLikertValue(competencies: CompDevCompetencyResult[]): Map<LikertValue, number> {
        let count = new Map<LikertValue, number>([
            [LikertValue.StronglyDisagree, 0],
            [LikertValue.Disagree, 0],
            [LikertValue.NeitherAgreeNorDisagree, 0],
            [LikertValue.Agree, 0],
            [LikertValue.StronglyAgree, 0],
        ]);

        if (competencies) {
            count = competencies.reduce((acc, c) => {
                const cat = this.interpretLikertAvgScore(
                    c.subCompetenciesReviewAverage ?? c.subCompetenciesAverage ?? 0,
                );
                if (cat !== undefined) {
                    acc.set(cat, acc.get(cat)! + 1);
                }
                return acc;
            }, count);
        }

        return count;
    }
}

export enum LikertValue {
    StronglyDisagree,
    Disagree,
    NeitherAgreeNorDisagree,
    Agree,
    StronglyAgree,
}

// Ranges defined by Fred and Peter
export const LIKERT_AVG_INTERPRETATION_RANGES: Map<IRange, LikertValue> = new Map<IRange, LikertValue>([
    [{ min: 1, max: 1.5 }, LikertValue.StronglyDisagree],
    [{ min: 1.5, max: 2.5 }, LikertValue.Disagree],
    [{ min: 2.5, max: 3.5 }, LikertValue.NeitherAgreeNorDisagree],
    [{ min: 3.5, max: 4.5 }, LikertValue.Agree],
    [{ min: 4.5, max: 5.1 }, LikertValue.StronglyAgree],
]);

export const getRoundedScore = (score: number | null | undefined): string | undefined => {
    if (score === undefined || score === null || isNaN(score)) {
        return "-";
    }

    return score.toFixed(1);
};
