import { Colors } from "../../../../styles/variables/colors";
import { VictoryLabel } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IScoreLabelProps {
    text?: string | string[];
    viewBoxWidth: number;
    size?: "regular" | "small"; // Regular: Used by 360 pie, small: used by the 180 pie.
}

@inject()
@observer
export class ScoreLabel extends React.Component<IScoreLabelProps> {
    public static defaultProps = { viewBoxWidth: 400 };

    render(): JSX.Element {
        const { text, size, viewBoxWidth = 0 } = this.props;

        const pos_y = size === "small" ? viewBoxWidth / 2 - 40 : viewBoxWidth / 2;
        const fontSize = size === "small" ? 104 : 128;

        return (
            <VictoryLabel
                //@ts-ignore
                inline={true}
                textAnchor="middle"
                verticalAnchor={"middle"}
                style={{
                    fontSize: fontSize,
                    fontFamily: "Quicksand",
                    color: Colors.greyBase,
                    fontWeight: "bold",
                }}
                x={viewBoxWidth / 2}
                y={pos_y}
                text={text}
            />
        );
    }
}
