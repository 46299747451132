import "./index.less";
import { AtButton, AtTitle, Colors, DefaultComponentProps } from "@atman/design-system";
import { SidePanelItemDefinition } from "../../../SidePanelElement/SidePanelStructure";
import React from "react";

export interface ISidePanelNavigationProps extends DefaultComponentProps {
    activeSidePanel: SidePanelItemDefinition | undefined;
    handleBackButton: () => void;
    isBackButtonDisabled?: boolean;
}

export const SidePanelNavigation: React.FC<ISidePanelNavigationProps> = (props) => {
    const { activeSidePanel, handleBackButton, isBackButtonDisabled, ...otherProps } = props;

    return (
        <div className="SidePanelNavigation" {...otherProps}>
            <AtButton
                icon={["far", "arrow-left"]}
                iconProps={{ color: Colors.primaryBase }}
                color="secondary"
                size="lg"
                onClick={handleBackButton}
                disabled={isBackButtonDisabled}
            ></AtButton>
            <AtTitle headingType={5} title={activeSidePanel?.title} />
        </div>
    );
};
