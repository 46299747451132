import "./RouteNavItem.less";
import * as React from "react";
import { NavLink as BootstrapNavLink, NavItem, NavItemProps } from "reactstrap";
import { NavLink, Route } from "react-router-dom";
import { Role, UrlFormatter } from "@atman/business";

export interface IRouteNavItemProps extends NavItemProps {
    href: string;
    external?: string;
    mvclink?: string;
    roles?: Role[];
    currentRoles?: string[];
    extraClasses?: string;
    includeActiveIndicator?: boolean;
}

export class RouteNavItem extends React.Component<IRouteNavItemProps, {}> {
    render() {
        const {
            href,
            external,
            mvclink,
            roles,
            currentRoles,
            extraClasses,
            includeActiveIndicator = false,
            onClick,
            ...props
        } = this.props;

        // @ts-ignore
        const hrefOverride = UrlFormatter.formatReactPath(href);

        if (roles) {
            const roleKeys = roles.map((x) => Role[x]);
            if (!currentRoles || !currentRoles.some((x) => roleKeys.indexOf(x) > -1)) {
                return null;
            }
        }

        let className = "RouteNavItem";

        if (extraClasses) {
            className = `${className} ${extraClasses}`;
        }

        if (!href && onClick) {
            return (
                <NavItem className={className} {...props}>
                    <BootstrapNavLink onClick={onClick}>{props.children}</BootstrapNavLink>
                </NavItem>
            );
        }

        if (external) {
            return (
                <NavItem className={className} {...props}>
                    <BootstrapNavLink href={href} target="_blank">
                        {props.children}
                    </BootstrapNavLink>
                </NavItem>
            );
        }

        if (mvclink) {
            return (
                <NavItem className={className} {...props}>
                    <BootstrapNavLink href={href}>{props.children}</BootstrapNavLink>
                </NavItem>
            );
        }

        return (
            <Route path={hrefOverride}>
                {({ match }) => (
                    <NavItem className={className} {...props} active={!!match}>
                        <NavLink to={hrefOverride}>
                            {props.children}
                            {match && includeActiveIndicator && <div className="active-indicator" />}
                        </NavLink>
                    </NavItem>
                )}
            </Route>
        );
    }
}
