import "./LikertLegend.less";
import * as React from "react";
import { Col, Label, Row } from "reactstrap";
import { Localizer } from "@atman/business";

export interface ILikertLegendProps {
    scales?: string[];
    languageCode?: string;
    inCol?: boolean;
}

export const LikertLegend: React.FC<ILikertLegendProps> = (props) => {
    const { languageCode, scales, inCol = true } = props;

    let scaleLabels: string[];

    if (scales) {
        scaleLabels = scales;
    } else {
        scaleLabels = [
            "global.likertLegendLabel.1".localize(),
            "global.likertLegendLabel.2".localize(),
            "global.likertLegendLabel.3".localize(),
            "global.likertLegendLabel.4".localize(),
            "global.likertLegendLabel.5".localize(),
        ];
    }

    const labelsComponent = (
        <>
            {scaleLabels.map((x, i) => (
                <Label key={i}>{languageCode ? Localizer.localizeForLanguage(x, languageCode) : x}</Label>
            ))}
        </>
    );

    return (
        <Row id="LikertLegend" noGutters>
            {inCol ? (
                <Col sm={{ size: 6, offset: 6 }} className={"labels-col-container"}>
                    <div className="labels">{labelsComponent}</div>
                </Col>
            ) : (
                <div className="labels-flex-container">{labelsComponent}</div>
            )}
        </Row>
    );
};
