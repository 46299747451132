import { CompDevObservableBehavior, ICompDevObservableBehavior } from "../StructureModels";
import { CompetencyDevelopmentAssessmentType } from "../CompDevEnums";
import { IMapEntries, autorun, computed, observable } from "mobx";

export interface ICompDevObservableBehaviorResult extends ICompDevObservableBehavior {
    readonly assessmentScores?: IMapEntries<CompetencyDevelopmentAssessmentType, number | null>;
    readonly assessmentNotes?: IMapEntries<CompetencyDevelopmentAssessmentType, string | null>;
}

export class CompDevObservableBehaviorResult extends CompDevObservableBehavior {
    @observable public assessmentScores: Map<CompetencyDevelopmentAssessmentType, number | null>;
    @observable public assessmentNotes: Map<CompetencyDevelopmentAssessmentType, string | null>;

    @observable public averageBetweenManagerAndEmployee: number | undefined;

    constructor(json: ICompDevObservableBehaviorResult, parentRank: string) {
        super(json, parentRank);

        this.assessmentScores = new Map<CompetencyDevelopmentAssessmentType, number | null>(json.assessmentScores);
        this.assessmentNotes = new Map<CompetencyDevelopmentAssessmentType, string | null>(json.assessmentNotes);

        autorun(() => {
            this.averageBetweenManagerAndEmployee = this._averageBetweenManagerAndEmployee;
        });
    }

    @computed
    private get _averageBetweenManagerAndEmployee(): number | undefined {
        const managerScore = this.assessmentScores.get(CompetencyDevelopmentAssessmentType.Manager);
        const employeeScore = this.assessmentScores.get(CompetencyDevelopmentAssessmentType.Self);

        if (managerScore && employeeScore) {
            return (managerScore + employeeScore) / 2;
        }

        if (managerScore) {
            return managerScore;
        }

        if (employeeScore) {
            return employeeScore;
        }

        return undefined;
    }
}
