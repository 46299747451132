import "./index.less";
import { BackgroundColorProperty, ColorProperty } from "csstype";
import { Colors, ExtraAndDefaultSizes, XXL } from "../../../internal";
import { PrintModeEnum } from "@atman/business";
import React, { useMemo } from "react";
import cn from "classnames";

export interface IInitialsAvatarProps {
    text?: string | Array<string | undefined>;
    splitTextInParts?: boolean;
    textColor?: ColorProperty;
    bgColor?: BackgroundColorProperty;
    active?: boolean;
    size?: ExtraAndDefaultSizes | XXL;
    className?: string;
    printMode?: PrintModeEnum;
}

export const InitialsAvatar: React.FC<IInitialsAvatarProps> = (props: IInitialsAvatarProps) => {
    const {
        text = "",
        textColor = Colors.greyShades.shade2,
        bgColor = Colors.greyBg,
        className,
        splitTextInParts = true,
        active = false,
        size = "md",
        printMode,
        ...otherProps
    } = props;

    const initials = useMemo(() => {
        if (printMode !== undefined) {
            return "";
        }

        if (splitTextInParts) {
            const removeSpecialCharacters = (x: string) => x?.replace(/[&\/\\#, +()$~%.'":*?<>{}_-]/g, "");

            const parts = (Array.isArray(text) ? text : text.split(" "))
                .map(removeSpecialCharacters)
                .filter((x) => !!x);

            switch (parts.length) {
                case 1:
                    return `${parts[0].charAt(0).toUpperCase()} ${parts[0].charAt(1).toUpperCase()}`;
                    break;
                case 2:
                    return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}`;
                    break;
                case 3:
                    return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}${parts[2]
                        .charAt(0)
                        .toUpperCase()}`;
                    break;
                default:
                    if (parts.length > 0) {
                        return `${parts[0].charAt(0).toUpperCase()}${parts[1].charAt(0).toUpperCase()}`;
                    } else {
                        return "";
                    }
                    break;
            }
        }

        return Array.isArray(text)
            ? text.filter((x) => !!x).reduce((previousValue, currentValue) => `${previousValue}${currentValue}`, "")!
            : text;
    }, [printMode, splitTextInParts, text]);

    
    let color = textColor;
    let backgroundColor = bgColor;

    if (active) {
        color = "white";
        backgroundColor = Colors.atmanOrange;
    }

    return (
        <div
            className={cn("InitialsAvatar", `size-${size}`, className)}
            style={{ color, backgroundColor }}
            {...otherProps}
        >
            {initials}
        </div>
    );
};
