import * as React from "react";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Swal = require("sweetalert2/dist/sweetalert2");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const withReactContent = require("sweetalert2-react-content");
import { CustomAlertContent, ICustomAlertContentProps } from "./CustomAlertContent";
import { ReactSweetAlert } from "sweetalert2-react-content";
import { SweetAlertOptions } from "sweetalert2";

type CustomAlertOptions = SweetAlertOptions & Omit<ICustomAlertContentProps, "swalInstance">;

export class CustomAlert {
    public swalInstance: typeof Swal & ReactSweetAlert;
    protected title: string;
    protected html: React.ReactNode = null;
    protected confirmAction: () => void;
    protected confirmButtonText?: string;
    protected cancelButtonText?: string;
    protected additionalSweetAlertOptions: CustomAlertOptions;

    constructor(
        confirmAction: () => void,
        title: string,
        html: React.ReactNode,
        additionalAlertOptions: CustomAlertOptions = {},
        confirmButtonText?: string,
        cancelButtonText?: string,
    ) {
        this.confirmAction = confirmAction;
        this.title = title;
        this.html = html;
        this.additionalSweetAlertOptions = additionalAlertOptions;
        this.swalInstance = withReactContent(Swal);
        this.confirmButtonText = confirmButtonText;
        this.cancelButtonText = cancelButtonText;
    }

    renderAlertContent(options: CustomAlertOptions = {}): React.ReactNode {
        return (
            <CustomAlertContent
                swalInstance={this.swalInstance}
                showCancelButton={options.showCancelButton ?? this.additionalSweetAlertOptions.showCancelButton}
                cancelButtonText={
                    options.cancelButtonText ??
                    this.additionalSweetAlertOptions.cancelButtonText ??
                    this.cancelButtonText
                }
                cancelButtonProps={options.cancelButtonProps ?? this.additionalSweetAlertOptions.cancelButtonProps}
                showConfirmButton={options.showConfirmButton ?? this.additionalSweetAlertOptions.showConfirmButton}
                confirmButtonText={
                    options.confirmButtonText ??
                    this.additionalSweetAlertOptions.confirmButtonText ??
                    this.confirmButtonText
                }
                confirmButtonProps={options.confirmButtonProps ?? this.additionalSweetAlertOptions.confirmButtonProps}
                confirmAction={
                    options.confirmAction ?? this.additionalSweetAlertOptions.confirmAction ?? this.confirmAction
                }
                reverseButtons={options.reverseButtons ?? this.additionalSweetAlertOptions.reverseButtons}
            >
                {this.html}
            </CustomAlertContent>
        );
    }

    fire(options: CustomAlertOptions = {}, isMobile = false) {
        // @ts-ignore
        const fireParams = {
            title: this.title,
            html: this.renderAlertContent(options),
            showCancelButton: false,
            showConfirmButton: false,
            showLoaderOnConfirm: false,
            buttonsStyling: false,
            ...this.additionalSweetAlertOptions,
            ...options,
        } as SweetAlertOptions;

        if (isMobile) {
            fireParams.showClass = {
                popup: "animate__animated animate__slideInUp animate__faster",
            };
            fireParams.hideClass = {
                popup: "animate__animated animate__slideOutDown animate__faster",
            };
        } else {
            fireParams.showClass = {
                popup: "animate__animated animate__fadeInUp animate__faster",
            };
            fireParams.hideClass = {
                popup: "animate__animated animate__fadeOutDown animate__faster",
            };
        }

        this.swalInstance.fire(fireParams);
    }
}
