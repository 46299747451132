import "./index.less";
import * as React from "react";

import { DimensionStructure, FitLevelEnum } from "@atman/business";

import { inject, observer } from "mobx-react";

import { AtBadge, AtText, Colors } from "@atman/design-system";
import { FitScoreContainerAndLegend } from "../FitScoreContainerAndLegend";
import { t } from "@lingui/macro";

export interface IFitScoreMapDisplayProps {
    dimensions: DimensionStructure[];
    shouldShowCompetenciesLabels?: boolean;
    isJobFitReport?: boolean;
}

export const FitScoreMapDisplay: React.FC<IFitScoreMapDisplayProps> = inject()(
    observer((props) => {
        const { dimensions, shouldShowCompetenciesLabels = false, isJobFitReport = false } = props;

        const beVigilentDimentsion: DimensionStructure[] = dimensions.filter(
            (dimension) => dimension.fitScore === FitLevelEnum.BeVigilent,
        );
        const goodFitDimensions: DimensionStructure[] = dimensions.filter(
            (dimension) => dimension.fitScore === FitLevelEnum.GoodFit,
        );
        const veryGoodFitDimensions: DimensionStructure[] = dimensions.filter(
            (dimension) => dimension.fitScore === FitLevelEnum.VeryGood,
        );

        return (
            <div className={`FitScoreMapDisplay `}>
                <div className="dimensionLabels-wrapper">
                    <div className="dimensions-container">
                        <FitScoreContainerAndLegend
                            fitScore={FitLevelEnum.VeryGood}
                            description={t({
                                id: `global.fitLevels.${FitLevelEnum[FitLevelEnum.VeryGood].toCamel()}`,
                            })}
                            count={veryGoodFitDimensions.length}
                            isJobFitReport={isJobFitReport}
                        />
                        <div>
                            {shouldShowCompetenciesLabels &&
                                veryGoodFitDimensions.map((item) => (
                                    <div className="fitscoremap-atbadge-wrapper" key={item.lowLabel}>
                                        <AtBadge className="very-good-fit" pill customColor={Colors.greenBase}>
                                            <AtText size="lg">{item.lowLabel}</AtText>
                                        </AtBadge>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="dimensions-container">
                        <FitScoreContainerAndLegend
                            fitScore={FitLevelEnum.GoodFit}
                            description={t({
                                id: `global.fitLevels.${FitLevelEnum[FitLevelEnum.GoodFit].toCamel()}`,
                            })}
                            count={goodFitDimensions.length}
                            isJobFitReport={isJobFitReport}
                        />
                        <div>
                            {shouldShowCompetenciesLabels &&
                                goodFitDimensions.map((item) => (
                                    <div className="fitscoremap-atbadge-wrapper" key={item.lowLabel}>
                                        <AtBadge className="good-fit" pill customColor={Colors.yellowShades.shade4}>
                                            <AtText size="lg">{item.lowLabel}</AtText>
                                        </AtBadge>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="dimensions-container">
                        <FitScoreContainerAndLegend
                            fitScore={FitLevelEnum.BeVigilent}
                            description={t({
                                id: `global.fitLevels.${FitLevelEnum[FitLevelEnum.BeVigilent].toCamel()}`,
                            })}
                            count={beVigilentDimentsion.length}
                            isJobFitReport={isJobFitReport}
                        />
                        <div>
                            {shouldShowCompetenciesLabels &&
                                beVigilentDimentsion.map((item) => (
                                    <div className="fitscoremap-atbadge-wrapper" key={item.lowLabel}>
                                        <AtBadge className="be-vigilent" pill customColor={Colors.redShades.shade4}>
                                            <AtText size="lg">{item.lowLabel}</AtText>
                                        </AtBadge>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }),
);
