import "./TeamDevelopmentActionPlanObjectivesSectionImprove.less";
import { AtButton, AtContainer, AtTitleSubtitle, Colors } from "@atman/design-system";
import {
    CompDevSubCompetenciesStrategicObjectiveResult,
    CompetencyDevelopmentProcessResultsData,
    GlobalStores,
    ICompetencyDevelopmentProcessAssessmentData,
    IObjectiveWithCompetency,
    LocalizationStore,
    PrintModeEnum,
} from "@atman/business";
import React, { useEffect, useState } from "react";

import { ActionPlanHeaderRow } from "./ActionPlanHeaderRow";

import { ActionPlanTablesRow } from "./ActionPlanTablesRow";
import { ActionPlanTablesRowContentObjective } from "./ActionPlanTablesRowContentObjective";
import { CompetencyDevelopmentActionPlanColumnType, sortArrayByStringProp } from "../../ResultTableContainer";
import { EditAssessmentButton } from "./EditAssessmentButton";
import { ReportPageHeader } from "../../../ReportPageHeader";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface ITeamDevelopmentActionPlanObjectivesSectionImproveProps {
    candidateProcessResultsData: CompetencyDevelopmentProcessResultsData;
    processAssessmentData?: ICompetencyDevelopmentProcessAssessmentData;
    openAndMonitorTabFunc?: (url: string) => void;
    printMode?: PrintModeEnum;
    localizationStore?: LocalizationStore;
}

export const TeamDevelopmentActionPlanObjectivesSectionImprove: React.FC<ITeamDevelopmentActionPlanObjectivesSectionImproveProps> =
    inject(GlobalStores.localizationStore)(
        observer((props) => {
            const {
                candidateProcessResultsData,
                processAssessmentData,
                openAndMonitorTabFunc,
                printMode,
                localizationStore,
            } = props;

            const [headerImage, setHeaderImage] = useState<string>();

            const localLanguage = localizationStore!.currentLocale.shortName;

            useEffect(() => {
                const loadSvg = async () => {
                    const svg = await import(
                        /* webpackMode: "lazy", webpackChunkName: "cognitive-svg-[index]" */
                        `../headerBanners/${localLanguage}/md/Development.svg`
                    );

                    setHeaderImage(svg.default);
                };

                loadSvg().catch(console.error);
            }, [localLanguage]);

            const type = "improve";
            const objectivesToImprove = candidateProcessResultsData.getObjectivesToImprove();

            const improveColumns: CompetencyDevelopmentActionPlanColumnType[] = [
                "objective",
                "competencies",
                "subCompetencies",
                "actionsCount",
                "iconCol",
            ];

            const onClickGotoAssessmentToImprove = () => {
                if (openAndMonitorTabFunc && processAssessmentData) {
                    openAndMonitorTabFunc(processAssessmentData.url);
                }
            };

            return (
                <AtContainer className="TeamDevelopmentActionPlanObjectivesSectionImprove">
                    {printMode === undefined && (
                        <div className="section-header" style={{ backgroundColor: Colors.greenShades.shade9 }}>
                            <AtTitleSubtitle
                                title={t({ id: "competencyDevelopment.actionPlan.improvementSection.title" })}
                                subtitle={t({ id: "competencyDevelopment.actionPlan.SubTitleMain.improve" })}
                                size="lg"
                            />
                            <EditAssessmentButton
                                processAssessmentData={processAssessmentData}
                                openAndMonitorTabFunc={openAndMonitorTabFunc}
                            />
                        </div>
                    )}

                    {printMode !== undefined && headerImage && <ReportPageHeader size="md" imageUrl={headerImage} />}

                    {printMode === undefined && (
                        <ActionPlanHeaderRow
                            columns={["objective", "competencies", "subCompetencies", "actionsCount", "iconCol"]}
                            type={type}
                        />
                    )}

                    <div className="actionPlanTablesRow-container">
                        {(objectivesToImprove.length &&
                            sortArrayByStringProp(objectivesToImprove, (value) => value.data.name).map(
                                (
                                    objective: IObjectiveWithCompetency<CompDevSubCompetenciesStrategicObjectiveResult>,
                                ) => (
                                    <ActionPlanTablesRow
                                        objective={objective}
                                        type={type}
                                        columns={improveColumns}
                                        printMode={printMode}
                                        key={`objective-id-${objective.data.id}`}
                                    >
                                        <ActionPlanTablesRowContentObjective
                                            objective={objective}
                                            processAssessmentData={processAssessmentData}
                                            openAndMonitorTabFunc={openAndMonitorTabFunc}
                                            printMode={printMode}
                                        />
                                    </ActionPlanTablesRow>
                                ),
                            )) || (
                            <div className="footerContainer">
                                <AtButton color="ghost" onClick={onClickGotoAssessmentToImprove}>
                                    {t({ id: "competencyDevelopment.addCompetencyToImprove" })}
                                </AtButton>
                            </div>
                        )}
                    </div>
                </AtContainer>
            );
        }),
    );
