import "./ModalCloseButton.less";
import * as React from "react";
import { AtLink } from "@atman/design-system";
import { GlobalStores, ModalStore } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IModalCloseButtonProps {
    toggleModal?: () => void;
    modalStore?: ModalStore;
}

@inject(GlobalStores.modalStore)
@observer
export class ModalCloseButton extends React.Component<IModalCloseButtonProps> {
    render() {
        const { toggleModal, modalStore } = this.props;

        return <AtLink onClick={toggleModal ?? modalStore!.toggleModal}>{"global.close".localize()}</AtLink>;
    }
}
