export interface IBaseInput {}

export interface IBaseModel {
    id: string;
    createdAt?: string;
    updatedAt?: string;
}

export class BaseModel implements IBaseModel {
    public id: string;
    public createdAt?: string;
    public updatedAt?: string;

    constructor(input?: IBaseModel) {
        if (input) {
            if (input.id) {
                this.id = input.id;
            }

            this.createdAt = input.createdAt;
            this.updatedAt = input.updatedAt;
        }
    }
}
