import "./index.less";
import { AtLabelTitle, DefaultSizes } from "../../../internal";
import React from "react";

export interface IAtIconLabelTitleProps {
    icon: JSX.Element;
    title: string;
    label: string;
    size?: DefaultSizes;
}

export const AtIconLabelTitle: React.FC<IAtIconLabelTitleProps> = (props) => {
    const { icon, title, label, size = "md", ...otherProps } = props;

    return (
        <div className="AtIconLabelTitle" {...otherProps}>
            {icon}
            <AtLabelTitle title={title ?? "-"} label={label} size={size} labelAsSubtitle />
        </div>
    );
};
