import "./index.less";
import * as React from "react";
import { AssessmentType, ProductCodeProEnum } from "@atman/business";

export interface IProductIconProps {
    productCode?: ProductCodeProEnum;
    assessmentType?: AssessmentType;
}

export const ProductIcon: React.FC<IProductIconProps> = (props) => {
    const { productCode, assessmentType } = props;

    if (productCode !== undefined) {
        return <span className={`ProductIcon ${ProductCodeProEnum[productCode]}`} />;
    }

    if (assessmentType !== undefined) {
        return <span className={`ProductIcon ${AssessmentType[assessmentType]}`} />;
    }

    console.error("You need to provide a productCode or an assessmentType for the ProductIcon to render.");
    return null;
};
