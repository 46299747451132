import { BaseModel, IBaseModel } from "../base/BaseModel";

export interface IShortPartner extends IBaseModel {
    readonly name: string;
    readonly legacyId?: string;
}

export class ShortPartner extends BaseModel implements IShortPartner {
    public readonly name: string;
    public readonly legacyId?: string;

    constructor(json: IShortPartner) {
        super(json);

        this.name = json.name;
        this.legacyId = json.legacyId;
    }
}
