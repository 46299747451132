import { BaseCrudStore } from "../base";
import { ClientsApi, CulturesApi } from "../apis";
import { Culture, ICulture, ICultureInput } from "../models";
import { ReactAppSettings, ToastProvider } from "../libs";
import { TeamStore } from "./TeamStore";
import { action, runInAction } from "mobx";

export class CultureStore extends BaseCrudStore<ICulture, Culture, ICultureInput> {
    @action public async assignCultureToOrganization(id: string) {
        // TODO: Consider moving this whole method to ScopedOrganizationStore
        await this.tryCatch(async () => {
            await ClientsApi.assignCultureToClient(ReactAppSettings.viewBag.baseInfo.clientId, id);

            const culture = this.findItemById(id);

            if (!culture) {
                return;
            }

            this.rootStore.scopedOrganizationStore.updateClientCulture(culture.id, culture.name);

            ToastProvider.success(`${this._toastPrefix}_assignment_to_organization_success`.localize());
        });
    }

    @action public async unassignCultureFromOrganization(id: string) {
        await this.tryCatch(async () => {
            await ClientsApi.unassignCultureFromClient(ReactAppSettings.viewBag.baseInfo.clientId, id);

            const culture = this.findItemById(id);

            if (!culture) {
                return;
            }

            this.rootStore.scopedOrganizationStore.updateClientCulture(undefined, undefined);

            ToastProvider.success(`${this._toastPrefix}_unassignment_to_organization_success`.localize());
        });
    }

    @action public async assignCultureToTeams(id: string, updatedTeamIds: string[]) {
        await this.tryCatch(async () => {
            await CulturesApi.assignToTeams(id, updatedTeamIds);

            const cultureIndex = this.findItemIndexById(id);

            if (cultureIndex >= 0) {
                runInAction(() => {
                    this.items[cultureIndex].teamsCount = updatedTeamIds.length;
                });
            }

            const teamStore = (this.rootStore as unknown as any).teamStore as TeamStore;
            teamStore.updateTeamCultures(
                updatedTeamIds,
                id,
                this.items?.[cultureIndex]?.name ?? "unknownCultureName".localize(),
            );

            ToastProvider.success(`${this._toastPrefix}_assignment_to_teams_success`.localize());
        });
    }

    protected _modelCtor = Culture;
    protected _loadItems = () => CulturesApi.getCultures();
    protected _createItem = (input: ICultureInput) => CulturesApi.createCulture(input);
    protected _updateItem = (id: string, input: ICultureInput) => CulturesApi.updateCulture(id, input);
    protected _deleteItem = (id: string) => CulturesApi.deleteCulture(id);
}
