import "./index.less";
import * as React from "react";

export interface ICircleContainerProps extends React.HTMLProps<HTMLDivElement> {
    borderColor?: string;
}

export const CircleContainer: React.FC<ICircleContainerProps> = (props) => {
    const { children, className, borderColor, ...divProps } = props;

    const style: React.CSSProperties = {
        borderColor: borderColor,
    };

    return (
        <div className={`CircleContainer ${className ?? ""}`} style={style} {...divProps}>
            {children}
        </div>
    );
};
