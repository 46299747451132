import "./FrequenciesNotificationEntry.less";
import * as React from "react";
import { EmailNotificationFrequencyEnum, NotificationType } from "@atman/business";
import { Label } from "reactstrap";
import { RadioFormInput } from "@atman/design-system";
import { inject, observer } from "mobx-react";

export interface IFrequenciesNotificationEntryProps {
    type: NotificationType;
    availableFrequencies: EmailNotificationFrequencyEnum[];
    selectedFrequency: EmailNotificationFrequencyEnum;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

@inject()
@observer
export class FrequenciesNotificationEntry extends React.Component<IFrequenciesNotificationEntryProps, {}> {
    render() {
        const { type, availableFrequencies, selectedFrequency, onChange } = this.props;

        return (
            <div className="FrequenciesNotificationEntry">
                <Label>{`global.notificationTypes.${NotificationType[type].toCamel()}`.localize()}</Label>
                <div className="frequencies">
                    {availableFrequencies.map((x, i) => (
                        <RadioFormInput
                            key={`frequency-${x}`}
                            fieldName={NotificationType[type]}
                            value={x}
                            id={`${NotificationType[type]}-${x}`}
                            valueText={`global.frequencies.${EmailNotificationFrequencyEnum[x].toCamel()}`.localize()}
                            onChange={onChange}
                            checked={Number(selectedFrequency) === x}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
