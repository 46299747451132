import "./index.less";
import { AssessmentStatementInfo } from "@atman/business";
import { AtTitle } from "@atman/design-system";
import { Col, FormText, Row } from "reactstrap";
import { Colors } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LikertScales } from "../../../../../../../LikertEvaluation";
import { ValidationError } from "@atman/core";
import { observer } from "mobx-react";
import React from "react";

export interface IAssessmentWizardStatementEntryProps {
    statementInfo: AssessmentStatementInfo;
    statementIndex: number;
    leftColumnProps: { lg: number; xl: number };
    onChange: (statementId: string, score: number) => void;
    scales: string[];
    scoreValidationErrors: ValidationError[];
    currentScore?: number;
}

export const AssessmentWizardStatementEntry: React.FunctionComponent<IAssessmentWizardStatementEntryProps> = observer(
    (props) => {
        const {
            statementInfo,
            statementIndex,
            leftColumnProps,
            scales,
            scoreValidationErrors,
            onChange,
            currentScore,
        } = props;

        const rightColumnProps = {
            lg: 12 - leftColumnProps.lg,
            xl: 12 - leftColumnProps.xl,
        };

        return (
            <Row className="AssessmentWizardStatementEntry" noGutters>
                <Col className="left-section" {...leftColumnProps}>
                    <AtTitle
                        headingType={4}
                        title={`${statementIndex + 1}. ${statementInfo.label}`}
                        className="statement-label"
                    />
                    {scoreValidationErrors
                        .filter((e) => e.fieldName === statementInfo.id)
                        .map((x) => (
                            <FormText key={x.name} color={"danger"}>
                                <div className="icon-container">
                                    <FontAwesomeIcon icon={["far", "exclamation-circle"]} color={Colors.redBase} />
                                </div>
                                {x.message}
                            </FormText>
                        ))}
                </Col>
                <Col className="right-section" {...rightColumnProps}>
                    <LikertScales
                        scaleKey={statementInfo.id}
                        scales={scales}
                        currentValue={currentScore}
                        onChange={(e) => onChange(statementInfo.id, Number(e.currentTarget.value))}
                    />
                </Col>
            </Row>
        );
    },
);
