import { AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { t } from "@lingui/macro";
import React, { ReactElement, useMemo } from "react";

interface IAtTableDropdownFilterProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    appliedFilterValue: number | null;
    setFilterValue: (columnKey: string, value: string | boolean | null | number) => void;
    options: IReactSelectOptionObject[];
    selectPlaceholder?: string;
}

export function AtTableDropdownFilter<T extends IBaseEntity>(props: IAtTableDropdownFilterProps<T>): ReactElement {
    const {
        appliedFilterValue,
        selectPlaceholder = t({ id: "global.selectAnOption" }),
        setFilterValue,
        column,
        options,
        ...otherProps
    } = props;

    const selectedOption = useMemo(() => {
        if (!options || options.length === 0) {
            return null;
        }

        return appliedFilterValue
            ? options.filter((option) => option.value === appliedFilterValue.toString())
            : options[0].value;
    }, [appliedFilterValue, options]);

    const handleChange = (e) => {
        const { value } = e;
        setFilterValue(column.key, value); // Send number for enum value
    };

    return (
        <div className="AtTableDropdownFilter" {...otherProps}>
            <AtSelect
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder={selectPlaceholder}
            />
        </div>
    );
}
