import "./ActionPlanHeaderRow.less";
import * as React from "react";

import { CompetencyDevelopmentActionPlanColumnType, isActionPlanColumnDisplayed } from "../../ResultTableContainer";

import { t } from "@lingui/macro";

type ActionPlanObjectiveType = "leverage" | "improve";

export interface IActionPlanHeaderRowProps {
    columns: CompetencyDevelopmentActionPlanColumnType[];
    type: ActionPlanObjectiveType;
}

export const ActionPlanHeaderRow: React.FC<IActionPlanHeaderRowProps> = (props) => {
    const { columns, type } = props;

    return (
        <div className="ActionPlanHeaderRow">
            {type === "improve"
                ? isActionPlanColumnDisplayed("objective", columns) && (
                      <div className="objective-action-row">
                          {t({ id: "competencyDevelopment.actionPlan.improvementSection.objective" })}
                      </div>
                  )
                : isActionPlanColumnDisplayed("actions", columns) && (
                      <div className="objective-action-row">
                          {t({ id: "competencyDevelopment.actionPlan.improvementSection.actions" })}
                      </div>
                  )}

            {isActionPlanColumnDisplayed("competencies", columns) && (
                <div className="sub-comp-or-type">{t({ id: "competencyDevelopment.global.competencies" })}</div>
            )}
            {type === "improve"
                ? isActionPlanColumnDisplayed("subCompetencies", columns) && (
                      <div className="sub-comp-or-type">
                          {t({ id: "competencyDevelopment.appraisal.contentView.subCompetencies" })}
                      </div>
                  )
                : isActionPlanColumnDisplayed("type", columns) && (
                      <div className="sub-comp-or-type">{t({ id: "global.type" })}</div>
                  )}

            {isActionPlanColumnDisplayed("actionsCount", columns) && (
                <div className="actionsCounters">
                    {t({ id: "competencyDevelopment.actionPlan.improvementSection.actions" })}
                </div>
            )}
            {isActionPlanColumnDisplayed("iconCol", columns) && <div className="iconCol"></div>}
        </div>
    );
};
