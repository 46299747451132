import { BaseApi } from "../../base";
import { HttpMethodEnum } from "../../types";
import { IEmployeePsychometrics } from "./models";
import { ReactAppSettings } from "../../libs";

export interface IEmployeePsychometricsApi {
    getEmployeePsychometrics(): Promise<IEmployeePsychometrics>;
}

export default class EmployeePsychometricsApi extends BaseApi implements IEmployeePsychometricsApi {
    getEmployeePsychometrics(): Promise<IEmployeePsychometrics> {
        return this.sendApiRequest<IEmployeePsychometrics>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getEmployeePsychometrics,
        );
    }
}
