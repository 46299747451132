import { observable } from "mobx";

export interface ISamlInformation {
    ssoEntityId: string;
    loginUrl: string;
    logoutUrl: string;
    userReplyUrl: string;
    candidateReplyUrl: string;
}

export interface ISamlConfiguration {
    providerName: string;
    samlEndpointUrl: string;
    certificate: string;
}

export interface IOrganizationAuthConfig {
    readonly authenticationMethod: AuthenticationMethod;
    readonly ssoStandard?: SsoStandard;
    readonly samlConfiguration?: ISamlConfiguration;
}

export class OrganizationAuthConfig implements IOrganizationAuthConfig {
    @observable public authenticationMethod: AuthenticationMethod;
    @observable public ssoStandard: SsoStandard | undefined;
    @observable public samlConfiguration: ISamlConfiguration | undefined;

    constructor(json: IOrganizationAuthConfig) {
        this.authenticationMethod = json.authenticationMethod;
        this.ssoStandard = json.ssoStandard;
        this.samlConfiguration = json.samlConfiguration;
    }
}

export enum AuthenticationMethod {
    Password,
    Sso,
}

export enum SsoStandard {
    SAML,
}
