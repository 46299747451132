import "./index.less";
import * as React from "react";
import { AtContainer, BaseColors, Ghost, IAtContainerProps, Sizing, StatusColors } from "../../../internal";

export type AtHintColor = StatusColors | BaseColors | Ghost;

export interface IAtHintProps extends Omit<IAtContainerProps, "padding"> {
    color?: AtHintColor;
}

export const AtHint: React.FC<IAtHintProps> = (props) => {
    const { color = "primary", children, className, ...containerProps } = props;

    let classes: string = `AtHint color-${color}`;

    if (className) {
        classes += ` ${className}`;
    }
    return (
        <AtContainer className={classes} padding={Sizing.paddings.sm} {...containerProps}>
            {children}
        </AtContainer>
    );
};
