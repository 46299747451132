import "./index.less";
import * as React from "react";
import { ISelectBaseSharedProps, InnerRefType, OptionTypeBase } from "../../../internal";
import { getSelectStyles } from "../../../shared/selects/getSelectStyles";
import Select, { GroupBase, SelectInstance } from "react-select";
import cn from "classnames";

export interface IAtSelectBaseProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends ISelectBaseSharedProps<TOption, IsMulti, TGroup> {
    options: ReadonlyArray<TOption | TGroup>;

    innerRef?: InnerRefType<SelectInstance<TOption, IsMulti, TGroup>>;
}

/**
 * AtSelectBase - Select Base Component
 * Category: Atom
 *
 * @param props {IAtSelectBaseProps}
 **/
export const AtSelectBase = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtSelectBaseProps<TOption, IsMulti, TGroup>>,
): JSX.Element => {
    const {
        options,
        value,
        onChange,
        formatOptionLabel,
        formatGroupLabel,
        getOptionLabel,
        getOptionValue,
        placeholder,
        additionalStyles,
        components,
        size = "md",
        isMulti,
        isDisabled,
        isClearable,
        isSearchable,
        hideSelectedOptions,
        closeMenuOnSelect,
        innerRef,
        onBlur,
        onFocus,
        className,
        id,
        children: _,
        ...otherProps
    } = props;

    return (
        <Select<TOption, IsMulti, TGroup>
            className={cn("AtSelectBase", className)}
            styles={getSelectStyles<TOption, IsMulti, TGroup>(additionalStyles, size)}
            id={id}
            options={options}
            value={value}
            onChange={onChange}
            formatOptionLabel={formatOptionLabel}
            formatGroupLabel={formatGroupLabel}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            hideSelectedOptions={hideSelectedOptions ?? isMulti ? false : undefined}
            closeMenuOnSelect={closeMenuOnSelect}
            components={components}
            ref={innerRef}
            classNamePrefix="AtSelectBase"
            {...otherProps}
        />
    );
};
