import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppScope } from "@atman/business";
import { ErrorFallback } from "./components/ErrorFallback";

export interface IBaseErrorBoundaryProps {
    scope: AppScope;
    identifier: "top-level" | "app-level" | "route-level" | string;
}

export class SentryErrorBoundary extends React.Component<IBaseErrorBoundaryProps, {}> {
    render() {
        const { children, identifier } = this.props;

        return (
            <Sentry.ErrorBoundary
                fallback={(props) => <ErrorFallback {...props} />}
                beforeCapture={(scope) => {
                    scope.setTag("boundary.identifier", identifier);
                }}
                showDialog={false}
            >
                {children}
            </Sentry.ErrorBoundary>
        );
    }
}

export interface IErrorData {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}
