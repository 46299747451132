import "./CreditsBalanceSelectorLineItem.less";
import * as React from "react";
import {
    AddCreditsCart,
    AppScope,
    AttributeCreditsCart,
    CartStore,
    GlobalStores,
    ModalStore,
    ModalTypes,
    ProductBalanceStore,
    ProductCodeProEnum,
} from "@atman/business";
import { AppCartKey } from "@atman/business/lib/stores/CartStore";
import { AtButton, AtLink } from "@atman/design-system";
import { ButtonGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IReactionDisposer, action, computed, observable, reaction } from "mobx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductInfo } from "../../components/ProductInfo";
import { inject, observer } from "mobx-react";
import autobind from "autobind-decorator";

export interface ICreditsBalanceSelectorLineItemProps {
    productCode: ProductCodeProEnum;
    value: number;
    currentRecipientBalance: number;
    currentBalance: number;
    newBalance: number;
    cartKey: AppCartKey;
    scope: AppScope;
    isUnlimited: boolean;
    modalStore?: ModalStore;
    cartStore?: CartStore;
    productBalanceStore?: ProductBalanceStore;
}

@inject(GlobalStores.modalStore, GlobalStores.cartStore, GlobalStores.productBalanceStore)
@observer
export class CreditsBalanceSelectorLineItem extends React.Component<ICreditsBalanceSelectorLineItemProps> {
    @observable public customPackageIsSelected: boolean = false;
    @observable public customPackageValue: string = "";

    customPackageValueReaction: IReactionDisposer = reaction(
        () => this.customPackageValue,
        (value) => this.updateCart(Number(value)),
    );

    @computed
    get cart() {
        return this.props.cartStore!.appCarts["addCredits"] as AddCreditsCart;
    }

    private updateCart(quantity: number) {
        const cart = this.props.cartStore!.appCarts[this.props.cartKey] as AddCreditsCart | AttributeCreditsCart;

        this.props.cartStore!.setCart(this.props.cartKey, {
            ...cart,
            balance: {
                ...cart.balance,
                [this.props.productCode]: quantity,
            },
        } as any);
    }

    @autobind
    goToAddProduct() {
        this.props.modalStore!.toggleModal();

        window.setTimeout(() => {
            this.props.modalStore!.openModal(ModalTypes.AddCredits); // TODO: Add prefill data for product
        }, 300);
    }

    @action.bound
    handleLineItemClick(quantity: number) {
        this.customPackageIsSelected = false;

        // if (this.cart.selectedPackageSku) {
        //     this.props.cartStore!.setCart("addCredits", {
        //         ...this.cart,
        //         balance: {}
        //     })
        //
        //
        // }

        this.updateCart(quantity);
    }

    @action.bound
    onCustomPackageButtonClick = () => {
        this.customPackageIsSelected = true;
        this.updateCart(Number(this.customPackageValue));
    };

    @action.bound
    onCustomPackageValueChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.customPackageValue = (event.target as any).value;
    };

    render() {
        const {
            productCode,
            value,
            newBalance,
            currentBalance,
            currentRecipientBalance,
            scope,
            cartKey,
            isUnlimited,
            productBalanceStore,
        } = this.props;

        const packages = [5, 25, 50, 100];

        const packageButtons = packages.map((x, i) => (
            <AtButton
                key={i}
                color={"secondary"}
                active={!this.customPackageIsSelected && value === x}
                onClick={() => this.handleLineItemClick(value !== x ? x : 0)}
            >
                {x}
            </AtButton>
        ));

        return (
            <div className="CreditsBalanceSelectorLineItem">
                <div className="product-info-section">
                    <ProductInfo productCode={productCode} />
                </div>
                <div className="quantity-selection-section">
                    <ButtonGroup>{packageButtons}</ButtonGroup>
                    <AtButton
                        color={"secondary"}
                        size={"md"}
                        className={"custom-quantity-btn"}
                        onClick={this.onCustomPackageButtonClick}
                    >
                        {this.customPackageIsSelected ? (
                            <Input
                                type={"text"}
                                className={"btn-input"}
                                value={this.customPackageValue}
                                onChange={this.onCustomPackageValueChange}
                                autoFocus
                                maxLength={3}
                            />
                        ) : "global.custom".localize().length > 6 ? (
                            `${"global.custom".localize().slice(0, 5)}.`
                        ) : (
                            "global.custom".localize()
                        )}
                    </AtButton>
                </div>
                {cartKey === "attributeCredits" ? (
                    <div className="recipient-balance-section">{currentRecipientBalance}</div>
                ) : null}
                {scope !== AppScope.Supplier && !productBalanceStore!.isUnlimited ? (
                    <div className="current-balance-section">
                        <div className="balance">{currentBalance}</div>
                        {cartKey === "attributeCredits" && !productBalanceStore!.isUnlimited ? (
                            <AtLink
                                size={"xs"}
                                color={"secondary"}
                                className="addProductLink"
                                onClick={this.goToAddProduct}
                            >
                                {"global.addProducts".localize()}
                            </AtLink>
                        ) : null}
                    </div>
                ) : null}
                {cartKey === "addCredits" ? (
                    <div className="new-balance-section">
                        {isUnlimited ? <FontAwesomeIcon icon={["far", "infinity"] as IconProp} /> : newBalance}
                    </div>
                ) : null}
            </div>
        );
    }
}
