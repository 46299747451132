import "./index.less";
import * as React from "react";
import { BaseResponsiveComponent, Default } from "../BaseResponsiveComponent";
import { ILikertKeyValueObject, ILocalizedLikertStatement } from "@atman/business";
import { LikertLegend } from "./components/LikertLegend";
import { LikertScales } from "./components/LikertScales";
import { LikertStatement } from "./components/LikertStatement";
import { StepHeader } from "./components/StepHeader";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";

export interface ILikertEvaluationProps {
    stepNumber: number;
    title: string;
    description: string;
    elements: ILocalizedLikertStatement[];
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    answers: ILikertKeyValueObject[];
    userLanguage: string;
    className?: string;
    disabled?: boolean;
}

@inject()
@observer
export class LikertEvaluation extends BaseResponsiveComponent<ILikertEvaluationProps> {
    @computed protected get rootElementClassName(): string | undefined {
        return this.props.className ?? "";
    }

    renderAll() {
        const {
            title,
            description,
            stepNumber,
            elements,
            onChange,
            answers,
            userLanguage,
            disabled = false,
        } = this.props;

        return (
            <>
                <StepHeader stepNumber={stepNumber} title={title} description={description} />
                <Default>
                    <LikertLegend languageCode={userLanguage} />
                </Default>
                <div className="likert-group">
                    {elements.map((x, i) => (
                        <LikertStatement
                            key={i}
                            index={i}
                            element={x}
                            onChange={onChange}
                            answers={answers}
                            userLanguage={userLanguage}
                            disabled={disabled}
                        />
                    ))}
                </div>
            </>
        );
    }
}

export { LikertLegend, LikertStatement, LikertScales, StepHeader };
