import { AppScope, Role } from "../types";
import {
    CartStore,
    ClientStore,
    LocalizationStore,
    ModalStore,
    PartnerStore,
    ProductBalanceStore,
    ScopedOrganizationStore,
    TransactionStore,
    UiStore,
    UserInfoStore,
    UserStore,
    WorkspaceStore,
} from "../stores";
import { ReactAppSettings } from "../libs";
import { RoleMatchType } from "../kernel";
import { configureDevtool } from "mobx-react-devtools";
import autobind from "autobind-decorator";

export abstract class BaseRootStore {
    public readonly scope: AppScope;
    public readonly localizationStore: LocalizationStore;
    public readonly uiStore: UiStore;

    protected constructor(scope: AppScope) {
        this.scope = scope;
        this.localizationStore = new LocalizationStore(scope);
        this.uiStore = new UiStore(this);
    }
}

export abstract class BasePlatformRootStore extends BaseRootStore {
    protected availableApiCallsDetails: IAvailableApiCallsDetails[];

    public readonly userInfoStore: UserInfoStore;
    public readonly productBalanceStore: ProductBalanceStore;
    public readonly transactionStore: TransactionStore;
    public readonly cartStore: CartStore;
    public readonly userStore: UserStore;
    public readonly modalStore: ModalStore;
    public readonly scopedOrganizationStore: ScopedOrganizationStore;
    // TODO: Move to PartnerRootStore
    public readonly clientStore: ClientStore;
    // TODO: Move to SupplierRootStore
    public readonly partnerStore: PartnerStore;
    public readonly workspaceStore: WorkspaceStore;

    protected constructor(scope: AppScope) {
        super(scope);

        this.userInfoStore = new UserInfoStore(this, this.jwtTokenHandleMethod);
        this.productBalanceStore = new ProductBalanceStore(this);
        this.transactionStore = new TransactionStore(this);
        this.cartStore = new CartStore(this);
        this.userStore = new UserStore(this);
        this.modalStore = new ModalStore(this);
        this.scopedOrganizationStore = new ScopedOrganizationStore(this);
        // TODO: Move to PartnerRootStore
        this.clientStore = new ClientStore(this);
        // TODO: Move to SupplierRootStore
        this.partnerStore = new PartnerStore(this);
        this.workspaceStore = new WorkspaceStore(this);

        this._setupApp();
    }

    protected abstract setupApp(): void;

    @autobind
    protected async jwtTokenHandleMethod(jwt: string): Promise<void> {
        const filteredApiCalls = this.availableApiCallsDetails
            .filter((x) => {
                const rolesMatchResult = this.userInfoStore.matchRoles(x.requiredRoles, x.requiredRolesMatchType);

                if (!x.evaluateAdditionalConditions) {
                    return rolesMatchResult;
                }

                return rolesMatchResult && x.evaluateAdditionalConditions();
            })
            .reduce((p, v) => [...p, ...v.calls], []);

        Promise.all(filteredApiCalls.map((x) => x()));
    }

    private _setupApp() {
        // MARK: Local Development Setup
        if (ReactAppSettings.viewBag.isInDevEnvironment) {
            configureDevtool({
                logEnabled: false,
                updatesEnabled: false,
                graphEnabled: false,
            });

            // enableLogging();
        }

        this.setupApp();
    }
}

export interface IAvailableApiCallsDetails {
    calls: Array<() => Promise<any>>;
    requiredRoles: Role[];
    requiredRolesMatchType?: RoleMatchType;
    evaluateAdditionalConditions?: () => boolean;
}
