export function stringEquals(sourceString: string, stringToCompare: string, ignoreCase: boolean = false) {
    if (!ignoreCase) {
        return sourceString === stringToCompare;
    }

    return sourceString.toLowerCase() === stringToCompare.toLowerCase();
}

export function stringContains(sourceString: string, stringToCompare: string, ignoreCase: boolean = false) {
    if (!ignoreCase) {
        return sourceString.includes(stringToCompare);
    }

    return sourceString.toLowerCase().includes(stringToCompare.toLowerCase());
}
