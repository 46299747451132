import "./ProductUsageDashboard.less";
import { AtTabCard, AtTitle } from "@atman/design-system";
import { Colors } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";
import { ProductCodeProEnum } from "@atman/business";
import { ProductInfo } from "../../../components/ProductInfo";
import { VictoryPie } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IProductUsageDashboardProps {
    data?: Dictionary<ProductCodeProEnum, number>;
    filterDaysCount: number;
}

@inject()
@observer
export class ProductUsageDashboard extends React.Component<IProductUsageDashboardProps, {}> {
    private getFillColor = (productCode: ProductCodeProEnum) => {
        switch (productCode) {
            case ProductCodeProEnum.Integral:
                return Colors.atmanOrange;
            case ProductCodeProEnum.CognitiveTest:
                return Colors.cognitiveAssessmentColor;
            case ProductCodeProEnum.PersonalityTest:
                return Colors.personalityAssessmentColor;
            case ProductCodeProEnum.PreferencesTest:
                return Colors.preferenceAssessmentColor;
            case ProductCodeProEnum.TripleBottomLineTest:
                return Colors.tripleBottomLineAssessmentColor;
            default:
                return Colors.atmanOrange;
        }
    };

    render() {
        const { data } = this.props;

        if (!data) {
            return (
                <AtTabCard id={"ProductUsageDashboard"} cardTitle={"global.overall".localize()}>
                    <FontAwesomeIcon icon={["far", "spinner"] as IconProp} spin />
                </AtTabCard>
            );
        }

        const mappedData = Object.keys(data)
            .filter((x) => !isNaN(Number(x)))
            .map(
                (x) =>
                    ({
                        productCode: Number(x),
                        quantity: data[x],
                    } as IProductUsageData),
            );

        return (
            <AtTabCard id="ProductUsageDashboard" cardTitle={"global.overall".localize()}>
                <div className="graph-container">
                    <div className="total">
                        <Label>{"global.total".localize()}</Label>
                        <AtTitle headingType={1} title={mappedData.sum((x) => x.quantity)} />
                    </div>
                    <VictoryPie
                        padAngle={2.5}
                        data={mappedData}
                        innerRadius={100}
                        height={275}
                        width={275}
                        labels={() => ""}
                        style={{
                            data: {
                                fill: ({ datum: { productCode } }) => this.getFillColor(productCode),
                            },
                        }}
                        x={(x: IProductUsageData) => x.productCode}
                        y={(x: IProductUsageData) => x.quantity}
                    />
                </div>
                <div className="products-list">
                    {mappedData.map((x, i) => (
                        <div className={"product-entry"} key={i}>
                            <ProductInfo productCode={x.productCode} />
                            <div className="usage-count">{x.quantity}</div>
                        </div>
                    ))}
                </div>
            </AtTabCard>
        );
    }
}

export interface IProductUsageData {
    productCode: ProductCodeProEnum;
    quantity: number;
}
