import "./index.less";
import { AtText } from "../../../../atoms/AtText";
import React, { useMemo } from "react";
import cn from "classnames";
export interface ITableItemListProps<T> {
    contents: T[];
    className?: string;
}

function TableItemList<T>(props: ITableItemListProps<T>): JSX.Element {
    const { contents, className } = props;

    const listContent = useMemo(() => {
        if (contents.length < 3) {
            return contents.map((content, index) => (
                <AtText key={index} className="truncatedList">
                    {content}
                </AtText>
            ));
        }

        if (contents.length > 2) {
            return (
                <>
                    <AtText className="truncatedList">{contents[0]}</AtText>
                    <AtText className="truncatedList">{contents[1]}</AtText>
                    <AtText className="truncatedList">{`+ ${contents.length - 2} `}</AtText>
                </>
            );
        }

        return null;
    }, [contents]);

    return <div className={cn("TableItemList", className)}>{listContent}</div>;
}

export { TableItemList };
