import {
    CompDevCompetencyResult,
    CompDevLearningResource,
    CompDevSubCompetencyResult,
    SelectedSubCompetencyToImproveData,
} from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../logic";
import { DevelopmentPlanStrategicObjectives } from "./DevelopmentPlanStrategicObjectives";
import { ObjectiveSubRow } from "../components";
import { observer } from "mobx-react";
import React from "react";

export interface IDevelopmentPlanImprovementSubRowProps {
    comp: CompDevCompetencyResult;
    group: CompDevSubCompetencyResult;
    selectedObjectivesData: SelectedSubCompetencyToImproveData | undefined;
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    saveStrategicObjectiveSelectionData: (
        elementId: string,
        subElementId: string,
        objectiveId: string,
        selectedApplicationIds: string[],
        applicationNote: string,
    ) => void;
    toggleLearningResourceSelection: (elementId: string, subElementId: string, learningResourceId: string) => void;
    setLearningResourceNote: (elementId: string, subElementId: string, value: string) => void;
    erroredElements?: Map<string, string[]>;
}

export const DevelopmentPlanImprovementSubRow: React.FC<IDevelopmentPlanImprovementSubRowProps> = observer((props) => {
    const {
        comp,
        group,
        selectedObjectivesData,
        erroredElements = new Map(),
        columns,
        managerInfo,
        employeeInfo,
        hiddenColumns,
        saveStrategicObjectiveSelectionData,
        toggleLearningResourceSelection,
        setLearningResourceNote,
    } = props;

    const learningResources: CompDevLearningResource[] = [
        ...(group.learningResources ?? []),
        ...(comp.learningResources ?? []),
    ];

    const onStrategicObjectiveSelectionDataSave = (
        objectiveId: string,
        selectedApplicationIds: string[],
        applicationNote: string,
    ) => {
        saveStrategicObjectiveSelectionData(comp.id, group.id, objectiveId, selectedApplicationIds, applicationNote);
    };

    const onLearningResourceChange = (resourceId) => {
        toggleLearningResourceSelection(comp.id, group.id, resourceId);
    };

    const onLearningResourceNoteChange = (value) => {
        setLearningResourceNote(comp.id, group.id, value);
    };

    return (
        <ObjectiveSubRow
            key={group.id}
            objective={group}
            inError={erroredElements.get(comp.id)?.includes(group.id)}
            isSelected={!!selectedObjectivesData}
            disabledSelect
            managerInfo={managerInfo}
            employeeInfo={employeeInfo}
            hiddenColumns={hiddenColumns}
            columns={columns}
            showCheckIcon
        >
            <DevelopmentPlanStrategicObjectives
                strategicObjectives={group.strategicObjectives}
                selectedObjectivesData={selectedObjectivesData?.selectedObjectivesToImprove}
                onStrategicObjectiveSelectionDataSave={onStrategicObjectiveSelectionDataSave}
                learningResources={learningResources}
                parentId={group.id}
                selectedLearningResourceIds={selectedObjectivesData?.selectedLearningResourceIds}
                learningResourceNotes={selectedObjectivesData?.learningResourceNotes}
                onLearningResourceChange={onLearningResourceChange}
                onLearningResourceNotesChange={onLearningResourceNoteChange}
                competency={comp}
                subCompetency={group}
            />

            {/* <ApplicationsLearningResourcesContainer
                title={group.strategicObjectives[0].description}
                context={"improve"}
                parentId={group.id}
                applications={group.applications}
                learningResources={learningResources}
                selectedApplicationIds={selectedObjectivesData?.selectedApplicationIds}
                selectedLearningResourceIds={selectedObjectivesData?.selectedLearningResourceIds}
                applicationNotes={selectedObjectivesData?.applicationNotes}
                learningResourceNotes={selectedObjectivesData?.learningResourceNotes}
                onApplicationChange={onApplicationChange}
                onLearningResourceChange={onLearningResourceChange}
                onApplicationNotesChange={onApplicationNoteChange}
                onLearningResourceNotesChange={onLearningResourceNoteChange}
            /> */}
        </ObjectiveSubRow>
    );
});
