import "./index.less";
import { AtContainer, Sizing } from "@atman/design-system";
import {
    AtmanAssessmentDocumentStructure,
    CompetencyGroupEnum,
    DimensionGroupStructure,
    DistributionData,
} from "@atman/business";
import { DimensionGroupScore } from "./components/DimensionGroupScore";
import React from "react";

export interface IDimensionGroupScoreSummaryProps {
    assessmentDocuments: AtmanAssessmentDocumentStructure[];
    scores?: Dictionary<string, number> | undefined; // Scores for a single candidate
    distributionData?: DistributionData; // Scores for team distribution (currently unused)
}

export const DimensionGroupScoreSummary: React.FC<IDimensionGroupScoreSummaryProps> = (props) => {
    const { assessmentDocuments, scores, distributionData } = props;

    const filterGroups = (groups: DimensionGroupStructure[]) => {
        return groups.filter((g) => g.enumName !== CompetencyGroupEnum[CompetencyGroupEnum.Interests]); // Exclude these because they are not competencies.
    };

    return (
        <div className="DimensionGroupScoreSummary">
            {assessmentDocuments.map((d) => {
                const groups = filterGroups(d.groups);

                return groups.map((g) => {
                    const dimensionNames = g.dimensions.map((x) => x.enumName);

                    return (
                        <AtContainer key={`${d.title}-${g.enumName}`} padding={[Sizing.pixels.md, Sizing.pixels.sm]}>
                            <DimensionGroupScore
                                title={g.name ? g.name : d.title}
                                dimensionNames={dimensionNames}
                                distributionData={distributionData}
                                scores={scores}
                            />
                        </AtContainer>
                    );
                });
            })}
        </div>
    );
};
