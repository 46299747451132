import "./index.less";
import * as React from "react";
import { AtPersonAvatar, AtTitle, InitialsAvatar } from "@atman/design-system";
import { CompetencyDevelopmentColumnType, IPersonInfo, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IManagerNoteTableHeaderColProps {
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
    managerInfo: IPersonInfo;
    hideAvatar?: boolean;
}

export const ManagerNoteTableHeaderCol: React.FC<IManagerNoteTableHeaderColProps> = observer((props) => {
    const { managerInfo, sortElement, sortDirection, onSortChange, hideAvatar } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("managerScore", sortElement, sortDirection, onSortChange);

    return (
        <TableHeader
            className="manager-note-col"
            {...selectionProps}
            tooltipContent={
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <InitialsAvatar text={managerInfo.displayName} size="md" className="square" />
                    <AtTitle headingType={5} title={managerInfo.displayName} />
                    <div className="textSizeSmall">{managerInfo.email}</div>
                </div>
            }
        >
            {hideAvatar ? (
                <AtTitle headingType={6} title={t({ id: "competencyDevelopment.global.evaluator" })} />
            ) : (
                <AtPersonAvatar
                    size={"md"}
                    firstName={managerInfo.firstName}
                    lastName={managerInfo.lastName}
                    email={managerInfo.email}
                />
            )}
        </TableHeader>
    );
});
