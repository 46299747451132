import { ApplicationsLearningResourcesContainer } from "../../../../ApplicationsLearningResourcesContainer";
import {
    CompDevCompetencyResult,
    CompDevLearningResource,
    CompDevStrategicObjectiveResult,
    ISelectedObjectiveData,
} from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../logic";
import { ObjectiveSubRow } from "../components";
import { observer } from "mobx-react";
import React from "react";

export interface IDevelopmentPlanLeverageSubRowProps {
    comp: CompDevCompetencyResult;
    objective: CompDevStrategicObjectiveResult;
    selectedObjectivesData: ISelectedObjectiveData | undefined;
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    toggleSubElementSelection: (elementId: string, subElementId: string) => void;
    toggleApplicationSelection: (elementId: string, subElementId: string, applicationId: string) => void;
    toggleLearningResourceSelection: (elementId: string, subElementId: string, learningResourceId: string) => void;
    setApplicationNote: (elementId: string, subElementId: string, value: string) => void;
    setLearningResourceNote: (elementId: string, subElementId: string, value: string) => void;
    erroredElements?: Map<string, string[]>;
}

export const DevelopmentPlanLeverageSubRow: React.FC<IDevelopmentPlanLeverageSubRowProps> = observer((props) => {
    const {
        comp,
        objective,
        selectedObjectivesData,
        erroredElements = new Map(),
        columns,
        managerInfo,
        employeeInfo,
        hiddenColumns,
        toggleSubElementSelection,
        toggleApplicationSelection,
        toggleLearningResourceSelection,
        setApplicationNote,
        setLearningResourceNote,
    } = props;

    const learningResources: CompDevLearningResource[] = [
        ...objective.learningResources,
        ...(comp.learningResources ?? []),
    ];

    const onSelect = () => {
        toggleSubElementSelection(comp.id, objective.id);
    };

    const onApplicationChange = (resourceId) => {
        toggleApplicationSelection(comp.id, objective.id, resourceId);
    };

    const onLearningResourceChange = (resourceId) => {
        toggleLearningResourceSelection(comp.id, objective.id, resourceId);
    };

    const onApplicationNoteChange = (value) => {
        setApplicationNote(comp.id, objective.id, value);
    };

    const onLearningResourceNoteChange = (value) => {
        setLearningResourceNote(comp.id, objective.id, value);
    };

    return (
        <ObjectiveSubRow
            key={objective.id}
            objective={objective}
            inError={erroredElements.get(comp.id)?.includes(objective.id)}
            isSelected={!!selectedObjectivesData}
            // disabledSelect={!isSubElementSelectable(comp.id, objective.id)}
            onSelect={onSelect}
            managerInfo={managerInfo}
            employeeInfo={employeeInfo}
            hiddenColumns={hiddenColumns}
            columns={columns}
        >
            <ApplicationsLearningResourcesContainer
                title={objective.description}
                context={"leverage"}
                parentId={objective.id}
                applications={objective.applications}
                learningResources={learningResources}
                selectedApplicationIds={selectedObjectivesData?.selectedApplicationIds}
                selectedLearningResourceIds={selectedObjectivesData?.selectedLearningResourceIds}
                applicationNotes={selectedObjectivesData?.applicationNotes}
                learningResourceNotes={selectedObjectivesData?.learningResourceNotes}
                onApplicationChange={onApplicationChange}
                onLearningResourceChange={onLearningResourceChange}
                onApplicationNotesChange={onApplicationNoteChange}
                onLearningResourceNotesChange={onLearningResourceNoteChange}
            />
        </ObjectiveSubRow>
    );
});
