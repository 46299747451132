import {
    AppScope,
    CurrencyEnum,
    IBillingAddress,
    IDiscountRate,
    ISummaryProductItem,
    ITax,
    IWindow,
    ProductCodeProEnum,
} from "../types";
import { BaseCart } from "../models";
import { IProductSkuDetails } from "../appLogic/ProductDefinition";
import { ProductBalanceStore } from "../stores/ProductBalanceStore";
import { ReactAppSettings } from "./ReactAppSettings";
import { t } from "@lingui/macro";

export interface ITaxRowContent {
    taxName: string;
    taxValue: number;
}

export interface IDiscountRowContent {
    discountRowName: string;
    discountPercentage: number;
    discountTotalValue: number;
}

export interface IProductPriceCalculationService {
    getPackageProductAndVolumeDiscountRate: (sku: string) => {
        discountRate: number;
        skuDetails: IProductSkuDetails;
    };
    getEffectiveTax: () => ITax;
    getTaxRows: (subTotal: number) => ITaxRowContent[];
    getDiscountRateRow: (subTotal: number) => IDiscountRowContent;
    getPackageSummaryItem: (cart: BaseCart, currency: CurrencyEnum) => ISummaryProductItem;
    getCustomSummaryItems: (cart: BaseCart, currency: CurrencyEnum) => ISummaryProductItem[];
    getCustomProductVolumeDiscountRate: (
        cart: BaseCart,
        currency: CurrencyEnum,
        productCode: ProductCodeProEnum,
    ) => number;
    getSubtotal: (summaryItems: ISummaryProductItem[]) => number;
    getTaxTotal: (subTotal: number) => number;
}

export class ProductPriceCalculationService implements IProductPriceCalculationService {
    public readonly customId: string = "custom";
    protected readonly scope: AppScope;
    protected readonly productBalanceStore: ProductBalanceStore;
    protected readonly address: IBillingAddress;

    constructor(productBalanceStore: ProductBalanceStore, scope: AppScope, address: IBillingAddress) {
        this.productBalanceStore = productBalanceStore;
        this.scope = scope;
        this.address = address;
    }

    public getPackageProductAndVolumeDiscountRate(sku: string): {
        discountRate: number;
        skuDetails: IProductSkuDetails;
    } {
        const productDefinitionForSku = this.productBalanceStore!.availableProductDefinitions.find((x) =>
            x.skus.some((s) => s.sku === sku),
        );
        const skuForPackage = productDefinitionForSku?.skus.find((x) => x.sku === sku);

        if (!skuForPackage || !productDefinitionForSku) {
            throw new Error(`Couldn't find product matching sku: ${sku}`);
        }

        const discountRates = ReactAppSettings.appModel.effectivePartnerDiscountRates as IDiscountRate[];

        let discountRatePercentage = 0;

        if (this.scope === AppScope.Partner) {
            const discountRateForPackage = discountRates.find(
                (discountRate) =>
                    skuForPackage.quantity >= discountRate.min && skuForPackage.quantity <= discountRate.max,
            );

            discountRatePercentage = discountRateForPackage ? discountRateForPackage.value : 0;
        }

        return {
            discountRate: discountRatePercentage,
            skuDetails: skuForPackage,
        };
    }

    public getCustomProductVolumeDiscountRate(cart: BaseCart, currency: CurrencyEnum, productCode: ProductCodeProEnum) {
        const discountRates = ReactAppSettings.appModel.effectivePartnerDiscountRates as IDiscountRate[];
        const balanceForProduct = cart.balance[productCode] as number;

        let volumeDiscountRatePercentage = 0;

        if (this.scope === AppScope.Partner) {
            const matchingDiscountRate = discountRates.find(
                (discountRate) => balanceForProduct >= discountRate.min && balanceForProduct <= discountRate.max,
            );

            volumeDiscountRatePercentage = matchingDiscountRate ? matchingDiscountRate.value : 0;
        }

        return volumeDiscountRatePercentage;
    }

    public getEffectiveTax() {
        let effectiveTax: ITax;

        if (this.address.country !== "CA") {
            effectiveTax = (window as unknown as IWindow).reactAppSettings.appModel.effectiveCanadaTaxes[
                "outsideCanada"
            ] as ITax;
        } else {
            effectiveTax = (window as unknown as IWindow).reactAppSettings.appModel.effectiveCanadaTaxes[
                this.address.state.toLowerCase()
            ] as ITax;
        }

        return effectiveTax;
    }

    public getDiscountRateRow(subTotal: number): IDiscountRowContent {
        return {
            discountRowName: t({ id: "global.customDiscount.label", message: "Personalized Discount Rate" }),
            discountPercentage: this.productBalanceStore.discountRate,
            discountTotalValue: (subTotal * this.productBalanceStore.discountRate) / 100,
        };
    }

    public getTaxRows(subTotal: number): ITaxRowContent[] {
        const effectiveTax = this.getEffectiveTax();

        return Object.keys(effectiveTax)
            .filter((x) => !x.includes("Ref"))
            .filter((x) => (effectiveTax[x] as number) > 0)
            .map((x, i) => ({
                taxName: `${"global.tax".localize()} (${`global.tax.labels.${x}.${this.address!.state.toLowerCase()}`.localize()})`,
                taxValue: (effectiveTax[x] as number) * subTotal,
            }));
    }

    public getPackageSummaryItem(cart: BaseCart, currency: CurrencyEnum) {
        const { skuDetails, discountRate } = this.getPackageProductAndVolumeDiscountRate(cart.selectedPackageSku!);

        return {
            label: skuDetails.name,
            quantity: skuDetails.quantity,
            price: skuDetails.prices.find((x) => x.currency === currency)!.value,
            productCode: skuDetails.productCode,
            discountRate: discountRate,
            isCustomPackage: false,
        };
    }

    public getCustomSummaryItems(cart: BaseCart, currency: CurrencyEnum) {
        const summaryItems: ISummaryProductItem[] = [];

        Object.keys(cart.balance)
            .filter((x) => !isNaN(Number(x)))
            .forEach((x) => {
                if (cart.balance[Number(x)] === 0) {
                    return;
                }

                const unitPrice = this.productBalanceStore!.getUnitPriceForCustomPackage(
                    Number(x),
                    cart.balance[Number(x)],
                    currency,
                );
                const volumeDiscountRate = this.getCustomProductVolumeDiscountRate(cart, currency, Number(x));

                summaryItems.push({
                    label: `${`global.productCodes.${ProductCodeProEnum[
                        Number(x)
                    ].toCamel()}`.localize()} - ${"global.customBundle".localize()}`,
                    quantity: cart.balance[Number(x)],
                    price: unitPrice * cart.balance[Number(x)],
                    productCode: Number(x),
                    discountRate: volumeDiscountRate,
                    isCustomPackage: true,
                });
            });

        return summaryItems;
    }

    public getSubtotal(summaryItems: ISummaryProductItem[]) {
        return summaryItems.sum((x) => x.price * ((100 - (x.discountRate ? x.discountRate : 0)) / 100));
    }

    public getTaxTotal(subTotal: number) {
        const effectiveTax = this.getEffectiveTax();
        return effectiveTax.gst * subTotal + effectiveTax.pst * subTotal + effectiveTax.hst * subTotal;
    }
}
