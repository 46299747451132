import "./index.less";
import React from "react";
import cn from "classnames";

export interface IMaxNumberCounterProps {
    currentNumber: number;
    maxNumber: number;
    noRedFlag?: boolean;
}

export const MaxNumberCounter: React.FC<IMaxNumberCounterProps> = (props) => {
    const { currentNumber, maxNumber, noRedFlag, ...otherProps } = props;

    return (
        <div className={cn("MaxNumberCounter", { maximum: currentNumber >= maxNumber && !noRedFlag })} {...otherProps}>
            {`${currentNumber}/${maxNumber}`}
        </div>
    );
};
