import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { ITagInput, Tag } from "../models";
import { ReactAppSettings } from "../libs";

export class TagsApi extends BaseApi {
    static async getTags() {
        return await this.sendApiRequest<Tag[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getTags);
    }

    static async getTag(id: string) {
        return await this.sendApiRequest<Tag>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getTag, undefined, id);
    }

    static async createTag(input: ITagInput) {
        return await this.sendApiRequestWithResult<ITagInput, Tag>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createTag,
            input,
        );
    }

    static async updateTag(id: string, input: ITagInput) {
        return await this.sendApiRequestWithResult<ITagInput, Tag>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateTag,
            input,
            id,
        );
    }

    static async deleteTag(id: string) {
        return await this.sendApiRequest(HttpMethodEnum.DELETE, ReactAppSettings.appUrls.deleteTag, undefined, id);
    }

    static async applyTagsToCandidates(tagIds: string[], candidateIds: string[]) {
        return await this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.applyTagToCandidates, {
            candidateIds,
            tagIds,
        });
    }

    static async removeTagsFromCandidates(tagIds: string[], candidateIds: string[]) {
        return await this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.removeTagFromCandidates, {
            candidateIds,
            tagIds,
        });
    }
}
