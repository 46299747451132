import "./index.less";
import * as React from "react";
import { AtHeader } from "../AtHeader";
import { AtSidebar } from "../AtSidebar";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { GlobalStores, ISidebarSectionItem, Role, UiStore } from "@atman/business";
import { IAtButtonProps } from "@atman/design-system";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import cn from "classnames";

export interface IAtLayoutProps {
    mainSidebarNavItems: ISidebarSectionItem[];
    isJwtAuthenticated: boolean;
    modalContainer?: React.ReactNode;
    breadcrumbComponent?: React.ReactNode;
    allowedRolesForSettingsLink?: Role[];
    headerButtonProps?: IAtButtonProps[];
    headerAdditionalHelpDropdownItems?: React.ReactNode[];
    showSearch?: boolean;
    showChatButton?: boolean;
    showAccountRole?: boolean;
    useAuthenticationForHelpCenter?: boolean;
    uiStore?: UiStore;
}

@inject(GlobalStores.uiStore)
@observer
export class AtLayout extends BaseResponsiveComponent<IAtLayoutProps> {
    protected get rootElementId(): string | undefined {
        return "AtLayout";
    }

    protected readonly sidebarContainerClassName = "sidebar-container";
    @observable public isSidebarOpen: boolean = false;

    @action.bound
    toggleSidebar = () => {
        this.isSidebarOpen = !this.isSidebarOpen;
    };

    onSidebarClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        const className: string = (event.target as any).className;

        if (className.includes(this.sidebarContainerClassName)) {
            this.toggleSidebar();
        }
    };

    protected renderDefault() {
        const {
            modalContainer,
            mainSidebarNavItems,
            allowedRolesForSettingsLink,
            isJwtAuthenticated,
            headerButtonProps,
            headerAdditionalHelpDropdownItems,
            children,
            breadcrumbComponent,
            showSearch,
            showChatButton,
            uiStore,
            showAccountRole,
            useAuthenticationForHelpCenter,
        } = this.props;

        return (
            <>
                {uiStore!.showSidebar && !uiStore!.isFullscreen && (
                    <div className={this.sidebarContainerClassName}>
                        <AtSidebar
                            mainNavItems={mainSidebarNavItems}
                            allowedRolesForSettings={allowedRolesForSettingsLink}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </div>
                )}
                <div className="app-container">
                    {uiStore!.showHeader && !uiStore!.isFullscreen && (
                        <AtHeader
                            breadcrumbComponent={breadcrumbComponent}
                            buttonsProps={headerButtonProps}
                            additionalHelpDropdownItems={headerAdditionalHelpDropdownItems}
                            showSearch={showSearch}
                            showChatButton={showChatButton}
                            showAccountRole={showAccountRole}
                            useAuthenticationForHelpCenter={useAuthenticationForHelpCenter}
                        />
                    )}
                    {modalContainer}

                    <div id="app-content" className={cn({ "hidden-header": !uiStore!.showHeader })}>
                        {isJwtAuthenticated ? children : null}
                    </div>
                    {/*{*/}
                    {/*    ReactAppSettings.viewBag.isInDevEnvironment*/}
                    {/*        ? <DevTools position={{ bottom: 0, left: 20 }} />*/}
                    {/*        : null*/}
                    {/*}*/}
                </div>
                {/*{ ToastProvider.renderContainer("APP") }*/}
            </>
        );
    }

    protected renderMobile() {
        const {
            modalContainer,
            mainSidebarNavItems,
            allowedRolesForSettingsLink,
            children,
            isJwtAuthenticated,
            headerButtonProps,
            breadcrumbComponent,
            showSearch,
            showChatButton,
            uiStore,
            showAccountRole,
            useAuthenticationForHelpCenter,
        } = this.props;

        return (
            <>
                <div className="app-container">
                    {uiStore!.showHeader && (
                        <AtHeader
                            onMenuButtonClick={this.toggleSidebar}
                            buttonsProps={headerButtonProps}
                            breadcrumbComponent={breadcrumbComponent}
                            showSearch={showSearch}
                            showChatButton={showChatButton}
                            showAccountRole={showAccountRole}
                            useAuthenticationForHelpCenter={useAuthenticationForHelpCenter}
                        />
                    )}
                    <div
                        className={cn(this.sidebarContainerClassName, {
                            "sidebar-container-open": this.isSidebarOpen,
                            "sidebar-container-close": !this.isSidebarOpen,
                        })}
                        onClick={this.onSidebarClick}
                    >
                        <AtSidebar
                            mainNavItems={mainSidebarNavItems}
                            allowedRolesForSettings={allowedRolesForSettingsLink}
                            toggleSidebar={this.toggleSidebar}
                            sidebarIsOpen={this.isSidebarOpen}
                        />
                    </div>
                    {modalContainer}
                    <div id="app-content" className={`${!uiStore!.showHeader ? "hidden-header" : ""}`}>
                        {isJwtAuthenticated ? children : null}
                    </div>
                    {/*{*/}
                    {/*    ReactAppSettings.viewBag.isInDevEnvironment*/}
                    {/*        ? <DevTools position={{ bottom: 0, left: 20 }} />*/}
                    {/*        : null*/}
                    {/*}*/}
                </div>
                {/*{ ToastProvider.renderContainer("APP") }*/}
            </>
        );
    }
}
