import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { ReactAppSettings } from "../libs";

export class CompetencyDevelopmentCampaignsApi extends BaseApi {
    public static startCampaign(teamId: string): Promise<{ campaignId: string }> {
        const data = {
            teamId,
        };

        return this.sendApiRequestWithResult<typeof data, { campaignId: string }>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.startCompetencyDevelopmentCampaign,
            data,
        );
    }
}
