import "./ActionPlanTablesRowContentObjective.less";
import * as React from "react";
import { Alert } from "reactstrap";
import {
    AtButton,
    AtContainer,
    AtIcon,
    AtParagraph,
    AtSubtitle,
    AtText,
    AtTitle,
    AtTitleSubtitle,
} from "@atman/design-system";
import {
    CompDevApplication,
    CompDevLearningResource,
    CompDevSubCompetenciesStrategicObjectiveResult,
    ICompetencyDevelopmentProcessAssessmentData,
    IObjectiveWithCompetency,
    PrintModeEnum,
} from "@atman/business";
import { LearningResourceInline } from "../../LearningResourceInline";
import { t } from "@lingui/macro";
import AtNotes from "../../../AtNotes";
import AtPrintNotes from "../../../AtPrintNotes";
export interface IActionPlanTablesRowContentObjectiveProps {
    objective: IObjectiveWithCompetency<CompDevSubCompetenciesStrategicObjectiveResult>;
    processAssessmentData?: ICompetencyDevelopmentProcessAssessmentData;
    openAndMonitorTabFunc?: (url: string) => void;
    printMode?: PrintModeEnum;
}

export const ActionPlanTablesRowContentObjective: React.FC<IActionPlanTablesRowContentObjectiveProps> = (props) => {
    const { objective, processAssessmentData, openAndMonitorTabFunc, printMode } = props;

    const onClickEditButton = () => {
        if (openAndMonitorTabFunc && processAssessmentData) {
            openAndMonitorTabFunc(processAssessmentData.url);
        }
    };

    return (
        <div className="ActionPlanTablesRowContentObjective">
            <div className="actionPlanTablesRowContentObjectiveSectionMain">
                <div>
                    <AtTitle title={t({ id: "competencyDevelopment.global.objective" })} />
                    <AtTitle title={objective.data.description} headingType={3} />
                </div>
                <div>
                    {printMode === undefined ? (
                        <AtButton color="secondary" onClick={onClickEditButton}>
                            {t({ id: "global.editCardInformation" })}
                        </AtButton>
                    ) : null}
                </div>
            </div>
            {printMode !== undefined ? (
                <div className="print-only-description-action-plan">
                    <div className="competency-wrapper">
                        <AtSubtitle
                            upWeight
                            size="sm"
                            subtitle={t({ id: "competencyDevelopment.global.competency" })}
                        />

                        <AtTitle headingType={3} title={objective?.competency.name} />
                    </div>
                    <div className="sub-competency-wrapper">
                        <AtSubtitle
                            upWeight
                            size="sm"
                            subtitle={t({ id: "competencyDevelopment.global.subcompetency" })}
                        />

                        <AtTitle headingType={3} title={objective?.subCompetency?.name} />
                    </div>
                </div>
            ) : null}

            {objective?.data?.explanation && (
                <div className="actionPlanTablesRowContentObjectiveSection">
                    <AtTitleSubtitle
                        size="sm"
                        title={t({ id: "competencyDevelopment.global.explanation" })}
                        subtitle={objective.data.explanation}
                    />
                </div>
            )}

            {objective?.data?.reflection && (
                <div className="actionPlanTablesRowContentObjectiveSection">
                    <AtTitle title={t({ id: "competencyDevelopment.global.reflection" })} />
                    <Alert color="success">{objective.data.reflection}</Alert>
                </div>
            )}

            {objective.data.applications.length > 0 && (
                <div className="actionPlanTablesRowContentObjectiveSectionApplications">
                    <div className="actionPlanTablesRowContentObjectiveSectionHeader">
                        <AtIcon icon={["fas", "bolt"]} />
                        <AtText weight="semibold">{t({ id: "competencyDevelopment.global.actionsToComplete" })}</AtText>
                    </div>
                    <AtContainer className="actionPlanApplicationActions">
                        {objective.data.applications.map((application: CompDevApplication) => (
                            <div
                                className="actionPlanApplicationActionItem"
                                key={`improve-application-id-${application.id}`}
                            >
                                <AtIcon icon={["far", "check-circle"]} color={"#D6D6D6"} />
                                <AtParagraph>{application.description}</AtParagraph>
                            </div>
                        ))}
                    </AtContainer>
                </div>
            )}

            {(objective.subCompetency?.selectedLearningResourceIds ?? []).length > 0 && (
                <div className="actionPlanTablesRowContentObjectiveSectionLearnings">
                    <AtTitle title={t({ id: "competencyDevelopment.actionPlan.resourcesToHelpYou" })} />
                    <AtContainer className="actionPlanLearningResources">
                        {[
                            ...(objective.competency.learningResources.filter((learningResource) =>
                                objective.subCompetency?.selectedLearningResourceIds.includes(learningResource.id),
                            ) as CompDevLearningResource[]),
                        ].map((learningResource) => (
                            <LearningResourceInline
                                className="actionPlanLearningResourceItem"
                                key={`learningResource-objective-id-${learningResource.id}`}
                                learningResource={learningResource}
                            />
                        ))}
                    </AtContainer>
                </div>
            )}

            {!!`${objective.subCompetency?.learningResourceNotes}`.trim() &&
                objective.subCompetency?.learningResourceNotes && (
                    <div className="objective-learning-ressources-notes-display">
                        <AtTitle
                            headingType={6}
                            title={t({ id: "competencyDevelopment.select.learningResources.notesTitle" })}
                        />
                        {(printMode === undefined && (
                            <AtNotes notes={objective.subCompetency?.learningResourceNotes} />
                        )) || (
                            <AtPrintNotes
                                prefixKey="subCompetency-learning"
                                notes={objective.subCompetency?.learningResourceNotes}
                            />
                        )}
                    </div>
                )}

            {!!`${objective.data.applicationNotes}`.trim() && objective.data.applicationNotes && (
                <div className="actionPlanTablesRowContentApplicationSectionNotes">
                    <AtTitle
                        title={t({
                            id: "competencyDevelopment.applicationNotes.notesAndComments",
                        })}
                    />
                    {(printMode === undefined && <AtNotes notes={objective.data.applicationNotes} />) || (
                        <AtPrintNotes prefixKey="subCompetency-application" notes={objective.data.applicationNotes} />
                    )}
                </div>
            )}
        </div>
    );
};
