import "./index.less";
import Badge from "reactstrap/lib/Badge";
import React, { useMemo } from "react";
import cn from "classnames";
export interface ITableItemBadgesProps<T> {
    contents: T[];
    className?: string;
}

function TableItemBadges<T>(props: ITableItemBadgesProps<T>): JSX.Element {
    const { contents, className } = props;

    const badgesContent = useMemo(() => {
        if (contents.length < 3) {
            return contents.map((content, index) => (
                <Badge key={index} className="badge truncatedBadge">
                    {content}
                </Badge>
            ));
        }

        if (contents.length > 2) {
            return (
                <>
                    <Badge className="badge truncatedBadge">{contents[0]}</Badge>
                    <Badge className="badge truncatedBadge">{contents[1]}</Badge>
                    <Badge className="badge">+ {contents.length - 2}</Badge>
                </>
            );
        }

        return null;
    }, [contents]);

    return <div className={cn("TableItemBadges", className)}>{badgesContent}</div>;
}

export { TableItemBadges };
