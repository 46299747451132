import * as Sentry from "@sentry/react";
import { AppScope, IAppContext } from "../types";
import { BrowserOptions } from "@sentry/react";
import { ConfigurationProvider, Environment } from "../utils/ConfigurationProvider";
import { History, LocationState } from "history";
import { IconPack, config, dom, library } from "@fortawesome/fontawesome-svg-core";
import { IntercomApi } from "./Intercom";
import { ReactAppSettings } from "./ReactAppSettings";
import { createBrowserHistory } from "history";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import Cache from "@aws-amplify/cache";

export class ApplicationSetup {
    private static _history: History;
    public static get history(): History {
        return this._history;
    }

    static configure(scope: AppScope, sentryDsn: string): IAppSetupReturn {
        this.setupHistory();
        this.setupFontAwesome();
        this.setupIntercomListeningHook();
        this.setupCache();
        this.setupSentry(sentryDsn);

        const rootElement = document.getElementById("react-root") as HTMLElement;

        const appContext: IAppContext = {
            scope,
        };

        return {
            history: this.history,
            rootElement,
            appContext,
        };
    }

    public static setupHistory(): History {
        this._history = createBrowserHistory();

        return this._history;
    }

    public static setupFontAwesome(): void {
        config.autoAddCss = false;
        library.add(fas as IconPack, far as IconPack, fab as IconPack, fal as IconPack);
        dom.watch();
    }

    public static setupIntercomListeningHook(): void {
        if (ReactAppSettings.viewBag.baseInfo?.userInfo) {
            if (!ReactAppSettings.viewBag.baseInfo.userInfo.intercomUserBlacklisted) {
                this.history.listen((location, action) => {
                    IntercomApi.update();
                });
            }
        }
    }

    public static setupCache(): void {
        Cache.configure({
            itemMaxSize: 512000,
        });
    }

    public static setupSentry(sentryDsn: string, initializeScope: boolean = true, tunnelDomain?: string): void {
        if (
            ConfigurationProvider.currentEnvironment !== Environment.Local &&
            ReactAppSettings?.appModel?.disableSentry !== true
        ) {
            const sentryOptions: BrowserOptions = {
                dsn: sentryDsn,
                tunnel: `${tunnelDomain ?? ""}/api/v1/Sentry/Tunnel`,
                debug: false,
                environment: Environment[ConfigurationProvider.currentEnvironment].toUpperCase(),
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(this._history),
                    }),
                    new Sentry.Replay({
                        maskAllText: true,
                        blockAllMedia: true,
                    }),
                ],
                sampleRate: ReactAppSettings?.appModel?.sentrySampleRate ?? 1.0,
                tracesSampleRate: ReactAppSettings?.appModel?.sentryTracesSampleRate ?? 0.01,
                replaysSessionSampleRate: ReactAppSettings?.appModel?.sentryReplaysSessionSampleRate ?? 0.01,
                replaysOnErrorSampleRate: ReactAppSettings?.appModel?.sentryReplaysOnErrorSampleRate ?? 1.0,
                normalizeDepth: 5,
            };

            if (initializeScope && ReactAppSettings?.viewBag?.baseInfo?.userInfo?.userId) {
                sentryOptions.initialScope = (scope) => {
                    scope.setUser({
                        id: ReactAppSettings.viewBag.baseInfo.userInfo.userId,
                        // username: ReactAppSettings.viewBag.baseInfo.userInfo.email,
                        // email: ReactAppSettings.viewBag.baseInfo.userInfo.email,
                    });
                    return scope;
                };
            }

            Sentry.init(sentryOptions);
        }
    }
}

export interface IAppSetupReturn {
    history: History<LocationState>;
    rootElement: HTMLElement;
    appContext: IAppContext;
}
