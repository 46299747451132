import "./SidePanelElementSkeleton.less";
import { DefaultComponentProps } from "@atman/design-system";
import React from "react";
import Skeleton from "react-loading-skeleton";

export interface ISidePanelElementSkeletonProps extends DefaultComponentProps {}

const SidePanelElementSkeleton: React.FC<ISidePanelElementSkeletonProps> = (props: ISidePanelElementSkeletonProps) => {
    const { ...otherProps } = props;
    return (
        <div className="SidePanelElementSkeleton" {...otherProps}>
            <div className="skeletonContainer">
                <Skeleton className="upper" />
                <Skeleton className="lower" />
            </div>
        </div>
    );
};

export default SidePanelElementSkeleton;
