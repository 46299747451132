import "./index.less";
import * as React from "react";
import {
    AppScope,
    GlobalStores,
    ItemType,
    ReactAppSettings,
    SearchAllItemResultModel,
    UnhandledScopeError,
    UrlFormatter,
    UserInfoStore,
} from "@atman/business";
import { AtAvatar, AtIcon, AtLink, CircleContainer, IconTitleSubtitle } from "@atman/design-system";
import { Colors } from "../../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";
import { useAppContext } from "../../../../contexts";
import { useHistory } from "react-router";

export interface ISearchResultEntryProps {
    item: SearchAllItemResultModel;
    toggleDropdown?: () => void;
    inSwitchAppContext?: boolean;
    userInfoStore?: UserInfoStore;
}

export const SearchResultEntry: React.FC<ISearchResultEntryProps> = inject(GlobalStores.userInfoStore)(
    observer((props) => {
        const { item, toggleDropdown, inSwitchAppContext, userInfoStore } = props;

        const { scope } = useAppContext();
        const history = useHistory();

        const manageClientAction = (e: React.SyntheticEvent) => {
            e.preventDefault();

            const partnerAppClientSettingsUrl = `${ReactAppSettings.getPartnerAppBaseUrl(item.partnerId!)}/Clients/${
                item.id
            }`;

            switch (scope) {
                case AppScope.Partner:
                    history.push(partnerAppClientSettingsUrl);
                    toggleDropdown?.();
                    break;
                case AppScope.Supplier:
                    window.open(partnerAppClientSettingsUrl);
                    break;
                default:
                    throw new UnhandledScopeError(scope);
            }
        };

        const clickAction: {
            action?: (e: React.SyntheticEvent) => void;
            externalUrl?: string;
            newTab?: boolean;
            disabled?: boolean;
        } = React.useMemo(() => {
            let url: string = "";

            switch (item.itemType) {
                case ItemType.Candidate:
                    url = `${ReactAppSettings.getClientAppBaseUrl(item.clientId!)}/People/${item.id}`;
                    break;
                case ItemType.Team:
                    url = `${ReactAppSettings.getClientAppBaseUrl(item.clientId!)}/Teams/${item.id}`;
                    break;
                case ItemType.Client:
                    if (!item.additionalData?.canManage && !inSwitchAppContext) {
                        return {
                            action: manageClientAction,
                        };
                    }
                    return {
                        externalUrl: ReactAppSettings.getClientAppBaseUrl(item.id),
                        newTab: !inSwitchAppContext,
                    };
                case ItemType.Partner:
                    return {
                        externalUrl: ReactAppSettings.getPartnerAppBaseUrl(item.id),
                        newTab: !inSwitchAppContext,
                    };
            }

            let action: ((e: React.SyntheticEvent) => void) | undefined = undefined;
            let externalUrl: string | undefined = undefined;
            let newTab: boolean = false;

            switch (scope) {
                case AppScope.Client:
                    action = (e) => {
                        e.preventDefault();

                        if (e.currentTarget.tagName === "button") {
                            return;
                        }

                        history.push(url);
                        toggleDropdown?.();
                    };
                    break;
                case AppScope.Partner:
                case AppScope.Supplier:
                    externalUrl = url;
                    newTab = true;
                    break;
            }

            return {
                action,
                externalUrl,
                newTab,
            };
        }, [item, scope, history, toggleDropdown, inSwitchAppContext]);

        const getIconComponent: () => JSX.Element = () => {
            let iconProp: IconProp;

            switch (item.itemType) {
                case ItemType.Candidate:
                    const pictureUri: string | undefined = item.additionalData?.profilePictureUri;
                    return (
                        <AtAvatar
                            avatarText={item.label}
                            pictureUri={pictureUri ? UrlFormatter.formatPictureUrlForSize(pictureUri, "sm") : undefined}
                            size="md"
                        />
                    );
                case ItemType.Team:
                    iconProp = ["fas", "users"];
                    break;
                case ItemType.Client:
                    iconProp = ["fas", "building"];
                    break;
                case ItemType.Partner:
                    iconProp = ["fas", "city"];
                    break;
                default:
                    iconProp = ["fas", "question"];
            }

            return (
                <CircleContainer>
                    <AtIcon icon={iconProp} color={Colors.greyShades.shade4} />
                </CircleContainer>
            );
        };

        const getSubtitle: () => string | undefined = () => {
            let secondaryLabel: string | undefined = undefined;

            switch (item.itemType) {
                case ItemType.Candidate:
                    secondaryLabel = ReactAppSettings.viewBag.baseInfo.competencyDevelopmentEnabled
                        ? item.additionalData?.jobName ?? "global.noJobAssigned".localize()
                        : item.additionalData?.email;
                    break;
                case ItemType.Team:
                    secondaryLabel = item.clientName;
                    break;
                case ItemType.Client:
                    secondaryLabel = item.partnerName;
                    break;
                case ItemType.Partner:
                    secondaryLabel = "AtmanCo";
                    break;
            }

            return secondaryLabel;
        };

        const renderActions: () => JSX.Element | null = () => {
            let additionalActionInfos: Array<{ action: (e: React.SyntheticEvent) => void; label: string }> = [];

            if (!inSwitchAppContext) {
                switch (item.itemType) {
                    case ItemType.Candidate:
                        break;
                    case ItemType.Team:
                        break;
                    case ItemType.Client:
                        additionalActionInfos = [
                            {
                                label: "global.manage".localize(),
                                action: manageClientAction,
                            },
                        ];
                        break;
                    case ItemType.Partner:
                        additionalActionInfos = [
                            {
                                label: "global.manage".localize(),
                                action: (e) => {
                                    e.preventDefault();

                                    const supplierAppPartnerSettingsUrl = `${ReactAppSettings.getSupplierAppBaseUrl(
                                        userInfoStore!.supplierId,
                                    )}/Partners/${item.id}`;

                                    history.push(supplierAppPartnerSettingsUrl);
                                    toggleDropdown?.();
                                },
                            },
                        ];
                        break;
                }
            }

            if (!additionalActionInfos.any() && !clickAction.newTab) {
                return null;
            }

            return (
                <div className="actions-container">
                    {additionalActionInfos.map((x, i) => (
                        <AtLink key={i} onClick={x.action} color="secondary" size="md">
                            {x.label}
                        </AtLink>
                    ))}
                    {clickAction.newTab && !clickAction.disabled && (
                        <FontAwesomeIcon icon={["far", "arrow-right"]} style={{ transform: "rotate(-45deg)" }} />
                    )}
                </div>
            );
        };

        return (
            <AtLink
                color="secondary"
                className="SearchResultEntry"
                href={clickAction.externalUrl}
                onClick={clickAction.action}
                target={clickAction.newTab ? "_blank" : undefined}
            >
                <IconTitleSubtitle icon={getIconComponent()} title={item.label} subtitle={getSubtitle()} />
                {renderActions()}
            </AtLink>
        );
    }),
);
