import { FitDimensionInfo, IFitDimensionInfo } from "./FitDimensionInfo";

export interface IFitPersonalityDimensionGroups {
    readonly openness: IFitDimensionInfo[];
    readonly conscientiousness: IFitDimensionInfo[];
    readonly agreeableness: IFitDimensionInfo[];
    readonly extraversion: IFitDimensionInfo[];
    readonly emotionalStability: IFitDimensionInfo[];
}

export class FitPersonalityDimensionGroups implements IFitPersonalityDimensionGroups {
    public readonly agreeableness: FitDimensionInfo[];
    public readonly conscientiousness: FitDimensionInfo[];
    public readonly emotionalStability: FitDimensionInfo[];
    public readonly extraversion: FitDimensionInfo[];
    public readonly openness: FitDimensionInfo[];

    constructor(json: IFitPersonalityDimensionGroups) {
        this.agreeableness = json.agreeableness.map((x) => new FitDimensionInfo(x));
        this.conscientiousness = json.conscientiousness.map((x) => new FitDimensionInfo(x));
        this.emotionalStability = json.emotionalStability.map((x) => new FitDimensionInfo(x));
        this.extraversion = json.extraversion.map((x) => new FitDimensionInfo(x));
        this.openness = json.openness.map((x) => new FitDimensionInfo(x));
    }
}
