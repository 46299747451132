// const Intercom = (window as any).Intercom;

export class IntercomApi {
    static update(options?: any) {
        (window as any).Intercom?.("update", options);
    }

    static show() {
        (window as any).Intercom?.("show");
    }

    static hide() {
        (window as any).Intercom?.("hide");
    }

    static showMessages() {
        (window as any).Intercom?.("showMessages");
    }

    static showNewMessage(initialText?: string) {
        (window as any).Intercom?.("showNewMessage", initialText);
    }

    static registerOnShow(action: () => void) {
        (window as any).Intercom?.("onShow", action);
    }

    static registerOnHide(action: () => void) {
        (window as any).Intercom?.("onHide", action);
    }

    static registerOnUnreadCountChange(action: (unreadCount: number) => void) {
        (window as any).Intercom?.("onUnreadCountChange", action);
    }

    static trackEvent(eventName: string, metadata?: any) {
        (window as any).Intercom?.("trackEvent", eventName, metadata);
    }

    static startTour(tourId: number) {
        (window as any).Intercom?.("startTour", tourId);
    }
}

export const INTERCOM_TOUR_IDS = {
    adminOnboarding: {
        test: {
            es: 153094, // we only have 1 language in test env
            fr: 153094, // we only have 1 language in test env
            en: 153094,
        },
        prod: {
            es: 249391,
            fr: 202451,
            en: 202452,
        },
    } as TourIdsConfig,
    userOnboarding: {
        test: {
            es: 202516, // we only have 1 language in test env
            fr: 202516, // we only have 1 language in test env
            en: 202516,
        },
        prod: {
            es: 249387,
            fr: 202448,
            en: 3692,
        },
    } as TourIdsConfig,
    teamOverview: {
        test: {
            es: 153075, // we only have 1 language in test env
            fr: 153075, // we only have 1 language in test env
            en: 153075,
        },
        prod: {
            es: 249388,
            fr: 202447,
            en: 98747,
        },
    } as TourIdsConfig,
    profileOverview: {
        test: {
            es: 149527, // we only have 1 language in test env
            fr: 149527, // we only have 1 language in test env
            en: 149527,
        },
        prod: {
            es: 249389,
            fr: 202446,
            en: 113555,
        },
    } as TourIdsConfig,
};

export type TourIdsConfig = Record<"test" | "prod", Record<"fr" | "en", number>>;
