import "./index.less";
import { AtCard } from "../../molecules/AtCard";
import { DefaultComponentProps } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtImageCardProps extends DefaultComponentProps {
    imageUrl?: string;
    text: string;
}

export const AtImageCard: React.FC<IAtImageCardProps> = (props: IAtImageCardProps) => {
    const { imageUrl, text, className, ...otherProps } = props;

    const style: React.CSSProperties = imageUrl
        ? {
              backgroundImage: `url(${imageUrl})`,
          }
        : {};

    return (
        <AtCard
            className={cn("AtImageCard", className, !imageUrl ? "noImageTextAlign" : "")}
            style={style}
            borderRadius={12}
            {...otherProps}
            padding={null}
        >
            <p className={cn("imageText", imageUrl ? "atImageCardTextColor" : "")}>{text}</p>
        </AtCard>
    );
};
