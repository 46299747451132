import { IDatePreset } from "@atman/design-system";
import { Moment } from "moment";
import moment from "moment";

export function renderConsumptionSchedulingDatePresets(startDate: Date | Nullable<Moment> | undefined) {
    const datePresets: IDatePreset[] = [
        {
            range: {
                from: moment(startDate || undefined),
                to: moment(startDate || undefined).add(3, "month"),
            },
        },
        {
            range: {
                from: moment(startDate || undefined),
                to: moment(startDate || undefined).add(1, "year"),
            },
        },
        {
            range: {
                from: moment(startDate || undefined),
                to: moment(startDate || undefined).add(2, "year"),
            },
        },
        {
            range: {
                from: moment(startDate || undefined),
                to: moment(startDate || undefined).add(5, "year"),
            },
        },
    ];

    return datePresets;
}
