import { ApiManager, ReactAppSettings } from "../libs";
import { BaseApi } from "../base";
import { HttpMethodEnum, ITokenSession } from "../types";

export class TokensApi extends BaseApi {
    private static readonly accessTokenRequestMaxNoOfRetries = 3;

    static async logoutFromOtherDevices() {
        return ApiManager.get(ReactAppSettings.appUrls.logoutFromOtherDevices);
    }

    static async logoutFromDevice(deviceId: string) {
        const data = { deviceId };

        return await this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.logoutFromDevice,
            data,
        );
    }

    static async requestAccessToken() {
        let numberOfRetries = 0;

        const requestAccessTokenMethod = async () => {
            try {
                if (numberOfRetries >= this.accessTokenRequestMaxNoOfRetries) {
                    window.location.href = ReactAppSettings.appUrls.login;
                    return;
                }

                return await ApiManager.post(ReactAppSettings.appUrls.requestJwt).then((result) => result.data);
            } catch (e) {
                console.log(
                    `Failed requesting access token. Attempt #${numberOfRetries + 1} of ${
                        this.accessTokenRequestMaxNoOfRetries
                    }`,
                );
                numberOfRetries++;

                return requestAccessTokenMethod();
            }
        };

        return await requestAccessTokenMethod();
    }

    static async requestZendeskAccessToken() {
        return this.sendApiRequestWithResult<undefined, { zendeskAccessToken: string }>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.requestZendeskAccessToken,
        );
    }

    static async getSessions() {
        return await this.sendApiRequestWithResult<undefined, ITokenSession[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSessions,
        );
    }
}
