import { AverageScoreDisplayWithLabel } from "../../../../AverageScoreDisplayWithLabel";
import { CompDevCompetencyResult, ISelectedObjectiveData } from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../logic";
import { DevelopmentPlanLeverageSubRow } from "./DevelopmentPlanLeverageSubRow";
import { Trans } from "@lingui/macro";
import { observer } from "mobx-react";
import React from "react";

export interface IDevelopmentPlanLeverageCompetencySectionProps {
    comp: CompDevCompetencyResult;
    selectedElement: Map<string, ISelectedObjectiveData> | undefined;
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    toggleSubElementSelection: (elementId: string, subElementId: string) => void;
    toggleApplicationSelection: (elementId: string, subElementId: string, applicationId: string) => void;
    toggleLearningResourceSelection: (elementId: string, subElementId: string, learningResourceId: string) => void;
    setApplicationNote: (elementId: string, subElementId: string, value: string) => void;
    setLearningResourceNote: (elementId: string, subElementId: string, value: string) => void;
    erroredElements?: Map<string, string[]>;
}

export const DevelopmentPlanLeverageCompetencySection: React.FC<IDevelopmentPlanLeverageCompetencySectionProps> =
    observer((props) => {
        const {
            comp,
            selectedElement,
            erroredElements = new Map(),
            columns,
            managerInfo,
            employeeInfo,
            hiddenColumns,
            toggleSubElementSelection,
            toggleApplicationSelection,
            toggleLearningResourceSelection,
            setApplicationNote,
            setLearningResourceNote,
        } = props;

        return (
            <div className="leveraging-objectives-container sub-elements-container">
                <div className="sub-comp-scores-container">
                    <h5>
                        <Trans id={"competencyDevelopment.assessments.leveragingPlan.subCompetencyScores.label"}>
                            Assessed sub-competencies
                        </Trans>
                    </h5>
                    <div className="d-flex flex-wrap">
                        {comp.subCompetencies.map((y, i) => (
                            <AverageScoreDisplayWithLabel
                                key={`subcompetency-${i}`}
                                averageScore={y.observableBehaviorsReviewAverage ?? y.observableBehaviorsAverage}
                                averageScoreDisplayMode={"light"}
                                label={y.name}
                                title={y.name}
                            />
                        ))}
                    </div>
                </div>
                {comp.strategicObjectives && comp.strategicObjectives.length > 0 && (
                    <>
                        <h5>
                            <Trans id={"competencyDevelopment.assessments.leveragingPlan.objectivesContainer.label"}>
                                Select objectives
                            </Trans>
                        </h5>
                        <div className="groups-container">
                            {comp.strategicObjectives.map((objective) => (
                                <DevelopmentPlanLeverageSubRow
                                    key={objective.id}
                                    objective={objective}
                                    comp={comp}
                                    selectedObjectivesData={selectedElement?.get(objective.id)}
                                    erroredElements={erroredElements}
                                    columns={columns}
                                    managerInfo={managerInfo}
                                    employeeInfo={employeeInfo}
                                    hiddenColumns={hiddenColumns}
                                    toggleSubElementSelection={toggleSubElementSelection}
                                    toggleApplicationSelection={toggleApplicationSelection}
                                    toggleLearningResourceSelection={toggleLearningResourceSelection}
                                    setApplicationNote={setApplicationNote}
                                    setLearningResourceNote={setLearningResourceNote}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        );
    });
