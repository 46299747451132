import {
    CompDevApplication,
    CompDevSubCompetenciesStrategicObjective,
    ICompDevApplication,
    ICompDevSubCompetenciesStrategicObjective,
} from "../StructureModels";
import { CompetencyDevelopmentLeveragingObjectiveType } from "../CompDevEnums";
import { observable } from "mobx";

export interface ICompDevSubCompetenciesStrategicObjectiveResult extends ICompDevSubCompetenciesStrategicObjective {
    readonly applications: ICompDevApplication[];
    readonly selectedApplicationIds: string[];
    readonly applicationNotes: string;
    readonly isSelected: boolean;
    readonly type: CompetencyDevelopmentLeveragingObjectiveType;
    readonly explanation: string;
    readonly reflection: string;
}

export class CompDevSubCompetenciesStrategicObjectiveResult extends CompDevSubCompetenciesStrategicObjective {
    public readonly applications: CompDevApplication[];
    public readonly type: CompetencyDevelopmentLeveragingObjectiveType;
    public readonly explanation: string;
    public readonly reflection: string;

    @observable public selectedApplicationIds: string[];
    @observable public applicationNotes: string;
    @observable public isSelected: boolean;

    constructor(json: ICompDevSubCompetenciesStrategicObjectiveResult) {
        super(json);
        // TODO: Check this bug
        this.applications = (json?.applications || []).map((x) => new CompDevApplication(x));
        this.type = json.type;
        this.explanation = json.explanation;
        this.reflection = json.reflection;

        this.selectedApplicationIds = json.selectedApplicationIds;
        this.applicationNotes = json.applicationNotes;
        this.isSelected = json.isSelected;
    }

    getSelectedApplications(): CompDevApplication[] {
        return this.applications.filter((x) => this.selectedApplicationIds.includes(x.id));
    }
}
