import { Borders, Colors, DefaultSizes, Typography } from "../../internal";
import { GroupBase, StylesConfig, mergeStyles } from "react-select";

export function getSelectStyles<
    TOption,
    IsMulti extends boolean,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    additionalStyles: StylesConfig<TOption, IsMulti, TGroup> | undefined,
    size: DefaultSizes,
): StylesConfig<TOption, IsMulti, TGroup> {
    const defaultStyles: StylesConfig<TOption, IsMulti, TGroup> = {
        container: (base, props) => ({
            ...base,
            minWidth: 160,
        }),
        control: (base, props) => ({
            ...base,
            fontFamily: Typography.fontFamily,
            lineHeight: Typography.lineHeight,
            fontWeight: 500,
            borderRadius: 4,
            borderColor: props.menuIsOpen
                ? Colors.primaryBase
                : props.isFocused
                ? Colors.greyShades.shade5
                : Borders.inputBorderColor,
            "&:hover": {
                borderColor: props.menuIsOpen ? Colors.primaryBase : Colors.greyShades.shade5,
            },
        }),
        indicatorSeparator: (base, props) => ({
            ...base,
            display: "none",
        }),
        option: (base, props) => ({
            ...base,
            fontWeight: props.isDisabled ? 400 : 500,
            pointerEvents: props.isDisabled ? "none" : undefined,
            backgroundColor: props.isFocused || props.isSelected ? Colors.greyBg : "white",
            color: props.isDisabled ? Colors.greyShades.shade7 : Colors.greyBase,
            "&:hover": {
                backgroundColor: Colors.greyShades.shade9,
                color: Colors.greyBase,
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }),
        valueContainer: (base, props) => ({
            ...base,
        }),
        multiValueRemove: (base, props) => ({
            ...base,
        }),
    };

    const combinedStyles = mergeStyles(defaultStyles, getStylesForSize(size));

    if (!additionalStyles) {
        return combinedStyles;
    }

    return mergeStyles(combinedStyles, additionalStyles);
}

function getStylesForSize<TOption, IsMulti extends boolean, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(
    size: DefaultSizes,
): StylesConfig<TOption, IsMulti, TGroup> {
    const smStyles: StylesConfig<TOption, IsMulti, TGroup> = {
        container: (base, props) => ({
            ...base,
            fontSize: Typography.fontSizes.md,
        }),
        control: (base, props) => ({
            ...base,
            padding: `1px 0`,
            minHeight: 30,
            fontSize: Typography.fontSizes.md,
        }),
        option: (base, props) => ({
            ...base,
            padding: "6px 12px",
        }),
        valueContainer: (base, props) => ({
            ...base,
            padding: "0 10px",
        }),
        dropdownIndicator: (base, props) => ({
            ...base,
            padding: "4px 8px",
        }),
        clearIndicator: (base, props) => ({
            ...base,
            padding: "4px 8px",
        }),
        multiValue: (base, props) => ({
            ...base,
            margin: "0 2px",
        }),
    };

    const mdStyles: StylesConfig<TOption, IsMulti, TGroup> = {
        container: (base, props) => ({
            ...base,
            fontSize: Typography.fontSizes.md,
        }),
        control: (base, props) => ({
            ...base,
            padding: `1px 0`,
            minHeight: 38,
            fontSize: Typography.fontSizes.md,
        }),
        option: (base, props) => ({
            ...base,
            padding: "8px 12px",
        }),
        valueContainer: (base, props) => ({
            ...base,
            padding: "2px 10px",
        }),
        dropdownIndicator: (base, props) => ({
            ...base,
            padding: "8px 8px",
        }),
        clearIndicator: (base, props) => ({
            ...base,
            padding: "8px 8px",
        }),
        multiValue: (base, props) => ({
            ...base,
            margin: "2px 2px",
        }),
    };

    const lgStyles: StylesConfig<TOption, IsMulti, TGroup> = {
        container: (base, props) => ({
            ...base,
            fontSize: Typography.fontSizes.lg,
        }),
        control: (base, props) => ({
            ...base,
            padding: `1px 0`,
            minHeight: 46,
            fontSize: Typography.fontSizes.lg,
        }),
        option: (base, props) => ({
            ...base,
            padding: "10px 12px",
        }),
        valueContainer: (base, props) => ({
            ...base,
            padding: "2px 10px",
        }),
        dropdownIndicator: (base, props) => ({
            ...base,
            padding: "12px 8px",
        }),
        clearIndicator: (base, props) => ({
            ...base,
            padding: "12px 8px",
        }),
        multiValue: (base, props) => ({
            ...base,
            margin: "4px 2px",
            padding: "4px 0",
        }),
    };

    switch (size) {
        case "sm":
            return smStyles;
        case "md":
            return mdStyles;
        case "lg":
            return lgStyles;
        default:
            return {};
    }
}

export function getSelectStylesWithIcon<
    TOption,
    IsMulti extends boolean,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(additionalStyles?: StylesConfig<TOption, IsMulti, TGroup>): StylesConfig<TOption, IsMulti, TGroup> {
    const iconStyles: StylesConfig<TOption, IsMulti, TGroup> = {
        placeholder: (base, props) => ({
            ...base,
            paddingLeft: 0,
        }),
        singleValue: (base, props) => ({
            ...base,
            paddingLeft: 18,
        }),
        input: (base, props) => ({
            ...base,
            marginLeft: 0,
        }),
        valueContainer: (base) => ({
            ...base,
            display: "flex",
        }),
    };

    if (!additionalStyles) {
        return iconStyles;
    }

    return mergeStyles(iconStyles, additionalStyles);
}
