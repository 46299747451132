import * as React from "react";
import { AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { GlobalStores, LocalizationStore } from "@atman/business";
import { inject, observer } from "mobx-react";
import { useMemo } from "react";

export interface ICultureSelectFieldProps {
    cultureValue: IReactSelectOptionObject<string>;
    onChange: (o: IReactSelectOptionObject<string>) => void;
    labelOverride?: string;
    subLabel?: string;
    localizationStore?: LocalizationStore;
}

export const CultureSelectField: React.FC<ICultureSelectFieldProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { cultureValue, onChange, labelOverride, subLabel, localizationStore } = props;

        const languageOptions = useMemo(() => {
            return localizationStore!.availableLanguages.map((x) => ({
                value: x.name,
                label: x.shortDisplay,
            }));
        }, [localizationStore!.availableLanguages]);

        return (
            <AtSelect
                className="CultureSelectField"
                label={labelOverride ?? "global.language".localize()}
                subLabel={subLabel}
                options={languageOptions}
                value={cultureValue}
                onChange={onChange}
            />
        );
    }),
);
