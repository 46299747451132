import * as qs from "qs";
import { ApiManager, FileHelper, ToastProvider, UrlFormatter } from "../libs";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { BaseModel, IBaseInput, IBaseModel } from "./BaseModel";
import { HttpMethodEnum } from "../types";

export type AllowedModels = BaseModel | BaseModel[] | Partial<BaseModel> | IBaseModel | IBaseInput | null;

export enum DataType {
    Json,
    QueryString,
}

export abstract class BaseApi {
    private static buildQueryStringOptions<T>(data: T): AxiosRequestConfig {
        return {
            paramsSerializer: (params) => qs.stringify(params),
            params: data,
        };
    }

    protected buildQueryStringOptions<T>(data: T): AxiosRequestConfig {
        return {
            paramsSerializer: (params) => qs.stringify(params),
            params: data,
        };
    }

    protected sendApiRequest<TResult = void>(
        method: HttpMethodEnum,
        resourceName: string,
        data?: any,
        id?: string,
        dataType?: DataType,
        extraOptionsConfig?: AxiosRequestConfig,
    ): Promise<TResult> {
        if (id) resourceName = UrlFormatter.format(resourceName, id);

        let axiosOptions: AxiosRequestConfig = {};

        const sendDataAsQueryString =
            dataType === DataType.QueryString ||
            [HttpMethodEnum.GET, HttpMethodEnum.DELETE, HttpMethodEnum.HEAD].includes(method);

        if (sendDataAsQueryString) {
            axiosOptions = {
                ...this.buildQueryStringOptions(data),
            };
        }

        axiosOptions = {
            ...axiosOptions,
            ...extraOptionsConfig,
        };

        switch (method) {
            case HttpMethodEnum.GET:
                return ApiManager.get<TResult>(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.POST:
                return ApiManager.post<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
            case HttpMethodEnum.PUT:
                return ApiManager.put<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
            case HttpMethodEnum.DELETE:
                return ApiManager.delete(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.HEAD:
                return ApiManager.head(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.PATCH:
                return ApiManager.patch<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
        }
    }

    protected static downloadFile<TResult extends {}>(
        fileName: string,
        apiCallPromise: Promise<AxiosResponse | TResult>,
    ) {
        const { hide } = ToastProvider.loading(fileName, {
            hideAfter: 0,
            subtitle: "global.loadingYourFile".localize(),
        });

        apiCallPromise
            .then((result) => {
                hide?.();

                FileHelper.downloadFileData(
                    result.hasOwnProperty("data") ? (result as AxiosResponse).data : result,
                    fileName,
                );
            })
            .catch((error) => {
                hide?.();
                if (error.response?.statusText) {
                    ToastProvider.error(error.response.statusText);
                }
            });
    }

    protected static uploadFile<TResult extends {}>(
        fileName: string,
        apiCallPromise: Promise<AxiosResponse | TResult | void>,
    ) {
        const { hide } = ToastProvider.loading(fileName, { hideAfter: 0, subtitle: "uploadingYourFile".localize() });

        apiCallPromise.finally(() => {
            hide?.();
        });
    }

    protected static sendApiRequest<T>(
        method: HttpMethodEnum,
        resourceName: string,
        data?: T,
        id?: string,
        dataType?: DataType,
        extraOptionsConfig?: AxiosRequestConfig,
    ): Promise<T> {
        return this.sendApiRequestWithResult<T, T>(method, resourceName, data, id, dataType, extraOptionsConfig);
    }

    protected static sendApiRequestWithResult<T, TResult = void>(
        method: HttpMethodEnum,
        resourceName: string,
        data?: T,
        id?: string,
        dataType?: DataType,
        extraOptionsConfig?: AxiosRequestConfig,
    ): Promise<TResult> {
        if (id) resourceName = UrlFormatter.format(resourceName, id);

        let axiosOptions: AxiosRequestConfig = {};

        const sendDataAsQueryString =
            dataType === DataType.QueryString ||
            [HttpMethodEnum.GET, HttpMethodEnum.DELETE, HttpMethodEnum.HEAD].some((x) => x === method);

        if (sendDataAsQueryString) {
            axiosOptions = {
                ...this.buildQueryStringOptions(data),
            };
        }

        axiosOptions = {
            ...axiosOptions,
            ...extraOptionsConfig,
        };

        switch (method) {
            case HttpMethodEnum.GET:
                return ApiManager.get<TResult>(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.POST:
                return ApiManager.post<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
            case HttpMethodEnum.PUT:
                return ApiManager.put<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
            case HttpMethodEnum.DELETE:
                return ApiManager.delete(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.HEAD:
                return ApiManager.head(resourceName, axiosOptions).then((result) => result.data);
            case HttpMethodEnum.PATCH:
                return ApiManager.patch<TResult>(resourceName, !sendDataAsQueryString ? data : null, axiosOptions).then(
                    (result) => result.data,
                );
        }
    }
}
