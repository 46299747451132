import "./index.less";
import * as React from "react";
import { AppScope, GlobalStores, Role, RoleMatchType, RouteWithSubRoutes, UserInfoStore } from "@atman/business";
import { AtTitle } from "@atman/design-system";
import { BaseResponsiveComponent, Default } from "../BaseResponsiveComponent";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label, Nav } from "reactstrap";
import { Redirect, Switch } from "react-router";
import { SubMenuItem } from "./components/SubMenuItem";
import { SubMenuNavItem } from "./components/SubMenuNavItem";
import { autorun, computed, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAtSubMenuNavigationLayoutProps extends IRoutedAppContext {
    navItemGroups: IAtSubMenuNavItemGroup[];
    pageTitle?: string;
    hidePageHeader?: boolean;
    className?: string;
    id?: string;
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AtSubMenuNavigationLayoutComp extends BaseResponsiveComponent<IAtSubMenuNavigationLayoutProps> {
    @observable filteredNavItemGroups: IAtSubMenuNavItemGroup[];

    constructor(props) {
        super(props);

        autorun(() => {
            this.filteredNavItemGroups = this._filteredNavItemGroups;
        });
    }

    @computed private get _filteredNavItemGroups(): IAtSubMenuNavItemGroup[] {
        const { navItemGroups } = this.props;

        const filteredGroups = [
            ...navItemGroups.filter((x) => this.validateAccessToRoute(x.requiredRoles, x.requiredRolesMatchType)),
        ];

        return filteredGroups.map((x) => {
            x.items = x.items.filter((x) => this.validateAccessToRoute(x.requiredRoles, x.requiredRolesMatchType));

            return x;
        });
    }

    @computed protected get rootElementClassName(): string | undefined {
        const { className } = this.props;

        return `AtSubMenuNavigationLayout ${className ? className : ""}`;
    }

    @computed protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    validateAccessToRoute = (requiredRoles?: Role[], matchType: RoleMatchType = "allOf") => {
        const { userInfoStore } = this.props;

        if (!requiredRoles?.any()) {
            return true;
        }

        switch (matchType) {
            case "anyOf":
                return userInfoStore!.hasOneOfRoles(requiredRoles);
            case "allOf":
            default:
                return userInfoStore!.hasAllRoles(requiredRoles);
        }
    };

    renderNavItemGroups() {
        const {
            match: { url },
        } = this.props;

        return (
            <Nav className={"sub-menu-nav"} vertical>
                {toJS(this.filteredNavItemGroups).map((x, i) => (
                    <div className="nav-item-group" key={`nav-item-group-${i}`}>
                        <Default>{x.label && <Label>{x.label}</Label>}</Default>
                        {x.items.map((x) => (
                            <SubMenuNavItem
                                href={`${url}${x.href}`}
                                icon={x.icon}
                                label={x.label}
                                uniqueKey={x.uniqueKey}
                                iconProps={x.iconProps}
                                disabled={x.disabled}
                                key={x.uniqueKey}
                            />
                        ))}
                    </div>
                ))}
            </Nav>
        );
    }

    getEntityName = (scope: AppScope, userInfoStore: UserInfoStore | undefined): string | undefined => {
        switch (scope) {
            case AppScope.Client:
                return userInfoStore!.clientName;

            case AppScope.Partner:
                return userInfoStore!.partnerName;

            case AppScope.Supplier:
                return "AtmanCo";

            case AppScope.Manager:
                return userInfoStore!.clientName;

            case AppScope.Print:
                return userInfoStore!.clientName;

            case AppScope.Assessment:
                return "competencyDevelopmentAssessment".localize();
        }

        return;
    };

    renderAll() {
        const {
            scope,
            pageTitle,
            hidePageHeader = false,
            match: { path },
            userInfoStore,
        } = this.props;

        if (this.filteredNavItemGroups.length === 0 || this.filteredNavItemGroups.every((x) => x.items.length === 0)) {
            console.warn(`[WARNING] No navigation items were found to render.`);
            return null;
        }

        const entityName = this.getEntityName(scope, userInfoStore);

        return (
            <>
                <div className="sub-menu">
                    <Default>
                        {!hidePageHeader && (
                            <div className={"sub-menu-header"}>
                                <AtTitle headingType={2} title={entityName} />
                                <AtTitle headingType={2} title={pageTitle} />
                            </div>
                        )}
                    </Default>
                    {this.renderNavItemGroups()}
                </div>
                <div className="flex-fill">
                    <Switch>
                        {this.filteredNavItemGroups
                            .reduce((p, c) => [...p, ...c.items], [] as IAtSubMenuNavigationItem[])
                            .map((x) => (
                                <RouteWithSubRoutes exact path={`${path}${x.href}`} key={`route-key-${x.uniqueKey}`}>
                                    {x.href === "/JobFits" ||
                                    x.href === "/CultureFits" ||
                                    x.href === "/PerformanceEvaluations" ? (
                                        <SubMenuItem {...x} showHeaderInPage={false} />
                                    ) : (
                                        <SubMenuItem {...x} showHeaderInPage={false} />
                                    )}
                                </RouteWithSubRoutes>
                            ))}
                        <Redirect
                            to={`${path}${
                                this.filteredNavItemGroups.filter((x) => x.items.length > 0)[0].items[0].href
                            }`}
                        />
                    </Switch>
                </div>
            </>
        );
    }
}

export interface IAtSubMenuNavigationItem {
    href: string;
    component: React.ReactNode;
    uniqueKey: string;
    label: string;
    showHeaderInPage?: boolean;
    description?: string;
    helpCenterArticleId?: string;
    helpCenterUrlLabel?: string;
    icon?: IconProp;
    iconProps?: Partial<Omit<FontAwesomeIconProps, "icon">>;
    requiredRoles?: Role[];
    requiredRolesMatchType?: RoleMatchType;
    disabled?: boolean;
}

export interface IAtSubMenuNavItemGroup {
    items: IAtSubMenuNavigationItem[];
    label?: string;
    requiredRoles?: Role[];
    requiredRolesMatchType?: RoleMatchType;
}

const AtSubMenuNavigationLayout = withRoutedAppContext(AtSubMenuNavigationLayoutComp);

export { AtSubMenuNavigationLayout, SubMenuNavItem };
