import { ColorProperty } from "csstype";
import { Colors } from "../../../../styles/variables/colors";
import { VictoryPie } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IFullMeterPieProps {
    value?: number;
    maxValue: number; // Max possible value
    color: ColorProperty;
}

@inject()
@observer
export class FullMeterPie extends React.Component<IFullMeterPieProps> {
    public static defaultProps = { value: 0 };

    render() {
        const { value, maxValue, color } = this.props;

        return (
            <VictoryPie
                animate={true}
                data={[
                    { x: 1, y: value!, label: "", active: true }, // The main value
                    { x: 2, y: maxValue - value!, label: "", active: false }, // The rest. Since it's a single value, we need to fill the rest of the pie
                ]}
                labels={() => ""}
                standalone={false}
                innerRadius={125}
                cornerRadius={20}
                style={{
                    data: {
                        fill: ({ datum: { active } }) => {
                            if (active) {
                                return color;
                            }

                            return Colors.greyBg;
                        },
                    },
                }}
            />
        );
    }
}
