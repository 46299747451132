export class StorageProvider {
    public static clear(): void {
        localStorage.clear();
    }

    public static getItem<TValue>(key: string): TValue | undefined {
        const value = localStorage.getItem(key);

        if (!value) {
            return undefined;
        }

        try {
            if (/\.([{}\[\]])$/.test(value)) {
                return JSON.parse(value);
            }

            return value as unknown as TValue;
        } catch (e) {
            console.log(`Couldn't parse value as JSON: ${value}`);
            return value as unknown as TValue;
        }
    }

    public static key(index: number): string | undefined {
        return localStorage.key(index) ?? undefined;
    }

    public static removeItem(key: string): void {
        return localStorage.removeItem(key);
    }

    public static setItem<TValue = any>(key: string, value: TValue): void {
        return localStorage.setItem(key, typeof value === "string" ? value : JSON.stringify(value));
    }

    public static getOrSetItem<TValue = any>(key: string, valueIfUnset: TValue): TValue {
        let result = this.getItem<TValue>(key);

        if (!result) {
            this.setItem(key, valueIfUnset);
            result = valueIfUnset;
        }

        return result;
    }
}
