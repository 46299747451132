import "./index.less";
import * as React from "react";
import { AtButton, IAtButtonProps } from "../../../internal";
import { PropsWithChildren } from "react";
import { observer } from "mobx-react";

export interface IAtAsyncButtonProps<TAsyncActionResult = any> extends Omit<IAtButtonProps, "isLoading" | "onClick"> {
    asyncAction: () => Promise<TAsyncActionResult>;
    callbackAction: (result: TAsyncActionResult) => void;
}

export const AtAsyncButton = observer(
    <TAsyncActionResult extends unknown>(props: PropsWithChildren<IAtAsyncButtonProps<TAsyncActionResult>>) => {
        const { asyncAction, callbackAction, className, ...otherProps } = props;

        const [isLoading, setIsLoading] = React.useState(false);

        const onClick = async () => {
            setIsLoading(true);

            try {
                const result = await asyncAction();

                setIsLoading(false);

                callbackAction(result);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        };

        return (
            <AtButton
                className={`AtAsyncButton ${className ?? ""}`}
                {...otherProps}
                onClick={onClick}
                isLoading={isLoading}
            />
        );
    },
);
