import "./EntityOverviewHeader.less";
import * as React from "react";
import { AppScope, GlobalStores, ReactAppSettings, UnhandledScopeError, UserInfoStore } from "@atman/business";
import { AtButton, AtTitle } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRoutedAppContext, withRoutedAppContext } from "../../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";

export interface IEntityOverviewHeaderProps extends IRoutedAppContext {
    ownerName: string;
    ownerId: string;
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class EntityOverviewHeaderComp extends React.Component<IEntityOverviewHeaderProps, {}> {
    manageEntity = () => {
        const { ownerId } = this.props;

        switch (this.props.scope) {
            case AppScope.Partner:
                window.open(ReactAppSettings.getClientAppBaseUrl(ownerId));
                break;
            case AppScope.Supplier:
                window.open(ReactAppSettings.getPartnerAppBaseUrl(ownerId));
                break;
            default:
                throw new UnhandledScopeError(this.props.scope);
        }
    };

    render() {
        const { ownerId, ownerName, scope, userInfoStore } = this.props;

        let manageEntityLabel: string;
        let hasDelegation: boolean;

        switch (scope) {
            case AppScope.Partner:
                manageEntityLabel = "partnerApp.manageClient".localize();
                hasDelegation =
                    userInfoStore!.automaticClientDelegationEnabled ||
                    (ReactAppSettings.appModel.delegatedClientIds ?? []).includes(ownerId);
                break;
            case AppScope.Supplier:
                manageEntityLabel = "global.managePartner".localize();
                hasDelegation = true;
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        return (
            <div id="EntityOverviewHeader">
                <div className="left-section">
                    <div className="entity-info">
                        <span className="logo">
                            <FontAwesomeIcon icon={["far", "building"] as IconProp} />
                        </span>
                        <AtTitle headingType={2} title={ownerName} />
                    </div>
                </div>
                <div className="right-section">
                    {hasDelegation && (
                        <AtButton id={"manage-entity-button"} onClick={this.manageEntity}>
                            {manageEntityLabel}
                        </AtButton>
                    )}
                </div>
            </div>
        );
    }
}

const EntityOverviewHeader = withRoutedAppContext(EntityOverviewHeaderComp);

export { EntityOverviewHeader };
