import "./index.less";
import * as React from "react";
import { AtIconTooltip } from "@atman/design-system";
import { AverageScoreDisplay, AverageScoreDisplayMode } from "../AverageScoreDisplay";
import { Colors } from "../../../styles";
import { LikertScoreDisplay } from "../LikertScoreDisplay";

export interface IAtScoreReviewIndicatorProps {
    score: number | undefined;
    scoreDisplayMode: "average" | "likert";
    averageScoreDisplayMode?: AverageScoreDisplayMode;
    tooltipTargetId?: string;
}

export const AtScoreReviewIndicator: React.FC<IAtScoreReviewIndicatorProps> = (props) => {
    const { score, scoreDisplayMode, averageScoreDisplayMode, tooltipTargetId } = props;

    const tooltipProps = { style: { width: 240 } };

    return (
        <AtIconTooltip
            icon={["fas", "pen"]}
            iconColor={Colors.greyShades.shade4}
            iconSizePx={8}
            tooltipProps={tooltipProps}
            tooltipDisplayMode={"light"}
            tooltipTargetId={tooltipTargetId}
            className={"AtScoreReviewIndicator"}
            showCircle
        >
            <div className="score-review-tooltip">
                <span>{"competencyDevelopment.observableBehavior.adjustedScore.description".localize()}</span>
                <div className="previous-score">
                    <span>{"competencyDevelopment.previousScore".localize()}</span>
                    {scoreDisplayMode === "average" && (
                        <AverageScoreDisplay averageScore={score} averageScoreDisplayMode={averageScoreDisplayMode} />
                    )}
                    {scoreDisplayMode === "likert" && <LikertScoreDisplay score={score} />}
                </div>
            </div>
        </AtIconTooltip>
    );
};
