import { AssessmentType } from "../types";
import { IDimensionStructure, ScaleType } from "../models";
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";

export interface IAssessmentResultsCalculationService {
    getScorePercentage: (score: number) => number;
    getScoreDifferenceFromRange: (score: number) => number;
    scorePositionInfo: Dictionary<number, IScorePositionInfo>;
    rangePositionInfo: Nullable<IRangePositionInfo>;
}

export class AssessmentResultsCalculationService implements IAssessmentResultsCalculationService {
    @observable public maxScore: number;
    @observable public assessmentType: AssessmentType;
    @observable public dimension: IDimensionStructure;
    @observable public scaleType: ScaleType;

    constructor(
        dimension: IDimensionStructure,
        assessmentType: AssessmentType,
        maxScore: number,
        scaleType: ScaleType,
    ) {
        this.dimension = dimension;
        this.assessmentType = assessmentType;
        this.maxScore = maxScore;
        this.scaleType = scaleType;
    }

    public getScorePercentage = computedFn((score: number) => {
        return AssessmentResultsCalculationServiceLogic.getScorePercentage(score, this.maxScore);
    });

    public static getScorePercentage(score: number, maxScore: number) {
        return AssessmentResultsCalculationServiceLogic.getScorePercentage(score, maxScore);
    }

    @computed
    public get scorePositionInfo(): Dictionary<number, IScorePositionInfo> {
        return AssessmentResultsCalculationServiceLogic.getScorePositionsInfo(this.scaleType, this.maxScore);
    }

    @computed
    private get bipolarScorePositionInfo(): Dictionary<number, IScorePositionInfo> {
        return AssessmentResultsCalculationServiceLogic.getBipolarScorePositionsInfo(this.maxScore);
    }

    public getScoreDifferenceFromRange = computedFn((score: number) => {
        if (
            !this.dimension.range ||
            this.isNullOrUndefined(this.dimension.range.min) ||
            this.isNullOrUndefined(this.dimension.range.max)
        ) {
            return -1;
        }

        if (score >= this.dimension.range.min) {
            if (score <= this.dimension.range.max) {
                return 0;
            } else {
                return score - this.dimension.range.max;
            }
        } else {
            return this.dimension.range.min - score;
        }
    });

    public get rangePositionInfo() {
        if (
            !this.dimension.range ||
            this.isNullOrUndefined(this.dimension.range.min) ||
            this.isNullOrUndefined(this.dimension.range.max)
        ) {
            return null;
        }

        const minPercentage = this.bipolarScorePositionInfo[this.dimension.range.min];
        const maxPercentage = this.bipolarScorePositionInfo[this.dimension.range.max];

        if (!minPercentage || !maxPercentage) {
            return null;
        }

        return {
            min: minPercentage.left,
            max: maxPercentage.left + maxPercentage.width,
            width: maxPercentage.left - minPercentage.left + maxPercentage.width,
        } as IRangePositionInfo;
    }

    private isNullOrUndefined: (object: any) => boolean = computedFn((object: any) => {
        return object === null || object === undefined;
    });
}

export class AssessmentResultsCalculationServiceLogic {
    static getScorePercentage(score: number, maxScore: number) {
        return ((score - 1) / maxScore) * 100;
    }

    static getUnipolarScorePositionsInfo(maxScore: number): Dictionary<number, IScorePositionInfo> {
        const positionPercentagesDict: Dictionary<number, IScorePositionInfo> = {};

        for (let i = 1; i <= maxScore; i++) {
            positionPercentagesDict[i] = AssessmentResultsCalculationServiceLogic.getUnipolarScorePositionInfo(
                i,
                maxScore,
            );
        }

        return positionPercentagesDict;
    }

    static getUnipolarScorePositionInfo(score: number, maxScore: number): IScorePositionInfo {
        return {
            width: (score / maxScore) * 100,
            left: 0,
        };
    }

    static getBipolarScorePositionsInfo(maxScore: number): Dictionary<number, IScorePositionInfo> {
        const positionPercentagesDict: Dictionary<number, IScorePositionInfo> = {
            1: {
                width: (1 / maxScore) * 100,
                left: 0,
            },
        };

        for (let i = 1; i <= maxScore; i++) {
            if (i === 1) {
                continue;
            }

            positionPercentagesDict[i] = {
                width: (1 / maxScore) * 100,
                left: AssessmentResultsCalculationServiceLogic.getScorePercentage(i, maxScore),
            };
        }

        return positionPercentagesDict;
    }

    static getScorePositionsInfo(scaleType: ScaleType, maxScore: number): Dictionary<number, IScorePositionInfo> {
        switch (scaleType) {
            case ScaleType.Unipolar:
                return AssessmentResultsCalculationServiceLogic.getUnipolarScorePositionsInfo(maxScore);
            case ScaleType.Bipolar:
                return AssessmentResultsCalculationServiceLogic.getBipolarScorePositionsInfo(maxScore);
        }
    }
}

export interface IScorePositionInfo {
    width: number;
    left: number;
}

export interface IRangePositionInfo {
    min: number;
    max: number;
    width: number;
}
