import "./index.less";
import * as React from "react";
import { AtContainer, AtTitleSubtitle, LoadingIndicator, LocalizedTextInput } from "@atman/design-system";
import { BaseForm } from "../../../BaseForm";
import { CardSaveButtons } from "../../../CardSaveButtons";
import { CustomValidatedTextInput } from "../../../CustomValidatedTextInput";
import { Form } from "reactstrap";
import {
    GlobalStores,
    ISupportedCulture,
    LocalizationStore,
    ScopedOrganizationStore,
    Validator,
} from "@atman/business";
import { ObservableMap, action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IOrgBaseCustomizationFormProps {
    scopedOrganizationStore?: ScopedOrganizationStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.scopedOrganizationStore, GlobalStores.localizationStore)
@observer
export class OrgBaseCustomizationForm extends BaseForm<IOrgBaseCustomizationFormProps> {
    @observable private logoUri: string = "";
    @observable private localizedDescription: ObservableMap<string, string> = new ObservableMap<string, string>();

    componentDidMount() {
        super.componentDidMount();

        this.resetForm();
    }

    onLocalizedDescriptionChange = action((event: React.FormEvent<HTMLInputElement>, currentLocale: string) => {
        this.localizedDescription.set(currentLocale, event.currentTarget.value);
    });

    resetForm = () => {
        const { scopedOrganizationStore } = this.props;

        const { logoUri, description } =
            scopedOrganizationStore!.scopedOrganizationCustomization?.baseCustomizations ?? {};

        this.logoUri = logoUri ?? "";
        this.localizedDescription.clear();

        if (description) {
            for (const key of Object.keys(description)) {
                this.localizedDescription.set(key, description[key]);
            }
        }
    };

    validateForm = () => {
        if (this.logoUri && !Validator.validateUrl(this.logoUri)) {
            this.errors.push({
                fieldName: "logoUri",
                message: t({
                    id: "global.orgCustomization.baseForm.errors.invalidUri",
                    message: "The URI is not in a valid format",
                }),
                name: "ValidationError",
            });
            return false;
        }

        return true;
    };

    onSave = async () => {
        const { scopedOrganizationStore } = this.props;

        if (!this.validateForm()) {
            return;
        }

        try {
            this.setIsLoading(true);

            await scopedOrganizationStore!.updateBaseCustomization({
                logoUri: this.logoUri,
                description: this.localizedDescription.toJSON(),
            });

            this.setIsLoading(false);
        } catch (e) {
            console.log(e);
            this.setIsLoading(false);
        }
    };

    render() {
        const { localizationStore, scopedOrganizationStore } = this.props;

        if (!scopedOrganizationStore?.scopedOrganizationCustomization) {
            return <LoadingIndicator />;
        }

        let languagesToTranslate: ISupportedCulture[];

        if (localizationStore!.availableAssessmentLanguages.any()) {
            languagesToTranslate = localizationStore!.availableAssessmentLanguages;
        } else {
            languagesToTranslate = localizationStore!.availableLanguages;
        }

        return (
            <AtContainer id="OrgBaseCustomizationForm">
                <AtTitleSubtitle
                    size={"xl"}
                    title={t({
                        id: "global.orgCustomization.baseForm.title",
                        message: "Organization Base Customization",
                    })}
                    subtitle={t({
                        id: "global.orgCustomization.baseForm.description",
                        message: "Customize your company logo, description and more",
                    })}
                />
                <Form className="base-customization-form">
                    <CustomValidatedTextInput
                        fieldName={"logoUri"}
                        value={this.logoUri}
                        onChange={this.onTextFieldChange}
                        label={"global.orgCustomization.baseForm.logoUri.label".localize()}
                        formText={"global.orgCustomization.baseForm.logoUri.formText".localize()}
                        validationErrors={this.errors}
                    />
                    {languagesToTranslate.map((x) => (
                        <LocalizedTextInput
                            key={x.shortName}
                            labelKey={"global.orgCustomization.description.label"}
                            formText={"global.orgCustomization.description.description".localize()}
                            fieldName={`localizedDescription-${x.shortName}`}
                            value={this.localizedDescription.get(x.shortName) ?? ""}
                            currentLocale={x.shortName}
                            onChange={(e) => this.onLocalizedDescriptionChange(e, x.shortName)}
                        />
                    ))}
                    <CardSaveButtons
                        saveAction={this.onSave}
                        cancelAction={this.resetForm}
                        isLoading={this.isLoading}
                    />
                </Form>
            </AtContainer>
        );
    }
}
