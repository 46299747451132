import {
    FitCompetencyGroups,
    FitCompetencyInfo,
    FitDimensionInfo,
    FitPersonalityDimensionGroups,
    FitRange,
    IFitCompetencyGroups,
    IFitPersonalityDimensionGroups,
    IFitRange,
} from "./elements";

export interface IBaseFitContent {
    readonly localizedNames: Record<string, string>;
    readonly localizedDescriptions: Record<string, string>;
    readonly competencies: IFitCompetencyGroups;
    readonly personalityDimensions: IFitPersonalityDimensionGroups;
    readonly cognitiveRange: IFitRange;
}

export abstract class BaseFitContent implements IBaseFitContent {
    public readonly localizedNames: Record<string, string>;
    public readonly localizedDescriptions: Record<string, string>;
    public readonly competencies: FitCompetencyGroups;
    public readonly personalityDimensions: FitPersonalityDimensionGroups;
    public readonly cognitiveRange: FitRange;

    protected constructor(json: IBaseFitContent) {
        this.localizedNames = json.localizedNames;
        this.localizedDescriptions = json.localizedDescriptions;
        this.competencies = new FitCompetencyGroups(json.competencies);
        this.personalityDimensions = new FitPersonalityDimensionGroups(json.personalityDimensions);

        if (json.cognitiveRange) {
            this.cognitiveRange = new FitRange(json.cognitiveRange);
        } else {
            this.cognitiveRange = new FitRange({
                enabled: true,
                min: 1,
                max: 9,
                isInverted: false,
                weight: undefined,
                weightDisplay: undefined,
            });
        }
    }

    public get allCompetencies(): FitCompetencyInfo[] {
        const returnObject: FitCompetencyInfo[] = [];

        if (!this.competencies) {
            return returnObject;
        }

        for (const key of Object.keys(this.competencies)) {
            returnObject.push(...(this.competencies[key] || []));
        }

        return returnObject;
    }

    public get allPersonalityDimensions(): FitDimensionInfo[] {
        const returnObject: FitDimensionInfo[] = [];

        if (!this.personalityDimensions) {
            return returnObject;
        }

        for (const key of Object.keys(this.personalityDimensions)) {
            returnObject.push(...(this.personalityDimensions[key] || []));
        }

        return returnObject;
    }
}
