import "./index.less";
import React, { ReactElement } from "react";
import cn from "classnames";

interface IFitIndicatorProps {
    fitLevelEnumString: string;
    isCompetency?: boolean;
}

export const FitIndicator = ({ fitLevelEnumString, isCompetency }: IFitIndicatorProps): ReactElement => (
    <div className={cn("FitIndicator", fitLevelEnumString, { isCompetency: isCompetency })}></div>
);
