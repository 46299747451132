import "./index.less";
import * as React from "react";
import { observer } from "mobx-react";

export interface ISmallTitleContentBlockProps {
    title: string;
}
export const SmallTitleContentBlock: React.FC<ISmallTitleContentBlockProps> = observer((props) => {
    const { title, children, ...otherProps } = props;
    return (
        <div {...otherProps} className="SmallTitleContentBlock">
            <div className="title">{title}</div>
            <div>{children}</div>
        </div>
    );
});
