import "./index.less";
import * as React from "react";
import { AtContainer, AtIcon, Colors, IReactSelectOptionObject, IconTitleSubtitle, Sizing } from "@atman/design-system";
import { CompetencyProfile, SeniorityLevel } from "@atman/business";
import { CompetencySetInformationForm, CompetencySetType } from "../../CompetencySetInformationForm";
import { FieldError } from "@atman/core";
import { HelpCenterInfoContained } from "../../../../../components";
import { observer } from "mobx-react";

export interface ICompetencySetInformationStep {
    competencySetType: CompetencySetType;
    localizedNames: Map<string, string>;
    localizedDescriptions: Map<string, string>;
    onLocalizedNameChange: (locale: string, name: string) => void;
    onLocalizedDescriptionChange: (locale: string, description: string) => void;
    onFormSubStepSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onAutoCompleteChange: <T>(options: T, fieldName?: string, enumValue?: boolean) => void;
    errors: FieldError[];
    departmentId: IReactSelectOptionObject<string> | undefined;
    profile?: CompetencyProfile | null;
    seniorityLevel?: SeniorityLevel | null;
}

export const CompetencySetInformationStep: React.FC<ICompetencySetInformationStep> = observer((props) => {
    const {
        localizedNames,
        localizedDescriptions,
        onLocalizedNameChange,
        onLocalizedDescriptionChange,
        onFormSubStepSubmit,
        onAutoCompleteChange,
        errors,
        departmentId,
        profile,
        seniorityLevel,
        competencySetType,
    } = props;

    const isJob: boolean = React.useMemo(() => {
        return competencySetType === "job";
    }, [competencySetType]);

    return (
        <div id="CompetencySetInformationStep">
            <AtContainer
                className="information-container"
                padding={[Sizing.pixels.md, Sizing.pixels.lg, Sizing.pixels.lg]}
            >
                <IconTitleSubtitle
                    title={(isJob
                        ? "global.addInformationForYourJob"
                        : "global.addInformationForYourCulture"
                    ).localize()}
                    subtitle={(isJob
                        ? "global.jobWizard.informationStep.description"
                        : "global.cultureWizard.informationStep.description"
                    ).localize()}
                    icon={
                        <AtIcon
                            icon={isJob ? "id-badge" : "theater-masks"}
                            size="2x"
                            color={Colors.atmanOrange}
                            bgColor={Colors.atmanOrangeFaded}
                        />
                    }
                    size="lg"
                />
                <CompetencySetInformationForm
                    type={competencySetType}
                    id="InformationStepForm"
                    localizedNames={localizedNames}
                    localizedDescriptions={localizedDescriptions}
                    onLocalizedNameChange={onLocalizedNameChange}
                    onLocalizedDescriptionChange={onLocalizedDescriptionChange}
                    onFormSubStepSubmit={onFormSubStepSubmit}
                    onAutoCompleteChange={onAutoCompleteChange}
                    errors={errors}
                    departmentId={departmentId}
                    profile={profile}
                    seniorityLevel={seniorityLevel}
                />
            </AtContainer>
            <HelpCenterInfoContained
                title={(isJob
                    ? "global.learnMoreOnHowToCreateJobs"
                    : "global.learnMoreOnHowToCreateCultures"
                ).localize()}
                description={(isJob
                    ? "global.jobWizard.informationStep.helpCenterDescription"
                    : "global.cultureWizard.informationStep.helpCenterDescription"
                ).localize()}
                href={isJob ? "/articles/360054657571" : "/articles/360054192232"}
                ctaLabelOverride={"global.readOurGuide".localize()}
            />
        </div>
    );
});
