import { ApiError, FieldError } from "@atman/core";
import { useState } from "react";

export interface IUseErrorHandling {
    errors: ReadonlyArray<FieldError | ApiError>;
    addErrors: (...errs: Array<FieldError | ApiError>) => void;
    clearErrorsForField: (fieldName: string) => void;
    clearErrors: () => void;
}

export function useErrorHandling(): IUseErrorHandling {
    const [errors, setErrors] = useState<Array<FieldError | ApiError>>([]);

    const addErrors = (...errs: Array<FieldError | ApiError>) => {
        setErrors((prev) => [...prev, ...errs]);
    };

    const clearErrorsForField = (fieldName: string) => {
        setErrors((prev) => prev.filter((x) => x.fieldName !== fieldName));
    };

    const clearErrors = () => {
        setErrors([]);
    };

    return {
        errors,
        addErrors,
        clearErrorsForField,
        clearErrors,
    };
}
