import "./index.less";
import { AtAvatar, AtBadge, AtCard, AtText } from "@atman/design-system";
import { AtStylesCandidateResult, IAtStyleDefinition, IShortCandidate, UrlFormatter } from "@atman/business";
import { AtmanIcon } from "../../AtmanIcon";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";
import cn from "classnames";

export interface IAtStyleTeamMemberCardProps {
    stylesResult: AtStylesCandidateResult;
    candidateInfo: IShortCandidate;
    stylesDefinitions: IAtStyleDefinition[];
    isLead: boolean;
    highlightedStyleId: string | undefined;
    isInPrintMode?: boolean;
}

export const AtStyleTeamMemberCard: React.FC<IAtStyleTeamMemberCardProps> = observer((props) => {
    const { stylesResult, candidateInfo, highlightedStyleId, isLead, stylesDefinitions, isInPrintMode } = props;

    const color = stylesDefinitions.find((style) => style.id === stylesResult.dominantStyleId)?.color;

    const memberStyleIds = [stylesResult.dominantStyleId, ...stylesResult.secondaryStyleIds];
    const isHighlighted = highlightedStyleId ? memberStyleIds.some((styleId) => styleId === highlightedStyleId) : false;
    const borderColor = isHighlighted ? stylesDefinitions.find((x) => x.id === highlightedStyleId)?.color : undefined;

    const handleCardClick = (): void => {
        window.open(UrlFormatter.formatReactPath(`/People/${candidateInfo.candidateProId}/Styles`));
    };

    return (
        <div className={cn("AtStyleTeamMemberCard", "memberCard")}>
            <AtCard
                borderColor={borderColor}
                borderRadius={8}
                style={{ opacity: highlightedStyleId ? (isHighlighted ? 1 : 0.5) : 1 }}
                onClick={handleCardClick}
                topContent={
                    <div className="avatarLeadContainer">
                        <AtAvatar
                            className="memberAvatar"
                            avatarText={`${candidateInfo?.firstName} ${candidateInfo?.lastName}`}
                            pictureUri={candidateInfo?.profilePictureUri ?? undefined}
                            backgroundColor={color}
                            size={"md"}
                        />
                        {isLead ? (
                            <AtBadge size={isInPrintMode ? "xs" : "sm"}>{t({ id: "global.manager" })}</AtBadge>
                        ) : null}
                    </div>
                }
            >
                <AtText weight="semibold">
                    {candidateInfo?.firstName} {candidateInfo?.lastName}
                </AtText>

                <div className="memberCardIconContainer flexContainer">
                    <AtmanIcon size="sm" color={color} />

                    {stylesResult.secondaryStyleIds.map((styleId) => (
                        <AtmanIcon
                            key={styleId}
                            size="xs"
                            color={stylesDefinitions.find((x) => x.id === styleId)?.color}
                        />
                    ))}
                </div>
            </AtCard>
        </div>
    );
});
