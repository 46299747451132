import "./index.less";
import { CompetencyDevelopmentAssessmentStatus, CompetencyDevelopmentAssessmentType } from "@atman/business";
import { CompetencyDevelopmentAssessmentThankYouScreen } from "../AssessmentWizard/components/AssessmentThankYouScreen";
import { ICompDevEvaluationContext, withCompDevEvaluationContext } from "../../../../contexts";
import { LoadingIndicator } from "@atman/design-system";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import CompetencyDevelopmentAssessmentWelcomeScreen from "../AssessmentWizard/components/AssessmentWelcomeScreen";
import React from "react";

export interface ICompetencyDevelopmentAssessmentContainerProps extends ICompDevEvaluationContext {
    id: string;
    assessmentComponent: React.ReactNode;
}

@observer
export class CompetencyDevelopmentAssessmentContainerImpl extends React.Component<
    ICompetencyDevelopmentAssessmentContainerProps,
    {}
> {
    @observable private isLoadingAssessmentStructure: boolean = true;
    @observable private isLoadingProcessResultsData: boolean = false;

    componentDidMount(): void {
        this.loadAssessmentStructure();
        this.loadProcessResultsData();
    }

    @action loadAssessmentStructure = async (): Promise<void> => {
        const { loadAssessmentStructure } = this.props;

        this.isLoadingAssessmentStructure = true;

        await loadAssessmentStructure();

        runInAction(() => {
            this.isLoadingAssessmentStructure = false;
        });
    };

    @action loadProcessResultsData = async (): Promise<void> => {
        const { loadProcessResultsData } = this.props;

        this.isLoadingProcessResultsData = true;

        await loadProcessResultsData();

        runInAction(() => {
            this.isLoadingProcessResultsData = false;
        });
    };

    render(): JSX.Element {
        const { assessmentType, id, assessmentComponent, assessmentStructure } = this.props;

        if (this.isLoadingAssessmentStructure || this.isLoadingProcessResultsData) {
            return (
                <div id={id} className={"CompetencyDevelopmentAssessmentContainer"}>
                    <LoadingIndicator
                        size={"xl"}
                        label={t({
                            id: "global.assessment.loadingMessage",
                            message: `Loading your assessment...`,
                        })}
                    />
                </div>
            );
        }

        const isCompetencyEvaluations =
            assessmentType === CompetencyDevelopmentAssessmentType.Self ||
            assessmentType === CompetencyDevelopmentAssessmentType.Manager;

        let content: React.ReactNode;

        switch (assessmentStructure!.status) {
            case CompetencyDevelopmentAssessmentStatus.NotStarted:
                content = <CompetencyDevelopmentAssessmentWelcomeScreen />;
                break;
            case CompetencyDevelopmentAssessmentStatus.Started:
                content = assessmentComponent;
                break;
            case CompetencyDevelopmentAssessmentStatus.Completed:
                if (isCompetencyEvaluations) {
                    content = <CompetencyDevelopmentAssessmentThankYouScreen />;
                } else {
                    content = assessmentComponent;
                }
                break;
        }

        let inWizard: boolean;

        if (isCompetencyEvaluations) {
            inWizard = assessmentStructure!.status === CompetencyDevelopmentAssessmentStatus.Started;
        } else {
            inWizard =
                assessmentStructure!.status === CompetencyDevelopmentAssessmentStatus.Started ||
                assessmentStructure!.status === CompetencyDevelopmentAssessmentStatus.Completed;
        }

        return (
            <div id={id} className={`CompetencyDevelopmentAssessmentContainer ${inWizard ? "in-wizard" : ""}`}>
                {content}
            </div>
        );
    }
}

export const CompetencyDevelopmentAssessmentContainer = withCompDevEvaluationContext(
    CompetencyDevelopmentAssessmentContainerImpl,
);
