import "./index.less";
import * as React from "react";
import { ApiError, FieldError } from "@atman/core";
import { AtButton, AtContainer, AtSubtitle, AtTitle } from "@atman/design-system";
import { CustomValidatedTextInput } from "@atman/ui-kit";
import { Form } from "reactstrap";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";

export interface IRequestCodeViewProps {
    email: string;
    setEmail: (e: React.FormEvent<HTMLInputElement>) => void;
    errors: ReadonlyArray<FieldError | ApiError>;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isLoading: boolean;
}

export const RequestCodeView: React.FC<IRequestCodeViewProps> = observer((props) => {
    const { email, setEmail, errors, onSubmit, isLoading } = props;

    return (
        <div id="RequestCodeView">
            <AtTitle
                title={t({
                    id: "loginApp.employee.signIn.requestCodeStep.title",
                    message: "Sign in to your Employee portal",
                })}
                headingType={1}
            />
            <AtSubtitle
                subtitle={t({
                    id: "loginApp.employee.signIn.requestCodeStep.subtitle",
                    message: "We suggest using the email address you use at work.",
                })}
                size={"xl"}
            />
            <Form onSubmit={onSubmit}>
                <CustomValidatedTextInput
                    fieldName={"email"}
                    label={t({ id: "global.email" })}
                    value={email}
                    onChange={setEmail}
                    validationErrors={errors}
                />
                <AtButton
                    id="email-submit-btn"
                    icon={["far", "wand-magic"]}
                    iconProps={{ position: "right" }}
                    color="primary"
                    size="xl"
                    fullWidth
                    isLoading={isLoading}
                    type={"submit"}
                >
                    <Trans id="loginApp.employee.signIn.requestCodeStep.buttonLabel">Send magic code</Trans>
                </AtButton>
            </Form>
            <AtContainer className={"info-container"}>
                <Trans id="loginApp.employee.signIn.requestCodeStep.helpText">
                    We'll email you a magic code for a password-free sign in.
                </Trans>
            </AtContainer>
        </div>
    );
});
