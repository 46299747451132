import "./index.less";
import * as React from "react";
import { AppContextInfo } from "../AppContextInfo";
import {
    AppScope,
    ErrorHandler,
    GlobalStores,
    ItemType,
    ModalStore,
    SearchAllCategory,
    SearchAllItemResultModel,
    SearchApi,
    UserInfoStore,
} from "@atman/business";
import { AtButton } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CustomTextInput } from "../CustomTextInput";
import { Dropdown, DropdownMenu, DropdownToggle, Modal } from "reactstrap";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { SearchResultEntry } from "./components/SearchResultEntry";
import { action, autorun, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { itemTypeToSearchAllCategoryMap } from "@atman/business/lib/libs/mappers/SearchAllCategoryToItemType";
import ModalHeader from "reactstrap/lib/ModalHeader";
import SwitchAppModalBody from "./components/SwitchAppModalBody";

export type GlobalSearchFilter = "all" | ItemType;

export interface IAtGlobalSearchProps extends IRoutedAppContext {
    userInfoStore?: UserInfoStore;
    modalStore?: ModalStore;
}

@inject(GlobalStores.userInfoStore, GlobalStores.modalStore)
@observer
class AtGlobalSearchImpl extends BaseForm<IAtGlobalSearchProps, {}> {
    private scopeToItemTypesMapping: Map<AppScope, GlobalSearchFilter[]> = new Map<AppScope, GlobalSearchFilter[]>([
        [AppScope.Client, ["all", ItemType.Candidate, ItemType.Team]],
        [AppScope.Partner, ["all", ItemType.Candidate, ItemType.Team, ItemType.Client]],
        [AppScope.Supplier, ["all", ItemType.Candidate, ItemType.Team, ItemType.Client, ItemType.Partner]],
        [AppScope.Manager, []],
    ]);

    @observable private isOpen: boolean = false;
    @observable private searchString: string = "";
    @observable private typingTimeout?: number;
    @observable private _suggestions: SearchAllItemResultModel[] = [];
    @observable private isAppSwitchModalOpen: boolean = false;

    @observable public currentItemTypeFilter: GlobalSearchFilter = "all";
    @observable public filteredSuggestions: SearchAllItemResultModel[] = [];
    @observable public isLoading: boolean = false;

    constructor(props) {
        super(props);

        autorun(() => {
            this.filteredSuggestions = this._filteredSuggestions;
        });

        reaction(
            () => this.searchString,
            () => this.loadSuggestions(),
        );

        reaction(
            () => this.currentItemTypeFilter,
            () => this.loadSuggestions(),
        );
    }

    @computed private get _filteredSuggestions(): SearchAllItemResultModel[] {
        if (this.currentItemTypeFilter === "all") {
            return this._suggestions;
        }

        return this._suggestions.filter((x) => this.currentItemTypeFilter === x.itemType);
    }

    @action public toggleDropdown = () => {
        this.isOpen = !this.isOpen;
    };

    @action public toggleAppSwitchModal = () => {
        this.isAppSwitchModalOpen = !this.isAppSwitchModalOpen;
        this.toggleDropdown();
    };

    @action private loadSuggestions = () => {
        if (!this.searchString) {
            this._suggestions = [];
            return;
        }

        this.isLoading = true;

        try {
            // @Tom debounce here
            if (this.typingTimeout) {
                window.clearTimeout(this.typingTimeout);
            }

            this.typingTimeout = window.setTimeout(async () => {
                let searchCategoryFilters: SearchAllCategory[] | undefined = undefined;

                if (this.currentItemTypeFilter !== undefined && this.currentItemTypeFilter !== "all") {
                    const searchCategoryFilterValue = itemTypeToSearchAllCategoryMap.get(this.currentItemTypeFilter);

                    if (searchCategoryFilterValue) {
                        searchCategoryFilters = [searchCategoryFilterValue];
                    }
                }

                const result = await SearchApi.searchAllItems(this.searchString, searchCategoryFilters);

                runInAction(() => {
                    this._suggestions = result;
                    this.isLoading = true;
                });
            }, 300);
        } catch (e) {
            this.isLoading = false;

            ErrorHandler.handleError(e);
        }
    };

    @action private onFilterChange = (e: React.SyntheticEvent<HTMLDivElement>, filterValue: GlobalSearchFilter) => {
        e.preventDefault();

        this.currentItemTypeFilter = filterValue;
    };

    render() {
        const { scope, userInfoStore } = this.props;

        return (
            <div id="AtGlobalSearch">
                <Dropdown isOpen={this.isOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle tag={"span"} data-toggle={"dropdown"} aria-expanded={this.isOpen}>
                        <AtButton size={"md"} icon={["far", "search"]} color={"secondary"} />
                    </DropdownToggle>
                    <DropdownMenu className={"menu-container"}>
                        <div className="header">
                            {userInfoStore!.hasAccessToMultipleApps && (
                                <div className="app-switch-container">
                                    <div className="left-container">
                                        <AppContextInfo />
                                    </div>
                                    <div className="right-container">
                                        <AtButton
                                            color={"ghost"}
                                            icon={["far", "exchange"]}
                                            size={"md"}
                                            onClick={this.toggleAppSwitchModal}
                                        >
                                            {"global.switchApp".localize()}
                                        </AtButton>
                                        <Modal
                                            isOpen={this.isAppSwitchModalOpen}
                                            toggle={this.toggleAppSwitchModal}
                                            modalClassName={"centered-modal"}
                                        >
                                            <ModalHeader toggle={this.toggleAppSwitchModal}>
                                                {"global.switchApplication".localize()}
                                            </ModalHeader>
                                            <SwitchAppModalBody />
                                        </Modal>
                                    </div>
                                </div>
                            )}
                            <div className="search-input-container">
                                <CustomTextInput
                                    autoFocus
                                    leftIcon={["far", "search"]}
                                    fieldName={"searchString"}
                                    value={this.searchString}
                                    onChange={this.onTextFieldChange}
                                    placeholder={`${"global.searchIn".localize()} ${
                                        userInfoStore!.currentAppContext.name
                                    }`}
                                    hideLabel
                                />
                            </div>
                            <div className="filters-container">
                                {(this.scopeToItemTypesMapping.get(scope) ?? ["all"]).map((x) => (
                                    <div
                                        className={"filter-element"}
                                        onClick={(e) => this.onFilterChange(e, x)}
                                        key={x}
                                    >
                                        {x === "all"
                                            ? "global.all".localize()
                                            : `global.itemTypes.${ItemType[x].toCamel()}`.localize()}
                                        <div
                                            className={`active-indicator ${
                                                this.currentItemTypeFilter === x ? "active" : ""
                                            }`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className={`results-container ${
                                userInfoStore!.hasAccessToMultipleApps ? "has-account-switcher" : ""
                            }`}
                        >
                            {this._filteredSuggestions.map((x) => (
                                <SearchResultEntry item={x} key={x.id} toggleDropdown={this.toggleDropdown} />
                            ))}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

export const AtGlobalSearch = withRoutedAppContext(AtGlobalSearchImpl);
