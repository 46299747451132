import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { IBaseReactComponentProps } from "@atman/business";
import { observer } from "mobx-react";

export interface ILegendSectionProps extends IBaseReactComponentProps {
    hideTitle?: boolean;
    title?: string;
    className?: string;
}

export const LegendSection: React.FC<ILegendSectionProps> = observer((props) => {
    const { title, className, children, hideTitle = false } = props;

    let legendSectionClassName = "LegendSection";

    if (className) {
        legendSectionClassName += ` ${className}`;
    }

    return (
        <div className={legendSectionClassName}>
            {!hideTitle && <AtTitle className="label" headingType={5} title={title} />}
            <div className="items-container">{children}</div>
        </div>
    );
});
