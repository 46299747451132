import "./index.less";
import {
    AtContainer,
    AtTitleBadge,
    IconTitleSubtitle,
    LabelValue,
    Sizing,
    ValueColorRenderMode,
} from "../../../internal";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React from "react";

export type LabelValueItem = {
    title: string;
    value?: number;
    badgeText?: string;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
};

export interface ILabelValueContainerProps {
    className?: string;
    iconProps: FontAwesomeIconProps;
    title: string;
    items: LabelValueItem[];
    limit?: number;
    sortDirection?: "asc" | "desc";
}

export const LabelValueContainer: React.FC<ILabelValueContainerProps> = ({
    className,
    title,
    iconProps,
    items,
    limit,
    sortDirection,
    ...otherProps
}) => {
    const sortedAndLimitedItems = React.useMemo(() => {
        let sortedItems: LabelValueItem[] = items;

        if (sortDirection) {
            sortedItems =
                sortDirection === "asc"
                    ? items.sort((a, b) => {
                          if (a.value === undefined) return 1;
                          if (b.value === undefined) return -1;
                          return a.value < b.value ? -1 : 1;
                      })
                    : items.sort((a, b) => {
                          if (a.value === undefined) return -1;
                          if (b.value === undefined) return 1;
                          return a.value > b.value ? -1 : 1;
                      });
        }

        return limit ? sortedItems.slice(0, limit) : sortedItems;
    }, [items, limit, sortDirection]);

    return (
        <AtContainer
            header={<IconTitleSubtitle icon={<FontAwesomeIcon size="1x" {...iconProps} />} title={title} />}
            className={`LabelValueContainer ${className ?? ""}`.trim()}
            padding={[Sizing.pixels.sm, Sizing.pixels.md]}
            {...otherProps}
        >
            {sortedAndLimitedItems.map((item, i) => {
                return (
                    <LabelValue
                        key={i}
                        title={item.title}
                        value={getRoundedScore(item.value)}
                        renderMode={ValueColorRenderMode.Background}
                        textColor={item.textColor}
                        backgroundColor={item.backgroundColor}
                        borderColor={item.borderColor}
                        customElement={
                            item.badgeText ? (
                                <AtTitleBadge titleProps={{ title: item.title }}>{item.badgeText}</AtTitleBadge>
                            ) : undefined
                        }
                    />
                );
            })}
        </AtContainer>
    );
};

// TODO Simon 2021-07-23: Remplace this with the exact same function that resides in core
const getRoundedScore = (score: number | null | undefined): string | undefined => {
    if (score === undefined || score === null || isNaN(score)) {
        return "-";
    }

    return score.toFixed(1);
};
