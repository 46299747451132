import "./index.less";
import { AtParagraph, AtSwitch, DefaultComponentProps } from "@atman/design-system";
import { observer } from "mobx-react";
import React from "react";

export interface IShowScoreToggleProps extends DefaultComponentProps {
    isToggled: boolean;
    onHandleToggle: () => void;
    label: string;
}

export const ShowScoreToggle: React.FC<IShowScoreToggleProps> = observer((props: IShowScoreToggleProps) => {
    const { isToggled, onHandleToggle, label, ...otherProps } = props;

    return (
        <div className="ShowScoreToggle" {...otherProps}>
            <div className="sidePanelSort" onClick={onHandleToggle}>
                <AtParagraph weight="semibold">{label}</AtParagraph>
                <AtSwitch checked={isToggled} onChange={() => null} {...{ bsSize: "lg" }} />
            </div>
        </div>
    );
});
