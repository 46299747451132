import * as Platform from "platform";
import { Browser } from "../types";

export class BrowserHelper {
    public static get browser(): Browser | undefined {
        if (!Platform.name) {
            return undefined;
        }

        return Browser[Platform.name];
    }

    public static getBrowserInfo() {
        return {
            name: Platform.name,
            version: this.shortenBrowserVersion(),
        };
    }

    public static getBrowserName() {
        return Platform.name;
    }

    public static isBrowser(browser: Browser) {
        return Platform.name === this.parseBrowserName(browser);
    }

    public static isInternetExplorerVersion(version: number) {
        if (!this.isBrowser(Browser.IE)) {
            return false;
        }

        return this.shortenBrowserVersion() === version;
    }

    public static isSafariVersion(version: number) {
        if (!this.isBrowser(Browser.Safari)) {
            return false;
        }

        return this.shortenBrowserVersion() === version;
    }

    public static isBrowserAtVersion(browser: Browser, version: number) {
        if (!this.isBrowser(browser)) {
            return false;
        }

        return this.shortenBrowserVersion() === version;
    }

    private static parseBrowserName(browser: Browser) {
        if (browser === Browser.IE) {
            return "IE";
        }

        return Browser[browser].toTitle();
    }

    private static shortenBrowserVersion() {
        if (String(Platform.version).indexOf(".") > -1) {
            return Number(String(Platform.version).split(".")[0]);
        }

        return Number(Platform.version);
    }
}
