import "./index.less";
import * as React from "react";
import {
    AssessmentStructure,
    CompDevCompetencyResult,
    CompetencyDevelopmentProcessResultsData,
    CompetencySource,
    ISelectedObjectiveData,
    PrintModeEnum,
    SelectedSubCompetencyToImproveData,
} from "@atman/business";
import { AtButton, AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import {
    CompDevTableDataType,
    CompDevTableDisplayType,
    CompetencyDevelopmentColumnType,
    IPersonInfo,
    SubCompetencyRowData,
    isColumnDisplayed,
    resultTableColumnOptions,
} from ".";
import { CompetencyObjectivesResultTable } from "./components/ResultTable/CompetencyObjectivesResultTable";
import { DetailedCompetencyResultTable } from "./components/ResultTable/DetailedCompetencyResultTable";
import { DetailedSubCompetencyResultTable } from "./components/ResultTable/DetailedSubCompetencyResultTable";
import { ImproveDevelopmentPlanCompetencyResultTable } from "./components/ResultTable/ImproveDevelopmentPlanCompetencyResultTable";
import { LeverageDevelopmentPlanCompetencyResultTable } from "./components/ResultTable/LeverageDevelopmentPlanCompetencyResultTable";
import { LikertLegendSection, SortDirection } from "../..";
import { SummaryCompetencyResultTable } from "./components/ResultTable/SummaryCompetencyResultTable";
import { getSortedAndFilteredData } from "./logic";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useDetectDevice } from "@atman/core";
import { useMemo } from "react";

type ObjectivesDataType<TDisplayType extends CompDevTableDisplayType> = TDisplayType extends
    | "developmentPlanLeverage"
    | "leverageObjectives"
    ? Map<string, Map<string, ISelectedObjectiveData>>
    : TDisplayType extends "developmentPlanImprovement" | "improvementObjectives"
    ? Map<string, Map<string, SelectedSubCompetencyToImproveData>>
    : never;

export interface IResultTableContainerProps<TDisplayType extends CompDevTableDisplayType> {
    assessmentStructure?: AssessmentStructure;
    processResultsData: CompetencyDevelopmentProcessResultsData;
    dataType: CompDevTableDataType;
    displayType: CompDevTableDisplayType;
    columns: CompetencyDevelopmentColumnType[];
    managerInfo?: IPersonInfo;
    employeeInfo?: IPersonInfo;
    isReadOnly?: boolean;
    showSourceFilter?: boolean;
    showColumnFilter?: boolean;
    showLegend?: boolean;
    showRank?: boolean;
    printMode?: PrintModeEnum;
    selectedElements?: ObjectivesDataType<TDisplayType>;
    erroredElements?: Map<string, string[]>;
    saveAssessmentFunction?: (
        competencyId: string,
        observableBehaviorScores: Map<string, number | undefined>,
        observableBehaviorNotes: Map<string, string | undefined>,
    ) => Promise<void>;
    onSelectedElementsChange?: (selectedElements: ObjectivesDataType<TDisplayType>) => void;
}

export function ResultTableContainerImpl<TDisplayType extends CompDevTableDisplayType>(
    props: IResultTableContainerProps<TDisplayType>,
) {
    const {
        assessmentStructure,
        processResultsData,
        selectedElements,
        dataType,
        displayType,
        managerInfo,
        employeeInfo,
        columns,
        isReadOnly = false,
        showSourceFilter = true,
        showColumnFilter = true,
        showLegend = true,
        showRank = true,
        printMode,
        erroredElements,
        saveAssessmentFunction,
        onSelectedElementsChange,
    } = props;

    const [isLegendExpanded, setIsLegendExpanded] = React.useState<boolean>(false);
    const [sortElement, setSortElement] = React.useState<CompetencyDevelopmentColumnType>("competencyName");
    const [sortDirection, setSortDirection] = React.useState<SortDirection>("asc");
    const [hiddenColumns, setHiddenColumns] = React.useState<
        IReactSelectOptionObject<CompetencyDevelopmentColumnType>[]
    >([]);

    const columnOptions: IReactSelectOptionObject<CompetencyDevelopmentColumnType>[] = React.useMemo(() => {
        return resultTableColumnOptions
            .filter((x) => columns.includes(x.value) && x.value !== "competencyName")
            .map((x) => ({ value: x.value, label: t({ id: x.labelKey }) }));
    }, [columns, resultTableColumnOptions]);

    const sourceFilterOptions: IReactSelectOptionObject<CompetencySource>[] = React.useMemo(() => {
        return [
            ...processResultsData.competencies
                .reduce((p, c) => [...p, ...c.competencySources], [])
                .distinct()
                .map((x) => ({
                    value: x,
                    label: `competencyDevelopment.competencySource.${CompetencySource[x].toCamel()}`.localize(),
                })),
        ];
    }, [processResultsData.competencies]);

    const [sourceFilters, setSourceFilters] =
        React.useState<IReactSelectOptionObject<CompetencySource>[]>(sourceFilterOptions);

    const sortedAndFilteredData = useMemo(
        () =>
            getSortedAndFilteredData(
                dataType,
                displayType,
                sortElement,
                processResultsData,
                sourceFilters,
                sortDirection,
            ),
        [dataType, displayType, sortElement, processResultsData, sourceFilters, sortDirection],
    );

    const hiddenColumnValues: CompetencyDevelopmentColumnType[] | undefined = React.useMemo(
        () => hiddenColumns?.map((x) => x.value),
        [hiddenColumns],
    );

    const toggleSortDirection = () => {
        if (sortDirection === "asc") {
            setSortDirection("desc");
        } else {
            setSortDirection("asc");
        }
    };

    const onSortChange = (element: CompetencyDevelopmentColumnType) => {
        if (sortElement === element) {
            toggleSortDirection();
        } else {
            setSortElement(element);
            setSortDirection("asc");
        }
    };

    const onSourceFiltersChange = (source: IReactSelectOptionObject<CompetencySource>) => {
        if (sourceFilters.some((x) => x.value === source.value)) {
            setSourceFilters(sourceFilters.filter((x) => x.value !== source.value));
        } else {
            setSourceFilters([...sourceFilters, source]);
        }
    };

    const toggleLegendExpansion = () => {
        setIsLegendExpanded(!isLegendExpanded);
    };

    const onHiddenColumnsChange = (column: IReactSelectOptionObject<CompetencyDevelopmentColumnType>) => {
        if (hiddenColumns.some((x) => x.value === column.value)) {
            setHiddenColumns(hiddenColumns.filter((x) => x.value !== column.value));
        } else {
            setHiddenColumns([...hiddenColumns, column]);
        }
    };

    const { isMobile } = useDetectDevice();

    return (
        <div className="ResultTableContainer">
            {!isMobile && (showSourceFilter || showLegend || showColumnFilter) && (
                <div className="table-filter-header">
                    <div className="table-filter-header-row">
                        {(showSourceFilter || showLegend) && (
                            <div className="table-filter-header-col">
                                {showSourceFilter && (
                                    <AtSelect
                                        options={sourceFilterOptions}
                                        value={sourceFilterOptions.filter((x) =>
                                            sourceFilters.some((y) => x.value === y.value),
                                        )}
                                        name="source-filter"
                                        onChange={onSourceFiltersChange}
                                        closeMenuOnSelect={false}
                                        placeholder={t({ id: "global.general.source", message: "Source" })}
                                        size="md"
                                    />
                                )}
                            </div>
                        )}

                        <div className="table-filter-header-col">
                            {showLegend && (
                                <AtButton color={"secondary"} onClick={toggleLegendExpansion}>
                                    {isLegendExpanded
                                        ? "global.legend.close".localize()
                                        : "global.legend.open".localize()}
                                </AtButton>
                            )}
                            {showColumnFilter && columnOptions.length > 0 && (
                                <AtSelect
                                    options={columnOptions}
                                    value={columnOptions.filter((x) =>
                                        isColumnDisplayed(x.value, columns, hiddenColumnValues),
                                    )}
                                    name="column-filter"
                                    onChange={onHiddenColumnsChange}
                                    closeMenuOnSelect={false}
                                    placeholder={t({ id: "global.general.column", message: "Column" })}
                                    size="md"
                                />
                            )}
                        </div>
                    </div>
                    {isLegendExpanded && (
                        <div className="table-filter-header-row">
                            <div className="table-filter-header-col">
                                <LikertLegendSection hideTitle />
                            </div>
                        </div>
                    )}
                </div>
            )}
            {dataType === "competency" && displayType === "detailed" && managerInfo && employeeInfo && (
                <DetailedCompetencyResultTable
                    assessmentStructure={assessmentStructure}
                    processResultsData={processResultsData}
                    competencies={sortedAndFilteredData as CompDevCompetencyResult[]}
                    columns={columns}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    isReadOnly={isReadOnly}
                    showRank={showRank}
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    hiddenColumns={hiddenColumnValues}
                    onSortChange={onSortChange}
                    saveAssessmentFunction={saveAssessmentFunction}
                />
            )}
            {dataType === "competency" && displayType === "summary" && (
                <SummaryCompetencyResultTable
                    competencies={sortedAndFilteredData as CompDevCompetencyResult[]}
                    columns={columns}
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    printMode={printMode}
                />
            )}
            {dataType === "competency" &&
                displayType === "developmentPlanLeverage" &&
                managerInfo &&
                employeeInfo &&
                onSelectedElementsChange &&
                selectedElements && (
                    <LeverageDevelopmentPlanCompetencyResultTable
                        competencies={sortedAndFilteredData as CompDevCompetencyResult[]}
                        selectedElements={selectedElements as ObjectivesDataType<"developmentPlanLeverage">}
                        columns={columns}
                        displayType={displayType}
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        sortElement={sortElement}
                        sortDirection={sortDirection}
                        hiddenColumns={hiddenColumnValues}
                        erroredElements={erroredElements}
                        onSortChange={onSortChange}
                        onSelectedElementsChange={
                            onSelectedElementsChange as (
                                selectedElements: ObjectivesDataType<"developmentPlanLeverage">,
                            ) => void
                        }
                    />
                )}
            {dataType === "competency" &&
                displayType === "developmentPlanImprovement" &&
                managerInfo &&
                employeeInfo &&
                onSelectedElementsChange &&
                selectedElements && (
                    <ImproveDevelopmentPlanCompetencyResultTable
                        competencies={sortedAndFilteredData as CompDevCompetencyResult[]}
                        selectedElements={selectedElements as ObjectivesDataType<"developmentPlanImprovement">}
                        columns={columns}
                        displayType={displayType}
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        sortElement={sortElement}
                        sortDirection={sortDirection}
                        hiddenColumns={hiddenColumnValues}
                        erroredElements={erroredElements}
                        onSortChange={onSortChange}
                        onSelectedElementsChange={
                            onSelectedElementsChange as (
                                selectedElements: ObjectivesDataType<"developmentPlanImprovement">,
                            ) => void
                        }
                    />
                )}
            {dataType === "competency" &&
                (displayType === "improvementObjectives" || displayType === "leverageObjectives") && (
                    <CompetencyObjectivesResultTable
                        competencies={sortedAndFilteredData as CompDevCompetencyResult[]}
                        columns={columns}
                        displayType={displayType}
                        sortElement={sortElement}
                        sortDirection={sortDirection}
                        onSortChange={onSortChange}
                    />
                )}
            {dataType === "subCompetency" && displayType === "detailed" && managerInfo && employeeInfo && (
                <DetailedSubCompetencyResultTable
                    subCompetencies={sortedAndFilteredData as SubCompetencyRowData[]}
                    columns={columns}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    hiddenColumns={hiddenColumnValues}
                    onSortChange={onSortChange}
                />
            )}
        </div>
    );
}

export const ResultTableContainer = observer(ResultTableContainerImpl);

export * from "./logic";
export * from "./components/ResultTable";
