import "./index.less";
import { AtContainer, AtIconTooltip, AtTitle } from "@atman/design-system";
import { Collapse } from "reactstrap";
import { CompDevCompetency, CompDevCompetencyGroup } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirection } from "../../../";
import { observer } from "mobx-react";
import { plural, t } from "@lingui/macro";
import CompetencyEntryInput from "../CompetencyEntryInput";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export interface ICompetencyBadge {
    competencyId: string;
    badge: string;
    className?: string;
}

export interface ICompetencyGroupFormSectionProps {
    competencyGroup: CompDevCompetencyGroup;
    filteredCompetenciesForGroup: CompDevCompetency[];
    isSearching: boolean;
    selectedCompetencyIds: string[];
    onCompetencyIdSelection: (id: string) => void;
    suggestedCompetencyIds?: string[];
    expandOnMount?: boolean;
    competencyBadges?: ICompetencyBadge[];
    className?: string;
}

export const CompetencyGroupFormSection: React.FC<ICompetencyGroupFormSectionProps> = observer((props) => {
    const {
        competencyGroup,
        selectedCompetencyIds,
        onCompetencyIdSelection,
        suggestedCompetencyIds,
        expandOnMount,
        competencyBadges,
        filteredCompetenciesForGroup,
        isSearching,
        className,
    } = props;

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    const openDropdown = () => {
        setDropdownIsOpen(true);
    };

    const numberOfSelectedCompetenciesForGroup = useMemo(() => {
        return filteredCompetenciesForGroup.filter((x) => selectedCompetencyIds.includes(x.id)).length;
    }, [filteredCompetenciesForGroup, selectedCompetencyIds]);

    useEffect(() => {
        if (expandOnMount || numberOfSelectedCompetenciesForGroup > 0) {
            openDropdown();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedCompetenciesForGroup: CompDevCompetency[] = useMemo(
        () => sortArrayByStringProp(filteredCompetenciesForGroup, (x) => x.name),
        [filteredCompetenciesForGroup],
    );
    const toggleDropdown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    const competencyGroupAsString = `global.competencyGroups.${CompDevCompetencyGroup[competencyGroup].toCamel()}`;

    const count = numberOfSelectedCompetenciesForGroup;

    const renderCompetencyEntryInputs = () => {
        return sortedCompetenciesForGroup.map((x) => {
            const customBadges: string[] | undefined =
                competencyBadges && competencyBadges.filter((b) => b.competencyId === x.id).map((b) => b.badge);

            return (
                <CompetencyEntryInput
                    key={x.id}
                    competency={x}
                    isSelected={selectedCompetencyIds.includes(x.id)}
                    isSuggested={suggestedCompetencyIds?.includes(x.id) ?? false}
                    onCompetencyIdSelection={onCompetencyIdSelection}
                    customBadges={customBadges}
                    displayCategory={isSearching}
                />
            );
        });
    };

    if (isSearching) {
        return <>{renderCompetencyEntryInputs()}</>;
    }

    return (
        <AtContainer className={cn("CompetencyGroupFormSection", className)}>
            <div className="group-header" onClick={toggleDropdown}>
                <div className="left-section">
                    <div className="title-container">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={["far", dropdownIsOpen ? "chevron-up" : "chevron-down"]} />
                        </div>
                        <AtTitle headingType={4} title={competencyGroupAsString.localize()} noMargin />
                        <AtIconTooltip
                            icon={"question-circle"}
                            tooltipTargetId={`competency-group-desc-${CompDevCompetencyGroup[
                                competencyGroup
                            ].toCamel()}`}
                            tooltipProps={{ placement: "right" }}
                            iconSize="sm"
                            tooltipDisplayMode="dark"
                        >
                            <div className="description">{`${competencyGroupAsString}.description`.localize()}</div>
                        </AtIconTooltip>
                    </div>
                </div>
                <div className="right-section">
                    <div className="selected-competencies-count">
                        {t({ id: "global.competenciesSelected", message: plural(count, {}), values: { count } })}
                    </div>
                </div>
            </div>
            <Collapse className="collapse-container" isOpen={dropdownIsOpen}>
                {renderCompetencyEntryInputs()}
            </Collapse>
        </AtContainer>
    );
});

// TODO Simon 2021-07-29: Move to a generic utility class.
function sortArrayByStringProp<T>(array: T[], keySelector: (item: T) => string, order: SortDirection = "asc") {
    return array.sort((a: T, b: T) => {
        const varA = keySelector(a).toUpperCase();
        const varB = keySelector(b).toUpperCase();

        const comparison = varA.localeCompare(varB);

        return order === "desc" ? comparison * -1 : comparison;
    });
}
