import { SearchPlaceHolder } from "../SearchPlaceHolder";
import { ShowScoreToggle } from "../ShowScoreToggle";
import { SidePanelItemDefinition } from "../../../../../SidePanelElement/SidePanelStructure";
import { SidePanelNavigation } from "../../../SidePanelNavigation";
import { SidePanelSearch } from "../../../SidePanelSearch";
import { SidePanelSearchType, SlidingDirection } from "../../index";
import { SidePanelSorter } from "../SidePanelSorter";
import { SidePanelSubElementsRenderer } from "../SidePanelSubElementsRenderer";
import { SortDirection } from "../../../../../BaseComponent";
import { getSubElementsWithSearchApplied } from "../utils/getSubElementsWithSearchApplied";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useDebounce } from "@atman/core";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export interface ISidePanelRendererNoTabsProps {
    sidePanelToRender: SidePanelItemDefinition;
    appliedFilter: string | undefined;
    setAppliedFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    sortDirection: SortDirection;
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
    sortType: SidePanelSearchType;
    setSortType: React.Dispatch<React.SetStateAction<SidePanelSearchType>>;
    activeSidePanel: SidePanelItemDefinition;
    handlePanelChangeWithAnimation: (activeSidePanel: SidePanelItemDefinition) => void;
    slidingDirection: SlidingDirection;
    isBackButtonDisabled: boolean;
    handleBackButtonNavigation: () => void;
    externalSideElementHighlightId?: string;
}

export const SidePanelRendererNoTabs: React.FC<ISidePanelRendererNoTabsProps> = observer(
    (props: ISidePanelRendererNoTabsProps) => {
        const {
            sidePanelToRender,
            appliedFilter,
            setAppliedFilter,
            sortDirection,
            setSortDirection,
            sortType,
            setSortType,
            activeSidePanel,
            handlePanelChangeWithAnimation,
            slidingDirection,
            isBackButtonDisabled,
            handleBackButtonNavigation,
            externalSideElementHighlightId,
            ...otherProps
        } = props;

        const completeSubElementsList = useMemo(
            () =>
                sidePanelToRender.customBackendSearch && sidePanelToRender.subElements.size === 0
                    ? []
                    : [...sidePanelToRender.subElements.values()][0],
            [sidePanelToRender.customBackendSearch, sidePanelToRender.subElements],
        );

        const [elements, setElements] = useState<SidePanelItemDefinition[]>();
        const [isCustomRightSidePanelDisplayed, setIsCustomRightSidePanelDisplayed] = useState<boolean>(false);

        const debouncedSearchTerm: string | undefined = useDebounce<string | undefined>(
            appliedFilter ?? undefined,
            500,
        );

        const handleCustomRightToggle = () => setIsCustomRightSidePanelDisplayed(!isCustomRightSidePanelDisplayed);

        useEffect(() => {
            const fetchData = async () => {
                const subElementsFound = await getSubElementsWithSearchApplied(
                    completeSubElementsList,
                    debouncedSearchTerm,
                    sortDirection,
                    sortType,
                    sidePanelToRender,
                );

                setElements(subElementsFound);
            };

            if (sidePanelToRender.customBackendSearch && !appliedFilter) {
                setElements([]);
            } else {
                fetchData().catch(console.error);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            debouncedSearchTerm,
            sidePanelToRender.backendSearch,
            sidePanelToRender.customBackendSearch,
            sortDirection,
            appliedFilter,
        ]);

        const displaySearchPlaceHolder = !appliedFilter && sidePanelToRender.customBackendSearch;

        return (
            <div className="SidePanelContainer" {...otherProps}>
                <div
                    className={cn("animate-wrapper", "animate__animated", {
                        animate__fadeInRight: slidingDirection === "forward",
                        animate__fadeInLeft: slidingDirection === "backwards",
                    })}
                >
                    <SidePanelNavigation
                        activeSidePanel={sidePanelToRender}
                        isBackButtonDisabled={isBackButtonDisabled}
                        handleBackButton={handleBackButtonNavigation}
                    />

                    <div className="sidePanelElementWrapper">
                        {sidePanelToRender.isSearchable && (
                            <SidePanelSearch
                                appliedFilter={appliedFilter}
                                setAppliedFilter={setAppliedFilter}
                                placeholder={t({ id: "clientApp.sidePanelSearch.placeholder" })}
                            />
                        )}

                        {displaySearchPlaceHolder && <SearchPlaceHolder />}

                        {!displaySearchPlaceHolder && sidePanelToRender.customRightToggleLabel && (
                            <ShowScoreToggle
                                isToggled={isCustomRightSidePanelDisplayed}
                                onHandleToggle={handleCustomRightToggle}
                                label={sidePanelToRender.customRightToggleLabel}
                            />
                        )}

                        <div className="sorters">
                            {!displaySearchPlaceHolder && sidePanelToRender.sort.displaySort && (
                                <SidePanelSorter
                                    sortLabel={sidePanelToRender.sort.sortLabel!}
                                    setSortDirection={setSortDirection}
                                    sortDirection={sortDirection}
                                    sortType={SidePanelSearchType.Primary}
                                    appliedSortType={sortType}
                                    setSortType={setSortType}
                                />
                            )}

                            {!displaySearchPlaceHolder &&
                                sidePanelToRender.secondarySort.sortLabel &&
                                isCustomRightSidePanelDisplayed && (
                                    <SidePanelSorter
                                        sortLabel={sidePanelToRender.secondarySort.sortLabel!}
                                        setSortDirection={setSortDirection}
                                        sortDirection={sortDirection}
                                        sortType={SidePanelSearchType.Secondary}
                                        appliedSortType={sortType}
                                        setSortType={setSortType}
                                    />
                                )}
                        </div>

                        {elements && (
                            <SidePanelSubElementsRenderer
                                isLoading={sidePanelToRender.isLoading}
                                subElements={elements}
                                activeSidePanelId={activeSidePanel.id}
                                handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                                isCustomRightSidePanelDisplayed={isCustomRightSidePanelDisplayed}
                                externalSideElementHighlightId={externalSideElementHighlightId}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    },
);
