import "../index.less";
import "./index.less";
import * as React from "react";
import { ApplicationsLearningResourcesContainer, SortDirection } from "../../../../..";
import { CompDevApplication, CompDevCompetencyResult, CompDevLearningResource } from "@atman/business";
import {
    CompDevTableDisplayType,
    CompetencyDevelopmentColumnType,
    ObjectiveSubRow,
    TableHeaderRow,
    TableRow,
} from "../../..";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface ICompetencyObjectivesResultTableProps {
    competencies: CompDevCompetencyResult[];
    columns: CompetencyDevelopmentColumnType[];
    displayType: CompDevTableDisplayType;
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
}

export const CompetencyObjectivesResultTable: React.FC<ICompetencyObjectivesResultTableProps> = observer((props) => {
    const { competencies, columns, displayType, sortElement, sortDirection, onSortChange } = props;

    const { isMobile } = useDetectDevice();

    const renderImprovementContent = (comp: CompDevCompetencyResult) => {
        return (
            <div>
                {comp.subCompetencies
                    .filter((subCompetency) => subCompetency.isSelected)!
                    .map((sub) => {
                        const applications: CompDevApplication[] = sub.applications.filter((x) =>
                            sub.selectedApplicationIds.includes(x.id),
                        );
                        const learningResources: CompDevLearningResource[] = sub.learningResources.filter((x) =>
                            sub.selectedLearningResourceIds.includes(x.id),
                        );

                        return (
                            <ObjectiveSubRow objective={sub} key={sub.id} columns={columns}>
                                <ApplicationsLearningResourcesContainer
                                    title={sub.strategicObjectives[0].description}
                                    context={"improve"}
                                    parentId={sub.id}
                                    applications={applications}
                                    learningResources={learningResources}
                                    applicationNotes={sub.applicationNotes}
                                    learningResourceNotes={sub.learningResourceNotes}
                                />
                            </ObjectiveSubRow>
                        );
                    })}
            </div>
        );
    };

    const renderLeverageContent = (comp: CompDevCompetencyResult) => {
        return (
            <div>
                {comp.strategicObjectives
                    .filter((x) => x.isSelected)!
                    .map((obj) => {
                        const applications: CompDevApplication[] = obj.applications.filter((x) =>
                            obj.selectedApplicationIds.includes(x.id),
                        );
                        const learningResources: CompDevLearningResource[] = obj.learningResources.filter((x) =>
                            obj.selectedLearningResourceIds.includes(x.id),
                        );

                        return (
                            <ObjectiveSubRow objective={obj} key={obj.id} columns={columns}>
                                <ApplicationsLearningResourcesContainer
                                    title={obj.description}
                                    context={"leverage"}
                                    parentId={obj.id}
                                    applications={applications}
                                    learningResources={learningResources}
                                    applicationNotes={obj.applicationNotes}
                                    learningResourceNotes={obj.learningResourceNotes}
                                />
                            </ObjectiveSubRow>
                        );
                    })}
            </div>
        );
    };
    return (
        <div className="ResultTable CompetencyObjectivesResultTable">
            {!isMobile && (
                <TableHeaderRow
                    columns={columns}
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                />
            )}
            {competencies.map((x, i) => (
                <TableRow
                    dataRow={x}
                    columns={columns}
                    showRank={false}
                    expandOnMount={i === 0}
                    key={x.id}
                    collapseContentOffset={true}
                >
                    {displayType === "improvementObjectives" ? renderImprovementContent(x) : renderLeverageContent(x)}
                </TableRow>
            ))}
        </div>
    );
});
