import "./index.less";
import * as React from "react";
import {
    AtButton,
    AtSelect,
    AtTitle,
    IAtButtonProps,
    IAtSelectProps,
    IReactSelectOptionObject,
    LoadingIndicator,
} from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAtSelectButtonProps<
    TOption extends IReactSelectOptionObject<any> = IReactSelectOptionObject<string>,
> {
    icon: IconProp;
    buttonLabel: string;
    selectedValue: TOption | undefined;
    onSelectChange: (option: TOption) => void | Promise<void>;
    selectOptions: TOption[];
    allowEdit?: boolean;
    allowCancel?: boolean;
    hideSelectedValue?: boolean;
    additionalSelectProps?: Partial<IAtSelectProps<TOption>>;
    additionalButtonProps?: Partial<IAtButtonProps>;
    buttonActionOverride?: () => void;
}

@inject()
@observer
export class AtSelectButton<
    TOption extends IReactSelectOptionObject<any> = IReactSelectOptionObject<string>,
> extends React.Component<IAtSelectButtonProps<TOption>, {}> {
    @observable private showSelect: boolean = false;
    @observable private isLoading: boolean = false;

    @action toggleSelect = () => {
        this.showSelect = !this.showSelect;
    };

    renderButton() {
        const { icon, buttonLabel, additionalButtonProps, buttonActionOverride } = this.props;

        return (
            <AtButton {...additionalButtonProps} icon={icon} onClick={buttonActionOverride ?? this.toggleSelect}>
                {buttonLabel}
            </AtButton>
        );
    }

    renderSelect() {
        const { additionalSelectProps, selectOptions, allowCancel = true } = this.props;

        return (
            <>
                <AtSelect
                    {...additionalSelectProps}
                    onChange={this.onSelectChange}
                    value={null}
                    options={selectOptions}
                    additionalStyles={{
                        control: (base) => ({ ...base, minHeight: 32, height: 40, alignItems: "baseline" }),
                    }}
                />
                {allowCancel && (
                    <a className={"primary-link cancel-btn action-btn"} onClick={this.toggleSelect}>
                        {"global.buttons.labels.cancel".localize()}
                    </a>
                )}
            </>
        );
    }

    onSelectChange = async (option: TOption) => {
        const { onSelectChange } = this.props;

        this.isLoading = true;

        await onSelectChange(option);

        runInAction(() => {
            this.isLoading = false;
        });

        this.toggleSelect();
    };

    render() {
        const { selectedValue, icon, allowEdit = true, hideSelectedValue = false } = this.props;

        return (
            <div className="AtSelectButton">
                {!hideSelectedValue &&
                selectedValue?.value !== undefined &&
                selectedValue?.value !== null &&
                !this.showSelect ? (
                    <div className={"value-container"}>
                        <div className="icon-container">
                            <FontAwesomeIcon icon={icon} />
                        </div>
                        <AtTitle headingType={4} title={selectedValue.label} />
                        {allowEdit && (
                            <a className={"primary-link edit-btn action-btn"} onClick={this.toggleSelect}>
                                {"global.edit".localize()}
                            </a>
                        )}
                    </div>
                ) : this.isLoading ? (
                    <LoadingIndicator />
                ) : this.showSelect ? (
                    this.renderSelect()
                ) : (
                    this.renderButton()
                )}
            </div>
        );
    }
}
