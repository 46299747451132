import "./index.less";
import { SidePanelItemDefinition } from "../../../SidePanelElement/SidePanelStructure";
import { SidePanelRendererNoTabs } from "./components/SidePanelRendererNoTabs";
import { SidePanelRendererTopLevelNoChildren } from "./components/SidePanelRendererTopLevelNoChildren";
import { SidePanelRendererWithTabs } from "./components/SidePanelRendererWithTabs";
import { SortDirection } from "../../..";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

export const SINGLE_LINE_SECTION_HEIGHT = 57; // We use that while we are waiting for a solution that won't be based on absolute positioning.

export interface ISidePanelContainerProps {
    activeSidePanel: SidePanelItemDefinition | undefined;
    sidePanelItemDefinitions: SidePanelItemDefinition[];
    handleActivePanelChange: (activeSidePanel?: SidePanelItemDefinition) => void;
    defaultActiveSidePanelId?: string;
    externalSideElementHighlightId?: string;
}

export type SlidingDirection = "forward" | "backwards" | undefined;

export type HandlePanelChangeWithAnimation = (newActiveSidePanel: SidePanelItemDefinition, animate?: boolean) => void;

export enum SidePanelSearchType {
    Primary,
    Secondary,
}

export const SidePanelContainer: React.FC<ISidePanelContainerProps> = observer((props) => {
    const {
        activeSidePanel,
        sidePanelItemDefinitions,
        handleActivePanelChange,
        defaultActiveSidePanelId,
        externalSideElementHighlightId,
    } = props;

    const [slidingDirection, setSlidingDirection] = useState<SlidingDirection>(undefined);
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(true);
    const [sortType, setSortType] = useState<SidePanelSearchType>(SidePanelSearchType.Primary);
    const [sortDirection, setSortDirection] = useState<SortDirection>("asc");
    const [appliedFilter, setAppliedFilter] = useState<string | undefined>();

    const sidePanelToRender: SidePanelItemDefinition | undefined = useMemo(() => {
        if (activeSidePanel?.subElements.size === 0) {
            if (activeSidePanel?.customBackendSearch) {
                return activeSidePanel;
            }

            return activeSidePanel.root ?? activeSidePanel;
        }

        return activeSidePanel;
    }, [activeSidePanel]);

    const resetAnimations = setTimeout(() => {
        setSlidingDirection(undefined);
        setIsBackButtonDisabled(false);
    }, 1100);

    useEffect(() => {
        return () => {
            if (resetAnimations) {
                clearTimeout(resetAnimations);
            }
        };
    }, []);

    const handlePanelChangeWithAnimation: HandlePanelChangeWithAnimation = (
        newActiveSidePanel: SidePanelItemDefinition,
        animate: boolean = true,
    ) => {
        if (newActiveSidePanel.resetSearchOnClick) {
            setAppliedFilter(undefined);
        }

        if ((animate && newActiveSidePanel.subElements.size > 0) || newActiveSidePanel.customBackendSearch) {
            setSlidingDirection("forward");
        }

        handleActivePanelChange(newActiveSidePanel);
    };

    const handleBackButtonNavigation = () => {
        setAppliedFilter(undefined);

        if (sidePanelToRender?.onBackButtonClick) {
            sidePanelToRender.onBackButtonClick();
        }

        if (sidePanelToRender?.root) {
            setSlidingDirection("backwards");
            resetAnimations;
            handleActivePanelChange(sidePanelToRender.root);
            return;
        }

        const defaultSidePanel = sidePanelItemDefinitions.find((x) => x.id === defaultActiveSidePanelId);

        if (defaultSidePanel) {
            setSlidingDirection("backwards");
            resetAnimations;
            handleActivePanelChange(defaultSidePanel);
        } else {
            setSlidingDirection("backwards");
            resetAnimations;
            handleActivePanelChange(undefined);
        }
    };

    const isActiveSidePanelATopLevel = sidePanelToRender?.root === null || !sidePanelToRender;
    const activeSidePanelHasChildren = sidePanelToRender ? sidePanelToRender.subElements.size > 0 : false;

    if (isActiveSidePanelATopLevel && !activeSidePanelHasChildren && !sidePanelToRender?.customBackendSearch) {
        return (
            <SidePanelRendererTopLevelNoChildren
                sidePanelItemDefinitions={sidePanelItemDefinitions}
                slidingDirection={slidingDirection}
                activeSidePanel={activeSidePanel}
                handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                externalSideElementHighlightId={externalSideElementHighlightId}
            />
        );
    }

    if (!activeSidePanel || !sidePanelToRender) {
        return <></>;
    }

    if (sidePanelToRender.subElements.size > 1) {
        return (
            <SidePanelRendererWithTabs
                sidePanelToRender={sidePanelToRender}
                appliedFilter={appliedFilter}
                setAppliedFilter={setAppliedFilter}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortType={sortType}
                setSortType={setSortType}
                activeSidePanel={activeSidePanel}
                handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                slidingDirection={slidingDirection}
                isBackButtonDisabled={isBackButtonDisabled}
                handleBackButtonNavigation={handleBackButtonNavigation}
                externalSideElementHighlightId={externalSideElementHighlightId}
            />
        );
    } else {
        return (
            <SidePanelRendererNoTabs
                sidePanelToRender={sidePanelToRender}
                appliedFilter={appliedFilter}
                setAppliedFilter={setAppliedFilter}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                sortType={sortType}
                setSortType={setSortType}
                activeSidePanel={activeSidePanel}
                handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                slidingDirection={slidingDirection}
                isBackButtonDisabled={isBackButtonDisabled}
                handleBackButtonNavigation={handleBackButtonNavigation}
                externalSideElementHighlightId={externalSideElementHighlightId}
            />
        );
    }
});

export default SidePanelContainer;
