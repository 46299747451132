import "./index.less";
import * as React from "react";
import { CompDevTabHeader } from "../CompDevTabHeader";
import {
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessResultsData,
    PrintModeEnum,
} from "@atman/business";
import { TeamDevelopmentActionPlanObjectivesSectionImprove } from "./components/TeamDevelopmentActionPlanObjectivesSectionImprove";
import { TeamDevelopmentActionPlanObjectivesSectionLeverage } from "./components/TeamDevelopmentActionPlanObjectivesSectionLeverage";
import { observer } from "mobx-react";

export interface ITeamDevelopmentActionPlanProps {
    candidateProcessData?: CompetencyDevelopmentProcessData;
    candidateProcessResultsData: CompetencyDevelopmentProcessResultsData;
    printMode?: PrintModeEnum;
    openAndMonitorTabFunc?: (url: string) => void;
}

export const TeamDevelopmentActionPlan: React.FC<ITeamDevelopmentActionPlanProps> = observer((props) => {
    const { candidateProcessData, candidateProcessResultsData, printMode, openAndMonitorTabFunc } = props;

    const competenciesToLeverageAssessment = candidateProcessData?.assessmentsData.get(
        CompetencyDevelopmentAssessmentType.CompetenciesToLeverage,
    );
    const competenciesToImproveAssessment = candidateProcessData?.assessmentsData.get(
        CompetencyDevelopmentAssessmentType.CompetenciesToImprove,
    );

    const countCompetencies =
        (candidateProcessResultsData.getCompetenciesToImprove() || []).length +
            (candidateProcessResultsData.getCompetenciesToLeverage() || []).length || 0;

    const countObjectives =
        (candidateProcessResultsData.getObjectivesToImprove() || []).length +
            (candidateProcessResultsData.getCompetenciesToLeverage() || []).length || 0;

    const countApplications = candidateProcessResultsData.getObjectivesToImprove().reduce((accumulator, current) => {
        return accumulator + current.data.applications.length;
    }, candidateProcessResultsData.getApplicationsToLeverage().length);

    return (
        <div id="TeamDevelopmentActionPlan">
            {printMode === undefined && (
                <CompDevTabHeader
                    title={"competencyDevelopment.actionPlan".localize()}
                    // TODO check if need to conform with new figma
                    // subTitle={"competencyDevelopment.actionPlan.description".localize()}
                    countCompetencies={countCompetencies}
                    countObjectives={countObjectives}
                    countApplications={countApplications}
                />
            )}

            <TeamDevelopmentActionPlanObjectivesSectionLeverage
                candidateProcessResultsData={candidateProcessResultsData}
                processAssessmentData={competenciesToLeverageAssessment}
                openAndMonitorTabFunc={openAndMonitorTabFunc}
                printMode={printMode}
            />
            <TeamDevelopmentActionPlanObjectivesSectionImprove
                candidateProcessResultsData={candidateProcessResultsData}
                processAssessmentData={competenciesToImproveAssessment}
                openAndMonitorTabFunc={openAndMonitorTabFunc}
                printMode={printMode}
            />
        </div>
    );
});
