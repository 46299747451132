import "./index.less";
import { AtTitleSubtitle } from "../../../AtTitleSubtitle";
import { DefaultSizes, XL } from "@atman/design-system/lib/internal";
import React from "react";
import cn from "classnames";

export interface ITableItemTitleProps {
    title: string;
    subtitle?: string;
    customLeftContent?: JSX.Element;
    size?: DefaultSizes | XL;
    className?: string;
    maxWidth?: number;
}

export const TableItemTitle: React.FC<ITableItemTitleProps> = (props) => {
    const { customLeftContent, title, subtitle, size, maxWidth, className, ...otherProps } = props;

    return (
        <div className={cn("TableItemTitle", className)} style={{ maxWidth: `${maxWidth}px` }} {...otherProps}>
            {customLeftContent && <div className="customLeftContent">{customLeftContent}</div>}
            <AtTitleSubtitle title={title} subtitle={subtitle} size={size} />
        </div>
    );
};
