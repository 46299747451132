import "./index.less";
import { AtButton } from "../AtButton";
import { AtContainer } from "../../atoms/AtContainer";
import { AtIcon } from "../../atoms/AtIcon";
import { AtText } from "../../../..";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import cn from "classnames";

export interface IBannerProps {
    icon: IconProp;
    text: string;
    onClickHandler: () => void;
    btnIcon?: IconProp;
    bgColor?: string;
    textColor?: string;
    classNames?: string;
}

export const Banner: React.FC<IBannerProps> = (props) => {
    const { text, bgColor, icon, classNames, onClickHandler, btnIcon, textColor } = props;

    return (
        <AtContainer className={cn("Banner", classNames)} style={{ backgroundColor: bgColor }}>
            <div className="iconTextBanner">
                <AtIcon icon={icon} />
                <AtText style={{ color: textColor }}>{text}</AtText>
            </div>
            <AtButton color="ghost" size="md" icon={btnIcon} onClick={onClickHandler} />
        </AtContainer>
    );
};
