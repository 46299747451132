import { AtmanAssessmentDocumentStructure } from "../models";
import { BaseStore } from "../base/BaseStore";
import { KpiApi } from "../apis";
import { action, computed, observable } from "mobx";

export class AssessmentStore extends BaseStore {
    @observable private _atmanAssessmentDocumentStructures: AtmanAssessmentDocumentStructure[] = [];

    @computed get atmanAssessmentDocumentStructures(): AtmanAssessmentDocumentStructure[] {
        return this._atmanAssessmentDocumentStructures ?? [];
    }

    @action loadAtmanAssessmentDocumentStructure = async () => {
        const assessmentDocumentsJson = await KpiApi.getAtmanDocuments();

        this._atmanAssessmentDocumentStructures = assessmentDocumentsJson.map(
            (x) => new AtmanAssessmentDocumentStructure(x),
        );
    };
}
