import "./index.less";
import * as React from "react";

export interface IAtVerticalSeparatorProps {
    height?: number;
    marginTop?: number;
}

const defaultProps: IAtVerticalSeparatorProps = {
    height: 92,
    marginTop: 28,
};

const AtVerticalSeparator: React.FC<IAtVerticalSeparatorProps> = (props) => {
    const { height, marginTop } = props;

    const style = {
        height,
        marginTop,
    };

    return <div className="AtVerticalSeparator" style={style}></div>;
};

AtVerticalSeparator.defaultProps = defaultProps;

export default AtVerticalSeparator;
