import "./index.less";
import * as React from "react";
import {
    AddCreditsCart,
    AppScope,
    CartStore,
    Client,
    CurrencyEnum,
    GlobalStores,
    IAppContext,
    IProductPriceCalculationService,
    ISummaryProductItem,
    LocalizationStore,
    ProductBalanceStore,
    ProductPriceCalculationService,
    ReactAppSettings,
    ScopedOrganizationStore,
} from "@atman/business";
import { Alert, Col, Container, Row } from "reactstrap";
import { AtBadge, AtButton } from "@atman/design-system";
import { ModalNewBalanceConfirmation } from "../../modals/components";
import { OrderSummaryItem } from "./components/OrderSummaryItem";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";

export interface IOrderSummaryProps extends IAppContext {
    scope: AppScope;
    onSave: (totalAmount: number) => Promise<string | undefined>;
    isProcessingStripeTransaction: boolean;
    scopedOrganizationStore?: ScopedOrganizationStore;
    cartStore?: CartStore;
    productBalanceStore?: ProductBalanceStore;
    localizationStore?: LocalizationStore;
}

@inject(
    GlobalStores.cartStore,
    GlobalStores.productBalanceStore,
    GlobalStores.localizationStore,
    GlobalStores.scopedOrganizationStore,
)
@observer
export class OrderSummaryComp extends React.Component<IOrderSummaryProps, {}> {
    private readonly customId: string = "custom";
    private readonly productPriceCalculationService: IProductPriceCalculationService;

    @observable public errorMessage: string = "";

    constructor(props: IOrderSummaryProps, context: any) {
        super(props, context);
        this.productPriceCalculationService = new ProductPriceCalculationService(
            props.productBalanceStore!,
            props.scope,
            this.userAccountAddress,
        );
    }

    @action.bound
    _onSave = async (totalNumber: number) => {
        this.errorMessage = "";

        const errorResult = await this.props.onSave(totalNumber);

        if (errorResult) {
            runInAction(() => {
                this.errorMessage = errorResult;
            });
        }
    };

    @computed
    get numberFormatter(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.props.localizationStore!.currentShortLocale, {
            style: "currency",
            currency: CurrencyEnum[this.props.cartStore!.billingCurrency],
        });
    }

    @computed
    get userAccountAddress() {
        const { scopedOrganizationStore } = this.props;

        const address = scopedOrganizationStore!.scopedOrganization?.billingInformation?.address;

        if (!address || !address.country) {
            throw new Error("Couldn't find a country set for the customer");
        }

        const country = ReactAppSettings.appModel.countries.find(
            (x) => x.code.toUpperCase() === address!.country.toUpperCase(),
        );

        if (!country) {
            throw new Error(`Couldn't find the country matching the code: ${address!.country}`);
        }

        if (country.states.any() && !address.state) {
            throw new Error("Couldn't find a state set for the customer");
        }

        return address;
    }

    getTaxRows = (subTotal: number) => {
        const taxRows = this.productPriceCalculationService.getTaxRows(subTotal);

        return taxRows.map((x, i) => (
            <Row className={`tax-row tax-row-${i}`} noGutters key={i}>
                <Col className={`tax-label tax-label-${i}`} sm={10}>
                    {x.taxName}
                </Col>
                <Col className={`tax-price price-col tax-price-${i}`} sm={2}>
                    <span className={`tax-price-value tax-price-value-${i}`}>
                        {this.numberFormatter.format(x.taxValue)}
                    </span>
                </Col>
            </Row>
        ));
    };

    @computed
    get cart(): AddCreditsCart {
        const { cartStore } = this.props;

        return new AddCreditsCart(cartStore!.appCarts["addCredits"]);
    }

    @computed
    get summaryItems(): ISummaryProductItem[] {
        const currency = this.props.cartStore!.billingCurrency;
        const summaryItems: ISummaryProductItem[] = [];

        if (this.cart.selectedPackageSku && this.cart.selectedPackageSku !== this.customId) {
            const summaryItem = this.productPriceCalculationService.getPackageSummaryItem(this.cart, currency);

            summaryItems.push(summaryItem);
        } else {
            const customSummaryItems = this.productPriceCalculationService.getCustomSummaryItems(this.cart, currency);

            summaryItems.push(...customSummaryItems);
        }

        return summaryItems;
    }

    render() {
        const { scope, isProcessingStripeTransaction, productBalanceStore, cartStore, scopedOrganizationStore } =
            this.props;

        const showPricesAndOrderSummary =
            scope !== AppScope.Client ||
            (scopedOrganizationStore!.scopedOrganization &&
                (scopedOrganizationStore!.scopedOrganization as Client).partnerIsInternal);

        const preDiscountSubTotal = this.productPriceCalculationService.getSubtotal(this.summaryItems);

        const discountRateRow = this.productPriceCalculationService.getDiscountRateRow(preDiscountSubTotal);
        const postDiscountSubTotal = preDiscountSubTotal - discountRateRow.discountTotalValue;
        const taxRows = this.getTaxRows(postDiscountSubTotal);
        const taxTotal = this.productPriceCalculationService.getTaxTotal(postDiscountSubTotal);
        const total = postDiscountSubTotal + taxTotal;

        return (
            <div className="OrderSummary">
                {showPricesAndOrderSummary ? (
                    <Container fluid className="order-summary-container">
                        <Row className="header-row" noGutters>
                            <Col className="table-header product-name-header" sm={scope === AppScope.Partner ? 5 : 7}>
                                {"global.productName".localize()}
                            </Col>
                            <Col className="table-header quantity-header" sm={2}>
                                {"global.quantity".localize()}
                            </Col>
                            {scope === AppScope.Partner ? (
                                <Col className="table-header discount-header" sm={2}>
                                    {"global.discount".localize()}
                                </Col>
                            ) : null}
                            <Col className="table-header price-per-unit-header" sm={1}>
                                {"global.pricePerUnit".localize()}
                            </Col>
                            <Col className="table-header price-header price-col" sm={2}>
                                {"global.price".localize()}
                            </Col>
                        </Row>
                        {this.summaryItems.map((x, i) => (
                            <OrderSummaryItem
                                scope={scope}
                                summaryItem={x}
                                customPackage={this.cart.selectedPackageSku === this.customId}
                                key={i}
                            />
                        ))}
                        {discountRateRow.discountPercentage > 0 && (
                            <Row className="discount-rate-row" noGutters>
                                <Col className="discount-rate-label" sm={10}>
                                    {discountRateRow.discountRowName}
                                    <AtBadge
                                        color={"success"}
                                        size={"md"}
                                        style={{ display: "inline-block", marginLeft: 8 }}
                                    >
                                        {`-${discountRateRow.discountPercentage}%`}
                                    </AtBadge>
                                </Col>
                                <Col className="discount-rate-price price-col" sm={2}>
                                    <span className="discount-rate-price-value">
                                        -{this.numberFormatter.format(discountRateRow.discountTotalValue)}
                                    </span>
                                </Col>
                            </Row>
                        )}
                        <Row className="subtotal-row" noGutters>
                            <Col className="subtotal-label" sm={10}>
                                {"global.subtotal".localize()}
                            </Col>
                            <Col className="subtotal-price price-col" sm={2}>
                                <span className="subtotal-price-value">
                                    {this.numberFormatter.format(postDiscountSubTotal)}
                                </span>
                            </Col>
                        </Row>
                        {taxRows}
                        <Row className="footer-row" noGutters>
                            <Col className="total-label" sm={9}>
                                {"global.total".localize()}
                            </Col>
                            <Col className="total-price price-col" sm={3}>
                                <span className="currency">
                                    {CurrencyEnum[cartStore!.billingCurrency].toUpperCase()}
                                </span>
                                <span className="total-price-value">{this.numberFormatter.format(total)}</span>
                            </Col>
                        </Row>
                    </Container>
                ) : null}
                <ModalNewBalanceConfirmation
                    balanceOperation={"credit"}
                    previousBalance={productBalanceStore!.balance}
                    selectedProductsBalance={this.cart.balance}
                />
                <div className="error-container">
                    {this.errorMessage ? <Alert color={"danger"}>{this.errorMessage}</Alert> : null}
                </div>
                <div className="btn-container">
                    <AtButton
                        size={"xl"}
                        onClick={() => this._onSave(total)}
                        isLoading={cartStore!.isLoading || isProcessingStripeTransaction}
                        disabled={this.cart.isEmpty}
                        fullWidth
                    >
                        {"global.confirmTransactionButton".localize()}
                    </AtButton>
                </div>
            </div>
        );
    }
}

const OrderSummary = withAppContext(OrderSummaryComp);
export { OrderSummary, OrderSummaryItem };
