import { ColorProperty } from "csstype";
import { tint } from "../../helpers/ColorHelpers";

/**
 * This class serves as a Static Library to access design-system colors in Typescript
 * TODO: Consider using webpack-loader to get the values directly from index.less file to avoid having to maintain both
 */
export class Colors {
    public static readonly greyBase = "#303030";
    public static readonly greyBg = tint(Colors.greyBase, 94);
    public static readonly greyShades = generateShades(Colors.greyBase);

    public static readonly primaryBase = "#1B59EA";
    public static readonly primaryBg = tint(Colors.primaryBase, 94);
    public static readonly primaryShades = generateShades(Colors.primaryBase);

    public static readonly greenBase = "#48B084";
    public static readonly greenBg = tint(Colors.greenBase, 94);
    public static readonly greenShades = generateShades(Colors.greenBase);

    public static readonly yellowBase = "#FAC13A";
    public static readonly yellowBg = tint(Colors.yellowBase, 94);
    public static readonly yellowShades = generateShades(Colors.yellowBase);

    public static readonly redBase = "#EB5757";
    public static readonly redBg = tint(Colors.redBase, 94);
    public static readonly redShades = generateShades(Colors.redBase);

    public static readonly purpleBase = "#8A3964";
    public static readonly purpleBg = tint(Colors.purpleBase, 94);
    public static readonly purpleShades = generateShades(Colors.purpleBase);

    public static readonly blueGreenGradient1 = "#123680";
    public static readonly blueGreenGradient2 = "#194580";
    public static readonly blueGreenGradient3 = "#1F5480";
    public static readonly blueGreenGradient4 = "#266481";
    public static readonly blueGreenGradient5 = "#2B7382";
    public static readonly blueGreenGradient6 = "#328382";
    public static readonly blueGreenGradient7 = "#399283";
    public static readonly blueGreenGradient8 = "#3FA183";
    public static readonly blueGreenGradient9 = "#48B084";

    // TODO: Consider removing the following colors from this file and moving them to their appropriate elements
    public static get inputBorderColor(): Colors {
        return Colors.greyShades.shade7;
    }
    public static get inputBorderColor__Hover(): Colors {
        return Colors.greyShades.shade5;
    }
    public static get inputBorderColor__Active(): Colors {
        return Colors.primaryBase;
    }
    public static get inputBoxShadow(): string {
        return `0 0 0 2px ${Colors.primaryShades.shade6}`;
    }

    public static readonly atmanOrange = "#F08752";
    public static readonly atmanOrangeFaded = tint(Colors.atmanOrange, 80);
    public static readonly atmanOrangeShades = generateShades(Colors.atmanOrange);

    public static readonly white = "#FFFFFF";
    public static readonly almostWhite = "#FAFAFA";
}

function generateShades(baseColor: ColorProperty): IColorShades {
    return {
        shade1: tint(baseColor, 10),
        shade2: tint(baseColor, 20),
        shade3: tint(baseColor, 30),
        shade4: tint(baseColor, 40),
        shade5: tint(baseColor, 50),
        shade6: tint(baseColor, 60),
        shade7: tint(baseColor, 70),
        shade8: tint(baseColor, 80),
        shade9: tint(baseColor, 87),
    };
}

interface IColorShades {
    shade1: ColorProperty;
    shade2: ColorProperty;
    shade3: ColorProperty;
    shade4: ColorProperty;
    shade5: ColorProperty;
    shade6: ColorProperty;
    shade7: ColorProperty;
    shade8: ColorProperty;
    shade9: ColorProperty;
}
