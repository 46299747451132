import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, UserInfoStore } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IAppContextInfoProps {
    userInfoStore?: UserInfoStore;
}

export const AppContextInfo: React.FC<IAppContextInfoProps> = inject(GlobalStores.userInfoStore)(
    observer((props) => {
        const { userInfoStore } = props;

        return (
            <div className="AppContextInfo">
                <div className="icon-container">
                    <FontAwesomeIcon icon={userInfoStore!.currentAppContext.icon} />
                </div>
                <AtTitle headingType={4} title={userInfoStore!.currentAppContext.name} />
            </div>
        );
    }),
);
