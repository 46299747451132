import "./AccountNotifications.less";
import {
    AppScope,
    EmailNotificationFrequencyEnum,
    GlobalStores,
    IAppContext,
    INotificationSettingDetail,
    NotificationType,
    ReactAppSettings,
    Role,
    UserInfoStore,
} from "@atman/business";
import { AtTabCard } from "@atman/design-system";
import { CardSaveButtons } from "../../../components/CardSaveButtons";
import { FrequenciesNotificationEntry } from "./components/FrequenciesNotificationEntry";
import { ToggleableNotificationEntry } from "./components/ToggleableNotificationEntry";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IAccountNotificationsProps extends IAppContext {
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AccountNotificationsComp extends React.Component<IAccountNotificationsProps, {}> {
    @observable public notificationSettings: Dictionary<NotificationType, EmailNotificationFrequencyEnum> = {};

    componentDidMount(): void {
        this.resetNotificationSettings();
    }

    @computed
    get isDisabled(): boolean {
        const keys = Object.keys(this.notificationSettings);

        for (const key of keys) {
            if (!(this.notificationSettings[key] >= 0)) {
                return true;
            }
        }

        return false;
    }

    @computed
    get toggleableNotifications(): INotificationSettingDetail[] {
        const { userInfoStore } = this.props;

        return ReactAppSettings.appModel.notificationSettings.emailNotifications
            .filter(
                (x) =>
                    !x.requiredRoles.any() || userInfoStore!.hasOneOfRoles(x.requiredRoles.map((role) => Role[role])),
            )
            .filter(
                (x) =>
                    x.availableFrequencies.length === 2 &&
                    x.availableFrequencies.indexOf(EmailNotificationFrequencyEnum.Disabled) >= 0 &&
                    x.availableFrequencies.indexOf(EmailNotificationFrequencyEnum.Enabled) >= 0,
            );
    }

    @computed
    get multiFrequencyNotifications(): INotificationSettingDetail[] {
        const { userInfoStore } = this.props;

        return ReactAppSettings.appModel.notificationSettings.emailNotifications
            .filter(
                (x) =>
                    !x.requiredRoles.any() || userInfoStore!.hasOneOfRoles(x.requiredRoles.map((role) => Role[role])),
            )
            .filter((x) => x.availableFrequencies.length > 2);
    }

    @action.bound
    resetNotificationSettings = () => {
        const { userInfoStore } = this.props;

        this.notificationSettings = {
            ...userInfoStore!.notificationSettings.emailNotificationSettings,
        };
    };

    @autobind
    async saveNotificationSettings() {
        const { userInfoStore } = this.props;

        await userInfoStore!.updateEmailNotificationSettings(this.notificationSettings);
    }

    @action.bound
    onToggleableNotificationChange = (event: React.FormEvent<HTMLInputElement>) => {
        const eventTarget = event.target as any;

        const notificationType = NotificationType[eventTarget.id];
        const frequency =
            Number(this.notificationSettings[notificationType]) === EmailNotificationFrequencyEnum.Disabled
                ? EmailNotificationFrequencyEnum.Enabled
                : EmailNotificationFrequencyEnum.Disabled;

        this.notificationSettings[notificationType] = frequency;
    };

    @action.bound
    onFrequenciesNotificationChange = (event: React.FormEvent<HTMLInputElement>) => {
        const notificationType = NotificationType[event.currentTarget.name];

        this.notificationSettings[Number(notificationType)] = Number(event.currentTarget.value);
    };

    @autobind
    renderProfileAlerts() {
        const {} = this.props;

        const profileNotificationTypes = [
            NotificationType.AssessmentCompleted,
            NotificationType.TermsAndConditionRejected,
        ];

        const profileAlerts = this.toggleableNotifications.filter((x) => profileNotificationTypes.indexOf(x.type) >= 0);

        if (!profileAlerts.any()) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"global.profileAlerts".localize()}>
                {profileAlerts.map((x, i) => {
                    if (!this.notificationSettings.hasOwnProperty(Number(x.type))) {
                        return null;
                    }

                    return (
                        <ToggleableNotificationEntry
                            type={x.type}
                            checked={
                                Number(this.notificationSettings[x.type]) !== EmailNotificationFrequencyEnum.Disabled
                            }
                            onChange={this.onToggleableNotificationChange}
                            key={i}
                        />
                    );
                })}
            </AtTabCard>
        );
    }

    @autobind
    renderAccountAlerts() {
        const {} = this.props;

        const accountNotificationTypes = [
            NotificationType.BalanceIsZero,
            NotificationType.NewUserCreated,
            NotificationType.TransactionCompleted,
        ];

        const accountAlerts = this.toggleableNotifications.filter((x) => accountNotificationTypes.indexOf(x.type) >= 0);

        if (!accountAlerts.any()) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"global.accountAlerts".localize()}>
                {accountAlerts.map((x, i) => {
                    if (!this.notificationSettings.hasOwnProperty(Number(x.type))) {
                        return null;
                    }

                    return (
                        <ToggleableNotificationEntry
                            type={x.type}
                            checked={
                                Number(this.notificationSettings[x.type]) !== EmailNotificationFrequencyEnum.Disabled
                            }
                            onChange={this.onToggleableNotificationChange}
                            key={i}
                        />
                    );
                })}
            </AtTabCard>
        );
    }

    @autobind
    renderClientAccountAlerts() {
        const {} = this.props;

        const accountNotificationTypes = [
            NotificationType.ClientBalanceIsZero,
            NotificationType.ClientBalanceIsNegative,
            NotificationType.ConsumptionSchedulingEnded,
            NotificationType.ConsumptionSchedulingEndSoon,
            NotificationType.ClientOustandingBalance,
        ];

        const toggleableNotifications = this.toggleableNotifications.filter(
            (x) => accountNotificationTypes.indexOf(x.type) >= 0,
        );

        const multiFrequencyNotifications = this.multiFrequencyNotifications.filter(
            (x) => accountNotificationTypes.indexOf(x.type) >= 0,
        );

        if (!toggleableNotifications.any() && !multiFrequencyNotifications.any()) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"partnerApp.clientAccountAlerts".localize()}>
                {toggleableNotifications.map((x, i) => (
                    <ToggleableNotificationEntry
                        type={x.type}
                        checked={Number(this.notificationSettings[x.type]) !== EmailNotificationFrequencyEnum.Disabled}
                        onChange={this.onToggleableNotificationChange}
                        key={i}
                    />
                ))}
                {multiFrequencyNotifications.map((x, i) => (
                    <FrequenciesNotificationEntry
                        type={x.type}
                        availableFrequencies={x.availableFrequencies}
                        selectedFrequency={this.notificationSettings[x.type]}
                        onChange={this.onFrequenciesNotificationChange}
                        key={i}
                    />
                ))}
            </AtTabCard>
        );
    }

    @autobind
    renderReportingAlerts() {
        const {} = this.props;

        const reportingNotificationTypes = [
            NotificationType.TransactionCompleted,
            NotificationType.NewProfiles,
            NotificationType.ClientConsumptionForPartner,
            NotificationType.ClientTransactionForPartner,
        ];

        const reportingNotifications = this.multiFrequencyNotifications.filter(
            (x) => reportingNotificationTypes.indexOf(x.type) >= 0,
        );

        if (!reportingNotifications.any()) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"global.reporting".localize()}>
                {reportingNotifications.map((x, i) => (
                    <FrequenciesNotificationEntry
                        type={x.type}
                        availableFrequencies={x.availableFrequencies}
                        selectedFrequency={this.notificationSettings[x.type]}
                        onChange={this.onFrequenciesNotificationChange}
                        key={i}
                    />
                ))}
            </AtTabCard>
        );
    }

    render() {
        const { scope, userInfoStore } = this.props;

        return (
            <div id="AccountNotifications">
                {scope === AppScope.Client ? this.renderProfileAlerts() : null}
                {this.renderAccountAlerts()}
                {scope === AppScope.Partner ? this.renderClientAccountAlerts() : null}
                {this.renderReportingAlerts()}
                <CardSaveButtons
                    saveAction={this.saveNotificationSettings}
                    cancelAction={this.resetNotificationSettings}
                    isLoading={userInfoStore!.isLoading}
                    disabledSaveButton={this.isDisabled}
                />
            </div>
        );
    }
}

const AccountNotifications = withAppContext(AccountNotificationsComp);

export { AccountNotifications };
