import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ICardSaveButtonsProps {
    saveAction: () => void;
    cancelAction?: () => void;
    saveLabel?: string;
    cancelLabel?: string;
    isLoading?: boolean;
    disabledSaveButton?: boolean;
}

export const CardSaveButtons: React.FC<ICardSaveButtonsProps> = (props) => {
    const {
        saveAction,
        cancelAction,
        saveLabel = "global.saveChanges".localize(),
        cancelLabel = "global.buttons.labels.cancel".localize(),
        isLoading = false,
        disabledSaveButton = false,
        ...otherProps
    } = props;

    return (
        <div className="CardSaveButtons" {...otherProps}>
            {cancelAction ? (
                <AtButton isLoading={isLoading} color={"secondary"} onClick={cancelAction} size="xl">
                    {cancelLabel}
                </AtButton>
            ) : null}
            <AtButton
                icon={["far", "save"] as IconProp}
                onClick={saveAction}
                isLoading={isLoading}
                disabled={disabledSaveButton}
                size="xl"
            >
                {saveLabel}
            </AtButton>
        </div>
    );
};
