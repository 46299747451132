import { BaseModel, IBaseInput, IBaseModel } from "../base/BaseModel";
import { IShortCandidate } from "../types";

export interface ITeamInput extends IBaseInput {
    name: string;
    purpose?: string;
    restricted: boolean;
    ownerIds: string[];
    leadIds: string[];
}

export enum CompetencyDevelopmentFlowStyle {
    WithoutEvaluator,
    WithFullAccessEvaluator,
    WithLimitedAccessEvaluator,
}

export class Team extends BaseModel implements IBaseInput {
    public name: string;
    public purpose: string;
    public restricted: boolean;
    public ownerIds: string[];
    public members?: IShortCandidate[];
    public leads?: IShortCandidate[];
    public activeCampaignId?: string;
    public cultureId?: string;
    public cultureName?: string;
    public managerId?: string;
    public managerName?: string;
    public optionalManagerAssessment?: boolean;
    public optionalSelfAssessment?: boolean;
    public useClientCultureInCompetencyDevelopment?: boolean;
    public thirdPartyEvaluatorName?: string;
    public thirdPartyEvaluatorEmail?: string;
    public competencyDevelopmentFlowStyle?: CompetencyDevelopmentFlowStyle;

    constructor(input?: ITeamInput, baseInput?: IBaseModel) {
        super(baseInput);

        if (input) {
            this.name = input.name;
            this.purpose = input.purpose || "";
            this.restricted = input.restricted;
            this.ownerIds = input.ownerIds;
            this.leads = input.leadIds.map((x) => ({ candidateProId: x } as IShortCandidate));
        }
    }
}

export interface ITeamCompetencyDevelopmentSettings {
    isManagerAssessmentOptional: boolean;
    isSelfAssessmentOptional: boolean;
    useClientCulture: boolean;
    flowStyle: CompetencyDevelopmentFlowStyle;
    thirdPartyEvaluatorName?: string;
    thirdPartyEvaluatorEmail?: string;
}
