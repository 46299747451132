import { AnalyzedCandidate, Candidate, User } from "../models";
import { IShortCandidate } from "../types";

export class PersonHelper {
    public static getDisplayName = (person: AnalyzedCandidate | IShortCandidate | Candidate | User) => {
        if (person.firstName && person.lastName) {
            return `${person.firstName} ${person.lastName}`;
        } else if (person.firstName) {
            return person.firstName;
        } else if (person.lastName) {
            return person.lastName;
        } else {
            return person.email;
        }
    };
}
