import "./index.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { UrlFormatter } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface ICandidatePictureProps {
    profilePictureUri?: string;
    size?: "xxxs" | "xxs" | "xs" | "sm" | "md" | "lg";
}

@inject()
@observer
export class CandidatePicture extends React.Component<ICandidatePictureProps, {}> {
    render() {
        const { profilePictureUri, size = "md", ...otherProps } = this.props;

        let length: number;
        let iconSize: SizeProp;

        switch (size) {
            case "xxxs":
                length = 20;
                iconSize = "2x";
                break;
            case "xxs":
                length = 40;
                iconSize = "2x";
                break;
            case "xs":
                length = 48;
                iconSize = "3x";
                break;
            case "sm":
                length = 64;
                iconSize = "4x";
                break;
            case "md":
                length = 80;
                iconSize = "5x";
                break;
            case "lg":
                length = 96;
                iconSize = "6x";
                break;
            default:
                length = 80;
                iconSize = "5x";
                break;
        }

        return (
            <div className={`CandidatePicture size-${size}`} {...otherProps}>
                {profilePictureUri ? (
                    <img
                        height={length}
                        width={length}
                        src={UrlFormatter.formatPictureUrlForSize(profilePictureUri, "md")}
                        style={{ borderRadius: "100%" }}
                    />
                ) : (
                    <FontAwesomeIcon icon={["far", "user-circle"] as IconProp} size={iconSize} />
                )}
            </div>
        );
    }
}
