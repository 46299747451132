import "./index.less";
import * as React from "react";
import {
    AppScope,
    Client,
    ContentStore,
    Culture,
    CultureStore,
    GlobalStores,
    ItemType,
    ModalStore,
    ModalTypes,
    ScopedOrganizationStore,
    UrlFormatter,
} from "@atman/business";
import { AtCustomizableCard, IAtCustomizablePropertyProps } from "../../../../components/AtCustomizableCard";
import { CustomDeleteAlert } from "../../../../components/CustomDeleteAlert";
import { DropdownItem } from "reactstrap";
import { inject, observer } from "mobx-react";
import { useAppContext } from "../../../../contexts";
import { useHistory } from "react-router";

export interface ICultureCardProps {
    culture: Culture;
    cultureStore?: CultureStore;
    contentStore?: ContentStore;
    modalStore?: ModalStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

export const CultureCard: React.FC<ICultureCardProps> = inject(
    GlobalStores.contentStore,
    GlobalStores.modalStore,
    GlobalStores.cultureStore,
    GlobalStores.scopedOrganizationStore,
)(
    observer((props) => {
        const { culture, contentStore, modalStore, cultureStore, scopedOrganizationStore } = props;

        const { scope } = useAppContext();
        const history = useHistory();

        const ctaAction = () => history.push(UrlFormatter.formatReactPath(`/Settings/Cultures/${culture.id}`));
        const openAssignmentModal = () =>
            modalStore!.openModal(ModalTypes.AssignCultureToTeams, { competencySetId: culture.id });
        const duplicateAction = () =>
            history.push(UrlFormatter.formatReactPath("/Settings/Cultures/Create"), { duplicatedId: culture.id });
        const assignToOrganization = () => cultureStore!.assignCultureToOrganization(culture.id);
        const archiveAction = () =>
            new CustomDeleteAlert(ItemType.Culture, culture.name, () => cultureStore!.deleteItem(culture.id)).fire();

        const topProperties: IAtCustomizablePropertyProps[] = [
            {
                label: "global.competencyCount".localize(),
                value: culture.competencyIds.length,
            },
        ];

        if (scope === AppScope.Client) {
            topProperties.push({
                label: "global.teamsCount".localize(),
                value: culture.teamsCount,
            });
        }

        const isAssignedToOrg = (scopedOrganizationStore!.scopedOrganization as Client)?.cultureId === culture.id;
        const isAssignedToTeams = culture.teamsCount > 0;

        const menuActions = (
            <>
                <DropdownItem onClick={duplicateAction}>{"global.buttons.labels.duplicate".localize()}</DropdownItem>
                {scope === AppScope.Client && (
                    <>
                        <DropdownItem onClick={assignToOrganization} disabled={isAssignedToOrg}>
                            {"clientApp.assignToOrganization".localize()}
                        </DropdownItem>
                        <DropdownItem onClick={openAssignmentModal}>
                            {"clientApp.assignToTeams".localize()}
                        </DropdownItem>
                    </>
                )}
                <DropdownItem onClick={archiveAction} disabled={isAssignedToOrg || isAssignedToTeams}>
                    {"global.archive".localize()}
                </DropdownItem>
            </>
        );

        const department = culture.departmentId ? contentStore!.getDepartmentById(culture.departmentId) : undefined;

        return (
            <AtCustomizableCard
                title={culture.name}
                subtitle={department?.name}
                ctaAction={ctaAction}
                ctaLabel={"global.edit".localize()}
                topProperties={topProperties}
                menuActions={menuActions}
                className={"CultureCard"}
                inverseTitleAndSubtitle
            />
        );
    }),
);
