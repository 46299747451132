import "./index.less";
import * as React from "react";
import { AtIconTooltip, AtTitle } from "@atman/design-system";
import { BaseResponsiveComponent } from "../../../BaseResponsiveComponent";

export interface IH3SectionLayoutProps {
    id?: string;
    title?: string;
    description?: string; // Info that we show in the help bubble
    showDescription?: boolean;
    className?: string;
    showHeader?: boolean;
    // children : The content to put in this section
}

export class H3SectionLayout extends BaseResponsiveComponent<IH3SectionLayoutProps> {
    render() {
        const {
            children,
            id = "",
            className = "",
            title = "",
            description = "",
            showDescription = false,
            showHeader = false,
        } = this.props;

        const tooltipId = `${id}-tooltip`;

        return (
            <div className={`H3SectionLayout ${className}`}>
                {showHeader && (
                    <div className="scale-group-header">
                        <AtTitle headingType={3} title={title} />

                        {showDescription && description && (
                            <AtIconTooltip
                                icon={"question-circle"}
                                tooltipTargetId={tooltipId}
                                tooltipDisplayMode={"dark"}
                                tooltipProps={{
                                    innerClassName: "scale-group-unipolar-tooltip",
                                    placement: "right",
                                }}
                            >
                                {description!.split(/\\n|<br\/><br\/>|<br>|<br\/>/).map((x) => (
                                    <React.Fragment key={x}>
                                        <span>{x}</span>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </AtIconTooltip>
                        )}
                    </div>
                )}
                <div className="results-container">{children}</div>
            </div>
        );
    }
}
