import "./index.less";
import { AtCheckableCard, AtIcon, AtParagraph, AtTitle, AtTooltip, Colors, Sizing } from "@atman/design-system";
import { CompetencySetPreview } from "../../../../components";
import { StringFormatter } from "@atman/business";
import { observer } from "mobx-react";
import React from "react";

export interface ICompetencySetTemplateCardProps {
    templateId: string;
    name: string;
    description: string;
    descriptionMaxLength?: number;
    competencyIds: string[];
    onTemplateChange: (id: string) => void;
    active: boolean;
}

export const CompetencySetTemplateCard: React.FC<ICompetencySetTemplateCardProps> = observer((props) => {
    const {
        templateId,
        name,
        description,
        descriptionMaxLength = 280,
        competencyIds,
        active,
        onTemplateChange,
    } = props;

    const competencyContainerId = `competency-preview-${templateId ?? "blank"}`;
    const descriptionContainerId = `${competencyContainerId}-description`;

    return (
        <AtCheckableCard
            className="CompetencySetTemplateCard"
            inputType="radio"
            onTopSectionClick={() => {
                onTemplateChange(templateId);
            }}
            checked={active}
            padding={[Sizing.pixels.sm, Sizing.pixels.md]}
            borderRadius={Sizing.pixels.xxs}
            noShadow
        >
            <div>
                <AtTitle headingType={4} title={name} />
                <AtParagraph weight="medium" id={descriptionContainerId}>
                    {StringFormatter.truncateWithEllipsis(description, descriptionMaxLength)}
                </AtParagraph>
                {description && description.length > descriptionMaxLength && (
                    <AtTooltip target={descriptionContainerId} displayMode={"dark"} placement={"auto"}>
                        {description}
                    </AtTooltip>
                )}
            </div>
            <div id={competencyContainerId} className="competency-count">
                <AtIcon icon={["far", "eye"]} size="1x" color={Colors.greyShades.shade4} />
                <AtTitle
                    title={`${competencyIds.length} ${"global.competencies".localize()}`}
                    headingType={5}
                    noMargin
                />
                {competencyIds.any() && (
                    <CompetencySetPreview
                        competencyContainerId={competencyContainerId}
                        competencySetName={name}
                        competencyIds={competencyIds}
                    />
                )}
            </div>
        </AtCheckableCard>
    );
});
