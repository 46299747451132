import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";

export interface IFormGroupSectionProps {
    sectionTitle: string;
    rightHeaderContent?: React.ReactNode;
    icon?: IconProp;
    iconSize?: SizeProp;
    className?: string;
    id?: string;
}

@inject()
@observer
export class FormGroupSection extends React.Component<IFormGroupSectionProps, {}> {
    render() {
        const { sectionTitle, rightHeaderContent, className, id, icon, iconSize, children, ...otherProps } = this.props;

        return (
            <div className={`FormGroupSection ${className ? className : ""}`} id={id ? id : ""} {...otherProps}>
                <div className="header">
                    <div className="left-header-content">
                        {icon ? (
                            <div className="icon-container">
                                <FontAwesomeIcon icon={icon} size={iconSize ?? "xs"} />
                            </div>
                        ) : null}
                        <AtTitle headingType={4} title={sectionTitle} />
                    </div>
                    <div className="right-header-content">{rightHeaderContent}</div>
                </div>
                <div className="content">{children}</div>
            </div>
        );
    }
}
