import * as React from "react";
import { AtButtonList, AtTable, IAtButtonProps } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { GlobalStores, IStripeCustomerCard, ModalStore, ModalTypes, ProductBalanceStore } from "@atman/business";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import getPaymentMethodsListColumns from "./paymentMethodColumns";

export interface IPaymentMethodsListProps {
    productBalanceStore?: ProductBalanceStore;
    modalStore?: ModalStore;
}

@inject(GlobalStores.productBalanceStore, GlobalStores.modalStore)
@observer
export class PaymentMethodsList extends BaseForm<IPaymentMethodsListProps, {}> {
    @computed get filteredCustomerCards(): IStripeCustomerCard[] {
        const { productBalanceStore } = this.props;

        return productBalanceStore!.customerCards;
    }

    render(): JSX.Element {
        const { productBalanceStore, modalStore } = this.props;

        const columns = getPaymentMethodsListColumns(modalStore!, productBalanceStore!);

        const buttons: IAtButtonProps[] = [
            {
                onClick: () => modalStore!.openModal(ModalTypes.AddCard, {}),
                children: "global.addCard".localize(),
            },
            {
                onClick: productBalanceStore!.loadCustomerCards,
                color: "secondary",
                icon: ["far", "sync"],
                isLoading: productBalanceStore!.isLoading,
            },
        ];

        return (
            <div className="PaymentMethodsList">
                <AtButtonList buttons={buttons} />

                <div className="list">
                    <AtTable<IStripeCustomerCard> items={this.filteredCustomerCards} columns={columns} />
                </div>
            </div>
        );
    }
}
