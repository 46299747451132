import "./DevelopmentPlanImprovementCompetencySection.less";
import { AtSubtitle } from "@atman/design-system";
import { CompDevCompetencyResult, SelectedSubCompetencyToImproveData } from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../logic";
import { DevelopmentPlanImprovementSubRow } from "./DevelopmentPlanImprovementSubRow";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export interface IDevelopmentPlanImprovementCompetencySectionProps {
    comp: CompDevCompetencyResult;
    selectedElement: Map<string, SelectedSubCompetencyToImproveData> | undefined;
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    saveStrategicObjectiveSelectionData: (
        elementId: string,
        subElementId: string,
        objectiveId: string,
        selectedApplicationIds: string[],
        applicationNote: string,
    ) => void;
    toggleLearningResourceSelection: (elementId: string, subElementId: string, learningResourceId: string) => void;
    setLearningResourceNote: (elementId: string, subElementId: string, value: string) => void;
    erroredElements?: Map<string, string[]>;
}

export const DevelopmentPlanImprovementCompetencySection: React.FC<IDevelopmentPlanImprovementCompetencySectionProps> =
    observer((props) => {
        const {
            comp,
            selectedElement,
            erroredElements = new Map(),
            columns,
            managerInfo,
            employeeInfo,
            hiddenColumns,
            saveStrategicObjectiveSelectionData,
            toggleLearningResourceSelection,
            setLearningResourceNote,
        } = props;

        return (
            <div className="improvement-objectives-container sub-elements-container">
                <div className="improvement-objectives-explanation">
                    <AtSubtitle
                        subtitle={t({
                            id: "competencyDevelopment.SubCompetenciesStrategicObjectiveSectionsLabel",
                            message: "Parcourir les sous-compétences associées à la compétence",
                        })}
                        size="sm"
                        className="improvement-objectives-container-subtitle"
                    />
                </div>
                <div className="groups-container">
                    {comp.subCompetencies!.map((group) => (
                        <DevelopmentPlanImprovementSubRow
                            key={group.id}
                            group={group}
                            comp={comp}
                            selectedObjectivesData={selectedElement?.get(group.id)}
                            erroredElements={erroredElements}
                            columns={columns}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                            saveStrategicObjectiveSelectionData={saveStrategicObjectiveSelectionData}
                            toggleLearningResourceSelection={toggleLearningResourceSelection}
                            setLearningResourceNote={setLearningResourceNote}
                        />
                    ))}
                </div>
            </div>
        );
    });
