import "./index.less";
import * as React from "react";
import { AtBadge, AtCard, AtCheckableCard, AtIcon, AtLinkBase } from "@atman/design-system";
import { CompDevLearningResource, CompetencyDevelopmentLearningResourceType } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface ILearningResourceProps {
    learningResource: CompDevLearningResource;
    parentId?: string;
    checked?: boolean;
    onClick?: () => void;
    isV2?: boolean;
}

export const LearningResource: React.FC<ILearningResourceProps> = observer((props) => {
    const { learningResource, parentId, checked, onClick, isV2 = false } = props;

    let iconProp: IconProp | undefined;

    switch (learningResource.type) {
        case CompetencyDevelopmentLearningResourceType.Article:
            iconProp = ["fal", "browser"];
            break;
        case CompetencyDevelopmentLearningResourceType.Book:
            iconProp = ["fal", "book"];
            break;
        case CompetencyDevelopmentLearningResourceType.Video:
            iconProp = ["fal", "video"];
            break;
        case CompetencyDevelopmentLearningResourceType.Podcast:
            iconProp = ["fal", "podcast"];
            break;
        case CompetencyDevelopmentLearningResourceType.Certification:
            iconProp = ["fal", "certificate"];
            break;
    }

    const topContent: JSX.Element = (
        <AtBadge size="sm" color={isV2 ? "ghost" : "primary"} badgeStyle="fill-light">
            {iconProp && <FontAwesomeIcon icon={iconProp} />}
            {t({
                id: `competencyDevelopment.learningResourceType.${CompetencyDevelopmentLearningResourceType[
                    learningResource.type
                ].toCamel()}`,
            })}
        </AtBadge>
    );

    const bottomContent: JSX.Element = (
        <>
            {learningResource.url ? (
                <AtLinkBase
                    className={isV2 ? "v2Link" : ""}
                    href={learningResource.url}
                    color="secondary"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    // title={learningResource.name}
                    underlined
                    id={`author-${learningResource.id}`}
                >
                    {(isV2 && (
                        <>
                            <AtIcon icon={["far", "link"]} />
                            <div className="resourceName">{learningResource.name}</div>
                        </>
                    )) || <>{learningResource.name}</>}
                </AtLinkBase>
            ) : (
                <span className="resourceName" id={`author-${learningResource.id}`}>
                    {learningResource.name}
                </span>
            )}
            <span className="resourceAuthors">{learningResource.authors}</span>
            <UncontrolledTooltip target={`author-${learningResource.id}`} placement="bottom">
                {learningResource.name}
            </UncontrolledTooltip>
        </>
    );

    return checked !== undefined && onClick ? (
        <AtCheckableCard
            id={`${parentId}-${learningResource.id}`}
            className="LearningResource"
            checked={checked}
            onContainerClick={onClick}
            topContent={topContent}
        >
            {bottomContent}
        </AtCheckableCard>
    ) : (
        <AtCard className="LearningResource" topContent={topContent}>
            {bottomContent}
        </AtCard>
    );
});
