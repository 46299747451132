import "./index.less";
import { AtCard, AtPersonLabel, AtTitle, IAtPersonLabelProps } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtProfileHeaderProps extends IAtPersonLabelProps {
    className?: string;
    rightSection?: React.ReactNode;
    teamName?: string;
}

export const AtProfileHeader: React.FC<IAtProfileHeaderProps> = (props: IAtProfileHeaderProps) => {
    const { className, rightSection, email, teamName, ...otherProps } = props;

    const {
        firstName,
        lastName,
        badge,
        pictureUri,
        pictureFormatFunc,
        onClick,
        showInitialsUnderPicture,
        avatarProps,
    } = otherProps;

    return (
        <AtCard className={cn("AtProfileHeader", className)}>
            {!teamName ? (
                <AtPersonLabel
                    firstName={firstName}
                    lastName={lastName}
                    badge={badge}
                    pictureUri={pictureUri}
                    pictureFormatFunc={pictureFormatFunc}
                    onClick={onClick}
                    showInitialsUnderPicture={showInitialsUnderPicture}
                    avatarProps={avatarProps}
                    description={email}
                    size={"md"}
                />
            ) : (
                <AtTitle title={teamName} headingType={3} />
            )}
            {rightSection && <div className="rightSection">{rightSection}</div>}
        </AtCard>
    );
};
