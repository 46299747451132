import * as Jwt from "jsonwebtoken";
import { AtmanCoPlatformsEnum } from "../../global/components/ProductSelector";
import { AuthApi, IAuthApi, IAuthApiOptions, ILoginResponse } from "../../api/AuthApi";
import { ISsoProviderDetails, LocalizationStore } from "@atman/business";
import { ToastProvider } from "@atman/business/lib/libs/ToastProvider";
import { action, computed, observable } from "mobx";
import { history } from "../../../index";
import { i18n } from "@lingui/core";

export class AuthStore {
    private readonly _localizationStore: LocalizationStore;
    private readonly _authApi: IAuthApi;

    constructor(localizationStore: LocalizationStore) {
        this._authApi = new AuthApi();
        this._localizationStore = localizationStore;
    }

    @observable private jwt?: string;
    @observable private jwtExpiresAt?: number;
    @observable private returnUrl?: string;
    @observable private clientId?: string;

    @observable public username?: string;

    @action verifyRefreshToken = async (options: IAuthApiOptions = {}) => {
        return await this._authApi.verifyRefreshToken(options);
    };

    @action login = async (email: string, password: string, rememberMe: boolean, options: IAuthApiOptions = {}) => {
        const result = await this._authApi.login(email, password, rememberMe, this.returnUrl, this.clientId, options);

        this.handleLoginResponse(result, options.platform);
    };

    @action loginSilently = async (options: IAuthApiOptions = {}) => {
        const result = await this._authApi.loginSilently(this.returnUrl, options);

        this.handleLoginResponse(result, options.platform);
    };

    @action loginSilentlyToHelpCenter = async (helpCenterReturnUrl?: string, options: IAuthApiOptions = {}) => {
        const result = await this._authApi.loginSilentlyToHelpCenter(helpCenterReturnUrl, options);

        this.handleLoginResponse(result, options.platform);
    };

    @action loginToHelpCenter = async (
        email: string,
        password: string,
        helpCenterReturnUrl?: string,
        options: IAuthApiOptions = {},
    ) => {
        const result = await this._authApi.loginToHelpCenter(email, password, helpCenterReturnUrl, options);

        this.handleLoginResponse(result, options.platform, true);
    };

    @action changePassword = async (
        password: string,
        fromHelpCenterLogin: boolean = false,
        options: IAuthApiOptions = {},
    ) => {
        const result = await this._authApi.changePassword(
            password,
            this.returnUrl,
            this.clientId,
            fromHelpCenterLogin,
            options,
        );

        this.handleLoginResponse(result, options.platform);
    };

    @action acceptCookiePolicy = async (options: IAuthApiOptions) => {
        const result = await this._authApi.acceptCookiePolicy(this.returnUrl, this.clientId, options);

        this.handleLoginResponse(result, options.platform);
    };

    @action chooseClient = async (clientId: string, options: IAuthApiOptions) => {
        const result = await this._authApi.chooseClient(clientId, this.returnUrl, options);

        this.handleLoginResponse(result, options.platform);
    };

    @action loginFromSamlLoginResponse = async (samlLoginResponse: ILoginResponse, platform?: AtmanCoPlatformsEnum) => {
        this.handleLoginResponse(samlLoginResponse, platform);
    };

    @action getAllowedClients = async (options) => {
        return await this._authApi.getAllowedClients(options);
    };

    @action getAllowedPasswordClients = async (options) => {
        return await this._authApi.getAllowedPasswordClients(options);
    };

    /** Reset Password Flow **/
    resetPassword = (loginAccessToken: string, newPassword: string): Promise<void> => {
        return this._authApi.resetPassword(loginAccessToken, newPassword);
    };

    acceptCandidateDataAccessRequest = (accessToken: string, options: IAuthApiOptions): Promise<void> => {
        return this._authApi.acceptCandidateDataAccessRequest(accessToken, options);
    };

    denyCandidateDataAccessRequest = (accessToken: string, options: IAuthApiOptions): Promise<void> => {
        return this._authApi.denyCandidateDataAccessRequest(accessToken, options);
    };

    /** Methods used in Employee MFA Flow **/
    requestMfaCodeForCandidate = async (email: string) => {
        return await this._authApi.requestMfaCodeForCandidate(email);
    };

    confirmMfaCodeForCandidate = async (email: string, code: string) => {
        const { redirectTo } = await this._authApi.confirmMfaCodeForCandidate(email, code);

        if (redirectTo) {
            window.location.href = redirectTo;
        }
    };

    /** Methods used in Employee MFA Flow **/
    getSsoProviderDetails = async (orgId: string): Promise<ISsoProviderDetails> => {
        return await this._authApi.getSsoProviderDetails(orgId);
    };

    @action clearAuthInfo = () => {
        this.jwt = undefined;
        this.jwtExpiresAt = undefined;
    };

    @action setReturnUrl = (returnUrl?: string) => {
        this.returnUrl = returnUrl;
    };

    @action setClientId = (clientId?: string) => {
        this.clientId = clientId;
    };

    @action private handleLoginResponse = (
        response: ILoginResponse,
        platform?: AtmanCoPlatformsEnum,
        fromHelpCenterLogin: boolean = false,
    ) => {
        if (response.redirectTo) {
            window.location.href = response.redirectTo;
            return;
        }

        this.jwt = response.jwt;
        this.jwtExpiresAt = response.jwtExpiresAt;

        const { languageCode, sub } = Jwt.decode(this.jwt, { json: true }) as { languageCode: string; sub: string };

        this._localizationStore.updateCurrentLocale(languageCode);
        this.username = sub;

        this._authApi.setJwtAuthHeader(response.jwt);

        const locationState = {
            platform,
            fromHelpCenterLogin,
        };

        if (response.mustChangePassword) {
            ToastProvider.warn(
                i18n._({
                    id: "loginApp.changePasswordForm.toastMessage",
                    message: "Please create a new password to continue",
                }),
            );
            history.push("/Auth/ChangePassword", locationState);
            return;
        }

        if (response.mustAcceptCookiePolicy) {
            ToastProvider.warn(
                i18n._({
                    id: "loginApp.cookiePolicyForm.toastMessage",
                    message: "Please accept our terms and conditions to continue",
                }),
            );
            history.push("/Auth/CookiePolicy", locationState);
            return;
        }

        if (response.mustChooseClient) {
            history.push("/Auth/ChooseClient", locationState);
            return;
        }
    };

    @computed get jwtExpiresIn() {
        if (!this.jwtExpiresAt) {
            return undefined;
        }

        return new Date(this.jwtExpiresAt).getTime() - new Date().getTime();
    }

    @computed get isAuthenticated() {
        if (!this.jwt) {
            return false;
        }

        const jwtExpiresIn = this.jwtExpiresIn;

        return jwtExpiresIn && jwtExpiresIn > 0;
    }
}
