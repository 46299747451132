import { BaseCompetencyElement, CompetencyDevelopmentLeveragingObjectiveType, IBaseCompetencyElement } from "..";

export interface ICompDevSubCompetenciesStrategicObjective extends IBaseCompetencyElement {
    explanation: string;
    reflection: string;
    type: CompetencyDevelopmentLeveragingObjectiveType;
}

export class CompDevSubCompetenciesStrategicObjective
    extends BaseCompetencyElement
    implements ICompDevSubCompetenciesStrategicObjective
{
    public readonly explanation: string;
    public readonly reflection: string;
    public readonly type: CompetencyDevelopmentLeveragingObjectiveType;

    constructor(json: ICompDevSubCompetenciesStrategicObjective) {
        super(json);

        this.explanation = json.explanation;
        this.reflection = json.reflection;
        this.type = json.type;
    }
}
