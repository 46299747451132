import "./index.less";
import * as React from "react";
import {
    AtBadge,
    AtPersonAvatar,
    DefaultSizes,
    ExtraAndDefaultSizes,
    IInitialsAvatarProps,
    IconTitleSubtitle,
    InitialsAvatar,
    XL,
    XXL,
} from "../../../internal";

export interface IAtPersonLabelProps {
    firstName?: string; // Person's name
    lastName?: string; // Person's name
    email?: string; // Person's name
    description?: string; // Short info about the person
    badge?: string; // An optional badge
    pictureUri?: string;
    pictureFormatFunc?: (pictureUrl: string, size: DefaultSizes | XL | XXL) => string;
    size?: DefaultSizes;
    onClick?: () => void;
    showInitialsUnderPicture?: boolean;
    avatarProps?: Partial<IInitialsAvatarProps>;
}

export class AtPersonLabel extends React.Component<IAtPersonLabelProps> {
    protected get containerProps(): React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement> {
        const { onClick } = this.props;

        return { onClick };
    }

    render() {
        const {
            firstName,
            lastName = "",
            email,
            description,
            badge,
            pictureUri,
            pictureFormatFunc,
            size = "sm",
            avatarProps,
            showInitialsUnderPicture = false,
            ...otherProps
        } = this.props;

        const fullName = firstName || lastName ? `${firstName} ${lastName}` : email;

        let pictureSize: ExtraAndDefaultSizes | XXL;

        switch (size) {
            case "sm":
                pictureSize = "sm";

                break;
            case "md":
                pictureSize = "lg";

                break;
            case "lg":
                pictureSize = "xl";

                break;
        }

        const icon =
            firstName || lastName || email ? (
                <>
                    <AtPersonAvatar
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        pictureUri={pictureUri}
                        pictureFormatFunc={pictureFormatFunc}
                        size={pictureSize}
                        displayIconInsteadOfAvatar={showInitialsUnderPicture}
                        borderColor={avatarProps?.bgColor}
                    />
                    {showInitialsUnderPicture && (
                        <InitialsAvatar
                            text={[firstName, lastName]}
                            size={"sm"}
                            className={"candidate-initials"}
                            {...avatarProps}
                        />
                    )}
                </>
            ) : (
                <div className="blank-profile-picture" />
            );

        return (
            <div className={`AtPersonLabel size-${size}`} {...otherProps}>
                <IconTitleSubtitle title={fullName} subtitle={description} icon={icon} />
                {badge && (
                    <div className="right-section">
                        <AtBadge size={"sm"}>{badge}</AtBadge>
                    </div>
                )}
            </div>
        );
    }
}
