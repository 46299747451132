import { SidePanelItemDefinition } from "@atman/ui-kit/lib/components/SidePanelElement/SidePanelStructure";
import { SidePanelSearchType } from "../..";
import { SortDirection } from "@atman/ui-kit";

export const getSubElementsWithSearchApplied = async (
    sidePanels: SidePanelItemDefinition[],
    searchedText: string | undefined,
    sortDirect: SortDirection,
    sortType: SidePanelSearchType,
    sidePanelToRender: SidePanelItemDefinition,
    currentTab?: string,
): Promise<SidePanelItemDefinition[]> => {
    const { backendSearch, customBackendSearch } = sidePanelToRender;
    const mainCustomSortFn = sidePanelToRender.sort.customSortFn;
    const secondaryCustomSortFn = sidePanelToRender.secondarySort.customSortFn;

    const sortElements = (a: SidePanelItemDefinition, b: SidePanelItemDefinition) => {
        // Leave sticky at the top
        if (a.isSticky) {
            return -1;
        }
        if (b.isSticky) {
            return 1;
        }

        // Custom sort function
        if (sortType === SidePanelSearchType.Primary && mainCustomSortFn) {
            if (sortDirect === "asc") {
                return mainCustomSortFn(a.sort.customSortValue, b.sort.customSortValue);
            }
            return mainCustomSortFn(b.sort.customSortValue, a.sort.customSortValue);
        }

        // Custom sort function
        if (sortType === SidePanelSearchType.Secondary && secondaryCustomSortFn) {
            if (sortDirect === "asc") {
                return secondaryCustomSortFn(a.secondarySort.customSortValue, b.secondarySort.customSortValue);
            }
            return secondaryCustomSortFn(b.secondarySort.customSortValue, a.secondarySort.customSortValue);
        }

        // Alphabetical order sort
        if (sortDirect === "asc") {
            return a.title.localeCompare(b.title);
        }

        return b.title.localeCompare(a.title);
    };

    if (!searchedText) {
        return sidePanels.sort(sortElements);
    }

    const normalizeText = (text: string, toLower: boolean = true) => {
        let normalized = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if (toLower) {
            normalized = normalized.toLowerCase();
        }

        return normalized;
    };

    if (customBackendSearch) {
        const backendResults = await backendSearch(25, searchedText);

        if (backendResults) {
            return [...backendResults.values()][0].sort(sortElements);
        }

        return [];
    }

    const filterBySearchTerms = (
        items: SidePanelItemDefinition[],
        searchRegexes: RegExp[],
    ): [SidePanelItemDefinition[], string[]] => {
        const matchedSearchTerms: RegExpMatchArray[] = [];

        const filterResults = items.filter((e) => {
            if (e.searchTerms.length === 0) {
                return true;
            }

            const normalizedSearchTerms = e.searchTerms.map((x) => normalizeText(x));

            const searchTermsMatches = searchRegexes.reduce(
                (previous, current) => [...previous, ...normalizedSearchTerms.map((s) => s.match(current))],
                [] as RegExpMatchArray,
            );

            matchedSearchTerms.push(
                ...(searchTermsMatches.filter((x) => typeof x !== "string" && !!x) as RegExpMatchArray[]),
            );

            return searchTermsMatches.some((x) => !!x);
        });

        if (!filterResults.any()) {
            return [items, []];
        }

        return [filterResults, matchedSearchTerms.map((x) => x[0]).distinct()];
    };

    const filterByContent = (items: SidePanelItemDefinition[], searchRegexes: RegExp[]): SidePanelItemDefinition[] => {
        return items.filter((e: SidePanelItemDefinition) => {
            const normalizedTitle = normalizeText(e.title);

            const titleMatches = searchRegexes.map((x) => normalizedTitle.match(x));

            if (e.description) {
                const normalizedDescription = normalizeText(e.description);

                const descriptionMatches = searchRegexes.map((x) => normalizedDescription.match(x));

                return titleMatches.some((x) => !!x) || descriptionMatches.some((x) => !!x);
            }

            return titleMatches.some((x) => !!x);
        });
    };

    const removeMatchedRegexes = (searchRegexes: RegExp[], matchedRegexTerms: string[]) => {
        if (!matchedRegexTerms.any()) {
            return;
        }

        for (const matchedRegexTerm of matchedRegexTerms) {
            searchRegexes.splice(
                searchRegexes.findIndex((x) => x.source == new RegExp(matchedRegexTerm, "i").source),
                1,
            );
        }
    };

    const filterResults = (items: SidePanelItemDefinition[]) => {
        const searchRegexes = normalizeText(searchedText)
            .replace(/\s+/g, " ")
            .trim()
            .split(" ")
            .filter((x) => x.length > 1)
            .map((x) => new RegExp(x.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")), "i");

        if (!searchRegexes.any()) {
            return [];
        }

        const [searchTermsFilterResult, matchedRegexTerms] = filterBySearchTerms(items, searchRegexes);

        removeMatchedRegexes(searchRegexes, matchedRegexTerms);

        if (!searchRegexes.any()) {
            return searchTermsFilterResult;
        }

        return filterByContent(searchTermsFilterResult, searchRegexes);
    };

    if (sidePanelToRender.isSearchLimitedAtFirstChildren) {
        const items = currentTab
            ? sidePanelToRender.subElements.get(currentTab)
            : [...sidePanelToRender.subElements.values()][0];

        if (!items) {
            return [];
        }

        return filterResults(items)
            .map(
                (s) =>
                    new SidePanelItemDefinition({
                        ...s,
                        resetSearchOnClick: true,
                    }),
            )
            .sort(sortElements);
    }

    const allSubElements = sidePanels.flatMap((sidePanel) => {
        if (sidePanel.subElements.size === 0 || !sidePanel.isSubElementsSearchable) {
            return [sidePanel];
        }

        return [...sidePanel.subElements].flatMap(([_title, subs]) =>
            subs.map((s) => {
                const isSubElementNavigationFrozen = s.subElements.size === 0;

                return new SidePanelItemDefinition(
                    {
                        ...s,
                        label: sidePanel.title,
                        isSubElementNavigationFrozen,
                        resetSearchOnClick: !isSubElementNavigationFrozen,
                    },
                    sidePanel.getTopLevelRoot(),
                );
            }),
        );
    });

    return filterResults(allSubElements).sort(sortElements);
};
