import "./index.less";
import * as React from "react";
import { ApiError, FieldError } from "@atman/core";
import { FormFeedback, FormGroup, FormText, Input, InputProps, Label } from "reactstrap";
import { observer } from "mobx-react";

export interface ICustomValidatedTextInputProps extends InputProps {
    fieldName: string;
    value: any;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    placeholder?: string;
    validationErrors: Array<ApiError | FieldError> | ReadonlyArray<ApiError | FieldError>;
    formText?: string;
    label?: string;
    hideLabel?: boolean;
    groupContainer?: boolean;
    validationFieldNameOverride?: string;
    containerClassName?: string;
}

@observer
export class CustomValidatedTextInput extends React.Component<ICustomValidatedTextInputProps, {}> {
    render() {
        const {
            fieldName,
            value,
            onChange,
            validationErrors,
            formText,
            hideLabel = false,
            groupContainer,
            validationFieldNameOverride,
            containerClassName,
            label,
            placeholder,
            invalid,
            ...otherProps
        } = this.props;

        const fieldValidationError = validationErrors.find(
            (x) => x.fieldName === (validationFieldNameOverride ?? fieldName),
        );

        return (
            <FormGroup className={`CustomValidatedTextInput ${containerClassName ?? ""}`}>
                {!hideLabel ? <Label for={fieldName}>{label ?? `global.${fieldName}`.localize()}</Label> : null}
                <div className={groupContainer ? "group" : ""}>
                    <Input
                        type="text"
                        name={fieldName}
                        id={fieldName}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        invalid={invalid || !!fieldValidationError}
                        {...otherProps}
                    />
                    {fieldValidationError ? (
                        <FormFeedback>{fieldValidationError.message}</FormFeedback>
                    ) : formText ? (
                        <FormText>{formText}</FormText>
                    ) : null}
                </div>
            </FormGroup>
        );
    }
}
