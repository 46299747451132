import "./index.less";
import * as React from "react";
import { AtContainer, AtLink, LoadingIndicator } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../global/components/ProductSelector";
import { AtmanLogoFullDark } from "@atman/ui-kit/lib/static";
import { IntercomApi, LocalizationStore, UrlFormatter } from "@atman/business";
import { LoginAppStores } from "../../../index";
import { LoginFlowSwitcher } from "../../global/components/LoginFlowSwitcher";
import { MfaSignInContainer } from "./MfaSignIn";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { SsoRedirectContainer } from "./SsoRedirect";
import { SsoSignInContainer } from "./SsoSignIn";
import { SystemStateStore } from "../../data/stores/SystemStateStore";
import { Trans, t } from "@lingui/macro";
import { UnavailableSystem } from "../../global/components/UnavailableSystem/UnavailableSystem";
import { inject, observer } from "mobx-react";
import { useMemo } from "react";

export interface IEmployeeContainerProps extends RouteComponentProps {
    localizationStore?: LocalizationStore;
    systemStateStore?: SystemStateStore;
}

export const EmployeeContainer: React.FC<IEmployeeContainerProps> = inject<LoginAppStores, any, any, any>((stores) => ({
    localizationStore: stores.localizationStore,
    systemStateStore: stores.systemStateStore,
}))(
    observer((props) => {
        const { localizationStore, systemStateStore } = props;

        const termsConditionsUrl = UrlFormatter.getBlobFileUrl(
            "ServiceLicenseAgreement",
            localizationStore!.currentShortLocale,
        );

        const openChat = () => {
            IntercomApi.show();
        };

        const { available, activeState } = useMemo(
            () => systemStateStore!.getStateForPlatform(AtmanCoPlatformsEnum.ProNA),
            [systemStateStore!.systemStateInfos],
        );

        return (
            <div id="EmployeeContainer">
                <div className="content-container">
                    <LoginFlowSwitcher />
                    <AtContainer className="view-container">
                        <img src={AtmanLogoFullDark} width={160} />
                        {!systemStateStore!.hasLoadedSystemState ? (
                            <LoadingIndicator
                                size={"md"}
                                label={t({
                                    id: "loginApp.loginForm.loadingSystemStatus",
                                    message: "Making sure the system is available...",
                                })}
                            />
                        ) : available ? (
                            <Switch>
                                <Route path={"/Employee/SignIn/Mfa"} component={MfaSignInContainer} />
                                <Route path={"/Employee/SignIn/Sso/:orgId"} component={SsoSignInContainer} />
                                <Route path={"/Employee/SignIn/SsoRedirect/:orgId"} component={SsoRedirectContainer} />
                                <Redirect to={{ search: window.location.search, pathname: "/Employee/SignIn/Mfa" }} />
                            </Switch>
                        ) : (
                            <UnavailableSystem activeSystemState={activeState!} />
                        )}
                    </AtContainer>
                    <div className="links-container">
                        <AtLink href={termsConditionsUrl} target="_blank" color="secondary" size={"md"}>
                            <Trans id="global.general.termsAndConditions">Terms and conditions</Trans>
                        </AtLink>
                        <AtLink href="#" onClick={openChat} color="secondary" size={"md"}>
                            <Trans id="global.general.contactUs">Contact us</Trans>
                        </AtLink>
                    </div>
                </div>
            </div>
        );
    }),
);
