import "./index.less";
import { AtCheckboxBase } from "../../../internal";
import { FormGroup, Label } from "reactstrap";
import React from "react";
import cn from "classnames";

export interface IAtCheckboxProps {
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    value: any;
    fieldName: string;
    id: string;
    className?: string;
    disabled?: boolean;
    inverseColorsOnCheck?: boolean;
}

export const AtCheckbox: React.FC<IAtCheckboxProps> = (props) => {
    const { children, checked, disabled, fieldName, className, inverseColorsOnCheck = false, ...otherProps } = props;

    return (
        <FormGroup
            check
            className={cn("AtCheckbox", className, {
                checked: checked,
                "inverse-colors": inverseColorsOnCheck,
                disabled: disabled,
            })}
        >
            <Label check className={disabled ? "disabled" : ""}>
                <AtCheckboxBase checked={checked} disabled={disabled} fieldName={fieldName} {...otherProps} />{" "}
                {children}
            </Label>
        </FormGroup>
    );
};
