import * as React from "react";
import { CompetencyDevelopmentColumnType, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IPotentialTableHeaderColProps {
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
}

export const PotentialTableHeaderCol: React.FC<IPotentialTableHeaderColProps> = observer((props) => {
    const { sortElement, sortDirection, onSortChange } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("potential", sortElement, sortDirection, onSortChange);

    return (
        <TableHeader
            className="potential-col"
            {...selectionProps}
            tooltipContent={
                <div className="textSizeMedium">
                    {t({
                        id: "competencyDevelopment.review.tooltips.potential",
                    })}
                </div>
            }
        >
            {"competencyDevelopment.assessments.global.potentialScore".localize()}
        </TableHeader>
    );
});
