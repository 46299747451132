import "./index.less";
import * as React from "react";
import { ColorProperty } from "csstype";
import { Colors } from "../../../styles/variables/colors";
import { FitLevelEnum } from "@atman/business";
import { fade } from "@atman/design-system";
import { inject, observer } from "mobx-react";

export interface IFitScoreContainerProps {
    fitScore: FitLevelEnum;
}

@inject()
@observer
export class FitScoreContainer extends React.Component<IFitScoreContainerProps, {}> {
    render() {
        const { fitScore } = this.props;

        let color: ColorProperty;

        switch (fitScore) {
            case 0:
                color = Colors.redBase;
                break;
            case 1:
                color = Colors.yellowBase;
                break;
            case 2:
                color = Colors.greenBase;
                break;
            default:
                return null;
        }

        const bgColor = fade(color, 1);

        return <div className={`FitScoreContainer`} style={{ background: bgColor }} />;
    }
}
