export const DEFAULT_HALFPIE_REGULAR_WIDTH = 120;
export const DEFAULT_HALFPIE_COMPACT_WIDTH = 60;
export const DEFAULT_FULLPIE_REGULAR_WIDTH = 120;
export const DEFAULT_FULLPIE_COMPACT_WIDTH = 48;

export const HALFPIE_STARTANGLE = -90;
export const HALFPIE_ENDANGLE = 90;
export const HALFPIE_ANGLERAGNE = HALFPIE_ENDANGLE - HALFPIE_STARTANGLE;

export const THREEQUARTERPIE_STARTANGLE = -135;
export const THREEQUARTERPIE_ENDANGLE = 135;

export const HALFPIE_INNERRADIUS = 166;
export const HALFPIE_CORNERRADIUS = 20;
export const HALFPIE_PADDING = 6;
