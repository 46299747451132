import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";
import { CompetencyDevelopmentLearningResourceType } from "../CompDevEnums";

export interface ICompDevLearningResource extends IBaseCompetencyElement {
    readonly url: string;
    readonly language: string;
    readonly authors: string;
    readonly type: CompetencyDevelopmentLearningResourceType;
}

export class CompDevLearningResource extends BaseCompetencyElement implements ICompDevLearningResource {
    public readonly url: string;
    public readonly language: string;
    public readonly authors: string;
    public readonly type: CompetencyDevelopmentLearningResourceType;

    constructor(json: ICompDevLearningResource) {
        super(json);

        this.url = json.url;
        this.language = json.language;
        this.authors = json.authors;
        this.type = json.type;
    }
}
