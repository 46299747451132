import "./StepHeader.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { BaseResponsiveComponent, Default } from "../../BaseResponsiveComponent";
import { Label } from "reactstrap";

export interface IStepHeaderProps {
    stepNumber: number;
    title: string;
    description: string;
}

export class StepHeader extends BaseResponsiveComponent<IStepHeaderProps> {
    protected get rootElementClassName(): string | undefined {
        return "StepHeader";
    }

    renderAll() {
        const { description, stepNumber, title } = this.props;

        return (
            <>
                <div className="title-container">
                    <Default>
                        <div className={"step-number-container"}>{stepNumber}</div>
                    </Default>
                    <AtTitle headingType={1} title={title} />
                </div>
                <Label>{description}</Label>
            </>
        );
    }
}
