import "./index.less";
import * as React from "react";
import { AtTooltip, AtTooltipDisplayMode, IAtTooltipProps } from "../../atoms/AtTooltip";
import { Colors } from "../../../styles/variables/colors";
import { DisplayProperty } from "csstype";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { v4 } from "uuid";

export interface IAtIconTooltipProps {
    icon: IconProp;
    iconColor?: string;
    iconSize?: SizeProp;
    iconSizePx?: number;
    tooltipTargetId?: string;
    tooltipDisplayMode?: AtTooltipDisplayMode;
    tooltipProps?: Omit<IAtTooltipProps, "target" | "children">;
    showCircle?: boolean;
    className?: string;
    containerDisplayStyle?: DisplayProperty;
}

export const AtIconTooltip: React.FC<IAtIconTooltipProps> = (props) => {
    const {
        icon,
        iconColor = Colors.greyShades.shade4,
        iconSize,
        iconSizePx,
        tooltipTargetId,
        className,
        tooltipProps,
        tooltipDisplayMode,
        showCircle,
        children,
        containerDisplayStyle = "inline-block",
        ...otherProps
    } = props;

    const memoizedTooltipTargetId = React.useMemo(() => tooltipTargetId ?? `tooltip-target-${v4()}`, []);

    const getContainerClasses = () => {
        let classes: string = "AtIconTooltip";

        if (showCircle) {
            classes += " circle";
        }

        if (className) {
            classes += ` ${className}`;
        }

        return classes;
    };

    let containerStyle: React.CSSProperties = {
        display: containerDisplayStyle,
    };

    let iconStyle: React.CSSProperties | undefined;

    if (iconSizePx) {
        iconStyle = {
            width: iconSizePx,
            height: iconSizePx,
        };
        containerStyle = {
            width: iconSizePx * 2,
            height: iconSizePx * 2,
        };
    }

    return (
        <div id={memoizedTooltipTargetId} className={getContainerClasses()} style={containerStyle} {...otherProps}>
            <FontAwesomeIcon icon={icon} color={iconColor} size={iconSize} style={iconStyle} />
            <AtTooltip
                displayMode={tooltipDisplayMode}
                placement={tooltipProps?.placement ?? "right"}
                target={memoizedTooltipTargetId}
                {...tooltipProps}
            >
                {children}
            </AtTooltip>
        </div>
    );
};
