import "./index.less";
import { AtButton } from "../../molecules/AtButton";
import { AtButtonColors } from "../../atoms/AtButtonBase";
import { AtIcon } from "../../atoms/AtIcon";
import { AtTitle } from "../../atoms/AtTitle";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";
import cn from "classnames";

interface IAtModalProps {
    className?: string;
    children: JSX.Element;
    title?: string;
    titleAlignment?: "left" | "center" | "right";
    open: boolean;
    // closeFromOutsideModal?: boolean;
    // fixFooterToBottomModal?: boolean;
    setOpen: (visibility: boolean) => void;
    // className: string;
    actions?: {
        label: string;
        prefixIcon?: IconProp;
        clickHandler?: () => void;
        color?: string;
        className?: string;
    }[];
}

export const AtModal: React.FC<IAtModalProps> = (props) => {
    const { className, title, open, setOpen, children, actions, titleAlignment = "left" } = props;

    return (
        <Modal className={cn("AtModal", className)} isOpen={open} toggle={() => setOpen(!open)}>
            <div className="AtModalContainerContent">
                <ModalHeader className="AtModalHeader">
                    {title && (
                        <div className="title">
                            <AtTitle title={title} style={{ textAlign: titleAlignment }} headingType={1} noMargin />
                        </div>
                    )}
                </ModalHeader>
                <ModalBody className="AtModalBody">{children}</ModalBody>
            </div>

            {actions?.length && (
                <ModalFooter className="AtModalFooter">
                    {actions.map(
                        ({
                            prefixIcon,
                            color,
                            label,
                            clickHandler,
                            className,
                        }: {
                            // TODO
                            clickHandler?: () => void;
                            color?: AtButtonColors;
                            label: string;
                            prefixIcon?: IconProp;
                            className?: string;
                        }) => (
                            <AtButton key={label} color={color} onClick={clickHandler} className={className}>
                                {prefixIcon && <AtIcon icon={prefixIcon} color="#fff" />}
                                {label}
                            </AtButton>
                        ),
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
};
