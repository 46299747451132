import "./SelectedCompetenciesSectionFragment.less";
import * as React from "react";
import { AtHint, AtRemovableItem, AtTitle } from "@atman/design-system";
import { CompDevCompetency } from "@atman/business";
import { t } from "@lingui/macro";

export interface ISelectedCompetenciesSectionFragment {
    selectedCompetencies: CompDevCompetency[] | undefined;
    onCompetencyIdSelection: (id: string) => void;
}

export const SelectedCompetenciesSectionFragment: React.FC<ISelectedCompetenciesSectionFragment> = (props) => {
    const { selectedCompetencies, onCompetencyIdSelection } = props;

    return (
        <>
            <AtTitle title={t({ id: "global.entityWizard.overview.selectedCompetencies.title" })} />
            <AtHint color="warning">
                {t({ id: "global.entityWizard.overview.selectedCompetencies.description" })}
            </AtHint>
            <div id="EntityWizardOverview-competency-list">
                {selectedCompetencies && selectedCompetencies.length > 0 ? (
                    selectedCompetencies.map((x) => (
                        <AtRemovableItem
                            title={x.name}
                            onRemoveClick={() => {
                                onCompetencyIdSelection(x.id);
                            }}
                            key={`comp-${x.id}`}
                        />
                    ))
                ) : (
                    <>
                        <AtHint className="competency-placeholder" color="ghost" />
                        <AtHint className="competency-placeholder" color="ghost" />
                        <AtHint className="competency-placeholder" color="ghost" />
                        <AtHint className="competency-placeholder" color="ghost" />
                    </>
                )}
            </div>
        </>
    );
};
