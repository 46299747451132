import "./index.less";
import * as React from "react";
import {
    AssessmentResultsCalculationServiceLogic,
    DistributionViewMode,
    IDistributionEntry,
    IPersonScore,
    IScorePositionInfo,
    ScaleType,
} from "@atman/business";
import { BaseResponsiveComponent } from "../../../BaseResponsiveComponent";
import { ColorProperty } from "csstype";
import { ScoreContainer } from "../../../AssessmentResultsComponents/BaseAtScale/components/ScoreContainer"; // TODO: Make this generic
import { ScoreRangeHighlight } from "../../../AssessmentResultsComponents/BaseAtScale/components/ScoreRangeHighlight"; // TODO: Make this generic
import { inject, observer } from "mobx-react";

export const STANINE_SCALE_SEPARATORS = [3, 6];
export const LIKERT_SCALE_SEPARATORS = [1, 4];

export interface IScaleProps {
    scaleType: ScaleType;
    maxScore: number;
    scores?: IPersonScore[]; // TODO: Change this IPersonScore for something more generic. (Ex.: Local IScore)
    isDecimalScale?: boolean;
    distributionEntries?: IDistributionEntry[];
    distributionAverage?: number;
    showDistribution?: boolean;
    separators?: Array<number>; // Array of scores where you want to put vertical separators. Use with predefined const like STANINE_SCALE_SEPARATORS.
    distributionViewMode?: DistributionViewMode;
    colorMapping?: Map<number, ColorProperty>;
    colorMappingFunc?: (value: number) => ColorProperty;
}

@inject()
@observer
export class Scale extends BaseResponsiveComponent<IScaleProps> {
    public static defaultProps = {
        isDecimalScale: false,
        separators: [],
    };

    render() {
        const {
            scores,
            maxScore,
            distributionEntries,
            distributionAverage,
            showDistribution,
            distributionViewMode,
            scaleType,
            colorMapping,
            colorMappingFunc,
            isDecimalScale,
            separators,
        } = this.props;

        if (!scores) {
            return <></>;
        }

        let scoreElementsArray: React.ReactNode[] = [];
        let currentScoreRangePosition: IScorePositionInfo | null = null;

        if (isDecimalScale) {
            const scorePositionInfos = AssessmentResultsCalculationServiceLogic.getScorePositionsInfo(
                scaleType,
                maxScore,
            );
            for (let i = 1; i <= maxScore; i++) {
                const scoreInfos = scores.filter((x) => x.score === i);
                const matchingDistributionEntry = distributionEntries?.find((x) => x.scoreKey === i);

                const scorePositionInfo = scorePositionInfos[i];

                scoreElementsArray.push(
                    <ScoreContainer
                        key={`${i}-scorecontainer`}
                        positionKey={i}
                        positionInfo={scorePositionInfo}
                        scoreInfos={scoreInfos}
                        distributionEntry={matchingDistributionEntry}
                        distributionViewMode={distributionViewMode}
                        showDistribution={showDistribution}
                        scaleType={scaleType}
                        isDistributionAverage={distributionAverage === i ?? false}
                        colorMapping={colorMapping}
                        colorMappingFunc={colorMappingFunc}
                        showSeparator={separators!.some((v) => v === i)}
                    />,
                );

                if (showDistribution && distributionViewMode === "numbers") {
                    if (matchingDistributionEntry?.isRange) {
                        if (!currentScoreRangePosition) {
                            currentScoreRangePosition = {
                                left: scorePositionInfo.left,
                                width: scorePositionInfo.width,
                            };
                        } else {
                            currentScoreRangePosition.width += scorePositionInfo.width;
                        }
                    }

                    if (currentScoreRangePosition && (!matchingDistributionEntry?.isRange || i === maxScore)) {
                        scoreElementsArray.push(
                            <ScoreRangeHighlight
                                key={`${i}-scorerangehighlight`}
                                positionInfo={currentScoreRangePosition}
                            />,
                        );
                        currentScoreRangePosition = null;
                    }
                }
            }
        } else {
            // Only supports unipolar scales for the moment
            scoreElementsArray = scoreElementsArray.concat(
                scoreElementsArray,
                scores.map((score, i) => {
                    const scorePositionInfo = AssessmentResultsCalculationServiceLogic.getUnipolarScorePositionInfo(
                        score.score,
                        maxScore,
                    );

                    return (
                        <ScoreContainer
                            key={`${i}-scorecontainer`}
                            positionKey={i}
                            positionInfo={scorePositionInfo}
                            scoreInfos={[score]}
                            scaleType={scaleType}
                            colorMapping={colorMapping}
                            colorMappingFunc={colorMappingFunc}
                            showSeparator={separators!.some((v) => v === i)}
                        />
                    );
                }),
            );
        }

        return <>{scoreElementsArray}</>;
    }
}
