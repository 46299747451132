import * as React from "react";
import { GlobalStores, RouteConfigEntry, RouteWithSubRoutes, UserInfoStore } from "@atman/business";
import { NotFound } from "../../views";
import { Redirect, Route, Switch } from "react-router-dom";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";

export interface IRoutesProps {
    routes: RouteConfigEntry[];
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
export class RouteSwitcher extends React.Component<IRoutesProps> {
    @computed get authorizedRoutes(): RouteConfigEntry[] {
        const { routes, userInfoStore } = this.props;

        return routes.filter((x) => {
            if (x.enabled === false) {
                return false;
            }

            if (!x.requiredRoles || !x.requiredRoles.any()) {
                return true;
            }

            switch (x.requiredRolesMatchType) {
                case "anyOf":
                    return userInfoStore!.hasOneOfRoles(x.requiredRoles);
                case "allOf":
                default:
                    return userInfoStore!.hasAllRoles(x.requiredRoles);
            }
        });
    }

    render() {
        const {} = this.props;

        const defaultRoute = this.authorizedRoutes.find((x) => x.default);
        const firstRootRoute = this.authorizedRoutes.filter((x) => !x.fullPath.includes(":")).firstOrDefault();

        return (
            <Switch>
                {this.authorizedRoutes.map((route, i) => {
                    return <RouteWithSubRoutes key={i} {...route.routeWithSubRoutesProps} />;
                })}
                {defaultRoute && <Redirect to={defaultRoute.fullPath} />}
                {firstRootRoute && <Redirect to={firstRootRoute.fullPath} />}
                <Route component={NotFound} />
            </Switch>
        );
    }
}
