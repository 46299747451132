import { useEffect, useState } from "react";

export const useStyleTitleSvg = (locale: string, uniqueIdentifiableKey: string, colorFolder: string) => {
    const [svg, setSvg] = useState<string>("");

    const loadSvg = async () => {
        if (!locale || !colorFolder || !uniqueIdentifiableKey) {
            return;
        }

        const svg = await import(
            /* webpackMode: "lazy", webpackChunkName: "style-svg-[index]" */
            `../components/AtStyleSvgs/titleStyleSvgs/${locale}/${colorFolder}/${uniqueIdentifiableKey}.svg`
        );

        setSvg(svg.default);
    };

    useEffect(() => {
        loadSvg().catch(console.error);
    }, [uniqueIdentifiableKey, locale, colorFolder]);

    return svg;
};
