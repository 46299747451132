import "./PasswordRule.less";
import * as React from "react";
import { Colors } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";

export type PasswordRuleType = "lowercaseChar" | "uppercaseChar" | "numberChar" | "minLength";

export interface IPasswordRuleProps {
    passwordValue: string;
    ruleType: PasswordRuleType;
    ruleLabelOverride?: string;
}

export const PasswordRule: React.FC<IPasswordRuleProps> = (props) => {
    const { passwordValue, ruleType, ruleLabelOverride } = props;

    let isValid: boolean = false;
    const isEmpty: boolean = !passwordValue;

    switch (ruleType) {
        case "lowercaseChar":
            isValid = /(?=.*[a-z])/.test(passwordValue);
            break;
        case "uppercaseChar":
            isValid = /(?=.*[A-Z])/.test(passwordValue);
            break;
        case "numberChar":
            isValid = /(?=.*\d)/.test(passwordValue);
            break;
        case "minLength":
            isValid = passwordValue.length >= 8;
    }

    let color: string;

    if (isEmpty) {
        color = "#82868A";
    } else if (isValid) {
        color = Colors.greenBase;
    } else {
        color = Colors.redBase;
    }

    return (
        <div className="PasswordRule">
            <FontAwesomeIcon icon={["far", "check"] as IconProp} color={color} />
            <Label>{ruleLabelOverride ?? `global.passwordRules.${ruleType}`.localize()}</Label>
        </div>
    );
};
