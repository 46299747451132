import { BaseCrudStore } from "../base";
import { IWorkspace, IWorkspaceInput, Workspace } from "../models";
import { WorkspacesApi } from "../apis";
import { action } from "mobx";

export class WorkspaceStore extends BaseCrudStore<IWorkspace, Workspace, IWorkspaceInput> {
    protected _modelCtor = Workspace;

    protected _loadItems = () => WorkspacesApi.getWorkspaces();
    protected _createItem = (input: IWorkspaceInput) => WorkspacesApi.createWorkspace(input);
    protected _updateItem = (id: string, input: IWorkspaceInput) => WorkspacesApi.updateWorkspace(id, input);
    protected _deleteItem = (id: string) => WorkspacesApi.deleteWorkspace(id);

    @action
    updateWorkspaceProfilesCount = (id: string, numberOfProfilesToAdd: number) => {
        const index = this.findItemIndexById(id);

        if (index >= 0) {
            this._items[index].usageCount += numberOfProfilesToAdd;
        }
    };
}
