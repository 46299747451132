import "./index.less";
import { GlobalStores, LocalizationStore } from "@atman/business";
import { inject, observer } from "mobx-react";

import { useStyleTitleSvg } from "../../..";
import React from "react";
import cn from "classnames";
export interface IAtStyleTitleProps {
    uniqueIdentifiableKey: string | undefined;
    localizationStore?: LocalizationStore;
    isInTeamLeadSection?: boolean;
}

export const AtStyleSvgs: React.FC<IAtStyleTitleProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { uniqueIdentifiableKey, localizationStore, isInTeamLeadSection } = props;

        const locale = localizationStore!.currentLocale.shortName;
        const colorFolder = isInTeamLeadSection ? "grayout" : "color";

        const styleSvg = useStyleTitleSvg(locale, uniqueIdentifiableKey!, colorFolder);

        if (!styleSvg) {
            return null;
        }

        return (
            <div className={cn("AtStyleSvgs", isInTeamLeadSection && uniqueIdentifiableKey)}>
                <img src={styleSvg} alt="styles svg" />
            </div>
        );
    }),
);
