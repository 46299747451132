import "./index.less";
import { AtTitle, AtValueColor, ValueColorRenderMode } from "../../../internal";
import React from "react";

export interface ILabelValueProps {
    className?: string;
    title: string;
    value?: number | string;
    renderMode: ValueColorRenderMode;
    textColor?: string;
    backgroundColor?: string;
    borderColor?: string;
    customElement?: JSX.Element;
}

export const LabelValue: React.FC<ILabelValueProps> = ({
    className,
    title,
    value,
    renderMode,
    textColor,
    backgroundColor,
    borderColor,
    customElement,
    ...otherProps
}) => {
    return (
        <div className={`LabelValue ${className ?? ""}`} {...otherProps}>
            {customElement ? customElement : <AtTitle title={title} />}

            <AtValueColor
                value={value}
                renderMode={renderMode}
                textColor={textColor}
                bgColor={backgroundColor}
                borderColor={borderColor}
            />
        </div>
    );
};
