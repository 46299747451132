import { BasePlatformRootStore, BaseStore } from "../../base";
import { EmployeeDevelopmentProcess } from "./models";
import { action, observable, runInAction } from "mobx";
import EmployeeDevelopmentApi, { IEmployeeDevelopmentApi } from "./EmployeeDevelopmentApi";

export class EmployeeDevelopmentStore extends BaseStore {
    private _api: IEmployeeDevelopmentApi;

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);

        this._api = new EmployeeDevelopmentApi();
    }

    @observable public hasLoadedEmployeeCompDevProcesses: boolean = false;
    @observable public employeeCompDevProcesses: EmployeeDevelopmentProcess[] = [];

    getEmployeeCompDevProcessById = (id: string): EmployeeDevelopmentProcess | undefined =>
        this.employeeCompDevProcesses?.find((dev) => dev.id === id);

    @action loadEmployeeCompDevProcesses = async (): Promise<void> => {
        const result = await this._api.getCompDevProcesses();

        runInAction(() => {
            this.employeeCompDevProcesses = result.map((dev) => new EmployeeDevelopmentProcess(dev));
            this.hasLoadedEmployeeCompDevProcesses = true;
        });
    };

    @action loadEmployeeCompDevProcess = async (processId: string): Promise<void> => {
        const result = await this._api.getCompDevProcess(processId);

        runInAction(() => {
            const index = this.employeeCompDevProcesses.findIndex((x) => x.id === processId);

            if (index >= 0) {
                this.employeeCompDevProcesses[index] = new EmployeeDevelopmentProcess(result);
            } else {
                this.employeeCompDevProcesses.push(new EmployeeDevelopmentProcess(result));
            }

            this.hasLoadedEmployeeCompDevProcesses = true;
        });
    };
}
