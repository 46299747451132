import "./index.less";
import * as React from "react";
import { ApiError, FieldError, textWithLocaleInParentheses } from "@atman/core";
import { AtTitle, Colors, MaxNumberCounter } from "../../../internal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, InputProps } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";

export interface ILocalizedTextInputProps extends InputProps {
    fieldName: string;
    currentLocale: string;
    labelKey?: string;
    value: string | ReadonlyArray<string> | number;
    validationErrors?: Array<ApiError | FieldError>;
    validationFieldNameOverride?: string;
    type?: "text" | "textarea";
    formText?: string;
    icon?: IconProp;
    rows?: number;
    maxLength?: number;
}

export const LocalizedTextInput: React.FC<ILocalizedTextInputProps> = observer((props) => {
    const {
        fieldName,
        currentLocale,
        labelKey,
        value,
        type = "text",
        rows = 4,
        maxLength,
        icon,
        id,
        validationErrors,
        validationFieldNameOverride,
        ...otherProps
    } = props;

    let textAreaAdditionalProps = {};

    if (type === "textarea") {
        textAreaAdditionalProps = {
            rows: rows,
        };
    }

    const validationErrorsForField = validationErrors?.filter(
        (x) => x.fieldName === (validationFieldNameOverride ?? fieldName),
    );

    return (
        <>
            <div className="d-flex justify-content-between">
                {labelKey !== undefined && <AtTitle title={textWithLocaleInParentheses(labelKey, currentLocale)} />}
                {maxLength !== undefined && (
                    <MaxNumberCounter currentNumber={value.toString().length} maxNumber={maxLength} />
                )}
            </div>

            <div className={`LocalizedTextInput ${fieldName}-input ${icon ? "has-icon" : ""}`}>
                {icon && (
                    <div className="left-icon-container">
                        <FontAwesomeIcon icon={icon} color={Colors.greyShades.shade4} />
                    </div>
                )}
                <InputGroup>
                    <Input
                        type={type}
                        name={fieldName}
                        id={id ?? fieldName}
                        value={value}
                        {...otherProps}
                        {...textAreaAdditionalProps}
                        maxLength={maxLength}
                        invalid={validationErrorsForField?.any()}
                    />
                    <InputGroupAddon addonType={"append"}>
                        <InputGroupText>{currentLocale.toUpperCase()}</InputGroupText>
                    </InputGroupAddon>
                    {validationErrorsForField?.map((x) => (
                        <FormFeedback key={x.name}>{x.message}</FormFeedback>
                    ))}
                </InputGroup>
            </div>
        </>
    );
});
