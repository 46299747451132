import "./index.less";
import * as React from "react";
import { AtPersonAvatar } from "@atman/design-system";
import { AtScoreReviewIndicator } from "../../../../../../../AtScoreReviewIndicator";
import { AverageScoreDisplay, AverageScoreDisplayMode } from "../../../../../../../AverageScoreDisplay";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../../../..";
import { getRoundedScore } from "@atman/business";
import { useDetectDevice } from "@atman/core";

export interface IDetailedScoreColumnsProps {
    reviewScore: number | undefined;
    averageScore: number | undefined;
    managerScore: number | undefined;
    employeeScore: number | undefined;
    averageScoreDisplayMode?: AverageScoreDisplayMode;
    disableScoreReviewIndicator?: boolean;
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    columns: CompetencyDevelopmentColumnType[];
    hiddenColumns?: CompetencyDevelopmentColumnType[];
}

export const DetailedScoreColumns: React.FC<IDetailedScoreColumnsProps> = (props) => {
    const {
        reviewScore,
        averageScore,
        employeeScore,
        managerScore,
        averageScoreDisplayMode,
        disableScoreReviewIndicator,
        managerInfo,
        employeeInfo,
        columns,
        hiddenColumns = [],
    } = props;

    const { isMobile } = useDetectDevice();

    const renderMobile = () => {
        return (
            <div className="DetailedScoreColumns">
                <div className="section-left">
                    {columns.includes("employeeScore") && !hiddenColumns.includes("employeeScore") && (
                        <div className="score-container">
                            <AtPersonAvatar
                                firstName={employeeInfo.firstName}
                                lastName={employeeInfo.lastName}
                                email={employeeInfo.email}
                                pictureUri={employeeInfo.pictureUri}
                            />
                            <div className="score">{getRoundedScore(employeeScore)}</div>
                        </div>
                    )}
                    {columns.includes("managerScore") && !hiddenColumns.includes("managerScore") && (
                        <div className="score-container">
                            <AtPersonAvatar
                                firstName={managerInfo.firstName}
                                lastName={managerInfo.lastName}
                                email={managerInfo.email}
                                pictureUri={managerInfo.pictureUri}
                            />
                            <div className="score">{getRoundedScore(managerScore)}</div>
                        </div>
                    )}
                    {columns.includes("spread") && !hiddenColumns.includes("spread") && (
                        <div className="score-container">
                            <div className="score spread">
                                {employeeScore && managerScore
                                    ? `± ${Math.abs(employeeScore - managerScore).toFixed(1)}`
                                    : "-"}
                            </div>
                        </div>
                    )}
                </div>
                {columns.includes("averageScore") && !hiddenColumns.includes("averageScore") && (
                    <div className="section-right">
                        <div className={"score-container"}>
                            <AverageScoreDisplay
                                reviewScore={reviewScore}
                                averageScore={averageScore}
                                averageScoreDisplayMode={averageScoreDisplayMode}
                            />
                            {reviewScore && !disableScoreReviewIndicator && (
                                <AtScoreReviewIndicator
                                    score={averageScore}
                                    scoreDisplayMode={"average"}
                                    averageScoreDisplayMode={averageScoreDisplayMode}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderDefault = () => {
        return (
            <>
                {columns.includes("averageScore") && !hiddenColumns.includes("averageScore") && (
                    <div className="average-note-col content-col">
                        <AverageScoreDisplay
                            reviewScore={reviewScore}
                            averageScore={averageScore}
                            averageScoreDisplayMode={averageScoreDisplayMode}
                        />
                        {reviewScore && !disableScoreReviewIndicator && (
                            <AtScoreReviewIndicator
                                score={averageScore}
                                scoreDisplayMode={"average"}
                                averageScoreDisplayMode={averageScoreDisplayMode}
                            />
                        )}
                    </div>
                )}
                {columns.includes("employeeScore") && !hiddenColumns.includes("employeeScore") && (
                    <div className="employee-note-col content-col">{getRoundedScore(employeeScore)}</div>
                )}
                {columns.includes("managerScore") && !hiddenColumns.includes("managerScore") && (
                    <div className="manager-note-col content-col">{getRoundedScore(managerScore)}</div>
                )}
                {columns.includes("spread") && !hiddenColumns.includes("spread") && (
                    <div className="spread-col content-col">
                        {employeeScore && managerScore ? `± ${Math.abs(employeeScore - managerScore).toFixed(1)}` : "-"}
                    </div>
                )}
            </>
        );
    };

    return isMobile ? renderMobile() : renderDefault();
};
