import "./BaseBreadcrumbs.less";
import * as H from "history";
import * as React from "react";
import { AppScope, IAppContext, IRouteConfigEntry, ReactAppSettings } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { computed } from "mobx";
import { match, matchPath } from "react-router";

export interface IAutoBreadcrumbsProps extends IAppContext {
    breadcrumbs: IBreadcrumbData[];
}

export abstract class BaseBreadcrumbs<TProps = {}, TState = {}> extends React.Component<
    IAutoBreadcrumbsProps & TProps,
    TState
> {
    protected abstract appRoutes: IRouteConfigEntry[];

    protected get additionalFilterExpression(): (
        value: IBreadcrumbData,
        index: number,
        array: IBreadcrumbData[],
    ) => boolean {
        return () => true;
    }

    @computed protected get filteredBreadcrumbs(): IBreadcrumbData[] {
        const { breadcrumbs } = this.props;

        const keysToExclude: string[] = ["/"];

        const splitBasePath = ReactAppSettings.basePath.substr(1).split("/");
        const scopePath = `/${splitBasePath[0]}`;
        const idPath = `/${splitBasePath[1]}`;

        keysToExclude.push(scopePath);
        keysToExclude.push(`${scopePath}${idPath}`);

        return breadcrumbs.filter((x, i, array) => {
            return (
                !keysToExclude.includes(x.key) &&
                this.filterNullBreadcrumbElements(x) &&
                this.additionalFilterExpression(x, i, array)
            );
        });
    }

    filterNullBreadcrumbElements(breadcrumbElement: IBreadcrumbData) {
        const reduceAction: (prevValue: IRouteConfigEntry[], currValue: IRouteConfigEntry) => IRouteConfigEntry[] = (
            previousValue,
            { subRoutes: currSubRoutes, ...currOtherProps },
        ) => {
            return [...previousValue, ...(currSubRoutes?.reduce(reduceAction, []) ?? []), { ...currOtherProps }];
        };

        const flattenedAppRoutes: IRouteConfigEntry[] = this.appRoutes.reduce(reduceAction, []);

        const matchingAppRoute = flattenedAppRoutes.find((route) =>
            matchPath(breadcrumbElement.key, {
                path: route.path,
                exact: true,
                strict: false,
            }),
        );

        if (!matchingAppRoute) {
            return true;
        }

        return !!matchingAppRoute.breadcrumb;
    }

    render() {
        const { scope } = this.props;

        return (
            <div id="Breadcrumbs">
                {this.filteredBreadcrumbs.map((x, i, array) => {
                    let breadcrumb = x.breadcrumb;

                    if (x.key === ReactAppSettings.basePath) {
                        // MARK: Root Path
                        switch (scope) {
                            case AppScope.Client:
                            case AppScope.Manager:
                            case AppScope.Employee:
                                breadcrumb = ReactAppSettings.viewBag.baseInfo.clientName.toUpperCase();
                                break;
                            case AppScope.Partner:
                                breadcrumb = ReactAppSettings.viewBag.baseInfo.partnerName.toUpperCase();
                                break;
                            case AppScope.Supplier:
                                breadcrumb = "AtmanCo".toUpperCase();
                                break;
                        }
                    }

                    const breadcrumbItem = (
                        <Link to={x.key} className={"custom-breadcrumb-item"} key={`breadcrumb-${i}`}>
                            {breadcrumb}
                        </Link>
                    );

                    const returnValue = [breadcrumbItem];

                    const isLastItem = i + 1 === array.length;

                    if (!isLastItem) {
                        returnValue.push(
                            <FontAwesomeIcon
                                key={`caret-${i}`}
                                className={"caret-icon"}
                                icon={["far", "angle-right"]}
                            />,
                        );
                    }

                    return returnValue;
                })}
            </div>
        );
    }
}

export interface IBreadcrumbData<TState = any, TMatchParams = any> {
    breadcrumb: React.ReactNode | string;
    key: string;
    location: H.Location<TState>;
    match: match<TMatchParams>;
}
