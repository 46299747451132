import { BorderStyleProperty, ColorProperty } from "csstype";
import { Colors } from "../variables/colors";

/**
 * This class serves as a Static Library to access design-system border styles in Typescript
 * TODO: Consider using webpack-loader to get the values directly from borders.less file to avoid having to maintain both
 */
export class Borders {
    public static readonly layoutBorderColor = Colors.greyShades.shade9;
    public static readonly cardBorderColor = Colors.greyShades.shade8; // TODO: Discuss with Design Team why this value was changed to shade9
    public static readonly inputBorderColor = Colors.greyShades.shade7;

    /**
     * Returns css borderRight & borderLeft properties based on a borderColorStyle
     *
     * @param borderColorStyle - the color style of the border ('layout' | 'card' | 'input')
     * @param width - the width of the border
     * @param style - the style of the border
     * @returns The css borderTop & borderBottom properties built from the provided parameters
     */
    public static getHorizontalBorder(
        borderColorStyle: BorderColorStyle,
        width: number = 1,
        style: BorderStyleProperty = "solid",
    ): Partial<React.CSSProperties> {
        const borderValue = Borders.getBorderValue(borderColorStyle, width, style);

        return {
            borderLeft: borderValue,
            borderRight: borderValue,
        };
    }

    /**
     * Returns css borderTop & borderBottom properties based on a borderColorStyle
     *
     * @param borderColorStyle - the color style of the border ('layout' | 'card' | 'input')
     * @param width - the width of the border
     * @param style - the style of the border
     * @returns The css borderTop & borderBottom properties built from the provided parameters
     */
    public static getVerticalBorder(
        borderColorStyle: BorderColorStyle,
        width: number = 1,
        style: BorderStyleProperty = "solid",
    ): Partial<React.CSSProperties> {
        const borderValue = Borders.getBorderValue(borderColorStyle, width, style);

        return {
            borderTop: borderValue,
            borderBottom: borderValue,
        };
    }

    /**
     * Returns css border properties based on a borderColorStyle and borderPositions
     *
     * @param borderColorStyle - the color style of the border ('layout' | 'card' | 'input')
     * @param borderPositions - the positions where to apply the border
     * @param width - the width of the border
     * @param style - the style of the border
     * @returns The css border properties built from the provided parameters
     */
    public static getCustomBorder(
        borderColorStyle: BorderColorStyle,
        borderPositions: BorderPositions,
        width: number = 1,
        style: BorderStyleProperty = "solid",
    ): Partial<React.CSSProperties> {
        const borderValue = Borders.getBorderValue(borderColorStyle, width, style);

        const cssProperties: Partial<React.CSSProperties> = {};

        if (borderPositions.top) {
            cssProperties.borderTop = borderValue;
        }

        if (borderPositions.right) {
            cssProperties.borderRight = borderValue;
        }

        if (borderPositions.bottom) {
            cssProperties.borderBottom = borderValue;
        }

        if (borderPositions.left) {
            cssProperties.borderLeft = borderValue;
        }

        return cssProperties;
    }

    /**
     * Returns a css border style value based on a borderColorStyle
     *
     * @param borderColorStyle - the color style of the border ('layout' | 'card' | 'input')
     * @param width - the width of the border
     * @param style - the style of the border
     * @returns The css style value built from the provided parameters
     */
    public static getBorderValue(
        borderColorStyle: BorderColorStyle,
        width: number = 1,
        style: BorderStyleProperty = "solid",
    ): string {
        return `${Borders.getBorderColorForColorStyle(borderColorStyle)} ${width}px ${style}`;
    }

    /**
     * Returns a color based on a borderColorStyle
     *
     * @param borderColorStyle - the color style of the border ('layout' | 'card' | 'input')
     * @returns The css color matching the provided `borderColorStyle`
     */
    public static getBorderColorForColorStyle(borderColorStyle: BorderColorStyle): ColorProperty {
        let color: ColorProperty;

        switch (borderColorStyle) {
            case "layout":
                color = Borders.layoutBorderColor;
                break;
            case "card":
                color = Borders.cardBorderColor;
                break;
            case "input":
                color = Borders.inputBorderColor;
                break;
            default:
                console.warn(
                    `[Borders] Could not recognize borderColorStyle: ${borderColorStyle}. Defaulting to 'greyBase'`,
                );
                color = Colors.greyBase;
                break;
        }

        return color;
    }
}

export type BorderColorStyle = "layout" | "card" | "input";
export type BorderPositions = {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
};
