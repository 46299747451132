import "./index.less";
import * as React from "react";
import { AtCard, Colors, Sizing } from "@atman/design-system";
import { CompetencyDevelopmentLearningResourceType } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";

export interface ILearningResourceFilterProps {
    label: string;
    value: CompetencyDevelopmentLearningResourceType | "all";
    iconProp?: IconProp;
    isSelected: boolean;
    onClick: (value: CompetencyDevelopmentLearningResourceType | "all") => void;
    removePadding?: boolean;
}

export const LearningResourceFilter: React.FC<ILearningResourceFilterProps> = observer((props) => {
    const { label, value, iconProp, isSelected, onClick, removePadding = false } = props;

    let backgroundColor: string | undefined = undefined;

    if (isSelected) {
        backgroundColor = Colors.greyShades.shade9;
    }

    return (
        <AtCard
            className="LearningResourceFilter"
            borderRadius={Sizing.pixels.xxs}
            padding={removePadding ? null : Sizing.paddings.xxs}
            onClick={() => onClick(value)}
            backgroundColor={backgroundColor}
        >
            {iconProp && <FontAwesomeIcon icon={iconProp} />}
            <span>{label}</span>
        </AtCard>
    );
});
