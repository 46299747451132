import "./index.less";
import { AtBaseCard, DefaultComponentProps } from "@atman/design-system";
import { AtTableColumn } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAtTableCardProps<T extends IBaseEntity> extends DefaultComponentProps {
    rowKey: string;
    columns: AtTableColumn<T>[];
    item: T;
}

function AtTableCardRaw<T extends IBaseEntity>(props: IAtTableCardProps<T>) {
    const { columns, item, className, ...otherProps } = props;

    return (
        <AtBaseCard className={cn("AtTableRowCard", className)} removeLabelWrapper {...otherProps}>
            <div className="cardContainer">
                {columns.map((column) => {
                    const { render, key, fieldName, hideOnCardDisplay } = column;

                    if (hideOnCardDisplay || !render) {
                        return null;
                    }

                    const value = item[fieldName];

                    return (
                        <div key={key} className="cardsItem">
                            {render(value, item)}
                        </div>
                    );
                })}
            </div>
        </AtBaseCard>
    );
}

export const AtTableRowCard = observer(AtTableCardRaw);
