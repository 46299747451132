import "./index.less";
import { AtText, DefaultSizes, XL, XS } from "../../../../../..";
import React from "react";
import cn from "classnames";
export interface ITableItemLabel {
    content: string;
    size?: XS | DefaultSizes | XL;
    className?: string;
    maxWidth?: number;
}

/**
 *
 * @param props maxWidth need to be equal than columns width
 */
export const TableItemLabel: React.FC<ITableItemLabel> = (props: ITableItemLabel) => {
    const { size = "md", content, className, maxWidth } = props;

    return (
        <div className={cn("TableItemLabel", className)} style={{ maxWidth: `${maxWidth}px` }}>
            <AtText size={size}>{content}</AtText>
        </div>
    );
};
