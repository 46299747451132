import "./index.less";
import * as React from "react";
import { AtPersonAvatar, DefaultComponentProps, DefaultSizes, XL, XXL } from "../../../internal";

export interface IAtPersonCard extends DefaultComponentProps {
    firstName?: string;
    lastName?: string;
    email?: string;
    pictureUri?: string;
    pictureFormatFunc?: (pictureUrl: string, size: DefaultSizes | XL | XXL) => string;
    isHighlighted?: boolean;
    isInteractive?: boolean;
    onClick?: () => void;
}

export const AtPersonCard: React.FC<IAtPersonCard> = (props: IAtPersonCard) => {
    const {
        firstName,
        lastName,
        email,
        pictureUri,
        pictureFormatFunc,
        isHighlighted = false,
        isInteractive,
        onClick,
        id,
        className,
        style,
        ...otherProps
    } = props;

    const [isHovering, setIsHovering] = React.useState(false);

    const getClasses: () => string = () => {
        let classes: string = "AtPersonCard";

        if (className) {
            classes += ` ${className}`;
        }

        if (isInteractive && onClick) {
            classes += " interactive";
        }

        if (isHighlighted) {
            classes += " highlighted";
        }

        if (isHovering) {
            classes += " hovered";
        }

        return classes;
    };

    const handleMouseEnter: () => void = () => {
        if (isInteractive) {
            setIsHovering(true);
        }
    };

    const handleMouseLeave: () => void = () => {
        if (isInteractive) {
            setIsHovering(false);
        }
    };

    return (
        <div
            key={id}
            className={getClasses()}
            id={id}
            onClick={isInteractive ? onClick : undefined}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={style}
            {...otherProps}
        >
            <div className="d-flex justify-content-center align-items-center">
                <AtPersonAvatar
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    pictureUri={pictureUri}
                    pictureFormatFunc={pictureFormatFunc}
                    size="xs"
                    // borderColor={isHighlighted || isHovering ? Colors.atmanOrange : Colors.greyShades.shade9}
                />
            </div>
            <div className="person-name">{`${firstName} ${lastName}`.trim()}</div>
        </div>
    );
};
