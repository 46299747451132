import "./index.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IClientConsumptionSettings,
    IPartnerConsumptionSettings,
    PartnerStore,
    ProRestrictionType,
    ReactAppSettings,
    UnhandledScopeError,
} from "@atman/business";
import { AtTabCard, RadioFormInput } from "@atman/design-system";
import { CardSaveButtons } from "../CardSaveButtons";
import { CustomTextInput } from "../CustomTextInput";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IConsumptionRestrictionFormProps extends IAppContext {
    consumptionSettings: IPartnerConsumptionSettings | IClientConsumptionSettings;
    ownerId: string;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
}

@inject(GlobalStores.clientStore, GlobalStores.partnerStore)
@observer
class ConsumptionRestrictionFormComp extends React.Component<IConsumptionRestrictionFormProps, {}> {
    @observable public restrictionType: ProRestrictionType;
    @observable public discountRate: number;

    componentDidMount(): void {
        this.resetConsumptionRestriction();
    }

    @computed
    get isLoading(): boolean {
        const { scope, clientStore, partnerStore } = this.props;

        let isLoading: boolean = false;

        switch (scope) {
            case AppScope.Client:
                isLoading = clientStore!.isLoading;
                break;
            case AppScope.Partner:
                if (this.props.ownerId) {
                    isLoading = clientStore!.isLoading;
                } else {
                    isLoading = partnerStore!.isLoading;
                }
                break;
            case AppScope.Supplier:
                isLoading = partnerStore!.isLoading;
                break;
        }

        return isLoading;
    }

    @autobind
    async saveConsumptionRestriction() {
        const { consumptionSettings, partnerStore, clientStore, scope, ownerId } = this.props;

        switch (scope) {
            case AppScope.Partner:
                await clientStore!.updateClientConsumptionSettings(ownerId, {
                    restrictionType: this.restrictionType,
                    discountRate: ReactAppSettings.viewBag.baseInfo.partnerIsInternal
                        ? this.discountRate
                        : consumptionSettings.discountRate,
                });
                break;
            case AppScope.Supplier:
                await partnerStore!.updatePartnerConsumptionSettings(ownerId, {
                    restrictionType: this.restrictionType,
                    discountRate: this.discountRate,
                });
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    @action.bound
    resetConsumptionRestriction = () => {
        const { consumptionSettings } = this.props;

        this.restrictionType = consumptionSettings.restrictionType;
        this.discountRate = consumptionSettings.discountRate;
    };

    @action.bound
    onConsumptionTypeChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.restrictionType = Number((event.target as any).value);
    };

    @action.bound
    onDiscountRateChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.discountRate = Number(event.currentTarget.value);
    };

    render() {
        const { scope } = this.props;

        return (
            <AtTabCard
                cardTitle={"global.balanceRestriction".localize()}
                id={"ConsumptionRestrictionForm"}
                className={"consumption-restriction-form"}
            >
                <div className="inputs">
                    <RadioFormInput
                        fieldName={"restricted"}
                        value={ProRestrictionType.Restricted}
                        valueText={"global.restricted".localize()}
                        onChange={this.onConsumptionTypeChange}
                        checked={this.restrictionType === ProRestrictionType.Restricted}
                        formText={"global.restrictedHelpText".localize()}
                    />
                    <RadioFormInput
                        fieldName={"unrestricted"}
                        value={ProRestrictionType.Unrestricted}
                        valueText={"global.unrestricted".localize()}
                        onChange={this.onConsumptionTypeChange}
                        checked={this.restrictionType === ProRestrictionType.Unrestricted}
                        formText={"global.unrestrictedHelpText".localize()}
                    />
                </div>
                {(scope === AppScope.Supplier || ReactAppSettings.viewBag.baseInfo.partnerIsInternal) && (
                    <div className="billing-details">
                        <CustomTextInput
                            fieldName={"discountRate"}
                            label={t({ id: "global.inputs.discountRate.label", message: "Discount Rate (%)" })}
                            formText={t({
                                id: "global.inputs.discountRate.helpText",
                                message: "This value can be between 0% and 50%",
                            })}
                            value={this.discountRate}
                            type={"number"}
                            onChange={this.onDiscountRateChange}
                        />
                    </div>
                )}
                <CardSaveButtons
                    saveAction={this.saveConsumptionRestriction}
                    cancelAction={this.resetConsumptionRestriction}
                    isLoading={this.isLoading}
                />
            </AtTabCard>
        );
    }
}

const ConsumptionRestrictionForm = withAppContext(ConsumptionRestrictionFormComp);

export { ConsumptionRestrictionForm };
