import { FitCompetencyInfo, IFitCompetencyInfo } from "./FitCompetencyInfo";

export interface IFitCompetencyGroups {
    readonly analysis: IFitCompetencyInfo[];
    readonly discipline: IFitCompetencyInfo[];
    readonly energy: IFitCompetencyInfo[];
    readonly influence: IFitCompetencyInfo[];
    readonly relation: IFitCompetencyInfo[];
    readonly interests: IFitCompetencyInfo[];
}

export class FitCompetencyGroups implements IFitCompetencyGroups {
    public readonly analysis: FitCompetencyInfo[];
    public readonly discipline: FitCompetencyInfo[];
    public readonly energy: FitCompetencyInfo[];
    public readonly influence: FitCompetencyInfo[];
    public readonly interests: FitCompetencyInfo[];
    public readonly relation: FitCompetencyInfo[];

    constructor(json: IFitCompetencyGroups) {
        this.analysis = json.analysis.map((x) => new FitCompetencyInfo(x));
        this.discipline = json.discipline.map((x) => new FitCompetencyInfo(x));
        this.energy = json.energy.map((x) => new FitCompetencyInfo(x));
        this.influence = json.influence.map((x) => new FitCompetencyInfo(x));
        this.interests = json.interests.map((x) => new FitCompetencyInfo(x));
        this.relation = json.relation.map((x) => new FitCompetencyInfo(x));
    }

    get allCompetencies(): FitCompetencyInfo[] {
        return [
            ...this.analysis,
            ...this.discipline,
            ...this.energy,
            ...this.influence,
            ...this.interests,
            ...this.relation,
        ];
    }
}
