import "./ClientAccountInformationForm.less";
import { AtCheckbox, AtTabCard, IReactSelectOptionObject } from "@atman/design-system";
import { AtSimpleSelect } from "@atman/design-system/lib/components/molecules/AtSimpleSelect";
import { BaseForm } from "../../../../components/BaseForm";
import { CardSaveButtons } from "../../../../components/CardSaveButtons";
import {
    Client,
    ClientStore,
    ClientType,
    GlobalStores,
    LocalizationStore,
    PlatformUseEnum,
    getEnumValues,
} from "@atman/business";
import { Col, Label, Row } from "reactstrap";
import { CultureSelectField } from "../../../../components";
import { action, observable } from "mobx";
import { findCultureFromSet } from "@atman/business/lib/stores/LocalizationStore";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IClientAccountInformationFormProps {
    entity: Client;
    clientStore?: ClientStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.clientStore, GlobalStores.localizationStore)
@observer
export class ClientAccountInformationForm extends BaseForm<IClientAccountInformationFormProps, {}> {
    @observable public platformUse: PlatformUseEnum[] = [];
    @observable public clientType: ClientType = ClientType.Default;
    @observable public languageCode: IReactSelectOptionObject;

    componentDidMount(): void {
        this.resetAccountInformation();
    }

    @action.bound
    resetAccountInformation = () => {
        const { entity, localizationStore } = this.props;

        if (entity.generalSettings && entity.generalSettings.platformUses) {
            this.platformUse = entity.generalSettings.platformUses;
        }

        if (entity.marketingInfo && entity.marketingInfo.clientType) {
            this.clientType = entity.marketingInfo.clientType;
        }

        const culture = findCultureFromSet(localizationStore!.availableLanguages, entity.communicationLanguageCode);

        if (culture) {
            this.languageCode = {
                value: culture.cultureInfo,
                label: culture.shortDisplay,
            };
        }

        // if (entity.generalSettings && entity.generalSettings.ownerId) {
        //     const ownerId = entity.generalSettings.ownerId;
        //     const matchingUser = this.props.userStore!.getUserById(ownerId) as User | undefined;
        //
        //     if (matchingUser) {
        //         this.ownerId = {
        //             label: `${matchingUser.firstName} ${matchingUser.lastName}`,
        //             value: matchingUser.id
        //         }
        //     }
        // }
    };

    @autobind
    async _onSave() {
        const { clientStore, entity } = this.props;

        const generalSettings = {
            ownerId: entity.generalSettings ? entity.generalSettings.ownerId : "",
            platformUses: this.platformUse,
        };

        const marketingInfo = {
            ...entity.marketingInfo,
            clientType: this.clientType,
        };

        await clientStore!.updateClientAdditionalInformation(
            entity.id,
            marketingInfo,
            generalSettings,
            this.languageCode.value,
        );
    }

    @action.bound
    onLanguageCodeChange(option: IReactSelectOptionObject): void {
        this.languageCode = option;
    }

    validateForm(): boolean {
        return true;
    }

    @action.bound
    onPlatformUseClick = (event: React.FormEvent<HTMLInputElement>): void => {
        const platformUse = Number((event.target as any).value);
        const index = this.platformUse.indexOf(platformUse);

        if (index >= 0) {
            this.platformUse.splice(index, 1);
        } else {
            this.platformUse.push(platformUse);
        }
    };

    @autobind
    renderCheckboxes() {
        return Object.keys(PlatformUseEnum)
            .filter((x) => !isNaN(Number(x)))
            .map((x, i) => (
                <AtCheckbox
                    id={`${PlatformUseEnum[Number(x)]}-platform-use`}
                    fieldName={"platformUse"}
                    checked={this.platformUse.some((use) => use === Number(x))}
                    onChange={this.onCheckboxesChange}
                    value={Number(x)}
                    key={i}
                >
                    {`global.platformUses.${PlatformUseEnum[Number(x)].toCamel()}`.localize()}
                </AtCheckbox>
            ));
    }

    render() {
        const { clientStore } = this.props;

        return (
            <AtTabCard
                cardTitle={"global.accountInformation".localize()}
                id={"ClientAccountInformationForm"}
                className={"client-account-information-form"}
            >
                <Row>
                    <Col sm={6}>
                        <AtSimpleSelect
                            name={"clientType"}
                            label={"partnerApp.clientType".localize()}
                            options={getEnumValues<ClientType>(ClientType)}
                            getOptionLabel={(x) =>
                                `partnerApp.clientTypes.${ClientType[Number(x)].toCamel()}`.localize()
                            }
                            value={this.clientType}
                            onChange={(o) => this.onAutoCompleteChange(o, "clientType", true)}
                        />
                    </Col>
                    <Col sm={6}>
                        <CultureSelectField cultureValue={this.languageCode} onChange={this.onLanguageCodeChange} />
                    </Col>
                    <Col sm={6}>
                        <Label className={"platform-use-label"}>{"global.platformUse".localize()}</Label>
                        <div className="platform-use-container">{this.renderCheckboxes()}</div>
                    </Col>
                </Row>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetAccountInformation}
                    isLoading={clientStore!.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </AtTabCard>
        );
    }
}
