import { ComponentGeneralPadding, ComponentLiteralPadding, ComponentPadding } from "../../types/paddings";

export const PADDING_UNIT = "px";
export const PADDING_DEFAULT = "0";

const generateGeneralPadding = (componentPadding: ComponentGeneralPadding): string =>
    componentPadding
        .filter((x) => x !== undefined)
        .map((padding) => `${padding}${PADDING_UNIT}`)
        .join(" ");

const generateLiteralPadding = (componentLiteralPadding: ComponentLiteralPadding) =>
    componentLiteralPadding
        ? Object.keys(componentLiteralPadding)
              .map(function (key) {
                  const padding = componentLiteralPadding[key];

                  if (padding) {
                      return `${padding}${PADDING_UNIT}`;
                  }
                  return PADDING_DEFAULT;
              })
              .join(" ")
        : PADDING_DEFAULT;

export function generatePadding(padding: ComponentPadding | undefined | null): string | undefined {
    if (!padding) {
        return undefined;
    }

    // If it is an array, then it's generalPadding
    if (Array.isArray(padding)) {
        return generateGeneralPadding(padding);
        // Else it's Literal Padding
    } else {
        return generateLiteralPadding(padding);
    }
}
