import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { IOrganizationBaseCustomizations, IOrganizationCustomization, IOrganizationSsoCustomizations } from "../models";
import { ReactAppSettings } from "../libs";

export class OrganizationCustomizationApi extends BaseApi {
    public static getCustomizations(): Promise<IOrganizationCustomization> {
        return this.sendApiRequestWithResult<undefined, IOrganizationCustomization>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getOrganizationCustomizations,
        );
    }

    public static updateBaseCustomization(model: IOrganizationBaseCustomizations): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateOrganizationBaseCustomizations,
            model,
        );
    }

    public static updateSsoCustomization(model: IOrganizationSsoCustomizations): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateOrganizationSsoCustomizations,
            model,
        );
    }

    public static deleteCustomization(): Promise<void> {
        return this.sendApiRequest(HttpMethodEnum.DELETE, ReactAppSettings.appUrls.deleteOrganizationCustomizations);
    }
}
