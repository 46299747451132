import "./ClientDelegationForm.less";
import * as React from "react";
import { AtAsyncSelect, DefaultComponentProps, IReactSelectOptionObject, RadioFormInput } from "@atman/design-system";
import { ClientStore, ConfigurationProvider, GlobalStores, ReactAppSettings, UserInfoStore, stringContains } from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IClientDelegationFormProps extends Omit<DefaultComponentProps, "id"> {
    automaticClientDelegation: boolean;
    onAutomaticClientDelegationChange: (event: React.FormEvent<HTMLInputElement>) => void;
    clientsDelegationOverride: IReactSelectOptionObject[];
    onClientsDelegationOverrideChange: (option: IReactSelectOptionObject[]) => void;
    uniqueKey?: string;
    userInfoStore?: UserInfoStore;
    clientStore?: ClientStore;
}

const storesToInject: string[] = [GlobalStores.userInfoStore];

if (ConfigurationProvider.appName === "partner") {
    storesToInject.push(GlobalStores.clientStore);
}

export const ClientDelegationForm: React.FC<IClientDelegationFormProps> = inject(...storesToInject)(
    observer((props) => {
        const {
            automaticClientDelegation,
            onAutomaticClientDelegationChange,
            clientsDelegationOverride,
            onClientsDelegationOverrideChange,
            uniqueKey,
            className,
            style,
            userInfoStore,
            clientStore,
        } = props;

        const loadClientOptions = (inputValue: string) => {
            if (!clientStore) {
                return;
            }

            return new Promise<IReactSelectOptionObject[]>(async (resolve) => {
                let results = inputValue
                    ? clientStore.clients.filter((x) => stringContains(x.name, inputValue, true))
                    : clientStore.clients;

                if (!userInfoStore!.automaticClientDelegationEnabled) {
                    results = results.filter((x) =>
                        (ReactAppSettings.appModel.delegatedClientIds ?? []).includes(x.id),
                    );
                }

                const processedResults = results.map(
                    (x) => ({ value: x.id, label: x.name } as IReactSelectOptionObject),
                );

                resolve(processedResults);
            });
        };

        return (
            <div id="ClientDelegationForm" className={className} style={style}>
                <div className="inputs-container">
                    <RadioFormInput
                        value={"automatic"}
                        checked={automaticClientDelegation === true}
                        fieldName={`automaticClientDelegation${uniqueKey ? `-${uniqueKey}` : ""}`}
                        valueText={t({ id: "partnerApp.automaticClientDelegation" })}
                        formText={t({ id: "partnerApp.automaticClientDelegation.description" })}
                        disabled={!userInfoStore!.automaticClientDelegationEnabled}
                        onChange={onAutomaticClientDelegationChange}
                    />
                    <RadioFormInput
                        value={"manual"}
                        checked={automaticClientDelegation === false}
                        fieldName={`manualClientDelegation${uniqueKey ? `-${uniqueKey}` : ""}`}
                        valueText={t({ id: "partnerApp.manualClientDelegation" })}
                        formText={t({ id: "partnerApp.manualClientDelegation.description" })}
                        disabled={!userInfoStore!.automaticClientDelegationEnabled}
                        onChange={onAutomaticClientDelegationChange}
                    />
                </div>
                <AtAsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    closeMenuOnSelect={false}
                    loadOptions={loadClientOptions}
                    onChange={onClientsDelegationOverrideChange}
                    value={automaticClientDelegation ? [] : clientsDelegationOverride.slice()}
                    placeholder={t({ id: "global.itemTypes.client.placeholders.select" })}
                    isDisabled={automaticClientDelegation}
                />
            </div>
        );
    }),
);
