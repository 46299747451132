import "./EditConsumptionScheduling.less";
import * as React from "react";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IProductConsumptionModeScheduleEntryModel,
    LocalizationStore,
    ModalStore,
    Partner,
    PartnerStore,
    ProductConsumptionMode,
    ScopedOrganizationStore,
    ToastProvider,
    UnhandledScopeError,
} from "@atman/business";
import { AtDateRangePickerPopover, AtSelect, DateRange, IReactSelectOptionObject } from "@atman/design-system";
import { BaseForm } from "../../components/BaseForm";
import { BaseModal, ModalButtons } from "../../components";
import { CustomTextInput } from "../../components/CustomTextInput";
import { FormGroupSection } from "../../components/FormGroupSection";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";
import { Moment } from "moment";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import autobind from "autobind-decorator";
import moment from "moment";

export interface IEditConsumptionSchedulingProps extends IAppContext {
    modalStore?: ModalStore;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
    localizationStore?: LocalizationStore;
}

@inject(
    GlobalStores.modalStore,
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.scopedOrganizationStore,
    GlobalStores.localizationStore,
)
@observer
class EditConsumptionSchedulingComp extends BaseForm<IEditConsumptionSchedulingProps, {}> {
    @observable public consumptionMode: IReactSelectOptionObject<ProductConsumptionMode> = {
        value: ProductConsumptionMode.Balance,
        label: `global.productConsumptionModes.${ProductConsumptionMode[
            ProductConsumptionMode.Balance
        ].toCamel()}`.localize(),
    };

    @observable public expectedConsumption: string = "";
    @observable public dateRange: DateRange = {
        from: undefined,
        to: undefined,
    };

    componentDidMount() {
        this.loadEntryData();
    }

    @computed
    get entryId(): string {
        return this.props.modalStore!.childProps!.entryId;
    }

    @computed
    get consumptionScheduleEntries(): IProductConsumptionModeScheduleEntryModel[] {
        const ownerId = this.props.modalStore!.childProps!.ownerId;

        switch (this.props.scope) {
            case AppScope.Partner:
                const client = this.props.clientStore!.getClientById(ownerId);

                if (!client) {
                    return [];
                }

                return client.usageModel.productConsumptionModeSchedule;
            case AppScope.Supplier:
                const partner = this.props.partnerStore!.getPartnerById(ownerId);

                if (!partner) {
                    return [];
                }

                return partner.usageModel.productConsumptionModeSchedule;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }
    }

    @action.bound
    loadEntryData = () => {
        const entry = this.consumptionScheduleEntries.find((x) => x.entryId === this.entryId);

        if (entry) {
            this.consumptionMode = {
                value: entry.consumptionMode,
                label: `global.productConsumptionModes.${ProductConsumptionMode[
                    entry.consumptionMode
                ].toCamel()}`.localize(),
            };
            this.dateRange.from = moment.parseZone(entry.effectiveDate).toDate();
            this.dateRange.to = moment.parseZone(entry.endDate).toDate();
            this.expectedConsumption = entry.expectedConsumption.toString();
        }
    };

    @action.bound
    handleDatesChanged = (dateRange: DateRange) => {
        this.dateRange = dateRange;
    };

    @autobind
    async _onSave() {
        if (!this.validateForm()) {
            const endDateIsValid =
                !!this.dateRange?.to &&
                moment(this.dateRange?.to).isSameOrAfter(undefined, "D") &&
                moment(this.dateRange?.to).isSameOrAfter(this.dateRange.from!, "D");

            if (!endDateIsValid) {
                ToastProvider.error("global.invalidEndDate".localize());
            }

            return;
        }

        if (!this.dateRange.from || !this.dateRange.to) {
            return;
        }

        const model = {
            entryId: this.props.modalStore!.childProps!.entryId,
            consumptionMode: this.consumptionMode.value,
            effectiveDate: moment(this.dateRange?.from).local().startOf("D").toISOString(true),
            endDate: moment(this.dateRange?.to).local().startOf("D").toISOString(true),
            expectedConsumption: Number(this.expectedConsumption),
        } as IProductConsumptionModeScheduleEntryModel;

        let hasErrored: boolean;

        this.setIsLoading(true);

        switch (this.props.scope) {
            case AppScope.Partner:
                await this.props.clientStore!.updateClientConsumptionScheduleEntry(
                    this.props.modalStore!.childProps!.ownerId,
                    model,
                );
                hasErrored = this.props.clientStore!.hasErrored;
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.updatePartnerConsumptionScheduleEntry(
                    this.props.modalStore!.childProps!.ownerId,
                    model,
                );
                hasErrored = this.props.partnerStore!.hasErrored;
                break;
            default:
                this.setIsLoading(false);
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }

        this.setIsLoading(false);

        if (!hasErrored) {
            this.props.modalStore!.toggleModal();
        }
    }

    @autobind
    validateForm(): boolean {
        const endDateIsValid =
            !!this.dateRange?.to &&
            moment(this.dateRange?.to).isSameOrAfter(undefined, "D") &&
            moment(this.dateRange?.to).isSameOrAfter(this.dateRange.from!, "D");
        if (!endDateIsValid) {
            return false;
        }

        return true;
    }

    @action.bound
    isOutsideRange = (day: Moment) => {
        if (day.isBefore(undefined, "D")) {
            return true;
        }

        return this.consumptionScheduleEntries
            .filter((x) => x.entryId !== this.entryId)
            .some((x) => day.isSameOrAfter(x.effectiveDate, "D") && day.isBefore(x.endDate, "D"));
    };

    @action.bound
    onConsumptionModeChange = (option: IReactSelectOptionObject<ProductConsumptionMode>) => {
        this.consumptionMode = option;
    };

    render() {
        const { scopedOrganizationStore, localizationStore, scope } = this.props;

        const futureStartDate: boolean = moment(this.dateRange.from || undefined).isAfter();

        const availableConsumptionModes = [ProductConsumptionMode.Balance];

        switch (scope) {
            case AppScope.Partner:
                if ((scopedOrganizationStore!.scopedOrganization as Partner)!.unlimitedClientCreationEnabled) {
                    availableConsumptionModes.push(ProductConsumptionMode.Unlimited);
                }
                break;
            case AppScope.Supplier:
                availableConsumptionModes.push(ProductConsumptionMode.Unlimited);
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        availableConsumptionModes.push(ProductConsumptionMode.Blocked);

        const consumptionModeOptions = availableConsumptionModes.map((x) => ({
            value: x,
            label: `global.productConsumptionMdoes.${ProductConsumptionMode[x].toCamel()}`.localize(),
        }));

        return (
            <BaseModal modalTitle={"global.editPlanScheduling".localize()} id={"EditConsumptionScheduling"}>
                <FormGroupSection
                    sectionTitle={"global.planSettings".localize()}
                    id={"edit-consumption-settings-form"}
                    icon={["far", "cogs"] as IconProp}
                >
                    <div className="main-section">
                        <AtSelect
                            name={"consumptionMode"}
                            label={"global.selectPlan".localize()}
                            options={consumptionModeOptions}
                            value={this.consumptionMode}
                            onChange={this.onConsumptionModeChange}
                            isDisabled={!futureStartDate}
                        />
                        <div className={`date-range ${futureStartDate ? "future-start-date" : "past-start-date"}`}>
                            <Label>{"global.period".localize()}</Label>

                            <AtDateRangePickerPopover
                                range={this.dateRange}
                                setRange={this.handleDatesChanged}
                                languageCode={localizationStore!.currentShortLocale}
                            />
                        </div>
                    </div>
                    <CustomTextInput
                        fieldName={"expectedConsumption"}
                        value={this.expectedConsumption}
                        onChange={this.onTextFieldChange}
                        label={"global.expectedUsage".localize()}
                    />
                    <ModalButtons
                        saveAction={this._onSave}
                        saveLabel={"global.editEntry".localize()}
                        disabledSaveButton={!this.validateForm()}
                        isLoading={this.isLoading}
                    />
                </FormGroupSection>
            </BaseModal>
        );
    }
}

const EditConsumptionScheduling = withAppContext(EditConsumptionSchedulingComp);

export { EditConsumptionScheduling };
