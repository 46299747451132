import "./index.less";
import { AtCollapse } from "../../../AtCollapse";
import { AtLink } from "../../../../../..";
import { AtTableColumn, AtTableItemKeyTypes } from "../../../../atoms/AtTableBase";
import { AtTempModal } from "../AtTempModal";
import { AtTitle } from "../../../../atoms/AtTitle";
import { AvailableFilterTypes } from "./applyFilters";
import { FiltersRenderer } from "./FiltersRenderer";
import { IBaseEntity } from "@atman/core";
import { MatchTypeOptionsEnum } from "./AtTableFreeTextFilter";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React, { useEffect, useState } from "react";

export interface IAppliedFilter<T> {
    key: keyof T;
    value: AvailableFilterTypes;
    matchType?: MatchTypeOptionsEnum;
}

interface ITableFilteringModalProps<T extends IBaseEntity> {
    isModalHidden: boolean;
    itemKeyTypes: AtTableItemKeyTypes;
    columns: AtTableColumn<T>[];
    appliedFilters: IAppliedFilter<T>[];
    clearFilters: () => void;
    setIsModalHidden: (visibility: boolean) => void;
    setAppliedFilters: React.Dispatch<
        React.SetStateAction<
            {
                key: keyof T;
                value: AvailableFilterTypes;
                matchType?: MatchTypeOptionsEnum;
            }[]
        >
    >;
}

function TableFilteringBase<T extends IBaseEntity>(props: ITableFilteringModalProps<T>) {
    const {
        isModalHidden,
        setIsModalHidden,
        itemKeyTypes,
        columns,
        appliedFilters,
        setAppliedFilters,
        clearFilters,
        ...otherProps
    } = props;

    useEffect(() => {
        setWorkInProgressFilters(appliedFilters);
    }, [appliedFilters]);

    const [workInProgressFilters, setWorkInProgressFilters] = useState<IAppliedFilter<T>[]>([]);

    const cancelFiltersEdition = () => {
        setWorkInProgressFilters(appliedFilters);
        setIsModalHidden(true);
    };

    const clearFiltersLocal = () => {
        clearFilters();
        setWorkInProgressFilters([]);
    };

    const applyFilters = () => {
        setAppliedFilters(workInProgressFilters);
        setIsModalHidden(true);
    };

    const setFilterValue = (columnKey: string, value: boolean, matchType?: MatchTypeOptionsEnum) => {
        const otherFiltersWithoutCurrent = workInProgressFilters.filter((applied) => applied.key !== columnKey);
        const newWorkInProgressFilters = [...otherFiltersWithoutCurrent, { key: columnKey, value, matchType }];

        setWorkInProgressFilters(newWorkInProgressFilters);
    };

    const clearOneFilter = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, columnKey: string) => {
        e.stopPropagation();

        const indexWorkInProgressFilter = workInProgressFilters.findIndex((el) => el.key === columnKey);

        if (indexWorkInProgressFilter > -1) {
            const workInProgressCopy = [...workInProgressFilters];
            workInProgressCopy.splice(indexWorkInProgressFilter, 1);

            setWorkInProgressFilters(workInProgressCopy);
            setAppliedFilters(workInProgressCopy);
        }
    };

    return (
        <AtTempModal
            className="TableFilteringBase"
            title={t({ id: "global.filterSomething" })}
            isModalHidden={isModalHidden}
            setIsModalHidden={setIsModalHidden}
            mainActionLabel={t({ id: "global.apply" })}
            mainAction={applyFilters}
            secondaryActionLabel={t({ id: "global.cancel" })}
            secondaryAction={cancelFiltersEdition}
            tertiaryActionLabel={t({ id: "global.clearAllFilters" })}
            tertiaryAction={clearFiltersLocal}
            {...otherProps}
        >
            {columns.map((column) => {
                const { key } = column;
                const appliedFilter = workInProgressFilters.find((applied) => applied.key === key) ?? undefined;
                const type = itemKeyTypes[key];

                if (type !== undefined && column.filter) {
                    return (
                        <AtCollapse
                            key={key}
                            toggleRowContent={
                                <div className="titleLink">
                                    <AtTitle title={column.filter.customName ?? column.label ?? ""} />
                                    {appliedFilter?.value !== undefined && (
                                        <AtLink
                                            color="secondary"
                                            target="_blank"
                                            onClick={(e) => clearOneFilter(e, key)}
                                            title={"clearFilter"}
                                        >
                                            {t({ id: "global.clearThisFilters" })}
                                        </AtLink>
                                    )}
                                </div>
                            }
                            toggleRowSizeHorizontal="md"
                            defaultOpen={appliedFilter?.value !== undefined}
                        >
                            <FiltersRenderer
                                column={column}
                                appliedFilter={appliedFilter}
                                setFilterValue={setFilterValue}
                            />
                        </AtCollapse>
                    );
                }

                return null;
            })}
        </AtTempModal>
    );
}

export const TableFilteringModal = observer(TableFilteringBase);
