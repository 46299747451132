import "./index.less";
import * as React from "react";
import { CardNumberElement } from "react-stripe-elements";
import { Label } from "reactstrap";
import { stripeStyles } from "../StripeJsStyles";

const cardNumberStyles = {
    ...stripeStyles,
};

export interface IStripeCardNumberProps {}

export const StripeCardNumber: React.FC<IStripeCardNumberProps> = (props: IStripeCardNumberProps) => {
    const {} = props;

    return (
        <div className="StripeCardNumber StripeCardElement">
            <Label>{"global.cardNumber".localize()}</Label>
            <CardNumberElement style={cardNumberStyles} />
        </div>
    );
};
