import "./index.less";
import * as React from "react";
import { AtBadge, AtTitle } from "@atman/design-system";
import { BaseResponsiveComponent } from "../../../BaseResponsiveComponent";
import { Col, Collapse, Row } from "reactstrap";
import { ScaleType } from "@atman/business";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

export interface IScaleRowProps {
    scaleType: ScaleType;

    leftLabel?: string;
    rightLabel?: string;
    valueLabel?: string;
    valueSubLabel?: string; // Ex.: the denominator
    weightLabel?: string;
    showLabels?: boolean;
    className?: string;
    displayCompact?: boolean;
    isInverted?: boolean;
    showDetailed?: boolean;

    indicator?: React.ReactNode; // Ex.: The small FitScoreIndicator circle
    additionnalInfoPanel?: React.ReactNode;

    expanded?: boolean; // If you want to control the expansion from the outside
    onRowClick?: () => void; // If you want to control the expansion from the outside

    // children: The scale control
}

@inject()
@observer
export class ScaleRow extends BaseResponsiveComponent<IScaleRowProps> {
    public static defaultProps = {
        showLabels: true,
        isInverted: false,
        showDetailed: false,
    };

    @observable public isPanelExpanded: boolean = false;

    @computed public get isPanelOpen(): boolean {
        const { expanded } = this.props;

        if (expanded != undefined) {
            return expanded;
        }

        return this.isPanelExpanded;
    }

    @action togglePanelExpansion = () => {
        const { onRowClick } = this.props;

        if (onRowClick) {
            onRowClick();
            return;
        }

        this.isPanelExpanded = !this.isPanelExpanded;
    };

    render() {
        const {
            children,
            className,
            additionnalInfoPanel,
            leftLabel,
            rightLabel,
            showLabels,
            displayCompact,
            scaleType,
            showDetailed,
            indicator,
            valueLabel,
            valueSubLabel,
            weightLabel,
        } = this.props;
        const isUnipolar = scaleType === ScaleType.Unipolar;
        let additionalClassNames = isUnipolar ? "AtUnipolarScale" : "AtBipolarScale";

        if (showDetailed && !isUnipolar) additionalClassNames += " detailed";

        // const rangePositionInfo = undefined; // = this.resultsCalculationService.rangePositionInfo;
        const hasRightLabel = !isUnipolar && rightLabel != undefined && rightLabel.length > 0;

        return (
            <div className={`ScaleRow ${className ?? ""} ${additionalClassNames}`}>
                {showLabels && (
                    <Row className="above-labels d-md-none">
                        <Col className={"left-align-label dimension-label-col"}>
                            <AtTitle headingType={4} title={leftLabel} />
                        </Col>
                        {hasRightLabel && (
                            <Col className={"right-align-label dimension-label-col"}>
                                <AtTitle headingType={4} title={rightLabel} />
                            </Col>
                        )}
                    </Row>
                )}
                <Col sm={12} className={`results-col`} onClick={this.togglePanelExpansion}>
                    <Row className={`scale-container ${displayCompact ? "compact" : ""}`}>
                        {indicator}
                        {showLabels && (
                            <div
                                className={`d-none d-md-flex dimension-label-col ${
                                    hasRightLabel
                                        ? "bipolar-label-col right-align-label"
                                        : "unipolar-label-col left-align-label"
                                }`}
                            >
                                <AtTitle headingType={4} title={leftLabel} />

                                {weightLabel && (
                                    <AtBadge size={"xs"}>{`${"important".localize()} ${weightLabel}`}</AtBadge>
                                )}
                            </div>
                        )}
                        <Col>
                            <Row className={"sub-scale-container"}>
                                {!hasRightLabel && (
                                    <Col className={"col-2 inline-score"}>
                                        {valueLabel && (
                                            <>
                                                {valueLabel}
                                                {valueSubLabel && <span className="denominator">{valueSubLabel}</span>}
                                            </>
                                        )}
                                    </Col>
                                )}
                                <Col className={`line-container`}>
                                    {/*{*/}
                                    {/*    rangePositionInfo && (*/}
                                    {/*        <div*/}
                                    {/*            className={`range-container ${isInverted ? 'is-inverted' : ''}`}*/}
                                    {/*            style={{*/}
                                    {/*                left: `${rangePositionInfo.min}%`,*/}
                                    {/*                right: `${rangePositionInfo.max}%`,*/}
                                    {/*                width: `${rangePositionInfo.width}%`*/}
                                    {/*            }}/>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                    {children}
                                </Col>
                            </Row>
                        </Col>
                        {showLabels && hasRightLabel && (
                            <div className={"bipolar-label-col d-none d-md-flex dimension-label-col left-align-label"}>
                                <AtTitle headingType={4} title={rightLabel} />
                            </div>
                        )}
                    </Row>
                </Col>
                {additionnalInfoPanel && (
                    <>
                        {showDetailed ? (
                            additionnalInfoPanel
                        ) : (
                            <Col sm={12} className="expanded-additional-info-col">
                                <Collapse isOpen={this.isPanelOpen} className={"collapsed-additional-info-panel"}>
                                    <div className={"collapsed-additional-info-panel-content"}>
                                        {additionnalInfoPanel}
                                    </div>
                                </Collapse>
                            </Col>
                        )}
                    </>
                )}
            </div>
        );
    }
}
