import "./AddCard.less";
import * as React from "react";
import { Amex, Mastercard, Visa } from "../../static";
import { BaseForm } from "../../components/BaseForm";
import { BaseModal } from "../../components/BaseModal";
import { CustomTextInput } from "../../components/CustomTextInput";
import { FormGroupSection } from "../../components/FormGroupSection";
import { GlobalStores, IAppContext, ModalStore, ProductBalanceStore, ToastProvider } from "@atman/business";
import { ModalButtons } from "../../components/ModalButtons";
import { ReactStripeElements, injectStripe } from "react-stripe-elements";
import { StripeCreditCardForm } from "../../components/StripeElements/StripeCreditCardForm";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { withAppContext } from "../../contexts";
import autobind from "autobind-decorator";

export interface IAddCardProps extends IAppContext, ReactStripeElements.InjectedStripeProps {
    modalStore?: ModalStore;
    productBalanceStore?: ProductBalanceStore;
}

@inject(GlobalStores.modalStore, GlobalStores.productBalanceStore)
@observer
class AddCardComp extends BaseForm<IAddCardProps, {}> {
    @observable public label: string = "";
    @observable public zipCode: string = "";

    @autobind
    async _onSave() {
        const { token } = await this.props.stripe!.createToken({ address_zip: this.zipCode });

        if (!token) {
            ToastProvider.error("stripeTokenCreationErrorMessage".localize());
            return;
        }

        await this.props.productBalanceStore!.addCustomerCard(token.id, this.label);

        if (!this.props.productBalanceStore!.hasErrored) {
            this.props.modalStore!.toggleModal();
        }
    }

    validateForm(): boolean {
        return !!this.label && !!this.zipCode;
    }

    render() {
        const { productBalanceStore } = this.props;

        return (
            <BaseModal modalTitle={"global.addNewCard".localize()} id={"AddCard"}>
                <FormGroupSection
                    sectionTitle={"global.cardInformation".localize()}
                    id={"card-information-section"}
                    rightHeaderContent={
                        <span className="card-containers">
                            <img src={Visa} height={32} alt="visa" className={"card-logo"} />
                            <img src={Mastercard} height={32} alt="mastercard" className={"card-logo"} />
                            <img src={Amex} height={32} alt="amex" className={"card-logo"} />
                        </span>
                    }
                >
                    <div className="label-field-container">
                        <CustomTextInput
                            fieldName={"label"}
                            value={this.label}
                            onChange={this.onTextFieldChange}
                            placeholder={"global.business".localize()}
                            label={"global.cardLabel".localize()}
                        />
                    </div>
                    <StripeCreditCardForm zipCodeValue={this.zipCode} onZipCodeChange={this.onTextFieldChange} />
                    <ModalButtons
                        saveAction={this._onSave}
                        saveLabel={"global.addCard".localize()}
                        isLoading={productBalanceStore!.isLoading}
                        disabledSaveButton={!this.validateForm()}
                    />
                </FormGroupSection>
            </BaseModal>
        );
    }
}

const AddCard = withAppContext(injectStripe(AddCardComp));

export { AddCard };
