import "./index.less";
import * as React from "react";
import { DefaultComponentProps } from "@atman/design-system";
import { observer } from "mobx-react";

export interface IDocumentSectionProps extends DefaultComponentProps {}

export const DocumentSection: React.FC<IDocumentSectionProps> = observer((props) => {
    const { className, style, id, children } = props;

    return (
        <div className={`DocumentSection ${className ?? ""}`} id={id} style={style}>
            {children}
        </div>
    );
});
