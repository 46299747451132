import * as React from "react";
import { CompDevEvaluationContextConsumer, ICompDevEvaluationContext } from "./context";

export function withCompDevEvaluationContext<
    P extends ICompDevEvaluationContext,
    R = Omit<P, keyof ICompDevEvaluationContext>,
>(Component: React.ComponentClass<P> | React.FC<P>): React.FC<R> {
    return function BoundComponent(props: R) {
        return (
            <CompDevEvaluationContextConsumer>
                {(value) => {
                    // @ts-ignore TODO @cvincent 2021-04-22: Fix typing
                    return <Component {...props} {...value} />;
                }}
            </CompDevEvaluationContextConsumer>
        );
    };
}
