import "./index.less";
import { AtButton } from "@atman/design-system";
import { CandidateIdentityView } from "../../../../../CandidateIdentityView";
import { IShortCandidate, ToastProvider } from "@atman/business";
import { Trans } from "@lingui/macro";
import { useCompDevEvaluationContext } from "../../../../../../contexts";
import React, { useState } from "react";

export interface ICompetencyDevelopmentAssessmentWelcomeScreenProps {}

const CompetencyDevelopmentAssessmentWelcomeScreen: React.FC<ICompetencyDevelopmentAssessmentWelcomeScreenProps> = (
    props,
) => {
    const {} = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { startAssessment, employeeDetails, assessmentStructure } = useCompDevEvaluationContext()!;

    const onStart = async (): Promise<void> => {
        setIsLoading(true);

        try {
            await startAssessment();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error(e);

            const errorMessage = e.errors?.firstOrDefault()?.message ?? e.message;

            if (errorMessage) {
                ToastProvider.error(errorMessage);
            }
        }
    };

    // @ts-ignore
    const shortCandidate: IShortCandidate = {
        firstName: employeeDetails.firstName,
        lastName: employeeDetails.lastName,
        email: employeeDetails.email,
        jobName: employeeDetails.jobName,
    };

    return (
        <div id="CompetencyDevelopmentAssessmentWelcomeScreen">
            <h1 className={"assessment-title"}>{assessmentStructure?.title}</h1>
            <div className="assessment-description">{assessmentStructure?.description}</div>
            <CandidateIdentityView size={"md"} candidate={shortCandidate} />
            <div className="button-container">
                <AtButton color={"primary"} onClick={onStart} isLoading={isLoading}>
                    <Trans id={"competencyDevelopment.assessments.welcomeScreen.buttons.start"}>Start</Trans>
                </AtButton>
            </div>
        </div>
    );
};

export default CompetencyDevelopmentAssessmentWelcomeScreen;
