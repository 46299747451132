import { AtTableBooleanFilter } from "./AtTableBooleanFilter";
import { AtTableColumn } from "../../../../atoms/AtTableBase";
import { AtTableDateRangeFilter } from "./AtTableDateRangeFilter";
import { AtTableDropdownFilter } from "./AtTableDropdownFilter";
import { AtTableFreeTextFilter, MatchType } from "./AtTableFreeTextFilter";
import { AtTableMultipleSelectFilter } from "./AtTableMultipleSelectFilter";
import { AtTableNumberFilter } from "./AtTableNumberRangeFilter/AtTableNumberFilter";
import { AtTableNumberRangeFilter } from "./AtTableNumberRangeFilter/AtTableNumberRangeFilter";
import { AvailableFilterTypes } from "./applyFilters";
import { DateRange } from "react-day-picker";
import { IAppliedFilter } from ".";
import { IBaseEntity } from "@atman/core";
import React, { ReactElement } from "react";

interface IFilterContentProps<T extends IBaseEntity> {
    column: AtTableColumn<T>;
    appliedFilter: IAppliedFilter<T> | undefined;
    setFilterValue: (columnKey: string, value: AvailableFilterTypes, matchType?: MatchType) => void;
}

export function FiltersRenderer<T extends IBaseEntity>(props: IFilterContentProps<T>): ReactElement | null {
    const { column, appliedFilter, setFilterValue } = props;
    const { filter } = column;

    switch (filter?.type) {
        case "multipleSelect":
            return filter.values ? (
                <AtTableMultipleSelectFilter<T>
                    values={filter.values}
                    column={column}
                    appliedFilterValue={(appliedFilter?.value as string[]) ?? []}
                    setFilterValue={setFilterValue}
                />
            ) : null;

        case "dropdown":
            return filter.options ? (
                <AtTableDropdownFilter<T>
                    options={filter.options}
                    column={column}
                    appliedFilterValue={(appliedFilter?.value as number) ?? null}
                    setFilterValue={setFilterValue}
                    selectPlaceholder={filter.selectPlaceholder}
                />
            ) : null;
        case "date":
            return filter.dateOptions ? (
                <AtTableDateRangeFilter
                    column={column}
                    appliedFilterValue={(appliedFilter?.value as DateRange) ?? { from: null, to: null }}
                    setFilterValue={setFilterValue}
                    filterOptions={filter.dateOptions}
                    hideDateTo={column.hideDateTo}
                />
            ) : null;
        case "boolean":
            return (
                <AtTableBooleanFilter<T>
                    column={column}
                    appliedFilterValue={appliedFilter?.value as boolean | null}
                    setFilterValue={setFilterValue}
                />
            );

        case "freeText":
            return (
                <AtTableFreeTextFilter<T>
                    column={column}
                    appliedFilterValue={appliedFilter ?? null}
                    setFilterValue={setFilterValue}
                />
            );

        case "number":
            return (
                <AtTableNumberFilter
                    column={column}
                    appliedFilterValue={(appliedFilter?.value as number) ?? null}
                    setFilterValue={setFilterValue}
                />
            );

        case "numberRange":
            return (
                <AtTableNumberRangeFilter
                    column={column}
                    appliedFilterValue={(appliedFilter?.value as number[]) ?? undefined}
                    setFilterValue={setFilterValue}
                />
            );

        default:
            return null;
    }
}
