import "./index.less";
import { DefaultComponentProps, ExtraAndDefaultSizes, FontWeight, Typography } from "../../../internal";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAtParagraphProps extends DefaultComponentProps {
    lineHeight?: ExtraAndDefaultSizes;
    size?: ExtraAndDefaultSizes;
    weight?: FontWeight;
}

export const AtParagraph: React.FC<IAtParagraphProps> = observer((props) => {
    const { lineHeight = "md", size = "md", weight = "medium", className, id, children, style, ...otherProps } = props;

    const getStyles: () => React.CSSProperties = () => {
        return {
            fontWeight: Typography.getFontWeightPropValue(weight),
            ...style,
        };
    };

    return (
        <p
            className={cn("AtParagraph", className, { [`size-${size}`]: size })}
            id={id}
            style={getStyles()}
            {...otherProps}
        >
            {children}
        </p>
    );
});
