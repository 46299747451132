import * as React from "react";
import { AtValueColor, ValueColorRenderMode } from "@atman/design-system";
import { Colors } from "../../../styles";
import { LIKERT_AVG_INTERPRETATION_RANGES, RangeHelper, getRoundedScore } from "@atman/business";
import { observer } from "mobx-react";
import { useMemo } from "react";

export interface ILikertScoreDisplayProps {
    score: number | undefined;
    scoreLabel?: string;
}

export const LikertScoreDisplay: React.FC<ILikertScoreDisplayProps> = observer((props) => {
    const { score, scoreLabel } = props;

    const backgroundColor = useMemo(() => {
        const likertValue = RangeHelper.getRangeMapValue(LIKERT_AVG_INTERPRETATION_RANGES, score);

        return Colors.getLikertScoreColorGroup(likertValue)?.backgroundColor;
    }, [score]);

    return (
        <AtValueColor
            value={scoreLabel ?? getRoundedScore(score)}
            bgColor={backgroundColor}
            borderColor={backgroundColor}
            renderMode={ValueColorRenderMode.LeftSquare}
        />
    );
});
