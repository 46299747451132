import "./index.less";
import { AtTitle, ComponentPadding, HeadingType, Sizing, generatePadding } from "@atman/design-system";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export interface IAtCollapsibleCardProps {
    title?: string;
    titleSize?: "sm" | "md";
    customLeftHeaderContent?: React.ReactNode;
    customRightHeaderContent?: React.ReactNode;
    defaultCollapseOpenState?: boolean;
    className?: string;
    id?: string;
    disabled?: boolean;
    isCollapseOpen?: boolean;
    hideChevron?: boolean;
    toggleCollapse?: () => void;
    headerPadding?: ComponentPadding;
    bodyPadding?: ComponentPadding;
    headingSize?: HeadingType;
    isInCollapsibleInputTextArea?: boolean;
}

export const AtCollapsibleCard: React.FC<IAtCollapsibleCardProps> = observer((props) => {
    const {
        title = "",
        titleSize,
        children,
        hideChevron,
        customLeftHeaderContent,
        customRightHeaderContent,
        defaultCollapseOpenState,
        className,
        id,
        headerPadding = Sizing.paddings.collapsibleHeader,
        bodyPadding,
        isCollapseOpen,
        toggleCollapse,
        disabled,
        isInCollapsibleInputTextArea,
        headingSize: _headingSize,
        ...otherProps
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(isCollapseOpen ?? defaultCollapseOpenState ?? false);

    useEffect(() => {
        if (defaultCollapseOpenState !== undefined) {
            setIsOpen(defaultCollapseOpenState ?? false);
        }
    }, [defaultCollapseOpenState]);

    useEffect(() => {
        if (isCollapseOpen !== undefined) {
            setIsOpen(isCollapseOpen ?? false);
        }
    }, [isCollapseOpen]);

    const applyToggleCollapse = () => {
        if (disabled) {
            return;
        }

        if (toggleCollapse) {
            toggleCollapse();
        } else {
            setIsOpen(!isOpen);
        }
    };

    const headingSize = useMemo(() => {
        if (_headingSize) {
            return _headingSize;
        }

        if (titleSize === "sm") {
            return 4;
        }

        return 3;
    }, [_headingSize, titleSize]);

    return (
        <div
            className={cn("AtCollapsibleCard", className, {
                "is-open": isOpen,
            })}
            id={id}
            {...otherProps}
        >
            <div
                className={cn("header", { disabled: disabled })}
                onClick={applyToggleCollapse}
                style={headerPadding && { padding: generatePadding(headerPadding) }}
            >
                <div className="left-section">
                    {!hideChevron && <FontAwesomeIcon icon={["far", isOpen ? "chevron-up" : "chevron-down"]} />}

                    {(customLeftHeaderContent && customLeftHeaderContent) || (
                        <AtTitle headingType={headingSize} title={title} />
                    )}
                </div>
                {customRightHeaderContent && (
                    <div
                        className="right-section"
                        onClick={(e) => {
                            if (isInCollapsibleInputTextArea) {
                                applyToggleCollapse();
                            }
                            e.stopPropagation();
                        }}
                    >
                        {customRightHeaderContent}
                    </div>
                )}
            </div>

            <Collapse isOpen={isOpen}>
                <div className="content" style={bodyPadding && { padding: generatePadding(bodyPadding) }}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
});
