import { AddCreditsCart, AttributeCreditsCart, BaseCart, Client, Partner, UsageModel } from "../models";
import {
    AppScope,
    AssessmentType,
    CheckoutProcessEnum,
    CurrencyEnum,
    IProductBalanceCreditCardRequestModel,
    IProductBalanceRequestModel,
    ITransactionProductInfo,
    ProductCodeProEnum,
    TransactionType,
} from "../types";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { ClientsApi, PartnersApi } from "../apis";
import { IProductSkuDetails } from "../appLogic/ProductDefinition";
import { ToastProvider } from "../libs";
import { UnhandledScopeError } from "../base";
import { action, autorun, computed, observable } from "mobx";

export type AppCartKey = "attributeCredits" | "addCredits";

export class CartStore extends BaseStore {
    protected readonly scope: AppScope;
    @observable public appCarts: Dictionary<AppCartKey, BaseCart> = {
        attributeCredits: {
            type: "sale",
            balance: {
                [ProductCodeProEnum.Integral]: 0,
                [ProductCodeProEnum.CognitiveTest]: 0,
                [ProductCodeProEnum.PersonalityTest]: 0,
                [ProductCodeProEnum.PreferencesTest]: 0,
                [ProductCodeProEnum.TripleBottomLineTest]: 0,
            },
        },
        addCredits: {
            type: "purchase",
            balance: {
                [ProductCodeProEnum.Integral]: 0,
                [ProductCodeProEnum.CognitiveTest]: 0,
                [ProductCodeProEnum.PersonalityTest]: 0,
                [ProductCodeProEnum.PreferencesTest]: 0,
                [ProductCodeProEnum.TripleBottomLineTest]: 0,
            },
        },
    };

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
        this.scope = rootStore.scope;
    }

    @action
    clearCart = (cartId: AppCartKey) => {
        if (this.appCarts.hasOwnProperty(cartId)) {
            this.appCarts[cartId].selectedPackageSku = undefined;
            this.appCarts[cartId].balance = {
                [ProductCodeProEnum.Integral]: 0,
                [ProductCodeProEnum.CognitiveTest]: 0,
                [ProductCodeProEnum.PersonalityTest]: 0,
                [ProductCodeProEnum.PreferencesTest]: 0,
                [ProductCodeProEnum.TripleBottomLineTest]: 0,
            };
            this.appCarts[cartId].checkoutProcess = CheckoutProcessEnum.PayLater;
            this.appCarts[cartId].ownerId = "";
        }
    };

    @action
    setCart = (cartId: AppCartKey, cart: BaseCart) => {
        if (this.appCarts.hasOwnProperty(cartId)) {
            this.appCarts[cartId] = cart;
        }
    };

    @action
    getSkuForProductCode = (productCode: ProductCodeProEnum, quantity: number) => {
        const productsForProductCode =
            this.rootStore.productBalanceStore.availableProductDefinitions.find((x) => x.productCode === productCode)
                ?.skus ?? [];
        const bundleSizes = [1, 5, 10, 25, 50, 100];

        let skuDetails: IProductSkuDetails | undefined;

        if (bundleSizes.some((x) => x === quantity)) {
            skuDetails = productsForProductCode.find(
                (x) => x.sku.endsWith(`-${quantity}`) && !x.sku.includes("-CUSTOM-"),
            );
        }

        if (!skuDetails) {
            skuDetails = this.rootStore.productBalanceStore.getProductForCustomPackage(productCode, quantity);
        }

        if (!skuDetails) {
            throw new Error("Couldn't find a matching product");
        }

        return {
            productSku: skuDetails.sku,
            nbUnits: skuDetails.sku.includes("-CUSTOM-") ? quantity : 1,
        } as ITransactionProductInfo;
    };

    @action
    processAttributeProductsTransaction = async (
        operationType: "add" | "remove" = "add",
        transactionType: TransactionType,
        comments?: string,
    ) => {
        const cart = this.appCarts["attributeCredits"] as AttributeCreditsCart;

        const balanceElements = Object.keys(cart.balance).filter((x) => cart.balance[x] !== 0);

        const model = {
            transactionProductInfos: balanceElements.map((x) => this.getSkuForProductCode(Number(x), cart.balance[x])),
            transactionType,
            comments,
        } as IProductBalanceRequestModel;

        return await this.tryCatch(
            async () => {
                switch (this.scope) {
                    case AppScope.Partner:
                        switch (operationType) {
                            case "add":
                                await ClientsApi.creditProductBalance(cart.ownerId, model);
                                break;
                            case "remove":
                                await ClientsApi.debitProductBalance(cart.ownerId, model);
                                break;
                        }
                        break;
                    case AppScope.Supplier:
                        switch (operationType) {
                            case "add":
                                await PartnersApi.creditProductBalance(cart.ownerId, model);
                                break;
                            case "remove":
                                await PartnersApi.debitProductBalance(cart.ownerId, model);
                                break;
                        }
                        break;
                }

                ToastProvider.success("partnerApp.productsAttributedSuccessfully".localize());

                let entity: Partner | Client | undefined;
                let balance: Dictionary<ProductCodeProEnum, number> = {};

                switch (operationType) {
                    case "add":
                        balance = cart.balance;
                        break;
                    case "remove":
                        for (const key of Object.keys(cart.balance)) {
                            balance[key] = cart.balance[key] * -1;
                        }
                        break;
                }

                switch (this.scope) {
                    case AppScope.Partner:
                        entity = this.rootStore.clientStore.getClientById(cart.ownerId);
                        this.rootStore.clientStore.updateClientBalance(cart.ownerId, balance);
                        break;
                    case AppScope.Supplier:
                        entity = this.rootStore.partnerStore.getPartnerById(cart.ownerId);
                        this.rootStore.partnerStore.updatePartnerBalance(cart.ownerId, balance);
                        break;
                    default:
                        throw new UnhandledScopeError(this.scope);
                }

                if (entity) {
                    const usageModel = new UsageModel(entity.usageModel);

                    if (!usageModel.isUnlimited && this.scope !== AppScope.Supplier) {
                        balanceElements.forEach((x) => {
                            switch (operationType) {
                                case "add":
                                    this.rootStore.productBalanceStore.debitProducts(Number(x), cart.balance[x]);
                                    break;
                                case "remove":
                                    this.rootStore.productBalanceStore.creditProducts(Number(x), cart.balance[x]);
                            }
                        });
                    }
                }

                this.clearCart("attributeCredits");
            },
            (error) => {
                if (Array.isArray(error)) {
                    if (error.any()) {
                        return error.firstOrDefault()!.code;
                    }

                    return;
                } else {
                    return error.message;
                }

                // if (Array.isArray(error)) {
                //     for (const errorEntry of error) {
                //         ToastProvider.error(errorEntry.message);
                //     }
                // }
                //
                // if (error instanceof ApiError) {
                //     ToastProvider.error(error.message);
                // }
            },
            true,
        );
    };

    @action
    processAddCreditsByInvoicingTransaction = async (totalAmount: number) => {
        const cart = this.appCarts["addCredits"] as AddCreditsCart;

        const balanceElements = Object.keys(cart.balance).filter((x) => cart.balance[x] !== 0);

        const model = {
            transactionProductInfos: balanceElements.map((x) => this.getSkuForProductCode(Number(x), cart.balance[x])),
            totalAmount,
        } as IProductBalanceRequestModel;

        return await this.tryCatch(
            async () => {
                switch (this.scope) {
                    case AppScope.Client:
                        await ClientsApi.purchaseProductsByInvoicing(this.rootStore.userInfoStore.clientId, model);
                        break;
                    case AppScope.Partner:
                        await PartnersApi.purchaseProductsByInvoicing(this.rootStore.userInfoStore.partnerId, model);
                        break;
                }

                ToastProvider.success("clientApp.productsPurchasedSuccessfully".localize());

                balanceElements.forEach((x) => {
                    this.rootStore.productBalanceStore.creditProducts(Number(x), cart.balance[x]);
                });

                this.clearCart("addCredits");
            },
            (error) => {
                // const limitErrorCodes = ['InvoiceAmountExceedsLimit', 'OutstandingInvoicesExceedLimit'];

                if (Array.isArray(error)) {
                    if (error.any()) {
                        return error.firstOrDefault()!.code;
                    }

                    return;

                    // if (error.some(x => limitErrorCodes.indexOf(x.code) > -1)) {
                    //     return error.firstOrDefault()!.code;
                    // } else {
                    //     for (const errorEntry of error) {
                    //         ToastProvider.error(errorEntry.message);
                    //     }
                    // }
                } else {
                    return error.message;

                    // if (limitErrorCodes.some(x => x === error.code)) {
                    //     return error.message;
                    // } else {
                    //     ToastProvider.error(error.message);
                }
            },
            true,
        );
    };

    @action
    processAddCreditsByCreditCardTransaction = async (cardToken: string, totalAmount: number, cardLabel?: string) => {
        const cart = this.appCarts["addCredits"] as AddCreditsCart;

        const balanceElements = Object.keys(cart.balance).filter((x) => cart.balance[x] !== 0);

        const model = {
            transactionProductInfos: balanceElements.map((x) => this.getSkuForProductCode(Number(x), cart.balance[x])),
            cardIdOrToken: cardToken,
            cardLabel,
            totalAmount,
        } as IProductBalanceCreditCardRequestModel;

        console.log(this.scope);

        await this.tryCatch(
            async () => {
                switch (this.scope) {
                    case AppScope.Client:
                        await ClientsApi.purchaseProductsByCreditCard(this.rootStore.userInfoStore.clientId, model);
                        break;
                    case AppScope.Partner:
                        await PartnersApi.purchaseProductsByCreditCard(this.rootStore.userInfoStore.partnerId, model);
                        break;
                }

                ToastProvider.success("clientApp.productsPurchasedSuccessfully".localize());

                balanceElements.forEach((x) => {
                    this.rootStore.productBalanceStore.creditProducts(Number(x), cart.balance[x]);
                });

                this.clearCart("addCredits");
            },
            (error) => {
                if (Array.isArray(error)) {
                    if (error.any()) {
                        return error.firstOrDefault()!.code;
                    }

                    return;
                } else {
                    return error.message;
                }

                // if (Array.isArray(error)) {
                //     for (const errorEntry of error) {
                //         ToastProvider.error(errorEntry.message);
                //     }
                // }
                //
                // if (error instanceof ApiError) {
                //     ToastProvider.error(error.message);
                // }
            },
            true,
        );
    };

    @computed
    get billingCurrency(): CurrencyEnum {
        const billingInfo = this.rootStore.scopedOrganizationStore.scopedOrganization?.billingInformation ?? null;

        if (!billingInfo) {
            return CurrencyEnum.USD;
        }

        if (billingInfo.address.country === "CA") {
            return CurrencyEnum.CAD;
        } else {
            return CurrencyEnum.USD;
        }
    }

    autorunner = autorun(() => {});

    public readonly productCodesMapping: Dictionary<ProductCodeProEnum, AssessmentType> = {
        [ProductCodeProEnum.CognitiveTest]: AssessmentType.ProCognitiveTest,
        [ProductCodeProEnum.PersonalityTest]: AssessmentType.ProPersonalityTest,
        [ProductCodeProEnum.PreferencesTest]: AssessmentType.ProPreferencesTest,
        [ProductCodeProEnum.TripleBottomLineTest]: AssessmentType.ProTripleBottomLineTest,
        [ProductCodeProEnum.LearningModeTest]: AssessmentType.ProLearningTest,
    };

    public readonly assessmentTypesMapping: Dictionary<AssessmentType, ProductCodeProEnum> = {
        [AssessmentType.ProCognitiveTest]: ProductCodeProEnum.CognitiveTest,
        [AssessmentType.ProPersonalityTest]: ProductCodeProEnum.PersonalityTest,
        [AssessmentType.ProPreferencesTest]: ProductCodeProEnum.PreferencesTest,
        [AssessmentType.ProTripleBottomLineTest]: ProductCodeProEnum.TripleBottomLineTest,
        [AssessmentType.ProLearningTest]: ProductCodeProEnum.LearningModeTest,
    };

    public readonly integralAssessmentTypes: AssessmentType[] = [
        AssessmentType.ProCognitiveTest,
        AssessmentType.ProPersonalityTest,
        AssessmentType.ProPreferencesTest,
        AssessmentType.ProLearningTest,
        AssessmentType.ProTripleBottomLineTest,
    ];
}

export const BUNDLE_PRODUCT_CODES: ProductCodeProEnum[] = [
    ProductCodeProEnum.Integral,
    ProductCodeProEnum.PersonalityStyles,
];
