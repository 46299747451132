import { HandlePanelChangeWithAnimation } from "../..";
import { SidePanelItemDefinition } from "../../../../../SidePanelElement/SidePanelStructure";
import { SidePanelSkeleton } from "./SidePanelSkeleton";
import { observer } from "mobx-react";
import React from "react";
import SidePanelElement from "../../../../../SidePanelElement";

export interface ISidePanelSubElementsRenderersProps {
    subElements: SidePanelItemDefinition[];
    activeSidePanelId: string | undefined;
    handlePanelChangeWithAnimation: HandlePanelChangeWithAnimation;
    isLoading?: boolean;
    isCustomRightSidePanelDisplayed?: boolean;
    externalSideElementHighlightId?: string;
}

export const SidePanelSubElementsRenderer: React.FC<ISidePanelSubElementsRenderersProps> = observer(
    (props: ISidePanelSubElementsRenderersProps) => {
        const {
            subElements,
            isLoading,
            activeSidePanelId,
            handlePanelChangeWithAnimation,
            isCustomRightSidePanelDisplayed,
            externalSideElementHighlightId,
            ...otherProps
        } = props;

        if (isLoading) {
            return <SidePanelSkeleton />;
        }

        return (
            <div {...otherProps}>
                {subElements.map((definition) => (
                    <SidePanelElement
                        key={definition.id}
                        sidePanelItemDefinition={definition}
                        isActive={
                            definition.id === activeSidePanelId || externalSideElementHighlightId === definition.id
                        }
                        handleActivePanelChange={handlePanelChangeWithAnimation}
                        isCustomRightSidePanelDisplayed={isCustomRightSidePanelDisplayed}
                    />
                ))}
            </div>
        );
    },
);
