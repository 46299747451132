import "./index.less";
import * as React from "react";
import { CustomTextInput } from "../../CustomTextInput";
import { StripeCVC } from "../StripeCVC";
import { StripeCardNumber } from "../StripeCardNumber";
import { StripeExpirationDate } from "../StripeExpirationDate";

export interface IStripeCreditCardFormProps {
    zipCodeValue: string;
    onZipCodeChange: (event: React.FormEvent) => void;
}

export const StripeCreditCardForm: React.FC<IStripeCreditCardFormProps> = (props: IStripeCreditCardFormProps) => {
    const { zipCodeValue, onZipCodeChange } = props;

    return (
        <div className="StripeCreditCardForm">
            <div className="stripe-elements-group">
                <StripeCardNumber />
                <StripeExpirationDate />
                <StripeCVC />
            </div>
            <div className="zipCode-field-container">
                <CustomTextInput
                    fieldName={"zipCode"}
                    value={zipCodeValue}
                    onChange={onZipCodeChange}
                    placeholder={"H1H 1H1 / 12345"}
                />
            </div>
        </div>
    );
};
