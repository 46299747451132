import { ReactAppSettings } from "../libs";
import { TokensApi } from "../apis";

export async function getHelpCenterUrl(path: string, userLanguageCode: string) {
    const urlPrefix = `${ReactAppSettings.appUrls.helpCenterRootUrl}/hc/${userLanguageCode.toLowerCase()}`;
    const returnUrl = `${urlPrefix}/${path}`;

    const { zendeskAccessToken } = await TokensApi.requestZendeskAccessToken();

    return `${ReactAppSettings.appUrls.helpCenterSignInUrl.replace(
        "{jwt}",
        zendeskAccessToken,
    )}&return_to=${returnUrl}`;
}
