import "./TransactionsContainer.less";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TransactionsList } from "../../components/TransactionsList";
import { inject, observer } from "mobx-react";

export interface ITransactionsContainerProps extends RouteComponentProps<any> {}

@inject()
@observer
class TransactionsContainerComp extends React.Component<ITransactionsContainerProps> {
    render() {
        return (
            <div id="TransactionsContainer">
                <TransactionsList />
            </div>
        );
    }
}

const TransactionsContainer = withRouter(TransactionsContainerComp);

export { TransactionsContainer };
