import "./index.less";
import {
    AppScope,
    GlobalStores,
    IAppContext,
    ISidebarSectionItem,
    Role,
    RouteProvider,
    UserInfoStore,
} from "@atman/business";
import { AtmanLogoIcon } from "../../static";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { CustomSidebarNavItem } from "./components/CustomSidebarNavItem";
import { Nav } from "reactstrap";
import { RouteNavItem } from "./components/RouteNavItem";
import { SidebarNavItem } from "./components/SidebarNavItem";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";

export interface IAtSidebarProps extends IAppContext {
    mainNavItems: ISidebarSectionItem[];
    toggleSidebar: () => void;
    sidebarIsOpen?: boolean;
    allowedRolesForSettings?: Role[];
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AtSidebarComp extends BaseResponsiveComponent<IAtSidebarProps> {
    protected get rootElementId(): string | undefined {
        return "AtSidebar";
    }

    @computed protected get rootElementClassName(): string | undefined {
        return this.props.sidebarIsOpen ? "sidebar-open" : "sidebar-close";
    }

    private dropdownTimeout: number;
    @observable public dropdownIsOpen: boolean = false;

    @action
    toggleDropdown = () => {
        this.dropdownIsOpen = !this.dropdownIsOpen;
    };

    @action
    openDropdown = () => {
        window.clearTimeout(this.dropdownTimeout);

        this.dropdownIsOpen = true;
    };

    closeDropdown = () => {
        this.dropdownTimeout = window.setTimeout(() => {
            runInAction(() => {
                this.dropdownIsOpen = false;
            });
        }, 500);
    };

    renderMainNavItems() {
        const { mainNavItems, userInfoStore } = this.props;

        return mainNavItems
            .filter((x) => {
                if (!x.requiredRoles?.any()) {
                    return true;
                }

                switch (x.requiredRolesMatchType ?? "allOf") {
                    case "anyOf":
                        return userInfoStore!.hasOneOfRoles(x.requiredRoles);
                    case "allOf":
                    default:
                        return userInfoStore!.hasAllRoles(x.requiredRoles);
                }
            })
            .map((x, i) => (
                <CustomSidebarNavItem
                    href={x.href}
                    iconName={x.iconName}
                    label={x.label}
                    uniqueKey={x.uniqueKey}
                    iconProps={x.iconProps}
                    key={x.uniqueKey}
                />
            ));
    }

    renderDefault() {
        const { allowedRolesForSettings, userInfoStore } = this.props;

        return (
            <>
                <Nav className="sidebar" vertical>
                    <div className="sidebar-header">
                        <img width={24} src={AtmanLogoIcon} className="markLogo" alt={"Atman Logo"} />
                    </div>
                    <div className="sections-container">
                        <div className="main-navigation">{this.renderMainNavItems()}</div>
                        <div className="bottom-navigation">
                            {(!allowedRolesForSettings?.any() ||
                                userInfoStore!.hasOneOfRoles(allowedRolesForSettings)) && (
                                <CustomSidebarNavItem
                                    href={RouteProvider.defaultRouteEntries.settings}
                                    iconName={"cog"}
                                    label={"global.settings".localize()}
                                    uniqueKey={"settings"}
                                />
                            )}
                        </div>
                    </div>
                </Nav>
            </>
        );
    }

    renderMobile() {
        const { scope, allowedRolesForSettings, toggleSidebar, userInfoStore } = this.props;

        let sidebarHeaderLabel = "";

        switch (scope) {
            case AppScope.Manager:
            case AppScope.Client:
                sidebarHeaderLabel = userInfoStore!.clientName;
                break;
            case AppScope.Partner:
                sidebarHeaderLabel = userInfoStore!.partnerName;
                break;
            case AppScope.Supplier:
                sidebarHeaderLabel = "AtmanCo"; // userInfoStore!.supplierName;
                break;
        }

        return (
            <>
                <Nav className="sidebar" vertical>
                    <div className="sidebar-header">{sidebarHeaderLabel}</div>
                    <div className="sections-container">
                        <div className="main-navigation">{this.renderMainNavItems()}</div>
                        <div className="bottom-navigation">
                            {(!allowedRolesForSettings?.any() ||
                                userInfoStore!.hasOneOfRoles(allowedRolesForSettings)) && (
                                <CustomSidebarNavItem
                                    href={RouteProvider.defaultRouteEntries.settings}
                                    iconName={"cog"}
                                    label={"global.settings".localize()}
                                    uniqueKey={"settings"}
                                    onClick={toggleSidebar}
                                />
                            )}
                            <CustomSidebarNavItem
                                iconName={"power-off"}
                                label={"global.logout".localize()}
                                uniqueKey={"logout"}
                                onClick={userInfoStore!.logout}
                            />
                        </div>
                    </div>
                </Nav>
            </>
        );
    }
}

const AtSidebar = withAppContext(AtSidebarComp);

export { AtSidebar, SidebarNavItem, RouteNavItem };
