import "./DevelopmentPlanStrategicObjectives.less";
import { AtButton, AtCard, AtCheckableCard, AtParagraph, AtSubsection, AtText } from "@atman/design-system";
import {
    CompDevCompetencyResult,
    CompDevLearningResource,
    CompDevSubCompetencyResult,
    CompetencyDevelopmentLearningResourceType,
    ISelectedObjectiveToImproveData,
    getEnumValues,
} from "@atman/business";
import { CompDevSubCompetenciesStrategicObjectiveResult } from "@atman/business/lib/models/CompetencyDevelopment/ResultModels/CompDevSubCompetenciesStrategicObjectiveResult";
import { CustomTextInput, LearningResource, LearningResourceFilterContainer } from "../../../../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StrategicObjectiveModalContent } from "@atman/ui-kit/lib/modals/contents/StrategicObjectiveModalContent";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

interface DevelopmentPlanStrategicObjectivesProps {
    strategicObjectives: CompDevSubCompetenciesStrategicObjectiveResult[];
    selectedObjectivesData?: Map<string, ISelectedObjectiveToImproveData>;
    parentId: string;
    learningResources?: CompDevLearningResource[];
    selectedLearningResourceIds?: string[];
    learningResourceNotes?: string;
    competency: CompDevCompetencyResult;
    subCompetency: CompDevSubCompetencyResult;
    onLearningResourceChange?: (id: string) => void;
    onLearningResourceNotesChange?: (value: string) => void;
    onStrategicObjectiveSelectionDataSave: (
        objectiveId: string,
        selectedApplicationIds: string[],
        applicationNote: string,
    ) => void;
}

export const DevelopmentPlanStrategicObjectives: FunctionComponent<DevelopmentPlanStrategicObjectivesProps> = observer(
    (props) => {
        const {
            strategicObjectives,
            selectedObjectivesData,
            parentId,
            onStrategicObjectiveSelectionDataSave,
            learningResources,
            selectedLearningResourceIds = [],
            learningResourceNotes = "",
            onLearningResourceChange,
            onLearningResourceNotesChange,
            competency,
            subCompetency,
        } = props;

        const [selectedLearningResourceTypes, setSelectedLearningResourceTypes] = useState<
            (CompetencyDevelopmentLearningResourceType | "all")[]
        >(["all"]);

        const [showLearningResource, setShowLearningResource] = useState(false);
        const [showLearningResourceNote, setShowLearningResourceNote] = useState(false);

        const saveStrategicObjectiveApplications = (
            strategicObjectiveId: string,
            selectedApplicationsFromModal: string[],
            applicationNoteFromModal: string,
        ) => {
            onStrategicObjectiveSelectionDataSave?.(
                strategicObjectiveId,
                selectedApplicationsFromModal,
                applicationNoteFromModal,
            );
            setOpenModal(null);
        };

        useEffect(() => {
            if (learningResourceNotes.length > 0) {
                setShowLearningResourceNote(true);
            }
        }, [learningResourceNotes.length]);

        const filteredLearningResources: CompDevLearningResource[] = useMemo(() => {
            if (!learningResources) {
                return [];
            }

            if (selectedLearningResourceTypes.includes("all")) {
                return learningResources;
            }

            return learningResources.filter((x) =>
                selectedLearningResourceTypes.includes(x.type as CompetencyDevelopmentLearningResourceType),
            );
        }, [learningResources, selectedLearningResourceTypes]);

        const filters: CompetencyDevelopmentLearningResourceType[] = useMemo(() => {
            const allFilters = getEnumValues<CompetencyDevelopmentLearningResourceType>(
                CompetencyDevelopmentLearningResourceType,
            );

            return allFilters.filter((x) => learningResources?.some((y) => y.type === x));
        }, [learningResources]);

        const [openModal, setOpenModal] = useState<string | null>(null);

        return (
            <div className="DevelopmentPlanStrategicObjectives">
                {strategicObjectives && strategicObjectives.length > 0 && (
                    <AtSubsection
                        headingType={3}
                        title={t({
                            id: "competencyDevelopment.SubCompetenciesStrategicObjectiveSectionsTitle",
                        })}
                        subtitle={t({
                            id: "competencyDevelopment.SubCompetenciesStrategicObjectiveSectionsSubTitle",
                        })}
                        className="applications-container"
                    >
                        {strategicObjectives.map((obj) => {
                            const selectedObjectiveData = selectedObjectivesData?.get(obj.id) ?? {
                                selectedApplicationIds: [],
                                applicationNotes: "",
                            };
                            const isChecked = selectedObjectiveData.selectedApplicationIds.length > 0;

                            const topContent: JSX.Element = (
                                <div className="objective-row" onClick={() => setOpenModal(obj.id)}>
                                    {obj.description?.split("\n").map((y) => (
                                        <AtParagraph key={`paragraph-${y}`}>{y}</AtParagraph>
                                    ))}
                                    <div className="applications-numbers-and-icon">
                                        <FontAwesomeIcon
                                            icon={["fas", "bolt"]}
                                            color={isChecked ? "#3366FF" : "#D6D6D6"}
                                        />
                                        {selectedObjectiveData.selectedApplicationIds.length}
                                    </div>
                                    <AtButton
                                        className="showMoreBtn"
                                        onClick={() => setOpenModal(obj.id)}
                                        color="ghost"
                                    >
                                        <AtText className="showMoreText">{t({ id: "global.showMore" })}</AtText>
                                    </AtButton>
                                    <FontAwesomeIcon icon={["far", "chevron-right"]} />
                                </div>
                            );

                            return (
                                <React.Fragment key={obj.id}>
                                    <AtCheckableCard
                                        className="strategicObjectiveCard"
                                        id={obj.id}
                                        checked={isChecked}
                                        onCheckboxClick={() => {
                                            if (isChecked) {
                                                onStrategicObjectiveSelectionDataSave(obj.id, [], "");
                                            } else {
                                                onStrategicObjectiveSelectionDataSave(
                                                    obj.id,
                                                    obj.applications.map((x) => x.id),
                                                    "",
                                                );
                                            }
                                        }} //TODO fix the checkbox click the code also trigger the container click
                                        topContent={topContent}
                                    />

                                    <StrategicObjectiveModalContent
                                        openModal={openModal === obj.id}
                                        setOpenModal={setOpenModal}
                                        strategicObjective={obj}
                                        selectedObjectiveData={selectedObjectiveData}
                                        competency={competency}
                                        subCompetency={subCompetency}
                                        saveStrategicObjectiveApplications={saveStrategicObjectiveApplications}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </AtSubsection>
                )}

                {showLearningResource && learningResources && learningResources.length > 0 && (
                    <AtSubsection
                        title={t({ id: "competencyDevelopment.select.learningResources.title" })}
                        subtitle={(onLearningResourceChange
                            ? "competencyDevelopment.learningResources.description.editable"
                            : "competencyDevelopment.learningResources.description.readOnly"
                        ).localize()}
                        className="learning-resources-container"
                        headingType={5}
                    >
                        <LearningResourceFilterContainer
                            filters={filters}
                            selectedFilters={selectedLearningResourceTypes}
                            onFilterClick={(selectedTypes) => setSelectedLearningResourceTypes(selectedTypes)}
                        />
                        <div className="learningResourcesContainer d-flex flex-wrap align-items-start justify-content-start">
                            {filteredLearningResources.map((x) => (
                                <LearningResource
                                    key={`resource-${x.id}`}
                                    learningResource={x}
                                    parentId={parentId}
                                    checked={selectedLearningResourceIds.includes(x.id)}
                                    onClick={() => (onLearningResourceChange ? onLearningResourceChange(x.id) : {})}
                                    isV2
                                />
                            ))}
                        </div>
                        {onLearningResourceNotesChange ? (
                            <div className="notes-input-container">
                                {showLearningResourceNote ? (
                                    <CustomTextInput
                                        className="note-text-input"
                                        fieldName={"learningResourceNotes"}
                                        label={t({
                                            id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                            message: "Additional Notes",
                                        })}
                                        placeholder={t({
                                            id: "competencyDevelopment.applicationNotes.placeholder",
                                            message: "Add a note and customize an objective if needed.",
                                        })}
                                        value={learningResourceNotes}
                                        onChange={(e) =>
                                            onLearningResourceNotesChange
                                                ? onLearningResourceNotesChange(e.currentTarget.value)
                                                : {}
                                        }
                                        type={"textarea"}
                                        rows={3}
                                    />
                                ) : (
                                    <AtButton
                                        color={"secondary"}
                                        size={"md"}
                                        icon={["far", "comment-dots"]}
                                        onClick={() => setShowLearningResourceNote(true)}
                                    >
                                        <Trans id="competencyDevelopment.applicationNotes.addNotes">Add notes</Trans>
                                    </AtButton>
                                )}
                            </div>
                        ) : learningResourceNotes ? (
                            <AtCard
                                title={t({
                                    id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                    message: "Additional Notes",
                                })}
                            >
                                {learningResourceNotes.split("\n").map((x, i) => (
                                    <div key={`observable-behavior-note-${i}`}>{x}</div>
                                ))}
                            </AtCard>
                        ) : null}
                    </AtSubsection>
                )}

                <AtButton
                    color={"secondary"}
                    size={"md"}
                    icon={showLearningResource ? undefined : ["far", "plus"]}
                    onClick={() => setShowLearningResource(!showLearningResource)}
                >
                    {showLearningResource ? (
                        <Trans id={"competencyDevelopment.strategicObjectives.buttonHideRessources"}>
                            Close ressources
                        </Trans>
                    ) : (
                        <Trans id={"competencyDevelopment.strategicObjectives.buttonShowRessources"}>
                            Add ressources to your objectives
                        </Trans>
                    )}
                </AtButton>
            </div>
        );
    },
);
