import { AtTitle } from "@atman/design-system";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IScoreSubTitleProps {
    text?: string;
}

@inject()
@observer
export class ScoreSubTitle extends React.Component<IScoreSubTitleProps> {
    render() {
        const { text } = this.props;
        return <AtTitle className="subtitle" headingType={6} title={text} />;
    }
}
