import "./AssignmentForm.less";
import * as React from "react";
import { AtAsyncSelect, AtTable, IReactSelectOptionObject } from "@atman/design-system";
import { BaseForm, ModalButtons } from "../../components";
import { IReactionDisposer, action, observable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react";
import getAssignementFormColumns from "./AssignementFormColumns";

export interface IAssignmentFormProps {
    loadItems: () => Promise<IReactSelectOptionObject[]>;
    loadOptions: (inputValue: string) => Promise<IReactSelectOptionObject[]>;
    onSave: (itemIds: string[]) => Promise<void>;
    selectFieldPlaceholder: string;
    selectKey?: string;
}

@observer
export class AssignmentForm extends BaseForm<IAssignmentFormProps> {
    @observable private selectedItemIds: IReactSelectOptionObject[] = [];

    loadItemsReactionDisposer: IReactionDisposer = reaction(
        () => this.props.loadItems,
        () => this._loadItems(),
    );

    componentDidMount() {
        this._loadItems();
    }

    componentWillUnmount(): void {
        this.loadItemsReactionDisposer();
    }

    @action private async _loadItems() {
        const { loadItems } = this.props;

        const result = await loadItems();

        runInAction(() => {
            this.selectedItemIds = result;
        });
    }

    @action private options = async (inputValue: string) => {
        const { loadOptions } = this.props;

        return await this.loadAsyncSelectOptions("assignment-modal", async () => {
            const results = await loadOptions(inputValue);

            return results.filter((x) => !this.selectedItemIds.some((i) => i.value === x.value));
        });
    };

    @action private addItem = (option: IReactSelectOptionObject) => {
        if (!this.selectedItemIds.find((x) => x.value === option.value)) {
            this.selectedItemIds.push(option);
        }
    };

    @action private removeItem = (itemId: string) => {
        const itemIndex = this.selectedItemIds.findIndex((x) => x.value === itemId);

        if (itemIndex >= 0) {
            this.selectedItemIds.splice(itemIndex, 1);
        }
    };

    onSave = async () => {
        const { onSave } = this.props;

        this.setIsLoading(true);

        await onSave(this.selectedItemIds.map((x) => x.value));

        this.setIsLoading(false);
    };

    render() {
        const { selectFieldPlaceholder } = this.props;

        const columns = getAssignementFormColumns(this.removeItem);

        return (
            <div id="AssignmentForm">
                <AtAsyncSelect
                    loadOptions={this.options}
                    value={null}
                    onChange={this.addItem}
                    placeholder={selectFieldPlaceholder}
                    defaultOptions
                    cacheOptions
                />

                <AtTable showFilters={false} items={this.selectedItemIds} columns={columns} />
                <ModalButtons saveAction={this.onSave} isLoading={this.isLoading} />
            </div>
        );
    }
}
