import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import {
    Client,
    IClientConsumptionSettings,
    IClientGeneralSettings,
    IClientInput,
    IClientMarketingInfo,
    IClientToggledFeatures,
    ICustomTermsAndConditions,
    IOrganizationCustomTermsConditions,
} from "../models";
import { ClientsApi } from "../apis";
import {
    CompetencyProfile,
    IAddProductConsumptionModeScheduleEntryModel,
    IBillingInformation,
    IProductConsumptionModeScheduleEntryModel,
    ProductCodeProEnum,
} from "../types";
import { ReactAppSettings, ToastProvider } from "../libs";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class ClientStore extends BaseStore {
    @observable public clients: Client[] = [];

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
    }

    @action
    loadClients = async () => {
        await this.tryCatch(async () => {
            const clients = await ClientsApi.getClients();

            runInAction(() => {
                this.clients = clients.map((x) => new Client(x));
                this.isLoading = false;
            });
        });
    };

    getClientById: (id: string) => Client | undefined = computedFn((id: string) => {
        return this.clients.find((x) => x.id.toLowerCase() === id.toLowerCase());
    });

    getClientIndexById: (id: string) => number = computedFn((id: string) => {
        return this.clients.findIndex((x) => x.id.toLowerCase() === id.toLowerCase());
    });

    @action
    createClient = async (client: IClientInput) => {
        return await this.tryCatch(
            async () => {
                const result = await ClientsApi.createClient(client);

                runInAction(() => {
                    this.clients.push(new Client(result));
                    this.isLoading = false;
                });

                ToastProvider.success("partnerApp.clientCreationSuccessMessage".localize());

                return result;
            },
            undefined,
            true,
        );
    };

    @action
    updateClientName = async (id: string, name: string) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateClientName(id, name);

                runInAction(() => {
                    this.clients[index].name = name;
                    this.isLoading = false;
                });

                ToastProvider.success("global.clientNameChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updateClientAdditionalInformation = async (
        id: string,
        marketingInfo: IClientMarketingInfo,
        generalSettings: IClientGeneralSettings,
        languageCode: string,
    ) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateClientAdditionalInformation(id, {
                    generalSettings,
                    marketingInfo,
                    languageCode,
                });

                runInAction(() => {
                    this.clients[index].generalSettings = generalSettings;
                    this.clients[index].marketingInfo = marketingInfo;
                    this.clients[index].communicationLanguageCode = languageCode;
                });

                ToastProvider.success("global.clientAdditionalInformationChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updateClientBillingInformation = async (id: string, billingInformation: IBillingInformation) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            const country = ReactAppSettings.appModel.countries.find(
                (x) => x.id === billingInformation.address.country,
            );

            if (!country) {
                ToastProvider.error("Selected country is invalid");
                return;
            }

            const state = country.states.find((x) => x.id === billingInformation.address.state);

            if (country.states.any() && !state) {
                ToastProvider.error("Selected state is invalid");
                return;
            }

            await this.tryCatch(async () => {
                await ClientsApi.updateClientBillingInformation(id, {
                    address: billingInformation.address.address,
                    address2: billingInformation.address.address2,
                    city: billingInformation.address.city,
                    zipCode: billingInformation.address.zipCode,
                    stateId: billingInformation.address.state,
                    countryId: billingInformation.address.country,
                    phone: billingInformation.address.phone,
                    extension: billingInformation.address.extension,
                    contactName: billingInformation.contactName,
                    contactEmail: billingInformation.email,
                    legalName: billingInformation.legalName,
                    language: billingInformation.language,
                });

                runInAction(() => {
                    this.clients[index].billingInformation = {
                        ...billingInformation,
                        address: {
                            ...billingInformation.address,
                            country: country.code,
                            state: state ? state.code : "",
                        },
                    };
                    this.isLoading = false;
                });

                ToastProvider.success("global.clientBillingInformationChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updateClientGeneralSettings = async (id: string, generalSettings: IClientGeneralSettings) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateClientGeneralSettings(id, generalSettings);

                runInAction(() => {
                    this.clients[index].generalSettings = generalSettings;
                    this.isLoading = false;
                });

                ToastProvider.success("clientGeneralSettingsChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updateClientMarketingInfo = async (id: string, additionalInformation: IClientMarketingInfo) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateClientMarketingInfo(id, additionalInformation);

                runInAction(() => {
                    this.clients[index].marketingInfo = additionalInformation;
                    this.isLoading = false;
                });

                ToastProvider.success("partnerApp.clientMarketingInfoChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updateClientConsumptionSettings = async (id: string, consumptionSettings: IClientConsumptionSettings) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateClientConsumptionSettings(id, consumptionSettings);

                runInAction(() => {
                    this.clients[index].consumptionSettings = consumptionSettings;
                    this.clients[index].usageModel.restrictionType = consumptionSettings.restrictionType;
                    this.clients[index].usageModel.discountRate = consumptionSettings.discountRate;
                    this.isLoading = false;
                });

                ToastProvider.success("partnerApp.clientConsumptionSettingsChangedSuccessMessage".localize());
            });
        }
    };

    @action
    addClientConsumptionScheduleEntry = async (id: string, model: IAddProductConsumptionModeScheduleEntryModel) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    const result = await ClientsApi.addClientConsumptionScheduleEntry(id, model);

                    runInAction(() => {
                        this.clients[index].usageModel.productConsumptionModeSchedule.push(result);
                        this.isLoading = false;
                    });

                    ToastProvider.success("global.consumptionScheduleEntryAddedSuccessMessage".localize());
                },
                undefined,
                true,
            );
        }
    };

    @action
    updateClientConsumptionScheduleEntry = async (id: string, entry: IProductConsumptionModeScheduleEntryModel) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            const entryIndex = this.clients[index].usageModel.productConsumptionModeSchedule.findIndex(
                (x) => x.entryId === entry.entryId,
            );

            if (entryIndex >= 0) {
                await this.tryCatch(
                    async () => {
                        await ClientsApi.updateClientConsumptionScheduleEntry(id, {
                            entryId: entry.entryId,
                            effectiveDate: entry.effectiveDate,
                            endDate: entry.endDate,
                            consumptionMode: entry.consumptionMode,
                            expectedConsumption: entry.expectedConsumption,
                        });

                        runInAction(() => {
                            this.clients[index].usageModel.productConsumptionModeSchedule[entryIndex] = entry;
                            this.isLoading = false;
                        });

                        ToastProvider.success("global.consumptionScheduleEntryUpdatedSuccessMessage".localize());
                    },
                    undefined,
                    true,
                );
            }
        }
    };

    @action
    removeClientConsumptionScheduleEntry = async (id: string, entryId: string) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            const entryIndex = this.clients[index].usageModel.productConsumptionModeSchedule.findIndex(
                (x) => x.entryId === entryId,
            );

            if (entryIndex >= 0) {
                await this.tryCatch(
                    async () => {
                        await ClientsApi.removeClientConsumptionScheduleEntry(id, { entryId });

                        runInAction(() => {
                            this.clients[index].usageModel.productConsumptionModeSchedule.splice(entryIndex, 1);
                            this.isLoading = false;
                        });

                        ToastProvider.success("global.consumptionScheduleEntryRemovedSuccessMessage".localize());
                    },
                    undefined,
                    true,
                );
            }
        }
    };

    @action
    updateProductRestrictions = async (id: string, productCodes: ProductCodeProEnum[]) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.updateProductRestrictions(id, productCodes);

                ToastProvider.success("global.productRestrictionsUpdatedSuccessfully".localize());

                runInAction(() => {
                    this.clients[index].usageModel.productRestriction = productCodes;
                    this.isLoading = false;
                });
            });
        }
    };

    @action
    toggleFeatures = async (id: string, features: IClientToggledFeatures) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await ClientsApi.toggleFeatures(id, features);

                ToastProvider.success("global.featuresUpdatedSuccessfully".localize());

                runInAction(() => {
                    this.clients[index].features = features;
                });
            });
        }
    };

    @action activateClientCustomTermsConditions = async (id: string): Promise<void> => {
        await this.tryCatch(async () => {
            await ClientsApi.activateClientCustomTermsConditions(id);

            ToastProvider.success("global.customTermsConditionsActivatedSuccessfully".localize());
        });
    };

    @action deactivateClientCustomTermsConditions = async (id: string): Promise<void> => {
        await this.tryCatch(async () => {
            await ClientsApi.deactivateClientCustomTermsConditions(id);

            ToastProvider.success("global.customTermsConditionsDeactivatedSuccessfully".localize());
        });
    };

    @action getOrganizationCustomTermsConditions = async (): Promise<
        IOrganizationCustomTermsConditions | undefined
    > => {
        return await this.tryCatch(async () => {
            return await ClientsApi.getOrganizationCustomTermsConditions();
        });
    };

    @action updateOrganizationCustomTermsConditions = async (
        customTermsConditions: ICustomTermsAndConditions,
    ): Promise<void> => {
        await this.tryCatch(async () => {
            await ClientsApi.updateOrganizationCustomTermsConditions(
                [...customTermsConditions.title.entries()],
                [...customTermsConditions.description.entries()],
            );
            ToastProvider.success("global.customTermsConditionsUpdateSuccessfully".localize());
        });
    };

    @action
    transferCandidate = async (
        id: string,
        candidateProId: string,
        newClientId: string,
    ): Promise<void | undefined | string> => {
        const index = this.getClientIndexById(id);
        const newClientIndex = this.getClientIndexById(newClientId);

        if (index >= 0) {
            return await this.tryCatch(
                async () => {
                    await ClientsApi.transferCandidate(id, candidateProId, newClientId);

                    ToastProvider.success("partnerApp.candidateTransferredSuccessfully".localize());

                    runInAction(() => {
                        this.clients[index].profilesCount--;
                        this.clients[newClientIndex].profilesCount++;
                    });
                },
                (error) => {
                    if (Array.isArray(error)) {
                        if (error.any()) {
                            return error.firstOrDefault()!.message;
                        }

                        return;
                    } else {
                        return error.message;
                    }
                },
            );
        }
    };

    @action
    updatePerformanceEvaluationMappings = async (id: string, mappings: Dictionary<CompetencyProfile, string>) => {
        await this.tryCatch(async () => {
            await ClientsApi.updateMappings(id, mappings);

            ToastProvider.success("supplierApp.performanceEvaluationMappingsUpdatedSuccessMessage".localize());
        });
    };

    @action
    updateClientBalance = (id: string, updatedBalance: Dictionary<ProductCodeProEnum, number>) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            for (const key of Object.keys(updatedBalance)) {
                this.clients[index].usageModel.productBalance[Number(key)] += updatedBalance[Number(key)];
            }
        }
    };

    @action
    updateClientCulture = (id: string, cultureId: string | undefined, cultureName: string | undefined) => {
        const index = this.getClientIndexById(id);

        if (index >= 0) {
            this.clients[index].cultureId = cultureId;
            this.clients[index].cultureName = cultureName;
        }
    };

    @computed
    get clientsCount() {
        return this.clients.length;
    }

    autorunner = autorun(() => {});
}
