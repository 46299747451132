import "../index.less";
import "./index.less";
import { AtLink } from "@atman/design-system";
import {
    BaseCompDevObjectiveElementResult,
    CompDevCompetencyResult,
    CompDevStrategicObjectiveResult,
    CompDevSubCompetencyResult,
    ISelectedObjectiveData,
} from "@atman/business";
import {
    CompDevTableDisplayType,
    CompetencyDevelopmentColumnType,
    IPersonInfo,
    TableHeaderRow,
    TableRow,
} from "../../..";
import { DevelopmentPlanLeverageCompetencySection } from "./DevelopmentPlanLeverageCompetencySection";
import { ObservableMap } from "mobx";
import { SortDirection } from "../../../../..";
import { Trans } from "@lingui/macro";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";
import React, { useEffect, useState } from "react";

export interface ILeverageDevelopmentPlanCompetencyResultTableProps {
    competencies: CompDevCompetencyResult[];
    columns: CompetencyDevelopmentColumnType[];
    displayType: CompDevTableDisplayType;
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    selectedElements: Map<string, Map<string, ISelectedObjectiveData>>;
    erroredElements?: Map<string, string[]>;
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
    onSelectedElementsChange: (selectedElements: Map<string, Map<string, ISelectedObjectiveData>>) => void;
}

export const LeverageDevelopmentPlanCompetencyResultTable: React.FC<ILeverageDevelopmentPlanCompetencyResultTableProps> =
    observer((props) => {
        const {
            competencies,
            selectedElements,
            columns,
            displayType,
            sortElement,
            sortDirection,
            managerInfo,
            employeeInfo,
            hiddenColumns,
            erroredElements = new Map(),
            onSortChange,
        } = props;

        const maxElementsToDisplay: number = 7;

        const [showAllCompetencies, setShowAllCompetencies] = useState<boolean>(false);

        // Fill the selection state.
        useEffect(() => {
            competencies.forEach((elDef) => {
                const subElementDefs = getSubElementsFromElement(elDef);

                subElementDefs.forEach((subElDef: CompDevStrategicObjectiveResult) => {
                    if (subElDef.isSelected) {
                        const subEl = selectSubElement(elDef.id, subElDef.id);

                        subEl.selectedApplicationIds = [...subElDef.selectedApplicationIds];
                        subEl.selectedLearningResourceIds = [...subElDef.selectedLearningResourceIds];
                        subEl.applicationNotes = subElDef.applicationNotes;
                        subEl.learningResourceNotes = subElDef.learningResourceNotes;
                    }
                });
            });
        }, []);

        const toggleShowAllCompetencies: () => void = () => {
            setShowAllCompetencies(!showAllCompetencies);
        };

        const getSelectedElement = (elementId: string) => {
            return selectedElements.get(elementId);
        };

        const cleanElementAfterDeselection = (elementId: string) => {
            const element = getSelectedElement(elementId);
            if (element && element.size === 0) {
                deselectElement(elementId);
            }
        };

        const cleanSubElementAfterDeselection = (elementId: string, subElementId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            if (
                subElement &&
                subElement.selectedApplicationIds.length === 0 &&
                subElement.selectedLearningResourceIds.length === 0 &&
                subElement.applicationNotes.length === 0 &&
                subElement.learningResourceNotes.length === 0
            ) {
                deselectSubElement(elementId, subElementId);
            }
        };

        const deselectElement = (elementId: string) => {
            selectedElements.delete(elementId);
        };

        const deselectSubElement = (elementId: string, subElementId: string) => {
            const element = getSelectedElement(elementId);
            if (element) {
                element.delete(subElementId);
                cleanElementAfterDeselection(elementId);
                cleanSubElementAfterDeselection(elementId, subElementId);
            }
        };

        const deselectApplication = (elementId: string, subElementId: string, applicationId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);
            if (subElement) {
                const applicationIndex = subElement.selectedApplicationIds.indexOf(applicationId);

                if (applicationIndex >= 0) {
                    subElement.selectedApplicationIds.splice(applicationIndex, 1);
                    cleanSubElementAfterDeselection(elementId, subElementId);
                }
            }
        };

        const deselectLearningResource = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            if (subElement) {
                const learningResourceIndex = subElement.selectedLearningResourceIds.indexOf(learningResourceId);
                if (learningResourceIndex >= 0) {
                    subElement.selectedLearningResourceIds.splice(learningResourceIndex, 1);
                    cleanSubElementAfterDeselection(elementId, subElementId);
                }
            }
        };

        const selectElement = (elementId: string) => {
            let element = selectedElements.get(elementId);
            if (!element) {
                element = new ObservableMap<string, ISelectedObjectiveData>();
                selectedElements.set(elementId, element);
                element = selectedElements.get(elementId)!;
            }

            return element;
        };

        const getSubElementsFromElement = (
            element?: CompDevCompetencyResult,
        ): Array<CompDevSubCompetencyResult | CompDevStrategicObjectiveResult> => {
            if (element) {
                if (displayType === "developmentPlanLeverage") return element.strategicObjectives;
                else return element.subCompetencies;
            }

            return [];
        };

        const getSelectedSubElement = (elementId: string, subElementId: string) => {
            const element = getSelectedElement(elementId);

            return element?.get(subElementId);
        };

        const isSubElementSelectable = (elementId: string, subElementId: string) => {
            const el = competencies.find((elDef) => elDef.id === elementId);
            if (!el) return false;

            const subElementDefs = getSubElementsFromElement(el);

            const subElementDef = (subElementDefs as BaseCompDevObjectiveElementResult[]).find(
                (subElDef) => subElDef.id === subElementId,
            );

            if (!subElementDef) return false;

            return subElementDef.applications.length > 0;
        };

        const selectSubElement = (elementId: string, subElementId: string) => {
            const element = selectElement(elementId);

            let subElement = element.get(subElementId);
            if (!subElement) {
                subElement = {
                    selectedLearningResourceIds: [],
                    selectedApplicationIds: [],
                    learningResourceNotes: "",
                    applicationNotes: "",
                } as ISelectedObjectiveData;
                element.set(subElementId, subElement);
                subElement = element.get(subElementId)!;
            }

            return subElement;
        };

        const selectSubElementAndChildren = (elementId: string, subElementId: string) => {
            const competencySource = competencies.find((x) => x.id === elementId);

            const subElementSource = (
                getSubElementsFromElement(competencySource) as BaseCompDevObjectiveElementResult[]
            ).find((o) => o.id === subElementId);

            const applicationsSource = subElementSource?.applications ?? [];

            const subElement = selectSubElement(elementId, subElementId);

            applicationsSource.forEach((app) => {
                subElement.selectedApplicationIds.push(app.id);
            });

            return subElement;
        };

        const selectElementAndChildren = (elementId: string) => {
            const element = selectElement(elementId);

            const competency = competencies.find((x) => x.id === elementId);
            const allSubElements = getSubElementsFromElement(competency);
            const allSubElementIds = allSubElements.map((x) => x.id);

            allSubElementIds.forEach((subElementId) => {
                if (isSubElementSelectable(elementId, subElementId))
                    selectSubElementAndChildren(elementId, subElementId);
            });

            return element;
        };

        const selectApplication = (elementId: string, subElementId: string, applicationId: string) => {
            const subElement = selectSubElement(elementId, subElementId);

            const applicationIndex = subElement.selectedApplicationIds.indexOf(applicationId);
            if (applicationIndex === -1) {
                subElement.selectedApplicationIds.push(applicationId);
            }
        };

        const selectLearningResource = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = selectSubElement(elementId, subElementId);

            const learningResourceIndex = subElement.selectedLearningResourceIds.indexOf(learningResourceId);
            if (learningResourceIndex === -1) {
                subElement.selectedLearningResourceIds.push(learningResourceId);
            }
        };

        const isApplicationSelected = (elementId: string, subElementId: string, applicationId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            return subElement ? subElement.selectedApplicationIds.indexOf(applicationId) >= 0 : false;
        };

        const isLearningResourceSelected = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);
            return subElement ? subElement.selectedLearningResourceIds.indexOf(learningResourceId) >= 0 : false;
        };

        const toggleElementSelection = (elementId: string) => {
            if (getSelectedElement(elementId)) deselectElement(elementId);
            else selectElementAndChildren(elementId);
        };

        const toggleSubElementSelection = (elementId: string, subElementId: string) => {
            if (getSelectedSubElement(elementId, subElementId)) deselectSubElement(elementId, subElementId);
            else selectSubElementAndChildren(elementId, subElementId);
        };

        const toggleApplicationSelection = (elementId: string, subElementId: string, applicationId: string) => {
            if (isApplicationSelected(elementId, subElementId, applicationId))
                deselectApplication(elementId, subElementId, applicationId);
            else selectApplication(elementId, subElementId, applicationId);
        };

        const toggleLearningResourceSelection = (
            elementId: string,
            subElementId: string,
            learningResourceId: string,
        ) => {
            if (isLearningResourceSelected(elementId, subElementId, learningResourceId))
                deselectLearningResource(elementId, subElementId, learningResourceId);
            else selectLearningResource(elementId, subElementId, learningResourceId);
        };

        const setApplicationNote = (elementId: string, subElementId: string, value: string) => {
            if (!value) {
                const subElement = getSelectedSubElement(elementId, subElementId);

                if (subElement) {
                    subElement.applicationNotes = "";
                }

                cleanSubElementAfterDeselection(elementId, subElementId);
                return;
            }

            const subElement = selectSubElement(elementId, subElementId);

            subElement.applicationNotes = value;
        };

        const setLearningResourceNote = (elementId: string, subElementId: string, value: string) => {
            if (!value) {
                const subElement = getSelectedSubElement(elementId, subElementId);

                if (subElement) {
                    subElement.learningResourceNotes = "";
                }

                cleanSubElementAfterDeselection(elementId, subElementId);
                return;
            }

            const subElement = selectSubElement(elementId, subElementId);

            subElement.learningResourceNotes = value;
        };

        const isElementSelectable = (elementId: string) => {
            const el = competencies.find((elDef) => elDef.id === elementId);
            if (!el) {
                return false;
            }

            const subElementDefs = getSubElementsFromElement(el);

            // Find at least one sub element with selectable applications
            const hasApplications =
                (subElementDefs as BaseCompDevObjectiveElementResult[]).find(
                    (subElDef) => subElDef.applications.length > 0,
                ) != undefined;

            return hasApplications;
        };

        const { isMobile } = useDetectDevice();

        const filteredCompetencies: CompDevCompetencyResult[] = showAllCompetencies
            ? competencies
            : competencies.slice(0, maxElementsToDisplay);

        return (
            <div className="ResultTable DevelopmentPlanCompetencyResultTable">
                {!isMobile && (
                    <TableHeaderRow
                        sortElement={sortElement}
                        onSortChange={onSortChange}
                        sortDirection={sortDirection}
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        columns={columns}
                        hiddenColumns={hiddenColumns}
                    />
                )}
                <div className="table-rows">
                    {filteredCompetencies.map((comp) => (
                        <TableRow
                            dataRow={comp}
                            columns={columns}
                            key={comp.id}
                            inError={erroredElements.has(comp.id)}
                            isSelected={selectedElements.has(comp.id)}
                            onSelectChange={() => {
                                toggleElementSelection(comp.id);
                            }}
                            disabledSelect={!isElementSelectable(comp.id) && !selectedElements.has(comp.id)}
                            showRank={false}
                            scoresColumnSet={"developmentPlanScores"}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                            collapseContentOffset={true}
                        >
                            <DevelopmentPlanLeverageCompetencySection
                                comp={comp}
                                selectedElement={selectedElements.get(comp.id)}
                                columns={columns}
                                managerInfo={managerInfo}
                                employeeInfo={employeeInfo}
                                hiddenColumns={hiddenColumns}
                                toggleSubElementSelection={toggleSubElementSelection}
                                toggleApplicationSelection={toggleApplicationSelection}
                                toggleLearningResourceSelection={toggleLearningResourceSelection}
                                setApplicationNote={setApplicationNote}
                                setLearningResourceNote={setLearningResourceNote}
                                erroredElements={erroredElements}
                            />
                        </TableRow>
                    ))}
                </div>
                {competencies.length > maxElementsToDisplay && (
                    <div className="show-all-competencies-container">
                        <AtLink
                            onClick={toggleShowAllCompetencies}
                            icon={["fas", showAllCompetencies ? "chevron-up" : "chevron-down"]}
                            iconProps={{ position: "right" }}
                        >
                            {showAllCompetencies ? (
                                <Trans id={"competencyDevelopment.assessments.global.showLessCompetencies"}>
                                    Show less competencies
                                </Trans>
                            ) : (
                                <Trans id={"competencyDevelopment.assessments.global.showAllCompetencies"}>
                                    Show all competencies
                                </Trans>
                            )}
                        </AtLink>
                    </div>
                )}
            </div>
        );
    });
