import { AnalysisTileEnum, GenericFit, JobFit } from "../models";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { IJobFitDomain } from "../models/IJobFitDomain";
import { KpiApi } from "../apis";
import { action, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class KpiStore extends BaseStore {
    @observable public jobFitDefinitions: JobFit[] = [];
    @observable public cultureFitDefinitions: GenericFit[] = [];
    @observable public potentialDefinitions: GenericFit[] = []; // MARK: Potential uses the same model as the CultureFit

    @observable public jobFitDomains: IJobFitDomain[] = [];

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
    }

    getJobFitDefinition: (id: string) => JobFit | undefined = computedFn((id) => {
        return this.jobFitDefinitions.find((x) => x.id === id);
    });

    // TODO: The backend actually returns a number. Argument needs to be caster as Number.
    getCultureFitDefinition: (id: string) => GenericFit | undefined = computedFn((id) => {
        return this.cultureFitDefinitions.find((x) => x.id === id);
    });

    getPotentialDefinition: (id: string) => GenericFit | undefined = computedFn((id) => {
        return this.potentialDefinitions.find((x) => x.id === id);
    });

    getDefinitionForTile: (id: string, tile: AnalysisTileEnum) => JobFit | GenericFit | undefined = computedFn(
        (id, tile) => {
            switch (tile) {
                case AnalysisTileEnum.JobFit:
                    return this.getJobFitDefinition(id);
                case AnalysisTileEnum.CultureFit:
                    return this.getCultureFitDefinition(id);
                case AnalysisTileEnum.Potential:
                    return this.getPotentialDefinition(id);
            }

            return undefined;
        },
    );

    @action
    loadJobFitDefinitions = async () => {
        await this.tryCatch(async () => {
            const jobFitDefinitions = await KpiApi.getJobFitDefinitions();

            runInAction(() => {
                this.jobFitDefinitions = jobFitDefinitions.map((j) => new JobFit(j));
            });
        });
    };

    @action
    loadCultureFitDefinitions = async () => {
        await this.tryCatch(async () => {
            const cultureFitDefinitions = await KpiApi.getCultureFitDefinitions();

            runInAction(() => {
                this.cultureFitDefinitions = cultureFitDefinitions.map((j) => new GenericFit(j));
            });
        });
    };

    @action
    loadPotentialDefinitions = async () => {
        await this.tryCatch(async () => {
            const potentialsDefinitions = await KpiApi.getPotentialDefinitions();

            runInAction(() => {
                this.potentialDefinitions = potentialsDefinitions.map((j) => new GenericFit(j));
            });
        });
    };

    @action
    loadJobFitDomains = async () => {
        await this.tryCatch(async () => {
            const jobFitDomains = await KpiApi.getJobFitDomains();

            runInAction(() => {
                this.jobFitDomains = jobFitDomains;
            });
        });
    };
}
