import "./index.less";
import { AtInput } from "../../../../AtInput";
import { AtRangeSlider } from "../RangeSlider";
import { AtTitle } from "../../../../../atoms/AtTitle";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { NUMBER_FILTER_VALUES } from "./const";
import React, { ReactElement, useState } from "react";

interface IAtTableFilterNumberProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    appliedFilterValue: number | null;
    setFilterValue: (columnKey: string, value: number) => void;
}

export function AtTableNumberFilter<T extends IBaseEntity>(props: IAtTableFilterNumberProps<T>): ReactElement {
    const { appliedFilterValue, setFilterValue, column, ...otherProps } = props;

    const min = column.filter?.min ?? NUMBER_FILTER_VALUES.min;
    const max = column.filter?.max ?? NUMBER_FILTER_VALUES.max;
    const step = column.filter?.step ?? NUMBER_FILTER_VALUES.step;

    const [rangeValues, setRangeValues] = useState(appliedFilterValue ? [appliedFilterValue] : [0]);
    const [inputValue, setInputValue] = useState(appliedFilterValue ? appliedFilterValue : 0);

    const handleRangeValueChange = (values: number[]) => {
        setRangeValues(values);
        setInputValue(values[0]);
        setFilterValue(column.key, values[0]);
    };

    const handleValueChange = (e) => {
        const val: number = +e.target.value;
        const filterValues = [val];

        setInputValue(val);
        setRangeValues(filterValues);
        setFilterValue(column.key, filterValues[0]);
    };

    return (
        <div className="AtTableNumberFilter" {...otherProps}>
            <AtRangeSlider
                min={min}
                max={max}
                step={step}
                rangeValues={rangeValues}
                multipleThumbs={false}
                handleRangeValueChange={handleRangeValueChange}
            />

            <div className="layout">
                <div className="numberInput">
                    <AtTitle className="inputTitle" title={"Value"} />
                    <AtInput
                        className="input"
                        fieldName={`field_${column.key}`}
                        type="number"
                        onChange={handleValueChange}
                        value={inputValue.toString()}
                    />
                </div>
            </div>
        </div>
    );
}
