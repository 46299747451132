import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { ICompDevCompetency } from "../models";
import { IDepartment } from "../models/Department";
import { ReactAppSettings } from "../libs";

export class PlatformContentApi extends BaseApi {
    static async getCompetencies(): Promise<ICompDevCompetency[]> {
        return this.sendApiRequestWithResult<undefined, ICompDevCompetency[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompetencies,
        );
    }

    static async getDepartments(): Promise<IDepartment[]> {
        return this.sendApiRequestWithResult<undefined, IDepartment[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getDepartments,
        );
    }
}
