import { AtTableFilterTypes } from "../../types";
import { DateRange } from "react-day-picker";
import { IBaseEntity } from "@atman/core";
import { MatchType, MatchTypeOptionsEnum } from "./AtTableFreeTextFilter";

export type AvailableFilterTypes = string | string[] | number | number[] | boolean | DateRange | undefined | null;

export function filtersApplication<T extends IBaseEntity>(
    fieldName: keyof T,
    value: AvailableFilterTypes,
    item: T,
    type?: AtTableFilterTypes,
    matchType?: MatchType,
): boolean {
    if (!type) {
        return false;
    }

    const itemValue = item[fieldName];

    switch (type) {
        case "boolean":
            if (value === null) {
                return itemValue === null || itemValue === undefined;
            }
            if (typeof value === "boolean") {
                return itemValue === value;
            }
            // Case of value is anyValue
            return itemValue !== null || itemValue !== undefined;

        case "switch":
            if (value === null) {
                return itemValue === null || itemValue === undefined;
            }
            if (typeof value === "boolean") {
                return itemValue === value;
            }

            // Case of value is anyValue
            return itemValue !== null || itemValue !== undefined;

        case "number":
            return itemValue === value;

        case "freeText":
            const regexContains = new RegExp(`.*${value}.*`, "i");

            if (matchType === MatchTypeOptionsEnum.DoesNotContains) {
                return !regexContains.test(itemValue);
            }

            return regexContains.test(itemValue);

        case "multipleSelect":
            return (value as string[]).includes(itemValue.toString());

        case "numberRange":
            const min = (value as number[])[0];
            const max = (value as number[])[1];

            if (typeof itemValue === "object") {
                return itemValue.length >= min && itemValue.length <= max;
            }

            if (Array.isArray(itemValue)) {
                return itemValue.length >= min && itemValue.length <= max;
            }

            return itemValue >= min && itemValue <= max;

        default:
            return true;
    }
}
