import { AssessmentType, IAssessmentInfo, IImportCandidateParams, IInviteCandidateParams, ItemType } from "../types";
import { BaseCrudStore } from "../base";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { Candidate, ICandidateInput, Tag, Workspace } from "../models";
import { CandidatesApi, ClientsApi, TagsApi } from "../apis";
import { History } from "history";
import { JobStore } from "./JobStore";
import { TeamStore } from "./TeamStore";
import { ToastProvider, UrlFormatter } from "../libs";
import { action, autorun, computed, observable, runInAction, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import moment from "moment";

export class CandidateStore extends BaseStore {
    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
    }

    @observable public candidates: Candidate[] = [];
    @observable public candidatesLastUpdatedFromStoreAt: number | null = null;

    getCandidateById: (id: string) => Candidate | undefined = computedFn((id: string) => {
        return this.candidates.find((x) => x.id === id);
    });

    @action
    updatedCandidatesLastUpdatedFromStoreAt() {
        this.candidatesLastUpdatedFromStoreAt = Date.now();
    }

    @action
    loadSpecificCandidates = async (id: string) => {
        await this.tryCatch(async () => {
            const candidate = await CandidatesApi.getCandidate(id);

            runInAction(() => {
                if (candidate) {
                    const candidateIndex = this.candidates.findIndex((x) => x.id === candidate.id);

                    if (candidateIndex >= 0) {
                        this.candidates[candidateIndex] = candidate;
                    } else {
                        this.candidates.push(candidate);
                    }
                }
            });
        });
    };

    @action
    validateCandidatesEmail = async (emails: string[]) => {
        this.isLoading = true;
        this.resetApiErrors();

        return await this.tryCatch(
            async () => {
                await CandidatesApi.validateInviteCandidateEmails(emails);

                return true;
            },
            (error) => false,
        );
    };

    @action
    createCandidates = async (params: IInviteCandidateParams) => {
        await this.tryCatch(
            async () => {
                const result = await CandidatesApi.sendAssessmentInvitations(params);

                runInAction(() => {
                    this.candidates.push(...result);
                    this.updatedCandidatesLastUpdatedFromStoreAt();
                });

                for (const productCode of params.selectedProducts) {
                    this.rootStore.productBalanceStore.debitProducts(productCode, params.candidates.length);
                }

                ToastProvider.success("clientApp.invitationsHaveBeenSent".localize());
            },
            undefined,
            true,
        );
    };

    @action
    createCandidatesFromImport = async (params: IImportCandidateParams) => {
        await this.tryCatch(
            async () => {
                const result = await CandidatesApi.confirmImport(params);

                runInAction(() => {
                    this.candidates.push(...result);
                });

                for (const productCode of params.selectedProducts) {
                    this.rootStore.productBalanceStore.debitProducts(productCode, params.rows.length);
                }

                ToastProvider.success("clientApp.candidatesImportSuccessMessage".localize());

                this.updatedCandidatesLastUpdatedFromStoreAt();
            },
            undefined,
            true,
        );
    };

    @action
    uploadCandidatesImportFile = async (file: File) => {
        return await this.tryCatch(async () => {
            return await CandidatesApi.uploadTemplateFile(file);
        });
    };

    @action
    updateCandidateInfo = async (id: string, input: ICandidateInput) => {
        await this.tryCatch(
            async () => {
                await CandidatesApi.updateCandidateInfo(id, input);

                runInAction(() => {
                    this.saveCandidate(
                        (x) => x.id === id,
                        (index, candidates) => {
                            candidates[index] = {
                                ...candidates[index],
                                firstName: input.firstName,
                                lastName: input.lastName,
                                displayName: `${input.firstName} ${input.lastName}`,
                                email: input.email,
                                communicationEmail: input.communicationEmail,
                                ssoNameId: input.ssoNameId,
                                communicationLanguageCode: input.communicationLanguageCode,
                            };

                            if (input.tagIds) {
                                candidates[index].tags = input.tagIds
                                    .map((x) =>
                                        ((this.rootStore as any).tagStore as BaseCrudStore<any, any, any>).findItemById(
                                            x,
                                        ),
                                    )
                                    .filter((x) => !!x) as Tag[];
                            }

                            if (input.workspaceIds) {
                                candidates[index].tags = input.workspaceIds
                                    .map((x) => this.rootStore.workspaceStore.findItemById(x))
                                    .filter((x) => !!x) as Workspace[];
                            }
                        },
                    );
                });

                ToastProvider.success("clientApp.updateProfileSuccess".localize());
            },
            undefined,
            true,
        );
    };

    @action
    updateCandidateNotificationUserIds = async (id: string, selectedNotificationUserIds: string[]) => {
        await this.tryCatch(async () => {
            await CandidatesApi.updateCandidateNotificationUserIds(id, selectedNotificationUserIds);

            runInAction(() => {
                this.saveCandidate(
                    (x) => x.id === id,
                    (index, candidates) => {
                        candidates[index].notificationUserIds = selectedNotificationUserIds;
                    },
                );
            });

            ToastProvider.success("clientApp.updateNotificationUserIdsSuccess".localize());
        });
    };

    @action
    updateProfilePicture = async (candidateId: string, fileBlob: Blob) => {
        return await this.tryCatch(
            async () => {
                const result = await CandidatesApi.updateProfilePicture(candidateId, fileBlob);

                runInAction(() => {
                    this.saveCandidate(
                        (x) => x.id === candidateId,
                        (index, candidates) => {
                            candidates[index] = {
                                ...candidates[index],
                                profilePictureUri: result.uri,
                            };
                        },
                    );
                });

                return "";
            },
            undefined,
            true,
        );
    };

    @action
    removeProfilePicture = async (candidateId: string) => {
        await this.tryCatch(
            async () => {
                await CandidatesApi.removeProfilePicture(candidateId);

                runInAction(() => {
                    this.saveCandidate(
                        (x) => x.id === candidateId,
                        (index, candidates) => {
                            candidates[index] = {
                                ...candidates[index],
                                profilePictureUri: undefined,
                            };
                        },
                    );
                });
            },
            undefined,
            true,
        );
    };

    @action
    addAssessments = async (testAtmanProId: string, candidateProId: string, assessmentTypes: AssessmentType[]) => {
        await this.tryCatch(
            async () => {
                await CandidatesApi.addAssessments(candidateProId, testAtmanProId, assessmentTypes);

                runInAction(() => {
                    this.saveCandidate(
                        (x) => x.id === candidateProId,
                        (index, candidates) => {
                            candidates[index] = {
                                ...candidates[index],
                                requestedAssessmentTypes: assessmentTypes,
                                assessmentInfos: [
                                    ...candidates[index].assessmentInfos,
                                    ...assessmentTypes.map(
                                        (a) =>
                                            ({
                                                assessmentType: a,
                                                sent: true,
                                            } as IAssessmentInfo),
                                    ),
                                ],
                            };
                        },
                    );
                });

                ToastProvider.success("clientApp.addAssessmentsSuccess".localize());
            },
            undefined,
            true,
        );
    };

    @action
    resendInvitationEmailToCandidate = async (assessmentId: string) => {
        await this.tryCatch(async () => {
            await CandidatesApi.resendInvitationEmailToCandidate(assessmentId);

            runInAction(() => {
                this.saveCandidate(
                    (x) => x.assessmentId === assessmentId,
                    (index, candidates) => {
                        candidates[index] = {
                            ...candidates[index],
                            assessmentNotificationSent: true,
                            assessmentNotificationSentDate: moment().valueOf(),
                        };
                    },
                );

                this.isLoading = false;
            });

            ToastProvider.success("clientApp.invitationHasBeenResent".localize());
        });
    };

    @action
    sendBatchEmail = async (candidateIds: string[], communicationLanguage?: string) => {
        await this.tryCatch(
            async () => {
                await CandidatesApi.sendBatchEmail(candidateIds, communicationLanguage);

                runInAction(() => {
                    candidateIds.forEach((candidateId) => {
                        this.saveCandidate(
                            (x) => x.id === candidateId,
                            (index, candidates) => {
                                candidates[index] = {
                                    ...candidates[index],
                                    assessmentNotificationSent: true,
                                    assessmentNotificationSentDate: moment().valueOf(),
                                    assessmentNotificationSentDates: [
                                        ...candidates[index].assessmentNotificationSentDates,
                                        moment().valueOf(),
                                    ],
                                };
                            },
                        );
                    });

                    this.isLoading = false;
                });

                ToastProvider.success("clientApp.sendBatchEmailSuccessMessage".localize());
            },
            undefined,
            true,
        );
    };

    @action
    applyTagsToCandidates = async (tagIds: string[], candidateIds: string[], tagType: "tag" | "workspace" = "tag") => {
        await this.tryCatch(
            async () => {
                await TagsApi.applyTagsToCandidates(tagIds, candidateIds);

                runInAction(() => {
                    candidateIds.forEach((candidateId) => {
                        this.saveCandidate(
                            (x) => x.id === candidateId,
                            (index, candidates) => {
                                let candidateTags: Array<Tag | Workspace>;
                                let tagsToApply: Array<Tag | Workspace>;

                                switch (tagType) {
                                    case "tag":
                                        candidateTags = candidates[index].tags ?? [];
                                        tagsToApply = (this.rootStore as any).tagStore.items.filter(
                                            (x) => tagIds.indexOf(x.id) >= 0,
                                        );
                                        break;
                                    case "workspace":
                                        candidateTags = candidates[index].workspaces ?? [];
                                        tagsToApply = this.rootStore.workspaceStore.items.filter(
                                            (x) => tagIds.indexOf(x.id) >= 0,
                                        );
                                        break;
                                }

                                const updatedTags = [...toJS(candidateTags), ...toJS(tagsToApply)];

                                let updatedTagProps: Partial<Candidate> = {};

                                switch (tagType) {
                                    case "tag":
                                        updatedTagProps = {
                                            tags: updatedTags.filter(
                                                (value, i, array) => array.findIndex((x) => x.id === value.id) === i,
                                            ),
                                        };
                                        break;
                                    case "workspace":
                                        updatedTagProps = {
                                            workspaces: updatedTags.filter(
                                                (value, i, array) => array.findIndex((x) => x.id === value.id) === i,
                                            ),
                                        };
                                        break;
                                }

                                candidates[index] = {
                                    ...candidates[index],
                                    ...updatedTagProps,
                                };
                            },
                        );
                    });

                    switch (tagType) {
                        case "tag":
                            for (const tagId of tagIds) {
                                (this.rootStore as any).tagStore.updateTagProfilesCount(tagId, candidateIds.length);
                            }
                            break;
                        case "workspace":
                            for (const tagId of tagIds) {
                                this.rootStore.workspaceStore.updateWorkspaceProfilesCount(tagId, candidateIds.length);
                            }
                            break;
                    }
                });

                ToastProvider.success("clientApp.tagAppliedSuccessMessage".localize());
            },
            undefined,
            true,
        );
    };

    @action
    removeTagsFromCandidates = async (tagIds: string[], candidateIds: string[]) => {
        await this.tryCatch(
            async () => {
                await TagsApi.removeTagsFromCandidates(tagIds, candidateIds);

                runInAction(() => {
                    candidateIds.forEach((candidateId) => {
                        this.saveCandidate(
                            (x) => x.id === candidateId,
                            (index, candidates) => {
                                candidates[index] = {
                                    ...candidates[index],
                                    tags: candidates[index].tags.filter((x) => tagIds.indexOf(x.id) === -1),
                                    workspaces: candidates[index].workspaces.filter((x) => tagIds.indexOf(x.id) === -1),
                                };
                            },
                        );
                    });

                    for (const tagId of tagIds) {
                        (this.rootStore as any).tagStore.updateTagProfilesCount(tagId, -candidateIds.length);
                    }
                });

                ToastProvider.success("clientApp.tagRemovedSuccessMessage".localize());
            },
            undefined,
            true,
        );
    };

    @action
    transferCandidateInternal = async (
        id: string,
        newClientId: string,
        history: History,
    ): Promise<void | undefined | string> => {
        return await this.tryCatch(
            async () => {
                await ClientsApi.transferCandidate(this.rootStore.userInfoStore.clientId, id, newClientId);

                history.push(UrlFormatter.formatReactPath("/People"));
                ToastProvider.success("partnerApp.candidateTransferredSuccessfully".localize());

                runInAction(() => {
                    this.saveCandidate(
                        (x) => x.id === id,
                        (index, candidates) => {
                            candidates.splice(index, 1);
                        },
                    );
                });
            },
            (error) => {
                if (Array.isArray(error)) {
                    if (error.any()) {
                        return error.firstOrDefault()!.message;
                    }

                    return;
                } else {
                    return error.message;
                }
            },
        );
    };

    @action
    deleteCandidate = async (id: string) => {
        await this.tryCatch(async () => {
            await CandidatesApi.deleteCandidate(id);

            runInAction(() => {
                this.saveCandidate(
                    (x) => x.id === id,
                    (index, candidates) => {
                        candidates.splice(index, 1);
                    },
                );
            });

            ToastProvider.success("clientApp.candidateDeletedSuccessMessage".localize());
        });
    };

    @action
    assignJobToCandidate = async (id: string, jobId: string) => {
        await this.tryCatch(async () => {
            await CandidatesApi.assignJob(id, jobId);

            runInAction(() => {
                // MARK: Job CandidatesCount Update
                const jobStore = (this.rootStore as any)?.jobStore as JobStore;
                const jobIndex = jobStore?.findItemIndexById(jobId);
                const jobName = jobStore?.items?.[jobIndex]?.name ?? "clientApp.unknownJobName".localize();

                if (jobIndex >= 0) {
                    jobStore.items[jobIndex].candidatesCount++;
                }

                // MARK: Candidate JobId/JobName Update
                this.saveCandidate(
                    (x) => x.id === id,
                    (i, candidates) => {
                        candidates[i].jobId = jobId;
                        candidates[i].jobName = jobName;
                    },
                );

                // MARK: Team Members JobId/JobName Update
                const teamStore = (this.rootStore as any).teamStore as TeamStore;
                teamStore.teams.forEach((value, index) => {
                    const memberIndex = value.members?.findIndex((x) => x.candidateProId === id) ?? -1;

                    if (memberIndex >= 0) {
                        teamStore.teams[index].members![memberIndex].jobId = jobId;
                        teamStore.teams[index].members![memberIndex].jobName = jobName;
                    }
                });

                ToastProvider.success("clientApp.jobAssignedToCandidateSuccessfully".localize());
            });
        });
    };

    @action
    unassignJobFromCandidate = async (id: string, jobId: string) => {
        await this.tryCatch(async () => {
            await CandidatesApi.unassignJob(id, jobId);

            runInAction(() => {
                // MARK: Job CandidatesCount Update
                const jobStore = (this.rootStore as any)?.jobStore as JobStore;
                const jobIndex = jobStore?.findItemIndexById(jobId);

                if (jobIndex >= 0) {
                    jobStore.items[jobIndex].candidatesCount--;
                }

                // MARK: Candidate JobId/JobName Update
                this.saveCandidate(
                    (x) => x.id === id,
                    (i, candidates) => {
                        candidates[i].jobId = undefined;
                        candidates[i].jobName = undefined;
                    },
                );

                // MARK: Team Members JobId/JobName Update
                const teamStore = (this.rootStore as any).teamStore as TeamStore;
                teamStore.teams.forEach((value, index) => {
                    const memberIndex = value.members?.findIndex((x) => x.candidateProId === id) ?? -1;

                    if (memberIndex >= 0) {
                        teamStore.teams[index].members![memberIndex].jobId = undefined;
                        teamStore.teams[index].members![memberIndex].jobName = undefined;
                    }
                });

                ToastProvider.success("clientApp.jobUnassignedFromCandidateSuccessfully".localize());
            });
        });
    };

    @action
    updateCandidateJobs = (candidateIds: string[], jobId: string, jobName: string) => {
        for (const candidate of this.candidates.filter((x) => x.jobId === jobId && !candidateIds.includes(x.id))) {
            candidate.jobId = undefined;
            candidate.jobName = undefined;
        }

        this.saveCandidate(
            (x) => candidateIds.includes(x.id),
            (i, candidates) => {
                candidates[i].jobId = jobId;
                candidates[i].jobName = jobName;
            },
        );
    };

    @action
    updateCandidatesTeam = (candidateIds: string[], teamId: string, teamName: string) => {
        for (const candidate of this.candidates.filter(
            (x) => x.teams?.some((t) => t.id === teamId) && !candidateIds.includes(x.id),
        )) {
            candidate.teams = candidate.teams.filter((x) => x.id === teamId);
        }

        this.saveCandidate(
            (x) => candidateIds.includes(x.id),
            (i, candidates) => {
                candidates[i].teams.push({ id: teamId, label: teamName, itemType: ItemType.Team });
            },
        );
    };

    @action
    private saveCandidate = (
        findPredicate: (x: Candidate) => boolean,
        updateCallback: (index: number, candidates: Candidate[]) => void,
    ) => {
        const index = this.candidates.findIndex(findPredicate);

        if (index >= 0) {
            updateCallback(index, this.candidates);
        }
    };

    @computed
    get validationErrors() {
        return this.apiErrors.filter((x) => x.code === "ValidationError");
    }

    @computed
    get hasErrored() {
        return this.apiErrors.length > 0;
    }

    autorunner = autorun(() => {});
}
