import "./index.less";
import * as React from "react";
import {
    AppScope,
    CompDevCompetency,
    CompetencyProfile,
    GlobalStores,
    IJobInput,
    ItemType,
    Job,
    JobStore,
    JobsApi,
    ModalTypes,
    SeniorityLevel,
    UrlFormatter,
} from "@atman/business";
import { BLANK_VALUE, CompetencySetTemplateStep } from "../shared/Steps/CompetencySetTemplateStep";
import { BaseCompetencySetWizard } from "../shared/BaseCompetencySetWizard";
import { CompetencySetInformationStep } from "../shared/Steps/CompetencySetInformationStep";
import { CompetencySetSelectionStep } from "../shared/Steps/CompetencySetSelectionStep";
import { FieldError, LocalizedTextValidationError, ValidationError } from "@atman/core";
import { IWizardStep } from "../../../components/AtWizard";
import { SelectedCompetenciesSectionFragment } from "../shared/SidePanelContentSections/SelectedCompetenciesSectionFragment";
import { TextSectionFragment } from "../shared/SidePanelContentSections/TextSectionFragment";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { withRoutedAppContext } from "../../../contexts";
import autobind from "autobind-decorator";

export interface IJobWizardProps {
    jobStore?: JobStore;
}

@inject(GlobalStores.jobStore, GlobalStores.contentStore, GlobalStores.localizationStore, GlobalStores.modalStore)
@observer
class JobWizardImpl extends BaseCompetencySetWizard<IJobWizardProps, Job> {
    @observable private seniorityLevel: SeniorityLevel | null = null;
    @observable private profile: CompetencyProfile | null = null;

    protected creationLabelKey: string = "global.jobCreation";
    protected wizardDomId: string = "JobWizard";
    protected setListPath: string = "/Settings/Jobs";
    protected itemType: ItemType = ItemType.Job;

    @computed get steps(): IWizardStep[] {
        const steps: IWizardStep[] = [];

        steps.push({
            title: "global.information".localize(),
            validateStep: this.validateForm_InformationStep,
            component: (
                <CompetencySetInformationStep
                    competencySetType="job"
                    localizedNames={this.localizedNames}
                    localizedDescriptions={this.localizedDescriptions}
                    onLocalizedNameChange={this.onLocalizedNameChange}
                    onLocalizedDescriptionChange={this.onLocalizedDescriptionChange}
                    onFormSubStepSubmit={this.onFormSubStepSubmit}
                    onAutoCompleteChange={this.onAutoCompleteChange}
                    errors={this.errors}
                    departmentId={this.departmentId}
                    profile={this.profile}
                    seniorityLevel={this.seniorityLevel}
                />
            ),
        });

        if (this.shouldIncludeTemplateStep) {
            steps.push({
                title: "global.template".localize(),
                validateStep: this.validateForm_TemplateStep,
                additionalOnSubmit: this.onFormSubmit_TemplateStep,
                component: (
                    <CompetencySetTemplateStep
                        competencySetType="job"
                        stepTitle={t({ id: "global.competencySetWizard.competencySelection.title" })}
                        stepDescription={t({
                            id: "global.jobWizard.competencySelection.description",
                        })}
                        createdFromId={this.createdFromId}
                        onTemplateChange={this.onTemplateChange}
                        templates={this.templates}
                    />
                ),
            });
        }

        steps.push({
            title: "global.competencies".localize(),
            validateStep: this.validateForm_CompetencySelectionStep,
            component: (
                <CompetencySetSelectionStep
                    competencySetType="job"
                    templates={this.templates}
                    createdFromId={this.createdFromId}
                    selectedCompetencyIds={this.selectedCompetencyIds}
                    onCompetencyIdSelection={this.onCompetencyIdSelection}
                    matchedSetId={this.matchedSetId}
                />
            ),
        });

        return steps;
    }

    protected findSetById(id: string): Job | undefined {
        const { jobStore } = this.props;

        return jobStore!.findItemById(id);
    }

    @action
    protected loadFromSet(
        set: Job,
        options: { isSystemSet?: boolean; isDuplicate?: boolean } = {
            isSystemSet: false,
            isDuplicate: false,
        },
    ): void {
        const { contentStore } = this.props;

        if (!set) {
            return;
        }

        this.selectedCompetencyIds = [...set.competencyIds];

        if (options.isSystemSet) {
            return;
        }

        this.localizedDescriptions = new Map<string, string>([...set.localizedDescriptions]);
        this.seniorityLevel = set.seniorityLevel;

        if (set.departmentId) {
            this.departmentId = {
                value: set.departmentId,
                label: contentStore!.getDepartmentById(set.departmentId)?.name ?? set.departmentId.localize(),
            };
        }

        if (set.profile !== undefined && set.profile !== null) {
            this.profile = set.profile;
        }

        if (options.isDuplicate) {
            for (const [k, v] of set.localizedNames) {
                this.localizedNames.set(k, `${"global.copyOf".localize()} ${v}`);
            }
        } else {
            this.localizedNames = set.localizedNames;
        }

        this.createdFromId = set.createdFromId;
    }

    @action protected async loadTemplates() {
        this.setIsLoading(true);
        const result = await JobsApi.getSystemJobs();

        runInAction(() => {
            this.templates = result.map((x) => new Job(x));
            this.setIsLoading(false);
        });
    }

    @autobind
    validateForm_InformationStep(): boolean {
        const { localizationStore, scope } = this.props;

        const jobNameEmptyValidationResult = localizationStore!.validateLocalizedTextMapEntries(this.localizedNames);
        const jobNameLengthValidationResult = localizationStore!.validateLocalizedTextMapEntries(
            this.localizedNames,
            (x) => x.length <= 80,
        );
        const seniorityLevelIsValid = scope === AppScope.Supplier || this.seniorityLevel !== undefined; // SeniorityLevel should only be required for client jobs created from Client App
        const departmentIsValid = scope === AppScope.Supplier || this.departmentId?.value !== undefined; // Department should only be required for client jobs created from Client App
        const profileIsValid = scope === AppScope.Client || this.profile !== undefined; // Profile should only be required for system jobs created from Supplier App

        this.clearErrors();

        if (!jobNameEmptyValidationResult.result) {
            jobNameEmptyValidationResult.invalidLanguages.forEach((x) => {
                this.errors.push(new FieldError(`localizedName-${x}`, t({ id: "global.requiredFieldErrorNotice" })));
            });
        } else if (!jobNameLengthValidationResult.result) {
            this.errors.push(
                new LocalizedTextValidationError(
                    "localizedNames",
                    jobNameLengthValidationResult.invalidLanguages,
                    (invalidLanguages) =>
                        `${"global.providedNameInTheFollowingLanguagesIsTooLong".localize()}: ${invalidLanguages}`,
                ),
            );
        }

        if (!seniorityLevelIsValid) {
            this.errors.push(new ValidationError("seniorityLevel", "global.requiredFieldErrorNotice".localize()));
        }

        if (!departmentIsValid) {
            this.errors.push(new ValidationError("departmentId", "global.requiredFieldErrorNotice".localize()));
        }

        if (!profileIsValid) {
            this.errors.push(new ValidationError("profile", "global.requiredFieldErrorNotice".localize()));
        }

        return jobNameEmptyValidationResult.result && seniorityLevelIsValid && departmentIsValid && profileIsValid;
    }

    onSubmit = async () => {
        const { scope, jobStore, modalStore, history } = this.props;

        const input: IJobInput = {
            localizedNames: [...this.localizedNames],
            localizedDescriptions: [...this.localizedDescriptions],
            createdFromId: this.createdFromId !== BLANK_VALUE ? this.createdFromId : undefined,
            departmentId: this.departmentId?.value,
            seniorityLevel: this.seniorityLevel!,
            competencyIds: this.selectedCompetencyIds,
            profile: this.profile ?? undefined,
        };

        let createdId: string | undefined;

        if (this.matchedSetId) {
            await jobStore!.updateItem(this.matchedSetId, input);
        } else {
            createdId = await jobStore!.createItem(input);
        }

        if (!jobStore!.hasErrored) {
            if (scope === AppScope.Client && !this.matchedSetId) {
                modalStore!.openModal(ModalTypes.AssignJobToEmployees, {
                    competencySetId: createdId,
                });
            }

            history.push(UrlFormatter.formatReactPath(this.setListPath));
        }
    };

    protected get sidePanelContentSections(): { section: JSX.Element; flexible?: boolean }[] {
        const { contentStore } = this.props;

        const sections: { section: JSX.Element; flexible?: boolean }[] = [];

        sections.push({
            section: (
                <TextSectionFragment title={t({ id: "global.department" })} text={this.departmentId?.label ?? "-"} />
            ),
        });

        sections.push({
            section: (
                <TextSectionFragment
                    title={t({ id: "global.seniorityLevel" })}
                    text={
                        this.seniorityLevel
                            ? `global.seniorityLevels.${SeniorityLevel[this.seniorityLevel].toCamel()}`.localize()
                            : "-"
                    }
                />
            ),
        });

        const selectedCompetencies: CompDevCompetency[] | undefined = contentStore?.competencies.filter((x) =>
            this.selectedCompetencyIds.includes(x.id),
        );

        sections.push({
            section: (
                <SelectedCompetenciesSectionFragment
                    selectedCompetencies={selectedCompetencies}
                    onCompetencyIdSelection={this.onCompetencyIdSelection}
                />
            ),
            flexible: true,
        });

        return sections;
    }
}

export const JobWizard = withRoutedAppContext(JobWizardImpl);
