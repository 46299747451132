import {
    AppScope,
    ITransactionProductModel,
    ProductCodeProEnum,
    Transaction,
    TransactionType,
    UnhandledScopeError,
} from "@atman/business";
import {
    AtTableColumn,
    AtTableDateFilterOptions,
    Colors,
    IReactSelectOptionObject,
    TableItemBadges,
    TableItemLabel,
    TableItemTitle,
} from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { YouBadge } from "../..";
import { t } from "@lingui/macro";
import React from "react";

const getTransactionListColumns = (
    isClientEnvironment: boolean,
    scope: AppScope,
    transactionTypeOptions: IReactSelectOptionObject[],
    otherPartyOwnerNameOptions: IReactSelectOptionObject[],
    dateOptions: AtTableDateFilterOptions,
    placeholder: string,
): AtTableColumn<Transaction>[] => {
    let otherPartyOwnerText: string = "";
    if (!isClientEnvironment) {
        switch (scope) {
            case AppScope.Partner:
                otherPartyOwnerText = t({ id: "partnerApp.clientName" });
                break;
            case AppScope.Supplier:
                otherPartyOwnerText = t({ id: "supplierApp.partnerName" });
                break;
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    const ownerNameColumn: AtTableColumn<Transaction> = {
        key: "otherPartyOwnerName",
        fieldName: "otherPartyOwnerName",
        align: "left",
        label: otherPartyOwnerText,
        displayOverSize: "md",
        width: 180,
        filter: {
            type: "dropdown",
            options: otherPartyOwnerNameOptions,
            selectPlaceholder: t({ id: placeholder }),
        },
        sort: true,
        render: (otherPartyOwnerName: string) => {
            if (!otherPartyOwnerName) {
                return <YouBadge />;
            }

            return <TableItemLabel maxWidth={180} content={otherPartyOwnerName} />;
        },
    };

    return [
        {
            key: "transactionType",
            fieldName: "transactionType",
            align: "left",
            label: t({ id: "global.type" }),
            filter: {
                type: "dropdown",
                options: transactionTypeOptions,
                selectPlaceholder: t({ id: "global.transactionTypeFilterPlaceholder" }),
            },
            width: 180,
            sort: true,
            render: (transactionType: TransactionType, record) => {
                if (transactionType === undefined || transactionType === null) {
                    return null;
                }

                let typeLabel: string = t({ id: `global.${TransactionType[transactionType].toCamel()}` });

                if (!record.otherPartyOwnerId && scope === AppScope.Partner) {
                    typeLabel = t({ id: "global.purchase" });
                }

                return <TableItemBadges contents={[typeLabel]} />;
            },
        },
        {
            key: "productColumn",
            fieldName: "products",
            align: "left",
            label: t({ id: "global.products" }),
            displayOverSize: "sm",
            width: 190,
            sort: (a, b) => {
                if (a.products[0].quantity > b.products[0].quantity) {
                    return 1;
                }
                if (a.products[0].quantity < b.products[0].quantity) {
                    return -1;
                }
                return 0;
            },
            render: (products: ITransactionProductModel[], record) => {
                if (!products) {
                    return null;
                }

                const productCode = products[0].productCode;

                let cellContent = `${products[0].quantity} ${t({
                    id: `global.productCodes.${ProductCodeProEnum[productCode].toCamel()}`,
                })}`;

                if (products.length > 1) {
                    cellContent += ` ${t({ id: "global.andOthers" }).replace("{0}", (products.length - 1).toString())}`;
                }

                let iconName: IconProp | null = null;
                let color: string;

                switch (record.transactionType) {
                    case TransactionType.Sale:
                        iconName = ["far", "dollar-sign"] as IconProp;
                        color = Colors.greenBase;

                        break;
                    case TransactionType.Promo:
                        iconName = ["far", "badge-check"] as IconProp;
                        color = Colors.greenBase;
                        break;
                    case TransactionType.Refund:
                        iconName = ["far", "undo"] as IconProp;
                        color = Colors.redBase;
                        break;
                    default:
                        color = Colors.greyShades.shade2;
                }

                return (
                    <TableItemTitle
                        maxWidth={190}
                        title={cellContent}
                        customLeftContent={<>{iconName ? <FontAwesomeIcon icon={iconName} color={color} /> : null}</>}
                    />
                );
            },
        },
        ...(!isClientEnvironment ? [ownerNameColumn] : []),

        {
            key: "transactionDate",
            fieldName: "transactionDate",
            align: "left",
            label: t({ id: "global.date" }),
            displayOverSize: "lg",
            width: 145,
            filter: {
                type: "date",
                dateOptions: dateOptions,
            },
            sort: true,
            render: (transactionDate: string) => (
                <TableItemLabel maxWidth={145} content={new Date(transactionDate).toLocaleDateString()} />
            ),
        },
        {
            key: "userDisplayName",
            fieldName: "userDisplayName",
            align: "left",
            label: t({ id: "global.itemTypes.user" }),
            displayOverSize: "xxl",
            width: 200,
            sort: true,
            render: (userDisplayName: string) => <TableItemLabel maxWidth={200} content={userDisplayName} />,
        },
    ];
};

export default getTransactionListColumns;
