import "./index.less";
import * as React from "react";
import { AtLinkBase, HorizontalPositions, IAtLinkBaseProps, Sizing } from "../../../internal";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IAtLinkProps extends IAtLinkBaseProps {
    isLoading?: boolean;
    icon?: IconProp;
    iconProps?: Omit<Partial<FontAwesomeIconProps>, "icon"> & { position?: HorizontalPositions };
}

/**
 * AtLink - Anchor Component
 * Category: Molecule
 *
 * @param props {IAtLinkProps}
 **/
export const AtLink: React.FC<IAtLinkProps> = (props) => {
    const { isLoading = false, disabled, className, children, icon, iconProps, ...otherProps } = props;

    const hasIcon = !!icon || isLoading;
    const iconPosition = iconProps?.position ?? "left";

    const getClassNames: () => string = () => {
        let classNames = "AtLink";

        if (className) {
            classNames += ` ${className}`;
        }

        if (hasIcon) {
            classNames += " has-icon";
        }

        return classNames;
    };

    const getContent: () => React.ReactNode = () => {
        if (!hasIcon) {
            return children;
        }

        let faIconProps: Omit<FontAwesomeIconProps, "icon"> = {};

        if (iconProps) {
            const { position, ..._faIconProps } = iconProps;

            faIconProps = _faIconProps;
        }

        const iconComponent = isLoading ? (
            <FontAwesomeIcon icon={["far", "spinner"]} {...faIconProps} spin />
        ) : (
            <FontAwesomeIcon icon={icon!} {...faIconProps} />
        );

        const iconContainerClassNames = "icon-container";

        return (
            <>
                {iconPosition === "left" && (
                    <span className={iconContainerClassNames} style={{ marginRight: Sizing.pixels.xs }}>
                        {iconComponent}
                    </span>
                )}
                {typeof children === "string" ? <span>{children}</span> : children}
                {iconPosition === "right" && (
                    <span className={iconContainerClassNames} style={{ marginLeft: Sizing.pixels.xs }}>
                        {iconComponent}
                    </span>
                )}
            </>
        );
    };

    return (
        <AtLinkBase className={getClassNames()} disabled={isLoading || disabled} {...otherProps}>
            {getContent()}
        </AtLinkBase>
    );
};
