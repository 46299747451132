import "../index.less";
import "./index.less";
import { AtLink } from "@atman/design-system";
import {
    CompDevCompetencyResult,
    CompDevSubCompetencyResult,
    ISelectedObjectiveToImproveData,
    SelectedSubCompetencyToImproveData,
} from "@atman/business";
import { CompDevSubCompetenciesStrategicObjectiveResult } from "@atman/business/lib/models/CompetencyDevelopment/ResultModels/CompDevSubCompetenciesStrategicObjectiveResult";
import {
    CompDevTableDisplayType,
    CompetencyDevelopmentColumnType,
    IPersonInfo,
    TableHeaderRow,
    TableRow,
} from "../../..";
import { DevelopmentPlanImprovementCompetencySection } from "./DevelopmentPlanImprovementCompetencySection";
import { ObservableMap } from "mobx";
import { SortDirection } from "../../../../..";
import { Trans } from "@lingui/macro";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";
import React, { useEffect, useState } from "react";

export interface IImproveDevelopmentPlanCompetencyResultTableProps {
    competencies: CompDevCompetencyResult[];
    columns: CompetencyDevelopmentColumnType[];
    displayType: CompDevTableDisplayType;
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    hiddenColumns: CompetencyDevelopmentColumnType[];
    selectedElements: Map<string, Map<string, SelectedSubCompetencyToImproveData>>;
    erroredElements?: Map<string, string[]>;
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
    onSelectedElementsChange: (selectedElements: Map<string, Map<string, SelectedSubCompetencyToImproveData>>) => void;
}

export const ImproveDevelopmentPlanCompetencyResultTable: React.FC<IImproveDevelopmentPlanCompetencyResultTableProps> =
    observer((props) => {
        const {
            competencies,
            selectedElements,
            columns,
            sortElement,
            sortDirection,
            managerInfo,
            employeeInfo,
            hiddenColumns,
            erroredElements = new Map(),
            onSortChange,
        } = props;

        const maxElementsToDisplay: number = 7;

        const [showAllCompetencies, setShowAllCompetencies] = useState<boolean>(false);

        // Fill the selection state.
        useEffect(() => {
            competencies.forEach((elDef) => {
                const subElementDefs = getSubElementsFromElement(elDef);

                subElementDefs.forEach((subElDef: CompDevSubCompetencyResult) => {
                    const objectiveDefs = getObjectivesFromSubElement(subElDef);

                    objectiveDefs.forEach((objectiveDef) => {
                        if (objectiveDef.isSelected) {
                            const subElement = selectSubElement(elDef.id, subElDef.id);
                            subElement.selectedLearningResourceIds = [...subElDef.selectedLearningResourceIds];
                            subElement.learningResourceNotes = subElDef.learningResourceNotes;

                            selectStrategicObjective(
                                elDef.id,
                                subElDef.id,
                                objectiveDef.id,
                                [...objectiveDef.selectedApplicationIds],
                                objectiveDef.applicationNotes,
                            );
                        }
                    });
                });
            });
        }, []);

        const toggleShowAllCompetencies: () => void = () => {
            setShowAllCompetencies(!showAllCompetencies);
        };

        const getSelectedElement = (elementId: string) => {
            return selectedElements.get(elementId);
        };

        const cleanElementAfterDeselection = (elementId: string) => {
            const element = getSelectedElement(elementId);
            if (element && element.size === 0) {
                deselectElement(elementId);
            }
        };

        const cleanSubElementAfterDeselection = (elementId: string, subElementId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            if (
                subElement &&
                subElement.selectedObjectivesToImprove.size === 0 &&
                subElement.selectedLearningResourceIds.length === 0 &&
                subElement.learningResourceNotes.length === 0
            ) {
                deselectSubElement(elementId, subElementId);
            }
        };

        const cleanStrategicObjectiveAfterDeselection = (
            elementId: string,
            subElementId: string,
            strategicObjectiveId: string,
        ) => {
            const strategicObjective = getSelectedStrategicObjective(elementId, subElementId, strategicObjectiveId);

            if (
                strategicObjective &&
                strategicObjective.selectedApplicationIds.length === 0 &&
                strategicObjective.applicationNotes.length === 0
            ) {
                deselectStrategicObjective(elementId, subElementId, strategicObjectiveId);
            }
        };

        const deselectElement = (elementId: string) => {
            selectedElements.delete(elementId);
        };

        const deselectSubElement = (elementId: string, subElementId: string) => {
            const element = getSelectedElement(elementId);

            if (!element) {
                return;
            }

            element.delete(subElementId);
            cleanElementAfterDeselection(elementId);
            cleanSubElementAfterDeselection(elementId, subElementId);
        };

        const deselectStrategicObjective = (elementId: string, subElementId: string, strategicObjectiveId: string) => {
            const element = getSelectedElement(elementId);

            if (!element) {
                return;
            }

            const subElement = element.get(subElementId);

            if (!subElement) {
                return;
            }

            subElement.selectedObjectivesToImprove.delete(strategicObjectiveId);
            cleanElementAfterDeselection(elementId);
            cleanSubElementAfterDeselection(elementId, subElementId);
            cleanStrategicObjectiveAfterDeselection(elementId, subElementId, strategicObjectiveId);
        };

        const deselectLearningResource = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            if (subElement) {
                const learningResourceIndex = subElement.selectedLearningResourceIds.indexOf(learningResourceId);
                if (learningResourceIndex >= 0) {
                    subElement.selectedLearningResourceIds.splice(learningResourceIndex, 1);
                    cleanSubElementAfterDeselection(elementId, subElementId);
                }
            }
        };

        const selectElement = (elementId: string) => {
            let element = selectedElements.get(elementId);
            if (!element) {
                element = new ObservableMap<string, SelectedSubCompetencyToImproveData>();
                selectedElements.set(elementId, element);
                element = selectedElements.get(elementId)!;
            }

            return element;
        };

        const getSubElementsFromElement = (element?: CompDevCompetencyResult): Array<CompDevSubCompetencyResult> => {
            if (element) {
                return element.subCompetencies;
            }

            return [];
        };

        const getObjectivesFromSubElement = (
            subElement?: CompDevSubCompetencyResult,
        ): Array<CompDevSubCompetenciesStrategicObjectiveResult> => {
            if (subElement) {
                return subElement.strategicObjectives;
            }

            return [];
        };

        const getSelectedSubElement = (elementId: string, subElementId: string) => {
            const element = getSelectedElement(elementId);

            return element?.get(subElementId);
        };

        const getSelectedStrategicObjective = (
            elementId: string,
            subElementId: string,
            strategicObjectiveId: string,
        ) => {
            const subElement = getSelectedSubElement(elementId, subElementId);

            return subElement?.selectedObjectivesToImprove.get(strategicObjectiveId);
        };

        const selectSubElement = (elementId: string, subElementId: string) => {
            const element = selectElement(elementId);

            let subElement = element.get(subElementId);

            if (!subElement) {
                subElement = new SelectedSubCompetencyToImproveData({
                    selectedLearningResourceIds: [],
                    learningResourceNotes: "",
                    selectedObjectivesToImprove: {},
                });
                element.set(subElementId, subElement);
                subElement = element.get(subElementId)!;
            }

            return subElement;
        };

        const selectStrategicObjective = (
            elementId: string,
            subElementId: string,
            strategicObjectiveId: string,
            selectedApplicationIds: string[] = [],
            applicationNotes: string = "",
        ) => {
            const subElement = selectSubElement(elementId, subElementId);

            let strategicObjective = subElement.selectedObjectivesToImprove.get(strategicObjectiveId);

            if (!strategicObjective) {
                strategicObjective = {
                    selectedApplicationIds,
                    applicationNotes,
                } as ISelectedObjectiveToImproveData;
                subElement.selectedObjectivesToImprove.set(strategicObjectiveId, strategicObjective);
                strategicObjective = subElement.selectedObjectivesToImprove.get(strategicObjectiveId)!;
            } else {
                strategicObjective.selectedApplicationIds = selectedApplicationIds;
                strategicObjective.applicationNotes = applicationNotes;
            }

            return strategicObjective;
        };

        const selectLearningResource = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = selectSubElement(elementId, subElementId);

            const learningResourceIndex = subElement.selectedLearningResourceIds.indexOf(learningResourceId);
            if (learningResourceIndex === -1) {
                subElement.selectedLearningResourceIds.push(learningResourceId);
            }
        };

        const isLearningResourceSelected = (elementId: string, subElementId: string, learningResourceId: string) => {
            const subElement = getSelectedSubElement(elementId, subElementId);
            return subElement ? subElement.selectedLearningResourceIds.indexOf(learningResourceId) >= 0 : false;
        };

        const toggleLearningResourceSelection = (
            elementId: string,
            subElementId: string,
            learningResourceId: string,
        ) => {
            if (isLearningResourceSelected(elementId, subElementId, learningResourceId))
                deselectLearningResource(elementId, subElementId, learningResourceId);
            else selectLearningResource(elementId, subElementId, learningResourceId);
        };

        const setLearningResourceNote = (elementId: string, subElementId: string, value: string) => {
            if (!value) {
                const subElement = getSelectedSubElement(elementId, subElementId);

                if (subElement) {
                    subElement.learningResourceNotes = "";
                }

                cleanSubElementAfterDeselection(elementId, subElementId);
                return;
            }

            const subElement = selectSubElement(elementId, subElementId);

            subElement.learningResourceNotes = value;
        };

        const saveStrategicObjectiveSelectionData = (
            elementId: string,
            subElementId: string,
            objectiveId: string,
            selectedApplicationIds: string[],
            applicationNote: string,
        ) => {
            console.log(selectedApplicationIds);
            if (selectedApplicationIds.length > 0) {
                selectStrategicObjective(elementId, subElementId, objectiveId, selectedApplicationIds, applicationNote);
            } else {
                deselectStrategicObjective(elementId, subElementId, objectiveId);
            }
        };

        const { isMobile } = useDetectDevice();

        const filteredCompetencies: CompDevCompetencyResult[] = showAllCompetencies
            ? competencies
            : competencies.slice(0, maxElementsToDisplay);

        return (
            <div className="ResultTable DevelopmentPlanCompetencyResultTable">
                {!isMobile && (
                    <TableHeaderRow
                        sortElement={sortElement}
                        onSortChange={onSortChange}
                        sortDirection={sortDirection}
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        columns={columns}
                        hiddenColumns={hiddenColumns}
                    />
                )}
                <div className="table-rows">
                    {filteredCompetencies.map((comp) => (
                        <TableRow
                            dataRow={comp}
                            columns={columns}
                            key={comp.id}
                            inError={erroredElements.has(comp.id)}
                            isSelected={selectedElements.has(comp.id)}
                            showRank={false}
                            scoresColumnSet={"developmentPlanScores"}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                            collapseContentOffset
                            disabledSelect
                            showCheckIcon
                        >
                            <DevelopmentPlanImprovementCompetencySection
                                comp={comp}
                                selectedElement={selectedElements.get(comp.id)}
                                columns={columns}
                                managerInfo={managerInfo}
                                employeeInfo={employeeInfo}
                                hiddenColumns={hiddenColumns}
                                toggleLearningResourceSelection={toggleLearningResourceSelection}
                                saveStrategicObjectiveSelectionData={saveStrategicObjectiveSelectionData}
                                setLearningResourceNote={setLearningResourceNote}
                                erroredElements={erroredElements}
                            />
                        </TableRow>
                    ))}
                </div>
                {competencies.length > maxElementsToDisplay && (
                    <div className="show-all-competencies-container">
                        <AtLink
                            onClick={toggleShowAllCompetencies}
                            icon={["fas", showAllCompetencies ? "chevron-up" : "chevron-down"]}
                            iconProps={{ position: "right" }}
                        >
                            {showAllCompetencies ? (
                                <Trans id={"competencyDevelopment.assessments.global.showLessCompetencies"}>
                                    Show less competencies
                                </Trans>
                            ) : (
                                <Trans id={"competencyDevelopment.assessments.global.showAllCompetencies"}>
                                    Show all competencies
                                </Trans>
                            )}
                        </AtLink>
                    </div>
                )}
            </div>
        );
    });
