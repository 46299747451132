import { AppScope, AssessmentAuthMethod, IBillingInformation, IUpdateBillingAddressCommand } from "../types";
import {
    AuthenticationMethod,
    Client,
    IOrganizationBaseCustomizations,
    IOrganizationCustomization,
    IOrganizationSsoCustomizations,
    ISamlConfiguration,
    IUpdateClientCompetencyDevelopmentSettings,
    OrganizationAuthConfig,
    Partner,
    SsoStandard,
} from "../models";
import { BasePlatformRootStore, BaseStore } from "../base";
import { ClientsApi, OrganizationCustomizationApi, PartnersApi } from "../apis";
import { ReactAppSettings, ToastProvider } from "../libs";
import { SingleSignOnApi } from "../apis/SingleSignOnApi";
import { action, computed, observable, runInAction } from "mobx";

type OrgType = "client" | "partner" | "supplier";

export class ScopedOrganizationStore extends BaseStore {
    private readonly scope: AppScope;

    @observable public scopedOrganization: Client | Partner;
    @observable public scopedOrganizationAuthConfig: OrganizationAuthConfig;
    @observable public scopedOrganizationCustomization: IOrganizationCustomization;
    private readonly orgType: OrgType;
    private readonly scopedOrganizationId: string;

    @computed get isSsoEnabled(): boolean {
        return this.scopedOrganizationAuthConfig?.authenticationMethod === AuthenticationMethod.Sso;
    }

    @computed get isSsoAllowed(): boolean {
        return this.orgType === "supplier" || this.scopedOrganization?.features?.allowSso;
    }

    public constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);

        this.scope = rootStore.scope;

        switch (this.scope) {
            case AppScope.Client:
            case AppScope.Print:
            case AppScope.Manager:
            case AppScope.Assessment:
                this.orgType = "client";
                this.scopedOrganizationId = ReactAppSettings.viewBag.scope.clientId;
                break;
            case AppScope.Partner:
                this.orgType = "partner";
                this.scopedOrganizationId = ReactAppSettings.viewBag.scope.partnerProId;
                break;
            case AppScope.Supplier:
                this.orgType = "supplier";
                this.scopedOrganizationId = ReactAppSettings.viewBag.scope.supplierId;
                break;
        }
    }

    /** MULTI-SCOPE METHODS **/

    @action
    loadScopedOrganization = async () => {
        // TODO: Block request for unwanted scopes -- Maybe in a generic method for the store, something like `checkAllowedScope()`

        await this.tryCatch(async () => {
            let organization: Client | Partner;

            switch (this.orgType) {
                case "client":
                    const clientResult = await ClientsApi.getClient(this.scopedOrganizationId);
                    organization = new Client(clientResult);
                    break;
                case "partner":
                    const partnerResult = await PartnersApi.getPartner(this.scopedOrganizationId);
                    organization = new Partner(partnerResult);
                    break;
                case "supplier":
                    console.warn("Supplier Scoped Organization is not implemented");
                    return;
            }

            runInAction(() => {
                this.scopedOrganization = organization;
            });
        });
    };

    @action
    loadScopedOrganizationAuthConfig = async () => {
        // TODO: Block request for unwanted scopes -- Maybe in a generic method for the store, something like `checkAllowedScope()`

        await this.tryCatch(async () => {
            const result = await SingleSignOnApi.getOrganizationAuthConfig();

            runInAction(() => {
                this.scopedOrganizationAuthConfig = new OrganizationAuthConfig(result);
            });
        });
    };

    @action
    loadScopedOrganizationCustomizations = async () => {
        await this.tryCatch(async () => {
            const result = await OrganizationCustomizationApi.getCustomizations();

            runInAction(() => {
                this.scopedOrganizationCustomization = result;
            });
        });
    };

    @action
    updateSsoAuthMethod = async (authMethod: AuthenticationMethod) => {
        if (!this.scopedOrganizationAuthConfig) {
            console.warn("No scoped organization auth config is loaded");
            return;
        }

        await this.tryCatch(async () => {
            await SingleSignOnApi.updateSsoAuthMethod(authMethod);

            runInAction(() => {
                this.scopedOrganizationAuthConfig.authenticationMethod = authMethod;

                if (authMethod === AuthenticationMethod.Sso) {
                    this.scopedOrganizationAuthConfig.ssoStandard = SsoStandard.SAML;
                }
            });

            ToastProvider.success("global.ssoConfigurationUpdatedSuccessfully".localize());
        });
    };

    @action
    updateSamlConfiguration = async (samlConfiguration: ISamlConfiguration) => {
        if (!this.scopedOrganizationAuthConfig) {
            console.warn("No scoped organization auth config is loaded");
            return;
        }

        await this.tryCatch(async () => {
            await SingleSignOnApi.updateSamlConfiguration(samlConfiguration);

            runInAction(() => {
                this.scopedOrganizationAuthConfig.samlConfiguration = samlConfiguration;
            });

            ToastProvider.success("global.ssoConfigurationUpdatedSuccessfully".localize());
        });
    };

    @action
    updateName = async (name: string) => {
        if (!this.scopedOrganization) {
            console.warn("No scoped organization is loaded");
            return;
        }

        await this.tryCatch(async () => {
            switch (this.orgType) {
                case "client":
                    await ClientsApi.updateClientName(this.scopedOrganizationId, name);
                    break;
                case "partner":
                    await PartnersApi.updatePartnerName(this.scopedOrganizationId, name);
                    break;
                case "supplier":
                    console.warn("Supplier Scoped Organization Method is not implemented");
                    return;
            }

            runInAction(() => {
                this.scopedOrganization.name = name;
            });

            // TODO: Change resx to be generic for client/partner/supplier
            ToastProvider.success("global.clientNameChangedSuccessMessage".localize());
        });
    };

    @action
    updateBillingInformation = async (billingInformation: IBillingInformation) => {
        if (!this.scopedOrganization) {
            console.error("No scoped organization is loaded");
            return;
        }

        const country = ReactAppSettings.appModel.countries.find((x) => x.id === billingInformation.address.country);

        if (!country) {
            ToastProvider.error("Selected country is invalid");
            return;
        }

        const state = country.states.find((x) => x.id === billingInformation.address.state);

        if (country.states.any() && !state) {
            ToastProvider.error("Selected state is invalid");
            return;
        }

        const updateModel: IUpdateBillingAddressCommand = {
            address: billingInformation.address.address,
            address2: billingInformation.address.address2,
            city: billingInformation.address.city,
            zipCode: billingInformation.address.zipCode,
            stateId: billingInformation.address.state,
            countryId: billingInformation.address.country,
            phone: billingInformation.address.phone,
            extension: billingInformation.address.extension,
            contactName: billingInformation.contactName,
            contactEmail: billingInformation.email,
            legalName: billingInformation.legalName,
            language: billingInformation.language,
        };

        await this.tryCatch(async () => {
            switch (this.orgType) {
                case "client":
                    await ClientsApi.updateClientBillingInformation(this.scopedOrganizationId, updateModel);
                    break;
                case "partner":
                    await PartnersApi.updatePartnerBillingInformation(this.scopedOrganizationId, updateModel);
                    break;
                case "supplier":
                    console.warn("Supplier Scoped Organization Method is not implemented");
                    return;
            }

            runInAction(() => {
                this.scopedOrganization.billingInformation = {
                    ...billingInformation,
                    address: {
                        ...billingInformation.address,
                        country: country.code,
                        state: state ? state.code : "",
                    },
                };
            });

            // TODO: Change resx to be generic for client/partner/supplier
            ToastProvider.success("global.clientBillingInformationChangedSuccessMessage".localize());
        });
    };

    updateBaseCustomization = async (model: IOrganizationBaseCustomizations) => {
        await OrganizationCustomizationApi.updateBaseCustomization(model);

        ToastProvider.success("global.orgCustomization.updateSuccessMessage".localize());

        runInAction(() => {
            this.scopedOrganizationCustomization.baseCustomizations = model;
        });
    };

    updateSsoCustomization = async (model: IOrganizationSsoCustomizations) => {
        await OrganizationCustomizationApi.updateSsoCustomization(model);

        ToastProvider.success("global.orgCustomization.updateSuccessMessage".localize());

        runInAction(() => {
            this.scopedOrganizationCustomization.ssoCustomizations = model;
        });
    };

    deleteCustomizations = async () => {
        await OrganizationCustomizationApi.deleteCustomization();

        ToastProvider.success("global.orgCustomization.deleteSuccessMessage".localize());

        runInAction(() => {
            this.scopedOrganizationCustomization.baseCustomizations.logoUri = undefined;
            this.scopedOrganizationCustomization.baseCustomizations.description = {};
            this.scopedOrganizationCustomization.ssoCustomizations.instructions = {};
        });
    };

    /** CLIENT METHODS **/

    @action
    updateClientRequiredLanguages = async (requiredLanguages: string[]) => {
        if (!this.scopedOrganization) {
            console.error("No scoped organization is loaded");
            return;
        }

        if (this.scope !== AppScope.Client) {
            console.error("Cannot update client required languages outside of the client app");
            return;
        }

        await this.tryCatch(async () => {
            await ClientsApi.updateClientRequiredLanguages(this.scopedOrganizationId, requiredLanguages);

            this.rootStore.localizationStore.updateRequiredLanguages(requiredLanguages);

            ToastProvider.success("clientApp.clientRequiredLanguagesChangedSuccessMessage".localize());
        });
    };

    @action
    updateClientCulture = (cultureId?: string, cultureName?: string) => {
        // TODO: Replace this check with `this.scopedOrganization instanceof Client` when we have instanced classes for client/partner
        if (this.orgType !== "client") {
            console.error("Cannot assign a culture to an org that is not a client");
            return;
        }

        (this.scopedOrganization as Client).cultureId = cultureId;
        (this.scopedOrganization as Client).cultureName = cultureName;
    };

    @action
    updateClientCompetencyDevelopmentSettings = async (
        id: string,
        clientCompetencyDevelopmentSettings: IUpdateClientCompetencyDevelopmentSettings,
    ) => {
        // TODO: Replace this check with `this.scopedOrganization instanceof Client` when we have instanced classes for client/partner
        if (this.orgType !== "client") {
            console.error("Cannot assign a culture to an org that is not a client");
            return;
        }

        await this.tryCatch(async () => {
            await ClientsApi.updateClientCompetencyDevelopmentSettings(id, clientCompetencyDevelopmentSettings);

            ToastProvider.success("clientApp.clientCompetencyDevelopmentSettingsUpdatedSuccessfully".localize());

            runInAction(() => {
                (this.scopedOrganization as Client).competencyDevelopmentSettings.optionalManagerAssessment =
                    clientCompetencyDevelopmentSettings.isManagerAssessmentOptional;
                (this.scopedOrganization as Client).competencyDevelopmentSettings.optionalSelfAssessment =
                    clientCompetencyDevelopmentSettings.isSelfAssessmentOptional;
            });
        });
    };

    @action
    updateClientAssessmentAuthMethodOverride = async (assessmentAuthMethodOverride: AssessmentAuthMethod | null) => {
        if (this.orgType !== "client") {
            console.error("Cannot update an assessment auth method override on an org that is not a client");
            return;
        }

        await ClientsApi.updateClientAssessmentAuthMethodOverride(
            this.scopedOrganizationId,
            assessmentAuthMethodOverride,
        );

        ToastProvider.success("clientApp.clientAssessmentAuthMethodOverrideUpdatedSuccessfully".localize());

        runInAction(() => {
            (this.scopedOrganization as Client).evaluationSettings.assessmentAuthMethodOverride =
                assessmentAuthMethodOverride;
        });
    };

    @action
    updateClientStylesGroupOverride = async (stylesGroupId: string, styleIds: string[]) => {
        if (this.orgType !== "client") {
            console.error("Cannot update styles group override on an org that is not a client");
            return;
        }

        await ClientsApi.updateClientStylesGroupOverride(this.scopedOrganizationId, stylesGroupId, styleIds);

        ToastProvider.success("clientApp.clientStylesGroupOverrideUpdatedSuccessfully".localize());

        runInAction(() => {
            (this.scopedOrganization as Client).stylesGroupOverrides.set(stylesGroupId, styleIds);
        });
    };
}
