import {
    AppScope,
    CompetencyProfile,
    ContentStore,
    ItemType,
    Job,
    JobStore,
    ModalStore,
    ModalTypes,
    ResponsivenessHelper,
    SeniorityLevel,
} from "@atman/business";
import { AtButton, AtTableColumn, TableItemButtons, TableItemLabel, TableItemTitle } from "@atman/design-system";
import { CustomDeleteAlert } from "../../..";
import { t } from "@lingui/macro";
import DropdownItem from "reactstrap/lib/DropdownItem";
import React from "react";

const getJobsTableColumns = (
    scope: AppScope,
    modalStore: ModalStore | undefined,
    jobStore: JobStore | undefined,
    contentStore: ContentStore | undefined,
    duplicateAction: (id: string) => void,
    editAction: (id: string) => void,
    jobs: Job[],
): AtTableColumn<Job>[] => {
    const competencyColumn: AtTableColumn<Job> = {
        key: "competencyIds",
        fieldName: "competencyIds",
        align: "left",
        label: t({ id: "competencyDevelopment.global.competencies" }),
        sort: (a, b) => a.competencyIds.length - b.competencyIds.length,
        displayOverSize: "xxl",
        width: 120,
        filter: {
            type: "numberRange",
            customName: t({ id: "global.numberOfCompetencies" }),
            min: 0,
            max: Math.max(...jobs.map((job) => job.competencyIds.length), 1),
        },
        render: (competencyIds: string[]) => (
            <TableItemLabel maxWidth={120} content={competencyIds.length.toString()} />
        ),
    };

    return [
        {
            key: "name",
            fieldName: "name",
            align: "left",
            label: t({ id: "global.jobName" }),
            sort: true,
            displayOverSize: "xs",
            width: "fit-content",
            filter: {
                type: "freeText",
            },
            render: (name) => <TableItemTitle maxWidth={200} title={name} />,
        },
        {
            key: "seniorityLevel",
            fieldName: "seniorityLevel",
            align: "left",
            label: t({ id: "global.seniorityLevel" }),
            sort: true,
            displayOverSize: "xl",
            width: 180,
            filter: {
                type: "multipleSelect",
                values: [
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[0].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[0].toCamel()}` }),
                    },
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[1].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[1].toCamel()}` }),
                    },
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[2].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[2].toCamel()}` }),
                    },
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[3].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[3].toCamel()}` }),
                    },
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[4].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[4].toCamel()}` }),
                    },
                    {
                        title: t({ id: `global.seniorityLevels.${SeniorityLevel[5].toCamel()}` }),
                        value: t({ id: `global.seniorityLevels.${SeniorityLevel[5].toCamel()}` }),
                    },
                ],
            },
            onFilter: (value: string, record) => {
                return record.seniorityLevel !== null && record.seniorityLevel !== undefined
                    ? value.includes(
                          t({ id: `global.seniorityLevels.${SeniorityLevel[record.seniorityLevel].toCamel()}` }),
                      )
                    : false;
            },

            render: (seniorityLevel) => (
                <TableItemLabel
                    maxWidth={180}
                    content={t({ id: `global.seniorityLevels.${SeniorityLevel[seniorityLevel].toCamel()}` })}
                />
            ),
        },
        {
            key: "departmentId",
            fieldName: "departmentId",
            align: "left",
            label: "Domaine", // todo : translate when we can (after dev merge)
            sort: true,
            displayOverSize: "lg",
            width: 185,
            filter: {
                type: "multipleSelect",
                values: contentStore!.departments.map((department) => ({
                    title: department.name,
                    value: department.id,
                })),
            },
            render: (departmentId: string, record) => {
                const department = departmentId ? contentStore!.getDepartmentById(departmentId) : undefined;
                const content =
                    scope === AppScope.Client && department
                        ? department.name
                        : t({ id: `global.competencyProfiles.${CompetencyProfile[record.profile!].toCamel()}` });
                return <TableItemLabel maxWidth={185} content={content} />;
            },
        },
        ...(ResponsivenessHelper.isBiggerThan(1600) ? [competencyColumn] : []),
        {
            key: "candidatesCount",
            fieldName: "candidatesCount",
            align: "left",
            label: t({
                id: "global.profile",
            }),
            sort: true,
            displayOverSize: "xxl",
            width: 100,
            filter: {
                type: "numberRange",
                customName: t({ id: "global.numberOfProfiles" }),
                min: 0,
                max: Math.max(...jobs.map((job) => job.candidatesCount), 1),
            },
            render: (candidatesCount) => <TableItemLabel maxWidth={100} content={candidatesCount} />,
        },
        {
            key: "clientId",
            fieldName: "clientId",
            align: "right",
            displayOverSize: "sm",
            width: 140,
            render: (_clientId: string, record) => {
                const openAssignmentModal = () =>
                    modalStore!.openModal(ModalTypes.AssignJobToEmployees, { competencySetId: record.id });

                const archiveAction = () =>
                    new CustomDeleteAlert(ItemType.Job, record.name, () => jobStore!.deleteItem(record.id)).fire();

                return (
                    <TableItemButtons
                        secondaryButton={
                            <AtButton size={"md"} color={"secondary"} onClick={() => editAction(record.id)}>
                                {t({ id: "global.edit" })}
                            </AtButton>
                        }
                        menuContent={
                            <>
                                <DropdownItem onClick={() => duplicateAction(record.id)}>
                                    {t({ id: "global.buttons.labels.duplicate" })}
                                </DropdownItem>
                                {scope === AppScope.Client && (
                                    <DropdownItem onClick={openAssignmentModal}>
                                        {t({ id: "global.assignToEmployees" })}
                                    </DropdownItem>
                                )}
                                <DropdownItem onClick={() => archiveAction()} disabled={record.candidatesCount > 0}>
                                    {t({ id: "global.archive" })}
                                </DropdownItem>
                            </>
                        }
                    />
                );
            },
        },
    ];
};

export default getJobsTableColumns;
