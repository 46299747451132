import {
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessStatus,
    ICompetencyDevelopmentProcessAssessmentData,
} from "../../../models";
import { IMapEntries, observable } from "mobx";

export interface IEmployeeDevelopmentProcess {
    id: string;
    managerId: string;
    managerName: string;
    teamId: string;
    teamName: string;
    jobId?: string;
    jobName?: string;
    status: CompetencyDevelopmentProcessStatus;
    assessmentsData: IMapEntries<CompetencyDevelopmentAssessmentType, ICompetencyDevelopmentProcessAssessmentData>;
    canCompleteActionPlans: boolean;
    competencyCount: number;
    dateStarted: string;
    dateCompleted?: string;
    updatedAt: string;
    updatedBy: string;
}

export class EmployeeDevelopmentProcess {
    public readonly id: string;
    public readonly managerId: string;
    public readonly managerName: string;
    public readonly teamId: string;
    public readonly teamName: string;
    public readonly jobId?: string;
    public readonly jobName?: string;
    public readonly status: CompetencyDevelopmentProcessStatus;
    @observable public assessmentsData: Map<
        CompetencyDevelopmentAssessmentType,
        ICompetencyDevelopmentProcessAssessmentData
    >;
    public readonly canCompleteActionPlans: boolean;
    public readonly competencyCount: number;
    public readonly dateStarted: string;
    public readonly dateCompleted?: string;
    public readonly updatedAt: string;
    public readonly updatedBy: string;

    constructor(base: IEmployeeDevelopmentProcess) {
        this.id = base.id;
        this.managerId = base.managerId;
        this.managerName = base.managerName;
        this.teamId = base.teamId;
        this.teamName = base.teamName;
        this.jobId = base.jobId;
        this.jobName = base.jobName;
        this.status = base.status;
        this.assessmentsData = new Map<
            CompetencyDevelopmentAssessmentType,
            ICompetencyDevelopmentProcessAssessmentData
        >(base.assessmentsData);
        this.canCompleteActionPlans = base.canCompleteActionPlans;
        this.competencyCount = base.competencyCount;
        this.dateStarted = base.dateStarted;
        this.dateCompleted = base.dateCompleted;
        this.updatedAt = base.updatedAt;
        this.updatedBy = base.updatedBy;
    }
}
