import "./CreditsBalanceSelector.less";
import * as React from "react";
import { AppCartKey } from "@atman/business/lib/stores/CartStore";
import {
    AppScope,
    BaseCart,
    CartStore,
    GlobalStores,
    IAppContext,
    ProductBalanceStore,
    ProductCodeProEnum,
    UsageModel,
} from "@atman/business";
import { CreditsBalanceSelectorLineItem } from "./CreditsBalanceSelectorLineItem";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";

export interface ICreditsBalanceSelectorProps extends IAppContext {
    cartKey: AppCartKey;
    transferableProducts?: ProductCodeProEnum[];
    recipientUsageModel?: UsageModel;
    currentBalance?: Dictionary<ProductCodeProEnum, number>;
    productBalanceStore?: ProductBalanceStore;
    cartStore?: CartStore;
}

@inject(GlobalStores.cartStore, GlobalStores.productBalanceStore)
@observer
class CreditsBalanceSelectorComp extends React.Component<ICreditsBalanceSelectorProps, {}> {
    render() {
        const {
            recipientUsageModel,
            currentBalance,
            transferableProducts,
            cartStore,
            productBalanceStore,
            cartKey,
            scope,
        } = this.props;

        const cart = cartStore!.appCarts[cartKey] as BaseCart;

        let recipientBalanceHeaderLabelKey: string = "";

        switch (scope) {
            case AppScope.Client:
                recipientBalanceHeaderLabelKey = "";
                break;
            case AppScope.Partner:
                recipientBalanceHeaderLabelKey = "partnerApp.clientsBalance";
                break;
            case AppScope.Supplier:
                recipientBalanceHeaderLabelKey = "supplierApp.partnersBalance";
                break;
            default:
                throw new Error(`Unhandled scope for component: ${scope}`);
        }

        const currentBalanceValue = (x: string | number) =>
            !!currentBalance && currentBalance.hasOwnProperty(Number(x)) ? Number(currentBalance[Number(x)]) : 0;
        const newBalanceValue = (x: string | number) =>
            Number(currentBalanceValue(x)) +
            (cart.balance.hasOwnProperty(Number(x)) ? Number(cart.balance[Number(x)]) : 0);

        const lineItems = (
            transferableProducts ?? productBalanceStore!.availableProductDefinitions.map((x) => x.productCode)
        ).map((x, i) => (
            <CreditsBalanceSelectorLineItem
                productCode={Number(x)}
                value={cart.balance[x]}
                currentRecipientBalance={recipientUsageModel ? recipientUsageModel.productBalance[Number(x)] || 0 : 0}
                currentBalance={currentBalanceValue(x)}
                newBalance={newBalanceValue(x)}
                cartKey={cartKey}
                key={i}
                scope={scope}
                isUnlimited={recipientUsageModel ? recipientUsageModel.isUnlimited : false}
            />
        ));

        return (
            <div className="CreditsBalanceSelector">
                <div className="products-table">
                    <div className="table-headers">
                        <div className="product-info-header table-header">{"global.products".localize()}</div>
                        <div className="quantity-selection-header table-header">{"global.quantity".localize()}</div>
                        {cartKey === "attributeCredits" ? (
                            <div className="recipient-balance-header table-header">
                                {recipientBalanceHeaderLabelKey.localize()}
                            </div>
                        ) : null}
                        {scope !== AppScope.Supplier && !productBalanceStore!.isUnlimited ? (
                            <div className="current-balance-header table-header">
                                {cartKey === "attributeCredits"
                                    ? "partnerApp.yourBalance".localize()
                                    : "global.currentBalance".localize()}
                            </div>
                        ) : null}
                        {cartKey === "addCredits" ? (
                            <div className="new-balance-header table-header">{"global.newBalance".localize()}</div>
                        ) : null}
                    </div>
                    {lineItems}
                </div>
            </div>
        );
    }
}

const CreditsBalanceSelector = withAppContext(CreditsBalanceSelectorComp);

export { CreditsBalanceSelector };
