import { CompetencyDevelopmentProcessStatus } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useMemo } from "react";

export const useCompetencyIcon = (status: CompetencyDevelopmentProcessStatus | undefined): IconProp => {
    const icon: IconProp = useMemo(() => {
        if (status === CompetencyDevelopmentProcessStatus.Cancelled) {
            return ["fas", "times-circle"];
        }

        return ["fas", "check-circle"];
    }, [status]);

    return icon;
};
