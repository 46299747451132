import { ApiError } from "@atman/core";
import { AxiosError } from "axios";
import { Logger } from "./Logger";
import { ToastProvider } from "./ToastProvider";
import { toJS } from "mobx";

const statusCodesToIgnore = [422];

export class ErrorHandler {
    static handleError(error: AxiosError | Error, showToast: boolean = true) {
        const isNetworkError = error.hasOwnProperty("response");

        if (isNetworkError) {
            return ErrorHandler.handleApiError(error as AxiosError);
        }

        if (showToast) {
            ToastProvider.error(error.message);
        }

        Logger.log(error);

        return null;
    }

    static handleApiError(error: AxiosError) {
        const errorResponse = (error as AxiosError).response;

        if (errorResponse) {
            const apiErrors = errorResponse.data.errors as ApiError[];

            Logger.log(apiErrors);

            if (!statusCodesToIgnore.find((x) => x === errorResponse.status)) {
                // apiErrors.forEach(apiError => ToastProvider.error(apiError.message));
            }

            return apiErrors;
        }

        // ToastProvider.error('error_UnknownError'.localize());

        Logger.log(error.message);

        return [] as ApiError[];
    }

    static isApiError(response: any) {
        return (
            Array.isArray(toJS(response)) &&
            response.length > 0 &&
            "code" in response[0] &&
            "message" in response[0] &&
            "resourceIdentifier" in response[0] &&
            "fieldName" in response[0]
        );
    }
}
