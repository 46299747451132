import "./index.less";
import { DefaultComponentProps, HeadingType } from "../../../internal";
import React from "react";
import cn from "classnames";
export interface IAtTitleProps extends DefaultComponentProps {
    title: React.ReactNode | undefined;
    headingType?: HeadingType;
    noMargin?: boolean;
    htmlTitle?: string;
}

export const AtTitle: React.FC<IAtTitleProps> = (props) => {
    const { title, headingType = 5, noMargin = false, htmlTitle, className, ...otherProps } = props;

    const Heading = `h${headingType}` as keyof JSX.IntrinsicElements;

    return title ? (
        <Heading className={cn("AtTitle", className, { "mb-0": noMargin })} title={htmlTitle} {...otherProps}>
            {title}
        </Heading>
    ) : null;
};
