import { AppScope, ISupportedCulture } from "../types";
import { ReactAppSettings, StringFormatter } from "../libs";
import { action, autorun, computed, observable } from "mobx";
import moment from "moment";

export class LocalizationStore {
    public readonly availableLanguages: ISupportedCulture[] = ReactAppSettings?.appModel?.availableLanguages ?? [
        ENGLISH_CULTURE,
        FRENCH_CULTURE,
        SPANISH_CULTURE,
        PORTUGUESE_CULTURE,
        ITALIAN_CULTURE,
        DEUTSCH_CULTURE,
        JAPANESE_CULTURE,
        CHINESE_CULTURE,
    ];

    public readonly availableReportLanguages: ISupportedCulture[] =
        ReactAppSettings?.appModel?.availableReportLanguages ?? [];

    public readonly availableAssessmentLanguages: ISupportedCulture[] =
        ReactAppSettings?.appModel?.availableAssessmentLanguages ?? [];

    @observable private _requiredLanguages: string[] = [];
    @computed public get requiredLanguages(): string[] {
        return this._requiredLanguages;
    }

    @observable private _currentLocale: ISupportedCulture;
    @computed public get currentLocale(): ISupportedCulture {
        return this._currentLocale;
    }
    @computed public get currentShortLocale(): string {
        return StringFormatter.get2CharIsoLanguage(this._currentLocale.shortName);
    }
    @computed public get currentFullLocale(): string {
        return this._currentLocale.name;
    }

    @observable private _currentReportLocale: ISupportedCulture;
    @computed public get currentReportLocale(): ISupportedCulture {
        return this._currentReportLocale;
    }
    @computed public get currentReportShortLocale(): string {
        return StringFormatter.get2CharIsoLanguage(this._currentReportLocale.shortName);
    }

    @computed public get currentAssessmentLanguage(): ISupportedCulture {
        const matching = findCultureFromSet(this.availableAssessmentLanguages, this.currentLocale);

        if (!matching) {
            return this.availableAssessmentLanguages.firstOrDefault();
        }

        return matching;
    }

    constructor(scope?: AppScope) {
        const appLanguageCode = ReactAppSettings?.viewBag?.baseInfo?.userInfo?.languageCode;

        if (appLanguageCode && scope !== AppScope.Assessment) {
            this._currentLocale =
                findCultureFromSet(this.availableLanguages, appLanguageCode) ??
                this.availableLanguages.firstOrDefault();
        } else {
            if (!navigator.language) {
                this._currentLocale = this.availableLanguages.firstOrDefault();
            } else {
                let navigatorCulture = findCultureFromSet(this.availableLanguages, navigator.language);

                if (!navigatorCulture) {
                    navigatorCulture = findCultureFromSet(
                        this.availableLanguages,
                        StringFormatter.get2CharIsoLanguage(navigator.language),
                        true,
                    );
                }

                this._currentLocale = navigatorCulture ?? this.availableLanguages.firstOrDefault();
            }
        }

        switch (scope) {
            case AppScope.Print:
                this._currentLocale =
                    findCultureFromSet(this.availableReportLanguages, appLanguageCode) ??
                    this.availableReportLanguages.firstOrDefault();
                break;
            case AppScope.Manager:
                this.availableLanguages = [FRENCH_CULTURE, ENGLISH_CULTURE];

                if (!findCultureFromSet(this.availableLanguages, this._currentLocale)) {
                    this._currentLocale = ENGLISH_CULTURE;
                }
                break;
            case AppScope.Supplier:
                this._requiredLanguages = this.availableLanguages.slice().map((x) => x.shortName);
                break;
            default:
                const appRequiredLanguages = ReactAppSettings?.appModel?.requiredLanguages;

                if (appRequiredLanguages) {
                    this._requiredLanguages = appRequiredLanguages.map((x) => x.shortName);
                }
                break;
        }

        this._currentReportLocale = this._currentLocale;

        autorun(() => {
            moment.locale(this.currentShortLocale);
        });
    }

    public validateLocalizedTextMapEntries<TValue>(
        map: Map<string, TValue>,
        validator?: (value: TValue) => boolean,
    ): { result: boolean; invalidLanguages: string[] } {
        validator = validator ?? ((value: TValue) => !!value);

        const validLanguages: string[] = [];

        for (const [k, v] of map) {
            if (validator(v)) {
                validLanguages.push(k);
            }
        }

        const invalidLanguages = this._requiredLanguages.filter((x) => !validLanguages.includes(x));

        return {
            result: !invalidLanguages.any(),
            invalidLanguages: invalidLanguages,
        };
    }

    @action updateCurrentLocale = (locale: string) => {
        const matchingCulture = findCultureFromSet(this.availableLanguages, locale);

        if (!matchingCulture) {
            return;
        }

        this._currentLocale = matchingCulture;
    };

    @action updateCurrentReportLocale = (locale: string) => {
        const matchingCulture = findCultureFromSet(this.availableReportLanguages, locale);

        if (!matchingCulture) {
            return;
        }

        this._currentReportLocale = matchingCulture;
    };

    @action updateRequiredLanguages = (requiredLanguages: string[]) => {
        this._requiredLanguages = requiredLanguages;
    };
}

export function findCultureFromSet(
    cultureSet: ISupportedCulture[],
    valueToMatch: ISupportedCulture | string,
    fuzzyMatch: boolean = false,
): ISupportedCulture | undefined {
    let match: ISupportedCulture | undefined;

    if (typeof valueToMatch === "string") {
        match = cultureSet.find((x) => x.name === valueToMatch || x.shortName === valueToMatch);

        if (!match && fuzzyMatch) {
            match = cultureSet.find((x) => x.name.includes(valueToMatch) || x.shortName.includes(valueToMatch));
        }
    } else {
        match = cultureSet.find((x) => x.name === valueToMatch.name || x.shortName === valueToMatch.shortName);

        if (!match && fuzzyMatch) {
            match = cultureSet.find((x) => x.name.includes(valueToMatch.shortName));
        }
    }

    return match;
}

export const ENGLISH_CULTURE: ISupportedCulture = {
    cultureInfo: "en-CA",
    display: "English (Canada)",
    shortDisplay: "English",
    name: "en-CA",
    shortName: "en",
};

export const FRENCH_CULTURE: ISupportedCulture = {
    cultureInfo: "fr-CA",
    display: "Français (Canada)",
    shortDisplay: "Français",
    name: "fr-CA",
    shortName: "fr",
};

export const SPANISH_CULTURE: ISupportedCulture = {
    cultureInfo: "es-ES",
    display: "Español (España)",
    shortDisplay: "Español",
    name: "es-ES",
    shortName: "es",
};

export const PORTUGUESE_CULTURE: ISupportedCulture = {
    cultureInfo: "pt-PT",
    display: "Português",
    shortDisplay: "Português",
    name: "pt-PT",
    shortName: "pt",
};

export const DEUTSCH_CULTURE: ISupportedCulture = {
    cultureInfo: "de-DE",
    display: "Deutsch",
    shortDisplay: "Deutsch",
    name: "de-DE",
    shortName: "de",
};

export const ITALIAN_CULTURE: ISupportedCulture = {
    cultureInfo: "it-IT",
    display: "Italiano",
    shortDisplay: "Italiano",
    name: "it-IT",
    shortName: "it",
};

export const JAPANESE_CULTURE: ISupportedCulture = {
    cultureInfo: "ja-JP",
    display: "日本語",
    shortDisplay: "日本語",
    name: "ja",
    shortName: "ja",
}

export const CHINESE_CULTURE: ISupportedCulture = {
    cultureInfo: "zh-CN",
    display: "中文",
    shortDisplay: "中文",
    name: "zh",
    shortName: "zh",
}