import "./index.less";
import * as React from "react";
import { AtPersonLabel } from "../AtPersonLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IInitialsAvatarProps, InitialsAvatar } from "../../../internal";
import cn from "classnames";

export interface Person {
    firstName?: string;
    lastName?: string;
    email?: string;
    description?: string;
    badge?: string;
    pictureUri?: string;
    avatarProps?: Partial<IInitialsAvatarProps>;
}

export interface IAtPeopleLabelCompareProps {
    leftPerson: Person;
    rightPerson: Person;
    isInPrintMode?: boolean;
}

export const AtPeopleLabelCompare: React.FC<IAtPeopleLabelCompareProps> = (props) => {
    const { leftPerson, rightPerson, isInPrintMode, ...otherProps } = props;

    return (
        <div className={cn("AtPersonLabelCompare", isInPrintMode ? "printDesign" : "")} {...otherProps}>
            {isInPrintMode && (
                <>
                    <div className="initialAndName">
                        <InitialsAvatar
                            text={`${leftPerson.firstName} ${leftPerson.lastName}`}
                            textColor="#fff"
                            bgColor={leftPerson.avatarProps?.bgColor}
                        />
                        <span>{`${leftPerson.firstName} ${leftPerson.lastName}`}</span>
                    </div>

                    <div className="initialAndName">
                        <InitialsAvatar
                            text={`${rightPerson.firstName} ${rightPerson.lastName}`}
                            textColor="#fff"
                            bgColor={rightPerson.avatarProps?.bgColor}
                        />
                        <span>{`${rightPerson.firstName} ${rightPerson.lastName}`}</span>
                    </div>
                </>
            )}
            {!isInPrintMode && (
                <>
                    <AtPersonLabel {...leftPerson} size={"md"} />
                    <div className="icon-container">
                        <FontAwesomeIcon icon={["far", "exchange"]} size={"1x"} />
                    </div>
                    <AtPersonLabel {...rightPerson} size={"md"} />
                </>
            )}
        </div>
    );
};
