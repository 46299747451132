import {
    AnalysisTileEnum,
    AnalyzedCandidate,
    AssessmentDocumentType,
    AtmanAssessmentDocumentStructure,
    DistributionData,
    DistributionViewMode,
    IInterpretationData,
    IPersonScores,
    Team,
} from "@atman/business";
import { AssessmentReport } from "../AssessmentReport";
import React from "react";

export interface IAssessmentDocumentsContentProps {
    activeTile: AnalysisTileEnum;
    assessmentDocuments: AtmanAssessmentDocumentStructure[];
    collapseAllExcept?: AssessmentDocumentType; // Undefined: Everything expanded (default), Specific value: Everything collapsed except this value.
    scores?: IPersonScores[];
    distributionData?: DistributionData;
    interpretationDataEntries?: Map<string, IInterpretationData>;
    analyzedCandidate?: AnalyzedCandidate;
    distributionViewMode?: DistributionViewMode;
    distributionMembers?: AnalyzedCandidate[];
    showDistribution?: boolean;
    showFitIndicators?: boolean;
    team?: Team;
    onViewCandidateDetailClick?: (candidateProId: string) => void;
    onActiveMemberUnselected?: () => void;
}

const getCollapseState = (
    document: AtmanAssessmentDocumentStructure,
    collapseAllExcept: AssessmentDocumentType | undefined,
    activeTile: AnalysisTileEnum,
): boolean | undefined => {
    if (collapseAllExcept) {
        if (activeTile === AnalysisTileEnum.Assessments && document.assessmentDocumentType === collapseAllExcept) {
            return false;
        }

        return true;
    }

    return undefined;
};

export const AssessmentDocumentsContent: React.FC<IAssessmentDocumentsContentProps> = (props) => {
    const { activeTile, assessmentDocuments, collapseAllExcept, showDistribution, ...otherProps } = props;

    return (
        <>
            {assessmentDocuments.map((x, i) => {
                return (
                    <AssessmentReport
                        key={x.assessmentDocumentType}
                        activeTile={activeTile}
                        documentStructure={x}
                        documentIndex={i}
                        defaultCollapseOpenState={!getCollapseState(x, collapseAllExcept, activeTile)}
                        showUnipolarScaleAsBipolar={showDistribution}
                        showDistribution={showDistribution}
                        {...otherProps}
                    />
                );
            })}
        </>
    );
};
