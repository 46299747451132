import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { inject, observer } from "mobx-react";

export interface IFormStepGroupSectionProps {
    sectionTitle: string;
    step: number;
    className?: string;
    id?: string;
}

@inject()
@observer
export class FormStepGroupSection extends React.Component<IFormStepGroupSectionProps, {}> {
    render() {
        const { sectionTitle, step, className, id, children, ...otherProps } = this.props;

        return (
            <div className={`FormStepGroupSection ${className ? className : ""}`} id={id} {...otherProps}>
                <div className="header">
                    <AtTitle headingType={5} className="step-number" title={step} />
                    <AtTitle headingType={4} title={sectionTitle} />
                </div>
                <div className="content">{children}</div>
            </div>
        );
    }
}
