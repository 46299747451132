import "./AtBalance.less";
import * as React from "react";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    ModalStore,
    ModalTypes,
    ProductBalanceStore,
    ProductConsumptionMode,
    PurchaseOptionEnum,
    ReactAppSettings,
    Role,
    TransactionType,
    UrlFormatter,
    UserInfoStore,
} from "@atman/business";
import { AtButton, AtLink } from "@atman/design-system";
import { BaseResponsiveComponent, Default } from "../../BaseResponsiveComponent";
import { BlockedBalanceMarker } from "../index";
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRoutedAppContext, withRoutedAppContext } from "../../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductInfo } from "../../ProductInfo";
import { UnlimitedBalanceMarker } from "./UnlimitedBalanceMarker";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAtBalanceProps extends IRoutedAppContext {
    productBalanceStore?: ProductBalanceStore;
    userInfoStore?: UserInfoStore;
    modalStore?: ModalStore;
    clientStore?: ClientStore;
}

@inject(GlobalStores.productBalanceStore, GlobalStores.modalStore, GlobalStores.userInfoStore, GlobalStores.clientStore)
@observer
class AtBalanceComp extends BaseResponsiveComponent<IAtBalanceProps> {
    protected get rootElementId(): string | undefined {
        return "AtBalance";
    }

    @observable public dropdownIsOpen: boolean = false;

    @computed get currentConsumptionMode(): ProductConsumptionMode {
        return this.props.productBalanceStore!.currentScheduleEntry?.consumptionMode ?? ProductConsumptionMode.Blocked;
    }

    @action.bound
    toggleDropdown = () => {
        this.dropdownIsOpen = !this.dropdownIsOpen;
    };

    @action.bound
    goToTransactions = () => {
        this.dropdownIsOpen = false;

        switch (this.props.scope) {
            case AppScope.Client:
                this.props.history.push(UrlFormatter.formatReactPath("/Settings/Transactions"));
                break;
            case AppScope.Partner:
            case AppScope.Supplier:
                this.props.history.push(UrlFormatter.formatReactPath("/Transactions"), {
                    transactionType: TransactionType.Purchase,
                });
                break;
        }
    };

    @action.bound
    openBuyProductsModal = () => {
        const {} = this.props;

        this.dropdownIsOpen = false;

        const addCreditsAvailable = ReactAppSettings.appModel.purchaseOptions.some(
            (x) => x === PurchaseOptionEnum.CreditCard || x === PurchaseOptionEnum.Invoice,
        );

        this.props.modalStore!.openModal(addCreditsAvailable ? ModalTypes.AddCredits : ModalTypes.RequestCredits);
    };

    getProductBalances(): IProductBalance[] {
        const { productBalanceStore } = this.props;

        if (!productBalanceStore!.balance) {
            return [];
        }

        return productBalanceStore!.availableProductDefinitions.map((x) => {
            const balanceValue = productBalanceStore!.balance[x.productCode];

            return { productCode: Number(x.productCode), balanceValue: balanceValue || 0 };
        });
    }

    getProductBalancesTotal(): number {
        return this.getProductBalances().reduce((acc, v) => acc + v.balanceValue, 0);
    }

    renderProductBalanceElements(): React.ReactNode[] {
        const balances = this.getProductBalances();

        return balances.map((balance, i) => {
            return (
                <div className="product-balance-detail" key={i}>
                    <ProductInfo productCode={balance.productCode} />
                    <div className="right-product-tion">
                        <div className="product-quantity">{balance.balanceValue}</div>
                    </div>
                </div>
            );
        });
    }

    renderBalanceItems() {
        let marker: React.ReactNode = null;

        switch (this.currentConsumptionMode) {
            case ProductConsumptionMode.Blocked:
                marker = <BlockedBalanceMarker />;
                break;
            case ProductConsumptionMode.Unlimited:
                marker = <UnlimitedBalanceMarker />;
                break;
            case ProductConsumptionMode.Balance:
                return this.renderProductBalanceElements();
        }

        return <div style={{ padding: 8 }}>{marker}</div>;
    }

    renderBalanceButton() {
        let balanceIndicator: React.ReactNode = null;

        switch (this.currentConsumptionMode) {
            case ProductConsumptionMode.Blocked:
                balanceIndicator = (
                    <div className="balance-indicator blocked">
                        <FontAwesomeIcon icon={["far", "ban"] as IconProp} />
                    </div>
                );
                break;
            case ProductConsumptionMode.Unlimited:
                balanceIndicator = (
                    <div className="balance-indicator unlimited">
                        <FontAwesomeIcon icon={["far", "infinity"] as IconProp} />
                    </div>
                );
                break;
            case ProductConsumptionMode.Balance:
                const balance = this.getProductBalancesTotal();
                const text = balance > 99 ? "99+" : balance;
                balanceIndicator = (
                    <div className={`balance-indicator product-count ${balance > 99 ? "three-chars" : ""}`}>{text}</div>
                );
        }

        return (
            <div>
                <AtButton
                    id="AtBalanceDropdownToggle"
                    color={this.currentConsumptionMode === ProductConsumptionMode.Blocked ? "danger" : "ghost"}
                    icon={["far", "shopping-cart"]}
                />
                {balanceIndicator}
            </div>
        );
    }

    renderAll() {
        const { productBalanceStore, userInfoStore } = this.props;

        if (!productBalanceStore!.balance) {
            return null;
        }

        const isBillingManager = userInfoStore!.hasRole(Role.BillingManager);
        const showBuyProductsButton =
            isBillingManager && this.currentConsumptionMode === ProductConsumptionMode.Balance;
        const showViewBalanceHistory = isBillingManager;

        const dropdownToggleWidth =
            document.getElementById("AtBalanceDropdownToggle")?.getBoundingClientRect().width ?? 40;
        const dropdownMenuWidth: number = 320;
        const menuOffset = (dropdownMenuWidth - dropdownToggleWidth) / 2;

        return (
            <>
                <Dropdown
                    isOpen={this.dropdownIsOpen}
                    toggle={this.toggleDropdown}
                    direction={"down"}
                    className={"balance-details-dropdown"}
                >
                    <DropdownToggle
                        className={"balance-overview"}
                        tag={"span"}
                        data-toggle={"dropdown"}
                        aria-expanded={this.dropdownIsOpen}
                    >
                        {this.renderBalanceButton()}
                        <Default>
                            <UncontrolledTooltip
                                className="sidebar-tooltip"
                                placement="bottom"
                                delay={{ show: 100, hide: 0 }}
                                target="AtBalanceDropdownToggle"
                            >
                                {"global.tooltipBalance".localize()}
                            </UncontrolledTooltip>
                        </Default>
                    </DropdownToggle>
                    <DropdownMenu
                        modifiers={{
                            offset: {
                                enabled: true,
                                offset: `-${menuOffset}px, 12px`,
                            },
                        }}
                        style={{ width: dropdownMenuWidth }}
                    >
                        {this.renderBalanceItems()}
                        {(showBuyProductsButton || showViewBalanceHistory) && (
                            <div className="transactions-link-container">
                                {showBuyProductsButton && (
                                    <AtButton onClick={this.openBuyProductsModal} fullWidth>
                                        {"global.buyProducts".localize()}
                                    </AtButton>
                                )}
                                {showViewBalanceHistory && (
                                    <AtLink size={"sm"} color={"secondary"} onClick={this.goToTransactions}>
                                        {"global.viewBalanceHistory".localize()}
                                    </AtLink>
                                )}
                            </div>
                        )}
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }
}

interface IProductBalance {
    productCode: number;
    balanceValue: number;
}

const AtBalance = withRoutedAppContext(AtBalanceComp);

export { AtBalance };
