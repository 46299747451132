import "./index.less";
import { AtTabCard, DefaultComponentProps } from "@atman/design-system";
import { Collapse } from "reactstrap";
import { Colors } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";
import React from "react";

export interface IExpandableFormStepGroupSectionProps extends DefaultComponentProps {
    sectionTitle: string;
    step: number;
    currentStep: number;
    showCheck?: boolean;
    customRightHeaderSectionContent?: React.ReactNode;
    goToStep?: (step: number) => void;
}

export const ExpandableFormStepGroupSection: React.FC<IExpandableFormStepGroupSectionProps> = observer((props) => {
    const {
        sectionTitle,
        step,
        className,
        id,
        customRightHeaderSectionContent,
        showCheck = true,
        goToStep,
        currentStep,
        children,
    } = props;

    const isOpen: boolean = step === currentStep;

    const shouldBeClickable: boolean = !!goToStep && currentStep > step;

    const getClasses: () => string = () => {
        let classes: string = "ExpandableFormStepGroupSection";

        if (className) {
            classes += ` ${className}`;
        }

        return classes;
    };

    const onClick = () => {
        if (goToStep) {
            goToStep(step);
        }
    };

    const leftHeaderContent: () => JSX.Element | undefined = () => {
        return (
            <div className="d-flex align-items-center">
                <div className="step-number">{step + 1}</div>
            </div>
        );
    };

    const rightHeaderContent: () => JSX.Element | undefined = () => {
        return customRightHeaderSectionContent ? (
            <div>{customRightHeaderSectionContent}</div>
        ) : showCheck && currentStep > step ? (
            <FontAwesomeIcon icon={["far", "check"] as IconProp} color={Colors.successColor} />
        ) : undefined;
    };

    return (
        <AtTabCard
            className={getClasses()}
            id={id}
            onClick={shouldBeClickable ? onClick : undefined}
            style={{ cursor: shouldBeClickable ? "pointer" : "default" }}
            cardTitle={sectionTitle}
            leftHeaderContent={leftHeaderContent()}
            rightHeaderContent={rightHeaderContent()}
        >
            <Collapse isOpen={isOpen}>
                <div className="collapse-content">{children}</div>
            </Collapse>
        </AtTabCard>
    );
});
