import * as React from "react";
import { AppContextConsumer, IRoutedAppContext } from "./context";
import { IAppContext } from "@atman/business";
import { withRouter } from "react-router";

export function withAppContext<P extends IAppContext, R = Omit<P, keyof IAppContext>>(
    Component: React.ComponentClass<P> | React.FC<P>,
): React.FC<R> {
    return function BoundComponent(props: R) {
        return (
            <AppContextConsumer>
                {(value) => {
                    // @ts-ignore TODO @cvincent 2021-04-22: Fix typing
                    return <Component {...props} {...value} />;
                }}
            </AppContextConsumer>
        );
    };
}

export function withRoutedAppContext<P extends IRoutedAppContext, R = Omit<P, keyof IRoutedAppContext>>(
    Component: React.ComponentClass<P> | React.FC<P>,
): React.FC<R> {
    return function BoundComponent(props: R) {
        return (
            <AppContextConsumer>
                {(value) => {
                    // @ts-ignore TODO @cvincent 2021-04-22: Fix typing
                    const RoutedComponent = withRouter((history) => <Component {...props} {...value} {...history} />);

                    return <RoutedComponent />;
                }}
            </AppContextConsumer>
        );
    };
}
