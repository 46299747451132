import "./index.less";
import {
    AnalysisTileEnum,
    AnalyzedCandidate,
    AppScope,
    AssessmentDocumentHelper,
    AssessmentDocumentType,
    AssessmentStore,
    AssessmentsResultsApi,
    AtmanAssessmentDocumentStructure,
    CacheProvider,
    CompetencyGroupEnum,
    CompetencyProfile,
    DimensionGroupStructure,
    DimensionStructure,
    DistributionData,
    DistributionViewMode,
    DrillDownLevelEnum,
    FitLevelEnum,
    GlobalStores,
    IAppContext,
    IAssessmentResultScore,
    IAtmanAssessmentDocumentStructure,
    IInterpretationData,
    IPersonScores,
    KpiApi,
    KpiStore,
    LocalizationStore,
    PublishableDocumentState,
    QueryableObservableMap,
    RangeHelper,
    Team,
    stanineCompetencyFitLevelMapping,
} from "@atman/business";
import { AssessmentDocumentsContent, AssessmentDocumentsHeader } from "..";
import {
    AtProfileHeader,
    IReactSelectOptionObject,
    LoadingIndicator,
    ReportRangeLegendItem,
} from "@atman/design-system";
import { IMapEntry, IReactionDisposer, action, computed, observable, reaction, runInAction, toJS } from "mobx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReportCandidateLegendItem, ReportLegendContainer } from "../..";
import { computedFn } from "mobx-utils";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../contexts";
import React from "react";
import cn from "classnames";

export interface IDocumentsData {
    documents: AtmanAssessmentDocumentStructure[];
    fitScoreCountMap: Nullable<Map<FitLevelEnum, number>>;
}
export type ViewFilterType =
    | "showAll"
    | AssessmentDocumentType[]
    | AssessmentDocumentType
    | CompetencyProfile
    | FitLevelEnum
    | FitLevelEnum[]
    | undefined;
export type TeamAnalysisLoadingStateKeys = "kpiScore" | "candidateScore" | "document" | "distributionData";

export interface IAssessmentDocumentMapKey {
    activeTile: AnalysisTileEnum;
    selectedOptionValue: string;
    languageCode: string;
}

export interface IAssessmentDocumentsContainerProps extends IAppContext {
    activeTile: AnalysisTileEnum;
    activeTileSelectedOption?: IReactSelectOptionObject<any>;
    loadingStates: Map<TeamAnalysisLoadingStateKeys, boolean>;
    collapseAllExcept?: AssessmentDocumentType; // Undefined: Everything expanded (default), Specific value: Everything collapsed except this value.
    scores?: IPersonScores[];
    distributionData?: DistributionData;
    interpretationDataEntries?: Map<string, IInterpretationData>;
    analyzedCandidate?: AnalyzedCandidate;
    compatibilityFitCandidate?: AnalyzedCandidate;
    currentViewFilter?: ViewFilterType;
    tileScore?: IAssessmentResultScore;
    distributionViewMode?: DistributionViewMode;
    distributionMembers?: AnalyzedCandidate[];
    showDistribution?: boolean;
    showFitIndicators?: boolean;
    team?: Team;
    printButton?: React.ReactNode;
    onViewCandidateDetailClick?: (candidateProId: string) => void;
    onActiveMemberUnselected?: () => void;
    onCurrentViewFiltersChange: (tile: AnalysisTileEnum, option: ViewFilterType) => void;
    assessmentStore?: AssessmentStore;
    kpiStore?: KpiStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.assessmentStore, GlobalStores.kpiStore, GlobalStores.localizationStore)
@observer
export class AssessmentDocumentsContainerImpl extends React.Component<IAssessmentDocumentsContainerProps, {}> {
    @observable public _assessmentDocumentsMap: QueryableObservableMap<
        IAssessmentDocumentMapKey,
        AtmanAssessmentDocumentStructure[]
    > = new QueryableObservableMap<IAssessmentDocumentMapKey, AtmanAssessmentDocumentStructure[]>();

    @observable private secondaryTitle: string | undefined = undefined;

    constructor(props) {
        super(props);

        this.clearOldCache();
    }

    activeTileReactionDisposer: IReactionDisposer = reaction(
        () => this.props.activeTile,
        () => this.props.activeTile !== AnalysisTileEnum.CompatibilityFit && this.loadDocumentsForTile(),
    );

    activeTileSelectedOptionReactionDisposer = reaction(
        () => this.props.activeTileSelectedOption?.value,
        () => this.props.activeTile !== AnalysisTileEnum.CompatibilityFit && this.loadDocumentsForTile(),
    );

    assessmentDocumentsMapReactionDisposer = reaction(
        () => this._assessmentDocumentsMap.keys(),
        () => this.cacheAssessmentDocumentsMap(),
    );

    compatibilityFitCandidateReactionDisposer = reaction(
        () => this.props.compatibilityFitCandidate?.candidateProId,
        () => this.loadDocumentsForTile(),
    );

    componentDidMount(): void {
        this.loadEntriesFromCache();
    }

    componentWillUnmount(): void {
        this.activeTileReactionDisposer();
        this.activeTileSelectedOptionReactionDisposer();
        this.assessmentDocumentsMapReactionDisposer();
        this.compatibilityFitCandidateReactionDisposer();
    }

    @computed get documentsAndFitScoresForTileType(): IDocumentsData {
        const {
            activeTile,
            activeTileSelectedOption,
            analyzedCandidate,
            currentViewFilter,
            assessmentStore,
            localizationStore,
            scores,
            team,
        } = this.props;

        let assessmentsDocuments: AtmanAssessmentDocumentStructure[];
        let fitScoreCountMap: Nullable<Map<FitLevelEnum, number>> = null;

        switch (activeTile) {
            case AnalysisTileEnum.Distribution:
            case AnalysisTileEnum.Assessments:
                const assessmentDocumentTypes: AssessmentDocumentType[] = [
                    AssessmentDocumentType.Cognitive,
                    AssessmentDocumentType.LearningMode,
                    AssessmentDocumentType.Personality,
                    AssessmentDocumentType.Preferences,
                    AssessmentDocumentType.TripleBilan,
                ];

                assessmentsDocuments = assessmentStore!.atmanAssessmentDocumentStructures
                    .filter((x) => {
                        if (!x.assessmentTypeEnum) {
                            return true;
                        }

                        if (analyzedCandidate && analyzedCandidate.analyzedAssessmentTypes) {
                            return analyzedCandidate.analyzedAssessmentTypes.includes(x.assessmentTypeEnum);
                        }

                        if (team) {
                            const teamPeople = [...team.members!, ...team.leads!];

                            const teamPeopleAssessmentTypes = teamPeople
                                .reduce((p, c) => [...p, ...c.completedAssessmentTypes], [])
                                .distinct();

                            return teamPeopleAssessmentTypes.includes(x.assessmentTypeEnum);
                        }

                        return true;
                    })
                    .filter((x) => {
                        if (currentViewFilter === "showAll") {
                            return assessmentDocumentTypes.includes(x.assessmentDocumentType);
                        }

                        if (
                            Array.isArray(toJS(currentViewFilter)) &&
                            (currentViewFilter as AssessmentDocumentType[]).length > 0
                        ) {
                            return (currentViewFilter as AssessmentDocumentType[]).includes(x.assessmentDocumentType);
                        }

                        return x.assessmentDocumentType === currentViewFilter;
                    })
                    .map((x) => {
                        if (currentViewFilter === "showAll") {
                            return x;
                        }

                        const assessmentDocument = new AtmanAssessmentDocumentStructure(x);
                        assessmentDocument.drillDownLevel = DrillDownLevelEnum.Group;

                        return assessmentDocument;
                    });
                break;
            case AnalysisTileEnum.Competencies:
                fitScoreCountMap = new Map<FitLevelEnum, number>([
                    [FitLevelEnum.VeryGood, 0],
                    [FitLevelEnum.GoodFit, 0],
                    [FitLevelEnum.BeVigilent, 0],
                ]);

                assessmentsDocuments = assessmentStore!.atmanAssessmentDocumentStructures
                    .filter(
                        (x) =>
                            x.assessmentDocumentType === AssessmentDocumentType.Competences &&
                            x.profile === activeTileSelectedOption?.value,
                    )
                    .map((x) => {
                        const document = new AtmanAssessmentDocumentStructure(x);
                        document.drillDownLevel = DrillDownLevelEnum.Group;

                        if (scores != undefined && scores.length > 0) {
                            for (const group of document.groups) {
                                group.dimensions = group.dimensions.map((d) => {
                                    const dimScore = AssessmentDocumentHelper.getScoresForDimension(
                                        scores,
                                        d.enumName,
                                    )[0];
                                    const fitScore = RangeHelper.getRangeMapValue(
                                        stanineCompetencyFitLevelMapping,
                                        dimScore.score,
                                    );
                                    d.fitScore = fitScore;

                                    if (
                                        d.fitScore !== undefined &&
                                        group.enumName !== CompetencyGroupEnum[CompetencyGroupEnum.Interests]
                                    ) {
                                        // We exclude the interest because they are not competencies.
                                        const currentValue = fitScoreCountMap!.get(d.fitScore);
                                        fitScoreCountMap!.set(d.fitScore, (currentValue ?? 0) + 1);
                                    }

                                    return d;
                                });
                            }

                            if (currentViewFilter !== "showAll") {
                                document.groups = filterDimensionsInGroups(document.groups, currentViewFilter);
                                document.groups = document.groups.filter(
                                    (g) => g.enumName !== CompetencyGroupEnum[CompetencyGroupEnum.Interests],
                                );
                                document.drillDownLevel = DrillDownLevelEnum.Group;
                            }

                            // Remove the fitScore from each dimension because we don't want to see "Fit score colored squares" in the scales.
                            // This fitScore is not calculated like the other and if we want leave it in the dimension, we're gonna have to
                            // make sure it is ok.
                            document.groups.forEach((g) => {
                                g.dimensions.forEach((d) => (d.fitScore = undefined));
                            });
                        }

                        return document;
                    });
                break;
            case AnalysisTileEnum.JobFit:
            case AnalysisTileEnum.CultureFit:
            case AnalysisTileEnum.Potential:
                fitScoreCountMap = new Map<FitLevelEnum, number>([
                    [FitLevelEnum.VeryGood, 0],
                    [FitLevelEnum.GoodFit, 0],
                    [FitLevelEnum.BeVigilent, 0],
                ]);

                assessmentsDocuments =
                    this._assessmentDocumentsMap
                        .getByPredicate(
                            (x) =>
                                x.activeTile === activeTile &&
                                x.selectedOptionValue === activeTileSelectedOption?.value &&
                                x.languageCode === localizationStore!.currentShortLocale,
                        )
                        ?.filter((x) => x.assessmentDocumentType !== undefined && x.assessmentDocumentType !== null)
                        ?.map((x) => {
                            const document = new AtmanAssessmentDocumentStructure(x);

                            document.drillDownLevel = DrillDownLevelEnum.Assessment;

                            if (
                                activeTile !== AnalysisTileEnum.JobFit ||
                                (activeTile === AnalysisTileEnum.JobFit &&
                                    document.assessmentDocumentType !== AssessmentDocumentType.Personality)
                            ) {
                                for (const group of document.groups) {
                                    group.dimensions = group.dimensions.map((x) => {
                                        const scoreToCompare = this.getScoreToCompare(x.enumName);

                                        if (scoreToCompare) {
                                            x.fitScore = x.getFitScore(scoreToCompare);

                                            if (x.fitScore !== undefined) {
                                                const currentValue = fitScoreCountMap!.get(x.fitScore);
                                                fitScoreCountMap!.set(x.fitScore, (currentValue ?? 0) + 1);
                                            }
                                        }

                                        return x;
                                    });
                                }
                            }

                            if (currentViewFilter !== "showAll") {
                                document.groups = filterDimensionsInGroups(document.groups, currentViewFilter);

                                document.drillDownLevel = DrillDownLevelEnum.Assessment;
                            }

                            return document;
                        }) ?? [];
                break;
            case AnalysisTileEnum.CompatibilityFit:
                fitScoreCountMap = new Map<FitLevelEnum, number>([
                    [FitLevelEnum.VeryGood, 0],
                    [FitLevelEnum.GoodFit, 0],
                    [FitLevelEnum.BeVigilent, 0],
                ]);

                assessmentsDocuments =
                    this._assessmentDocumentsMap
                        .getByPredicate(
                            (x) =>
                                x.activeTile === activeTile &&
                                x.selectedOptionValue === activeTileSelectedOption?.value,
                        )
                        ?.map((x) => {
                            const document = new AtmanAssessmentDocumentStructure(x);
                            document.drillDownLevel = DrillDownLevelEnum.Assessment;

                            const fitLevels: FitLevelEnum[] = [
                                FitLevelEnum.VeryGood,
                                FitLevelEnum.GoodFit,
                                FitLevelEnum.BeVigilent,
                            ];

                            const dimensions = document.groups.flatMap((x) => x.dimensions.slice());

                            for (const fitLevel of fitLevels) {
                                const currentFitLevelCountValue = fitScoreCountMap!.get(fitLevel);

                                fitScoreCountMap!.set(
                                    fitLevel,
                                    (currentFitLevelCountValue ?? 0) +
                                        dimensions.filter((d) => d.fitScore === fitLevel).length,
                                );
                            }

                            if (currentViewFilter !== "showAll") {
                                document.groups = filterDimensionsInGroups(document.groups, currentViewFilter);

                                document.drillDownLevel = DrillDownLevelEnum.Assessment;
                            }

                            return document;
                        }) ?? [];
                break;
        }

        return {
            documents: assessmentsDocuments,
            fitScoreCountMap,
        };
    }

    get isInTeamView(): boolean {
        return this.props.team !== undefined;
    }

    get showHeader(): boolean {
        const { activeTile } = this.props;

        return ![AnalysisTileEnum.Assessments, AnalysisTileEnum.Distribution].includes(activeTile);
    }

    @computed get documentsForCompetenciesHeader(): AtmanAssessmentDocumentStructure[] {
        const { activeTileSelectedOption, assessmentStore } = this.props;

        let assessmentsDocuments: AtmanAssessmentDocumentStructure[] = [];

        assessmentsDocuments = assessmentStore!.atmanAssessmentDocumentStructures
            .filter(
                (x) =>
                    x.assessmentDocumentType === AssessmentDocumentType.Competences &&
                    x.profile === activeTileSelectedOption?.value,
            )
            .map((x) => {
                const document = new AtmanAssessmentDocumentStructure(x);
                document.drillDownLevel = DrillDownLevelEnum.Group;

                return document;
            });

        return assessmentsDocuments;
    }

    @computed get contextTitle(): string | undefined {
        const { activeTile, team, activeTileSelectedOption } = this.props;

        switch (activeTile) {
            case AnalysisTileEnum.Distribution:
                return team?.name;
            case AnalysisTileEnum.Assessments:
                return "psychometrics.assessments".localize();
            case AnalysisTileEnum.CompatibilityFit:
                return undefined;
            default:
                return activeTileSelectedOption?.label ?? "";
        }
    }

    get headerDescription(): string | undefined {
        const { activeTile, activeTileSelectedOption } = this.props;

        if (!activeTileSelectedOption || activeTileSelectedOption.value === "") {
            return undefined;
        }

        switch (activeTile) {
            case AnalysisTileEnum.Competencies:
                const enumValue = CompetencyProfile[activeTileSelectedOption?.value];

                if (!enumValue) {
                    return undefined;
                }

                return `psychometrics.competencyProfiles.${enumValue.toCamel()}.option.description`.localize();
            case AnalysisTileEnum.JobFit:
            case AnalysisTileEnum.CultureFit:
            case AnalysisTileEnum.Potential:
                return activeTileSelectedOption.subtitle;
            default:
                return undefined;
        }
    }

    get headerIcon(): IconProp | undefined {
        const { activeTile } = this.props;

        let iconProp: IconProp | undefined = undefined;

        switch (activeTile) {
            case AnalysisTileEnum.JobFit:
                if (this.isInTeamView) {
                    iconProp = ["fal", "bullseye"];
                }
                break;
            case AnalysisTileEnum.Competencies:
                iconProp = ["fal", "stars"];
                break;
            case AnalysisTileEnum.CultureFit:
                iconProp = ["fal", "theater-masks"];
                break;
            case AnalysisTileEnum.Potential:
                iconProp = ["fal", "lightbulb"];
                break;
        }

        return iconProp;
    }

    get helpCenterLinkArticleId(): string | undefined {
        const { activeTile } = this.props;

        switch (activeTile) {
            case AnalysisTileEnum.JobFit:
                return "360054659371";
            case AnalysisTileEnum.Competencies:
                return "360054193712";
            case AnalysisTileEnum.CultureFit:
                return "360054193812";
            case AnalysisTileEnum.Potential:
                return "360054659411";
            case AnalysisTileEnum.CompatibilityFit:
                return "360054659431";
            default:
                return undefined;
        }
    }

    get assessmentResultScore(): IAssessmentResultScore | undefined {
        const { activeTile, tileScore } = this.props;

        if (activeTile !== AnalysisTileEnum.JobFit) {
            return undefined;
        }

        return tileScore;
    }

    get legendItems(): JSX.Element[] {
        const { activeTile, scores, onActiveMemberUnselected } = this.props;

        const legendItems = new Array<JSX.Element>();

        const desiredAreaCondition =
            activeTile === AnalysisTileEnum.JobFit ||
            activeTile === AnalysisTileEnum.CultureFit ||
            activeTile === AnalysisTileEnum.Potential;

        if (this.isInTeamView) {
            legendItems.push(
                <ReportRangeLegendItem
                    key="distributionRange"
                    iconClassName="team-concentration"
                    text={`psychometrics.teamsTendancy`.localize()}
                />,
            );
        }

        if (desiredAreaCondition) {
            legendItems.push(<ReportRangeLegendItem key="desiredArea" text={"psychometrics.desiredArea".localize()} />);
        }

        if (this.isInTeamView && scores && scores.length > 0) {
            scores.forEach((c) =>
                legendItems.push(
                    <ReportCandidateLegendItem
                        key={c.candidateProId}
                        candidateDisplayName={c.candidateDisplayName}
                        bgColor={c.color}
                        textColor={c.textColor}
                        size={"xs"}
                        onClose={c.isSelectedCandidate ? onActiveMemberUnselected : undefined}
                    />,
                ),
            );
        }

        return legendItems;
    }

    clearOldCache = async (): Promise<void> => {
        const allKeys = await CacheProvider.getAllKeys();

        if (
            allKeys.includes(CacheProvider.CACHE_KEYS.ASSESSMENT_DOCUMENTS_OLD) ||
            allKeys.includes(CacheProvider.CACHE_KEYS.ASSESSMENT_DOCUMENTS_OLD_V2) ||
            allKeys.includes(CacheProvider.CACHE_KEYS.ASSESSMENT_DOCUMENTS_OLD_v3)
        ) {
            CacheProvider.clear();
        }
    };

    cacheAssessmentDocumentsMap = () => {
        const { kpiStore, scope } = this.props;

        if (scope === AppScope.Print) {
            return; // MARK: Do not cache anything in a print context
        }

        const mapEntries = [...this._assessmentDocumentsMap.entries()].filter(([key]) => {
            const fitTiles: AnalysisTileEnum[] = [
                AnalysisTileEnum.JobFit,
                AnalysisTileEnum.CultureFit,
                AnalysisTileEnum.Potential,
            ];

            if (fitTiles.includes(key.activeTile) && key.selectedOptionValue) {
                // MARK: Remove Drafted Fits from list to avoid caching fits that are currently being worked on
                const fit = kpiStore!.getDefinitionForTile(key.selectedOptionValue, key.activeTile);

                if (fit) {
                    return fit.state === PublishableDocumentState.Published;
                }
            }

            return key.activeTile !== AnalysisTileEnum.CompatibilityFit;
        });

        CacheProvider.setItem(CacheProvider.CACHE_KEYS.ASSESSMENT_DOCUMENTS, mapEntries);
    };

    @action loadEntriesFromCache = () => {
        const entriesFromCache = CacheProvider.getItem<
            IMapEntry<IAssessmentDocumentMapKey, AtmanAssessmentDocumentStructure[]>[]
        >(CacheProvider.CACHE_KEYS.ASSESSMENT_DOCUMENTS, {
            callback: () => [],
        });

        this._assessmentDocumentsMap = new QueryableObservableMap<
            IAssessmentDocumentMapKey,
            AtmanAssessmentDocumentStructure[]
        >(entriesFromCache);
    };

    @action loadDocumentsForTile = async (): Promise<void> => {
        const {
            analyzedCandidate,
            activeTile,
            activeTileSelectedOption,
            loadingStates,
            kpiStore,
            localizationStore,
            compatibilityFitCandidate,
        } = this.props;

        let documents: IAtmanAssessmentDocumentStructure[] | null = null;

        const existingDocumentFromCache = this._assessmentDocumentsMap.getByPredicate(
            (x) =>
                x.activeTile === activeTile &&
                x.selectedOptionValue === activeTileSelectedOption?.value &&
                x.languageCode === localizationStore!.currentShortLocale,
        );

        if (existingDocumentFromCache && activeTile !== AnalysisTileEnum.CompatibilityFit) {
            return;
        }

        switch (activeTile) {
            case AnalysisTileEnum.Assessments:
                this.secondaryTitle = undefined;
                break;
            case AnalysisTileEnum.JobFit:
                const jobFit = kpiStore!.getJobFitDefinition(activeTileSelectedOption?.value);

                if (jobFit) {
                    loadingStates.set("document", true);
                    documents = await KpiApi.getJobFitDocuments(jobFit.id);

                    this.secondaryTitle = kpiStore?.jobFitDomains.find((x) => x.id === jobFit.jobDomainId)?.name;
                }
                break;
            case AnalysisTileEnum.CultureFit:
                const cultureFit = kpiStore!.getCultureFitDefinition(activeTileSelectedOption?.value);

                if (cultureFit) {
                    loadingStates.set("document", true);
                    documents = await KpiApi.getCultureFitDocuments(cultureFit.id);
                }
                break;
            case AnalysisTileEnum.Potential:
                const potential = kpiStore!.getPotentialDefinition(activeTileSelectedOption?.value);

                if (potential) {
                    loadingStates.set("document", true);
                    documents = await KpiApi.getPotentialDocuments(potential.id);
                }
                break;
            case AnalysisTileEnum.CompatibilityFit:
                if (
                    analyzedCandidate?.assessmentId &&
                    compatibilityFitCandidate?.assessmentId &&
                    compatibilityFitCandidate.candidateProId === activeTileSelectedOption?.value
                ) {
                    loadingStates.set("document", true);
                    const matchDocument = await AssessmentsResultsApi.getCompatibilityFitResult(
                        analyzedCandidate.assessmentId,
                        compatibilityFitCandidate.assessmentId,
                    );
                    documents = [matchDocument];
                }
                break;
        }

        if (documents !== null) {
            this._assessmentDocumentsMap.set(
                {
                    activeTile,
                    selectedOptionValue: activeTileSelectedOption?.value,
                    languageCode: localizationStore!.currentShortLocale,
                },
                documents
                    .filter((x) => x.assessmentDocumentType !== AssessmentDocumentType.GlobalScore)
                    .map((x) => new AtmanAssessmentDocumentStructure(x)),
            );
        }

        runInAction(() => {
            loadingStates.set("document", false);
        });
    };

    protected getAnalyzedCandidateScore = computedFn((dimensionName: string): number | undefined => {
        const { analyzedCandidate } = this.props;

        return analyzedCandidate?.tryGetDimensionScore(dimensionName);
    });

    protected getScoreToCompare = computedFn((dimensionName: string): number | undefined => {
        const { distributionData } = this.props;

        const distributionAverage = distributionData?.getAverageForDimension(dimensionName);
        const analyzedCandidateScore = this.getAnalyzedCandidateScore(dimensionName);

        return distributionAverage ?? analyzedCandidateScore;
    });

    render() {
        const {
            analyzedCandidate,
            activeTile,
            loadingStates,
            currentViewFilter,
            scores,
            distributionData,
            compatibilityFitCandidate,
            collapseAllExcept,
            interpretationDataEntries,
            distributionViewMode,
            distributionMembers,
            showDistribution,
            showFitIndicators,
            team,
            printButton,
            onViewCandidateDetailClick,
            onActiveMemberUnselected,
            onCurrentViewFiltersChange,
            ...otherProps
        } = this.props;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { activeTileSelectedOption, tileScore, assessmentStore, kpiStore, localizationStore, ...otherHtmlProps } =
            otherProps;

        const { documents, fitScoreCountMap } = this.documentsAndFitScoresForTileType;

        if (loadingStates.get("document")) {
            return (
                <>
                    {this.showHeader && (
                        <AssessmentDocumentsHeader
                            analysisTile={activeTile}
                            title={this.contextTitle}
                            secondaryTitle={this.secondaryTitle ?? undefined}
                            description={this.headerDescription}
                            icon={this.headerIcon}
                            firstCandidate={analyzedCandidate}
                            secondCandidate={compatibilityFitCandidate}
                            personScores={scores}
                            legendItems={this.legendItems}
                            printMode={undefined}
                        />
                    )}
                    <LoadingIndicator size={"xl"} label={"global.loadingYourData".localize()} />
                </>
            );
        }

        return (
            <div
                className={cn("AssessmentDocumentsContainer", {
                    "compatibility-fit-report": !!compatibilityFitCandidate,
                })}
                {...otherHtmlProps}
            >
                {this.isInTeamView ? (
                    <>
                        <AtProfileHeader teamName={team?.name} size={"md"} rightSection={printButton} />
                        {activeTile === AnalysisTileEnum.Distribution && (
                            <ReportLegendContainer>{this.legendItems}</ReportLegendContainer>
                        )}
                    </>
                ) : (
                    <AtProfileHeader {...analyzedCandidate} size={"md"} rightSection={printButton} />
                )}
                {this.showHeader && (
                    <AssessmentDocumentsHeader
                        analysisTile={activeTile}
                        title={this.contextTitle}
                        secondaryTitle={this.secondaryTitle ?? undefined}
                        description={this.headerDescription}
                        icon={this.headerIcon}
                        helpCenterLinkArticleId={this.helpCenterLinkArticleId}
                        assessmentResultScore={this.assessmentResultScore}
                        firstCandidate={analyzedCandidate}
                        secondCandidate={compatibilityFitCandidate}
                        personScores={scores}
                        legendItems={this.legendItems}
                        assessmentDocuments={
                            activeTile === AnalysisTileEnum.Competencies && !this.isInTeamView
                                ? this.documentsForCompetenciesHeader
                                : undefined
                        }
                        currentViewFilter={currentViewFilter}
                        fitScoreCountMap={!this.isInTeamView ? fitScoreCountMap : undefined}
                        onCurrentViewFiltersChange={onCurrentViewFiltersChange}
                        printMode={undefined}
                    />
                )}
                <AssessmentDocumentsContent
                    activeTile={activeTile}
                    collapseAllExcept={collapseAllExcept}
                    scores={scores}
                    distributionData={distributionData}
                    analyzedCandidate={analyzedCandidate}
                    interpretationDataEntries={interpretationDataEntries}
                    assessmentDocuments={documents}
                    distributionViewMode={distributionViewMode}
                    distributionMembers={distributionMembers}
                    showDistribution={showDistribution}
                    showFitIndicators={showFitIndicators}
                    team={team}
                    onViewCandidateDetailClick={onViewCandidateDetailClick}
                    onActiveMemberUnselected={onActiveMemberUnselected}
                />
            </div>
        );
    }
}

// Logic functions. We're gonna have to talk about where we want to put these but these are static and easily testable.
function filterDimensionsInGroups(
    groups: DimensionGroupStructure[],
    viewFilterType: ViewFilterType,
): DimensionGroupStructure[] {
    groups.forEach((g) => {
        g.dimensions = filterDimensions(g.dimensions, viewFilterType);
    }); // Filter dimensions in groups
    return groups.filter((g) => g.dimensions.any()); // Return only groups on which we still have dimensions
}

function filterDimensions(dimensions: DimensionStructure[], viewFilterType: ViewFilterType): DimensionStructure[] {
    return dimensions.filter((d) => d.fitScore === viewFilterType);
}

const AssessmentDocumentsContainer = withAppContext(AssessmentDocumentsContainerImpl);

export { AssessmentDocumentsContainer };
