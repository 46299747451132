import { IReactAppSettings, IWindow } from "../types";

export const ReactAppSettings: IReactAppSettings = (window as unknown as IWindow).reactAppSettings ?? {};
ReactAppSettings.getClientAppBaseUrl = (id) =>
    ReactAppSettings.appUrls.clientAppUrl.replace("{id}", id).replace(window.location.origin, "");
ReactAppSettings.getPartnerAppBaseUrl = (id) =>
    ReactAppSettings.appUrls.partnerAppUrl.replace("{id}", id).replace(window.location.origin, "");
ReactAppSettings.getSupplierAppBaseUrl = (id) =>
    ReactAppSettings.appUrls.supplierAppUrl.replace("{id}", id).replace(window.location.origin, "");
ReactAppSettings.getAssessmentAppBaseUrl = (id) =>
    ReactAppSettings.appUrls.assessmentAppUrl.replace("{id}", id).replace(window.location.origin, "");
