import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { observer } from "mobx-react";

export interface IEmailAccountComponentProps {
    goBack: () => void;
    email: string;
}

export const EmailAccountComponent: React.FC<IEmailAccountComponentProps> = (props) => {
    const { goBack, email } = props;

    return (
        <div className="EmailAccountComponent">
            <AtButton size={"md"} color={"ghost"} icon={["far", "long-arrow-left"]} onClick={goBack} />
            <span className={"email"}>{email}</span>
        </div>
    );
};

export default observer(EmailAccountComponent);
