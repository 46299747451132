import { Badge, UncontrolledTooltip } from "reactstrap";
import { t } from "@lingui/macro";
import React from "react";

export interface IYouBadgeProps {
    showTooltip?: boolean;
}

export const YouBadge: React.FC<IYouBadgeProps> = (props: IYouBadgeProps) => {
    const { showTooltip = false, ...otherProps } = props;

    return (
        <span {...otherProps}>
            <Badge color={"light"} id={"own-user-badge"}>
                {t({ id: "global.you" })}
            </Badge>
            {showTooltip && (
                <UncontrolledTooltip target={"own-user-badge"} placement={"left"}>
                    {t({ id: "global.cannotEditOwnUserTooltipText" })}
                </UncontrolledTooltip>
            )}
        </span>
    );
};
