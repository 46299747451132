import "./index.less";
import * as React from "react";
import { AtButton, AtIconTooltip, AtTitle } from "../../../internal";
import CopyToClipboard from "react-copy-to-clipboard";

export interface ITextCopyToClipboardProps {
    text: string;
    label?: string;
    onCopy?: () => void;
    iconTooltipContent?: string;
    buttonId?: string;
}

export const TextCopyToClipboard: React.FC<ITextCopyToClipboardProps> = (props) => {
    const { buttonId, label, text, onCopy, iconTooltipContent, children, ...otherProps } = props;

    return (
        <div className="TextCopyToClipboard" {...otherProps}>
            {label && (
                <div className="d-flex title-container align-items-center">
                    <AtTitle title={label} headingType={4} />
                    {iconTooltipContent && (
                        <AtIconTooltip icon={["far", "question-circle"]} tooltipDisplayMode={"dark"}>
                            {iconTooltipContent}
                        </AtIconTooltip>
                    )}
                </div>
            )}
            <div className="d-flex align-items-center">
                <span>{children ?? text}</span>
                <CopyToClipboard text={text} onCopy={onCopy}>
                    <AtButton size="sm" color="ghost" icon={["far", "copy"]} id={buttonId} />
                </CopyToClipboard>
            </div>
        </div>
    );
};
