import { ComponentPadding } from "../../../internal";

/**
 * This class serves as a Static Library to access design-system sizing values in Typescript
 * TODO: Consider using webpack-loader to get the values directly from index.less file to avoid having to maintain both
 */
export class Sizing {
    public static readonly pixels = {
        xxs: 4,
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
        xl: 40,
        xxl: 48,
        xxxl: 64,
        xxxxl: 80,
    };

    /**
     * Returns the css style value of the size specified in pixel.
     *
     * @param size - the pixel size
     * @returns The css style value of `size` in px
     */
    public static getPixelsPropValue(size: keyof typeof Sizing.pixels): string {
        if (!size || !Sizing.pixels.hasOwnProperty(size)) {
            console.warn(`[Sizing] Could not recognize size: ${size}. Defaulting to 'sm'`);
            return `${Sizing.pixels.sm}px`;
        }

        return `${Sizing.pixels[size]}px`;
    }

    public static readonly paddings: { [size: string]: ComponentPadding } = {
        xxs: {
            top: 2,
            right: Sizing.pixels.xs + Sizing.pixels.xxs,
            bottom: 2,
            left: 2,
        },
        sm: [Sizing.pixels.sm],
        smPlus: [Sizing.pixels.sm, Sizing.pixels.lg],
        md: [20, Sizing.pixels.xl],
        lg: [Sizing.pixels.md, Sizing.pixels.lg],
        lgPlus: [Sizing.pixels.lg],
        collapsibleHeader: {
            top: 18,
            right: Sizing.pixels.lg,
            bottom: 18,
            left: Sizing.pixels.lg,
        },
        collapsibleBody: {
            top: 18,
            right: Sizing.pixels.lg,
            bottom: 24,
            left: Sizing.pixels.lg,
        },
    };
}
