import { AtmanCoPlatformsEnum } from "../global/components/ProductSelector";
import { BaseMultiUrlApi, IBaseMultiUrlApi } from "./BaseMultiUrlApi";
import { INewsBoxContent } from "../data/models/NewsBoxContent";
import { ISsoProviderDetails, ShortClient } from "@atman/business";
import { ISystemStateInfo } from "../data/models/SystemState";
import { IUserSsoConfigurationModel } from "../data/models/UserSsoConfigurationModel";

export interface IAuthApi extends IBaseMultiUrlApi {
    login(
        username: string,
        password: string,
        rememberMe: boolean,
        returnUrl?: string,
        clientId?: string,
        options?: IAuthApiOptions,
    ): Promise<ILoginResponse>;
    loginSilently(returnUrl?: string, options?: IAuthApiOptions): Promise<ILoginResponse>;
    loginToHelpCenter(
        username: string,
        password: string,
        returnUrl?: string,
        options?: IAuthApiOptions,
    ): Promise<ILoginResponse>;
    loginSilentlyToHelpCenter(returnUrl?: string, options?: IAuthApiOptions): Promise<ILoginResponse>;
    getUserSsoConfiguration(username: string, options?: IAuthApiOptions): Promise<IUserSsoConfigurationModel>;
    getNewsBoxContent(languageCode: string, options?: IAuthApiOptions): Promise<INewsBoxContent>;
    getSystemStateInfos(): Promise<ISystemStateInfo[]>;
    changePassword(
        password: string,
        returnUrl?: string,
        clientId?: string,
        fromHelpCenterLogin?: boolean,
        options?: IAuthApiOptions,
    ): Promise<ILoginResponse>;
    acceptCookiePolicy(returnUrl?: string, clientId?: string, options?: IAuthApiOptions): Promise<ILoginResponse>;

    chooseClient(clientId: string, returnUrl?: string, options?: IAuthApiOptions): Promise<ILoginResponse>;
    getAllowedClients(options?: IAuthApiOptions): Promise<ShortClient[]>;
    getAllowedPasswordClients(options?: IAuthApiOptions): Promise<ShortClient[]>;

    verifyRefreshToken(options?: IAuthApiOptions): Promise<{ user?: string; rememberMe: boolean }>;

    /** Reset Password Flow **/
    requestPasswordReset(username: string, options?: IAuthApiOptions): Promise<void>;
    resetPassword(loginAccessToken: string, newPassword: string): Promise<void>;

    acceptCandidateDataAccessRequest(accessToken: string, options: IAuthApiOptions): Promise<void>;
    denyCandidateDataAccessRequest(accessToken: string, options: IAuthApiOptions): Promise<void>;

    /** Methods used in Employee MFA Flow **/
    requestMfaCodeForCandidate(email: string): Promise<void>;
    confirmMfaCodeForCandidate(email: string, code: string): Promise<{ redirectTo: string }>;

    /** Methods used in Employee SSO Flow **/
    getSsoProviderDetails(orgId: string): Promise<ISsoProviderDetails>;
}

const defaultAuthApiOptions: IAuthApiOptions = {
    platform: AtmanCoPlatformsEnum.ProNA,
};

export class AuthApi extends BaseMultiUrlApi implements IAuthApi {
    // MARK: Unauthenticated API Calls
    public async login(
        username: string,
        password: string,
        rememberMe: boolean,
        returnUrl?: string,
        clientId?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<ILoginResponse> {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/Login", {
            username,
            password,
            rememberMe,
            returnUrl,
            clientId,
        });
    }

    public async loginSilently(
        returnUrl?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<ILoginResponse> {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/SilentLogin", { returnUrl });
    }

    public async loginToHelpCenter(
        username: string,
        password: string,
        returnUrl?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ) {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/LoginToHelpCenter", {
            username,
            password,
            returnUrl,
        });
    }

    public async loginSilentlyToHelpCenter(
        returnUrl?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<ILoginResponse> {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/SilentHelpCenterLogin", { returnUrl });
    }

    public async getUserSsoConfiguration(username: string, options: IAuthApiOptions = defaultAuthApiOptions) {
        this.setOptions(options);
        return await this._apiClient.get<IUserSsoConfigurationModel>("/Security/GetUserSsoConfiguration", { username });
    }

    public async getNewsBoxContent(languageCode: string, options: IAuthApiOptions = defaultAuthApiOptions) {
        this.setOptions(options);
        return await this._apiClient.get<INewsBoxContent>("/Security/NewsBoxContent", { languageCode });
    }

    public async getSystemStateInfos() {
        return await this._apiClient.get<ISystemStateInfo[]>("/public/SystemState/state.json", undefined, {
            baseURL: this.getStorageBaseUrl(),
        });
    }

    public async verifyRefreshToken(
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<{ user?: string; rememberMe: boolean }> {
        this.setOptions(options);
        return await this._apiClient.post<{ user?: string; rememberMe: boolean }>("/Tokens/VerifyRefreshToken");
    }

    // MARK: Authenticated API Calls
    public async changePassword(
        password: string,
        returnUrl?: string,
        clientId?: string,
        fromHelpCenterLogin: boolean = false,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ) {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/ChangePassword", {
            password,
            returnUrl,
            fromHelpCenterLogin,
        });
    }

    public async acceptCookiePolicy(
        returnUrl?: string,
        clientId?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ) {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/AcceptCookiePolicy", { returnUrl, clientId });
    }

    public async chooseClient(
        clientId: string,
        returnUrl?: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<ILoginResponse> {
        this.setOptions(options);
        return await this._apiClient.post<ILoginResponse>("/Security/ChooseClient", { clientId, returnUrl });
    }

    public async getAllowedClients(options: IAuthApiOptions = defaultAuthApiOptions) {
        this.setOptions(options);
        return await this._apiClient.get<ShortClient[]>("/Security/AllowedClients");
    }

    public async getAllowedPasswordClients(options: IAuthApiOptions = defaultAuthApiOptions) {
        this.setOptions(options);
        return await this._apiClient.get<ShortClient[]>("/Security/AllowedPasswordClients");
    }

    /** Reset Password Flow **/
    public async requestPasswordReset(username: string, options: IAuthApiOptions = defaultAuthApiOptions) {
        this.setOptions(options);

        return await this._apiClient.post<void>("/Security/SendResetPasswordEmail", { username });
    }

    public resetPassword(loginAccessToken: string, newPassword: string): Promise<void> {
        this.setOptions(defaultAuthApiOptions);
        return this._apiClient.post("/Security/ResetPasswordWithToken", { loginAccessToken, newPassword });
    }

    /** Methods used in Employee MFA Flow **/
    public async requestMfaCodeForCandidate(email: string): Promise<void> {
        this.setOptions(defaultAuthApiOptions);
        return await this._apiClient.get("/PsychometricEvaluationAccess/RequestMfaCode", { email });
    }

    public async confirmMfaCodeForCandidate(email: string, code: string): Promise<{ redirectTo: string }> {
        this.setOptions(defaultAuthApiOptions);
        return await this._apiClient.post<{ redirectTo: string }>("/PsychometricEvaluationAccess/ConfirmMfaCode", {
            email,
            code,
        });
    }

    /** Methods used in Employee SSO Flow **/
    public async getSsoProviderDetails(orgId: string): Promise<ISsoProviderDetails> {
        this.setOptions(defaultAuthApiOptions);
        return await this._apiClient.get<ISsoProviderDetails>("/PsychometricEvaluationAccess/GetSsoProviderDetails", {
            orgId,
        });
    }

    public async acceptCandidateDataAccessRequest(
        accessToken: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<void> {
        this.setOptions(options);
        return await this._apiClient.post("/Security/AcceptAccessRequestToCandidate", { accessToken });
    }

    public async denyCandidateDataAccessRequest(
        accessToken: string,
        options: IAuthApiOptions = defaultAuthApiOptions,
    ): Promise<void> {
        this.setOptions(options);
        return await this._apiClient.post("/Security/DenyAccessRequestToCandidate", { accessToken });
    }

    private setOptions = (options: IAuthApiOptions) => {
        this.setBaseUrlFromPlatform(options.platform);
    };
}

export enum JwtType {
    App,
    Login,
}

export interface IJwtResponse {
    jwt: string;
    jwtExpiresAt: number;
}

export interface ILoginResponse extends IJwtResponse {
    redirectTo?: string;
    mustChangePassword?: boolean;
    mustAcceptCookiePolicy?: boolean;
    mustChooseClient?: boolean;
}

export interface IAuthApiOptions {
    platform?: AtmanCoPlatformsEnum;
}
