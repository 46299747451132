import "./index.less";
import * as React from "react";
import { FormGroup, FormText, Input, InputProps, Label } from "reactstrap";
import { inject, observer } from "mobx-react";

export interface ICustomSelectInputProps extends InputProps {
    fieldName: string;
    value: any;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    formText?: string;
    labelOverride?: string;
    hideLabel?: boolean;
}

@inject()
@observer
export class CustomSelectInput extends React.Component<ICustomSelectInputProps, {}> {
    render() {
        const { fieldName, value, onChange, labelOverride, children, formText, hideLabel, ...otherProps } = this.props;

        return (
            <FormGroup className={`CustomSelectInput ${fieldName}-input`}>
                {!hideLabel ? (
                    <Label for={fieldName}>{labelOverride ? labelOverride.localize() : fieldName.localize()}</Label>
                ) : null}
                <Input type="select" name={fieldName} id={fieldName} value={value} onChange={onChange} {...otherProps}>
                    {children}
                </Input>
                {formText ? <FormText>{formText}</FormText> : null}
            </FormGroup>
        );
    }
}
