import { AtStylesCandidateResult, AtStylesTeamResult, IAtStyleDefinition, IAtStylesGroupDefinition } from "./models";
import { BasePlatformRootStore, BaseStore } from "../../base";
import { ScoreBarDistribution } from "@atman/design-system";
import { observable, runInAction } from "mobx";
import AtStylesApi, { IAtStylesApi } from "./AtStylesApi";

export type StyleRepartition = {
    id: string;
    count: number;
    percentage: number;
}[];

export class AtStylesDefinitionStore extends BaseStore {
    private readonly _api: IAtStylesApi;

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);

        this._api = new AtStylesApi();
    }

    @observable public stylesGroupDefinitions: IAtStylesGroupDefinition[] = [];

    getGroupDefinition = (stylesGroupId: string): IAtStylesGroupDefinition | undefined => {
        return this.stylesGroupDefinitions.find((x) => x.id === stylesGroupId);
    };

    getStylesDefinition = (stylesGroupId: string, styleId: string): IAtStyleDefinition | undefined => {
        return this.stylesGroupDefinitions.find((x) => x.id === stylesGroupId)?.styles.find((x) => x.id === styleId);
    };

    getCandidateStylesResults = async (
        candidateProId: string,
        stylesGroupId: string,
    ): Promise<AtStylesCandidateResult> => {
        const result = await this._api.getCandidateStylesResult(candidateProId, stylesGroupId);

        return new AtStylesCandidateResult(result);
    };

    getTeamStylesResult = async (teamId: string, stylesGroupId: string): Promise<AtStylesTeamResult> => {
        const result = await this._api.getTeamStylesResult(teamId, stylesGroupId);

        return new AtStylesTeamResult(result);
    };

    getTeamStylesRepartition = (teamResult: AtStylesTeamResult): StyleRepartition => {
        const reduced = teamResult.memberResults
            .map((member) => member.dominantStyleId)
            .reduce(function (acc, curr) {
                return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
            }, {});

        return Object.keys(reduced)
            .map((key) => ({
                id: key,
                count: reduced[key] as number,
                percentage: (reduced[key] * 100) / teamResult.memberResults.length,
            }))
            .sort((a, b) => b.count - a.count);
    };

    generateScoreBarFromTeamStylesRepartition = (
        repartition: StyleRepartition,
        stylesGroupId: string,
    ): ScoreBarDistribution =>
        repartition.map((rep) => {
            const style = this.stylesGroupDefinitions
                .find((x) => x.id === stylesGroupId)
                ?.styles.find((x) => x.id === rep.id);

            return {
                value: rep.percentage,
                color: style?.color || "",
            };
        });

    loadStylesGroupDefinitions = async (): Promise<void> => {
        const result = await this._api.getStylesGroupDefinitions();

        runInAction(() => {
            this.stylesGroupDefinitions = result;
        });
    };
}
