import "./ScaleDotIndicator.less";
import * as React from "react";
import { ColorProperty } from "csstype";
import { observer } from "mobx-react";

export interface IScaleDotIndicatorProps {
    color: ColorProperty;
}

export const ScaleDotIndicator: React.FC<IScaleDotIndicatorProps> = observer((props) => {
    const { color } = props;
    return <div className="ScaleDotIndicator" style={{ backgroundColor: color }}></div>;
});
