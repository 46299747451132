import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";

export interface ICompDevObservableBehavior extends IBaseCompetencyElement {}

export class CompDevObservableBehavior extends BaseCompetencyElement implements ICompDevObservableBehavior {
    public readonly hierarchicalRank: string;

    constructor(json: ICompDevObservableBehavior, parentRank: string) {
        super(json);

        this.hierarchicalRank = `${parentRank}.${this.rank}`;
    }
}
