import "./index.less";
import * as React from "react";
import { AtTabBarItem } from "./components/AtTabBarItem";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { inject, observer } from "mobx-react";

export interface IAtTabBarProps {
    className?: string;
}

@inject()
@observer
class AtTabBar extends BaseResponsiveComponent<IAtTabBarProps> {
    renderAll() {
        const { className, children, ...otherProps } = this.props;

        return (
            <div className={`nav AtTabBar ${className ?? ""}`} {...otherProps}>
                {children}
            </div>
        );
    }
}

export { AtTabBar, AtTabBarItem };
