export interface IFitElement {
    readonly min: number;
    readonly max: number;
    readonly isInverted: boolean;
    readonly weight?: number;
    readonly weightDisplay?: string;
}

export abstract class FitElement implements IFitElement {
    public readonly isInverted: boolean;
    public readonly max: number;
    public readonly min: number;
    public readonly weight: number | undefined;
    public readonly weightDisplay: string | undefined;

    protected constructor(json: IFitElement) {
        this.isInverted = json.isInverted;
        this.max = json.max;
        this.min = json.min;
        this.weight = json.weight;
        this.weightDisplay = json.weightDisplay;
    }
}
