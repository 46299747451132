import "./index.less";
import * as React from "react";
import {
    AppScope,
    CompDevCompetency,
    Culture,
    CultureStore,
    CultureType,
    CulturesApi,
    GlobalStores,
    ICultureInput,
    ItemType,
    ModalTypes,
    UrlFormatter,
} from "@atman/business";
import { BLANK_VALUE, CompetencySetTemplateStep } from "../shared/Steps/CompetencySetTemplateStep";
import { BaseCompetencySetWizard } from "../shared/BaseCompetencySetWizard";
import { CompetencySetInformationStep } from "../shared/Steps/CompetencySetInformationStep";
import { CompetencySetSelectionStep } from "../shared/Steps/CompetencySetSelectionStep";
import { FieldError, LocalizedTextValidationError, ValidationError } from "@atman/core";
import { IWizardStep } from "../../../components/AtWizard";
import { RouteComponentProps, StaticContext } from "react-router";
import { SelectedCompetenciesSectionFragment } from "../shared/SidePanelContentSections/SelectedCompetenciesSectionFragment";
import { TextSectionFragment } from "../shared/SidePanelContentSections/TextSectionFragment";
import { action, computed, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { withRoutedAppContext } from "../../../contexts";
import autobind from "autobind-decorator";

export interface ICultureWizardProps
    extends RouteComponentProps<{ id?: string }, StaticContext, { duplicatedId?: string; forOrgCulture?: boolean }> {
    cultureStore?: CultureStore;
}

@inject(GlobalStores.cultureStore, GlobalStores.contentStore, GlobalStores.localizationStore, GlobalStores.modalStore)
@observer
class CultureWizardImpl extends BaseCompetencySetWizard<ICultureWizardProps, Culture> {
    protected creationLabelKey: string = "global.cultureCreation";
    protected wizardDomId: string = "CultureWizard";
    protected setListPath: string = "/Settings/Cultures";
    protected itemType: ItemType = ItemType.Culture;

    @computed get steps(): IWizardStep[] {
        const steps: IWizardStep[] = [];

        steps.push({
            title: "global.information".localize(),
            validateStep: this.validateForm_InformationStep,
            component: (
                <CompetencySetInformationStep
                    competencySetType="culture"
                    localizedNames={this.localizedNames}
                    localizedDescriptions={this.localizedDescriptions}
                    onLocalizedNameChange={this.onLocalizedNameChange}
                    onLocalizedDescriptionChange={this.onLocalizedDescriptionChange}
                    onFormSubStepSubmit={this.onFormSubStepSubmit}
                    onAutoCompleteChange={this.onAutoCompleteChange}
                    errors={this.errors}
                    departmentId={this.departmentId}
                />
            ),
        });

        if (this.shouldIncludeTemplateStep) {
            steps.push({
                title: "global.template".localize(),
                validateStep: this.validateForm_TemplateStep,
                additionalOnSubmit: this.onFormSubmit_TemplateStep,
                component: (
                    <CompetencySetTemplateStep
                        competencySetType="culture"
                        stepTitle={t({ id: "global.competencySetWizard.competencySelection.title" })}
                        stepDescription={t({
                            id: "global.cultureWizard.competencySelection.description",
                        })}
                        createdFromId={this.createdFromId}
                        onTemplateChange={this.onTemplateChange}
                        templates={this.templates}
                    />
                ),
            });
        }

        steps.push({
            title: "global.competencies".localize(),
            validateStep: this.validateForm_CompetencySelectionStep,
            component: (
                <CompetencySetSelectionStep
                    competencySetType="culture"
                    templates={this.templates}
                    createdFromId={this.createdFromId}
                    selectedCompetencyIds={this.selectedCompetencyIds}
                    onCompetencyIdSelection={this.onCompetencyIdSelection}
                    matchedSetId={this.matchedSetId}
                />
            ),
        });

        return steps;
    }

    protected findSetById(id: string): Culture | undefined {
        const { cultureStore } = this.props;

        return cultureStore?.findItemById(id);
    }

    @action
    protected loadFromSet(
        set: Culture,
        options: { isSystemSet?: boolean; isDuplicate?: boolean } = {
            isSystemSet: false,
            isDuplicate: false,
        },
    ): void {
        const { contentStore } = this.props;

        if (!set) {
            return;
        }

        this.selectedCompetencyIds = [...set.competencyIds];

        if (options.isSystemSet) {
            return;
        }

        this.localizedDescriptions = new Map<string, string>([...set.localizedDescriptions]);

        if (set.departmentId) {
            this.departmentId = {
                value: set.departmentId,
                label: contentStore?.getDepartmentById(set.departmentId)?.name ?? set.departmentId.localize(),
            };
        }

        if (options.isDuplicate) {
            for (const [k, v] of set.localizedNames) {
                this.localizedNames.set(k, `${"global.copyOf".localize()} ${v}`);
            }
        } else {
            this.localizedNames = set.localizedNames;
        }

        this.createdFromId = set.createdFromId;
    }

    @action protected async loadTemplates() {
        this.setIsLoading(true);
        const result = await CulturesApi.getSystemCultures();

        runInAction(() => {
            this.templates = result.map((x) => new Culture(x));
            this.setIsLoading(false);
        });
    }

    @autobind
    validateForm_InformationStep(): boolean {
        const { scope, localizationStore } = this.props;

        const cultureNameEmptyValidationResult = localizationStore!.validateLocalizedTextMapEntries(
            this.localizedNames,
        );
        const cultureNameLengthValidationResult = localizationStore!.validateLocalizedTextMapEntries(
            this.localizedNames,
            (x) => x.length <= 80,
        );
        const departmentIsValid = scope === AppScope.Supplier || this.departmentId?.value !== undefined; // Department should only be required for client cultures created from Client App

        this.clearErrors();

        if (!cultureNameEmptyValidationResult.result) {
            cultureNameEmptyValidationResult.invalidLanguages.forEach((x) => {
                this.errors.push(new FieldError(`localizedName-${x}`, t({ id: "global.requiredFieldErrorNotice" })));
            });
        } else if (!cultureNameLengthValidationResult.result) {
            this.errors.push(
                new LocalizedTextValidationError(
                    "localizedNames",
                    cultureNameLengthValidationResult.invalidLanguages,
                    (invalidLanguages) =>
                        `${"global.providedNameInTheFollowingLanguagesIsTooLong".localize()}: ${invalidLanguages}`,
                ),
            );
        }

        if (!departmentIsValid) {
            this.errors.push(new ValidationError("departmentId", "global.requiredFieldErrorNotice".localize()));
        }

        return cultureNameEmptyValidationResult.result && cultureNameLengthValidationResult.result && departmentIsValid;
    }

    onSubmit = async () => {
        const { scope, cultureStore, modalStore, history, location } = this.props;

        const input: ICultureInput = {
            localizedNames: [...this.localizedNames],
            localizedDescriptions: [...this.localizedDescriptions],
            createdFromId: this.createdFromId !== BLANK_VALUE ? this.createdFromId : undefined,
            departmentId: this.departmentId?.value,
            competencyIds: this.selectedCompetencyIds,
            cultureType: CultureType.EnterpriseAndTeam,
        };

        let createdId: string | undefined;

        if (this.matchedSetId) {
            await cultureStore!.updateItem(this.matchedSetId, input);
        } else {
            createdId = await cultureStore!.createItem(input);
        }

        if (!cultureStore!.hasErrored) {
            if (scope === AppScope.Client && !this.matchedSetId) {
                if (location?.state?.forOrgCulture && createdId) {
                    await cultureStore!.assignCultureToOrganization(createdId);
                } else {
                    modalStore!.openModal(ModalTypes.AssignCultureToTeams, {
                        competencySetId: createdId,
                    });
                }
            }

            history.push(UrlFormatter.formatReactPath(this.setListPath));
        }
    };

    protected get sidePanelContentSections(): { section: JSX.Element; flexible?: boolean }[] {
        const { contentStore } = this.props;

        const sections: { section: JSX.Element; flexible?: boolean }[] = [];

        sections.push({
            section: (
                <TextSectionFragment title={t({ id: "global.department" })} text={this.departmentId?.label ?? "-"} />
            ),
        });

        const selectedCompetencies: CompDevCompetency[] | undefined = contentStore?.competencies.filter((x) =>
            this.selectedCompetencyIds.includes(x.id),
        );

        sections.push({
            section: (
                <SelectedCompetenciesSectionFragment
                    selectedCompetencies={selectedCompetencies}
                    onCompetencyIdSelection={this.onCompetencyIdSelection}
                />
            ),
            flexible: true,
        });

        return sections;
    }
}

export const CultureWizard = withRoutedAppContext(CultureWizardImpl);
