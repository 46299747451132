import "./index.less";
import { AtButton, AtIcon, AtTitleSubtitle, IAtButtonProps } from "@atman/design-system/lib/internal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useUniqueId } from "@atman/core";
import React from "react";

export interface ITableItemHeadingProps {
    title?: string;
    description?: string;
    icon?: IconProp;
    buttons?: IAtButtonProps[];
    menuButton?: React.ReactNode;
}

export const TableItemHeading: React.FC<ITableItemHeadingProps> = (props: ITableItemHeadingProps) => {
    const { title, description, icon, buttons, menuButton, ...otherProps } = props;
    const { uniqueId } = useUniqueId();

    return (
        <div className="TableItemHeading AtContainer">
            <div className="icon-title-subtitle-container">
                {icon && (
                    <div className="icon-container">
                        <AtIcon size="lg" icon={icon ?? ["far", "user"]} />
                    </div>
                )}
                {title && <AtTitleSubtitle title={title} description={description} size="lg" />}
            </div>

            <div className="tableItemHeading-button-container">
                {buttons?.map((x, i) => (
                    <AtButton {...x} key={`at-list-btn-${uniqueId}`} />
                ))}
                {menuButton ?? null}
            </div>
        </div>
    );
};
