import "./index.less";
import { DefaultSizes, XS } from "../../../types";
import { Tooltip, TooltipProps } from "reactstrap";
import { observer } from "mobx-react";
import React, { useState } from "react";
import cn from "classnames";

export type AtTooltipDisplayMode = "light" | "dark";

export interface IAtTooltipProps extends Omit<TooltipProps, "isOpen" | "toggle"> {
    displayMode?: AtTooltipDisplayMode;
    size?: XS | DefaultSizes;
}

export const AtTooltip: React.FC<IAtTooltipProps> = observer((props) => {
    const { target, displayMode = "light", size = "md", children, className, innerClassName, ...otherProps } = props;

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleToggle = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    return (
        <Tooltip
            isOpen={isTooltipOpen}
            toggle={handleToggle}
            className={cn("AtTooltip", `tooltip-display-${displayMode}`, `size-${size}`, className)}
            innerClassName={cn("AtTooltip-inner", `tooltip-display-${displayMode}`, `size-${size}`, innerClassName)}
            target={target}
            hideArrow={true}
            {...otherProps}
        >
            {children}
        </Tooltip>
    );
});
