import "./index.less";
import * as React from "react";
import { AtButton, DefaultSizes } from "@atman/design-system";
import { UncontrolledTooltip } from "reactstrap";

export interface IRefreshButtonProps {
    onClick: () => void;
    isLoading: boolean;
    size?: DefaultSizes;
}

export class RefreshButton extends React.Component<IRefreshButtonProps, {}> {
    render() {
        const { onClick, isLoading, size } = this.props;

        return (
            <span className="RefreshButton">
                <AtButton id="refresh-btn" onClick={onClick} icon={["far", "sync"]} isLoading={isLoading} size={size} />
                <UncontrolledTooltip delay={{ show: 100, hide: 0 }} placement="top" target="refresh-btn">
                    {"global.refresh".localize()}
                </UncontrolledTooltip>
            </span>
        );
    }
}
