import {
    CompDevCompetencyResult,
    CompDevSubCompetencyResult,
    CompetencyDevelopmentProcessResultsData,
    CompetencySource,
    competencyGroupsToExcludeFromActionPlans,
} from "@atman/business";
import { IReactSelectOptionObject } from "@atman/design-system";
import { ITableHeaderProps, SortDirection } from "../../../..";

export type CompetencyDevelopmentColumnType =
    | "competencyName"
    | "averageScore"
    | "averageScoreScale"
    | "employeeScore"
    | "managerScore"
    | "spread"
    | "potential";

export type CompetencyDevelopmentActionPlanColumnType =
    | "competencies"
    | "subCompetencies"
    | "actions"
    | "objective"
    | "iconCol"
    | "type"
    | "actionsCount";

export type CompDevRowScoresColumnSet = "reviewScores" | "developmentPlanScores";

export type CompDevTableDataType = "competency" | "subCompetency";

export type CompDevTableDisplayType =
    | "summary"
    | "detailed"
    | "developmentPlanImprovement"
    | "developmentPlanLeverage"
    | "improvementObjectives"
    | "leverageObjectives";

export interface IPersonInfo {
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    pictureUri?: string;
}

export type SubCompetencyRowData = {
    subCompetency: CompDevSubCompetencyResult;
    parentCompetencyName: string;
};

export const resultTableColumnOptions: Array<{ value: CompetencyDevelopmentColumnType; labelKey: string }> = [
    { value: "competencyName", labelKey: "competencyDevelopment.global.competencies" },
    { value: "averageScore", labelKey: "competencyDevelopment.review.averageScore" },
    { value: "potential", labelKey: "competencyDevelopment.assessments.global.potentialScore" },
    { value: "employeeScore", labelKey: "competencyDevelopment.global.employeeScore" },
    { value: "managerScore", labelKey: "competencyDevelopment.global.managerScore" },
    { value: "spread", labelKey: "competencyDevelopment.global.spread" },
];

export const getSelectionProps = (
    element: CompetencyDevelopmentColumnType,
    sortElement?: CompetencyDevelopmentColumnType,
    sortDirection?: SortDirection,
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void,
) => {
    if (sortElement === undefined && onSortChange === undefined) {
        return undefined;
    }

    return {
        isActive: sortElement === element,
        onClick: () => onSortChange?.(element),
        sortDirection,
    } as Pick<ITableHeaderProps, "isActive" | "onClick" | "sortDirection">;
};

export function getSortedAndFilteredData(
    dataType: CompDevTableDataType,
    displayType: CompDevTableDisplayType,
    sortElement: CompetencyDevelopmentColumnType,
    processResultsData: CompetencyDevelopmentProcessResultsData,
    sourceFilters: IReactSelectOptionObject<CompetencySource>[],
    sortDirection: SortDirection,
): (CompDevCompetencyResult | SubCompetencyRowData)[] {
    if (displayType === "improvementObjectives") {
        return getSortedAndSelectedCompetencyImprovementObjectives(sortElement, processResultsData, sortDirection);
    } else if (displayType === "leverageObjectives") {
        return getSortedAndSelectedCompetencyLeveragingObjectives(sortElement, processResultsData, sortDirection);
    } else {
        const excludeOtherCompetencies =
            displayType === "developmentPlanLeverage" || displayType === "developmentPlanImprovement";

        return dataType === "competency"
            ? getSortedAndFilteredCompetencies(
                  sortElement,
                  processResultsData,
                  sourceFilters,
                  sortDirection,
                  excludeOtherCompetencies,
              )
            : getSortedAndFilteredSubCompetencies(sortElement, processResultsData, sourceFilters, sortDirection);
    }
}

export function getSortedAndSelectedCompetencyImprovementObjectives(
    sortElement: CompetencyDevelopmentColumnType,
    processResultsData: CompetencyDevelopmentProcessResultsData,
    sortDirection: SortDirection,
): CompDevCompetencyResult[] {
    const items = processResultsData!.getCompetenciesToImprove();
    const { selector, valueType } = getCompetencySortingKeySelector(sortElement);

    return sortCompDevElements(items, selector, valueType, sortDirection);
}

export function getSortedAndSelectedCompetencyLeveragingObjectives(
    sortElement: CompetencyDevelopmentColumnType,
    processResultsData: CompetencyDevelopmentProcessResultsData,
    sortDirection: SortDirection,
): CompDevCompetencyResult[] {
    const items = processResultsData!.getCompetenciesToLeverage();
    const { selector, valueType } = getCompetencySortingKeySelector(sortElement);

    return sortCompDevElements(items, selector, valueType, sortDirection);
}

export function getSortedAndFilteredCompetencies(
    sortElement: CompetencyDevelopmentColumnType,
    processResultsData: CompetencyDevelopmentProcessResultsData,
    sourceFilters: IReactSelectOptionObject<CompetencySource>[],
    sortDirection: SortDirection,
    excludeOtherCompetencies: boolean = false,
): CompDevCompetencyResult[] {
    let items = processResultsData!.getCompetenciesForSources(...sourceFilters.map((x) => x.value));

    if (excludeOtherCompetencies) {
        items = items.filter((x) => !competencyGroupsToExcludeFromActionPlans.includes(x.competencyGroup));
    }

    const { selector, valueType } = getCompetencySortingKeySelector(sortElement);

    return sortCompDevElements(items, selector, valueType, sortDirection);
}

export function getSortedAndFilteredSubCompetencies(
    sortElement: CompetencyDevelopmentColumnType,
    processResultsData: CompetencyDevelopmentProcessResultsData,
    sourceFilters: IReactSelectOptionObject<CompetencySource>[],
    sortDirection: SortDirection,
): SubCompetencyRowData[] {
    const filteredCompetencies = processResultsData!.getCompetenciesForSources(...sourceFilters.map((x) => x.value));
    const { selector, valueType } = getSubCompetencySortingKeySelector(sortElement);

    const filteredSubCompetencyRowData: SubCompetencyRowData[] = filteredCompetencies.flatMap((x) => {
        return x.subCompetencies.map((y) => {
            return { parentCompetencyName: x.name, subCompetency: y } as SubCompetencyRowData;
        });
    });

    return sortCompDevElements(filteredSubCompetencyRowData, selector, valueType, sortDirection);
}

function getCompetencySortingKeySelector(sortElement: CompetencyDevelopmentColumnType): {
    selector: (x: CompDevCompetencyResult) => number | string;
    valueType: "string" | "number";
} {
    let keySelector: (x: CompDevCompetencyResult) => number | string;
    let valueType: "string" | "number";

    switch (sortElement) {
        case "competencyName":
            keySelector = (x) => x.name;
            valueType = "string";
            break;
        case "averageScore":
        case "averageScoreScale":
            keySelector = (x) => x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage!;
            valueType = "number";
            break;
        case "potential":
            keySelector = (x) => x.psychometricScore ?? 0;
            valueType = "number";
            break;
        case "managerScore":
            keySelector = (x) => x.subCompetenciesManagerAverage!;
            valueType = "number";
            break;
        case "spread":
            keySelector = (x) => Math.abs(x.subCompetenciesManagerAverage! - x.subCompetenciesEmployeeAverage!);
            valueType = "number";
            break;
        case "employeeScore":
            keySelector = (x) => x.subCompetenciesEmployeeAverage!;
            valueType = "number";
            break;
        default:
            throw new Error("Forbidden column");
    }

    return {
        selector: keySelector,
        valueType: valueType,
    };
}

function getSubCompetencySortingKeySelector(sortElement: CompetencyDevelopmentColumnType): {
    selector: (x: SubCompetencyRowData) => number | string;
    valueType: "string" | "number";
} {
    let keySelector: (x: SubCompetencyRowData) => number | string;
    let valueType: "string" | "number";

    switch (sortElement) {
        case "competencyName":
            keySelector = (x) => x.subCompetency.name;
            valueType = "string";
            break;
        case "averageScore":
        case "averageScoreScale":
            keySelector = (x) =>
                x.subCompetency.observableBehaviorsReviewAverage ?? x.subCompetency.observableBehaviorsAverage!;
            valueType = "number";
            break;
        case "managerScore":
            keySelector = (x) => x.subCompetency.observableBehaviorsManagerAverage!;
            valueType = "number";
            break;
        case "spread":
            keySelector = (x) =>
                Math.abs(
                    x.subCompetency.observableBehaviorsManagerAverage! -
                        x.subCompetency.observableBehaviorsEmployeeAverage!,
                );
            valueType = "number";
            break;
        case "employeeScore":
            keySelector = (x) => x.subCompetency.observableBehaviorsEmployeeAverage!;
            valueType = "number";
            break;
        default:
            throw new Error("Forbidden column");
    }

    return {
        selector: keySelector,
        valueType: valueType,
    };
}

export function sortCompDevElements<T>(
    items: T[],
    selector: (item: T) => string | number,
    selectionValueType: "string" | "number",
    sortDirection: SortDirection,
) {
    switch (selectionValueType) {
        case "string":
            return sortArrayByStringProp(items, selector as (item: T) => string, sortDirection);
        case "number":
            return sortArrayByNumberProp(items, selector as (item: T) => number, sortDirection);
    }
}

export function sortArrayByNumberProp<T>(
    array: T[],
    keySelector: (item: T) => number,
    order: SortDirection = "asc",
): T[] {
    return array.sort((a: T, b: T) => {
        const varA = keySelector(a);
        const varB = keySelector(b);

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        return order === "desc" ? comparison * -1 : comparison;
    });
}

export function sortArrayByStringProp<T>(
    array: T[],
    keySelector: (item: T) => string,
    order: SortDirection = "asc",
): T[] {
    return array.sort((a: T, b: T) => {
        const varA = keySelector(a).toUpperCase();
        const varB = keySelector(b).toUpperCase();

        const comparison = varA.localeCompare(varB);

        return order === "desc" ? comparison * -1 : comparison;
    });
}

export function isColumnDisplayed(
    column: CompetencyDevelopmentColumnType,
    columns: CompetencyDevelopmentColumnType[],
    hiddenColumns?: CompetencyDevelopmentColumnType[],
): boolean {
    return columns.includes(column) && !hiddenColumns?.includes(column);
}

export function isActionPlanColumnDisplayed(
    column: CompetencyDevelopmentActionPlanColumnType,
    columns: CompetencyDevelopmentActionPlanColumnType[],
    hiddenColumns?: CompetencyDevelopmentActionPlanColumnType[],
): boolean {
    return columns.includes(column) && !hiddenColumns?.includes(column);
}
