import { BaseApi } from "../base";
import { GenericFit, IAtmanAssessmentDocumentStructure, IJobFit } from "../models";
import { HttpMethodEnum } from "../types";
import { IJobFitDomain } from "../models/IJobFitDomain";
import { ReactAppSettings } from "../libs";

export class KpiApi extends BaseApi {
    public static async getJobFitDefinitions(): Promise<IJobFit[]> {
        return await this.sendApiRequestWithResult<null, IJobFit[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJobFitDefinitions,
        );
    }

    public static async getCultureFitDefinitions(): Promise<GenericFit[]> {
        return await this.sendApiRequestWithResult<null, GenericFit[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCultureFitDefinitions,
        );
    }

    public static async getPotentialDefinitions(): Promise<GenericFit[]> {
        return await this.sendApiRequestWithResult<null, GenericFit[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPotentialDefinitions,
        );
    }

    public static async getJobFitDomains(): Promise<IJobFitDomain[]> {
        return await this.sendApiRequestWithResult<null, IJobFitDomain[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJobFitDomains,
        );
    }

    public static async getJobFitDocuments(jobFitId: string): Promise<IAtmanAssessmentDocumentStructure[]> {
        const data = {
            jobFitId,
        };

        return await this.sendApiRequestWithResult<typeof data, IAtmanAssessmentDocumentStructure[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJobFitDocuments,
            data,
        );
    }

    public static async getCultureFitDocuments(cultureFitId: string): Promise<IAtmanAssessmentDocumentStructure[]> {
        const data = {
            cultureFitId,
        };

        return await this.sendApiRequestWithResult<typeof data, IAtmanAssessmentDocumentStructure[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCultureFitDocuments,
            data,
        );
    }

    public static async getPotentialDocuments(potentialId: string): Promise<IAtmanAssessmentDocumentStructure[]> {
        const data = {
            potentialId,
        };

        return await this.sendApiRequestWithResult<typeof data, IAtmanAssessmentDocumentStructure[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPotentialDocuments,
            data,
        );
    }

    public static async getAtmanDocuments(): Promise<IAtmanAssessmentDocumentStructure[]> {
        return await this.sendApiRequestWithResult<void, IAtmanAssessmentDocumentStructure[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getAtmanDocuments,
        );
    }
}
