import "./index.less";
import * as React from "react";
import { AtWizardIconHeader } from "../../../../../components/AtWizard";
import { BaseCompetencySet } from "@atman/business";
import { BaseForm } from "../../../../../components/BaseForm";
import { CompetencySetTemplateCard } from "../../CompetencySetTemplateCard";
import { CompetencySetType } from "../../CompetencySetInformationForm";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

export const BLANK_VALUE = "blank";

export interface ICompetencySetTemplateStepProps {
    competencySetType: CompetencySetType;
    stepTitle: string;
    stepDescription: string;
    createdFromId: string | undefined;
    onTemplateChange: (id: string) => void;
    templates: BaseCompetencySet[];
}

@inject()
@observer
export class CompetencySetTemplateStep extends BaseForm<ICompetencySetTemplateStepProps, {}> {
    @observable private searchString: string = "";
    @observable private filteredTemplates: BaseCompetencySet[];

    constructor(props) {
        super(props);

        autorun(() => {
            this.filteredTemplates = this._filteredTemplates;
        });
    }

    @computed get _filteredTemplates(): BaseCompetencySet[] {
        const { templates } = this.props;

        if (!this.searchString) {
            return templates;
        }

        return templates.filter((x) =>
            [...x.localizedNames.values()].some((x) => x.toUpperCase().includes(this.searchString.toUpperCase())),
        );
    }

    render() {
        const { competencySetType, createdFromId, onTemplateChange, stepTitle, stepDescription } = this.props;

        return (
            <div id="CompetencySetTemplateStep">
                <AtWizardIconHeader
                    icon={competencySetType === "job" ? "id-badge" : "theater-masks"}
                    title={stepTitle}
                    description={stepDescription}
                />
                <div className="header">
                    <CustomTextInput
                        leftIcon={["far", "search"]}
                        className={"list-header-search-input"}
                        fieldName={"searchString"}
                        value={this.searchString}
                        onChange={this.onTextFieldChange}
                        placeholder={"global.search".localize()}
                        hideLabel
                    />
                </div>
                <div className="templates-container">
                    <CompetencySetTemplateCard
                        templateId={BLANK_VALUE}
                        name={"global.blank".localize()}
                        description={"global.startFromScratch".localize()}
                        competencyIds={[]}
                        onTemplateChange={onTemplateChange}
                        active={createdFromId === BLANK_VALUE}
                    />
                    {this.filteredTemplates.map((x) => (
                        <CompetencySetTemplateCard
                            key={`template-${x.id}`}
                            templateId={x.id}
                            name={x.name}
                            description={x.description}
                            competencyIds={x.competencyIds}
                            onTemplateChange={onTemplateChange}
                            active={createdFromId === x.id}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
