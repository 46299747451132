import "./index.less";
import * as React from "react";
import { observer } from "mobx-react";
import ProductElement from "./components/ProductElement";

export interface IProductSelectorProps {
    selectedPlatform: AtmanCoPlatformsEnum | undefined;
    onSelectedPlatformChange: (key?: AtmanCoPlatformsEnum) => void;
    disableSelection?: boolean;
}

@observer
class ProductSelector extends React.Component<IProductSelectorProps, {}> {
    private readonly atmanCoProducts: IAtmanCoProductInfo[] = [];

    constructor(props) {
        super(props);

        this.atmanCoProducts.push({
            enum: AtmanCoPlatformsEnum.ProNA,
            namePrefix: "Atman",
            nameSuffix: "Pro",
        });

        // if (process.env.REACT_APP_ENVIRONMENT !== "prod") {
        //     this.atmanCoProducts.push({
        //         enum: AtmanCoPlatformsEnum.ProEU,
        //         namePrefix: "Atman",
        //         nameSuffix: "Europe",
        //     });
        // }
    }

    render() {
        const { selectedPlatform, onSelectedPlatformChange, disableSelection } = this.props;

        if (this.atmanCoProducts.length <= 1) {
            return null;
        }

        return (
            <div id="ProductSelector">
                <div className="products-container">
                    {this.atmanCoProducts.map((x) => (
                        <ProductElement
                            productInfo={x}
                            onClick={() => {
                                if (disableSelection) {
                                    return;
                                }

                                onSelectedPlatformChange(x.enum);
                            }}
                            active={x.enum === selectedPlatform}
                            disabled={disableSelection ? x.enum !== selectedPlatform : undefined}
                            key={x.enum}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export enum AtmanCoPlatformsEnum {
    // @deprecated
    // Classic = 0,

    ProNA = 1,
    ProEU = 2,
}

export interface IAtmanCoProductInfo {
    enum: AtmanCoPlatformsEnum;
    namePrefix: string;
    nameSuffix: string;
}

export default ProductSelector;
