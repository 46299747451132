import "./index.less";
import * as React from "react";
import {
    AppScope,
    GlobalStores,
    ItemType,
    ModalStore,
    ModalTypes,
    ReactAppSettings,
    Role,
    User,
    UserInfoStore,
    UserState,
    UserStore,
    WorkspaceStore,
} from "@atman/business";
import {
    AtContainer,
    AtTable,
    AtTableColumn,
    AtTabs,
    IAtButtonProps,
    IAtTabsContent,
    TableItemHeading,
} from "@atman/design-system";
import { CustomDeleteAlert, PersonQuickView } from "..";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { toJS } from "mobx";
import { useAppContext } from "../../contexts";
import { useMemo } from "react";
import getUserManagementColumns from "./columns";

export type UserManagementView = "active" | "pending" | "disabled";

export interface IUserManagementProps {
    userStore?: UserStore;
    modalStore?: ModalStore;
    userInfoStore?: UserInfoStore;
    ownerId?: string;
    fromSettingsPage?: boolean;
    workspaceStore?: WorkspaceStore;
    icon?: IconProp;
}

export const UserManagement: React.FC<IUserManagementProps> = inject(
    GlobalStores.userInfoStore,
    GlobalStores.modalStore,
    GlobalStores.workspaceStore,
    GlobalStores.userStore,
)(
    observer((props: IUserManagementProps) => {
        const { userStore, modalStore, userInfoStore, fromSettingsPage, ownerId, workspaceStore, icon } = props;

        const { scope } = useAppContext();

        const canManageUserClientsDelegation =
            scope === AppScope.Partner && fromSettingsPage && userInfoStore!.automaticClientDelegationEnabled;

        const allUsers = ownerId ? userStore!.entityUsers : userStore!.users;

        const isDelegatedForClient: boolean = useMemo(() => {
            switch (scope) {
                case AppScope.Client:
                    return true;
                case AppScope.Partner:
                    return (
                        fromSettingsPage ||
                        userInfoStore!.automaticClientDelegationEnabled ||
                        userInfoStore!.hasRole(Role.AdminSupplier) ||
                        (ReactAppSettings.appModel.delegatedClientIds ?? []).includes(ownerId!)
                    );
                case AppScope.Supplier:
                    return true;
                default:
                    return false;
            }
        }, [fromSettingsPage, ownerId, scope, userInfoStore]);

        const onUserEditClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
            e?.stopPropagation();
            modalStore!.openModal(ModalTypes.UserForm, { id, externalUsers: !!ownerId });
        };

        const onUserClientsDelegationUpdateClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
            e?.stopPropagation();

            modalStore!.openModal(ModalTypes.UpdateUserClientsDelegation, { id });
        };

        const onUserSecondaryClick = async (id: string) => {
            let user: User | undefined;

            if (ownerId) {
                user = userStore!.entityUsers.find((x) => x.id === id);
            } else {
                user = userStore!.getUserById(id);
            }

            if (!user) {
                return;
            }

            const deleteAlert = new CustomDeleteAlert(
                ItemType.User,
                `${user.firstName} ${user.lastName}`,
                () => userStore!.deleteUser(id, ownerId),
                <PersonQuickView user={user} />,
            );

            return deleteAlert.fire();
        };

        const getUsersForView = (view: UserManagementView) => {
            let users: User[];

            if (ownerId) {
                users = userStore!.entityUsers;
            } else {
                users = userStore!.users;
            }

            const pendingUsers = users.filter((x) => !x.lastLoginDate && x.mustChangePassword);

            switch (view) {
                case "active":
                    return users.filter(
                        (x) => !pendingUsers.find((u) => u.id === x.id) && x.state === UserState.Active,
                    );
                case "disabled":
                    return users.filter(
                        (x) => !pendingUsers.find((u) => u.id === x.id) && x.state === UserState.Inactive,
                    );
                case "pending":
                    return pendingUsers;
            }
        };

        const columns: AtTableColumn<User>[] = useMemo(
            () =>
                getUserManagementColumns(
                    userInfoStore!,
                    userStore!,
                    workspaceStore!,
                    onUserEditClick,
                    onUserSecondaryClick,
                    isDelegatedForClient,
                    canManageUserClientsDelegation ? onUserClientsDelegationUpdateClick : null,
                    !fromSettingsPage!,
                    scope,
                    allUsers.map((x) => x.baseRole).filter(x => !!x).distinct(),
                    toJS(allUsers)
                        .flatMap((x) => x.additionalRoles)
                        .distinct(),
                ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                scope,
                userInfoStore,
                userStore,
                workspaceStore,
                isDelegatedForClient,
                canManageUserClientsDelegation,
                fromSettingsPage,
                allUsers,
            ],
        );

        const createUserModalChildProps: { clientId?: string; partnerProId?: string } = {
            clientId: undefined,
            partnerProId: undefined,
        };

        const userViewTabs: IAtTabsContent[] = [
            {
                id: "active",
                buttonContent: t({ id: "global.enabled" }),
                content: (
                    <AtTable<User>
                        items={getUsersForView("active")}
                        columns={columns}
                        onRowClick={(e, record) => onUserEditClick(e, record.id)}
                        disableClickActionForRow={(record) => {
                            if (!isDelegatedForClient) {
                                return true;
                            }

                            if (record.id === userInfoStore!.userId) {
                                return true;
                            }

                            return false;
                        }}
                        refreshTable={userStore!.loadUsers}
                        isLoading={userStore!.isLoading}
                    />
                ),
            },
            {
                id: "pending",
                buttonContent: t({ id: "global.pending" }),
                content: (
                    <AtTable<User>
                        items={getUsersForView("pending")}
                        columns={columns}
                        onRowClick={(e, record) => onUserEditClick(e, record.id)}
                        disableClickActionForRow={(record) => {
                            if (!isDelegatedForClient) {
                                return true;
                            }

                            if (record.id === userInfoStore!.userId) {
                                return true;
                            }

                            return false;
                        }}
                        refreshTable={userStore!.loadUsers}
                        isLoading={userStore!.isLoading}
                    />
                ),
            },
            {
                id: "disabled",
                buttonContent: t({ id: "global.disabled" }),
                content: (
                    <AtTable<User>
                        items={getUsersForView("disabled")}
                        columns={columns}
                        onRowClick={(e, record) => onUserEditClick(e, record.id)}
                        disableClickActionForRow={(record) => {
                            if (!isDelegatedForClient) {
                                return true;
                            }

                            if (record.id === userInfoStore!.userId) {
                                return true;
                            }

                            return false;
                        }}
                        refreshTable={userStore!.loadUsers}
                        isLoading={userStore!.isLoading}
                    />
                ),
            },
        ];

        switch (scope) {
            case AppScope.Client:
                createUserModalChildProps.clientId = userInfoStore!.clientId;
                createUserModalChildProps.partnerProId = userInfoStore!.partnerId;
                break;
            case AppScope.Partner:
                createUserModalChildProps.partnerProId = userInfoStore!.partnerId;
                break;
            case AppScope.Supplier:
                break;
        }

        const buttons: IAtButtonProps[] = [
            {
                onClick: () => modalStore!.openModal(ModalTypes.UserForm, createUserModalChildProps),
                children: "global.addUser".localize(),
            },
        ];

        return (
            <div id="UserManagement">
                {fromSettingsPage && (
                    <TableItemHeading
                        title={t({ id: "global.users" })}
                        description={t({ id: "global.users.description" })}
                        buttons={buttons}
                        icon={icon}
                    />
                )}

                <AtContainer className="list">
                    <AtTabs
                        tabs={userViewTabs}
                        defaultTab={userViewTabs[0].id}
                        atButtonTabsProps={{ fitContent: true }}
                    />
                </AtContainer>
            </div>
        );
    }),
);
