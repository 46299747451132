import { BaseFitContent, IBaseFitContent } from "./BaseFitContent";
import { CustomFlag, ICustomFlag } from "./elements/CustomFlag";

export interface IJobFitContent extends IBaseFitContent {
    readonly negativeCorrectionRuleSet: {
        // systemFlags: ICustomFlag[];
        customFlags: ICustomFlag[];
    };
}

export class JobFitContent extends BaseFitContent implements IJobFitContent {
    public readonly negativeCorrectionRuleSet: {
        // systemFlags: CustomFlag[];
        customFlags: CustomFlag[];
    };

    constructor(json: IJobFitContent) {
        super(json);

        this.negativeCorrectionRuleSet = {
            // systemFlags: json.negativeCorrectionRuleSet?.systemFlags?.map((x) => new CustomFlag(x)) ?? [],
            customFlags: json.negativeCorrectionRuleSet?.customFlags?.map((x) => new CustomFlag(x)) ?? [],
        };
    }

    get totalCompetencyWeights(): number {
        return this.allCompetencies.reduce((previousValue, { weight }) => previousValue + (weight ?? 0), 0);
    }
}
