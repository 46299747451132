import * as React from "react";
import { AtText, AtTitle } from "@atman/design-system";

export interface ITextSectionFragment {
    title: string;
    text: string;
}

export const TextSectionFragment: React.FC<ITextSectionFragment> = (props) => {
    const { title, text } = props;

    return (
        <>
            <AtTitle title={title} />
            <AtText size="lg" weight="medium">
                {text}
            </AtText>
        </>
    );
};
