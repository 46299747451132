import "./ModalNewBalanceConfirmation.less";
import * as React from "react";
import { GlobalStores, ProductBalanceStore, ProductCodeProEnum } from "@atman/business";
import { ProductBalanceChange } from "../../components/ProductBalanceChange";
import { inject, observer } from "mobx-react";

export type BalanceOperation = "credit" | "debit";

export interface IModalNewBalanceConfirmationProps {
    balanceOperation: BalanceOperation;
    previousBalance: Dictionary<ProductCodeProEnum, number>;
    selectedProductsBalance: Dictionary<ProductCodeProEnum, number>;
    productBalanceStore?: ProductBalanceStore;
}

@inject(GlobalStores.productBalanceStore)
@observer
export class ModalNewBalanceConfirmation extends React.Component<IModalNewBalanceConfirmationProps, {}> {
    render() {
        const { previousBalance, selectedProductsBalance, balanceOperation, productBalanceStore } = this.props;

        const balanceCards = !!previousBalance
            ? productBalanceStore!.availableProductDefinitions
                  .map((x, i) => {
                      const amountToSubtract = Object.keys(selectedProductsBalance)
                          .filter((selectProductBalanceKey) => !isNaN(Number(selectProductBalanceKey)))
                          .filter((productCode) => productCode !== undefined && Number(productCode) === x.productCode)
                          .reduce((sum, current) => sum + selectedProductsBalance[current], 0);

                      if (amountToSubtract === 0) {
                          return null;
                      }

                      const previousBalanceAmount = Number(previousBalance[x.productCode]) || 0;

                      const newBalance =
                          balanceOperation === "debit"
                              ? previousBalanceAmount - Number(amountToSubtract)
                              : previousBalanceAmount + Number(amountToSubtract);

                      return (
                          <ProductBalanceChange
                              key={`product-balance-change-${x.productCode}`}
                              previousBalance={previousBalanceAmount}
                              newBalance={newBalance}
                              productCode={x.productCode}
                          />
                      );
                  })
                  .filter((x) => x !== null)
            : null;

        return (
            !!previousBalance && (
                <div className="ModalNewBalanceConfirmation">
                    <div className="label">{"global.yourNewBalanceWillBe".localize()}</div>
                    <div className="cards-container">{balanceCards}</div>
                </div>
            )
        );
    }
}
