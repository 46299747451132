import "./index.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, IProductDefinition, ProductBalanceStore } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";
import { ProductIcon } from "../ProductIcon";
import { ProductInfo } from "../ProductInfo";
import { inject, observer } from "mobx-react";

export interface IAssessmentBalanceInfoProps {
    productDefinition: IProductDefinition;
    hideBalance?: boolean;
    productBalanceStore?: ProductBalanceStore;
}

@inject(GlobalStores.productBalanceStore)
@observer
export class AssessmentBalanceInfo extends React.Component<IAssessmentBalanceInfoProps, {}> {
    render() {
        const { productBalanceStore, productDefinition, hideBalance = false } = this.props;

        return (
            <div className="AssessmentBalanceInfo">
                <div className="info-container">
                    <ProductInfo productCode={productDefinition.productCode} />
                    {productDefinition.isBundle ? (
                        <div className="assessments-included-container">
                            {productDefinition.assessmentTypes.map((x, i) => (
                                <>
                                    <ProductIcon assessmentType={x} />
                                    {i + 1 < productDefinition.assessmentTypes.length ? (
                                        <span className="plus">+</span>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ) : null}
                </div>
                {!hideBalance ? (
                    <div className="balance-info">
                        <Label>{"global.availableProducts".localize()}</Label>
                        {productBalanceStore!.isUnlimited ? (
                            <FontAwesomeIcon icon={["far", "infinity"] as IconProp} />
                        ) : (
                            <div className={"balance-value"}>
                                {productBalanceStore!.getBalanceForProduct(productDefinition.productCode)}
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}
